import { Util } from "../modules/Util";
import { ratingService } from "../services/ChecklistRatingService";
import { lessonService } from "../services/LessonService";
import { IChecklistItem } from "../types/ChecklistItem";
import BaseModel from "./BaseModel";
import RatingModel from "./RatingModel";

export default class ChecklistItemModel extends BaseModel {
    public Label: string;
    public Overview: string;
    public ParentId: number;
    public PreviousSiblingId: number;
    public NextSiblingId: number;
    public ChecklistCollectionId: number;
    public Group: string;
    public TitleSort: number;
    public ChapterSort: number;
    public PreviouseRatings: RatingModel[];
    public CurrentRating: RatingModel;
    public LessonCount: number;
    public IsUpdate = false;
    public HasContent: boolean;
    public WasInPlannedLesson: boolean;

    constructor(checklistItem: IChecklistItem, group: string, titleSort: number, chapterSort: number) {
        super(checklistItem);
        this.Label = checklistItem.Label;
        this.Overview = checklistItem.Overview;
        this.ParentId = checklistItem.ParentId;
        this.PreviousSiblingId = checklistItem.PreviousSiblingId;
        this.NextSiblingId = checklistItem.NextSiblingId;
        this.ChecklistCollectionId = checklistItem.ChecklistCollectionId;
        this.HasContent = checklistItem.HasContent;
        this.Group = group;
        this.TitleSort = titleSort;
        this.ChapterSort = chapterSort;
        super.init(this);
    }

    public RatedCount(): string {
        return `${this.PreviouseRatings.filter(x => x.Id).length}/${this.LessonCount}`;
    }
    public NoNote() {
        return !this.CurrentRating.Note && !this.PreviouseRatings.some(x => x.Note);
    }

    public editNote() {
        app.mobileApp.navigate(`views/checkListNotes.html?checklistItemId=${this.Id}&updateLesson=${this.IsUpdate}`);
    }

    public get LatestRatingColor() {
        const latestRating = Util.firstOrDefault(this.PreviouseRatings || []);
        return Util.getRatingColor(latestRating ? latestRating.RatingValue : null);
    }

    public async setLastRating(personId: number) {
        if (!this.PreviouseRatings.length) {
            return;
        }
        const previousRatingValue = this.PreviouseRatings[this.PreviouseRatings.length - 1].RatingValue;
        if ((previousRatingValue && this.CurrentRating.RatingValue) || this.CurrentRating.Note) {
            this.CurrentRating.RatingValue = previousRatingValue;
            const entity = this.CurrentRating.toEntity();
            if (entity.Id) {
                await ratingService.updateEntity(entity, true);
            }
        } else if (!previousRatingValue && this.CurrentRating.RatingValue && !this.CurrentRating.Note) {
            await this.deleteRating();
        } else if (previousRatingValue) {
            this.CurrentRating.RatingValue = previousRatingValue;
            await this.addRating(personId);
        }

        this.trigger("change", { field: "CurrentRating" });
    }

    private prom: Promise<void> = Promise.resolve();
    public async changeRating(e) {
        this.prom = this.prom.then(async () => {
            const newRatingValue = Number($(e.currentTarget).closest(".ratingCircle").data("type"));
            const oldRatinValue = this.CurrentRating.RatingValue;
            this.CurrentRating.RatingValue = newRatingValue;

            if (!this.CurrentRating.Id) {
                await this.addRating(Util.getSelectedPersonId());
            } else if (oldRatinValue === newRatingValue && !this.CurrentRating.Note) {
                if (this.CurrentRating.OldRatingValue || this.CurrentRating.OldNote) {
                    this.CurrentRating.RatingValue = null;
                    const entity = this.CurrentRating.toEntity();
                    if (entity.Id) {
                        await ratingService.updateEntity(entity, true);
                    }
                } else {
                    await this.deleteRating();
                }
            } else {
                if (oldRatinValue === newRatingValue) {
                    this.CurrentRating.RatingValue = null;
                }
                const entity = this.CurrentRating.toEntity();
                if (entity.Id) {
                    await ratingService.updateEntity(entity, true);
                }
            }

            this.trigger("change", { field: "CurrentRating" });
        });
    }

    private async deleteRating() {
        await ratingService.delete(this.CurrentRating.Id);
        this.CurrentRating = new RatingModel();
    }

    private async addRating(personId: number) {
        this.CurrentRating.IsCurrentRating = !this.IsUpdate;
        this.CurrentRating.IsUpdateRating = this.IsUpdate;
        if (this.IsUpdate) {
            this.CurrentRating.LessonId = (await lessonService.getUpdateLesson(Util.getEducationId(personId))).Id;
        } else {
            this.CurrentRating.LessonId = (await lessonService.getCurrentLessonModel(Util.getEducationId(personId))).Id;
        }
        this.CurrentRating.ChecklistItemId = this.Id;
        this.CurrentRating.Id = await ratingService.insert(this.CurrentRating.toEntity());
    }
}
