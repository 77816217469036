import { dal } from "../dal/Dal";
import ConfirmContent from "../modules/ConfirmContent";
import { Util } from "../modules/Util";
import { localDataService } from "../services/LocalDataService";
import { personService } from "../services/PersonService";
import { syncService } from "../services/SyncService";
import { translation } from "../services/TranslationService";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { BaseView } from "./BaseView";

export default class Settings extends BaseView {
    public isAdmin: boolean;

    private readonly NAV_POINT = "views/personDetail.html?resetTab=true";
    private _tappedForLog;

    public async loadData(): Promise<void> {
        const userAppSettings = await userAppSettingsService.getSettings();
        const localData = await localDataService.getLocalData();
        this.set("isAdmin", localData.CanUpdateCompanySettings);
        if (userAppSettings.SyncRestriction === "WLAN") {
            this.set("syncRestrictionText", translation.t("synchronize-settings.wlan-only"));
        } else {
            this.set("syncRestrictionText", translation.t("synchronize-settings.also-with-data-connection"));
        }
        if (this.personId) {
            const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
            if (person) {
                this.set("backButtonText", `${person.FirstName} ${person.LastName}`);
            }
        }
    }

    public showView(): void {
        this._tappedForLog = 0;
        this.set("showLog", false);
        this.set("backButtonVisible", !!this.personId);
    }

    public askForLogout = async (): Promise<void> => {
        const logout = (await Util.confirmTemplate(ConfirmContent.logoutWarning(), translation.t("setting.yes-logout"), translation.t("common.cancel"))).confirmed;
        if (!logout) {
            return;
        }

        const dirtyItemCount = await syncService.getOverallDirtyItemsCount();
        if (dirtyItemCount && !Util.isDemo()) {
            const sync = (await Util.confirmTemplate(ConfirmContent.syncBeforeLogout(), translation.t("setting.sync-before-logout"), translation.t("setting.logout-without-sync"))).confirmed;
            if (sync) {
                await syncService.initSync();
            }
        }
        await this.logout();
    };

    private logout = async () => {
        for (const key of Object.keys(localStorage)) {
            if (key === "firstStartDone" || (key === "username" && !Util.isDemo())) {
                continue;
            }
            localStorage.removeItem(key);
        }
        await dal.closeDb();
        app.mobileApp.navigate("views/login.html");
    };

    public getNavPoint(): string {
        return this.NAV_POINT;
    }

    public back(e) {
        if (this.personId) {
            app.mobileApp.navigate(this.getNavPoint());
        } else {
            e.preventDefault();
        }
    }

    public tapForLog = () => {
        this._tappedForLog += 1;
        if (this._tappedForLog === 5) {
            this.set("showLog", true);
            this._tappedForLog = 0;
        }
    };
}
