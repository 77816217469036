import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import UserAppSettings, { IUserAppSettings, IUserAppSettingsDto } from "../types/UserAppSettings";
import { AppSettingsService } from "./AppSettingsService";

export default class UserAppSettingsService extends AppSettingsService<IUserAppSettings, IUserAppSettingsDto> {
    private readonly TABLE_NAME = "userappsettings";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IUserAppSettingsDto>().filter(x => x !== "Id");
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IUserAppSettings>());
    }

    protected getAppSettingsKey(): string {
        return UserAppSettings.getUserAppSettingsKey();
    }

    public async getSettings(): Promise<IUserAppSettings> {
        const appUserSettings = await this.getItems(`UserId = ${network.getUserId()}`);
        return Util.firstOrDefault(appUserSettings);
    }

    public async updateSettings(updateObject: Map<string, any>, isDirty: boolean = true): Promise<void> {
        await this.updateWhere(`UserId = ${network.getUserId()}`, updateObject, isDirty);
    }

    protected createEntityFromOData(item: IUserAppSettingsDto): IUserAppSettings {
        const entity = new UserAppSettings();
        entity.populateFromOData(item);
        entity.UserId = network.getUserId();
        return entity;
    }

    public createDbUpdateObject(oDataUpdateItem: any): Map<string, any> {
        const updateObject = new Map<string, any>();
        for (const [key, value] of Object.entries(oDataUpdateItem)) {
            if ((key === "PlannedLessons" || key === "BillEmail") && typeof value !== "string") {
                updateObject.set(key, JSON.stringify(value));
            } else if (this.getDtoFields().some(x => x === key)) {
                updateObject.set(key, value);
            }
        }
        return updateObject;
    }

    protected createEntityFromDb(item: IUserAppSettings): IUserAppSettings {
        const entity = new UserAppSettings();
        entity.populateFromDb(item);
        return entity;
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE IsDirty = ? AND UserId = ${network.getUserId()}`;
    }
}

export const userAppSettingsService = new UserAppSettingsService();
