import OpenFileModule from "../modules/OpenFileModule";
import { Util } from "../modules/Util";
import { translation } from "../services/TranslationService";
import { BaseView } from "./BaseView";

export default class Drawing extends BaseView {
    public lineOpacity: number;
    public drawFree: boolean;
    public drawStraightLines: boolean;

    private yOffset = 0;
    private surface: kendo.drawing.Surface;
    private initGroup: kendo.drawing.Group;
    private lineWidth: number;
    private lineColor: string;
    private history: kendo.drawing.Group[][];
    private navPoint: string;
    private exportName: string;

    public showView(e: any): void {
        this.exportName = e.view.params.name;
        this.initGroup = null;
        const imgurl = window.drawingImage;
        this.set("lineWidths", this.getLineWidths());
        this.navPoint = e.view.params.navPoint;
        this.set("lockButtonVisible", !Util.isSimulator());

        setTimeout(() => {
            $("#color-chooser").kendoColorPalette({
                palette: ["#000000", "#808080", "#FFFFFF", "#E73C29", "#F59F00", "#F7F700", "#7BB928", "#0000F7"],
                tileSize: 48,
                value: "#000000",
                change: (event: any) => {
                    this.set("lineColor", event.value);
                }
            } as any);
            if (imgurl) {
                $(`<img src=${imgurl}>`).on("load", event => {
                    this.initDrawing(event, imgurl);
                });
            } else {
                this.initDrawing();
            }
        }, 0);
    }

    public async loadData(): Promise<void> {
        // nothing to load
    }

    private getLineWidths = () => {
        const lw = [];
        for (let i = 2; i <= 50; i += 2) {
            lw.push({ Name: `${i} pt`, Value: i });
        }
        return lw;
    };

    private initDrawing = (e = null, imgurl = null) => {
        let imageDimensions;
        if (e) {
            imageDimensions = this.getImageDimensions(e.target.naturalWidth, e.target.naturalHeight);
        }
        this.history = [];
        this.set("lineWidth", 4);
        this.set("lineOpacity", 100);
        this.set("lineColor", "#000000");
        const surfaceElement = $("#surface");
        surfaceElement.width(window.innerWidth);

        // create drawing surface
        this.initGroup = new kendo.drawing.Group();
        if (imgurl) {
            // create image
            $("#surface-container").css("padding-top", "15px");
            surfaceElement.height(window.innerHeight - $("#drawingHeader").height() - 15);
            const imgRect = new kendo.geometry.Rect(
                new kendo.geometry.Point((window.innerWidth - imageDimensions.get("width")) / 2, 0),
                kendo.geometry.Size.create(imageDimensions.get("width"), imageDimensions.get("height"))
            );
            const img = new kendo.drawing.Image(imgurl, imgRect);
            this.initGroup.append(img);
        } else {
            surfaceElement.height(window.innerHeight - $("#drawingHeader").height());
            $("#surface-container").css("padding-top", "0px");

            const rect = new kendo.geometry.Rect(new kendo.geometry.Point(0, 0), kendo.geometry.Size.create(window.innerWidth, window.innerHeight - $("#drawingHeader").height()));
            const background = new kendo.drawing.Rect(rect, { fill: { color: "white" } });

            this.initGroup.append(background);
        }
        this.yOffset = surfaceElement.offset().top * -1;
        this.surface = kendo.drawing.Surface.create(surfaceElement, { type: "svg" });
        this.surface.draw(this.initGroup);
        this.enableFreeDrawing();
    };

    private getImageDimensions = (imageWidth: number, imageHeight: number): Map<string, number> => {
        let width: number;
        let height: number;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        if (imageWidth > windowWidth) {
            width = windowWidth;
            height = imageHeight / (imageWidth / width);
        } else {
            width = imageWidth;
            height = imageHeight;
        }
        if (height > windowHeight * 0.8) {
            height = windowHeight * 0.8;
            width = imageWidth / (imageHeight / height);
        }
        return new Map<string, number>().set("width", width).set("height", height);
    };

    private lastX: number;
    private lastY: number;
    private draw = e => {
        let x: number;
        let y: number;

        if (Util.isBrowser()) {
            x = e.clientX;
            y = e.clientY + this.yOffset;
        } else {
            x = e.originalEvent.targetTouches[0].screenX;
            y = e.originalEvent.targetTouches[0].screenY + this.yOffset;
        }
        const path = this.getPath();
        if (!this.hasLastCoordinates()) {
            path.moveTo(x, y).lineTo(x, y).close();
        } else {
            path.moveTo(this.lastX, this.lastY).lineTo(x, y).close();
        }
        const group = new kendo.drawing.Group();
        group.append(path);
        this.history[this.history.length - 1].push(group);
        this.lastX = x;
        this.lastY = y;
        this.surface.draw(group);
    };

    public async toggleLock(e) {
        if (Util.isSimulator()) {
            return;
        }
        if ($(e.target).closest("i").hasClass("icon-unlock")) {
            await (window.screen.orientation as any).lock(window.screen.orientation.type.startsWith("portrait") ? "portrait" : "landscape");
            $(e.target).closest("i").removeClass("icon-unlock");
            $(e.target).closest("i").addClass("icon-lock");
        } else {
            window.screen.orientation.unlock();
            $(e.target).closest("i").removeClass("icon-lock");
            $(e.target).closest("i").addClass("icon-unlock");
        }
    }

    private drawStraightLine = e => {
        const group = new kendo.drawing.Group();
        if (!this.hasLastCoordinates()) {
            this.history.push([]);
            this.lastX = e.pageX;
            this.lastY = e.pageY + this.yOffset;
            const circle = new kendo.geometry.Circle([this.lastX, this.lastY], this.lineWidth / 4);
            group.append(new kendo.drawing.Circle(circle).stroke(this.lineColor, this.lineWidth / 2));
        } else {
            this.history.push([]);
            const path = this.getPath();
            path.moveTo(this.lastX, this.lastY)
                .lineTo(e.pageX, e.pageY + this.yOffset)
                .close();
            this.deleteLast();
            group.append(path);
            this.resetLastCoordinates();
        }
        this.history[this.history.length - 1].push(group);
        this.surface.draw(group);
    };

    public deleteLast = () => {
        this.resetLastCoordinates();
        const lastSteps = this.history.pop();
        if (lastSteps) {
            lastSteps.forEach(value => {
                value.clear();
            });
        }
        this.makeHistoryNotEmpty();
    };

    public clearAll = () => {
        this.resetLastCoordinates();
        do {
            const group = this.history.pop();
            group.forEach(value => {
                value.clear();
            });
        } while (this.history.length);
        this.makeHistoryNotEmpty();
    };

    public getNavPoint(): string {
        return `${this.navPoint}${this.navPoint.includes("?") ? "&fromDrawing=true" : "?fromDrawing=true"}`;
    }

    private makeHistoryNotEmpty(): boolean {
        if (!this.history.length) {
            this.history.push([]);
            return true;
        }
        return false;
    }

    public enableFreeDrawing = () => {
        if (!this.get<boolean>("drawFree")) {
            this.resetDrawingMode();
            this.set("drawFree", true);
            $("#surface-container").on(Util.isBrowser() ? "mousedown" : "touchstart", () => {
                this.history.push([]);
                $("#surface-container").on(Util.isBrowser() ? "mousemove" : "touchmove", this.draw);
            });
            $("#surface-container").on(Util.isBrowser() ? "mouseup" : "touchend", () => {
                this.resetLastCoordinates();
                $("#surface-container").off(Util.isBrowser() ? "mousemove" : "touchmove", this.draw);
            });
        }
    };

    public deleteImage = () => {
        this.set("exportName", translation.t("drawing.file-name"));
        this.initDrawing();
    };

    public enableStraightLines = () => {
        if (!this.get<boolean>("drawStraightLines")) {
            this.resetDrawingMode();
            this.set("drawStraightLines", true);
            $("#surface-container").on("click", e => {
                this.drawStraightLine(e);
            });
        }
    };

    public exportDrawing = async () => {
        app.mobileApp.showLoading();
        const image = await kendo.drawing.exportImage(this.getGroup(), {});
        new OpenFileModule(
            image.substring(image.indexOf(";base64,") + 8),
            `${this.exportName}.jpg`,
            "image/jpeg",
            () => {
                app.mobileApp.hideLoading();
            },
            () => {
                app.mobileApp.hideLoading();
                Util.showNotification(translation.t("export.unknown-error"), "error");
            }
        ).openFile();
    };

    private getGroup = (): kendo.drawing.Group => {
        this.history.forEach(groupArr => {
            groupArr.forEach(group => {
                this.initGroup.append(group);
            });
        });
        return this.initGroup;
    };

    private resetDrawingMode = () => {
        this.set("drawFree", false);
        this.set("drawStraightLines", false);
        this.resetLastCoordinates();
        this.disableStraightLines();
        this.disableFreeDrawing();
    };

    private resetLastCoordinates = () => {
        this.lastX = null;
        this.lastY = null;
    };

    private disableStraightLines = () => {
        $("#surface-container").off("click");
    };

    private disableFreeDrawing = () => {
        $("#surface-container").off("touchstart");
        $("#surface-container").off("touchend");
    };

    private hasLastCoordinates() {
        return this.lastX && this.lastY;
    }

    private getPath() {
        return new kendo.drawing.Path({
            stroke: {
                color: this.get<string>("lineColor"),
                opacity: this.get<number>("lineOpacity") / 100,
                width: this.get<number>("lineWidth"),
                lineCap: "round",
                lineJoin: "round"
            } as any
        });
    }

    public hideView(): void {
        if (!Util.isSimulator()) {
            window.screen.orientation.unlock();
        }
        window.drawingImage = "";
        $("#color-chooser").data().kendoColorPalette.destroy();
        $("#color-chooser").html("");
    }
}
