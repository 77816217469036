import { translation } from "../services/TranslationService";
import { Util } from "./Util";
// eslint-disable-next-line @typescript-eslint/no-namespace
export module Connection {
    // eslint-disable-next-line no-inner-declarations
    function getNetworkConnection(): string {
        return navigator.connection.type;
    }

    export function isAllowedToSync(syncRestriction: string): boolean {
        if (Util.isBrowser()) {
            return true;
        }
        switch (syncRestriction) {
            case "WLAN":
                if (getNetworkConnection() === "wifi" || Util.isSimulator()) {
                    return true;
                } else {
                    Util.showNotification(translation.t("confirm.no-wlan"), "info");
                    return false;
                }
            default:
                return true;
        }
    }

    export function noInternetConnection(): boolean {
        return navigator.connection.type === "none";
    }
}
