import { activeLesson } from "../modules/ActiveLesson";
import { BaseService } from "./BaseService";
import CurrentLesson, { ICurrentLesson, ICurrentLessonDto } from "../types/CurrentLesson";
import { keys } from "ts-transformer-keys";
import { dal } from "../dal/Dal";

export default class CurrentLessonService extends BaseService<ICurrentLesson, ICurrentLessonDto> {
    private readonly TABLE_NAME = "currentlesson";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ICurrentLessonDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ICurrentLesson>());
    }

    public getApiRoute(): string {
        throw new Error("no odata route available");
    }

    protected createEntityFromOData(item: any): CurrentLesson {
        const entity = new CurrentLesson();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: any): CurrentLesson {
        const entity = new CurrentLesson();
        entity.populateFromDb(item);
        return entity;
    }

    public async getCurrentLesson(personId: number): Promise<ICurrentLesson[]> {
        return this.getItems(`personId = ${personId}`);
    }

    // TODO: OR-892
    public getRating = async (personId: number, chapterId: number): Promise<any> => {
        const result = await dal.firstOrDefault<{ RatingValue: number }>(`SELECT RatingValue FROM currentlesson WHERE PersonId = ${personId} AND ChapterId = ${chapterId}`);
        return { ratingValue: result ? result.RatingValue : 0, chapterId: chapterId };
    };

    public deleteRatingValue = async (personId: number, chapterId: number): Promise<void> => {
        await dal.execute(`UPDATE currentlesson SET RatingValue = null WHERE PersonId = ${personId} AND ChapterId = ${chapterId}`);
    };

    public updateRatingValue = async (personId: number, chapterId: number, ratingValue: number): Promise<void> => {
        const results = await dal.executeRead(`SELECT RatingValue FROM currentlesson WHERE PersonId = ${personId} AND ChapterId = ${chapterId}`);
        if (results.length > 0) {
            await dal.execute(`UPDATE currentlesson SET RatingValue = ${ratingValue} WHERE PersonId = ${personId} AND ChapterId = ${chapterId}`);
        } else {
            await dal.execute(
                `INSERT INTO currentlesson(Id, RatingValue, PersonId, ChapterId, IsDeleted)
                 VALUES ((SELECT MIN(0, COALESCE(MIN(Id), 0)) - 1 FROM ${this.getTableName()}), ?, ?, ?, ?)`,
                [ratingValue, personId, chapterId, false]
            );
        }
    };

    public async getNote(chapterId: number, personId: number): Promise<ICurrentLesson[]> {
        return this.getItems(`PersonId=${personId} AND ChapterId=${chapterId}`);
    }

    public updateNote = async (personId: number, chapterId: number, note: string): Promise<void> => {
        const results = await dal.firstOrDefault(`SELECT Note FROM currentlesson WHERE PersonId = ? AND ChapterId = ?`, [personId, chapterId]);
        if (results) {
            await dal.execute(`UPDATE currentlesson SET Note = ? WHERE PersonId = ? AND ChapterId = ?`, [note, personId, chapterId]);
            activeLesson.filter(al => al.PersonId === personId)[0].setNote(chapterId, note);
        } else {
            const sql = `INSERT INTO currentlesson(Id, Note, PersonId, ChapterId, IsDeleted) VALUES ((SELECT MIN(0, COALESCE(MIN(Id), 0)) - 1 FROM ${this.getTableName()}),?, ?, ?, ?)`;
            await dal.execute(sql, [note, personId, chapterId, false]);
            activeLesson.filter(al => al.PersonId === personId)[0].setNote(chapterId, note);
        }
    };

    public deleteAllByPerson = async (personId: number): Promise<void> => {
        await dal.execute(`DELETE FROM currentlesson WHERE PersonId = ${personId}`);
    };
}

export const currentLessonService = new CurrentLessonService();
