import { BaseService } from "./BaseService";
import Lfa, { ILfa, ILfaDto } from "../types/Lfa";
import { network } from "../modules/Network";
import LfaModel from "../models/LfaModel";
import { keys } from "ts-transformer-keys";

export default class LfaService extends BaseService<ILfa, ILfaDto> {
    private readonly TABLE_NAME = "Lfas";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ILfaDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ILfa>());
    }

    public getApiRoute(): string {
        return `${network.API}/${Lfa.EntityTypeId}`;
    }

    protected getNewItems(): Promise<ILfa[]> {
        return this.getItems("IsNew = ?", null, null, null, null, [true]);
    }

    protected createEntityFromDb(item: ILfa): ILfa {
        const lfa = new Lfa();
        lfa.populateFromDb(item);
        return lfa;
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        params.push(true);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE (IsNew = ? OR IsDirty = ?)`;
    }

    public createEntityFromOData(item: ILfaDto): ILfa {
        const lfa = new Lfa();
        lfa.populateFromOData(item);
        return lfa;
    }

    public async insertOrUpdate(lfa: ILfa): Promise<void> {
        const exists = await this.getItemById(lfa.Id);
        if (exists) {
            await this.updateEntity(lfa, true);
        } else {
            await this.insert(lfa);
        }
    }

    public async getLfaModel(educationId: number): Promise<LfaModel> {
        const lfa = await this.getItemById(educationId);
        if (lfa) {
            return new LfaModel(lfa);
        }
        const newLfa = new Lfa();
        newLfa.Id = educationId;
        return new LfaModel(newLfa);
    }
}

export const lfaService = new LfaService();
