import CalendarListModel from "../models/CalendarListModel";
import { Util } from "../modules/Util";
import { translation } from "../services/TranslationService";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { BaseView } from "./BaseView";

export default class CalendarSettings extends BaseView {
    private readonly NAV_POINT = "views/settings.html";

    private viewCalendarList: kendo.data.ObservableArray;
    public calendarStatusText: string;

    constructor() {
        super();
        this.viewCalendarList = new kendo.data.ObservableArray([]);
        super.init(this);
    }

    public initView(): void {
        if ($("#calendarSettingsContent").length) {
            Util.renderView("calendarTemplate", this, $("#calendarSettingsContent"));
        }
    }

    public async loadData(): Promise<void> {
        if (!Util.isSimulator()) {
            while (this.viewCalendarList.length > 0) {
                this.viewCalendarList.pop();
            }
            const { calendar } = window.plugins;
            const userAppSettings = await userAppSettingsService.getSettings();
            return new Promise(res => {
                calendar.listCalendars(
                    deviceCalendars => {
                        let parsedSettingsList = [];
                        if (userAppSettings.CalendarList !== null) {
                            parsedSettingsList = JSON.parse(userAppSettings.CalendarList);
                        }
                        this.prepareCalendars(deviceCalendars, parsedSettingsList);
                        $("#indexCalendarList .calendarSwitch").kendoMobileSwitch({ onLabel: "", offLabel: "" });
                        res();
                    },
                    () => {
                        Util.showNotification(translation.t("util.calendar-sync-error"), "error");
                        res();
                    }
                );
            });
        }
        return Promise.resolve();
    }

    private prepareCalendars = (deviceCalendars, calendarSettingsList): void => {
        this.setStatusText(deviceCalendars.length);
        for (const calendar of deviceCalendars) {
            this.viewCalendarList.push(
                new kendo.data.ObservableObject(new CalendarListModel(calendar.id, this.getDeviceCalendarItemName(calendar.name), this.isSelected(calendar, calendarSettingsList)))
            );
        }
    };

    private isSelected(item, cl) {
        return cl.some(calendarListItem => item.id === calendarListItem.id);
    }

    private getDeviceCalendarItemName(name: string) {
        return name === undefined ? translation.t("util.calendar-no-name") : name;
    }

    public save = async (showMessage: boolean = true): Promise<void> => {
        const choosenCalendarUpdate = [];
        const checkedCalendars = this.get<CalendarListModel[]>("viewCalendarList");
        for (const calendar of checkedCalendars) {
            if (calendar.isChecked) {
                choosenCalendarUpdate.push({ name: calendar.name, id: calendar.id });
            }
        }
        const updateUserAppSettingsCalendarList = new Map<string, any>().set("CalendarList", JSON.stringify(choosenCalendarUpdate));
        try {
            await userAppSettingsService.updateSettings(updateUserAppSettingsCalendarList);
            if (showMessage) {
                Util.showNotification(translation.t("util.calendar-saved"), "success");
                this.back();
            }
        } catch (e) {
            if (showMessage) {
                Util.showNotification(translation.t("util.calendar-save-error"), "success");
            }
            this.back();
        }
    };

    private setStatusText = listLength => {
        if (listLength !== 0) {
            this.set("calendarStatusText", translation.t("util.please-select-calendar"));
        } else {
            this.set(
                "calendarStatusText",
                translation.t("util.calendar-no-access")
            );
        }
    };

    public getNavPoint(): string {
        return this.NAV_POINT;
    }
}
