import ChecklistItemModel from "../models/ChecklistItemModel";
import { ConfirmRejectReason } from "../modules/ConfirmRejectReason";
import { orientationChange } from "../modules/OrientationChange";
import PromptContent from "../modules/PromptContent";
import { Util } from "../modules/Util";
import { checklistCollectionService } from "../services/ChecklistCollectionService";
import { checklistItemService } from "../services/ChecklistItemService";
import { emailService } from "../services/EmailService";
import { personContactService } from "../services/PersonContactService";
import { personService } from "../services/PersonService";
import { translation } from "../services/TranslationService";
import ChecklistCollection from "../types/ChecklistCollection";
import { ImageGalleryView } from "./ImageGalleryView";

export default class EducationPaper extends ImageGalleryView {
    public backButtonText: string;
    public backButtonVisible: boolean;
    public chapterTitle: string;
    public hasContent: boolean;
    public checkLists: ChecklistCollection[];
    public selectedChecklistId: number;

    private navPoint: string;
    private fullChecklist: kendo.data.DataSource;
    private item: ChecklistItemModel;

    constructor() {
        super();
        this.set("selectedChecklistId", 1);
        this.set(
            "fullChecklist",
            kendo.data.DataSource.create({
                group: {
                    field: "Group",
                    dir: "asc",
                    compare: (a, b) => {
                        const compareA = Util.firstOrDefault<ChecklistItemModel>(a.items);
                        const compareB = Util.firstOrDefault<ChecklistItemModel>(b.items);
                        if (compareA && compareB) {
                            return compareA.TitleSort - compareB.TitleSort;
                        }
                        return 0;
                    }
                } as any,
                sort: { field: "ChapterSort", dir: "asc" },
                transport: {
                    read: async options => {
                        const checklistItemModels = await checklistItemService.getChecklistModel(
                            this.get<number>("selectedChecklistId"),
                            true
                        );
                        options.success(checklistItemModels);
                    }
                }
            } as any)
        );
        super.init(this);
    }

    public initView(): void {
        $("#allLessonViewEducationPaper").show();
        $("#chapterContentPlanLessonView").show();
        this.set("chapterTitle", translation.t("education-paper.select-chapter"));
    }

    public async loadData(): Promise<void> {
        await this.fullChecklist.read();
        const checklists = await checklistCollectionService.getItems();
        this.set("checkLists", checklists);
        if (this.personId) {
            const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
            if (person) {
                this.set("backButtonText", `${person.FirstName} ${person.LastName}`);
                this.set("backButtonVisible", true);
            } else {
                this.set("backButtonVisible", false);
            }
        }
        if (this.item) {
            this.fullChecklist.data().forEach((newItem: ChecklistItemModel) => {
                if (this.item.Id === newItem.Id) {
                    this.item = newItem;
                    this.item.set("IsSelected", true);
                }
            });
            const checklistItem = await checklistItemService.getItemById(this.item.Id);
            this.loadImages(Util.replaceChecklistBlob(checklistItem.Overview));
        } else {
            this.loadImages("");
        }
    }

    public showView(e: any): void {
        this.navPoint = "views/personDetail.html?resetTab=true";
        this.set("backButtonVisible", !!this.personId);
        if (!$("#sideBarEducationPaper").hasClass("sideBarVisible") && !(e.view.params.fromDrawing === "true")) {
            this.toggleSidebar();
        }
        orientationChange.FunctionList = [this.setEducationContentHeight];
    }

    public listSelected = async e => {
        if (this.get<number>("selectedChecklistId") !== e.dataItem.Id) {
            this.set("selectedChecklistId", e.dataItem.Id);
            this.loadImages("");
            this.set("chapterTitle", translation.t("education-paper.select-chapter"));
            await this.fullChecklist.read();
        }
    };

    public toggleSidebar = () => {
        let leftMargin: number;
        if ($(window).width() >= 480) {
            leftMargin = 350;
        } else {
            leftMargin = 200;
        }

        if ($("#sideBarEducationPaper").hasClass("sideBarVisible")) {
            this.setEducationContentHeight();
            $(".chapterContentPlanLesson").css("margin-left", 0);
            $(".contentEducationPaperLessonHeader").find("i").toggleClass("icon-cross");
            $(".contentEducationPaperLessonHeader").find("i").toggleClass("icon-bullets");
        } else {
            $(".chapterContentPlanLesson").css("margin-left", `${leftMargin}px`);
            this.setEducationContentHeight();
            $(".contentEducationPaperLessonHeader").find("i").toggleClass("icon-cross");
            $(".contentEducationPaperLessonHeader").find("i").toggleClass("icon-bullets");
        }
        $("#sideBarEducationPaper").toggleClass("sideBarVisible");
    };

    public setEducationContentHeight = () => {
        const contentHeight = $("#educationPaper").closest(".km-content.km-stretched-view").height();
        const chapterHeaderHeight =
            $("#educationPaper").find(".planLesson").height() +
            $(
                "#allLessonViewEducationPaper > div.km-content.km-widget.km-scroll-wrapper > div.km-scroll-header"
            ).height();
        $("#allLessonViewEducationPaperContainer").height(contentHeight - chapterHeaderHeight);
        $(".chapterContentPlanLesson").height(contentHeight);
    };

    public showContent = async e => {
        app.mobileApp.showLoading();
        const newItem = e.data as ChecklistItemModel;
        if (!this.item || (this.item && this.item.Id !== newItem.Id)) {
            if (this.item) {
                this.item.set("IsSelected", false);
            }
            this.item = newItem;
            this.fullChecklist.getByUid(this.item.uid).set("IsSelected", true);
            this.set("chapterTitle", this.item.Label);
            const checklistItem = await checklistItemService.getItemById(this.item.Id);
            const content = Util.replaceChecklistBlob(checklistItem.Overview ? checklistItem.Overview.trim() : "");
            this.loadImages(content);
            this.set("hasContent", !!content);
            ($("#chapterContentPlanLessonView").data("kendoMobileView") as any).scroller.reset();
            this.toggleSidebar();
            $(".myDriveGalleryPicture em [style]").each((_, ele) => $(ele).removeAttr("style"));
            app.mobileApp.hideLoading();
        } else {
            app.mobileApp.hideLoading();
        }
    };

    public sendContentAsMail = async (): Promise<void> => {
        const chapter = await checklistItemService.getItemById(this.getChapterId());
        let personEmail = "";
        if (this.personId) {
            const mail = await personContactService.getPersonEmail(this.personId);
            if (mail) {
                personEmail = mail.NameInMedia;
            }
        }
        try {
            personEmail = await Util.prompt(
                PromptContent.sendContentMail(),
                translation.t("education-paper.send-email"),
                translation.t("common.cancel"),
                personEmail
            );
            await emailService.sendEmail(
                Util.getUsername(),
                personEmail,
                this.get<string>("chapterTitle"),
                chapter.Overview
            );
            Util.showNotification(
                translation.t("education-paper.send-email-to-successful", { toAddress: personEmail }),
                "success"
            );
        } catch (e) {
            if (e !== ConfirmRejectReason.Cancel && e !== ConfirmRejectReason.NoText) {
                Util.showNotification(
                    translation.t("education-paper.send-email-error", { toAddress: personEmail }),
                    "error"
                );
            }
        }
    };

    protected getChapterId(): number {
        return this.item.Id;
    }

    protected getImageSelector(): string {
        return "#chapterContentPlanLesson";
    }

    public getNavPoint(): string {
        return this.navPoint;
    }

    thisNavPoint(): string {
        return "views/educationPaper.html";
    }

    public openFreeHandDrawing = (): void => {
        app.mobileApp.navigate(
            `views/drawing.html?name=${translation.t("drawing.file-name")}&navPoint=${this.thisNavPoint()}`
        );
    };

    public back(e) {
        if (this.personId) {
            app.mobileApp.navigate(this.getNavPoint());
        } else {
            e.preventDefault();
        }
    }
}
