import DateTime from "../modules/DateTime";
import { Util } from "../modules/Util";
import { BillStatus } from "../types/Bill";
import { IOrphyDriveSubscriptionDto } from "../types/OrphyDriveSubscription";
import ObservableObject from "../viewModels/ObservableObject";

export default class OrphyDriveSubscriptionModel extends ObservableObject {
    public Id: number;
    public BillStatus: any;
    public Name: string;
    public RecurringJobPeriodStart: Date;
    public RecurringJobPeriodEnd: Date;
    public TotalPriceWithDiscountAndTax: number;

    constructor(subscription: IOrphyDriveSubscriptionDto) {
        super();
        this.Id = subscription.Id;
        this.BillStatus = BillStatus[subscription.BillStatus];
        this.Name = subscription.Name;
        this.RecurringJobPeriodEnd = new Date(subscription.RecurringJobPeriodEnd);
        this.RecurringJobPeriodStart = new Date(subscription.RecurringJobPeriodStart);
        this.TotalPriceWithDiscountAndTax = subscription.TotalPriceWithDiscountAndTax;
        super.init(this);
    }

    get BillStatusIcon() {
        return Util.getBillStatus(this.BillStatus);
    }

    get FormattedRecurringJobPeriodStart() {
        return this.RecurringJobPeriodStart ? DateTime.parseNumberDate(this.RecurringJobPeriodStart) : "";
    }

    get FormattedRecurringJobPeriodEnd() {
        return this.RecurringJobPeriodEnd ? DateTime.parseNumberDate(this.RecurringJobPeriodEnd) : "";
    }
}
