import { translation } from "../services/TranslationService";

export default class PromptContent {
    public static addChecklistCollection = () => `
    <div class="centerText">
        <div class="dialogIcon">
            <i class="icons icon-book-open"></i>
        </div>
        <div class="largeTitle">${translation.t("edit-checklist.add-new-card")}</div>
        <div>${translation.t("edit-checklist.name-of-card")}</div>
    </div>`;

    public static addChecklistItem = () => `
    <div class='centerText'>
        <div class='dialogIcon'>
            <i class='icons icon-book-open'></i>
        </div>
        <div class='largeTitle'>${translation.t("edit-checklist.add-new-main-chapter")}</div>
        <p>${translation.t("edit-checklist.add-name-of-chapter")}</p>
    </div>`;

    public static sendContentMail = () => `
    <div class='centerText'>
        <div class='dialogIcon'>
            <i class='icons icon-mail'></i>
        </div>
        <div class='largeTitle'>${translation.t("education-paper.send-by-email")}</div>
        <p>${translation.t("education-paper.send-email-to")}</p>
    </div>`;

    public static notSupported = () => `
    <div class='centerText'>
        <div class='dialogIcon'>
            <i class='icons icon-danger'></i>
        </div>
        <div>Die Browserversion von OrphyDrive unterstützen wir nicht offiziell. Unsere offizielle Version finden Sie in den App-Store (iOS und Android).</div>
        <br />
        <div>Für die inoffizielle Nutzung empfehlen wir die Verwendung des Google-Chrome Browsers.</div>
        <div>Vielen Dank für die Kenntnisnahme.</div>
    </div>`;
}
