import { Util } from "../modules/Util";
import { jobPositionService } from "../services/JobPositionService";
import { paymentWayService } from "../services/PaymentWayService";
import { personAddressService } from "../services/PersonAddressService";
import { PaymentWayType } from "../types/PaymentWay";
import { BaseView } from "./BaseView";
import DateTime from "../modules/DateTime";
import JobPositionModel from "../models/JobPositionModel";
import { personContactService } from "../services/PersonContactService";
import { personService } from "../services/PersonService";
import { IPersonAddress } from "../types/PersonAddress";
import { IPerson } from "../types/Person";
import { IPersonContact } from "../types/PersonContact";
import { educationService } from "../services/EducationService";
import { translation } from "../services/TranslationService";

export interface CreateBillModelType {
    EducationId: number;
    FirstName: string;
    LastName: string;
    ChecklistCollectionName: string;
}

export default class CreateBill extends BaseView {
    public isSearchVisible: boolean;
    public isCreateBillForPersonVisible: boolean;
    public personTitle: string;
    public personAvatar: string;
    public totalPrice: string;
    public disabledButton: boolean;
    public searchTerm: string;
    public personName: string;
    public personStreet: string;
    public personZipCodeCity: string;
    public personEmail: string;
    public paymentWay: string;

    private readonly NAV_POINT = "views/paperworkBills.html";
    private allPersons: CreateBillModelType[];
    private hasPersonMail: boolean;
    private selectedEducationId: number;

    public async loadData(): Promise<void> {
        this.allPersons = await jobPositionService.getNotBilledJobpositions();
        this.set("paymentWays", await paymentWayService.getItems());
        this.set("selectedPaymentWay", (await paymentWayService.getLastSelectedPaymentWay()).Id);
        this.set("billingDate", DateTime.getInputDate(new Date()));
        this.set("searchedPersons", this.allPersons);
    }

    public showView(): void {
        this.set("isSearchVisible", true);
        this.set("isCreateBillForPersonVisible", false);
        this.set("searchTerm", "");
    }

    public search = (): void => {
        const searchTerm = this.get<string>("searchTerm").toLowerCase();
        this.set(
            "searchedPersons",
            this.allPersons.filter(p => {
                const displayName = `${p.FirstName} ${p.LastName}`.toLowerCase();
                const displayNameInverse = `${p.LastName} ${p.FirstName}`.toLowerCase();
                return displayName.startsWith(searchTerm) || displayNameInverse.startsWith(searchTerm) || !searchTerm;
            })
        );
    };

    public choosePerson = async (educationId: number) => {
        this.set("disabledButton", true);
        this.set("totalPrice", Util.formatPrice(0));
        this.set("isCreateBillForPersonVisible", true);
        this.set("isSearchVisible", false);
        this.toggleCreateButton();
        const education = await educationService.getItemById(educationId);
        this.selectedEducationId = educationId;
        const person = await personService.getItemById(education.PersonId, "Id, FirstName, LastName");
        this.setPersonAddress(await personAddressService.getPersonAddress(person.Id), person);
        this.setPersonEmail(await personContactService.getPersonEmail(person.Id));
        const jobPositions = await jobPositionService.getAllPositionsByEducationId(educationId);

        jobPositions.forEach(job => {
            if (job.Description === "Fahrlektion") {
                job.Description = translation.t("account-lesson.account-lesson-name", { count: job.Amount });
            }
        });

        this.set("personTitle", `${person.FirstName} ${person.LastName}`);
        this.set("personAvatar", Util.getAvatar(person.FirstName, person.LastName));
        this.set(
            "jobPositions",
            jobPositions.filter(j => j.AllowBilling)
        );
    };

    private setPersonAddress = (personAddress: IPersonAddress, person: IPerson) => {
        if (personAddress) {
            this.set("personName", `${person.FirstName} ${person.LastName}`);
            this.set("personStreet", personAddress.Street);
            this.set("personZipCodeCity", `${personAddress.ZipCode} ${personAddress.City}`);
        } else {
            this.set("personName", `<i class="icons icon-danger largeIcon"></i>&nbsp;${translation.t("account-lesson.no-receipt-address")}`);
            this.set("personStreet", "");
            this.set("personZipCodeCity", "");
        }
    };

    private setPersonEmail = (personEmail: IPersonContact) => {
        if (personEmail) {
            this.set("personEmail", personEmail.NameInMedia);
            this.hasPersonMail = true;
        } else {
            this.set("personEmail", `<i class="icons icon-danger largeIcon"></i>&nbsp;${translation.t("account-lesson.no-email-on-bill")}`);
            this.hasPersonMail = false;
        }
    };

    public clickRow = async e => {
        e.data.set("CheckedForAccount", !e.data.get("CheckedForAccount"));
        this.calcTotalPrice();
        this.toggleCreateButton();
    };

    public calcTotalPrice = (): void => {
        const jobPositions = this.get<JobPositionModel[]>("jobPositions");
        const total = jobPositions.reduce((a, b) => {
            if (b.CheckedForAccount) {
                return a + b.Amount * b.Price;
            }
            return a;
        }, 0);
        this.set("totalPrice", Util.formatPrice(total));
    };

    private toggleCreateButton = (): void => {
        const jobPositions = this.get<JobPositionModel[]>("jobPositions");
        this.set("disabledButton", !jobPositions?.some(j => j.CheckedForAccount));
    };

    public setLastSelectedPaymentWay = () => {
        paymentWayService.setLastSelectedPaymentWayId(this.get<number>("selectedPaymentWay"));
    };

    public createBill = async (): Promise<void> => {
        const accountIds = this.get<JobPositionModel[]>("jobPositions")
            .filter(j => j.CheckedForAccount)
            .map(j => j.PositionId);
        const paymentWayEntity = await paymentWayService.getItemById(this.get<number>("selectedPaymentWay"));
        const billingDate = DateTime.getDateTimeFromInput(this.get<string>("billingDate"));
        if (paymentWayEntity.Type !== PaymentWayType.Invoice) {
            await jobPositionService.accountPositions(this.selectedEducationId, this.personId, accountIds, paymentWayEntity.Id, true, billingDate, billingDate, true);
            this.back();
        } else {
            await jobPositionService.accountPositions(this.selectedEducationId, this.personId, accountIds, paymentWayEntity.Id, false, billingDate, null);
            const message = `${translation.t("account-lesson.bill-successfull")}.${this.hasPersonMail ? `<br />${translation.t("account-lesson.send-bill-after-sync")}` : ""}`;
            Util.showNotification(message, "info");
            this.back();
        }
    };

    public back() {
        this.cancelCreateBill();
        super.back();
    }

    public cancelCreateBill = () => {
        this.set("totalPrice", Util.formatPrice(0));
        this.set("isSearchVisible", true);
        this.set("isCreateBillForPersonVisible", false);
    };

    public getNavPoint(): string {
        return this.NAV_POINT;
    }
}
