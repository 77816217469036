import { orientationChange } from "../modules/OrientationChange";
import { Util } from "../modules/Util";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { localDataService } from "../services/LocalDataService";
import { syncService } from "../services/SyncService";
import { translation } from "../services/TranslationService";
import { BaseView } from "./BaseView";

export default class FirstTimeUsage extends BaseView {
    public companySettingsId: number = null;
    public availableCalendars: any = null;

    private profileView: kendo.View;
    private productView: kendo.View;
    private billSettingView: kendo.View;
    private calendarSettingsView: kendo.View;
    private validator: kendo.ui.Validator = null;

    public async loadData(e: any): Promise<void> {
        $("#backButtonFTU, #nextButtonFTU, .configCarNavWrapper, .configCarNavNumbersWrapper, #headerFTU, .configNavGradient").hide();
        app.mobileApp.hideLoading();
        try {
            await syncService.initSync();
        } catch (error) {
            // initial sync failed back to login
            Util.setAutoLogin(false);
            app.mobileApp.navigate("views/login.html");
            return;
        }
        const localData = await localDataService.getLocalData();
        this.set("welcomePageVisible", true);
        this.set("headerTitle", "");
        const settings = await companyAppSettingsService.getSettings();
        if (!settings || !settings.IsInitialized) {
            this.set("CanUpdateCompanySettings", localData.CanUpdateCompanySettings);

            this.profileView = Util.renderView("profileTemplate", window.firstTimeProfileModel, $("#firstTimeProfile"));
            this.productView = Util.renderView("productsMainTemplate", window.firstTimeProductManagerModel, $("#firstTimeProductManager"));
            this.billSettingView = Util.renderView("billSettingsTemplate", window.firstTimeBillSettingsModel, $("#firstTimeBillSettings"));
            this.calendarSettingsView = Util.renderView("calendarTemplate", window.firstTimeCalendarSettingsModel, $("#firstTimeCalendarSettings"));

            await window.firstTimeProfileModel.loadData(e);

            window.firstTimeProductManagerModel.set("orphyDriveOne", localData.OrphyDriveOne);
            await window.firstTimeProductManagerModel.loadData(e);

            window.firstTimeBillSettingsModel.set("orphyDriveOne", localData.OrphyDriveOne);
            await window.firstTimeBillSettingsModel.loadData(e);

            await window.firstTimeCalendarSettingsModel.loadData(e);
        } else {
            app.mobileApp.navigate("views/mainView.html");
        }
    }

    public showView(): void {
        this.validator = $("#viewFirstTimeUsage").kendoValidator().data().kendoValidator;
        this.validator.bind("validateInput", Util.validateInput);
        this.validator.validate();
        $("#backButtonFTU, #nextButtonFTU").show();
        $("#headerFTU").find(".globalHeader").show();
        $(".configurationDoneHeader").hide();
        $(".configCarNavIcon").find("img").attr("src", "bundles/css/img/smart.jpg");
        $(".configCarNavWrapper, .configCarNavNumbersWrapper").removeClass("position2 position3 position4 position5");
        $(".configCarNavWrapper, .configCarNavNumbersWrapper").addClass("position1");
        orientationChange.FunctionList = [window.billSettingsModel.createBillPreview];
    }

    public leapConfig = () => {
        if (!this.validator.validate()) {
            return;
        }
        this.set("welcomePageVisible", false);
        this.set("profilePageVisible", false);
        this.set("basicSettingsVisible", false);
        this.set("billSettingsVisible", false);
        this.set("calendarSettingsVisible", false);
        this.set("startOrphyDriveVisible", true);
        $("#backButtonFTU, #nextButtonFTU, .configCarNavNumbersWrapper, .configNavGradient").hide();
        $("#headerFTU").find(".globalHeader").hide();
        $(".configurationDoneHeader, #headerFTU, .configCarNavWrapper").show();
        $(".configCarNavWrapper").removeClass("position1 position2 position3 position4");
        $(".configCarNavIcon").find("img").attr("src", "bundles/css/img/smart-fast.jpg");
        setTimeout(() => {
            $(".configCarNavWrapper").addClass("position5");
        }, 0);
    };

    public next = () => {
        if (!this.get<boolean>("welcomePageVisible") && !this.validator.validate()) {
            return;
        }
        if (this.get<boolean>("welcomePageVisible")) {
            this.set("welcomePageVisible", false);
            this.set("profilePageVisible", true);
            $("#backButtonFTU, #nextButtonFTU, .configCarNavWrapper, .configCarNavNumbersWrapper, #headerFTU, .configNavGradient").show();
            this.set("headerTitle", translation.t("first-time-usage.header-1"));
        } else if (this.get<boolean>("profilePageVisible")) {
            this.set("profilePageVisible", false);
            this.set("basicSettingsVisible", true);
            $(".configCarNavWrapper, .configCarNavNumbersWrapper").removeClass("position1");
            $(".configCarNavWrapper, .configCarNavNumbersWrapper").addClass("position2");
            this.set("headerTitle", translation.t("first-time-usage.header-2"));
        } else if (this.get<boolean>("basicSettingsVisible")) {
            this.set("basicSettingsVisible", false);
            this.set("billSettingsVisible", true);
            $(".configCarNavWrapper, .configCarNavNumbersWrapper").removeClass("position2");
            $(".configCarNavWrapper, .configCarNavNumbersWrapper").addClass("position3");
            this.set("headerTitle", translation.t("first-time-usage.header-3"));
            window.firstTimeBillSettingsModel.createBillPreview();
        } else if (this.get<boolean>("billSettingsVisible")) {
            this.set("billSettingsVisible", false);
            this.set("calendarSettingsVisible", true);
            $(".configCarNavWrapper, .configCarNavNumbersWrapper").removeClass("position3");
            $(".configCarNavWrapper, .configCarNavNumbersWrapper").addClass("position4");
            this.set("headerTitle", translation.t("first-time-usage.header-4"));
        } else if (this.get<boolean>("calendarSettingsVisible")) {
            this.set("calendarSettingsVisible", false);
            this.set("startOrphyDriveVisible", true);
            $("#backButtonFTU, #nextButtonFTU, .configCarNavNumbersWrapper, .configNavGradient").hide();
            $("#headerFTU").find(".globalHeader").hide();
            $(".configurationDoneHeader").show();
            $(".configCarNavWrapper").removeClass("position4");
            $(".configCarNavIcon").find("img").attr("src", "bundles/css/img/smart-fast.jpg");
            $(".configCarNavWrapper").addClass("position5");
        }
        this.validator.validate();
    };

    public getNavPoint(): string {
        throw new Error("Not implemented");
    }

    public back(e?): void {
        if (e) {
            e.preventDefault();
        }
    }

    public saveSettings = async (): Promise<void> => {
        await window.firstTimeProfileModel.save(false);
        await window.firstTimeProductManagerModel.save(false);
        await window.firstTimeBillSettingsModel.save(false);
        await window.firstTimeCalendarSettingsModel.save(false);
        await companyAppSettingsService.updateSettings(new Map<string, boolean>().set("IsInitialized", true));

        Util.destroyView(this.profileView, $("#firstTimeProfile"));
        Util.destroyView(this.productView, $("#firstTimeProductManager"));
        Util.destroyView(this.billSettingView, $("#firstTimeBillSettings"));
        Util.destroyView(this.calendarSettingsView, $("#firstTimeCalendarSettings"));

        app.mobileApp.navigate("views/mainView.html");
    };
}
