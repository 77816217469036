import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import PaymentWay, { IPaymentWay, IPaymentWayDto, PaymentWayType } from "../types/PaymentWay";
import { BaseNoHistoryService } from "./BaseNoHistoryService";
import { companyAppSettingsService } from "./CompanyAppSettingsService";

export default class PaymentWayService extends BaseNoHistoryService<IPaymentWay, IPaymentWayDto> {
    private readonly TABLE_NAME = "PaymentWays";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getApiRoute(): string {
        return `${network.API}/${PaymentWay.EntityTypeId}`;
    }

    public getDtoFields() {
        return keys<IPaymentWayDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IPaymentWay>());
    }

    protected createEntityFromDb(item: IPaymentWay): IPaymentWay {
        const paymentWayEntity = new PaymentWay();
        paymentWayEntity.populateFromDb(item);
        return paymentWayEntity;
    }

    protected createEntityFromOData(item: IPaymentWayDto): IPaymentWay {
        const paymentWayEntity = new PaymentWay();
        paymentWayEntity.populateFromOData(item);
        return paymentWayEntity;
    }

    public async getItems(where: string = null, orderBy: string = null, limit: number = null, select: string = "*", offset: number = null): Promise<IPaymentWay[]> {
        const items = await super.getItems(where, orderBy, limit, select, offset);
        return items.filter(i => i.Type !== PaymentWayType.AdvancePayment && i.Type !== PaymentWayType.DirectDebiting && i.Type !== PaymentWayType.CashOnDelivery);
    }

    public getDefaultPaymentWay = async (): Promise<IPaymentWay> => {
        const items = await this.getItems(`Type = ${PaymentWayType.Invoice}`, null, 1);
        return Util.firstOrDefault(items);
    };

    public async getLastSelectedPaymentWayId(): Promise<number> {
        return (await this.getLastSelectedPaymentWay()).Id;
    }

    public getLastSelectedPaymentWay = async (): Promise<IPaymentWay> => {
        const companyAppSettings = await companyAppSettingsService.getSettings();
        const lastSelectedId = Number(localStorage.getItem("lastSelectedPaymentWayId"));
        if (companyAppSettings.SaveCashPayment && lastSelectedId) {
            return (await this.getItemById(lastSelectedId)) || this.getDefaultPaymentWay();
        }
        return this.getDefaultPaymentWay();
    };

    public setLastSelectedPaymentWayId = (id: number): void => {
        localStorage.setItem("lastSelectedPaymentWayId", id.toString());
    };

    public async getCashPaymentWays(): Promise<IPaymentWay[]> {
        return (await this.getItems()).filter(p => p.Type !== PaymentWayType.Invoice);
    }

    public getPaymentWayByType = async (type: PaymentWayType): Promise<IPaymentWay> => {
        const items = await this.getItems(`Type = ${type}`, null, 1);
        return Util.firstOrDefault(items);
    };
}

export const paymentWayService = new PaymentWayService();
