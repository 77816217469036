// eslint-disable-next-line import/extensions
const config = require("../../../env.js")();

interface ICOnfig {
    Version: string;
    App: string;
    Base_Api: string;
    Api: string;
    Public_Api: string;
    OdataApi_v3: string;
    Idsrv: string;
    Production: boolean;
}

export default class ConfigService {
    get Config(): ICOnfig {
        return config;
    }
}
export const configService = new ConfigService();
