import JobDefaultPosition, { IJobDefaultPosition, IJobDefaultPositionDto } from "./JobDefaultPosition";

export interface IBaseJobActivityPosition {
    ActivityId: number;
}

export interface IJobActivityPositionDto extends IBaseJobActivityPosition, IJobDefaultPositionDto {}
export interface IJobActivityPosition extends IBaseJobActivityPosition, IJobDefaultPosition {}

export default class JobActivityPosition extends JobDefaultPosition<IJobActivityPosition, IJobActivityPositionDto> implements IJobActivityPosition {
    public static TYPE_NAME = "JobActivityPosition";
    public static ODATA_TYPE_NAME = "#SharedDto.PublicApi.JobActivityPositionDtoV2";
    public ActivityId: number;

    public odataTypeName(): string {
        return JobActivityPosition.ODATA_TYPE_NAME;
    }

    public get Discriminator(): string {
        return JobActivityPosition.TYPE_NAME;
    }

    public populateFromDb(dbItem: IJobActivityPosition): void {
        super.populateFromDb(dbItem);
        this.ActivityId = dbItem.ActivityId;
    }

    public populateFromOData(oDataItem: IJobActivityPositionDto): void {
        super.populateFromOData(oDataItem);
        this.ActivityId = oDataItem.ActivityId;
    }

    public toODataObject(): IJobActivityPositionDto {
        const oDataObject = super.toODataObject();
        oDataObject.ActivityId = this.ActivityId;

        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set("ActivityId", this.ActivityId);
        return dbObject;
    }

    public createChargingPosition(parentId: number, amount: number = null): JobActivityPosition {
        const chargingPosition = new JobActivityPosition();
        chargingPosition.copyFrom(this); // Copy common data
        chargingPosition.Amount = amount !== null ? amount : this.Amount;
        chargingPosition.SortOrder = 0;
        chargingPosition.ParentId = parentId;
        chargingPosition.ChargedPositionId = this.Id;
        return chargingPosition;
    }

    protected copyFrom(entity: JobActivityPosition): void {
        super.copyFrom(entity);
        this.ActivityId = entity.ActivityId;
    }
}
