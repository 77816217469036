import ChecklistModel from "../models/ChecklistModel";
import RatingModel from "../models/RatingModel";
import DateTime from "../modules/DateTime";
import { Util } from "../modules/Util";
import { checklistCollectionService } from "../services/ChecklistCollectionService";
import { checklistItemService } from "../services/ChecklistItemService";
import { ratingService } from "../services/ChecklistRatingService";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { drivingTestService } from "../services/DrivingTestService";
import { educationService } from "../services/EducationService";
import { lessonService } from "../services/LessonService";
import { lfaService } from "../services/LfaService";
import { personAddressService } from "../services/PersonAddressService";
import { personContactService } from "../services/PersonContactService";
import { personService } from "../services/PersonService";
import { testService } from "../services/TestService";
import { timeTrackingService } from "../services/TimeTrackingService";
import { translation } from "../services/TranslationService";
import { userService } from "../services/UserService";
import { ILesson } from "../types/Lesson";
import ListExport from "./ListExport";

export default class EducationCardExport extends ListExport {
    public async createExport(args: any[]): Promise<void> {
        const personId: number = args[0];

        let rows = [];
        const sheets: any[] = [];
        const columns: any[] = [{ autoWidth: true }];
        const fileName = `${translation.t("export.education-card-filename-prefix")}-${personId}.xlsx`;
        const colSpan = 1;
        const person = await personService.getItemById(personId);
        const address = await personAddressService.getPersonAddress(person.Id);
        const phoneNumber = await personContactService.getPersonPhone(person.Id);
        const education = await educationService.getSelectedEducation(Util.getEducationId(personId), personId);
        const tree = await checklistItemService.getTreeStructure(education.ChecklistCollectionId);
        const checklistCollectionName = (await checklistCollectionService.getItemById(education.ChecklistCollectionId)).Name;
        const lessons = await lessonService.getPreviousLessons(Util.getEducationId(personId));
        const ratings = await ratingService.getPreviousRatingModels(Util.getEducationId(personId));
        const fullLessonCount = await lessonService.getLessonCount(Util.getEducationId(personId), personId);
        const noMissedLessonCount = await lessonService.getLessonCount(Util.getEducationId(personId), personId);
        const lfa = await lfaService.getLfaModel(education.Id);

        const user = await userService.getItemById(education.ResponsibleDrivingTeacherId);
        const title = `${person.FirstName} ${person.LastName}`;
        rows.push({
            cells: [
                {
                    value: `${translation.t("common.education")} ${person.FirstName} ${person.LastName}, ${checklistCollectionName}`,
                    bold: true,
                    borderLeft: { color: "#000", size: 1 },
                    borderTop: { color: "#000", size: 1 },
                    borderRight: { color: "#000", size: 1 },
                    colSpan: 7
                }
            ]
        });
        rows.push({ cells: [{ borderLeft: { color: "#000", size: 1 }, colSpan: 7, borderRight: { color: "#000", size: 1 } }] });
        rows.push({
            cells: [
                { value: title, bold: true, borderLeft: { color: "#000", size: 1 } },
                { value: translation.t("enter-lessons.amount-appointment"), borderRight: { color: "#000", size: 1 }, colSpan: 6 }
            ]
        });
        rows.push({
            cells: [
                { value: `${address && address.Street ? address.Street : ""} ${address && address.StreetNumber ? address.StreetNumber : ""}`, borderLeft: { color: "#000", size: 1 } },
                { value: lessons.length, bold: true, borderRight: { color: "#000", size: 1 }, colSpan: 6, textAlign: "left" }
            ]
        });
        rows.push({
            cells: [
                { value: `${address && address.ZipCode ? address.ZipCode : ""} ${address && address.City ? address.City : ""}`, borderLeft: { color: "#000", size: 1 } },
                { value: translation.t("completed-driving-lessons.header"), borderRight: { color: "#000", size: 1 }, colSpan: 6 }
            ]
        });
        rows.push({ cells: [{ borderLeft: { color: "#000", size: 1 } }, { value: fullLessonCount, bold: true, borderRight: { color: "#000", size: 1 }, colSpan: 6, textAlign: "left" }] });

        rows.push({
            cells: [
                { value: "", borderLeft: { color: "#000", size: 1 } },
                { value: translation.t("export.lessons-done"), colSpan: 6, borderRight: { color: "#000", size: 1 } }
            ]
        });
        rows.push({
            cells: [
                { value: "", bold: true, borderLeft: { color: "#000", size: 1 } },
                { value: noMissedLessonCount, bold: true, borderRight: { color: "#000", size: 1 }, colSpan: 6, textAlign: "left" }
            ]
        });

        rows.push({
            cells: [
                { value: translation.t("common.phone-number"), borderLeft: { color: "#000", size: 1 } },
                { value: translation.t("common.periode"), colSpan: 6, borderRight: { color: "#000", size: 1 } }
            ]
        });
        rows.push({
            cells: [
                { value: phoneNumber ? phoneNumber.NameInMedia : translation.t("export.no-number"), bold: true, borderLeft: { color: "#000", size: 1 } },
                { value: this.minDateFromLesson(lessons) + this.maxDateFromLessons(lessons), bold: true, borderRight: { color: "#000", size: 1 }, colSpan: 6 }
            ]
        });
        rows.push({ cells: [{ borderLeft: { color: "#000", size: 1 }, colSpan: 7, borderRight: { color: "#000", size: 1 } }] });
        rows.push({
            cells: [
                { value: translation.t("common.birthday"), borderLeft: { color: "#000", size: 1 } },
                { value: translation.t("education-view.lfa-number"), borderRight: { color: "#000", size: 1 }, colSpan: 6 }
            ]
        });
        rows.push({
            cells: [
                {
                    value: person.Birthday && (person.Birthday as Date).getFullYear() !== 400 ? DateTime.parseShortNumberDate(person.Birthday as Date) : translation.t("export.no-birthday"),
                    bold: true,
                    borderLeft: { color: "#000", size: 1 }
                },
                { value: lfa.LfaNumber ? lfa.LfaNumber : translation.t("education-view.no-lfa-number"), bold: true, borderRight: { color: "#000", size: 1 }, colSpan: 6 }
            ]
        });
        rows.push({
            cells: [
                { value: translation.t("lfa-view.ivzv"), borderLeft: { color: "#000", size: 1 } },
                { value: translation.t("common.driving-instructor"), borderRight: { color: "#000", size: 1 }, colSpan: 6 }
            ]
        });
        rows.push({
            cells: [
                { value: lfa.FaberNumber ? lfa.FaberNumber : translation.t("lfa-view.no-ivzv"), bold: true, borderLeft: { color: "#000", size: 1 } },
                { value: user ? user.DisplayName : translation.t("common.unknown"), bold: true, borderRight: { color: "#000", size: 1 }, colSpan: 6 }
            ]
        });
        rows.push({ cells: [{ borderTop: { color: "#000", size: 1 }, colSpan: 7 }] });

        const drivingTest = await drivingTestService.getDrivingTest(education.Id);
        rows.push({ cells: [{ value: `${translation.t("export.education-vku-done-at")}:` }] });
        rows.push({
            cells: [{ value: drivingTest && drivingTest.VkuDoneDate ? DateTime.parseStringDate(drivingTest.VkuDoneDate as Date) : translation.t("export.education-no-vku-done"), bold: true }]
        });
        if (drivingTest) {
            const tests = await testService.getItemsByDrivingTestId(drivingTest.Id);
            let examNumber = 1;
            for (const test of tests) {
                rows.push({ cells: [{ value: `${translation.t("common.test-date")} ${examNumber++}` }] });
                rows.push({ cells: [{ value: test.TestDate ? DateTime.parseStringDateTime(new Date(test.TestDate)) : translation.t("common.test-date-no"), bold: true }] });
            }
        }

        rows.push({ cells: [{ value: "" }] });
        let currentMonth = 0;
        const monthRow = rows.length - 1;
        let lastInsertedMonth = -1;
        rows.push(this.empty(colSpan));
        const lessonCountCells: any[] = [];
        for (const lesson of lessons) {
            const timeTracking = await timeTrackingService.getItemById(lesson.TimeTrackingId);
            let date = null;
            if (timeTracking) {
                date = timeTracking.IssueDate;
            } else {
                date = new Date(lesson.Date);
            }
            currentMonth = new Date(date).getMonth() + 1;
            if (currentMonth !== lastInsertedMonth) {
                rows[monthRow].cells.push({
                    value: DateTime.getNameOfMonth(currentMonth),
                    fontSize: 8
                });
                lastInsertedMonth = currentMonth;
            } else {
                const lastCell = rows[monthRow].cells.length - 1;
                rows[monthRow].cells[lastCell].colSpan = Number.isNaN(rows[monthRow].cells[lastCell].colSpan) ? 2 : rows[monthRow].cells[lastCell].colSpan + 1;
            }
            lessonCountCells.push({ value: lesson.Count, autoWidth: false, width: 20, fontSize: 8, textAlign: "center" });
            rows[rows.length - 1].cells.push({ value: DateTime.parseShortNumberDateOnlyDay(date), fontSize: 8 });
            columns.push({ autoWidth: false, width: 20 });
        }
        for (let i = 0; i < tree.length; i++) {
            if (tree[i].items.length > 0) {
                if (i === 0) {
                    const cells: any[] = [{ value: tree[i].Label, bold: true, colSpan: colSpan }];
                    for (const cell of lessonCountCells) {
                        cells.push(cell);
                    }
                    rows.push({ cells: cells });
                } else {
                    rows.push({ cells: [{ value: tree[i].Label, bold: true, colSpan: colSpan }] });
                }
                this.appendChildren(rows, tree[i].items, ratings, lessons);
            }
        }

        rows = this.addEmptyRows(rows, 7, 1);
        rows.push({ cells: [{ value: `${translation.t("export.education-legend")}:`, bold: true }] });
        const ratingLabels = (await companyAppSettingsService.getSettings()).RatingLabels;
        ratingLabels.forEach((label, index) => {
            rows.push(this.getLegendRow(label, index));
        });

        rows = this.addEmptyRows(rows, 7, 3);
        rows.push({ cells: [{ value: translation.t("export.education-finished") }] });
        rows.push({ cells: [{ value: translation.t("export.education-finished-and-ready") }] });
        rows = this.addEmptyRows(rows, 7, 4);
        rows.push({ cells: [{ colSpan: 7, borderBottom: { color: "#000", size: 1 } }] });
        rows.push({
            cells: [
                { value: translation.t("export.education-footer-1"), colSpan: 1 },
                { value: translation.t("export.education-footer-2"), colspan: 6 }
            ]
        });

        sheets.push({
            columns: columns,
            title: title,
            rows: rows,
            showGridLines: false
        });
        this.openExcel(sheets, fileName);
    }

    private getLegendRow(description: string, ratingValue: number): any {
        return {
            cells: [
                { value: description },
                {
                    background: Util.getRatingColor(ratingValue),
                    borderBottom: { color: "#000000", size: 1 },
                    borderLeft: { color: "#000000", size: 1 },
                    borderTop: { color: "#000000", size: 1 },
                    borderRight: { color: "#000000", size: 1 }
                }
            ]
        };
    }

    private minDateFromLesson(lessons: ILesson[]): string {
        const dates = lessons.map(x => new Date(x.Date));
        if (dates.length > 0) {
            const min = new Date(Math.min.apply(null, dates));
            return `${DateTime.parseShortNumberDate(min)} - `;
        } else {
            return "-";
        }
    }

    private maxDateFromLessons(lessons: ILesson[]): string {
        const dates = lessons.map(x => new Date(x.Date));
        if (dates.length > 0) {
            const max = new Date(Math.max.apply(null, dates));
            return DateTime.parseShortNumberDate(max);
        } else {
            return "";
        }
    }

    private appendChildren(rows: any[], children: ChecklistModel[], ratings: RatingModel[], lessons: ILesson[]): void {
        for (const child of children) {
            const cells: any[] = [{ value: child.Label }];
            for (const lesson of lessons) {
                const rating = ratings.find(r => r.ChecklistItemId === child.Id && r.LessonId === lesson.Id);
                const cellColors = {
                    background: Util.getRatingColor(rating?.RatingValue),
                    borderBottom: { color: "#000000", size: 1 },
                    borderLeft: { color: "#000000", size: 1 },
                    borderTop: { color: "#000000", size: 1 },
                    borderRight: { color: "#000000", size: 1 }
                };
                cells.push(cellColors);
            }
            rows.push({ cells: cells });
            this.appendChildren(rows, child.items, ratings, lessons);
        }
    }
}
