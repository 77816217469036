import { keys } from "ts-transformer-keys";
import RatingModel from "../models/RatingModel";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import ChecklistRating, { IChecklistRating, IChecklistRatingDto } from "../types/ChecklistRating";
import { ILesson } from "../types/Lesson";
import { BaseService } from "./BaseService";
import { lessonService } from "./LessonService";
import { dal } from "../dal/Dal";

export default class ChecklistRatingService extends BaseService<IChecklistRating, IChecklistRatingDto> {
    private readonly TABLE_NAME = "ChecklistRatings";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IChecklistRatingDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IChecklistRating>());
    }

    public getApiRoute(): string {
        return `${network.API}/${ChecklistRating.EntityTypeId}`;
    }

    protected createEntityFromDb(item: IChecklistRating): IChecklistRating {
        const checklistRating = new ChecklistRating();
        checklistRating.populateFromDb(item);
        return checklistRating;
    }

    public createEntityFromOData(item: IChecklistRatingDto): IChecklistRating {
        const checklistRating = new ChecklistRating();
        checklistRating.populateFromOData(item);
        return checklistRating;
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true, false);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE (Id < 0 OR IsDirty = ?) AND IsCurrentRating = ?`;
    }

    protected getNewItems(): Promise<IChecklistRating[]> {
        return this.getItems("Id < 0 AND IsCurrentRating = ? AND IsUpdateRating = ?", null, null, null, null, [false, false]);
    }

    public getByLessonId(lessonId: number): Promise<IChecklistRating[]> {
        return this.getItems(`LessonId = ${lessonId}`);
    }

    public async setUpdateRating(lessonId: number): Promise<void> {
        const sql = `UPDATE ${this.getTableName()} SET IsUpdateRating = ?, OldRatingValue = RatingValue, OldNote = Note WHERE LessonId = ?`;
        await dal.execute(sql, [true, lessonId]);
    }

    public async getUpdateRatings(lessonId: number): Promise<RatingModel[]> {
        const ratings = await this.getItems(`IsUpdateRating = ? AND LessonId = ?`, null, null, null, null, [true, lessonId]);
        return ratings.map(x => new RatingModel(x));
    }

    public async saveUpdateRatings(lessonid: number): Promise<void> {
        const ratingModels = await this.getUpdateRatings(lessonid);
        for (const ratingModel of ratingModels) {
            let isdirty = true;
            if (!ratingModel.RatingValue && !ratingModel.Note) {
                await this.delete(ratingModel.Id);
                continue;
            } else if (ratingModel.OldNote === ratingModel.Note && ratingModel.OldRatingValue === ratingModel.RatingValue) {
                isdirty = null;
            }
            ratingModel.IsUpdateRating = false;
            ratingModel.OldNote = null;
            ratingModel.OldRatingValue = null;
            await this.updateEntity(ratingModel.toEntity(), isdirty);
        }
    }

    public async resetUpdateRatings(lessonId: number) {
        const ratingModels = await this.getUpdateRatings(lessonId);
        for (const ratingModel of ratingModels) {
            if (!ratingModel.OldNote && !ratingModel.OldRatingValue) {
                await this.delete(ratingModel.Id);
                continue;
            }

            ratingModel.IsUpdateRating = false;
            ratingModel.RatingValue = ratingModel.OldRatingValue;
            ratingModel.Note = ratingModel.OldNote;
            ratingModel.OldRatingValue = null;
            ratingModel.OldNote = null;
            await this.updateEntity(ratingModel.toEntity());
        }
    }

    public async addCurrentRatings(educationId: number) {
        const lesson = await lessonService.getCurrentLesson(educationId);
        const currentRatings = await this.getCurrentRatings(lesson.Id);
        for (const currentRating of currentRatings) {
            currentRating.IsCurrentRating = false;
            await this.updateEntity(currentRating, true);
        }
    }

    public async getRatings(educationId: number): Promise<IChecklistRating[]> {
        const lessons = await lessonService.getLessonsByEducationId(educationId);
        const ratings = [];
        for (const l of lessons) {
            ratings.push(await this.getByLessonId(l.Id));
        }
        return ratings.flat();
    }

    public async getRatingsByChapterId(checklistItemId: number, educationId: number): Promise<RatingModel[]> {
        const lessons = await lessonService.getPreviousLessons(educationId);
        const ratings: RatingModel[] = [];
        for (const lesson of lessons) {
            const rating = Util.firstOrDefault(await this.getItems(`LessonId = ${lesson.Id} AND ChecklistItemId = ${checklistItemId}`));
            if (rating) {
                ratings.push(new RatingModel(rating));
            } else {
                ratings.push(null);
            }
        }
        return ratings;
    }

    public async getCurrentRatingModels(lessonId: number): Promise<RatingModel[]> {
        const ratings = await this.getCurrentRatings(lessonId);
        return ratings.map(x => new RatingModel(x));
    }

    private getCurrentRatings(lessonId: number) {
        return this.getItems(`LessonId = ? AND IsCurrentRating = ?`, null, null, null, null, [lessonId, true]);
    }

    public async getPreviousRatingModels(educationId: number): Promise<RatingModel[]> {
        const lessons = await lessonService.getPreviousLessons(educationId);
        const ratings = await this.getItems(`LessonId IN ${Util.joinIds(lessons.map(x => x.Id))}`);
        return ratings
            .map(x => {
                const rating = new RatingModel(x);
                rating.LessonDate = lessons.find(l => l.Id === x.LessonId).Date;
                return rating;
            })
            .sort((a, b) => b.LessonDate.getTime() - a.LessonDate.getTime());
    }

    public async getCurrentNote(checklistItemId: number, educationId: number): Promise<RatingModel> {
        const currentLesson = await lessonService.getCurrentLesson(educationId);
        return this.getRatingModel(currentLesson, checklistItemId);
    }

    public async getUpdateNote(checklistItemId: number, educationId: number): Promise<RatingModel> {
        const updateLesson = await lessonService.getUpdateLesson(educationId);
        const ratingModel = await this.getRatingModel(updateLesson, checklistItemId);
        ratingModel.IsUpdateRating = true;
        ratingModel.IsCurrentRating = false;
        return ratingModel;
    }

    public async getPreviousNotes(checklistItemId: number, educationId: number): Promise<RatingModel[]> {
        const previousLessons = await lessonService.getPreviousLessons(educationId);
        const previousRatings: RatingModel[] = [];
        for (const lesson of previousLessons) {
            const ratingModel = await this.getRatingModel(lesson, checklistItemId);
            if (ratingModel.Note) {
                previousRatings.push(ratingModel);
            }
        }
        return previousRatings;
    }

    private async getRatingModel(lesson: ILesson, checklistItemId: number): Promise<RatingModel> {
        const current = Util.firstOrDefault(await this.getItems(`LessonId = ${lesson.Id} AND ChecklistItemId = ${checklistItemId}`));
        const ratingModel = current ? new RatingModel(current) : new RatingModel();
        ratingModel.LessonDate = lesson.Date;
        ratingModel.ChecklistItemId = checklistItemId;
        ratingModel.LessonId = lesson.Id;
        return ratingModel;
    }
}

export const ratingService = new ChecklistRatingService();
