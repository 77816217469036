import { IDal } from "../IDal";
import { Util } from "../../modules/Util";
import Transaction from "./Transaction";
import { logger } from "../../modules/Logger";
import { ReturnValueEnum, RowModeEnum, SqliteClient, SqliteClientTypeEnum } from "@magieno/sqlite-client";
import { network } from "../../modules/Network";
import Migration from "../Migration";
import PromptContent from "../../modules/PromptContent";

export default class WasmDal implements IDal {
    private db: SqliteClient;
    private dbName: string;

    async load(dbName: string): Promise<void> {
        this.dbName = dbName;
        if (!Util.isSimulator() && Util.isBrowser()) {
            if (!localStorage.getItem("acceptedBrowserNotSupported")) {
                app.mobileApp.hideLoading();
                const answer = await Util.confirmTemplate(PromptContent.notSupported(), "Akzeptieren", "", true);
                if (answer.confirmed) {
                    localStorage.setItem("acceptedBrowserNotSupported", "accepted");
                } else {
                    throw new Error("Failed to load");
                }
                app.mobileApp.showLoading();
            }
        }

        this.db = new SqliteClient({
            type: SqliteClientTypeEnum.OpfsSahWorker,
            filename: dbName,
            sqliteWorkerPath: "./externalLibraries/sqlite-client/dist/bundle/sqlite-client-worker.js"
        });

        await this.db.init();
        const migrator = new Migration(this);
        await migrator.migrate();
        window.dal = this;
    }

    async firstOrDefault<T>(sql: string, params?: any[]): Promise<T> {
        return Util.firstOrDefault(await this.executeRead(sql, params));
    }

    async execute(sql: string, params?: any[]): Promise<any[]> {
        if (!this.dbName) {
            await this.load(`OD_${network.getCompanyId()}_${network.getUserId()}`);
        }
        return this.db.executeSql(sql, params, ReturnValueEnum.ResultRows, RowModeEnum.Object);
    }

    executeRead(sql: string, params?: any[]): Promise<any[]> {
        return this.execute(sql, params);
    }

    async withTransaction(transactionCallback: (transaction: Transaction) => void): Promise<void> {
        await this.db.executeSql("BEGIN TRANSACTION;");
        try {
            const transactions = new Transaction();
            transactionCallback(transactions);
            for (const statement of transactions.getStatements()) {
                await this.db.executeSql(statement.statement, statement.params, ReturnValueEnum.SaveSql, RowModeEnum.Object);
            }
            await this.db.executeSql("COMMIT;");
        } catch (error) {
            await this.db.executeSql("ROLLBACK;");
            logger.logError(error);
        }
    }

    isReady(): boolean {
        return !!this.dbName;
    }

    async closeDb(): Promise<void> {
        // do nothing
    }

    getDbName(): string {
        return this.dbName;
    }
}
