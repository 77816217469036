import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import Profile, { IProfile, IProfileDto } from "../types/Profile";
import { SettingsService } from "./SettingsService";
import { nameof } from "ts-simple-nameof";

export default class ProfileService extends SettingsService<IProfile, IProfileDto> {
    private readonly TABLE_NAME = "Profiles";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IProfileDto>().filter(x => x !== "Id");
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IProfile>());
    }

    protected canSkipAndTop(): boolean {
        return false;
    }

    protected valueModifier(key: string, value: any): any {
        if (key === nameof<Profile>(x => x.Sex)) {
            switch (value) {
                case "Male":
                    return 1;
                case "Female":
                    return 2;
                case "Unspecified":
                default:
                    return 0;
            }
        }
        return value;
    }

    public getApiRoute(): string {
        return `${network.ODATA_API_V3}/GetSubscriptionAddress`;
    }

    protected getModifiedSinceODataRoute() {
        return `${network.ODATA_API_V3}/GetSubscriptionAddressChangesSince`;
    }

    protected getPatchODataRoute() {
        return `${network.ODATA_API_V3}/PatchSubscriptionAddress`;
    }

    protected createEntityFromOData(item: IProfileDto): IProfile {
        const entity = new Profile();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: IProfile): IProfile {
        const entity = new Profile();
        entity.populateFromDb(item);
        return entity;
    }

    public async getSettings(): Promise<IProfile> {
        const companysettings = await this.getItems();
        return Util.firstOrDefault(companysettings);
    }

    public async updateSettings(updateObject: Map<string, any>, isDirty: boolean = true): Promise<void> {
        await this.updateWhere(null, updateObject, isDirty);
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE IsDirty = ?`;
    }
}

export const profileService = new ProfileService();
