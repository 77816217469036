import { nameof } from "ts-simple-nameof";
import ChecklistPricingModel from "../models/ChecklistPricingModel";
import { Util } from "../modules/Util";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";
import { translation } from "../services/TranslationService";

export interface UserWorkingHours {
    UserId: number;
    UserPersonId: number;
    WeeklyWorkingHours: number;
}

interface IBaseCompanyAppSettings {
    PaymentConditionId: number;
    LessonDuration: number;
    PricePerLesson: number;
    ChecklistPricing: ChecklistPricingModel[];
    LockEditLesson: boolean;
    LessonEditLockDays: number;
    SaveCashPayment: boolean;
    IsInitialized: boolean;
    RatingLabels: string[];
    WeeklyWorkingHours: UserWorkingHours[];
    SendBills: boolean;
    ForceEasyAcccounting: boolean;
    DisablePriceChange: boolean;
}

export interface ICompanyAppSettingsDto extends IBaseDto, IBaseCompanyAppSettings {}
export interface ICompanyAppSettings extends IBaseEntity, IBaseCompanyAppSettings {}

export default class CompanyAppSettings extends BaseEntity<ICompanyAppSettings, ICompanyAppSettingsDto> implements ICompanyAppSettings {
    public PaymentConditionId: number;
    public LessonDuration: number;
    public PricePerLesson: number;
    public ChecklistPricing: ChecklistPricingModel[];
    public LockEditLesson: boolean;
    public LessonEditLockDays: number;
    public SaveCashPayment: boolean;
    public IsInitialized: boolean;
    public RatingLabels: string[];
    public WeeklyWorkingHours: UserWorkingHours[];
    public SendBills: boolean;
    public ForceEasyAcccounting: boolean;
    public DisablePriceChange: boolean;

    public populateFromDb(dbItem: ICompanyAppSettings): void {
        super.populateFromDb(dbItem);
        this.PaymentConditionId = dbItem.PaymentConditionId;
        this.LessonDuration = dbItem.LessonDuration;
        this.PricePerLesson = dbItem.PricePerLesson;
        this.IsInitialized = this.getDbBoolean(dbItem.IsInitialized);
        const clp = JSON.parse(dbItem.ChecklistPricing as unknown as string);
        this.ChecklistPricing = clp || [];
        this.SaveCashPayment = this.getDbBoolean(dbItem.SaveCashPayment);
        this.LockEditLesson = this.getDbBoolean(dbItem.LockEditLesson);
        this.LessonEditLockDays = dbItem.LessonEditLockDays;
        try {
            this.RatingLabels = JSON.parse(dbItem.RatingLabels as unknown as string);
        } catch (e) {
            this.RatingLabels = [];
        }
        try {
            this.WeeklyWorkingHours = JSON.parse(dbItem.WeeklyWorkingHours as unknown as string);
        } catch (e) {
            this.WeeklyWorkingHours = [];
        }
        this.SendBills = this.getDbBoolean(dbItem.SendBills);
        this.ForceEasyAcccounting = this.getDbBoolean(dbItem.ForceEasyAcccounting);
        this.DisablePriceChange = this.getDbBoolean(dbItem.DisablePriceChange);
    }

    public populateFromOData(oDataItem: ICompanyAppSettingsDto): void {
        let data: ICompanyAppSettingsDto = null;
        try {
            data = JSON.parse((oDataItem as any).value);
        } catch (e) {
            // companyappsettings has no values
        }
        this.PaymentConditionId = data?.PaymentConditionId || null;
        this.LessonDuration = data?.LessonDuration || 45;
        this.PricePerLesson = data?.PricePerLesson || 100;
        this.IsInitialized = typeof data?.IsInitialized === "boolean" ? data.IsInitialized : null;
        this.ChecklistPricing = data?.ChecklistPricing || [];
        this.SaveCashPayment = data?.SaveCashPayment || false;
        this.LockEditLesson = data?.LockEditLesson || false;
        this.LessonEditLockDays = data?.LessonEditLockDays || 1;
        if (data?.RatingLabels) {
            this.RatingLabels = data.RatingLabels;
        } else {
            this.RatingLabels = [
                translation.t("ratings.no"),
                translation.t("ratings.shown"),
                translation.t("ratings.learned"),
                translation.t("ratings.unsure"),
                translation.t("ratings.self"),
                translation.t("ratings.good")
            ];
        }
        this.WeeklyWorkingHours = data?.WeeklyWorkingHours || [];
        this.SendBills = typeof data?.SendBills === "boolean" ? data.SendBills : true;
        this.ForceEasyAcccounting = data?.ForceEasyAcccounting || false;
        this.DisablePriceChange = data?.DisablePriceChange || false;
    }

    // TODO: OR-892
    public toODataObject(): ICompanyAppSettingsDto {
        // const oDataObject = super.toODataObject(); // No base call!
        const oDataObject = { appId: Util.getAppGUID(), key: CompanyAppSettings.getCompanyAppSettingsKey(), value: "" };
        const value = {
            PaymentConditionId: this.PaymentConditionId,
            LessonDuration: this.LessonDuration,
            PricePerLesson: this.PricePerLesson,
            IsInitialized: this.IsInitialized,
            ChecklistPricing: this.ChecklistPricing,
            SaveCashPayment: this.SaveCashPayment,
            LockEditLesson: this.LockEditLesson,
            LessonEditLockDays: this.LessonEditLockDays,
            RatingLabels: this.RatingLabels,
            WeeklyWorkingHours: this.WeeklyWorkingHours,
            SendBills: this.SendBills,
            ForceEasyAcccounting: this.ForceEasyAcccounting,
            DisablePriceChange: this.DisablePriceChange
        };
        oDataObject.value = JSON.stringify(value);
        return oDataObject as any;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.Id),
            1
        ); // Override Id with 1 (only one entity)
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.PaymentConditionId),
            this.PaymentConditionId
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.LessonDuration),
            this.LessonDuration
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.PricePerLesson),
            this.PricePerLesson
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.IsInitialized),
            this.IsInitialized
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.ChecklistPricing),
            JSON.stringify(this.ChecklistPricing)
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.SaveCashPayment),
            this.SaveCashPayment
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.LockEditLesson),
            this.LockEditLesson
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.LessonEditLockDays),
            this.LessonEditLockDays
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.RatingLabels),
            JSON.stringify(this.RatingLabels)
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.WeeklyWorkingHours),
            JSON.stringify(this.WeeklyWorkingHours)
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.SendBills),
            this.SendBills
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.ForceEasyAcccounting),
            this.ForceEasyAcccounting
        );
        dbObject.set(
            nameof<CompanyAppSettings>(x => x.DisablePriceChange),
            this.DisablePriceChange
        );
        return dbObject;
    }

    public static getCompanyAppSettingsKey() {
        return "CompanySettings";
    }
}
