import ImageItem from "../models/ImageItemModel";
import { Util } from "../modules/Util";
import { BaseView } from "./BaseView";
import { v4 as uuidv4 } from "uuid";

// eslint-disable-next-line import/prefer-default-export
export abstract class ImageGalleryView extends BaseView {
    public content: string;

    protected abstract getImageSelector(): string;
    protected abstract thisNavPoint(): string;
    protected abstract getChapterId(): number;

    private readonly ONE_PIXEL_IMAGE = "data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=";
    private imageItems: ImageItem[];
    private imageGallery: any;

    public initializeBinding(content: string) {
        this.set("content", "");
        this.set("content", content);
    }

    public loadImages = (content: string) => {
        this.initializeBinding(content);
        this.imageItems = [];
        $(this.getImageSelector())
            .find("img")
            .each((index, elem) => {
                $(elem).data("index", index);
                if (Util.isBrowser()) {
                    $(elem).attr("crossorigin", "anonymous");
                }
            });

        $(this.getImageSelector())
            .find("img")
            .on("load", event => {
                this.imageLoaded(event);
            });

        $(this.getImageSelector())
            .find("img")
            .on("error", event => {
                const element = $(event.target);
                if (Util.isiOS() && event && event.target && (event.target as any).src === this.ONE_PIXEL_IMAGE) {
                    element.attr("alt", "");
                    element.width(0);
                } else {
                    element.attr("src", `bundles/css/img/noimage.png`);
                }
            });
    };

    public openGallery = (e: Event) => {
        const index = parseInt($(e.target).data("index") as any);
        const pswpElement = document.querySelectorAll(".pswp")[0];
        const options = { index: index, shareEl: false, arrowEl: true, maxSpreadZoom: 5, showAnimationDuration: 0, hideAnimationDuration: 0 };
        this.imageGallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, this.imageItems, options);
        this.imageGallery.init();
        this.removeBackButtonEvent();
        document.addEventListener("backbutton", this.closeGallery, false);
        $(".pswp__button.pswp__button--arrow--left").css("visibility", "visible");
        $(".pswp__button.pswp__button--arrow--right").css("visibility", "visible");
    };

    public closeGallery = () => {
        this.imageGallery.close();
        document.removeEventListener("backbutton", this.closeGallery, false);
        this.addBackButtonEvent();
    };

    public hideView(): void {
        this.set("content", "");
    }

    private imageLoaded = (event: JQueryEventObject) => {
        const image = $(event.target);
        if (!image.attr("src").includes("bundles/css/img/noimage.png")) {
            const index = parseInt(image.data("index").toString());
            image.on("click", this.openGallery);
            const id = uuidv4();
            image.attr("id", id);
            image.wrap("<div class='wrappedMyDriveGalleryPicture'></div>");
            const button = $('<button class="iconOnly iconOnlyOutline iconOnlyFilled iconBtnLarge"><i class="icon-paint-brush icons"></i></button>');
            image.after(button);
            button.on("click", () => {
                this.openDrawing(image);
            });
            this.imageItems[index] = new ImageItem(image);
        }
    };

    private openDrawing = (image: JQuery) => {
        window.drawingImage = image.attr("src");
        app.mobileApp.navigate(`views/drawing.html?name=${this.getChapterId()}-${$(image).data("index")}&navPoint=${this.thisNavPoint()}`);
    };
}
