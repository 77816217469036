import { salutationService } from "../services/SalutationService";
import Person, { IPerson, Gender } from "../types/Person";
import BaseModel from "./BaseModel";

export default class PersonModel extends BaseModel {
    public Sex: Gender;
    public SalutationId: number;
    public FirstName: string;
    public LastName: string;
    public Birthday: Date | string;
    public Avatar: string;
    public Profession: string;
    public MeetingPointAddress: string;
    public IsInactive: boolean;

    constructor(person: IPerson = new Person()) {
        super(person);
        this.Sex = person.Sex || 0;
        this.SalutationId = person.SalutationId || salutationService.getDefaultSalutationId();
        this.FirstName = person.FirstName;
        this.LastName = person.LastName;
        this.Birthday = person.Birthday;
        this.Avatar = person.Avatar;
        this.Profession = person.Profession;
        this.IsInactive = person.IsInactive || false;
        super.init(this);
    }

    public toEntity(person: IPerson = new Person()): IPerson {
        super.toEntity(person);
        person.Sex = this.Sex;
        person.SalutationId = this.SalutationId;
        person.FirstName = this.FirstName;
        person.LastName = this.LastName;
        person.Birthday = this.Birthday;
        person.Avatar = this.Avatar;
        person.Profession = this.Profession;
        person.IsInactive = this.IsInactive;
        return person;
    }
}
