import EducationModel from "../models/EducationModel";
import ConfirmContent from "../modules/ConfirmContent";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { checklistCollectionService } from "../services/ChecklistCollectionService";
import { educationService } from "../services/EducationService";
import { personService } from "../services/PersonService";
import { translation } from "../services/TranslationService";
import { userService } from "../services/UserService";
import { IChecklistCollection } from "../types/ChecklistCollection";
import Education from "../types/Education";
import { BaseView } from "./BaseView";

export default class EducationView extends BaseView {
    private readonly educationModel = "model";
    private validator: kendo.ui.Validator;
    private navPoint: string;

    public initView(): void {
        this.validator = $("#contentEducation").kendoValidator().data().kendoValidator;
        this.validator.bind("validateInput", Util.validateInput);
    }

    public async loadData(e: any): Promise<void> {
        const education = e.view.params.addEducation !== "true" ? await educationService.getSelectedEducation(Util.getEducationId(this.personId), this.personId) : null;
        this.navPoint = e.view.params.navPoint ? e.view.params.navPoint : "views/allPersons.html";

        this.set("hasNoEducations", !(await educationService.personHasEducation(this.personId)));

        const users = await userService.getItems();
        const checklistCollections = (await checklistCollectionService.getItems()).filter(x => x.Id > 0);
        this.set("responsibleDrivingTeacher", users);
        this.set("checklistCollections", checklistCollections);

        this.set("responsibleDrivingTeacherVisible", users.length > 1);
        this.set("checklistCollectionsVisible", checklistCollections.length > 1);

        if (education) {
            this.set(this.educationModel, new EducationModel(education));
            this.set("checklistSwitchEnabled", !checklistCollections.find(x => x.Id === education.ChecklistCollectionId));
        } else {
            this.set("checklistSwitchEnabled", true);

            const personEducations = await educationService.getEducationsByPersonId(this.personId);
            this.set(
                "checklistCollections",
                checklistCollections.filter(x => !personEducations.some(edu => edu.ChecklistCollectionId === x.Id))
            );

            const newEducation = new Education();
            newEducation.PersonId = this.personId;

            newEducation.ResponsibleDrivingTeacherId = network.getUserId();
            newEducation.ChecklistCollectionId = Util.firstOrDefault(this.get<IChecklistCollection[]>("checklistCollections")).Id;

            this.set(this.educationModel, new EducationModel(newEducation));
            if (checklistCollections.length === 1) {
                await this.saveOrUpdate();
            }
        }
    }

    public async saveOrUpdate() {
        if (!this.validator.validate()) {
            return;
        }

        const education = this.get<EducationModel>(this.educationModel).toEntity();

        if (!education.PersonId) {
            // can't be null
            app.mobileApp.navigate("views/allPersons.html");
            return;
        }

        if (education.Id) {
            await educationService.updateEntity(education, true);
        } else {
            const id = await educationService.insert(education);
            Util.setEducationId(id, this.personId);
        }
        app.mobileApp.navigate("views/personDetail.html");
    }

    public async deleteStudent() {
        const person = await personService.getItemById(this.personId, "FirstName, LastName");
        const { confirmed } = await Util.confirmTemplate(ConfirmContent.deleteDrivingStudent(`${person.FirstName} ${person.LastName}`), translation.t("util.yes-delete-finaly"), translation.t("common.cancel"));
        if (confirmed) {
            await personService.delete(this.personId);
            this.back();
        }
    }

    public getNavPoint(): string {
        return this.navPoint;
    }
}
