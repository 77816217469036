import JobPositionModel, { JobPositionItemState } from "../models/JobPositionModel";
import { logger } from "../modules/Logger";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { BillStatus, IBill } from "../types/Bill";
import JobActivityPosition from "../types/JobActivityPositionEntity";
import JobDefaultPosition, { IJobDefaultPosition } from "../types/JobDefaultPosition";
import { IJobPosition, IJobPositionDto, JobPosition } from "../types/JobPosition";
import { IJobPricePosition, IJobPricePositionDto, JobPricePosition } from "../types/JobPricePosition";
import JobProductPosition, { IJobProductPosition } from "../types/JobProductPosition";
import JobSeparatorPosition from "../types/JobSeparatorPosition";
import JobSubTotalPosition from "../types/JobSubTotalPosition";
import JobTextPosition from "../types/JobTextPosition";
import JobTotalPosition from "../types/JobTotalPosition";
import { PaymentWayType } from "../types/PaymentWay";
import TimeTracking from "../types/TimeTracking";
import { BaseService } from "./BaseService";
import { billService } from "./BillService";
import { companyAppSettingsService } from "./CompanyAppSettingsService";
import { conditionService } from "./ConditionService";
import { jobService } from "./JobService";
import { lessonService } from "./LessonService";
import { paymentWayService } from "./PaymentWayService";
import { personAddressService } from "./PersonAddressService";
import { productService } from "./ProductService";
import { timeTrackingService } from "./TimeTrackingService";
import { unitService } from "./UnitService";
import { userSettingsService } from "./UserSettingsService";
import { CreateBillModelType } from "../viewModels/CreateBill";
import { personService } from "./PersonService";
import DateTime from "../modules/DateTime";
import { orphyDriveJobPositionService } from "./OrphyDriveJobPositionService";
import { OrphyDriveJobPosition } from "../types/OrphyDriveJobPosition";
import { educationService } from "./EducationService";
import { keys } from "ts-transformer-keys";
import { IProduct } from "../types/Product";
import { nameof } from "ts-simple-nameof";
import { checklistCollectionService } from "./ChecklistCollectionService";
import { translation } from "./TranslationService";
import { dal } from "../dal/Dal";

export default class JobPositionService extends BaseService<IJobPosition, IJobPositionDto> {
    private readonly TABLE_NAME = "JobPositions";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IJobPositionDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IJobPosition>()).filter(
            x => x !== nameof<IJobPosition>(n => n.Parent) && x !== nameof<IJobPosition>(n => n.Children) && x !== nameof<IJobPosition>(n => n.createChargingPosition)
        );
    }

    public getApiRoute(): string {
        return `${network.API}/${JobPosition.EntityTypeId}`;
    }

    protected getPostRoute(): string {
        return `${network.ODATA_API_V3}/JobPosition`;
    }

    protected getPatchRoute(id: number): string {
        return `${this.getPostRoute()}(${id})`;
    }

    protected createEntityFromDb(item: JobPosition<IJobPosition, IJobPositionDto>): JobPosition<IJobPosition, IJobPositionDto> {
        const entity: JobPosition<IJobPosition, IJobPositionDto> = this.createPositionByTypeName(item.Discriminator);
        if (entity) {
            entity.populateFromDb(item);
        }
        return entity;
    }

    public createEntityFromOData(item: any): JobPosition<IJobPosition, IJobPositionDto> {
        const entity: JobPosition<IJobPosition, IJobPositionDto> = item["@odata.type"] ? this.createPositionByODataTypeName(item["@odata.type"]) : this.createPositionByTypeName(item.Discriminator);
        if (entity) {
            entity.populateFromOData(item);
        }
        return entity;
    }

    protected localItemSelect(): string {
        return "Id, Discriminator";
    }

    public async productInUse(productId: number): Promise<boolean> {
        return (await this.getItems(`ProductId = ${productId}`)).length > 0;
    }

    public async getAvailableBalance(educationId: number, personId: number): Promise<number> {
        const education = await educationService.getSelectedEducation(educationId, personId);
        const jobTotalPosition = await this.getJobPositions(education.JobId);
        const positions: { Id: number; Amount: number }[] = jobTotalPosition.Children.map(jobposition => {
            if (jobposition.Discriminator === JobDefaultPosition.TYPE_NAME || jobposition.Discriminator === JobProductPosition.TYPE_NAME) {
                return { Id: jobposition.Id, Amount: jobposition.Amount };
            }
            return null;
        }).filter(x => x);

        const availableLessons = await orphyDriveJobPositionService.getItems(`JobPositionId IN ${Util.joinIds(positions.map(x => x.Id))}`);
        const availableLessonCount = availableLessons.reduce((acc, ojp) => {
            const position = positions.find(x => x.Id === ojp.JobPositionId);
            if (position) {
                return acc + position.Amount * ojp.LessonCount;
            }
            return acc + 0;
        }, 0);
        const lessons = await lessonService.getLessonsByEducationId(educationId);
        const usedLessons = lessons.reduce((acc, l) => acc + l.Count, 0);
        return availableLessonCount - usedLessons;
    }

    public async isPositionCharged(positionId: number): Promise<boolean> {
        const position = Util.firstOrDefault(await this.getItems(`ChargedPositionId = ${positionId}`));
        return position && (position.Discriminator === JobDefaultPosition.TYPE_NAME || position.Discriminator === JobProductPosition.TYPE_NAME);
    }

    public async isLessonPosition(positionId: number): Promise<boolean> {
        const defaultLessonProduct = await productService.getDefaultLessonProduct();
        const jobposition = (await this.getItemById(positionId)) as IJobProductPosition;
        return jobposition && jobposition.ProductId === defaultLessonProduct.Id;
    }

    public async getProductPosition(positionId: number): Promise<IJobProductPosition> {
        const productPosition = await this.getItemById(positionId);
        if (productPosition.Discriminator === JobProductPosition.TYPE_NAME) {
            return productPosition as IJobProductPosition;
        }
        return null;
    }

    public async getProductPositions(positionIds: number[]): Promise<IJobProductPosition[]> {
        const productPositions = await this.getItemsByIds(positionIds);
        return productPositions.filter(x => x.Discriminator === JobProductPosition.TYPE_NAME).map(x => x as IJobProductPosition);
    }

    public async pushItems(): Promise<number> {
        const pushedCount = await this.pushJobPositionItems(0);
        if (pushedCount === 0) {
            await billService.sendBills();
            return pushedCount;
        } else {
            return pushedCount;
        }
    }

    private pushJobPositionItems = async (previouslyPushedItems: number): Promise<number> => {
        if (!previouslyPushedItems) {
            previouslyPushedItems = 0;
        }
        // including deleted items for this push
        const items = await this.getItemsIncludingDeleted("Id < 0 AND (ParentId IS NULL OR ParentId > 0) AND (ChargedPositionId IS NULL OR ChargedPositionId > 0)");
        const pushedItems = await this.processNewItems(items);
        if (pushedItems > 0) {
            return this.pushJobPositionItems(pushedItems + previouslyPushedItems);
        } else {
            const patchedItems = await this.patchItems();
            return patchedItems + previouslyPushedItems;
        }
    };

    protected getIdStatement(): string {
        return `SELECT MIN((SELECT MIN(0, COALESCE(MIN(Id), 0)) - 1 FROM ${billService.getTableName()}), (SELECT MIN(0, COALESCE(MIN(Id), 0)) - 1 FROM ${jobService.getTableName()}), (SELECT MIN(0, COALESCE(MIN(Id), 0)) - 1 FROM ${this.getTableName()})) AS Id`;
    }

    private createPositionByTypeName(oDataTypeName: string) {
        switch (oDataTypeName) {
            case JobDefaultPosition.TYPE_NAME:
                return new JobDefaultPosition();
            case JobProductPosition.TYPE_NAME:
                return new JobProductPosition();
            case JobActivityPosition.TYPE_NAME:
                return new JobActivityPosition();
            case JobTextPosition.TYPE_NAME:
                return new JobTextPosition();
            case JobSeparatorPosition.TYPE_NAME:
                return new JobSeparatorPosition();
            case JobSubTotalPosition.TYPE_NAME:
                return new JobSubTotalPosition();
            case JobTotalPosition.TYPE_NAME:
                return new JobTotalPosition();
            default:
                return null;
        }
    }

    private createPositionByODataTypeName(oDataTypeName: string) {
        switch (oDataTypeName) {
            case JobDefaultPosition.ODATA_TYPE_NAME:
                return new JobDefaultPosition();
            case JobProductPosition.ODATA_TYPE_NAME:
                return new JobProductPosition();
            case JobActivityPosition.ODATA_TYPE_NAME:
                return new JobActivityPosition();
            case JobTextPosition.ODATA_TYPE_NAME:
                return new JobTextPosition();
            case JobSeparatorPosition.ODATA_TYPE_NAME:
                return new JobSeparatorPosition();
            case JobSubTotalPosition.ODATA_TYPE_NAME:
                return new JobSubTotalPosition();
            case JobTotalPosition.ODATA_TYPE_NAME:
                return new JobTotalPosition();
            default:
                return null;
        }
    }

    public async updateJobBaseForeignKey(oldId: number, newId: number): Promise<void> {
        await this.updateDependencies(oldId, newId, true);
    }

    public async updateProductForeignKey(oldProductId: number, newProductId: number): Promise<void> {
        await this.update("ProductId", oldProductId, new Map<string, any>().set("ProductId", newProductId), true);
    }

    public async updateActivityForeignKey(oldActivityId: number, newActivityId: number): Promise<void> {
        return this.update("ActivityId", oldActivityId, new Map<string, any>().set("ActivityId", newActivityId), true);
    }

    public async updateDependencies(oldId: number, newId: number, updateSelf: boolean): Promise<void> {
        await super.updateDependencies(oldId, newId, updateSelf);
        await this.update("ParentId", oldId, new Map<string, any>().set("ParentId", newId), true);
        await this.update("ChargedPositionId", oldId, new Map<string, any>().set("ChargedPositionId", newId), true);
        await orphyDriveJobPositionService.updateJobpositionId(oldId, newId);
    }

    public async getAllPositionsByEducationId(educationId: number): Promise<JobPositionModel[]> {
        const education = await educationService.getItemById(educationId);
        const job = await jobService.getItemById(education.JobId);

        const timetrackings = await timeTrackingService.getTimetrackingsWithTwinLessonByEducationId(educationId);

        const totalPosition = await this.getJobPositions(job.Id);
        const lessonProduct = await productService.getDefaultLessonProduct();
        const paymentFlowProduct = await productService.getDefaultPaymentFlowProduct();
        const jobPositionModels = (await this.getJobPositionItems(totalPosition, timetrackings, lessonProduct, paymentFlowProduct)).sort(this.sortJobItems);
        for (const model of jobPositionModels) {
            model.JobId = totalPosition.Id;
            await this.populateBillingData(model);
        }
        const unsorted = jobPositionModels.reduce((prev, current) => prev.concat(current), []) as JobPositionModel[];
        const allowBilling = unsorted.filter(x => x.AllowBilling && !x.IsPaymentFlowPosition);
        const noBilling = unsorted.filter(x => !x.AllowBilling);

        return allowBilling.concat(noBilling);
    }

    private sortJobItems = (a: JobPositionModel, b: JobPositionModel) => {
        if (a.PositionId < 0 && b.PositionId < 0) {
            return a.PositionId - b.PositionId;
        } else if (a.PositionId < 0) {
            return -1;
        } else if (b.PositionId < 0) {
            return 1;
        } else {
            return b.PositionId - a.PositionId;
        }
    };

    public async accountPositions(
        educationId: number,
        personId: number,
        positionIds: number[],
        paymentWayId: number = null,
        isPaid: boolean = false,
        billDate: Date,
        paidAt: Date,
        createReceipt: boolean = false
    ): Promise<void> {
        if (!positionIds || positionIds.length === 0) {
            return;
        }
        const education = await educationService.getSelectedEducation(educationId, personId);
        const checklistCollection = await checklistCollectionService.getItemById(education.ChecklistCollectionId);
        const usersettings = await userSettingsService.getSettings();
        const companyAppSettings = await companyAppSettingsService.getSettings();
        const job = await jobService.getItemById(education.JobId);
        const person = await personService.getItemById(education.PersonId, "Id, FirstName, LastName");
        const personAddress = await personAddressService.getPersonAddress(education.PersonId);
        const bill = job.createBill(billDate, paidAt, companyAppSettings.PaymentConditionId, checklistCollection ? checklistCollection.Name : "", paymentWayId);
        if (personAddress) {
            bill.Anschrift = `${person.FirstName} ${person.LastName}<br />${personAddress.Street ? personAddress.Street : ""} ${personAddress.StreetNumber ? personAddress.StreetNumber : ""}<br />${
                personAddress.ZipCode ? personAddress.ZipCode : ""
            } ${personAddress.City ? personAddress.City : ""}`;
        }

        if (createReceipt) {
            bill.Name = bill.Name.split(translation.t("job-position-service.bill")).join(translation.t("job-position-service.replace-bill-with"));
            bill.DokumentKopfZeile = translation.t("account-lesson.receipt-head");
        } else {
            bill.DokumentKopfZeile = usersettings.Dokument_Rechnung_Kopfzeile;
        }

        bill.DocumentAfterList = usersettings.Dokument_Rechnung_Konditionen;
        bill.DokumentFussZeile = usersettings.Dokument_Rechnung_Fusszeile;
        bill.ConditionId = companyAppSettings.PaymentConditionId;
        if (isPaid) {
            bill.BillStatus = BillStatus.Paid;
        }
        const billId = await billService.insert(bill);
        const totalPosition = new JobTotalPosition();
        totalPosition.Id = billId;
        const totalPositionId = await this.insert(totalPosition);
        let positions = await this.getItems(`Id IN ${Util.joinIds(positionIds)}`);
        positions = this.sortPositionsAfterIds(positions, positionIds);
        for (let i = 0; i < positions.length; i++) {
            const position = positions[i];
            if (position.Discriminator === JobDefaultPosition.TYPE_NAME || position.Discriminator === JobProductPosition.TYPE_NAME) {
                const orphyDriveJobPosition = await orphyDriveJobPositionService.getItemByPositionId(position.Id);
                if (orphyDriveJobPosition) {
                    const timetracking = await timeTrackingService.getItemById(orphyDriveJobPosition.TimeTrackingId);
                    if (timetracking) {
                        position.Position = translation.t("account-lesson.lesson-of", {
                            lessonString: translation.t("account-lesson.account-lesson-name-no-ammount", { count: (position as IJobProductPosition | IJobDefaultPosition).Amount }),
                            lessonDate: DateTime.parseNumberDateTime(timetracking.IssueDate as Date)
                        });
                    }
                }
            }

            const chargingPosition = position.createChargingPosition(totalPositionId);
            chargingPosition.SortOrder = i;
            await this.insert(chargingPosition);
        }
    }

    public sortPositionsAfterIds(jobPositions: IJobPosition[], jobPositionIds: number[]): IJobPosition[] {
        const sortedJobPositions: IJobPosition[] = [];
        jobPositionIds.forEach(id => {
            for (const position of jobPositions) {
                if (position.Id === id) {
                    sortedJobPositions.push(position);
                    break;
                }
            }
        });
        return sortedJobPositions;
    }

    public getLocalJobPricePositions = async (): Promise<JobPricePosition<IJobPricePosition, IJobPricePositionDto>[]> => {
        const where = `Id < 0 AND ChargedPositionId > 0 AND (Discriminator = '${JobProductPosition.TYPE_NAME}' OR Discriminator = '${JobDefaultPosition.TYPE_NAME}')`;
        const jobPositions = await this.getItems(where);
        return jobPositions as JobPricePosition<IJobPricePosition, IJobPricePositionDto>[];
    };

    public async addCreditPosition(creditAmount: number, note: string, educadtionId: number, personId: number): Promise<number[]> {
        const education = await educationService.getSelectedEducation(educadtionId, personId);
        const defaultPaymentFlowProduct = await productService.getDefaultPaymentFlowProduct();
        const totalPosition = await this.getJobPositions(education.JobId);
        const unit = await unitService.getDefaultUnit();

        const prodcutPosition = new JobProductPosition();
        prodcutPosition.Amount = 1;
        prodcutPosition.Position = defaultPaymentFlowProduct.Name;
        prodcutPosition.ProductId = defaultPaymentFlowProduct.Id;
        prodcutPosition.Price = creditAmount;
        prodcutPosition.UnitId = unit ? unit.Id : null;
        prodcutPosition.ParentId = totalPosition.Id;
        prodcutPosition.SortOrder = totalPosition.Children.length;
        const positionId = await this.insert(prodcutPosition);

        if (note) {
            const textPosition = new JobTextPosition();
            textPosition.ParentId = totalPosition.Id;
            textPosition.Position = note;
            textPosition.SortOrder = prodcutPosition.SortOrder + 1;
            const textPositionId = await this.insert(textPosition);
            return [positionId, textPositionId];
        }

        return [positionId];
    }

    public async addLessonProductPosition(price: number, amount: number = 1, educadtionId: number, personId: number): Promise<number> {
        const education = await educationService.getSelectedEducation(educadtionId, personId);
        const defaultLessonProduct = await productService.getDefaultLessonProduct();
        const totalPosition = await this.getJobPositions(education.JobId);
        const unit = await unitService.getDefaultUnit();

        const productPosition = new JobProductPosition();
        productPosition.Amount = amount;
        productPosition.Position = defaultLessonProduct.Name;
        productPosition.Price = price;
        productPosition.UnitId = unit ? unit.Id : null;
        productPosition.ParentId = totalPosition.Id;
        productPosition.SortOrder = totalPosition.Children.length;
        productPosition.ProductId = defaultLessonProduct.Id;
        return this.insert(productPosition);
    }

    public addProductPosition = async (educadtionId: number, productId: number, amount: number = 1): Promise<number> => {
        const education = await educationService.getItemById(educadtionId);
        const job = await jobService.getItemById(education.JobId);
        const totalPosition = await this.getJobPositions(job.Id);
        const product = await productService.getItemById(productId);
        const unit = await unitService.getDefaultUnit();

        if (product) {
            const productPosition = new JobProductPosition();
            productPosition.Amount = amount;
            productPosition.Position = product.Name;
            productPosition.Price = product.Verkaufspreis;
            productPosition.UnitId = unit ? unit.Id : null;
            productPosition.ParentId = totalPosition.Id;
            productPosition.ProductId = product.Id;
            productPosition.SortOrder = totalPosition.Children.length;

            const productPositionId = await this.insert(productPosition);

            const orphyDriveJobPosition = new OrphyDriveJobPosition();
            orphyDriveJobPosition.JobPositionId = productPositionId;
            orphyDriveJobPosition.LessonCount = product.LessonCount;
            await orphyDriveJobPositionService.insert(orphyDriveJobPosition);
            return productPositionId;
        } else {
            const error = new Error(`Could not find product with id ${productId}`);
            logger.logError(error);
            return Promise.reject(error);
        }
    };

    public getJobTotalPrice = async (jobBaseId: number): Promise<number> => {
        const totalPosition = await this.getJobPositions(jobBaseId);
        return totalPosition.calculateTotalPrice();
    };

    public getNotBilledJobpositions = async (): Promise<CreateBillModelType[]> => {
        const items = await dal.executeRead(
            `SELECT education.Id AS EducationId, person.FirstName, person.LastName, ChecklistCollection.Name AS ChecklistCollectionName FROM jobpositions pos_job 
            LEFT JOIN jobpositions pos_job_parent ON pos_job_parent.Id = pos_job.ParentId 
            LEFT JOIN (SELECT pos_bill.ChargedPositionId FROM jobpositions pos_bill 
            LEFT JOIN jobpositions pos_bill_parent ON pos_bill_parent.Id = pos_bill.ParentId 
            INNER JOIN bills bill ON bill.Id = pos_bill.Id OR bill.Id = pos_bill.ParentId OR (bill.Id = pos_bill_parent.ParentId AND pos_bill_parent.Id IS NOT NULL) 
            WHERE pos_bill.IsDeleted = ? AND bill.IsDeleted = ? AND bill.BillStatus != 128 
            GROUP BY pos_bill.ChargedPositionId) AS pos_charged ON pos_job.Id = pos_charged.ChargedPositionId 
            INNER JOIN Jobs job ON job.Id = pos_job.Id OR job.Id = pos_job.ParentId OR (job.Id = pos_job_parent.ParentId AND pos_job_parent.Id IS NOT NULL) 
            INNER JOIN Persons person ON job.PersonId = person.Id  
            INNER JOIN Educations education ON education.JobId = job.Id 
            INNER JOIN ChecklistCollections checklistCollection ON checklistCollection.Id = education.ChecklistCollectionId 
            WHERE pos_job.IsDeleted = ? AND job.IsDeleted = ? AND person.IsDeleted = ? AND pos_charged.ChargedPositionId IS NULL 
            AND (pos_job.Discriminator = '${JobDefaultPosition.TYPE_NAME}' OR pos_job.Discriminator = '${JobProductPosition.TYPE_NAME}') 
            GROUP BY person.FirstName, person.LastName, person.Id, education.Id ORDER BY person.LastName`,
            [false, false, false, false, false]
        );
        return items;
    };

    public async getAllJobPositions(jobBaseIds: number[]): Promise<JobTotalPosition[]> {
        // This query returns only 3 hierarchical layers of the job positions structure (total position + 2 layers)
        // Currently, that's enough for the OrphyDrive
        let jobBaseIdString = Util.stringJoin(", ", jobBaseIds);
        let sql = `SELECT * FROM ${this.TABLE_NAME} WHERE Id IN (${jobBaseIdString}) AND IsDeleted = ? ORDER BY SortOrder ASC`;
        const entitiesLevel_1 = (await dal.executeRead(sql, [false])).map(x => this.createEntityFromDb(x));

        sql = `SELECT * FROM ${this.TABLE_NAME} WHERE ParentId IN (${jobBaseIdString}) AND IsDeleted = ? ORDER BY SortOrder ASC`;
        const entitiesLevel_2 = (await dal.executeRead(sql, [false])).map(x => this.createEntityFromDb(x));

        jobBaseIdString = Util.stringJoin<IJobPosition>(", ", entitiesLevel_2, item => item.Id);
        sql = `SELECT * FROM ${this.TABLE_NAME} WHERE ParentId IN (${jobBaseIdString}) AND IsDeleted = ? ORDER BY SortOrder ASC`;
        const entitiesLevel_3 = (await dal.executeRead(sql, [false])).map(x => this.createEntityFromDb(x));

        const allEntites = entitiesLevel_1.concat(entitiesLevel_2).concat(entitiesLevel_3);
        let totalPosition: JobTotalPosition = null;
        const totalPositions: JobTotalPosition[] = [];
        for (const entity of allEntites) {
            if (entity.Discriminator === JobTotalPosition.TYPE_NAME) {
                totalPosition = entity as JobTotalPosition;
                totalPositions.push(totalPosition);
                this.addChildren(totalPosition, allEntites);
            }
        }
        return totalPositions;
    }

    public async getPositionsChargedByTimetrackingId(timetrackingId: number, educationId: number): Promise<JobPositionModel[]> {
        return (await this.getAllPositionsByEducationId(educationId)).filter(p => p.TimeTrackingReference === timetrackingId);
    }

    private getJobPositions = async (jobBaseId: number): Promise<JobTotalPosition> => {
        // This query returns only 3 hierarchical layers of the job positions structure (total position + 2 layers)
        // Currently, that's enough for the OrphyDrive app
        const sql = `SELECT * FROM ${this.TABLE_NAME} WHERE Id = ${jobBaseId} AND IsDeleted = ?
                        UNION ALL
                        SELECT * FROM ${this.TABLE_NAME} WHERE ParentId = ${jobBaseId} AND IsDeleted = ?
                        UNION ALL
                        SELECT * FROM ${this.TABLE_NAME} WHERE ParentId IN (SELECT Id FROM ${this.TABLE_NAME} WHERE ParentId = ${jobBaseId}) AND IsDeleted = ?
                        ORDER BY SortOrder ASC`;
        const items = await dal.executeRead(sql, [false, false, false]);
        const entities = items.map(x => this.createEntityFromDb(x));
        let totalPosition: JobTotalPosition = null;
        for (const entity of entities) {
            if (entity.Discriminator === JobTotalPosition.TYPE_NAME) {
                totalPosition = entity as JobTotalPosition;
                this.addChildren(totalPosition, entities);
                break;
            }
        }
        return totalPosition;
    };

    private addChildren(parentPosition: IJobPosition, allPositions: IJobPosition[]): void {
        for (const position of allPositions) {
            if (position.ParentId === parentPosition.Id) {
                parentPosition.Children.push(position);
                position.Parent = parentPosition;
                this.addChildren(position, allPositions);
            }
        }
    }

    private async getJobPositionItems(jobPosition: IJobPosition, timetrackings: TimeTracking[], lessonProduct: IProduct, paymentFlowProduct: IProduct): Promise<JobPositionModel[]> {
        const items: JobPositionModel[] = [];
        if (jobPosition.Discriminator === JobProductPosition.TYPE_NAME || jobPosition.Discriminator === JobDefaultPosition.TYPE_NAME) {
            const orphyDriveJobPosition = await orphyDriveJobPositionService.getItemByPositionId(jobPosition.Id);
            const timetracking = orphyDriveJobPosition ? timetrackings.find(t => t.Id === orphyDriveJobPosition.TimeTrackingId) : null;
            const model = new JobPositionModel(
                jobPosition.Id,
                jobPosition.Position,
                (jobPosition as IJobPricePosition).Amount,
                (jobPosition as IJobPricePosition).Price,
                orphyDriveJobPosition ? orphyDriveJobPosition.LessonCount : 0,
                orphyDriveJobPosition ? orphyDriveJobPosition.TimeTrackingId : null,
                timetracking ? (timetracking.IssueDate as Date).toISOString() : null
            );
            model.IsLessonPosition = lessonProduct.Id === (jobPosition as IJobProductPosition).ProductId;
            if (paymentFlowProduct.Id === (jobPosition as IJobProductPosition).ProductId) {
                model.IsPaymentFlowPosition = true;
                const textPositions = (await this.getItems(
                    `ParentId = ${jobPosition.ParentId} AND Discriminator = '${JobTextPosition.TYPE_NAME}' AND SortOrder = ${jobPosition.SortOrder + 1}`,
                    "SortOrder ASC"
                )) as JobTextPosition[];
                const texPosition = Util.firstOrDefault(textPositions);
                model.PaymentFlowText = texPosition ? texPosition.Position : "";
            }
            items.push(model);
        } else if (jobPosition instanceof JobSubTotalPosition) {
            for (const child of jobPosition.Children) {
                const childItems = await this.getJobPositionItems(child, timetrackings, lessonProduct, paymentFlowProduct);
                for (const childItem of childItems) {
                    items.push(childItem);
                }
            }
        }
        return items;
    }

    private populateBillingData = async (item: JobPositionModel): Promise<void> => {
        const paymentWays = await paymentWayService.getItems();
        const chargingPositions = await this.getItems(`ChargedPositionId = ${item.PositionId}`);
        const result = [];
        for (const chargingPosition of chargingPositions) {
            let billingDate: Date = null;
            let status = JobPositionItemState.NotCharged;
            let chargingPositionAmountCharged = 0;
            const bill = await this.getBillForPosition(chargingPosition.Id);
            if (bill) {
                if (bill.BillStatus !== BillStatus.Canceled) {
                    item.BillCustomId = bill.CustomId;
                }
                const condition = (await conditionService.getItemById(bill.ConditionId)) ?? (await conditionService.getCondition());

                const maxBillDate = DateTime.today();
                maxBillDate.setDate(maxBillDate.getDate() - condition.CountOfDays);
                if (bill.BillStatus === BillStatus.Canceled) {
                    continue;
                } else if (bill.BillStatus === BillStatus.Paid) {
                    const paymentWay = Util.firstOrDefault(paymentWays.filter(p => bill.PaymentWayId === p.Id));
                    if (paymentWay && paymentWay.Type === PaymentWayType.Cash) {
                        status = JobPositionItemState.PaidCash;
                    } else if (paymentWay && paymentWay.Type === PaymentWayType.DebitCard) {
                        status = JobPositionItemState.PaidDebitCard;
                    } else {
                        status = JobPositionItemState.Paid;
                    }
                } else if (bill.BillStatus === BillStatus.Draft) {
                    status = JobPositionItemState.Draft;
                } else if (
                    bill.Date < maxBillDate ||
                    bill.BillStatus === BillStatus.Overdue ||
                    bill.BillStatus === BillStatus.LateNotice1 ||
                    bill.BillStatus === BillStatus.LateNotice2 ||
                    bill.BillStatus === BillStatus.Reminder
                ) {
                    status = JobPositionItemState.Overdue;
                } else {
                    status = JobPositionItemState.Open;
                }
                if (chargingPosition instanceof JobPricePosition) {
                    chargingPositionAmountCharged = chargingPosition.Amount;
                }
                billingDate = bill.Date as Date;
            } else {
                logger.logWarning(`Charging JobPosition ${chargingPosition.Id} is not attached to a bill entity`);
            }
            result.push({ billingDate: billingDate, status: status, amountCharged: chargingPositionAmountCharged });
        }
        item.BillingDate = null;
        item.BillStatus = JobPositionItemState.NotCharged;
        let amountCharged = 0;

        result.forEach(i => {
            if (i) {
                if (item.BillingDate === null || item.BillingDate < i.billingDate) {
                    item.BillingDate = i.billingDate;
                }
                amountCharged += i.amountCharged;
                item.BillStatus = this.setStatus(item.BillStatus, i.status);
            }
        });

        if (item.Amount - amountCharged > 0) {
            item.BillStatus = this.setStatus(item.BillStatus, JobPositionItemState.PartiallyCharged);
            item.AllowBilling = true;
        } else {
            item.AllowBilling = false;
        }
    };

    private getBillForPosition = async (positionId: number): Promise<IBill> => {
        // This query supports only 3 hierarchical layers of the job positions structure (total position + 2 layers)
        // Currently, that's enough for the drive swiss app
        const sql = `SELECT COALESCE(parent.ParentId, child.ParentId, child.Id) AS Id FROM ${this.TABLE_NAME} as child LEFT JOIN ${this.TABLE_NAME} as parent ON child.ParentId = parent.Id WHERE child.Id = ${positionId}`;
        const result = await dal.firstOrDefault<{ Id: number }>(sql);
        return result ? billService.getItemById(result.Id) : null;
    };

    private setStatus(oldStatus: JobPositionItemState, newStatus: JobPositionItemState): JobPositionItemState {
        switch (newStatus) {
            case JobPositionItemState.NotCharged:
                if (oldStatus === JobPositionItemState.Open || oldStatus === JobPositionItemState.PartiallyOpen) {
                    return JobPositionItemState.PartiallyOpen;
                } else if (oldStatus === JobPositionItemState.Overdue || oldStatus === JobPositionItemState.PartiallyOverdue) {
                    return JobPositionItemState.PartiallyOverdue;
                } else if (oldStatus === JobPositionItemState.PaidCash || oldStatus === JobPositionItemState.Paid || oldStatus === JobPositionItemState.PaidDebitCard) {
                    return JobPositionItemState.PartiallyCharged;
                } else {
                    return JobPositionItemState.NotCharged;
                }
            case JobPositionItemState.PartiallyCharged:
                if (oldStatus === JobPositionItemState.Open || oldStatus === JobPositionItemState.PartiallyOpen) {
                    return JobPositionItemState.PartiallyOpen;
                } else if (oldStatus === JobPositionItemState.Overdue || oldStatus === JobPositionItemState.PartiallyOverdue) {
                    return JobPositionItemState.PartiallyOverdue;
                } else {
                    return JobPositionItemState.PartiallyCharged;
                }
            case JobPositionItemState.Draft:
                return JobPositionItemState.Draft;
            case JobPositionItemState.PartiallyOpen:
                if (oldStatus === JobPositionItemState.Overdue || oldStatus === JobPositionItemState.PartiallyOverdue) {
                    return JobPositionItemState.PartiallyOverdue;
                } else {
                    return JobPositionItemState.PartiallyOpen;
                }
            case JobPositionItemState.Open:
                if (oldStatus === JobPositionItemState.NotCharged || oldStatus === JobPositionItemState.Open) {
                    return JobPositionItemState.Open;
                } else if (oldStatus === JobPositionItemState.Overdue || oldStatus === JobPositionItemState.PartiallyOverdue) {
                    return JobPositionItemState.PartiallyOverdue;
                } else {
                    return JobPositionItemState.PartiallyOpen;
                }
            case JobPositionItemState.PartiallyOverdue:
                return JobPositionItemState.PartiallyOverdue;
            case JobPositionItemState.Overdue:
                if (oldStatus === JobPositionItemState.NotCharged || oldStatus === JobPositionItemState.Overdue) {
                    return JobPositionItemState.Overdue;
                } else {
                    return JobPositionItemState.PartiallyOverdue;
                }
            case JobPositionItemState.PaidCash:
                if (oldStatus === JobPositionItemState.PaidCash || oldStatus === JobPositionItemState.Paid || oldStatus === JobPositionItemState.PaidDebitCard) {
                    return oldStatus;
                } else {
                    return JobPositionItemState.PaidCash;
                }
            case JobPositionItemState.Paid:
            case JobPositionItemState.PaidDebitCard:
                if (oldStatus === JobPositionItemState.Open) {
                    return JobPositionItemState.PartiallyOpen;
                } else if (oldStatus === JobPositionItemState.Overdue) {
                    return JobPositionItemState.PartiallyOverdue;
                } else if (
                    oldStatus === JobPositionItemState.NotCharged ||
                    oldStatus === JobPositionItemState.PaidCash ||
                    oldStatus === JobPositionItemState.Paid ||
                    oldStatus === JobPositionItemState.PaidDebitCard
                ) {
                    return newStatus;
                } else {
                    return oldStatus;
                }
            default:
                logger.logError(new Error(`Unknown job position item status: ${newStatus}`));
                return newStatus;
        }
    }
}

export const jobPositionService = new JobPositionService();
