import { Connection } from "../modules/Connection";
import { logger } from "../modules/Logger";
import { Util } from "../modules/Util";
import { translation } from "../services/TranslationService";
import { BaseView } from "./BaseView";

export default class GMap extends BaseView {
    public buttonText: string;

    private readonly ZOOM = 14;
    private readonly BACKGROUND_COLOR = "white";
    private map: google.maps.Map;
    private geocoder: google.maps.Geocoder;
    private address = "";
    private street = "";
    private streetNumber = "";
    private zipCode = "";
    private city = "";
    private marker: google.maps.Marker;
    private presetPosition: google.maps.LatLng;
    private navPoint: string;
    private mapLoadFailed: boolean;

    public async loadData(): Promise<void> {
        if (typeof google === "undefined") {
            if (Connection.noInternetConnection()) {
                Util.showNotification(translation.t("map.no-access"), "error");
                this.mapLoadFailed = true;
            } else {
                return new Promise(res => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyAePWuRfOAeWMT3_OC_h6PQbeCNWZs_ciM")
                        .done(() => {
                            // Orphy key
                            this.mapLoadFailed = false;
                            this.loadMap();
                            res();
                        })
                        .fail(() => {
                            this.mapLoadFailed = true;
                            res();
                        });
                });
            }
        } else {
            this.mapLoadFailed = false;
            this.loadMap();
        }
        return Promise.resolve();
    }

    private loadMap = () => {
        this.presetPosition = new google.maps.LatLng(46.7996438, 8.2348661);
        this.map = new google.maps.Map(document.getElementById("map"), {
            center: this.presetPosition,
            zoom: this.ZOOM,
            backgroundColor: this.BACKGROUND_COLOR
        });
        this.marker = new google.maps.Marker();
        this.geocoder = new google.maps.Geocoder();
    };

    public showView(e: any): void {
        this.navPoint = `${e.view.params.navPoint}?fromMap=true`;
        if (this.mapLoadFailed) {
            this.back();
            return;
        }
        const height = window.innerHeight - $("#headerMap").outerHeight() - $("#footerMap").outerHeight();
        this.removeMarker();
        this.set("address", "");
        this.street = "";
        this.zipCode = "";
        this.city = "";
        $("#map").css("height", height);
        this.set("buttonText", e.view.params.buttonText);
        const address = decodeURI(e.view.params.address ? e.view.params.address : "");
        this.set("address", address);
        this.getLocation();
    }

    private getLocation = () => {
        if (this.address) {
            this.geocode(this.address, this.initMap);
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.initMap(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
                },
                () => {
                    Util.showNotification(translation.t("map.position-not-detected"), "info");
                    this.initMap(this.presetPosition);
                },
                { timeout: 10_000, enableHighAccuracy: true }
            );
        } else {
            Util.showNotification(translation.t("map.gps-not-available"), "info");
            this.initMap(this.presetPosition);
        }
    };

    private initMap = (location: google.maps.LatLng) => {
        this.map.setCenter(location);
        this.map.addListener("click", this.mapClicked);
        $("#searchAddress").keyup(this.searchAddress);
    };

    private mapClicked = event => {
        $("#geoAddressMap").blur();
        this.moveMarker(event.latLng);
        this.reverseGeocode(event.latLng);
    };

    private searchAddress = e => {
        if (e.which === 13) {
            this.geocode($(e.target).val());
            $(e.target).blur();
        }
    };

    // get address from latlng
    private geocode = (address: string, callback: (Location: google.maps.LatLng) => void = null) => {
        address = this.replaceUmlaute(address);
        this.geocoder.geocode({ address: address }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                const newLocation = results[0].geometry.location;
                this.map.setCenter(newLocation);
                this.moveMarker(newLocation);
                this.reverseGeocode(newLocation);
                if (callback) callback(newLocation);
            } else {
                Util.showNotification(translation.t("map.place-not-found"), "info");
            }
        });
    };

    private moveMarker = (location: google.maps.LatLng) => {
        this.marker.setMap(this.map);
        this.marker.setPosition(location);
    };

    private removeMarker = () => {
        this.marker.setMap(null);
    };

    // get latlng from address
    private reverseGeocode = (location: google.maps.LatLng) => {
        this.geocoder.geocode({ location: location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                if (results.length) {
                    let street = "";
                    let streetNumber = "";
                    let zipCode = "";
                    let city = "";
                    results[0].address_components.forEach(addressComponent => {
                        addressComponent.types.forEach(componentType => {
                            switch (componentType) {
                                case "street_number":
                                    streetNumber = addressComponent.long_name;
                                    break;
                                case "route":
                                    street = addressComponent.long_name;
                                    break;
                                case "postal_code":
                                    zipCode = addressComponent.long_name;
                                    break;
                                case "locality":
                                    city = addressComponent.long_name;
                                    break;
                                case "administrative_area_level_3":
                                    if (!city) city = addressComponent.long_name;
                                    break;
                                default:
                                    logger.logInfo(`${componentType} not handled`, false);
                            }
                        });
                    });
                    this.street = street;
                    this.streetNumber = streetNumber;
                    this.zipCode = zipCode;
                    this.city = city;
                    this.set("address", Util.getAddressStringFromFields(street, streetNumber, zipCode, city));
                } else {
                    Util.showNotification(translation.t("main-view.no-results"), "info");
                }
            } else if (status !== google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                Util.showNotification(translation.t("main-view.no-adress"), "info");
            }
        });
    };

    private replaceUmlaute(address: string): string {
        return address.toLowerCase().split("\u00e4").join("ae").split("\u00f6").join("oe").split("\u00fc").join("ue");
    }

    public saveAddress = () => {
        app.mobileApp.navigate(
            `${this.navPoint}&street=${encodeURI(this.street)}&streetNumber=${encodeURI(this.streetNumber)}&zipCode=${encodeURI(this.zipCode)}&city=${encodeURI(
                this.city
            )}&formattedAddress=${encodeURI(this.address)}`
        );
    };

    public getNavPoint(): string {
        return this.navPoint;
    }
}
