import Lesson, { ILesson, ILessonDto } from "../types/Lesson";
import { BaseService } from "./BaseService";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import LessonModel from "../models/LessonModel";
import { ratingService } from "./ChecklistRatingService";
import { educationService } from "./EducationService";
import { keys } from "ts-transformer-keys";
import { nameof } from "ts-simple-nameof";
import { dal } from "../dal/Dal";

export default class LessonService extends BaseService<ILesson, ILessonDto> {
    private readonly TABLE_NAME = "Lessons";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ILessonDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ILesson>()).filter(
            x => x !== nameof<ILesson>(n => n.IsCurrentLesson) && x !== nameof<ILesson>(n => n.IsUpdateLesson) && x !== nameof<ILesson>(n => n.IsMissedLesson)
        );
    }

    public getApiRoute(): string {
        return `${network.API}/${Lesson.EntityTypeId}`;
    }

    public createEntityFromOData(item: ILessonDto): Lesson {
        const entity = new Lesson();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: ILesson): Lesson {
        const entity = new Lesson();
        entity.populateFromDb(item);
        return entity;
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        params.push(false);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE (Id < 0 OR IsDirty = ?) AND IsCurrentLesson = ?`;
    }

    protected getNewItems(): Promise<ILesson[]> {
        return this.getItems("Id < 0 AND IsCurrentLesson = ?", null, null, null, null, [false]);
    }

    protected async updateDependencies(oldId: number, newId: number, updateSelf: boolean): Promise<void> {
        const updateFK = new Map<string, any>().set("LessonId", newId);
        await super.updateDependencies(oldId, newId, updateSelf);
        await ratingService.update("LessonId", oldId, updateFK, false);
    }

    public async getLessonByTimeTrackingId(timeTrackingId: number, educadtionId: number): Promise<ILesson> {
        return Util.firstOrDefault(await this.getItems(`TimeTrackingId = ${timeTrackingId} AND EducationId = ${educadtionId}`));
    }

    public async setUpdateLesson(timeTrackingId: number, educadtionId: number): Promise<void> {
        await this.resetUpdateLesson(educadtionId);
        await dal.execute(
            `UPDATE ${this.getTableName()} SET 
            IsUpdateLesson = ? WHERE TimeTrackingId = ? AND EducationId = ?`,
            [true, timeTrackingId, educadtionId]
        );
        const lesson = await this.getLessonByTimeTrackingId(timeTrackingId, educadtionId);
        await ratingService.setUpdateRating(lesson.Id);
    }

    public async resetUpdateLesson(educationId: number): Promise<void> {
        const updateLesson = await this.getUpdateLesson(educationId);
        if (updateLesson) {
            await ratingService.resetUpdateRatings(updateLesson.Id);
            updateLesson.IsUpdateLesson = false;
            await this.updateEntity(updateLesson);
        }
    }

    public async addCurrentLesson(lessonCount: number, timeTrackingId: number, lessonDate: Date, educadtionId: number) {
        const currentLessson = await this.getCurrentLesson(educadtionId);
        currentLessson.IsCurrentLesson = false;
        currentLessson.Count = lessonCount;
        currentLessson.TimeTrackingId = timeTrackingId;
        currentLessson.Date = lessonDate;
        await this.updateEntity(currentLessson, true);
    }

    public async addMisseLesson(lessonCount: number, timetrackingId: number, lessonDate: Date, educationId: number) {
        const missedLesson = new Lesson();
        missedLesson.EducationId = educationId;
        missedLesson.IsCurrentLesson = false;
        missedLesson.IsUpdateLesson = false;
        missedLesson.Count = lessonCount;
        missedLesson.TimeTrackingId = timetrackingId;
        missedLesson.Date = lessonDate;
        await this.insert(missedLesson);
    }

    public async hasRatedLessons(): Promise<boolean> {
        const sql = `SELECT COUNT(*) AS Count FROM ${this.getTableName()} WHERE Id IN (SELECT LessonId FROM ${ratingService.getTableName()})`;
        const item = await dal.firstOrDefault<{ Count: number }>(sql);
        return item.Count > 0;
    }

    public async isMissedLesson(lessonId: number): Promise<boolean> {
        const sql = `SELECT COUNT(*) AS Count FROM ${ratingService.getTableName()} WHERE LessonId = ${lessonId}`;
        const item = await dal.firstOrDefault<{ Count: number }>(sql);
        return item.Count > 0;
    }

    public async getItemsWithMissedLessons(timeTrackingIds: number[]): Promise<ILesson[]> {
        const items = await dal.executeRead(
            `SELECT l.*,
        (SELECT COUNT(*) FROM ${ratingService.getTableName()} WHERE l.Id IN (SELECT LessonId FROM ${ratingService.getTableName()})) = 0 AS IsMissedLesson
         FROM Lessons AS l 
         WHERE IsDeleted = ? AND l.TimeTrackingId IN ${Util.joinIds(timeTrackingIds)}`,
            [false]
        );
        return items.map(l => this.createEntityFromDb(l));
    }

    public async delete(id: number, isDirty?: boolean): Promise<void> {
        await super.delete(id, isDirty);
        if (id < 0) {
            await ratingService.hardDeleteByCondidtion(`Lessonid = ${id}`);
        }
    }

    public async getLessonsByEducationId(educationId: number): Promise<ILesson[]> {
        const items = await dal.executeRead(
            `SELECT l.*, (SELECT COUNT(*) FROM ${ratingService.getTableName()} AS C 
            WHERE c.LessonId = l.Id) = 0 AS IsMissedLesson 
            FROM ${this.getTableName()} AS l 
            WHERE EducationId = ${educationId} AND IsDeleted = ?`,
            [false]
        );
        return items.map(x => this.createEntityFromDb(x));
    }

    public async getLessonCount(educationId: number, personId: number): Promise<number> {
        const education = await educationService.getSelectedEducation(educationId, personId);
        if (!education) {
            return 0;
        }

        const sql = `SELECT SUM(l.Count) AS LessonCount FROM ${this.getTableName()} AS l WHERE EducationId = ${
            education.Id
        } AND l.IsDeleted = ? AND l.IsCurrentLesson = ? AND l.Id IN (SELECT LessonId FROM ${ratingService.getTableName()} AS cr WHERE l.Id = cr.LessonId)`;

        const lessonCount = await dal.firstOrDefault<{ LessonCount: number }>(sql, [false, false]);
        if (lessonCount) {
            return lessonCount.LessonCount || 0;
        } else {
            return 0;
        }
    }

    public async getMissedLessonCount(educationId: number, personId: number): Promise<number> {
        const education = await educationService.getSelectedEducation(educationId, personId);
        if (!education) {
            return 0;
        }
        const sql = `SELECT SUM(l.Count) AS LessonCount FROM ${this.getTableName()} AS l WHERE EducationId = ${
            education.Id
        } AND l.IsDeleted = ? AND l.IsCurrentLesson = ? AND l.Id NOT IN (SELECT LessonId FROM ${ratingService.getTableName()} AS cr WHERE l.Id = cr.LessonId)`;

        const missedLessonCount = await dal.firstOrDefault<{ LessonCount: number }>(sql, [false, false]);
        if (missedLessonCount) {
            return missedLessonCount.LessonCount || 0;
        } else {
            return 0;
        }
    }

    public async getCompletedLessons(educationId: number): Promise<number> {
        const lessonCount = await this.get<{ Count: number }>(`EducationId = ? AND IsCurrentLesson = ?`, "Count(Id) AS Count", [educationId, false]);
        return lessonCount.Count;
    }

    public async getCurrentLessonModel(educationId: number): Promise<LessonModel> {
        const currentLesson = await this.getCurrentLesson(educationId);
        if (!currentLesson) {
            const newCurrentLesson = new Lesson();
            newCurrentLesson.EducationId = educationId;
            newCurrentLesson.IsCurrentLesson = true;
            newCurrentLesson.Id = await this.insert(newCurrentLesson);
            return new LessonModel(newCurrentLesson);
        }
        return new LessonModel(currentLesson);
    }

    public async getPreviousLessons(educationId: number): Promise<ILesson[]> {
        const lessons = await this.getItems(`EducationId = ? AND IsCurrentLesson = ?`, null, null, null, null, [educationId, false]);
        return lessons.sort((a, b) => a.Date.getTime() - b.Date.getTime());
    }

    public async getCurrentLesson(educationId: number): Promise<ILesson> {
        return Util.firstOrDefault(await this.getItems(`EducationId = ? AND IsCurrentLesson = ?`, null, null, null, null, [educationId, true]));
    }

    public async getUpdateLesson(educationId: number): Promise<ILesson> {
        return Util.firstOrDefault(await this.getItems(`EducationId = ? AND IsUpdateLesson = ?`, null, null, null, null, [educationId, true]));
    }

    public async getItemByTimeTrackingId(timeTrackingId: number) {
        return Util.firstOrDefault(await this.getItems(`TimeTrackingId = ${timeTrackingId}`));
    }
}

export const lessonService = new LessonService();
