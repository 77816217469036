export default class PlannedLessonModel {
    private _checklistPoints: number[] = [];
    private _note: string = "";

    public get ChecklistPoints() {
        return this._checklistPoints;
    }

    public set ChecklistPoints(val: number[]) {
        this._checklistPoints = val;
    }

    public get Note() {
        return this._note;
    }

    public set Note(val: string) {
        this._note = val;
    }
}
