import { nameof } from "ts-simple-nameof";
import { IBaseDto, IBaseEntity } from "../types/BaseEntity";
import ChecklistItem from "../types/ChecklistItem";
import { activityService } from "./ActivityService";
import { addressTypeService } from "./AddressTypeService";
import { BaseService } from "./BaseService";
import { billService } from "./BillService";
import { checklistCollectionService } from "./ChecklistCollectionService";
import { checklistItemService } from "./ChecklistItemService";
import { ratingService } from "./ChecklistRatingService";
import { companyAppSettingsService } from "./CompanyAppSettingsService";
import { companySettingsService } from "./CompanySettingsService";
import { conditionService } from "./ConditionService";
import { contactMediaService } from "./ContactMediaService";
import { currencyService } from "./CurrencyService";
import { drivingTestService } from "./DrivingTestService";
import { educationService } from "./EducationService";
import { jobPositionService } from "./JobPositionService";
import { jobService } from "./JobService";
import { lessonService } from "./LessonService";
import { lfaService } from "./LfaService";
import { orphyDriveJobPositionService } from "./OrphyDriveJobPositionService";
import { paymentWayService } from "./PaymentWayService";
import { personAddressService } from "./PersonAddressService";
import { personContactService } from "./PersonContactService";
import { personService } from "./PersonService";
import { productService } from "./ProductService";
import { profileService } from "./ProfileService";
import { salutationService } from "./SalutationService";
import { testService } from "./TestService";
import { timeTrackingService } from "./TimeTrackingService";
import { unitService } from "./UnitService";
import { userAppSettingsService } from "./UserAppSettingsService";
import { userService } from "./UserService";
import { userSettingsService } from "./UserSettingsService";

interface Table {
    csv: string;
    name: string;
    service: BaseService<IBaseEntity, IBaseDto>;
    columns: string[];
}

export default class PushDbService {
    private tables: Table[] = [
        { csv: "", name: "activities", service: activityService, columns: activityService.getDbFields() },
        { csv: "", name: "addressTypes", service: addressTypeService, columns: addressTypeService.getDbFields() },
        {
            csv: "",
            name: "profile",
            service: profileService,
            columns: profileService.getDbFields()
        },
        {
            csv: "",
            name: "bills",
            service: billService,
            columns: billService.getDbFields()
        },
        {
            csv: "",
            name: "ChecklistItems",
            service: checklistItemService,
            columns: checklistItemService.getDbFields().filter(x => x !== nameof<ChecklistItem>(n => n.Overview))
        },
        { csv: "", name: "ChecklistCollections", service: checklistCollectionService, columns: checklistCollectionService.getDbFields() },
        {
            csv: "",
            name: "companyAppSettings",
            service: companyAppSettingsService,
            columns: companyAppSettingsService.getDbFields()
        },
        {
            csv: "",
            name: "companySettings",
            service: companySettingsService,
            columns: companySettingsService.getDbFields()
        },
        { csv: "", name: "conditions", service: conditionService, columns: conditionService.getDbFields() },
        { csv: "", name: "contactMedia", service: contactMediaService, columns: contactMediaService.getDbFields() },
        { csv: "", name: "currencies", service: currencyService, columns: currencyService.getDbFields() },
        {
            csv: "",
            name: "jobs",
            service: jobService,
            columns: jobService.getDbFields()
        },
        {
            csv: "",
            name: "jobPositions",
            service: jobPositionService,
            columns: jobPositionService.getDbFields()
        },
        { csv: "", name: "lessons", service: lessonService, columns: lessonService.getDbFields() },
        { csv: "", name: "paymentWays", service: paymentWayService, columns: paymentWayService.getDbFields() },
        {
            csv: "",
            name: "persons",
            service: personService,
            columns: personService.getDbFields()
        },
        {
            csv: "",
            name: "personAddresses",
            service: personAddressService,
            columns: personAddressService.getDbFields()
        },
        {
            csv: "",
            name: "personContacts",
            service: personContactService,
            columns: personContactService.getDbFields()
        },
        { csv: "", name: "products", service: productService, columns: productService.getDbFields() },
        { csv: "", name: "salutations", service: salutationService, columns: salutationService.getDbFields() },
        {
            csv: "",
            name: "timeTrackings",
            service: timeTrackingService,
            columns: timeTrackingService.getDbFields()
        },
        { csv: "", name: "units", service: unitService, columns: unitService.getDbFields() },
        { csv: "", name: "users", service: userService, columns: userService.getDbFields() },
        {
            csv: "",
            name: "userAppSettings",
            service: userAppSettingsService,
            columns: userAppSettingsService.getDbFields()
        },
        {
            csv: "",
            name: "userSettings",
            service: userSettingsService,
            columns: userSettingsService.getDbFields()
        },
        {
            csv: "",
            name: "Educations",
            service: educationService,
            columns: educationService.getDbFields()
        },
        {
            csv: "",
            name: "OrphyDriveJobPosition",
            service: orphyDriveJobPositionService,
            columns: orphyDriveJobPositionService.getDbFields()
        },
        {
            csv: "",
            name: "DrivingTest",
            service: drivingTestService,
            columns: drivingTestService.getDbFields()
        },
        {
            csv: "",
            name: "Tests",
            service: testService,
            columns: testService.getDbFields()
        },
        {
            csv: "",
            name: "Lfas",
            service: lfaService,
            columns: lfaService.getDbFields()
        },
        {
            csv: "",
            name: "ChecklistRatings",
            service: ratingService,
            columns: ratingService.getDbFields()
        }
    ];

    public pushDB = async (): Promise<string> => {
        app.mobileApp.showLoading();
        for (const table of this.tables) {
            const { columns } = table;
            const rows = await table.service.getItems(null, null, null, columns.join(","));

            table.csv += `${table.name}<br/><br/>`;
            table.csv += `${columns.join(";")}<br/>`;
            for (const row of rows) {
                for (const column of columns) {
                    if (Array.isArray(row[column])) {
                        row[column].forEach(item => {
                            table.csv += `${JSON.stringify(item)}`;
                        });
                    } else {
                        table.csv += `${row[column]}`;
                    }
                    table.csv += `;`;
                }
                table.csv += "<br/>";
            }
            table.csv += "<br/><br/><br/>";
        }
        let csv = "";
        this.tables.forEach(c => {
            csv += c.csv;
        });
        app.mobileApp.hideLoading();
        return csv;
    };
}
