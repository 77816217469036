import TimeTrackingGroupModel from "../models/TimeTrackingGroupModel";
import TimeTrackingModel from "../models/TimeTrackingModel";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { IChecklistCollection } from "../types/ChecklistCollection";
import { ICompanyAppSettings } from "../types/CompanyAppSettings";
import { ILesson } from "../types/Lesson";
import TimeTracking, { ITimeTracking, ITimeTrackingDto } from "../types/TimeTracking";
import { BaseService } from "./BaseService";
import { checklistCollectionService } from "./ChecklistCollectionService";
import { companyAppSettingsService } from "./CompanyAppSettingsService";
import { lessonService } from "./LessonService";
import DateTime from "../modules/DateTime";
import { personService } from "./PersonService";
import { orphyDriveJobPositionService } from "./OrphyDriveJobPositionService";
import { educationService } from "./EducationService";
import { IEducation } from "../types/Education";
import { keys } from "ts-transformer-keys";
import { dal } from "../dal/Dal";

export default class TimeTrackingService extends BaseService<ITimeTracking, ITimeTrackingDto> {
    private readonly TABLE_NAME = "TimeTrackings";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ITimeTrackingDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ITimeTracking>());
    }

    public getApiRoute(): string {
        return `${network.API}/${TimeTracking.EntityTypeId}`;
    }

    public createEntityFromOData(item: ITimeTrackingDto): TimeTracking {
        const entity = new TimeTracking();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: ITimeTracking): TimeTracking {
        const entity = new TimeTracking();
        entity.populateFromDb(item);
        return entity;
    }

    public async updateDependencies(oldId: number, newId: number, updateSelf: boolean): Promise<void> {
        const updateFK = new Map<string, any>().set("TimeTrackingId", newId);
        await super.updateDependencies(oldId, newId, updateSelf);
        await lessonService.update("TimetrackingId", oldId, updateFK, false);
        await orphyDriveJobPositionService.updateTimeTrackingId(oldId, newId);
    }

    public async getItemCount(where?: string, params?: any[]): Promise<number> {
        params = params ?? [];
        const sql = `SELECT COUNT(DISTINCT DATE(IssueDate)) AS ItemCount FROM ${this.getTableName()} WHERE ${where ? `(${where})` : ""} AND IsDeleted = ?`;
        params.push(false);
        const count = Util.firstOrDefault((await dal.executeRead(sql, params)) as { ItemCount: number }[]);
        return count ? count.ItemCount : 0;
    }

    public async getTimeTrackingItems(asc: boolean, where: string, skip: number, take: number, params?: any[]): Promise<{ data: TimeTrackingGroupModel[]; total: number }> {
        const companyAppSettings = await companyAppSettingsService.getSettings();
        const total = await this.getItemCount(
            where,
            params.map(x => x)
        );
        const sql = `SELECT * FROM ${this.getTableName()}
         WHERE DATE(IssueDate) IN (
            SELECT DISTINCT DATE(IssueDate) FROM ${this.getTableName()} WHERE ${where ? `(${where})` : ""}
            AND IsDeleted = ? 
            ORDER BY IssueDate ${asc ? "ASC" : "DESC"}
            ${take ? `LIMIT ${take}` : ""} ${skip ? `OFFSET ${skip}` : ""}
            ) ${where ? `AND (${where})` : ""}  AND IsDeleted = ? ORDER BY IssueDate ${asc ? "ASC" : "DESC"}`;
        params.push(false);
        params.push(params[0]);
        params.push(false);
        const timeTrackings = (await dal.executeRead(sql, params)).map(t => this.createEntityFromDb(t));
        const lessons = await lessonService.getItemsWithMissedLessons(timeTrackings.map(x => x.Id));
        return {
            total: total,
            data: await this.createTimeTrackingItems(timeTrackings, lessons, companyAppSettings)
        };
    }

    public async getTimeTrackingsItemsByEducationId(educationId: number): Promise<TimeTrackingGroupModel[]> {
        const companyAppSettings = await companyAppSettingsService.getSettings();
        const lessons = await lessonService.getLessonsByEducationId(educationId);
        const tt = await this.getTimetrackingsWithTwinLessonByEducationId(educationId);
        return this.createTimeTrackingItems(tt, lessons, companyAppSettings);
    }

    public getTimetrackingsWithTwinLessonByEducationId = async (educationId: number): Promise<TimeTracking[]> => {
        const timeTrackings = await dal.executeRead(
            `SELECT t.* FROM ${this.getTableName()} as t JOIN ${lessonService.getTableName()} as l ON t.Id = l.TimeTrackingId
            WHERE l.EducationId = ${educationId} AND l.IsDeleted = ? AND t.IsDeleted = ?
            ORDER BY t.IssueDate ASC`,
            [false, false]
        );
        return timeTrackings.map(x => this.createEntityFromDb(x));
    };

    public getThisWeekWorkingHoursInMinutes = async (userPersonId: number): Promise<number> => {
        const timeTrackings = await this.getTimeTrackingsInRangeByMitarbeiterId(userPersonId, DateTime.getStartOfWeek(), new Date());
        return timeTrackings.reduce((a, b) => a + DateTime.getMinutesFromTicks(b.DurationTicks), 0);
    };

    public getThisMonthWOrkingHoursInMinutes = async (userPersonId: number): Promise<number> => {
        const timeTrackings = await this.getTimeTrackingsInRangeByMitarbeiterId(userPersonId, DateTime.getStartOfMonth(), new Date());
        return timeTrackings.reduce((a, b) => a + DateTime.getMinutesFromTicks(b.DurationTicks), 0);
    };

    private async getTimeTrackingsInRangeByMitarbeiterId(userPersonId: number, start: Date, end: Date): Promise<ITimeTracking[]> {
        return this.getItems(`MitarbeiterId = ${userPersonId} AND IssueDate >= '${start.toISOString()}' AND IssueDate <= '${end.toISOString()}'`);
    }

    public getTimeTrackingForPaperworkDashboard = async (userFilter: string = null): Promise<TimeTrackingModel[]> => {
        const where = userFilter ? `WHERE ${userFilter}` : "";
        const sql = `SELECT t.*, p.FirstName, p.LastName FROM ${this.getTableName()} AS t JOIN ${personService.getTableName()} AS p ON t.PersonId = p.Id AND t.IsDeleted = ? ${where} ORDER BY IssueDate DESC LIMIT 25`;
        const timetrackings = await dal.executeRead(sql, [false]);
        let refDate = new Date();
        const ret: TimeTrackingModel[] = [];
        for (const timetracking of timetrackings) {
            const tracking = this.createEntityFromDb(timetracking);
            const trackingItem = new TimeTrackingModel();
            trackingItem.Title = tracking.Title;
            trackingItem.IssueDate = tracking.IssueDate as Date;
            trackingItem.DurationTicks = tracking.DurationTicks;
            trackingItem.PersonName = `${timetracking.FirstName} ${timetracking.LastName}`;
            trackingItem.Avatar = Util.getAvatar(timetracking.FirstName, timetracking.LastName);
            if (DateTime.isSameDay(refDate, tracking.IssueDate as Date)) {
                trackingItem.IsNewDay = false;
            } else {
                refDate = tracking.IssueDate as Date;
                trackingItem.Title = DateTime.parseStringDate(refDate);
                trackingItem.IsNewDay = true;
            }
            ret.push(trackingItem);
        }
        return ret;
    };

    private async createTimeTrackingItems(timeTrackings: ITimeTracking[], lessons: ILesson[], companyAppSettings: ICompanyAppSettings): Promise<TimeTrackingGroupModel[]> {
        let lastDate: Date = null;
        let dailyTotal = 0;
        let dailyLessonTotal = 0;
        let weeklyTotal = 0;
        let weeklyLessonTotal = 0;
        let monthlyTotal = 0;
        let monthlyLessonTotal = 0;
        let groupItem: TimeTrackingGroupModel = null;
        const groupItems: TimeTrackingGroupModel[] = [];
        const checklistCollections = await checklistCollectionService.getItems();
        const educations = await educationService.getItems();

        for (const timeTracking of timeTrackings) {
            const lesson = lessons.find(x => x.TimeTrackingId === timeTracking.Id);

            if (lastDate) {
                if (!DateTime.isSameMonth(lastDate, timeTracking.IssueDate as Date)) {
                    groupItem.MonthlyTotal.Total = monthlyTotal;
                    groupItem.MonthlyTotal.LessonTotal = monthlyLessonTotal;
                    monthlyTotal = 0;
                    monthlyLessonTotal = 0;
                }
                if (!DateTime.isSameWeek(lastDate, timeTracking.IssueDate as Date)) {
                    groupItem.WeeklyTotal.Total = weeklyTotal;
                    groupItem.WeeklyTotal.LessonTotal = weeklyLessonTotal;
                    weeklyTotal = 0;
                    weeklyLessonTotal = 0;
                }
                if (!DateTime.isSameDay(lastDate, timeTracking.IssueDate as Date)) {
                    groupItem.DailyTotal.Total = dailyTotal;
                    groupItem.DailyTotal.LessonTotal = dailyLessonTotal;
                    dailyTotal = 0;
                    dailyLessonTotal = 0;
                    groupItem = new TimeTrackingGroupModel();
                    groupItem.HeaderName = DateTime.parseStringDate(timeTracking.IssueDate as Date);
                    groupItem.IssueDate = timeTracking.IssueDate as Date;
                    groupItems.push(groupItem);
                }
            } else {
                groupItem = new TimeTrackingGroupModel();
                groupItem.HeaderName = DateTime.parseStringDate(timeTracking.IssueDate as Date);
                groupItem.IssueDate = timeTracking.IssueDate as Date;
                groupItems.push(groupItem);
            }

            const trackedTime = DateTime.getTrackedTime(timeTracking.IssueDate as Date, timeTracking.DurationTicks);

            monthlyTotal += trackedTime;
            weeklyTotal += trackedTime;
            dailyTotal += trackedTime;

            if (lesson) {
                weeklyLessonTotal += lesson.Count;
                monthlyLessonTotal += lesson.Count;
                dailyLessonTotal += lesson.Count;
            }

            groupItem.TimeTrackingItems.push(this.createTimeTrackingItem(timeTracking, lesson, companyAppSettings, educations, checklistCollections));
            lastDate = timeTracking.IssueDate as Date;
        }

        if (groupItem) {
            groupItem.MonthlyTotal.Total = monthlyTotal;
            groupItem.WeeklyTotal.Total = weeklyTotal;
            groupItem.DailyTotal.Total = dailyTotal;

            groupItem.MonthlyTotal.LessonTotal = monthlyLessonTotal;
            groupItem.WeeklyTotal.LessonTotal = weeklyLessonTotal;
            groupItem.DailyTotal.LessonTotal = dailyLessonTotal;
        }

        return groupItems;
    }

    private createTimeTrackingItem(
        timeTracking: ITimeTracking,
        lesson: ILesson,
        companyAppSettings: ICompanyAppSettings,
        educations: IEducation[],
        checklistCollections: IChecklistCollection[]
    ): TimeTrackingModel {
        const timeTrackingModel = new TimeTrackingModel();
        timeTrackingModel.TimeTrackingId = timeTracking.Id;
        timeTrackingModel.Title = timeTracking.Title;
        timeTrackingModel.IsTotal = false;
        timeTrackingModel.IssueDate = timeTracking.IssueDate as Date;
        timeTrackingModel.DurationTicks = timeTracking.DurationTicks;
        timeTrackingModel.MissedReason = timeTracking.Beschreibung;

        if (companyAppSettings.LockEditLesson) {
            const lockDate = new Date();
            lockDate.setDate(lockDate.getDate() - companyAppSettings.LessonEditLockDays);
            timeTrackingModel.IsEditAble = lockDate < timeTrackingModel.IssueDate;
        } else {
            timeTrackingModel.IsEditAble = true;
        }

        if (lesson) {
            const education = educations.find(x => x.Id === lesson.EducationId);
            timeTrackingModel.GlobalLessonNote = lesson.Note;
            timeTrackingModel.MissedLesson = lesson.IsMissedLesson;
            timeTrackingModel.LessonCount = lesson.Count;
            if (education) {
                const checklistCollection = checklistCollections.find(x => x.Id === education.ChecklistCollectionId);
                timeTrackingModel.ChecklistName = checklistCollection ? checklistCollection.Name : "";
            }
        }
        return timeTrackingModel;
    }
}

export const timeTrackingService = new TimeTrackingService();
