export default class NetworkError {
    private readonly _response: Response;

    constructor(response: Response) {
        this._response = response;
    }

    get Status(): number {
        return this._response.status;
    }

    get StatusText(): string {
        return this._response.statusText;
    }
}
