import DateTime from "../modules/DateTime";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { activityService } from "../services/ActivityService";
import { timeTrackingService } from "../services/TimeTrackingService";
import { translation } from "../services/TranslationService";
import TimeTracking from "../types/TimeTracking";
import { BaseView } from "./BaseView";

export default class CreateTimeTracking extends BaseView {
    private navPoint: string;

    public showView(e: any): void {
        this.navPoint = e.view.params.navPoint;
        this.initTimeTracking();
    }

    public async loadData(): Promise<void> {
        const activites = await activityService.getItems();
        this.set("activities", activites);
        this.set("activitiesVisible", activites.length > 0);
        activites.forEach(item => {
            if (item.TranslatedDisplayName === "Allgemein") {
                this.set("activityId", item.Id);
            }
        });
    }

    private initTimeTracking = (): void => {
        const startDateTime = new Date();
        this.set("timetrackingStart", DateTime.getDateTimeForInput(startDateTime));
        const endDateTime = new Date(startDateTime.toISOString());
        endDateTime.setMinutes(endDateTime.getMinutes() + 60);
        this.set("timetrackingEnd", DateTime.getDateTimeForInput(endDateTime));
        this.set("timeTrackingDescription", "");
    };

    public saveTimeTracking = async (): Promise<void> => {
        if (this.checkTimeTracking()) {
            await this.insertTimetracking();
        }
    };

    private insertTimetracking = async () => {
        const timetrackingEntry = this.getNewTimeTracking();
        await timeTrackingService.insert(timetrackingEntry);
        this.back();
    };

    private getNewTimeTracking = (): TimeTracking => {
        const timetracking = new TimeTracking();
        const startTime = DateTime.getDateTimeFromInput(this.get<string>("timetrackingStart"));
        const endTime = DateTime.getDateTimeFromInput(this.get<string>("timetrackingEnd"));
        timetracking.IssueDate = startTime;
        timetracking.DurationTicks = DateTime.getTicks(startTime, endTime);
        timetracking.ActivityId = this.get<number>("activityId") ? this.get<number>("activityId") : 2;
        timetracking.MitarbeiterId = network.getPersonId();
        timetracking.IsDeleted = false;
        timetracking.Status = 2;
        timetracking.Title = this.get<string>("timeTrackingDescription");
        return timetracking;
    };

    private checkTimeTracking = (): boolean => {
        const start = DateTime.getDateTimeFromInput(this.get<string>("timetrackingStart"));
        const end = DateTime.getDateTimeFromInput(this.get<string>("timetrackingEnd"));
        const subject = this.get<string>("timeTrackingDescription");
        if (Number.isNaN(start.getTime())) {
            Util.showNotification(translation.t("util.add-start-date"), "error");
            return false;
        }
        if (Number.isNaN(end.getTime())) {
            Util.showNotification(translation.t("util.add-end-date"), "error");
            return false;
        }
        if (start > end) {
            Util.showNotification(translation.t("util.wrong-start-end-date"), "error");
            return false;
        }
        if (!DateTime.isSameDay(start, end)) {
            Util.showNotification(translation.t("util.date-not-same-day"), "error");
            return false;
        }
        if (!subject) {
            Util.showNotification(translation.t("util.no-explanation"), "error");
            return false;
        }
        return true;
    };

    public getNavPoint(): string {
        return this.navPoint;
    }
}
