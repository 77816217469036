import { network } from "../modules/Network";
import { Util } from "../modules/Util";

export default class EmailService {
    public sendEmail = async (fromAddress: string, toAddress: string, subject: string, body: string): Promise<void> => {
        if (!Util.isDemo()) {
            await network.send("POST", `${network.ODATA_API_V3}/SendEmail`, { from: fromAddress, to: toAddress, subject: subject, body: body });
        }
    };
}

export const emailService = new EmailService();
