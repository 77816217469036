import { keys } from "ts-transformer-keys";
import PersonContactModel from "../models/PersonContactModel";
import { logger } from "../modules/Logger";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { IContactMedia } from "../types/ContactMedia";
import PersonContact, { IPersonContact, IPersonContactDto } from "../types/PersonContact";
import { BaseService } from "./BaseService";
import { contactMediaService } from "./ContactMediaService";

export default class PersonContactService extends BaseService<IPersonContact, IPersonContactDto> {
    private TABLE_NAME = "PersonContacts";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IPersonContactDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IPersonContact>());
    }

    public getApiRoute(): string {
        return `${network.API}/${PersonContact.EntityTypeId}`;
    }

    public createEntityFromOData(item: IPersonContactDto): IPersonContact {
        const entity = new PersonContact();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: IPersonContact): IPersonContact {
        const entity = new PersonContact();
        entity.populateFromDb(item);
        return entity;
    }

    public async insert(insertObject: IPersonContact): Promise<number> {
        if (!insertObject.MediaId) {
            logger.logError(new Error("PersonContact cannot be inserted without MediaId set!"));
            return Promise.reject();
        }
        return super.insert(insertObject);
    }

    public getContactsByPersonId(personId: number): Promise<IPersonContact[]> {
        return this.getItems(`PersonId = ${personId}`);
    }

    public getPersonPhone = async (personId: number): Promise<IPersonContact> => {
        const contactMedia = await contactMediaService.getPhoneContactMedia();
        return this.getPersonContact(personId, contactMedia);
    };

    public async getPersonPhoneModel(personId: number): Promise<PersonContactModel> {
        const contactMedia = await contactMediaService.getPhoneContactMedia();
        const personPhone = await this.getPersonContact(personId, contactMedia);
        return personPhone ? new PersonContactModel(personPhone, contactMedia) : new PersonContactModel(new PersonContact(), contactMedia);
    }

    public getPersonMobile = async (personId: number): Promise<IPersonContact> => {
        const contactMedia = await contactMediaService.getMobileContactMedia();
        return this.getPersonContact(personId, contactMedia);
    };

    public async getPersonMobileModel(personId: number): Promise<PersonContactModel> {
        const contactMedia = await contactMediaService.getMobileContactMedia();
        const personMobile = await this.getPersonContact(personId, contactMedia);
        return personMobile ? new PersonContactModel(personMobile, contactMedia) : new PersonContactModel(new PersonContact(), contactMedia);
    }

    public getPersonEmail = async (personId: number): Promise<IPersonContact> => {
        const contactMedia = await contactMediaService.getEmailContactMedia();
        return this.getPersonContact(personId, contactMedia);
    };

    public async getPersonEmailModel(personId: number): Promise<PersonContactModel> {
        const contactMedia = await contactMediaService.getEmailContactMedia();
        const personMail = await this.getPersonContact(personId, contactMedia);
        return personMail ? new PersonContactModel(personMail, contactMedia) : new PersonContactModel(new PersonContact(), contactMedia);
    }

    private async getPersonContact(personId: number, contactMedia: IContactMedia) {
        return Util.firstOrDefault(await this.getItems(`PersonId = ${personId} AND MediaId = ${contactMedia.Id}`, `IsPrefered DESC`, 1));
    }

    public findDuplicateEmail = async (email: PersonContactModel): Promise<boolean> => {
        const contactMedia = await contactMediaService.getEmailContactMedia();
        const result = await this.getItems(`NameInMedia = ? AND MediaId = ?`, `IsPrefered DESC`, 1, null, null, [email.NameInMedia, contactMedia.Id]);
        return !!result.length;
    };

    public insertOrUpdatePhone = async (personId: number, contact: IPersonContact): Promise<void> => {
        const contactMedia = await contactMediaService.getPhoneContactMedia();
        contact.PersonId = personId;
        contact.MediaId = contactMedia.Id;
        const existingPhone = await this.getPersonPhone(personId);
        if (existingPhone) {
            await this.update("Id", existingPhone.Id, contact.toDbObject(), true);
        } else {
            await this.insert(contact);
        }
    };

    public deletePhoneIfExists = async (personId: number): Promise<void> => {
        const existingPhone = await this.getPersonPhone(personId);
        if (existingPhone) {
            if (existingPhone.Id > 0) {
                existingPhone.IsDeleted = true;
                await this.updateEntity(existingPhone, true);
            } else {
                await this.hardDelete(existingPhone.Id);
            }
        }
    };

    public insertOrUpdateMobile = async (personId: number, contact: IPersonContact): Promise<void> => {
        const contactMedia = await contactMediaService.getMobileContactMedia();
        contact.PersonId = personId;
        contact.MediaId = contactMedia.Id;
        const existingMobile = await this.getPersonMobile(personId);
        if (existingMobile) {
            await this.update("Id", existingMobile.Id, contact.toDbObject(), true);
        } else {
            await this.insert(contact);
        }
    };

    public deleteMobileIfExists = async (personId: number): Promise<void> => {
        const existingMobile = await this.getPersonMobile(personId);
        if (existingMobile) {
            if (existingMobile.Id > 0) {
                existingMobile.IsDeleted = true;
                await this.updateEntity(existingMobile, true);
            } else {
                await this.hardDelete(existingMobile.Id);
            }
        }
    };

    public insertOrUpdateEmail = async (personId: number, contact: IPersonContact): Promise<void> => {
        const contactMedia = await contactMediaService.getEmailContactMedia();
        contact.PersonId = personId;
        contact.MediaId = contactMedia.Id;
        const existingEmail = await this.getPersonEmail(personId);
        if (existingEmail) {
            await this.update("Id", existingEmail.Id, contact.toDbObject(), true);
        } else {
            await this.insert(contact);
        }
    };

    public deleteEmailIfExists = async (personId: number): Promise<void> => {
        const existingEmail = await this.getPersonEmail(personId);
        if (existingEmail) {
            if (existingEmail.Id > 0) {
                existingEmail.IsDeleted = true;
                await this.updateEntity(existingEmail, true);
            } else {
                await this.hardDelete(existingEmail.Id);
            }
        }
    };
}

export const personContactService = new PersonContactService();
