import CalendarEntry from "./CalendarEntry";

export default class CalendarModel {
    public Today: CalendarEntry[];
    public Tomorrow: CalendarEntry[];
    public HasReadPermission: boolean;

    constructor(today: CalendarEntry[], tomorrow: CalendarEntry[], hasReadPermission: boolean) {
        this.Today = today;
        this.Tomorrow = tomorrow;
        this.HasReadPermission = hasReadPermission;
    }

    public hasEntries(): boolean {
        return !!(this.Today.length + this.Tomorrow.length);
    }

    public noEntries(): boolean {
        return !this.hasEntries();
    }
}
