import { IChecklistCollection } from "../types/ChecklistCollection";
import { IPerson } from "../types/Person";

export default class AllPersonItemModel extends kendo.data.ObservableObject {
    public Id: number;
    public FirstName: string;
    public LastName: string;
    public PhoneNumber: string;
    public Email: string;
    public IsInactive: boolean;
    public ChecklistNames: string;
    private firstNameFirst: boolean;

    constructor(person: IPerson, email: string, phoneNumber: string, checklistName: IChecklistCollection[], firstNameFirst: boolean) {
        super();
        this.Id = person.Id;
        this.FirstName = person.FirstName;
        this.LastName = person.LastName;
        this.Email = email;
        this.PhoneNumber = phoneNumber;
        this.IsInactive = person.IsInactive;
        this.ChecklistNames = checklistName.map(x => x.Name).join(", ");
        this.firstNameFirst = firstNameFirst;
        super.init(this);
    }

    get DisplayNameHtml(): string {
        return this.firstNameFirst ? `<b>${this.FirstName}</b> ${this.LastName}` : `<b>${this.LastName}</b> ${this.FirstName}`;
    }

    get DisplayName(): string {
        return this.firstNameFirst ? `${this.FirstName} ${this.LastName}` : `${this.LastName} ${this.FirstName}`;
    }

    get Letter(): string {
        return this.LastName[0].toUpperCase();
    }
}
