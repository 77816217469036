import PlannedLessonModel from "../models/PlannedLessonModel";
import ActiveLesson, { activeLesson } from "../modules/ActiveLesson";
import { network } from "../modules/Network";
import { orientationChange } from "../modules/OrientationChange";
import Popup from "../modules/Popup";
import { Util } from "../modules/Util";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { BaseView } from "./BaseView";
import { personService } from "../services/PersonService";
import { checklistItemService } from "../services/ChecklistItemService";
import { educationService } from "../services/EducationService";
import { ratingService } from "../services/ChecklistRatingService";
import ChecklistItemModel from "../models/ChecklistItemModel";
import { translation } from "../services/TranslationService";
import { dal } from "../dal/Dal";

export default class PlanLesson extends BaseView {
    public backButtonText: string;
    public startAndSaveButtonVisible: boolean;

    private navPoint: string;
    private selectableLessons: kendo.data.DataSource;
    private selectedLessons: kendo.data.DataSource;
    private plannedLessons: Map<string, PlannedLessonModel>;
    private notePopup: Popup;
    private editor: kendo.ui.Editor;

    constructor() {
        super();
        this.notePopup = new Popup();
        this.set(
            "selectableLessons",
            kendo.data.DataSource.create({
                group: {
                    field: "Group",
                    dir: "asc",
                    compare: (a, b) => {
                        const compareA = Util.firstOrDefault<ChecklistItemModel>(a.items);
                        const compareB = Util.firstOrDefault<ChecklistItemModel>(b.items);
                        if (compareA && compareB) {
                            return compareA.TitleSort - compareB.TitleSort;
                        }
                        return 0;
                    }
                } as any,
                sort: { field: "ChapterSort", dir: "asc" },
                transport: {
                    read: async options => {
                        const education = await educationService.getSelectedEducation(Util.getEducationId(this.personId), this.personId);
                        const checklistModels = await checklistItemService.getChecklistModel(education.ChecklistCollectionId, true);
                        const ratings = await ratingService.getPreviousRatingModels(Util.getEducationId(this.personId));
                        for (const checklistModel of checklistModels) {
                            checklistModel.PreviouseRatings = ratings.filter(x => x.ChecklistItemId === checklistModel.Id);
                        }
                        options.success(checklistModels);
                    }
                }
            } as any)
        );

        this.set(
            "selectedLessons",
            kendo.data.DataSource.create({
                group: {
                    field: "Group",
                    dir: "asc",
                    compare: (a, b) => {
                        const compareA = Util.firstOrDefault<ChecklistItemModel>(a.items);
                        const compareB = Util.firstOrDefault<ChecklistItemModel>(b.items);
                        if (compareA && compareB) {
                            return compareA.TitleSort - compareB.TitleSort;
                        }
                        return 0;
                    }
                } as any,
                transport: {
                    read: options => {
                        options.success(this.getSelected());
                    }
                }
            } as any)
        );
        super.init(this);
    }

    public initView(): void {
        $("#selectableLessonsViewPlanLesson").show();
        $("#selectedLessonsViewPlanLesson").show();
        this.initEditor();
    }

    private initEditor() {
        this.editor = $("#noteEditorPL")
            .kendoEditor({
                tools: []
            })
            .data("kendoEditor");
        $(this.editor.body).keyup(() => {
            this.editor.update();
        });
        $(".editorToolbarWindow").remove();
    }

    public async loadData(): Promise<void> {
        const userAppsettings = await userAppSettingsService.getSettings();
        this.plannedLessons = userAppsettings.PlannedLessons;
        this.set("note", "");
        const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
        await this.selectableLessons.read();
        await this.selectedLessons.read();
        this.set("backButtonText", `${person.FirstName} ${person.LastName}`);
    }

    public showView(e: any): void {
        this.set("selectedLessons", this.selectedLessons);
        this.navPoint = e.view.params.navPoint ? e.view.params.navPoint : this.navPoint;
        this.plannedLessonHeightChanged();
        this.setStartAndSaveButtonsVisibility();
        orientationChange.FunctionList = [this.setListHeight];
    }

    private setListHeight = (): void => {
        const headerHeight = $("#headerPlanLesson").height();
        this.setSelectableLessonHeight(headerHeight);
        this.setSelectedLessonHeight(headerHeight);
    };

    private setSelectableLessonHeight(headerHeight: number): void {
        const height = window.innerHeight - headerHeight - 25;
        $("#selectableLessonsViewPlanLesson").height(height);
    }

    private setSelectedLessonHeight(headerHeight: number = null): void {
        if (!headerHeight) {
            headerHeight = $("#headerPlanLesson").height();
        }
        const height = window.innerHeight - headerHeight - $("#saveOrStartLessonPlanLesson").height() - 80;
        $("#selectedLessonsViewPlanLesson").css("max-height", height);
    }

    private getSelected(): ChecklistItemModel[] {
        const selected: ChecklistItemModel[] = [];
        if (this.plannedLessons) {
            const plannedLesson = this.plannedLessons.get(PlanLesson.getStoredLessonKey(this.personId, Util.getEducationId(this.personId))) ?? this.plannedLessons.get(this.getOldStoredLessonKey());

            if (plannedLesson) {
                this.set("note", plannedLesson.Note);
                const selectable = this.selectableLessons.data() as unknown as ChecklistItemModel[];
                for (const planned of plannedLesson.ChecklistPoints) {
                    const selectedChecklistItem = selectable.find(x => x.Id === planned);
                    if (selectedChecklistItem) {
                        selected.push(selectedChecklistItem);
                        selectedChecklistItem.set("IsSelected", true);
                    }
                }
            }
        } else {
            this.plannedLessons = new Map<string, PlannedLessonModel>();
        }
        return selected;
    }

    public addItem(e): void {
        const item = this.selectableLessons.getByUid(e.data.uid) as unknown as ChecklistItemModel;
        if (this.itemIsNotSelected(item.Id)) {
            item.set("IsSelected", true);
            this.selectedLessons.add(item);
        }
        this.setStartAndSaveButtonsVisibility();
        this.plannedLessonHeightChanged();
    }

    private itemIsNotSelected(id: number): boolean {
        const data = this.selectedLessons.data();
        return !data.some((x: any) => x.Id === id);
    }

    public removeItem(e) {
        this.selectedLessons.remove(this.selectedLessons.getByUid(e.data.uid));
        this.selectableLessons.getByUid(e.data.uid).set("IsSelected", false);
        this.setStartAndSaveButtonsVisibility();
        this.plannedLessonHeightChanged();
    }

    private plannedLessonHeightChanged() {
        const listHeight = $("#selectedLessonsList").height();
        $("#selectedLessonsViewPlanLesson").height(listHeight);
    }

    public getNavPoint(): string {
        return this.navPoint;
    }

    public saveLesson = async (): Promise<void> => {
        const plannedLesson = new PlannedLessonModel();
        plannedLesson.ChecklistPoints = this.getPlannedLessondIds();
        plannedLesson.Note = this.get<string>("note");
        this.plannedLessons.set(PlanLesson.getStoredLessonKey(this.personId, Util.getEducationId(this.personId)), plannedLesson);
        const userAppsettings = await userAppSettingsService.getSettings();
        userAppsettings.PlannedLessons = this.plannedLessons;
        await userAppSettingsService.updateEntity(userAppsettings, true);
        Util.showNotification(translation.t("plan-lesson.lesson-storred"), "success");
        this.back();
    };

    public async startLesson(): Promise<void> {
        const plannedLessonIds = this.getPlannedLessondIds();
        const checklistItems = await checklistItemService.getPlannedChecklistItems(plannedLessonIds);
        const lessons = [];
        for (const plannedLessonId of plannedLessonIds) {
            const checklistItem = checklistItems.find(x => x.Id === plannedLessonId);
            if (checklistItem) {
                lessons.push(checklistItem);
            }
        }
        while (activeLesson.length > 0) {
            activeLesson.pop();
        }
        activeLesson.push(new ActiveLesson(lessons, "views/planLesson.html", this.personId));
        activeLesson[0].navigate();
    }

    public openPlanLessonNote = async () => {
        await this.notePopup.renderContentEditPopup("#popupNotePL");
        this.editor.value(this.get<string>("note"));
    };

    public closeNotePopup = () => {
        this.editor.value(this.get<string>("note"));
        this.notePopup.closePopup();
    };

    public saveNote = () => {
        this.set("note", this.editor.value());
        this.notePopup.closePopup();
    };

    private getPlannedLessondIds = (): number[] => {
        const selected = this.selectedLessons.data();
        return selected.map((x: any) => x.Id);
    };

    public static getStoredLessonKey(personId: number, educationId: number): string {
        return `plannedLessonDbName${dal.getDbName()}person${personId}user${network.getUserId()}education${educationId}`;
    }

    private getOldStoredLessonKey(): string {
        return `plannedLessonDbName${dal.getDbName()}person${this.personId}user${network.getUserId()}`;
    }

    private setStartAndSaveButtonsVisibility = () => {
        this.set("startAndSaveButtonVisible", this.selectedLessons.data().length);
        this.setSelectedLessonHeight();
    };
}

export const planLessonModel = new PlanLesson();
