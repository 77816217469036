import { IJobPosition, IJobPositionDto, JobPosition } from "./JobPosition";

export enum RabattType {
    Percent,
    Amount
}

export interface IBaseJobPricePosition {
    Amount: number;
    Price: number;
    DiscountType: RabattType;
    DiscountPercent: number;
    DiscountAmount: number;
    ChargedPositionId: number;
}

export interface IJobPricePositionDto extends IBaseJobPricePosition, IJobPositionDto {}
export interface IJobPricePosition extends IBaseJobPricePosition, IJobPosition {}

export abstract class JobPricePosition<TEntity extends IJobPricePosition, TDto extends IJobPricePositionDto> extends JobPosition<TEntity, TDto> implements IJobPricePosition {
    public abstract calculateTotalPrice(): number;

    public Amount: number;
    public Price: number;
    public DiscountType: RabattType;
    public DiscountPercent: number;
    public DiscountAmount: number;
    public ChargedPositionId: number;

    protected applyDiscount(price: number): number {
        const discount = this.DiscountType === RabattType.Percent ? (price * this.DiscountPercent) / 100 : this.DiscountAmount;
        return price - discount;
    }

    public populateFromDb(dbItem: TEntity): void {
        super.populateFromDb(dbItem);
        this.Amount = dbItem.Amount;
        this.Price = dbItem.Price;
        this.DiscountType = dbItem.DiscountType;
        this.DiscountPercent = dbItem.DiscountPercent;
        this.DiscountAmount = dbItem.DiscountAmount;
        this.ChargedPositionId = dbItem.ChargedPositionId;
    }

    public populateFromOData(oDataItem: TDto): void {
        super.populateFromOData(oDataItem);
        this.Amount = oDataItem.Amount;
        this.Price = oDataItem.Price;
        this.DiscountType = oDataItem.DiscountType;
        this.DiscountPercent = oDataItem.DiscountPercent;
        this.DiscountAmount = oDataItem.DiscountAmount;
        this.ChargedPositionId = oDataItem.ChargedPositionId;
    }

    public toODataObject(): TDto {
        const oDataObject = super.toODataObject();
        oDataObject.Amount = this.Amount;
        oDataObject.Price = this.Price;
        oDataObject.DiscountPercent = this.DiscountPercent ? this.DiscountPercent : 0;
        oDataObject.DiscountAmount = this.DiscountAmount ? this.DiscountAmount : 0;
        oDataObject.ChargedPositionId = this.ChargedPositionId;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set("Amount", this.Amount);
        dbObject.set("Price", this.Price);
        dbObject.set("DiscountType", this.DiscountType);
        dbObject.set("DiscountPercent", this.DiscountPercent);
        dbObject.set("DiscountAmount", this.DiscountAmount);
        dbObject.set("ChargedPositionId", this.ChargedPositionId);
        return dbObject;
    }

    protected copyFrom(entity: TEntity): void {
        super.copyFrom(entity);
        this.Amount = entity.Amount;
        this.Price = entity.Price;
        this.DiscountType = entity.DiscountType;
        this.DiscountPercent = entity.DiscountPercent;
        this.DiscountAmount = entity.DiscountAmount;
    }
}
