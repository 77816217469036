import { logger } from "../modules/Logger";
import { IDal } from "./IDal";
import Transaction from "./Wasm/Transaction";

export default class Migration {
    private readonly migrations: ((tx: SQLitePlugin.Transaction | Transaction) => void)[] = [];
    private static readonly BASE_FIELDS = "Id INTEGER PRIMARY KEY NOT NULL, Uuid TEXT, IsDirty BOOLEAN, IsNew BOOLEAN, IsDeleted BOOLEAN";
    private static readonly CREATE_TABLE = "CREATE TABLE IF NOT EXISTS";
    public static readonly ALL_TABLES = [
        "Activities",
        "AddressTypes",
        "Bills",
        "ChecklistItems",
        "ChecklistCollections",
        "ChecklistRatings",
        "CompanyAppSettings",
        "CompanySettings",
        "Conditions",
        "ContactMedia",
        "Currencies",
        "CurrencyValues",
        "CurrentLessons",
        "DrivingStudentDocuments",
        "DrivingTests",
        "Educations",
        "Jobs",
        "JobPositions",
        "LastsyncDates",
        "Lessons",
        "Lfas",
        "LocalData",
        "Logs",
        "OrphyDriveJobPositions",
        "PaymentWays",
        "Persons",
        "PersonAddresses",
        "PersonContacts",
        "Products",
        "Profiles",
        "Salutations",
        "Tests",
        "TimeTrackings",
        "Units",
        "Users",
        "UserAppSettings",
        "UserSettings"
    ];

    constructor(private readonly db: IDal) {
        this.migrations[0] = this.initial;
        this.migrations[1] = this.v1to2;
        this.migrations[2] = this.v2to3;
        this.migrations[3] = this.v3to4;
        // Don't remove was always here, this is not wrong
        this.migrations[4] = this.v3to4;
        this.migrations[5] = this.v4to5;
        this.migrations[6] = this.v5to6;
        this.migrations[7] = this.v6to7;
        this.migrations[8] = this.v7to8;
        this.migrations[9] = this.v8to9;
        // Register new migrations here
    }

    public async migrate() {
        const currentVersion = await this.getVersion();
        await this.db.withTransaction(tx => {
            for (let version = currentVersion + 1; version < this.migrations.length; version++) {
                logger.logInfo(`Migrate from version ${version - 1} to ${version}`);
                this.migrations[version](tx);
                tx.executeSql(`UPDATE version SET Version = ${version}`);
            }
        });
    }

    private async getVersion(): Promise<number> {
        await this.db.execute("CREATE TABLE IF NOT EXISTS version (Version INTEGER);");
        const version = (await this.db.firstOrDefault<{ Version: number }>("SELECT COALESCE(MAX(version), -1) AS Version FROM version;")).Version;
        if (version < 0) {
            await this.db.execute(`INSERT INTO version (version) VALUES (?)`, [version]);
        }
        return version;
    }

    static getTables(): string[] {
        return Migration.ALL_TABLES;
    }

    private initial(tx: SQLitePlugin.Transaction | Transaction): void {
        // This creates the database as released in app version 4.0
        tx.executeSql(`${Migration.CREATE_TABLE} Activities (${Migration.BASE_FIELDS}, Name TEXT, Type INTEGER, TranslatedDisplayName TEXT, Verrechnbar BOOLEAN, Stundenansatz REAL)`);

        tx.executeSql(`${Migration.CREATE_TABLE} AddressTypes (${Migration.BASE_FIELDS}, Name TEXT, Kind INTEGER NOT NULL, TranslatedDisplayName TEXT)`);

        tx.executeSql(
            `${Migration.CREATE_TABLE} Bills (${Migration.BASE_FIELDS}, PaymentWayId INTEGER, Name TEXT, Date TEXT, PersonId INTEGER, ConditionId INTEGER, CurrencyId INTEGER, CurrencyValueId INTEGER, CustomId TEXT, Anschrift TEXT, ContactPersonId INTEGER, DokumentKopfZeile TEXT, DocumentAfterList TEXT, DokumentFussZeile TEXT, BillStatus INTEGER, JobMasterId INTEGER, LastStateChange TEXT, DokumentTitel TEXT, PaidAt TEXT, NeedsStateChange BOOLEAN, SendBill BOOLEAN)`
        );

        tx.executeSql(
            `${Migration.CREATE_TABLE} ChecklistItems (${Migration.BASE_FIELDS}, Label TEXT, Overview BLOB, ParentId INTEGER, PreviousSiblingId INTEGER, NextSiblingId INTEGER, ChecklistCollectionId INTEGER, ContentDirty BOOLEAN)`
        );

        tx.executeSql(`${Migration.CREATE_TABLE} ChecklistCollections (${Migration.BASE_FIELDS}, Name TEXT, HasVku BOOLEAN)`);

        tx.executeSql(
            `${Migration.CREATE_TABLE} ChecklistRatings (${Migration.BASE_FIELDS}, RatingValue INTEGER, Note TEXT, LessonId INTEGER, NewRating, ChecklistItemId INTEGER, IsCurrentRating BOOLEAN, IsUpdateRating BOOLEAN, OldNote TEXT, OldRatingValue INTEGER)`
        );

        tx.executeSql(
            `${Migration.CREATE_TABLE} CompanyAppSettings (${Migration.BASE_FIELDS}, PaymentConditionId INTEGER, LessonDuration INTEGER, PricePerLesson REAL, ChecklistPricing TEXT, LockEditLesson BOOLEAN, LessonEditLockDays INTEGER, SaveCashPayment BOOLEAN, IsInitialized BOOLEAN, RatingLabels TEXT, WeeklyWorkingHours TEXT, SendBills BOOLEAN, ForceEasyAcccounting BOOLEAN)`
        );

        tx.executeSql(`${Migration.CREATE_TABLE} CompanySettings (${Migration.BASE_FIELDS}, CompanyName TEXT)`);

        tx.executeSql(`${Migration.CREATE_TABLE} Conditions (${Migration.BASE_FIELDS}, Name TEXT, CountOfDays INTEGER, Skonto REAL, Type INTEGER NOT NULL)`);

        tx.executeSql(`${Migration.CREATE_TABLE} ContactMedia (${Migration.BASE_FIELDS}, Name TEXT, MediaType INTEGER NOT NULL, InputType INTEGER NOT NULL, TranslatedDisplayName TEXT)`);

        tx.executeSql(`${Migration.CREATE_TABLE} LastsyncDates (TableName TEXT PRIMARY KEY NOT NULL, LastSyncDate TEXT)`);

        tx.executeSql(`${Migration.CREATE_TABLE} Currencies (${Migration.BASE_FIELDS}, Code TEXT)`);

        tx.executeSql(`${Migration.CREATE_TABLE} CurrencyValues (${Migration.BASE_FIELDS}, CurrencyId INTEGER, Value REAL, ValidFrom TEXT)`);

        tx.executeSql(`${Migration.CREATE_TABLE} DrivingTests (${Migration.BASE_FIELDS},  Vku1 BOOLEAN, Vku2 BOOLEAN, Vku3 BOOLEAN, Vku4 BOOLEAN, VkuDoneDate TEXT)`);

        tx.executeSql(`${Migration.CREATE_TABLE} DrivingStudentDocuments (${Migration.BASE_FIELDS}, PersonId INTEGER, BlobId INTEGER, Image BLOB)`);

        tx.executeSql(
            `${Migration.CREATE_TABLE} Educations (${Migration.BASE_FIELDS}, ChecklistCollectionId INTEGER, PersonId INTEGER, ResponsibleDrivingTeacherId INTEGER, MeetingPointAddress TEXT, Note TEXT, JobId INTEGER)`
        );

        tx.executeSql(
            `${Migration.CREATE_TABLE} Jobs (${Migration.BASE_FIELDS}, PaymentWayId INTEGER, Name TEXT, Date TEXT, PersonId INTEGER, ConditionId INTEGER, CurrencyId INTEGER, CurrencyValueId INTEGER, CustomId TEXT, Anschrift TEXT, ContactPersonId INTEGER, DokumentKopfZeile TEXT, DocumentAfterList TEXT, DokumentFussZeile TEXT, JobStatus INTEGER)`
        );

        tx.executeSql(
            `${Migration.CREATE_TABLE} JobPositions (${Migration.BASE_FIELDS}, Discriminator TEXT NOT NULL, Position TEXT, SortOrder INTEGER, UnitId INTEGER, ParentId INTEGER, Amount INTEGER, Price INTEGER, DiscountType INTEGER, DiscountPercent INTEGER, DiscountAmount INTEGER, ChargedPositionId INTEGER, ActivityId INTEGER, ProductId INTEGER)`
        );

        tx.executeSql(
            `${Migration.CREATE_TABLE} Lessons (${Migration.BASE_FIELDS}, Date TEXT, Count INTEGER, TimeTrackingId Integer, EducationId INTEGER, Note TEXT, IsCurrentLesson BOOLEAN, IsUpdateLesson BOOLEAN)`
        );

        tx.executeSql(`${Migration.CREATE_TABLE} Lfas (${Migration.BASE_FIELDS}, LfaNumber TEXT, LfaExpireDate TEXT, Pid TEXT, Pin TEXT, FaberNumber TEXT, LfaImage TEXT)`);

        tx.executeSql(
            `${Migration.CREATE_TABLE} LocalData (${Migration.BASE_FIELDS}, LastSync TEXT, SubscriptionNotification BOOLEAN, OrphyReadonly BOOLEAN, OrphyDriveOne BOOLEAN, CanUpdateCompanySettings BOOLEAN, EasyAccounting BOOLEAN)`
        );
        tx.executeSql(`${Migration.CREATE_TABLE} Logs (${Migration.BASE_FIELDS}, LogLevel TEXT, Log TEXT, Date TEXT, UserId INTEGER, CompanyId INTEGER)`);

        tx.executeSql(`${Migration.CREATE_TABLE} PaymentWays (${Migration.BASE_FIELDS}, Name TEXT, Type INTEGER NOT NULL)`);
        //
        tx.executeSql(`${Migration.CREATE_TABLE} OrphyDriveJobPositions (${Migration.BASE_FIELDS}, JobPositionId INTEGER, TimeTrackingId INTEGER, LessonCount REAL)`);

        tx.executeSql(
            `${Migration.CREATE_TABLE} Persons (${Migration.BASE_FIELDS}, Sex INTEGER, SalutationId INTEGER, AnsprechspartnerId INTEGER, FirstName TEXT, LastName TEXT, Birthday TEXT, Avatar TEXT, Profession TEXT, MeetingPointAddress TEXT, IsInactive BOOLEAN)`
        );

        tx.executeSql(
            `${Migration.CREATE_TABLE} PersonAddresses (${Migration.BASE_FIELDS}, PersonId INTEGER, Street TEXT, StreetNumber TEXT, ZipCode TEXT, City TEXT, State TEXT, Country TEXT, AddressZusatz TEXT, AddressTypeId INTEGER, AddressType TEXT, IsPrefered BOOLEAN)`
        );

        tx.executeSql(`${Migration.CREATE_TABLE} PersonContacts (${Migration.BASE_FIELDS}, PersonId INTEGER, MediaId INTEGER, NameInMedia TEXT, IsPrefered BOOLEAN TEXT)`);

        tx.executeSql(`${Migration.CREATE_TABLE} Products (${Migration.BASE_FIELDS}, Name TEXT, Beschreibung TEXT, Verkaufspreis REAL, LessonCount REAL)`);

        tx.executeSql(
            `${Migration.CREATE_TABLE} Profiles (${Migration.BASE_FIELDS}, NameFirst TEXT, NameFamily TEXT, Street TEXT, StreetNumber TEXT, Plz TEXT, Ort TEXT, Sex INTEGER, Email TEXT, PhoneNumber TEXT)`
        );

        tx.executeSql(`${Migration.CREATE_TABLE} Salutations (${Migration.BASE_FIELDS}, TranslatedNameWithPlaceholder TEXT)`);

        tx.executeSql(`${Migration.CREATE_TABLE} Tests (${Migration.BASE_FIELDS}, TestDate TEXT, TestNote TEXT, DrivingTestId INTEGER, TestPassed BOOLEAN)`);

        tx.executeSql(
            `${Migration.CREATE_TABLE} TimeTrackings (${Migration.BASE_FIELDS}, IssueDate TEXT, DurationTicks INTEGER, Title TEXT, Status INTEGER, ActivityId INTEGER, MitarbeiterId INTEGER, PersonId INTEGER, Beschreibung TEXT)`
        );

        tx.executeSql(`${Migration.CREATE_TABLE} Units (${Migration.BASE_FIELDS}, Name TEXT, Type INTEGER NOT NULL)`);

        tx.executeSql(`${Migration.CREATE_TABLE} Users (${Migration.BASE_FIELDS}, DisplayName TEXT, PersonId INTEGER)`);

        tx.executeSql(
            `${Migration.CREATE_TABLE} UserAppSettings (${Migration.BASE_FIELDS}, UserId INTEGER, CalendarList TEXT, SyncRestriction TEXT, ShowAllUserData BOOLEAN, PlannedLessons TEXT, LastSelectedPaymentWay BOOLEAN, ShowSyncMessage BOOLEAN, BillEmail TEXT, CollapseEducationCard BOOLEAN)`
        );

        tx.executeSql(
            `${Migration.CREATE_TABLE} UserSettings (${Migration.BASE_FIELDS}, MailFrom TEXT, DefaultCompanyLogo TEXT, DefaultCompanyLogo_INTERNAL TEXT, UserId INTEGER, CompanyId INTEGER, Dokument_Rechnung_Fusszeile TEXT, Dokument_Rechnung_Konditionen TEXT, Dokument_Rechnung_Kopfzeile TEXT)`
        );
    }

    private v1to2(tx: SQLitePlugin.Transaction | Transaction): void {
        tx.executeSql("DELETE FROM Tests WHERE Id IN (SELECT MAX(Id) FROM tests WHERE Id < 0 GROUP BY Uuid HAVING COUNT(*) > 1)");
        tx.executeSql("DELETE FROM Tests WHERE Id IN (SELECT MAX(Id) FROM tests WHERE Id < 0 GROUP BY Uuid HAVING COUNT(*) > 1)");
        tx.executeSql("DELETE FROM Tests WHERE Id IN (SELECT MAX(Id) FROM tests WHERE Id < 0 GROUP BY Uuid HAVING COUNT(*) > 1)");
        tx.executeSql("DELETE FROM Tests WHERE Id IN (SELECT MAX(Id) FROM tests WHERE Id < 0 GROUP BY Uuid HAVING COUNT(*) > 1)");

        tx.executeSql("DELETE FROM Tests WHERE Id IN (SELECT MIN(Id) FROM tests GROUP BY Uuid HAVING COUNT(*) > 1)");
    }

    private v2to3(tx: SQLitePlugin.Transaction | Transaction): void {
        tx.executeSql("CREATE TABLE tmp_UserAppSettings AS SELECT * FROM UserAppSettings");
        tx.executeSql("DROP TABLE UserAppSettings");
        tx.executeSql(
            "CREATE TABLE IF NOT EXISTS UserAppSettings (" +
                "Id INTEGER PRIMARY KEY NOT NULL, " +
                "Uuid TEXT, " +
                "IsDirty BOOLEAN, " +
                "IsNew BOOLEAN, " +
                "IsDeleted BOOLEAN, " +
                "UserId INTEGER, " +
                "CalendarList TEXT, " +
                "SyncRestriction BOOLEAN, " +
                "ShowAllUserData BOOLEAN, " +
                "PlannedLessons TEXT, " +
                "LastSelectedPaymentWay BOOLEAN, " +
                "ShowSyncMessage BOOLEAN, " +
                "BillEmail TEXT, " +
                "CollapseEducationCard TEXT," +
                "FirstNameFirst BOOLEAN" +
                ")"
        );
        // Copy values from old table to new table
        tx.executeSql(
            `INSERT INTO UserAppSettings 
            (Id, Uuid, IsDirty, IsNew, IsDeleted, UserId, CalendarList, SyncRestriction, ShowAllUserData, PlannedLessons, LastSelectedPaymentWay, ShowSyncMessage, BillEmail, CollapseEducationCard, FirstNameFirst)
            SELECT Id, Uuid, IsDirty, IsNew, IsDeleted, UserId, CalendarList, SyncRestriction, ShowAllUserData, PlannedLessons, LastSelectedPaymentWay, ShowSyncMessage, BillEmail, CollapseEducationCard, ? 
            FROM tmp_UserAppSettings`,
            [false]
        );
        // Drop old table
        tx.executeSql("DROP TABLE tmp_UserAppSettings");
    }

    private v3to4(tx: SQLitePlugin.Transaction | Transaction): void {
        tx.executeSql("DELETE FROM ChecklistRatings WHERE Id IN (SELECT MAX(Id) FROM ChecklistRatings WHERE Id < 0 GROUP BY Uuid HAVING COUNT(*) > 1)");
        tx.executeSql("DELETE FROM ChecklistRatings WHERE Id IN (SELECT MAX(Id) FROM ChecklistRatings WHERE Id < 0 GROUP BY Uuid HAVING COUNT(*) > 1)");
        tx.executeSql("DELETE FROM ChecklistRatings WHERE Id IN (SELECT MAX(Id) FROM ChecklistRatings WHERE Id < 0 GROUP BY Uuid HAVING COUNT(*) > 1)");
        tx.executeSql("DELETE FROM ChecklistRatings WHERE Id IN (SELECT MAX(Id) FROM ChecklistRatings WHERE Id < 0 GROUP BY Uuid HAVING COUNT(*) > 1)");

        tx.executeSql("DELETE FROM ChecklistRatings WHERE Id IN (SELECT MIN(Id) FROM ChecklistRatings GROUP BY Uuid HAVING COUNT(*) > 1)");
        tx.executeSql("UPDATE JobPositions SET Price = 0 WHERE Price IS NULL AND (Discriminator = 'JobProductPosition' OR Discriminator = 'JobDefaultPosition')");
    }

    private v4to5(tx: SQLitePlugin.Transaction | Transaction): void {
        tx.executeSql("CREATE TABLE tmp_LocalData AS SELECT * FROM LocalData");
        tx.executeSql("DROP TABLE LocalData");
        tx.executeSql(
            "CREATE TABLE IF NOT EXISTS LocalData (" +
                "Id INTEGER PRIMARY KEY NOT NULL, " +
                "Uuid TEXT, " +
                "IsDirty BOOLEAN, " +
                "IsNew BOOLEAN, " +
                "IsDeleted BOOLEAN, " +
                "LastSync TEXT, " +
                "SubscriptionNotification BOOLEAN, " +
                "OrphyReadonly BOOLEAN, " +
                "OrphyDriveOne BOOLEAN, " +
                "CanUpdateCompanySettings BOOLEAN, " +
                "EasyAccounting BOOLEAN, " +
                "TrialOver BOOLEAN" +
                ")"
        );
        // Copy values from old table to new table
        tx.executeSql(
            `INSERT INTO LocalData 
            (Id, Uuid, IsDirty, IsNew, IsDeleted, LastSync, SubscriptionNotification, OrphyReadonly, OrphyDriveOne, CanUpdateCompanySettings, EasyAccounting, TrialOver) 
            SELECT Id, Uuid, IsDirty, IsNew, IsDeleted, LastSync, SubscriptionNotification, OrphyReadonly, OrphyDriveOne, CanUpdateCompanySettings, EasyAccounting, ?
             FROM tmp_LocalData`,
            [false]
        );
        tx.executeSql("DROP TABLE tmp_LocalData");
    }

    private v5to6(tx: SQLitePlugin.Transaction | Transaction): void {
        tx.executeSql("CREATE TABLE tmp_CompanyAppSettings AS SELECT * FROM CompanyAppSettings");
        tx.executeSql("DROP TABLE CompanyAppSettings");
        tx.executeSql(
            "CREATE TABLE IF NOT EXISTS CompanyAppSettings (" +
                "Id INTEGER PRIMARY KEY NOT NULL, " +
                "Uuid TEXT, " +
                "IsDirty BOOLEAN, " +
                "IsNew BOOLEAN, " +
                "IsDeleted BOOLEAN, " +
                "PaymentConditionId INTEGER," +
                "LessonDuration INTEGER," +
                "PricePerLesson REAL," +
                "ChecklistPricing TEXT," +
                "LockEditLesson BOOLEAN," +
                "LessonEditLockDays INTEGER," +
                "SaveCashPayment BOOLEAN, " +
                "IsInitialized BOOLEAN," +
                "RatingLabels TEXT," +
                "WeeklyWorkingHours TEXT," +
                "SendBills BOOLEAN," +
                "ForceEasyAcccounting BOOLEAN," +
                "DisablePriceChange BOOLEAN" +
                ")"
        );
        // Copy values from old table to new table
        tx.executeSql(
            `INSERT INTO CompanyAppSettings 
            (Id, Uuid, IsDirty, IsNew, IsDeleted, PaymentConditionId, LessonDuration, PricePerLesson, ChecklistPricing, LockEditLesson, LessonEditLockDays, SaveCashPayment, IsInitialized, RatingLabels, WeeklyWorkingHours, SendBills, ForceEasyAcccounting, DisablePriceChange)
            SELECT Id, Uuid, IsDirty, IsNew, IsDeleted, PaymentConditionId, LessonDuration, PricePerLesson, ChecklistPricing, LockEditLesson, LessonEditLockDays, SaveCashPayment, IsInitialized, RatingLabels, WeeklyWorkingHours, SendBills, ForceEasyAcccounting, ?
            FROM tmp_CompanyAppSettings`,
            [false]
        );
        tx.executeSql("DROP TABLE tmp_CompanyAppSettings");
    }

    private v6to7(tx: SQLitePlugin.Transaction | Transaction): void {
        tx.executeSql("DELETE FROM LastSyncDates WHERE TableName = 'companyappsettings'");
    }

    private v7to8(tx: SQLitePlugin.Transaction | Transaction): void {
        tx.executeSql("DROP TABLE IF EXISTS CurrencyValues");
    }

    private v8to9(tx: SQLitePlugin.Transaction | Transaction): void {
        tx.executeSql("CREATE TABLE tmp_Salutations AS SELECT * FROM Salutations");
        tx.executeSql("DROP TABLE Salutations");
        tx.executeSql(
            "CREATE TABLE IF NOT EXISTS Salutations (" +
                "Id INTEGER PRIMARY KEY NOT NULL, " +
                "Uuid TEXT, " +
                "IsDirty BOOLEAN, " +
                "IsNew BOOLEAN, " +
                "IsDeleted BOOLEAN, " +
                "Name TEXT," +
                "Type INTEGER" +
                ")"
        );
        // Copy values from old table to new table
        tx.executeSql("INSERT INTO Salutations (Id, Uuid, IsDirty, IsNew, IsDeleted, Name, Type) SELECT Id, Uuid, IsDirty, IsNew, IsDeleted, TranslatedNameWithPlaceholder, 0 FROM tmp_Salutations");
        // Setting Orphys default values
        tx.executeSql("UPDATE Salutations SET Type = 1 WHERE Id = 1");
        tx.executeSql("UPDATE Salutations SET Type = 2 WHERE Id = 2");
        tx.executeSql("UPDATE Salutations SET Type = 3 WHERE Id = 3");
        tx.executeSql("UPDATE Salutations SET Type = 4 WHERE Id = 4");

        tx.executeSql("DROP TABLE tmp_Salutations");

        tx.executeSql("CREATE TABLE tmp_AddressTypes AS SELECT * FROM AddressTypes");
        tx.executeSql("DROP TABLE AddressTypes");
        tx.executeSql(
            "CREATE TABLE IF NOT EXISTS AddressTypes (" +
                "Id INTEGER PRIMARY KEY NOT NULL, " +
                "Uuid TEXT, " +
                "IsDirty BOOLEAN, " +
                "IsNew BOOLEAN, " +
                "IsDeleted BOOLEAN, " +
                "Name TEXT," +
                "Type INTEGER" +
                ")"
        );
        // Copy values from old table to new table
        tx.executeSql("INSERT INTO AddressTypes (Id, Uuid, IsDirty, IsNew, IsDeleted, Name, Type) SELECT Id, Uuid, IsDirty, IsNew, IsDeleted, TranslatedDisplayName, 0 FROM tmp_AddressTypes");
        // Setting Orphys default values
        tx.executeSql("UPDATE AddressTypes SET Type = 1 WHERE Id = 1");
        tx.executeSql("UPDATE AddressTypes SET Type = 2 WHERE Id = 2");

        tx.executeSql("DROP TABLE tmp_AddressTypes");

        tx.executeSql("CREATE TABLE tmp_UserSettings AS SELECT * FROM UserSettings");
        tx.executeSql("DROP TABLE UserSettings");
        tx.executeSql(
            "CREATE TABLE IF NOT EXISTS UserSettings (" +
                "Id INTEGER PRIMARY KEY NOT NULL, " +
                "Uuid TEXT, " +
                "IsDirty BOOLEAN, " +
                "IsNew BOOLEAN, " +
                "IsDeleted BOOLEAN, " +
                "MailFrom TEXT," +
                "DefaultCompanyLogo TEXT," +
                "DefaultCompanyLogo_INTERNAL TEXT," +
                "UserId INTEGER," +
                "CompanyId INTEGER," +
                "Dokument_Rechnung_Fusszeile TEXT," +
                "Dokument_Rechnung_Konditionen TEXT," +
                "Dokument_Rechnung_Kopfzeile TEXT," +
                "Language TEXT" +
                ")"
        );

        // Copy values from old table to new table
        tx.executeSql(
            `INSERT INTO UserSettings (Id, Uuid, IsDirty, IsNew, IsDeleted, DefaultCompanyLogo, DefaultCompanyLogo_INTERNAL, UserId, CompanyId, Dokument_Rechnung_Fusszeile, Dokument_Rechnung_Konditionen, Dokument_Rechnung_Kopfzeile, Language) ` +
                `SELECT Id, Uuid, IsDirty, IsNew, IsDeleted, DefaultCompanyLogo, DefaultCompanyLogo_INTERNAL, UserId, CompanyId, Dokument_Rechnung_Fusszeile, Dokument_Rechnung_Konditionen, Dokument_Rechnung_Kopfzeile, 'de' FROM tmp_UserSettings`
        );
        tx.executeSql("DROP TABLE tmp_UserSettings");
    }
}
