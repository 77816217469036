import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import Currency, { ICurrency, ICurrencyDto } from "../types/Currency";
import { BaseNoHistoryService } from "./BaseNoHistoryService";

export default class CurrencyService extends BaseNoHistoryService<ICurrency, ICurrencyDto> {
    private readonly TABLE_NAME = "Currencies";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ICurrencyDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ICurrency>());
    }

    public getApiRoute(): string {
        return `${network.API}/${Currency.EntityTypeId}`;
    }

    protected createEntityFromDb(item: ICurrency): Currency {
        const currencyEntity = new Currency();
        currencyEntity.populateFromDb(item);
        return currencyEntity;
    }

    protected createEntityFromOData(item: ICurrencyDto): Currency {
        const currencyEntity = new Currency();
        currencyEntity.populateFromOData(item);
        return currencyEntity;
    }

    public getDefaultCurrency = async (): Promise<ICurrency> => {
        const items = await this.getItems(`LOWER(Code) = 'chf'`, null, 1);
        return Util.firstOrDefault(items);
    };
}

export const currencyService = new CurrencyService();
