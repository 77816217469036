import TimeTrackingGroupModel from "../models/TimeTrackingGroupModel";
import DateTime from "../modules/DateTime";
import { companySettingsService } from "../services/CompanySettingsService";
import { personAddressService } from "../services/PersonAddressService";
import { personService } from "../services/PersonService";
import { translation } from "../services/TranslationService";
import ListExport from "./ListExport";

export default class CompletedDrivingLessonExport extends ListExport {
    public async createExport(args: any[]): Promise<void> {
        const timeTrackingGroups: TimeTrackingGroupModel[] = args[0];
        const lessonCount: number = args[1];
        const missedLessonCount: number = args[2];
        const personId: number = args[3];
        const checklistName: string = args[4];

        const person = await personService.getItemById(personId);
        const address = await personAddressService.getPersonAddress(personId);
        const companySettings = await companySettingsService.getSettings();

        const sheets: any[] = [];
        const rows: any[] = [];
        const columns = [{ width: 90 }, { width: 90 }, { width: 140 }, { width: 90 }, { width: 180 }];
        const colSpan = 5;
        const fileName = `${translation.t("export.lessons-filename-prefix")}-${DateTime.parseNumberDate(new Date())}.xlsx`;

        rows.push({ cells: [{ value: companySettings.CompanyName, colSpan: colSpan, bold: true }] });
        // rows.push({ cells: [{ value: userSettings.CompanyAddress, colSpan: colSpan }] });
        // rows.push({ cells: [{ value: `${userSettings.CompanyPlz} ${userSettings.CompanyCity}`, colSpan: colSpan }] });
        this.addEmptyRows(rows, colSpan, 1);
        rows.push({ cells: [{ value: translation.t("common.student"), colSpan: 2, bold: true }] });
        rows.push({ cells: [{ value: `${person.FirstName} ${person.LastName}` }] });
        this.addEmptyRows(rows, colSpan, 1);

        if (address) {
            this.addEmptyRows(rows, colSpan, 3);
            rows.push({ cells: [{ value: "", colSpan: 4 }, { value: `${person.FirstName} ${person.LastName}` }] });
            rows.push({ cells: [{ value: "", colSpan: 4 }, { value: `${address.Street} ${address.StreetNumber}` }] });
            rows.push({ cells: [{ value: "", colSpan: 4 }, { value: `${address.ZipCode} ${address.City}` }] });
            this.addEmptyRows(rows, colSpan, 4);
        }

        rows.push({
            cells: [
                { value: `${translation.t("completed-driving-lessons.header")}:`, colSpan: 2, bold: true },
                { value: lessonCount + missedLessonCount, colSpan: 1, textAlign: "right" }
            ]
        });
        rows.push({
            cells: [
                { value: `${translation.t("export.lessons-done")}:`, colSpan: 2, bold: true },
                { value: lessonCount, colSpan: 1, textAlign: "right" }
            ]
        });
        rows.push({
            cells: [
                { value: `${translation.t("common.category")}:`, colSpan: 2, bold: true },
                { value: checklistName ? checklistName.replace("&ouml;", "\u00f6") : translation.t("export.lessons-no-category"), colSpan: 1, textAlign: "right" }
            ]
        });
        rows.push(this.empty(colSpan));
        rows.push({
            cells: [
                { value: translation.t("common.date"), bold: true },
                { value: translation.t("common.time"), bold: true },
                { value: translation.t("common.duration"), bold: true },
                { value: translation.t("common.lesson"), bold: true },
                { value: translation.t("common.comment"), bold: true }
            ]
        });

        let printHeader = true;

        for (const group of timeTrackingGroups) {
            if (printHeader) {
                rows.push(this.empty(colSpan));
                rows.push({ cells: [{ value: this.getSheetTitle(group.HeaderName), colSpan: colSpan, bold: true }] });
                printHeader = false;
                rows.push(this.empty(colSpan));
            }
            for (const timeTracking of group.TimeTrackingItems) {
                rows.push({
                    cells: [
                        { value: DateTime.parseNumberDate(timeTracking.IssueDate), autoWidth: true },
                        { value: timeTracking.FromTo, autoWidth: true },
                        { value: timeTracking.Duration, autoWidth: true },
                        { value: timeTracking.LessonCount, autoWidth: true },
                        { value: timeTracking.MissedLesson ? `${timeTracking.MissedReason ? timeTracking.MissedReason : translation.t("export.lessons-missed-lesson")}` : "", autoWidth: true }
                    ]
                });
            }
            if (group.MonthlyTotal.LessonTotal) {
                rows.push(this.empty(colSpan));
                rows.push({
                    cells: [
                        { value: translation.t("common.total-month"), colSpan: 1, bold: true },
                        { value: group.MonthlyTotal.Title, colspan: colSpan, bold: true }
                    ]
                });
                printHeader = true;
            }
        }
        sheets.push({ columns: columns, rows: rows, showGridLines: true, title: translation.t("export.lessons-title") });
        this.openExcel(sheets, fileName);
    }
}
