import { IUser } from "../types/User";
import BaseModel from "./BaseModel";

export default class WeeklyWorkingHourModel extends BaseModel {
    public User: IUser;
    public WeeklyWorkingHours: number;
    public UserId: number;
    public UserPersonId: number;
    public DisplayName: string;

    constructor(user: IUser, weeklyWorkingHours: number) {
        super(user);
        this.User = user;
        this.WeeklyWorkingHours = weeklyWorkingHours;
        this.UserId = user.Id;
        this.UserPersonId = user.PersonId;
        this.DisplayName = user.DisplayName;
        super.init(this);
    }
}
