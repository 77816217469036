import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

export interface IBaseLesson {
    Count: number;
    TimeTrackingId: number;
    Note: string;
    EducationId: number;
}

export interface ILessonDto extends IBaseDto, IBaseLesson {
    Date: string;
}
export interface ILesson extends IBaseEntity, IBaseLesson {
    Date: Date;
    IsCurrentLesson: boolean;
    IsMissedLesson: boolean;
    IsUpdateLesson: boolean;
}

export default class Lesson extends BaseEntity<ILesson, ILessonDto> implements ILesson {
    public static get EntityTypeId(): string {
        return "2D4F07B3-AAFC-489C-A764-48C7559A33A8";
    }

    public Date: Date;
    public Count: number;
    public TimeTrackingId: number;
    public Note: string;
    public EducationId: number;
    public IsCurrentLesson: boolean;
    public IsMissedLesson: boolean;
    public IsUpdateLesson: boolean;

    public populateFromDb(dbItem: ILesson): void {
        super.populateFromDb(dbItem);
        this.Date = DateTime.fromDbDateTime(dbItem.Date);
        this.Count = dbItem.Count;
        this.TimeTrackingId = dbItem.TimeTrackingId;
        this.EducationId = dbItem.EducationId;
        this.Note = dbItem.Note;
        this.IsMissedLesson = this.getDbBoolean(dbItem.IsMissedLesson);
        this.IsCurrentLesson = this.getDbBoolean(dbItem.IsCurrentLesson);
        this.IsUpdateLesson = this.getDbBoolean(dbItem.IsUpdateLesson);
    }

    public populateFromOData(oDataItem: ILessonDto): void {
        super.populateFromOData(oDataItem);
        this.Date = DateTime.fromODataDateTime(oDataItem.Date);
        this.Count = oDataItem.Count;
        this.TimeTrackingId = oDataItem.TimeTrackingId;
        this.EducationId = oDataItem.EducationId;
        this.Note = oDataItem.Note;

        this.IsCurrentLesson = false;
        this.IsUpdateLesson = false;
    }

    public toODataObject(): ILessonDto {
        const oDataObject = super.toODataObject();
        oDataObject.Date = DateTime.toODataDateTime(this.Date as Date);
        oDataObject.Count = this.Count;
        oDataObject.TimeTrackingId = this.TimeTrackingId;
        oDataObject.EducationId = this.EducationId;
        oDataObject.Note = this.Note;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Lesson>(x => x.Date),
            DateTime.toDbDateTime(this.Date)
        );
        dbObject.set(
            nameof<Lesson>(x => x.Count),
            this.Count
        );
        dbObject.set(
            nameof<Lesson>(x => x.TimeTrackingId),
            this.TimeTrackingId
        );
        dbObject.set(
            nameof<Lesson>(x => x.EducationId),
            this.EducationId
        );
        dbObject.set(
            nameof<Lesson>(x => x.Note),
            this.Note
        );
        dbObject.set(
            nameof<Lesson>(x => x.IsCurrentLesson),
            this.IsCurrentLesson
        );
        dbObject.set(
            nameof<Lesson>(x => x.IsUpdateLesson),
            this.IsUpdateLesson
        );
        return dbObject;
    }
}
