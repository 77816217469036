import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import BaseEntity, { IBaseEntity, IBaseDto } from "./BaseEntity";

interface IBaseTest {
    TestDate: Date | string | null;
    TestNote: string;
    DrivingTestId: number;
    TestPassed: boolean | null;
}

export interface ITestDto extends IBaseDto, IBaseTest {}
export interface ITest extends IBaseEntity, IBaseTest {}

export default class Test extends BaseEntity<ITest, ITestDto> implements ITest {
    public static get EntityTypeId(): string {
        return "46E2E6B5-D5A3-4B99-89D7-5F216D4E0BD1";
    }

    public TestDate: Date | string | null;
    public TestNote: string;
    public DrivingTestId: number;
    public TestPassed: boolean | null;

    public populateFromDb(dbItem: ITest) {
        super.populateFromDb(dbItem);
        this.TestDate = dbItem.TestDate === null ? null : DateTime.fromDbDateTime(dbItem.TestDate as string);
        this.TestNote = dbItem.TestNote;
        this.DrivingTestId = dbItem.DrivingTestId;
        this.TestPassed = this.getNullAbleDbBoolean(dbItem.TestPassed);
    }

    public populateFromOData(oDataItem: ITestDto): void {
        super.populateFromOData(oDataItem);
        this.TestDate = oDataItem.TestDate === null ? null : DateTime.fromODataDateTime(oDataItem.TestDate as string);
        this.TestNote = oDataItem.TestNote;
        this.DrivingTestId = oDataItem.DrivingTestId;
        this.TestPassed = oDataItem.TestPassed;
    }

    public toODataObject(): ITestDto {
        const oDataObject = super.toODataObject();
        oDataObject.TestDate = this.TestDate === null ? null : DateTime.toODataDateTime(this.TestDate as Date);
        oDataObject.TestNote = this.TestNote;
        oDataObject.DrivingTestId = this.DrivingTestId;
        oDataObject.TestPassed = this.TestPassed;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Test>(x => x.TestDate),
            this.TestDate === null ? null : DateTime.toDbDateTime(this.TestDate as Date)
        );
        dbObject.set(
            nameof<Test>(x => x.TestNote),
            this.TestNote
        );
        dbObject.set(
            nameof<Test>(x => x.DrivingTestId),
            this.DrivingTestId
        );
        dbObject.set(
            nameof<Test>(x => x.TestPassed),
            this.TestPassed
        );
        return dbObject;
    }
}
