import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseLocalData {
    LastSync: Date | string;
    SubscriptionNotification: boolean;
    OrphyReadonly: boolean;
    OrphyDriveOne: boolean;
    CanUpdateCompanySettings: boolean;
    EasyAccounting: boolean;
    TrialOver: boolean;
}

export interface ILocalDataDto extends IBaseDto, IBaseLocalData {}
export interface ILocalData extends IBaseEntity, IBaseLocalData {}

export default class LocalData extends BaseEntity<ILocalData, ILocalDataDto> implements ILocalData {
    public LastSync: Date;
    public SubscriptionNotification: boolean;
    public OrphyReadonly: boolean;
    public OrphyDriveOne: boolean;
    public CanUpdateCompanySettings: boolean;
    public EasyAccounting: boolean;
    public TrialOver: boolean;

    public populateFromDb(dbItem: ILocalData): void {
        super.populateFromDb(dbItem);
        this.LastSync = DateTime.fromDbDateTime(dbItem.LastSync as string);
        this.SubscriptionNotification = this.getDbBoolean(dbItem.SubscriptionNotification);
        this.OrphyReadonly = this.getDbBoolean(dbItem.OrphyReadonly);
        this.OrphyDriveOne = this.getDbBoolean(dbItem.OrphyDriveOne);
        this.CanUpdateCompanySettings = this.getDbBoolean(dbItem.CanUpdateCompanySettings);
        this.EasyAccounting = this.getDbBoolean(dbItem.EasyAccounting);
        this.TrialOver = this.getDbBoolean(dbItem.TrialOver);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public populateFromOData(oDataItem: ILocalDataDto): void {}

    public toODataObject(): ILocalDataDto {
        return null; // Localdata is not pushed to Orphy
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<LocalData>(x => x.LastSync),
            DateTime.toDbDateTime(this.LastSync)
        );
        dbObject.set(
            nameof<LocalData>(x => x.SubscriptionNotification),
            this.SubscriptionNotification
        );
        dbObject.set(
            nameof<LocalData>(x => x.OrphyReadonly),
            this.OrphyReadonly
        );
        dbObject.set(
            nameof<LocalData>(x => x.OrphyDriveOne),
            this.OrphyDriveOne
        );
        dbObject.set(
            nameof<LocalData>(x => x.CanUpdateCompanySettings),
            this.CanUpdateCompanySettings
        );
        dbObject.set(
            nameof<LocalData>(x => x.EasyAccounting),
            this.EasyAccounting
        );
        dbObject.set(
            nameof<LocalData>(x => x.TrialOver),
            this.TrialOver
        );
        return dbObject;
    }
}
