import { translation } from "../services/TranslationService";
import { IContactMedia, InputType } from "../types/ContactMedia";
import PersonContact, { IPersonContact } from "../types/PersonContact";
import BaseModel from "./BaseModel";

export default class PersonContactModel extends BaseModel {
    public NameInMedia: string;
    public MediaId: number;
    public ContactMedia: IContactMedia;
    public IsPrefered: boolean;

    constructor(personContact: IPersonContact, contactMedia: IContactMedia) {
        super(personContact);
        this.MediaId = personContact.MediaId;
        this.NameInMedia = personContact.NameInMedia;
        this.IsPrefered = personContact.IsPrefered || false;
        this.ContactMedia = contactMedia;
        super.init(this);
    }

    public hasNameInMedia(): boolean {
        return !!this.NameInMedia;
    }

    public getPhoneNumber() {
        return this.hasNameInMedia() ? this.NameInMedia : translation.t("person-detail.no-number");
    }

    public createLink(isSmS: boolean = false): string {
        if (!this.NameInMedia) {
            return "";
        }
        switch (this.ContactMedia.InputType) {
            case InputType.EMailAddress:
                return `mailto:${this.NameInMedia}`;
            case InputType.PhoneNumber:
                if (isSmS) {
                    return `sms:${this.NameInMedia}`;
                }
                return `tel:${this.NameInMedia}`;
            default:
                return this.NameInMedia;
        }
    }

    public createPhoneLink(): string {
        return this.createLink();
    }

    public createSmsLink(): string {
        return this.createLink(true);
    }

    public toEntity(personContact: IPersonContact = new PersonContact()): IPersonContact {
        super.toEntity(personContact);
        personContact.MediaId = this.MediaId;
        personContact.NameInMedia = this.NameInMedia;
        personContact.IsPrefered = this.IsPrefered;
        return personContact;
    }
}
