import DateTime from "../modules/DateTime";
import RatingModel from "./RatingModel";

export default class DashboardNotesModel {
    public Ratings: RatingModel[];
    public LessonDate: Date;
    public LessonNote: string;

    public getFormattedLessonDate() {
        return DateTime.parseNumberDateTime(this.LessonDate);
    }
}
