import ProfileModel from "../models/ProfileModel";
import { Util } from "../modules/Util";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { companySettingsService } from "../services/CompanySettingsService";
import { localDataService } from "../services/LocalDataService";
import { profileService } from "../services/ProfileService";
import { translation } from "../services/TranslationService";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { userService } from "../services/UserService";
import { userSettingsService } from "../services/UserSettingsService";
import { BaseView } from "./BaseView";

export default class ProfileView extends BaseView {
    private readonly NAV_POINT = "views/settings.html";
    private validator: kendo.ui.Validator;
    private isInitialized: boolean = false;

    public initView(): void {
        if ($("#profileContent").length) {
            Util.renderView("profileTemplate", this, $("#profileContent"));
        }
        this.set("gender", Util.getTranslatedGender());
        this.set("languages", translation.getLanguageModels());
    }

    public async loadData(): Promise<void> {
        this.set("gender", Util.getTranslatedGender());
        if (!this.isInitialized) {
            this.isInitialized = true;

            this.validator = $(".profileTemplateContent").kendoValidator().data().kendoValidator;
            this.validator.bind("validateInput", Util.validateInput);

            if (!$("#profilePhone").data() || !$("#profilePhone").data().plugin_intlTelInput) {
                $("#profilePhone").intlTelInput(Util.getIntlOptions());
            }
            $("#profilePhone").intlTelInput("setNumber", this.get<string>("profile.PhoneNumber") || "");
            $("#multiUserSwitchProfile").kendoMobileSwitch({ offLabel: "", onLabel: "" });
            $("#easyAccountingSwitch").kendoMobileSwitch({ offLabel: "", onLabel: "" });
            $("#collapseSwitch").kendoMobileSwitch({ offLabel: "", onLabel: "" });
            $("#firstNameFirstSwitch").kendoMobileSwitch({ offLabel: "", onLabel: "" });
        }

        const profile = await profileService.getSettings();
        const companysettings = await companySettingsService.getSettings();
        const userAppSettings = await userAppSettingsService.getSettings();
        const users = await userService.getItems();
        const localData = await localDataService.getLocalData();
        const companyAppSettings = await companyAppSettingsService.getSettings();
        this.set("ForceEasyAcccounting", companyAppSettings.ForceEasyAcccounting);

        const profileModel = new ProfileModel(profile, companysettings, userAppSettings, users, localData);
        this.set("profileModel", profileModel);

        $(".inputFieldContainer").on("click", Util.focusInput);
        Util.enableFocusStateFeature($("input"));
    }

    public save = async (showMessage: boolean = true): Promise<void> => {
        if (!this.validator.validate()) {
            return;
        }
        const profileModel = this.get<ProfileModel>("profileModel");

        profileModel.PhoneNumber = $("#profilePhone").intlTelInput("getNumber", window.intlTelInputUtils.numberFormat.INTERNATIONAL);

        await profileService.updateSettings(profileModel.getProfileUpdate());
        await userAppSettingsService.updateSettings(profileModel.getUserAppSettingsUpdate());
        await localDataService.updateLocalData(profileModel.getLocalDataUpdate());
        await userSettingsService.updateSettings(profileModel.getUserSettingsUpdate(), false);

        if (profileModel.get("CanUpdateCompanySettings")) {
            await companySettingsService.updateSettings(profileModel.getCompanyUpdate(), true);
        }
        await translation.reloadLanguage();
        this.navigate(showMessage);
    };

    private navigate = (showMessage: boolean) => {
        if (showMessage) {
            Util.showNotification(translation.t("profile-settings.successfully-stored"), "success");
            this.back();
        }
    };

    public getNavPoint(): string {
        return this.NAV_POINT;
    }

    public hideView(): void {
        $(".inputFieldContainer").off("click", Util.focusInput);
        Util.disableFocusStateFeature($("input"));
    }
}
