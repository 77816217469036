import { Util } from "../modules/Util";
import { localDataService } from "../services/LocalDataService";
import { translation } from "../services/TranslationService";
import ObservableObject from "./ObservableObject";

// eslint-disable-next-line import/prefer-default-export
export abstract class BaseView extends ObservableObject {
    protected personId: number;
    protected orphyDriveOne: boolean;

    public abstract getNavPoint(): string;

    public initialize = (e: any): void => {
        this.initView(e);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public initView(e: any): void {}

    public show = async (e: any): Promise<void> => {
        await translation.reloadLanguage();
        this.translate();
        app.mobileApp.showLoading();
        this.set("orphyDriveOne", window.orphyDriveOne);
        const localData = await localDataService.getLocalData();
        if (localData) {
            this.showReadOnylHeader(localData.OrphyReadonly, localData.LastSync as Date, localData.TrialOver);
            this.setSubscriptionNotification(localData.SubscriptionNotification);
            this.setDisabled(localData.OrphyReadonly, localData.LastSync as Date);
        }
        this.personId = Util.getSelectedPersonId();
        await this.loadData(e);
        this.addBackButtonEvent();
        app.mobileApp.hideLoading();
        this.showView(e);
        this.translate();
    };

    public translate(): void {
        translation.localize("#kendoUiMobileApp");
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public showView(e: any): void {}

    public abstract loadData(e: any): Promise<void>;

    public addBackButtonEvent() {
        document.addEventListener("backbutton", this.backButton, false);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public hide = (e): void => {
        this.hideView(e);
        this.removeBackButtonEvent();
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public hideView(e: any): void {}

    public removeBackButtonEvent() {
        document.removeEventListener("backbutton", this.backButton, false);
    }

    public backButton = (e: any) => {
        this.back(e);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public back(e?: any) {
        app.mobileApp.navigate(this.getNavPoint());
    }

    private setDisabled = (orphyReadOnly: boolean, lastSync: Date) => {
        if (orphyReadOnly || Util.isReadonly(lastSync)) {
            $("input.readOnly").attr("disabled", "disabled");
            $(".readOnly:button").addClass("disabled");
            $("div.readOnly").addClass("disabled");
            $(".billTextArea.readOnly").attr("contenteditable", "false");
            if ($("#paymentConditionsBillSettings") && $("#paymentConditionsBillSettings").data()) {
                $("#paymentConditionsBillSettings").data().kendoDropDownList.enable(false);
            }
        } else {
            $("input.readOnly").removeAttr("disabled");
            $(".readOnly:button").removeClass("disabled");
            $("div.readOnly").removeClass("disabled");
            $(".billTextArea.readOnly").attr("contenteditable", "true");
            if ($("#paymentConditionsBillSettings") && $("#paymentConditionsBillSettings").data()) {
                $("#paymentConditionsBillSettings").data().kendoDropDownList.enable(true);
            }
        }
    };

    private showReadOnylHeader = (orphyReadOnly: boolean, lastSync: Date, trialOver: boolean) => {
        if (orphyReadOnly || Util.isReadonly(lastSync)) {
            $(".headerReadonly").addClass("showHeaderReadonly");
            if (trialOver) {
                $(".readOnlyHeaderTextTop").html(translation.t("util.time-over"));
                $(".readOnlyHeaderTextBottom").html(translation.t("util.activate-license"));
            } else {
                $(".readOnlyHeaderTextTop").html(translation.t("util.sync-over"));
                $(".readOnlyHeaderTextBottom").html(translation.t("util.sync-on-home"));
            }
        } else {
            $(".headerReadonly").removeClass("showHeaderReadonly");
        }
    };

    protected setSubscriptionNotification = (subscriptionNotification: boolean) => {
        if (subscriptionNotification) {
            $(".subscription-notification").addClass("new-subscription");
        } else {
            $(".subscription-notification").removeClass("new-subscription");
        }
    };

    public footerNavigation = (e: MouseEvent) => {
        const navPoint = $(e.currentTarget).data("navpoint");
        app.mobileApp.navigate(`${navPoint}`);
    };
}
