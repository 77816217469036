import ChecklistModel from "../models/ChecklistModel";
import EditChecklistItemModel from "../models/EditChecklistItemModel";
import { Util } from "../modules/Util";
import ChecklistCollection from "../types/ChecklistCollection";
import { BaseView } from "./BaseView";
import { checklistCollectionService } from "../services/ChecklistCollectionService";
import { checklistItemService } from "../services/ChecklistItemService";
import ConfirmContent from "../modules/ConfirmContent";
import { translation } from "../services/TranslationService";
import { kendoEvent } from "../typings/kendoEvent";
import PromptContent from "../modules/PromptContent";
import { ConfirmRejectReason } from "../modules/ConfirmRejectReason";

export default class EditChecklist extends BaseView {
    private readonly NAV_POINT = "views/settings.html";
    private readonly CHECKLIST_ID = "#checklistEditChecklist";
    private validator: kendo.ui.Validator;

    constructor() {
        super();
        this.set(
            "treeViewDataSource",
            kendo.data.HierarchicalDataSource.create({
                transport: {
                    read: async options => {
                        const checklistCollectionId = this.get<number>("checklistCollectionId") || 1;
                        this.set("hasVkuVisible", checklistCollectionId !== 1);
                        const checklistCollection = await checklistCollectionService.getItemById(checklistCollectionId);
                        this.set("hasVku", checklistCollection.HasVku);
                        const selectedChecklist = await checklistItemService.getTreeStructure(checklistCollectionId);
                        options.success(this.createTree(selectedChecklist));
                    }
                } as any,
                schema: {
                    model: {
                        children: "items"
                    }
                }
            })
        );
        super.init(this);
    }

    public initView(): void {
        $("#addChecklistVkuSwitch").kendoMobileSwitch({ offLabel: "", onLabel: "" });
        this.validator = $(this.CHECKLIST_ID)
            .kendoValidator({
                validateOnBlur: false,
                rules: {
                    required: input => {
                        if (input.filter("[required]").length && $.trim(input.val()) === "") {
                            return false;
                        }
                        return true;
                    }
                }
            })
            .data().kendoValidator;
        this.validator.bind("validateInput", arg => {
            if (!arg.valid) {
                $(arg.input).closest(".editingInputFieldContainer").addClass("invalid");
            } else {
                $(arg.input).closest(".editingInputFieldContainer").removeClass("invalid");
            }
        });
    }

    public async loadData(e: any): Promise<void> {
        const checklists = await checklistCollectionService.getItems();
        if (e.view.params.fromEditContent !== "true") {
            this.set("checklistCollectionId", 1);
        } else {
            this.set("checklistCollectionId", Number(e.view.params.checklistCollectionId));
        }
        const checklist = await checklistCollectionService.getItemById(this.get<number>("checklistCollectionId"));
        this.set("checkLists", checklists);
        await this.loadChecklist();
        this.set("name", checklist.Name);
    }

    private async loadChecklist(): Promise<void> {
        await this.getTreeView().dataSource.read();
        this.translate();
    }

    public async addChecklistCollection(): Promise<void> {
        try {
            const checklistName = await Util.prompt(PromptContent.addChecklistCollection(), translation.t("edit-checklist.add-card"));
            await this.insertChecklistCollection(checklistName);
            Util.showNotification(translation.t("edit-checklist.add-card-successful", { checklistName: checklistName }), "success");
        } catch (e) {
            if (e !== ConfirmRejectReason.Cancel) {
                Util.showNotification(translation.t("edit-checklist.add-card-not-successful"), "error");
            }
        }
    }

    private async insertChecklistCollection(checklistName: string): Promise<void> {
        app.mobileApp.showLoading();
        const newChecklistsEntry = new ChecklistCollection();
        newChecklistsEntry.Name = checklistName;
        newChecklistsEntry.HasVku = false;

        const newId = await checklistCollectionService.insert(newChecklistsEntry);
        this.set("checklistCollectionId", newId);
        this.set("checkLists", await checklistCollectionService.getItems());
        await this.switchChecklist();
        app.mobileApp.hideLoading();
    }

    public switchChecklist = async (): Promise<void> => {
        app.mobileApp.showLoading();
        await this.loadChecklist();
        app.mobileApp.hideLoading();
    };

    public async updateHasVKu(): Promise<void> {
        app.mobileApp.showLoading();
        const id = this.get<number>("checklistCollectionId");
        await checklistCollectionService.update("Id", id, new Map<string, any>().set("HasVku", this.get<boolean>("hasVku")), true);
        app.mobileApp.hideLoading();
    }

    public addChapter = async (): Promise<void> => {
        try {
            const checklistItemName = await Util.prompt(PromptContent.addChecklistItem(), translation.t("edit-checklist.add-chapter"));
            app.mobileApp.showLoading();
            await checklistItemService.insertLast(checklistItemName, null, this.get<number>("checklistCollectionId"));
            await this.loadChecklist();
            app.mobileApp.hideLoading();
            Util.showNotification(translation.t("edit-checklist.main-chapter-added"), "success");
        } catch (e) {
            if (e !== ConfirmRejectReason.Cancel) {
                Util.showNotification(translation.t("edit-checklist.add-chapter-not-successful"), "error");
            }
        }
    };

    public insertSubChapter = async (e: kendoEvent<EditChecklistItemModel>): Promise<void> => {
        const input = $(`#${e.data.uid}`);
        if (this.validator.validateInput(input)) {
            app.mobileApp.showLoading();
            await checklistItemService.insertLast(input.val(), e.data.ParentId, this.get<number>("checklistCollectionId"));
            await this.loadChecklist();
            app.mobileApp.hideLoading();
        }
    };

    public updateItem = async (e): Promise<void> => {
        const editNode = this.getByUid(e.data.uid);
        const editMode = editNode.EditMode;
        const input = $(`#${e.data.uid}`);
        if (editMode) {
            if (this.validator.validateInput(input)) {
                editNode.set("EditMode", false);
                app.mobileApp.showLoading();
                await checklistItemService.update("Id", editNode.Id, new Map().set("Label", input.val()), true);
                await this.loadChecklist();
                app.mobileApp.hideLoading();
            } else {
                setTimeout(() => this.validator.hideMessages(), 3000);
            }
        } else {
            editNode.set("EditMode", true);
        }
    };

    public deleteChecklistItem = async (e: kendoEvent<EditChecklistItemModel>) => {
        const item = e.data;
        const { confirmed } = await Util.confirmTemplate(ConfirmContent.deleteChecklistItem(!item.ParentId, item.Label), translation.t("util.yes-delete-finaly"), translation.t("common.cancel"));
        if (confirmed) {
            app.mobileApp.showLoading();
            await checklistItemService.delete(item.Id);
            await this.loadChecklist();
            Util.showNotification(translation.t("util.was-deleted", { label: item.Label }), "success");
            app.mobileApp.hideLoading();
        }
    };

    public cancelEdit(e) {
        const editNode = this.getByUid(e.data.uid);
        editNode.set("EditMode", false);
    }

    private sourceNode;

    public dragstart = e => {
        const treeView = this.getTreeView();
        const data = treeView.dataItem(e.sourceNode) as any;
        const initialTouch = e.sender.dragging._draggable.userEvents.touches;
        if (data.IsInputNode || (initialTouch.length && !$(initialTouch[0].initialTouch).hasClass("icon-list"))) {
            e.preventDefault();
        } else {
            this.sourceNode = $(e.sourceNode);
            this.sourceNode.css("background", "lightblue");
        }
    };

    public drag = e => {
        const treeView = this.getTreeView();
        const data = treeView.dataItem(e.sourceNode) as any;
        const hoveredItem = $(e.dropTarget).closest(".k-item");
        const newData = treeView.dataItem(hoveredItem) as any;
        e.setStatusClass("k-denied");
        if (data && newData) {
            if (data.ParentId) {
                if (!newData.IsInputNode) {
                    if ((treeView.dataItem(hoveredItem) as any).ParentId) {
                        const dropTarget = $(e.dropTarget);
                        if (e.pageY - dropTarget.height() / 2 < dropTarget.offset().top) {
                            hoveredItem.prev().after(this.sourceNode);
                        } else {
                            hoveredItem.after(this.sourceNode);
                        }
                    } else {
                        const nearestRoot = this.getNearestRoot(e.pageY);
                        if (nearestRoot) {
                            nearestRoot.find("ul").find("li:first").before(this.sourceNode);
                        }
                    }
                }
            } else {
                const nearestRoot = this.getNearestRoot(e.pageY);
                if (e.pageY > nearestRoot.offset().top) {
                    nearestRoot.after(this.sourceNode);
                } else {
                    nearestRoot.before(this.sourceNode);
                }
            }
        }
    };

    private getNearestRoot(dragHeight: number): JQuery {
        const roots = $(`${this.CHECKLIST_ID} > ul > li`) as any;
        let nearest = null;
        let lastDistance = null;
        for (const root of roots) {
            const tempRoot = $(root);
            if (!lastDistance && lastDistance !== 0) {
                lastDistance = Math.abs(tempRoot.offset().top - dragHeight);
                nearest = tempRoot;
            } else if (Math.abs(tempRoot.offset().top - dragHeight) < lastDistance) {
                lastDistance = Math.abs(tempRoot.offset().top - dragHeight);
                nearest = tempRoot;
            }
        }
        return nearest;
    }

    public drop = async e => {
        e.preventDefault();
        this.sourceNode.css("background", "");
        let items;
        const originalItem = this.getTreeView().dataItem(e.sourceNode) as any as EditChecklistItemModel;
        if (!originalItem) {
            // do nothing if we could not get the original item from the event
            return;
        }
        const originalParent = this.getData().find(p => p.Id === originalItem.ParentId);
        // differ between root nodes and children
        if (originalParent) {
            items = originalParent.items;
        } else {
            items = this.getData();
        }
        const originalPrev = items.find(p => p.Id === originalItem.PreviousSiblingId);
        const originalNext = items.find(p => p.Id === originalItem.NextSiblingId);

        const newPrev = this.getTreeView().dataItem($(e.sourceNode).closest("li").prev()) as any as EditChecklistItemModel;
        const newNext = this.getTreeView().dataItem($(e.sourceNode).closest("li").next()) as any as EditChecklistItemModel;

        if (!newNext && !newPrev) {
            return;
        }
        // find new parent with next or previous item as previous or next can be undefined (dragged to first or last)
        const newParent = this.getData().find(p => p.Id === (newPrev ? newPrev.ParentId : newNext.ParentId));
        app.mobileApp.showLoading();
        // update dragged item
        await checklistItemService.update("Id", originalItem.Id, new Map().set("PreviousSiblingId", newPrev ? newPrev.Id : null), true);
        await checklistItemService.update("Id", originalItem.Id, new Map().set("NextSiblingId", newNext && !newNext.IsInputNode ? newNext.Id : null), true);
        if (newParent) {
            await checklistItemService.update("Id", originalItem.Id, new Map().set("ParentId", newParent.Id), true);
        }
        // update left nodes
        if (originalPrev) {
            await checklistItemService.update("Id", originalPrev.Id, new Map().set("NextSiblingId", originalNext ? originalNext.Id : null), true);
        }
        if (originalNext) {
            await checklistItemService.update("Id", originalNext.Id, new Map().set("PreviousSiblingId", originalPrev ? originalPrev.Id : null), true);
        }
        // update new node neighbours
        if (newPrev) {
            await checklistItemService.update("Id", newPrev.Id, new Map().set("NextSiblingId", originalItem.Id), true);
        }
        if (newNext && !newNext.IsInputNode) {
            await checklistItemService.update("Id", newNext.Id, new Map().set("PreviousSiblingId", originalItem.Id), true);
        }

        await this.loadChecklist();

        app.mobileApp.hideLoading();
    };

    public editContent = e => {
        const node = this.getByUid(e.data.uid) as any as EditChecklistItemModel;
        app.mobileApp.navigate(`views/editChecklistContent.html?chapterId=${node.Id}&checklistCollectionId=${this.get("checklistCollectionId")}`);
    };

    public save = async (): Promise<void> => {
        app.mobileApp.navigate("views/settings.html");
    };

    public async back(): Promise<void> {
        if ((await checklistItemService.getItems("Id < 0")).length || (await checklistCollectionService.getItems("Id < 0")).length) {
            Util.showNotification(translation.t("util.use-card-after-sync"), "success", 0, true, false);
        }
        super.back();
    }

    private scrollToEnd = () => {
        $("#editChecklistMasterElement .km-content.km-stretched-view").scrollTop($("#editChecklistMasterElement .km-content.km-stretched-view").height() * 2);
    };

    private createTree(content: ChecklistModel[]) {
        return content.map(c => this.getNode(c));
    }

    private getNode = (c: ChecklistModel) => {
        const node = EditChecklistItemModel.fromChecklistItem(c);
        node.items.push(this.getInputNode(c));
        return node;
    };

    private getInputNode = (p: ChecklistModel): EditChecklistItemModel => {
        const inputNode = new EditChecklistItemModel();
        inputNode.Label = "";
        inputNode.ParentId = p.Id;
        inputNode.EditMode = true;
        inputNode.IsInputNode = true;
        inputNode.HasContent = p.HasContent;
        return inputNode;
    };

    private getData(): EditChecklistItemModel[] {
        return this.getTreeView().dataSource.data() as any as EditChecklistItemModel[];
    }

    private getByUid(uid: string): EditChecklistItemModel {
        return this.getTreeView().dataItem(this.getTreeView().findByUid(uid)) as any as EditChecklistItemModel;
    }

    private getTreeView() {
        return $(this.CHECKLIST_ID).data("kendoTreeView");
    }

    public getNavPoint(): string {
        return this.NAV_POINT;
    }
}
