import ChecklistCollection, { IChecklistCollection, IChecklistCollectionDto } from "../types/ChecklistCollection";
import { BaseService } from "./BaseService";
import { checklistItemService } from "./ChecklistItemService";
import { educationService } from "./EducationService";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { keys } from "ts-transformer-keys";

export default class ChecklistCollectionService extends BaseService<IChecklistCollection, IChecklistCollectionDto> {
    private readonly TABLE_NAME = "ChecklistCollections";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IChecklistCollectionDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IChecklistCollection>());
    }

    public getApiRoute(): string {
        return `${network.API}/${ChecklistCollection.EntityTypeId}`;
    }

    protected createEntityFromOData(item: any): ChecklistCollection {
        const entity = new ChecklistCollection();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: any): ChecklistCollection {
        const entity = new ChecklistCollection();
        entity.populateFromDb(item);
        return entity;
    }

    public getChecklistNamesByPersonId = async (personId: number): Promise<IChecklistCollection[]> => {
        const educations = await educationService.getEducationsByPersonId(personId);
        return this.getItems(`Id in ${Util.joinIds(educations.map(x => x.ChecklistCollectionId))}`);
    };

    public async updateDependencies(oldId: number, newId: number, updateSelf: boolean): Promise<void> {
        const updateFK = new Map<string, any>().set("ChecklistCollectionId", newId);
        await super.updateDependencies(oldId, newId, updateSelf);
        await checklistItemService.update("ChecklistCollectionId", oldId, updateFK, false);
    }

    public async hasVku(educationId: number, personId: number): Promise<boolean> {
        const education = await educationService.getSelectedEducation(educationId, personId);
        const checklistCollection = await this.getItemById(education.ChecklistCollectionId);
        return checklistCollection?.HasVku;
    }
}

export const checklistCollectionService = new ChecklistCollectionService();
