import ProductChooserModel from "../models/ProductChooserModel";
import ConfirmContent from "../modules/ConfirmContent";
import { Util } from "../modules/Util";
import { jobPositionService } from "../services/JobPositionService";
import { orphyDriveJobPositionService } from "../services/OrphyDriveJobPositionService";
import { paymentWayService } from "../services/PaymentWayService";
import { personService } from "../services/PersonService";
import { productService } from "../services/ProductService";
import { translation } from "../services/TranslationService";
import { PaymentWayType } from "../types/PaymentWay";
import { BaseView } from "./BaseView";

export default class SubscriptionChooser extends BaseView {
    public products: ProductChooserModel[];
    public chooseButtonDisabled: boolean;
    public personName: string;
    public personAvatar: string;
    public noMatchingProduct: string;
    public hasProducts: boolean;

    private navPoint: string;
    private fromEnterLesson: boolean;

    public async loadData(): Promise<void> {
        const paymentWays = (await paymentWayService.getItems()).filter(p => p.Type !== PaymentWayType.Invoice);
        this.set("paymentWays", paymentWays);
        const lastSelectedId = (await paymentWayService.getLastSelectedPaymentWay()).Id;
        if (paymentWays.find(p => p.Id === lastSelectedId)) {
            this.set("selectedPaymentWay", (await paymentWayService.getLastSelectedPaymentWay()).Id);
        } else {
            this.set("selectedPaymentWay", Util.firstOrDefault(paymentWays).Id);
        }
        const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
        this.set("personName", `${person.FirstName} ${person.LastName}`);
        this.set("personAvatar", Util.getAvatar(person.FirstName, person.LastName));

        const products = await productService.getChooserModel();
        this.set("createReceipt", false);
        this.set("products", products);
        this.set("hasProducts", !!products.length);
        this.set("noMatchingProduct", translation.t("subscription-chooser.no-abo"));
    }

    public showView(e: any): void {
        this.fromEnterLesson = e.view.params.fromEnterLesson === "true";
        if (this.fromEnterLesson) {
            this.navPoint = "views/enterLessons.html";
        } else {
            this.navPoint = "views/personDetail.html";
        }
        this.set("chooseButtonDisabled", true);
    }

    public setLastSelectedPaymentWay = () => {
        paymentWayService.setLastSelectedPaymentWayId(this.get<number>("selectedPaymentWay"));
    };

    public getNavPoint(): string {
        return `${this.navPoint}?fromEnterLesson=${this.fromEnterLesson}`;
    }

    public productSelected = () => {
        const products = this.get<ProductChooserModel[]>("products");
        this.set("chooseButtonDisabled", !products.some(p => p.Selected));
    };

    public addProductPosition = async (): Promise<void> => {
        this.set("chooseButtonDisabled", true);
        await this.askToDeleteOldPositions();
        const products = this.get<ProductChooserModel[]>("products").filter(p => p.Selected);
        const positionIds = [];
        for (const product of products) {
            positionIds.push(await jobPositionService.addProductPosition(Util.getEducationId(this.personId), product.Id));
        }
        if (this.get<boolean>("createReceipt")) {
            const paymentWayEntity = await paymentWayService.getItemById(this.get<number>("selectedPaymentWay"));
            const createDate = new Date();
            await jobPositionService.accountPositions(Util.getEducationId(this.personId), this.personId, positionIds, paymentWayEntity.Id, true, createDate, createDate, true);
        }
        Util.showNotification(translation.t("subscription-chooser.added-successfully"), "success");
        this.back();
    };

    public async askToDeleteOldPositions(): Promise<void> {
        const currentBalance = await jobPositionService.getAvailableBalance(Util.getEducationId(this.personId), this.personId);
        const unchargedLessonPositions = (await jobPositionService.getAllPositionsByEducationId(Util.getEducationId(this.personId))).filter(x => x.AllowBilling && x.IsLessonPosition);
        const unchargedLessonCount = unchargedLessonPositions.reduce((a, b) => a + b.Amount * b.LessonCount, 0);
        if (currentBalance >= unchargedLessonCount && unchargedLessonPositions.length > 0) {
            const deletePositions = (await Util.confirmTemplate(ConfirmContent.askDeleteOldPositions(), translation.t("util.yes-delete-finaly"), translation.t("common.cancel"))).confirmed;
            if (deletePositions) {
                for (const unchargedLessonPosition of unchargedLessonPositions) {
                    await orphyDriveJobPositionService.delete(unchargedLessonPosition.PositionId);
                    await jobPositionService.delete(unchargedLessonPosition.PositionId);
                }
            }
        }
    }

    public addCredit() {
        if (this.fromEnterLesson) {
            app.mobileApp.navigate(`views/addCredit.html?fromEnterLesson=true`);
        } else {
            app.mobileApp.navigate(`views/addCredit.html`);
        }
    }

    public chooseProduct() {
        if (this.fromEnterLesson) {
            app.mobileApp.navigate("views/settings/productManager.html?navPoint=views/subscriptionChooser.html&fromEnterLesson=true");
        } else {
            app.mobileApp.navigate("views/settings/productManager.html?navPoint=views/subscriptionChooser.html");
        }
    }
}
