import DateTime from "../modules/DateTime";
import { Util } from "../modules/Util";
import { drivingTestService } from "../services/DrivingTestService";
import { testService } from "../services/TestService";
import Test, { ITest } from "../types/Test";
import BaseModel from "./BaseModel";

export default class TestModel extends BaseModel {
    public TestDate: string | null;
    public TestNote: string;
    public DrivingTestId: number;
    public TestPassed: boolean | null;
    public pleaseChooseVisible: boolean;

    constructor(test: ITest = new Test()) {
        super(test);
        this.TestDate = test.TestDate ? DateTime.getDateTimeForInput(test.TestDate as Date) : "";
        this.TestNote = test.TestNote;
        this.DrivingTestId = test.DrivingTestId;
        this.TestPassed = test.TestPassed;
        this.pleaseChooseVisible = !this.TestDate;
        super.init(this);
    }

    public showNextTest(): boolean {
        return this.TestPassed === false;
    }

    private prom: Promise<void> = Promise.resolve();

    public set(name: string, value: any): void {
        super.set(name, value);
        this.prom = this.prom.then(() => this.addOrUpdate());
    }

    public async addOrUpdate(): Promise<void> {
        if (!this.DrivingTestId) {
            const drivingTestModel = await drivingTestService.getDrivingTestModel(Util.getEducationId(Util.getSelectedPersonId()), Util.getSelectedPersonId());
            this.DrivingTestId = await drivingTestModel.addOrUpdate();
        }
        if (!this.Id) {
            this.Id = await testService.insert(this.toEntity());
        } else {
            await testService.updateEntity(this.toEntity(), true);
        }
    }

    public toEntity(test: ITest = new Test()): ITest {
        super.toEntity(test);
        test.TestDate = this.TestDate ? DateTime.getDateTimeFromInput(this.TestDate as string) : null;
        test.TestNote = this.TestNote;
        test.DrivingTestId = this.DrivingTestId;
        test.TestPassed = this.TestPassed;
        return test;
    }
}
