import { dal } from "../dal/Dal";

interface ILastSyncDate {
    LastSyncDate: string;
}

export default class LastSyncDateService {
    public update = async (table: string, lastSyncDate: string): Promise<void> => {
        const sql = `INSERT OR REPLACE INTO LastsyncDates (TableName, LastSyncDate) VALUES('${table}', '${lastSyncDate}')`;
        await dal.execute(sql);
    };

    public getLastSyncDate = async (table: string): Promise<string> => {
        const sql = `SELECT LastSyncDate FROM LastsyncDates WHERE TableName = '${table}'`;
        const lastSyncDate = await dal.firstOrDefault<ILastSyncDate>(sql);

        return !lastSyncDate ? null : lastSyncDate.LastSyncDate;
    };
}

export const lastSyncDateService = new LastSyncDateService();
