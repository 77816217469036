import MeModel from "../models/MeModel";
import { logger } from "./Logger";
import NetworkError from "./NetworkError";
import { Util } from "./Util";
import jwtDecode from "jwt-decode";
import { configService } from "../services/ConfigService";

export default class Network {
    public get APP() {
        return configService.Config.App;
    }
    public get BASE_API() {
        return configService.Config.Base_Api;
    }
    public get API() {
        return configService.Config.Api;
    }
    public get PUBLIC_API() {
        return configService.Config.Public_Api;
    }
    public get ODATA_API_V3() {
        return configService.Config.OdataApi_v3;
    }
    public get IDSRV_BASE() {
        return configService.Config.Idsrv;
    }
    public get IDSRV() {
        return `${this.IDSRV_BASE}/connect/token`;
    }

    private readonly IDSRV_CLIENT_ID = `OrphyDrive`;
    private readonly IDSRV_CLIENT_SECRET = `Orphis2015!`;
    private readonly IDSRV_SCOPES = `openid profile email orphy_odata_api orphy_public_api`;

    public getToken = async (useCachedToken = true): Promise<MeModel> => {
        const localCompanyId = this.getCompanyId(); // currently stored companyId
        await this.getTokenInternal(useCachedToken);
        const me = await this.getLoginInfo();
        const remoteCompanyId = me.CompanyId; // companyId received from orphy (has been stored during getLoginInfo())
        if (localCompanyId && remoteCompanyId !== localCompanyId && me.Companies.some(x => x.Id === localCompanyId)) {
            this.setCompanyId(localCompanyId);
            await this.switchCompany(localCompanyId);
            await this.getLoginInfo();
        } else {
            this.setCompanyId(remoteCompanyId);
        }
        await this.setUserInfo();
        return me;
    };

    public async getItems<TDto>(url: string): Promise<TDto> {
        try {
            const data = (await this.get(url, true)) as any;
            return data.value ? data.value : data;
        } catch (e) {
            logger.logError(new Error(url));
            throw e;
        }
    }

    public async get<TDto>(url: string, useAuthorization = true): Promise<TDto> {
        await this.getTokenInternal();
        try {
            const response = await fetch(url, this.getOptions(useAuthorization));
            this.checkResponse(response);
            if (response.headers.has("content-type") && response.headers.get("content-type").includes("text/plain")) {
                return response.text() as unknown as TDto;
            }
            return await response.json();
        } catch (e) {
            logger.logError(new Error(url));
            throw e;
        }
    }

    public async getBlob(url: string): Promise<Blob> {
        await this.getTokenInternal();
        try {
            const response = await fetch(url, this.getOptions(true));
            this.checkResponse(response);
            return await response.blob();
        } catch (e) {
            logger.logError(new Error(url));
            throw e;
        }
    }

    public async send<T>(requestType: "POST" | "PATCH" | "DELETE", url: string, data: any = {}, useAuthorization: boolean = true): Promise<T> {
        await this.getTokenInternal();
        const options = this.getOptions(useAuthorization, requestType, "application/json", data);
        try {
            const response = await fetch(url, options);
            this.checkResponse(response);
            try {
                const json = await response.json();
                return json;
            } catch (e) {
                logger.logInfo(`${url} does not return json`, false);
            }
        } catch (e) {
            logger.logError(new Error(`Method: ${requestType}, URL: ${url}, Data: ${JSON.stringify(data)}`));
            throw e;
        }
    }

    public async postForm<T>(url: string, form: FormData): Promise<T> {
        await this.getTokenInternal();
        const options = this.getOptions(true, "POST", null);
        options.body = form;
        try {
            const response = await fetch(url, options);
            return response.json() as unknown as T;
        } catch (e) {
            logger.logError(new Error(url));
            throw e;
        }
    }

    public getPDF = async (requestType: string, url: string, data: any = {}, useAuthorization: boolean = true): Promise<any> => {
        await this.getTokenInternal();
        const options = this.getOptions(useAuthorization, requestType, "application/json", data);
        try {
            const response = await fetch(url, options);
            this.checkResponse(response);
            try {
                const text = await response.text();
                return text;
            } catch (e) {
                logger.logInfo(`${url} does not return json`, false);
                return Promise.resolve();
            }
        } catch (e) {
            logger.logError(new Error(url));
            throw e;
        }
    };

    private getTokenInternal = async (useCachedToken = true): Promise<void> => {
        if (useCachedToken) {
            try {
                const currentToken: IOrphyToken = jwtDecode(this.Token);
                // convert exp unix time to js time by multiplying 1k
                // the token will be used if it does not expire in the next minute
                if (currentToken && currentToken.exp * 1_000 > new Date().getTime() - 60 * 1_000) {
                    return;
                }
            } catch (e) {
                // token null or invalid, nothing todo request one
            }
        }

        const options = this.getOptions(false, "POST", "application/x-www-form-urlencoded");
        options.body = this.getTokenRequestString();
        const response = await fetch(this.IDSRV, options);
        this.checkResponse(response);
        const token = await response.json();
        this.setToken(token.access_token);
    };

    private getLoginInfo = async (): Promise<MeModel> => {
        const info = await this.get<MeModel>(`${this.PUBLIC_API}/me`);
        this.setUserId(info.UserId);
        return info;
    };

    private switchCompany = async (targetCompanyId: number): Promise<void> => {
        await this.send("POST", `${this.PUBLIC_API}/me/switchcompany`, targetCompanyId);
    };

    private setUserInfo = async (): Promise<void> => {
        await this.getTokenInternal();
        const userId = this.getUserId();
        const response = await fetch(`${this.ODATA_API_V3}/user(${userId})`, this.getOptions());
        const data = await response.json();
        this.setPersonId(data.PersonId);
        this.setPersonName(data.DisplayName);
    };

    private checkResponse = (response: Response): void => {
        if (response.status >= 400) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw new NetworkError(response);
        }
    };

    private getOptions = (useAuthorization: boolean = true, method: string = "GET", contentType: string = "application/json", data: any = null): RequestInit => {
        const options: RequestInit = {
            method: method
        };

        options.headers = new Headers();

        options.headers.set("X-OrphyDrive-Client-Version", configService.Config.Version);

        if (contentType) {
            options.headers.set("Content-Type", contentType);
        }
        if (useAuthorization) {
            options.headers.set("Authorization", `${this.getTokenType()} ${this.Token}`);
        }
        if (data) {
            options.body = JSON.stringify(data);
        }
        if (method === "PATCH") {
            // to return the updated entity after patch
            options.headers.set("Prefer", "return=representation");
        }
        return options;
    };

    private get Token() {
        return localStorage.getItem("token");
    }

    public getTokenType(): string {
        return "bearer";
    }

    private getTokenRequestString(): string {
        return `username=${encodeURIComponent(Util.getUsername())}&password=${encodeURIComponent(Util.getPassword())}&grant_type=password&client_id=${this.IDSRV_CLIENT_ID}&client_secret=${
            this.IDSRV_CLIENT_SECRET
        }&scope=${this.IDSRV_SCOPES}`;
    }

    public setToken(token: string): void {
        localStorage.setItem("token", token);
    }

    public setUserId = (id: number): void => {
        if (id) {
            localStorage.setItem("userId", `${id}`);
        } else {
            localStorage.removeItem("userId");
        }
    };

    public getUserId(): number {
        return parseInt(localStorage.getItem("userId"));
    }

    private setPersonId = (id: number): void => {
        localStorage.setItem("appPersonId", `${id}`);
    };

    public getPersonId(): number {
        return parseInt(localStorage.getItem("appPersonId"));
    }

    private setPersonName = (id: string): void => {
        localStorage.setItem("appPersonName", `${id}`);
    };

    public getPersonName(): string {
        return localStorage.getItem("appPersonName");
    }

    public setCompanyId(id: number): void {
        localStorage.setItem("companyId", `${id}`);
    }

    public getCompanyId(): number {
        return parseInt(localStorage.getItem("companyId"));
    }
}
export const network = new Network();
