import { syncService } from "../services/SyncService";
import { IBaseEntity } from "../types/BaseEntity";
import { IPersonAddress } from "../types/PersonAddress";
import { BaseView } from "../viewModels/BaseView";
import { network } from "./Network";
import { v4 as uuidv4 } from "uuid";
import { Gender } from "../types/Person";
import { BillStatus } from "../types/Bill";
import { translation } from "../services/TranslationService";
import { ConfirmRejectReason } from "./ConfirmRejectReason";

export class Util {
    static getTranslatedGender(): any {
        return [
            { Id: Gender.Unspecified, Name: translation.t("util.unspecified") },
            { Id: Gender.Female, Name: translation.t("util.female") },
            { Id: Gender.Male, Name: translation.t("util.male") }
        ];
    }

    static compareGuids(guid: string, other: string) {
        if (!guid || !other) {
            return false;
        }
        return guid.toLowerCase() === other.toLowerCase();
    }

    static isNullOrWhitespace(input): boolean {
        if (typeof input === "undefined" || input === null) return true;
        if (typeof input === "string") {
            return input.replace(/\s/g, "").length < 1;
        }
        return false;
    }

    static joinUuids<T extends IBaseEntity>(entity: T[]): string {
        return `('${entity
            .filter(x => x)
            .map(x => x.Uuid)
            .join("','")}')`;
    }

    static joinIds(ids: number[]): string {
        return `(${ids.filter(x => x).join(",")})`;
    }

    static getRatingColor(rating: number): string {
        // this is the same as in numberFont.css
        switch (rating) {
            case 1:
                return "#AF0000";
            case 2:
                return "#FF6F00";
            case 3:
                return "#DBCB00";
            case 4:
                return "#00DF00";
            case 5:
                return "#007F00";
            default:
                return "#FFFFFF";
        }
    }

    static validateInput(arg: { valid: boolean; input: HTMLInputElement }) {
        if (!arg.valid) {
            $(arg.input).closest(".inputFieldContainer").addClass("invalidInput");
        } else {
            $(arg.input).closest(".inputFieldContainer").removeClass("invalidInput");
        }
    }

    static isArray(obj: any): boolean {
        return Object.prototype.toString.call(obj) === "[object Array]";
    }

    static isiOS(): boolean {
        return window.device.platform === "iOS" && !Util.isSimulator();
    }

    static isBrowser(): boolean {
        return (
            window.cordova.platformId === "browser" ||
            (window.cordova.file &&
                (window.cordova.file.applicationDirectory === "https://drive-niggi.cluyo.ch/" ||
                    window.cordova.file.applicationDirectory === "http://localhost:8000/" ||
                    window.cordova.file.applicationDirectory === "http://localhost:8001/"))
        );
    }

    static isSimulator(): boolean {
        return window.navigator.simulator || window.cordova.file.applicationDirectory === "http://localhost:8000/";
    }

    static blurOnScroll(): void {
        if (Util.isiOS()) {
            $(":focus").blur();
        }
    }

    static isDemo(): boolean {
        return Util.getUsername() === "orphydrivedemo@orphis.ch";
    }

    static setWindowHeight(): void {
        localStorage.setItem("windowHeight", `${window.innerHeight}`);
    }

    static getWindowHeight(): number {
        return parseInt(localStorage.getItem("windowHeight"));
    }

    static renderView(templateName: string, templateModel: BaseView, destinationSelector: JQuery): kendo.View {
        let createdView: kendo.View;
        if (destinationSelector.is(":empty")) {
            createdView = new kendo.View(templateName, { model: templateModel, evalTemplate: true });
            createdView.render(destinationSelector);
        }
        return createdView;
    }

    static destroyView(view: kendo.View, destinationSelector: JQuery): void {
        view.destroy();
        destinationSelector.remove();
    }

    static isEnterKey(e: KeyboardEvent): boolean {
        return e.which === 13 || e.key === "Enter" || e.keyCode === 13;
    }

    static replaceUmlaut(str: string): string {
        return str.split("Ä").join("\u00C4").split("ä").join("\u00E4").split("Ö").join("\u00D6").split("ö").join("\u00F6").split("Ü").join("\u00DC").split("ü").join("\u00fc");
    }

    static showNotification(
        notificationText: string,
        notificationType: "error" | "info" | "success",
        autoHideDuration: number = 4000,
        keepPrevious: boolean = false,
        hideOnClick: boolean = true
    ): void {
        const uid = uuidv4();
        notificationText = this.replaceUmlaut(notificationText);

        let closeButton = "<i class='icons icon-cross'></i>";
        if (keepPrevious) {
            $("body").append(`<div id="${uid}"></div>`);
            closeButton = `<i onclick="Util.closeNotification('${uid}')" class='icons icon-cross'></i>`;
        } else {
            $("body").append(`<div id="${uid}"></div>`);
            setTimeout(() => {
                $(`#${uid}`).remove();
            }, autoHideDuration);
        }

        $(`#${uid}`)
            .kendoNotification({
                templates: [
                    {
                        type: "info",
                        template: `<div class='infoNotification notificationContainer default-inline-flex'><div><i class='icons icon-information'></i></div><div class='notificationMessage'> #= notificationText #</div><div id='removeMessages'>${closeButton}</div>`
                    },
                    {
                        type: "error",
                        template: `<div class='errorNotification notificationContainer default-inline-flex'><div><i class='icons icon-danger'></i></div><div class='notificationMessage'> #= notificationText #</div><div id='removeMessages'>${closeButton}</div>`
                    },
                    {
                        type: "success",
                        template: `<div class='successNotification notificationContainer default-inline-flex'><div><i class='icons icon-check-all'></i></div><div class='notificationMessage'> #= notificationText #</div><div id='removeMessages'>${closeButton}</div>`
                    }
                ],
                autoHideAfter: autoHideDuration,
                hideOnClick: hideOnClick,
                width: "100%",
                height: "auto",
                position: { top: 0, left: 0 },
                animation: {
                    open: {
                        effects: "fade"
                    },
                    close: {
                        effects: "fade",
                        reverse: true
                    }
                }
            })
            .data("kendoNotification")
            .show({ notificationText: notificationText }, notificationType);
    }

    static closeNotification(uid: string): void {
        $(`#${uid}`).data("kendoNotification").getNotifications().parent().remove();
        $(`#${uid}`).remove();
    }

    static navigateFromDuplicateBillNotification(personId: number): void {
        if (!syncService.getIsSynchronising() && (!(app.mobileApp.pane as any).viewEngine.url.startsWith("views/personDetail") || personId !== Util.getSelectedPersonId())) {
            Util.setSelectedPersonId(personId);
            if (!Util.isiOS()) app.mobileApp.navigate("views/void.html");
            setTimeout(() => {
                app.mobileApp.navigate("views/personDetail.html?specificTab=subsTabButtonPD");
            }, 0);
        }
    }

    static enableFocusStateFeature(selector: JQuery): void {
        selector.on("focus", Util.setFocusState);
        selector.on("blur", Util.removeFocusState);
    }

    static disableFocusStateFeature(selector: JQuery): void {
        selector.off("focus", Util.setFocusState);
        selector.off("blur", Util.removeFocusState);
    }

    private static setFocusState(event: Event): void {
        const focusedContainer = $(event.target).closest(".inputFieldContainer");
        focusedContainer.addClass("inputFieldContainerFocused");
    }

    private static removeFocusState(event: Event): void {
        const focusedContainer = $(event.target).closest(".inputFieldContainer");
        focusedContainer.removeClass("inputFieldContainerFocused");
    }

    static getMapURIScheme(address: string): string {
        return `${Util.getMapPrefix()}0,0?q=${encodeURI(address)}`;
    }

    static getMapPrefix(): string {
        return Util.isiOS() ? "maps:" : "geo:";
    }

    static focusInput(e: Event): void {
        $(e.target).find("input").focus();
    }

    static setUsername(username: string): void {
        if (username) {
            localStorage.setItem("username", username);
        } else {
            localStorage.removeItem("username");
        }
    }

    static getUsername(): string {
        return localStorage.getItem("username");
    }

    static setPassword(password: string): void {
        if (password) {
            localStorage.setItem("password", password);
        } else {
            localStorage.removeItem("password");
        }
    }

    static getPassword(): string {
        return localStorage.getItem("password");
    }

    static getSelectedPersonId(): number {
        const personId = localStorage.getItem("personId");
        return personId ? Number(personId) : null;
    }

    static setSelectedPersonId(personId: number): void {
        if (personId) {
            localStorage.setItem("personId", String(personId));
        } else {
            localStorage.removeItem("personId");
        }
    }

    static getEducationId(personId: number) {
        const educationId = localStorage.getItem(`p_${personId}`);
        return educationId ? Number(educationId) : null;
    }

    static setEducationId(educationId: number, personId: number) {
        const key = `p_${personId}`;
        localStorage.removeItem(key);
        if (educationId) {
            localStorage.setItem(key, String(educationId));
        }
    }

    static updateEducationIdKey(oldPersonId: number, newPersonId: number) {
        const key = `p_${oldPersonId}`;
        const educationId = localStorage.getItem(key);
        localStorage.removeItem(key);
        if (educationId) {
            localStorage.setItem(`p_${newPersonId}`, educationId);
        }
    }

    static setAutoLogin(autoLogin: boolean): void {
        localStorage.setItem("autologin", autoLogin.toString().toLowerCase());
    }

    static getAutoLogin(): boolean {
        return JSON.parse(localStorage.getItem("autologin"));
    }

    static showPopup(element: Element, selector: string, elementWidth: number): void {
        const windowWidth = $(window).width();
        const maxWidth = (windowWidth / 100) * 80;
        const target = $(element);
        const targetOffset = target.offset();
        const targetOffsetRight = windowWidth - targetOffset.left - target.innerWidth();
        const popupOffsetRight = windowWidth - elementWidth - targetOffsetRight + 20;
        let leftPosition;
        if (popupOffsetRight < 30) {
            leftPosition = 30;
        } else {
            leftPosition = popupOffsetRight;
        }
        $(selector).css("width", elementWidth);
        $(selector).css("max-width", maxWidth);
        $(selector).css("position", "fixed");
        $(selector).css("top", targetOffset.top - 5);
        $(selector).css("height", "auto");
        $(selector).css("left", leftPosition);
        $(".showPopup").removeClass("showPopup");
        $(selector).addClass("showPopup");
        const popupHeaderHeight = $(`${selector} .popupIconBar.rightText`).outerHeight();
        const popupMaxHeight = window.innerHeight / 3;
        const popupContainerPadding = parseInt($(selector).css("padding-top")) - parseInt($(selector).css("padding-bottom"));
        const popupMaxContentHeight = popupMaxHeight - popupHeaderHeight - popupContainerPadding;
        $(selector).css("max-height", popupMaxHeight);
        $(`${selector} .popupContentHeightSelector`).css("max-height", popupMaxContentHeight);
        $(`${selector} .popupContentHeightSelector`).css("overflow-y", "auto");
    }

    static closePopupOnDocumentClick(event: Event): void {
        let target = $(event.target);
        let targetNotFound = true;
        do {
            if (target.prop("nodeName").toLowerCase() === "body") {
                targetNotFound = false;
                $(document).off("click touchstart touchmove", Util.closePopupOnDocumentClick);
                Util.closePopup();
            } else if (target.hasClass("popup")) {
                targetNotFound = false;
            } else if (event.type.toLowerCase() === "touchmove") {
                Util.closePopup();
                $(document).off("click touchstart touchmove", Util.closePopupOnDocumentClick);
                targetNotFound = false;
            }
            target = target.parent();
        } while (targetNotFound);
    }

    static closePopup(): void {
        $(".showPopup").removeClass("showPopup");
    }

    static getRotationDegrees(obj: JQuery): number {
        let angle = 0;
        const matrix = obj.css("transform");
        if (matrix !== "none") {
            const values = matrix.split("(")[1].split(")")[0].split(",");
            const a = parseFloat(values[0]);
            const b = parseFloat(values[1]);
            angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
        }
        return angle;
    }

    static deleteSavedLesson(personId: number): void {
        localStorage.removeItem(`plannedLesson${personId}`);
    }

    static setCardScroller(): void {
        $(".cardScroller").each((_, e) => {
            const cardHeight = $(e).closest(".card").innerHeight();
            const elementParentOffset = $(e).closest(".card").offset();
            const elem = $(e);
            const elemOffset = elem.offset();
            if (elemOffset) {
                const offset = elemOffset.top - elementParentOffset.top;
                const cardScrollerHeight = cardHeight - offset;
                elem.css("height", cardScrollerHeight);
            }
        });
    }

    static detectCardOverflow(): void {
        $(".cardScroller").each((_, e) => {
            const scrollHeight = $(e).prop("scrollHeight");
            const elementHeight = $(e).outerHeight();

            if (scrollHeight > elementHeight) {
                $(e).closest(".card").addClass("cardOverflown");
            }
        });
    }

    static focusRegisterFormDate(id: string): void {
        if (Util.isiOS()) {
            $(`#${id}`).removeClass("inputField").focus().addClass("inputField");
        } else {
            setTimeout(() => {
                $(`#${id}`).click();
                $(`#${id}`).click();
            }, 0);
        }
    }

    static isFirstStart(): boolean {
        return localStorage.getItem("firstStartDone") !== "true";
    }

    static setFirstStartDone(): void {
        localStorage.setItem("firstStartDone", "true");
    }

    static getPhotoFromLibrary(): Promise<string> {
        // Retrieve image file location from specified source
        return new Promise((res, rej) => {
            window.navigator.camera.getPicture(
                (data: string) => {
                    res(data);
                },
                () => {
                    rej();
                },
                {
                    quality: 50,
                    destinationType: window.Camera.DestinationType.DATA_URL,
                    sourceType: Camera.PictureSourceType.PHOTOLIBRARY
                }
            );
        });
    }

    static replaceChecklistBlob(data: string): string {
        if (!Util.isBrowser()) {
            return data ? data.split("http://orphis.blob.core.windows.net/cdn/").join("") : data;
        }
        return data;
    }

    static addChecklistBlob(data: string): string {
        if (!Util.isBrowser()) {
            return data ? data.split('src="mydrive/').join('src="http://orphis.blob.core.windows.net/cdn/mydrive/') : data;
        }
        return data;
    }

    static stringJoin<TItem>(separator: string, data: TItem[], selector: (item: TItem) => any = null): string {
        let joinedString: string = "";
        if (data && data.length > 0) {
            for (const dataItem of data) {
                const item = $.isFunction(selector) ? selector(dataItem) : dataItem;
                joinedString += `${item}${separator}`;
            }
            if (separator) {
                joinedString = joinedString.substr(0, joinedString.length - separator.length);
            }
        }
        return joinedString;
    }

    static firstOrDefault<TItem>(array: TItem[]): TItem {
        return array.length >= 1 ? array[0] : null;
    }

    static where<TItem>(array: TItem[], predicate: (item: TItem) => boolean): TItem[] {
        const ret: TItem[] = [];
        array.forEach(item => {
            if (predicate(item)) {
                ret.push(item);
            }
        });
        return ret;
    }

    static parseStringifiedValue<T>(value: string, defaultValue: T = null): T {
        return value ? JSON.parse(value) : defaultValue;
    }

    static getAvatar(firstName: string, lastName: string): string {
        return `<div class="personDetailNoAvatar">${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}</div>`;
    }

    static replaceParagraph(text: string): string {
        return text ? text.split("<p>").join("").split("</p>").join("<br />") : null;
    }

    static replaceAll(str: string, find: string, replace: string): string {
        return str.replace(new RegExp(Util.escapeRegExp(find), "g"), replace);
    }

    static escapeRegExp(str: string): string {
        return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
    }

    static tokenFailedNotification(response: any): void {
        // status 299 is resolved if the currently used odata version is marked deprecated in Orphy. Though it can be used, the user should consider updating the app soon.
        // status 8888 is resolved if a userid could not be retrieved
        if (response) {
            if (response.status === 8888) {
                Util.showNotification(`${translation.t("util.contact-support")}: info@orhpis.ch`, "error");
            } else if (response.status === 400) {
                let message = "";
                if (response.responseText) {
                    const responseData = JSON.parse(response.responseText);
                    if (responseData) {
                        message = responseData.error;
                    }
                }
                switch (message) {
                    case "invalid_company":
                        Util.showNotification(translation.t("util.company-not-available"), "error");
                        network.setCompanyId(null);
                        app.mobileApp.navigate("views/login.html");
                        break;
                    case "invalid_grant":
                    default:
                        Util.showNotification(translation.t("util.user-wrong"), "error");
                        network.setCompanyId(null);
                        app.mobileApp.navigate("views/login.html");
                        break;
                }
            } else {
                Util.showNotification(translation.t("util.check-internet"), "error");
            }
        }
    }

    static getAppGUID(): string {
        return "orphy.orphyDrive";
    }

    static getAddressStringFromAddress(address: IPersonAddress): string {
        if (address) {
            return Util.getAddressStringFromFields(address.Street, address.StreetNumber, address.ZipCode, address.City);
        }
        return "";
    }

    static getAddressStringFromFields(street: string, streetNumber: string, zipCode: string, city: string): string {
        const zipCityString = zipCode && city ? `${zipCode} ${city}` : zipCode + city;
        const streetNumberString = street && streetNumber ? `${street} ${streetNumber}` : street;
        const addressString = streetNumberString && zipCityString ? `${streetNumberString}, ${zipCityString}` : `${streetNumberString} ${zipCityString}`;
        return addressString ? addressString.trim() : "";
    }

    static formatPrice(price: number): string {
        if (Math.floor(price) === price) {
            return kendo.format("{0:0}.&#8211;", price);
        } else {
            return kendo.format("{0:0.00}", price).split(",").join(".");
        }
    }

    static formatPriceExport(price: number): string {
        if (Math.floor(price) === price) {
            return kendo.format(`{0:0}.\u2013`, price);
        } else {
            return kendo.format("{0:0.00}", price).split(",").join(".");
        }
    }

    static isReadonly(refDate: Date): boolean {
        if (refDate) {
            refDate.setDate(refDate.getDate() + 30);
            return new Date() > refDate;
        } else {
            return false;
        }
    }

    static setTableWidths(cellCount: number, masterSelector: string, ignoreCells: boolean = false): void {
        for (let i = 0; i < cellCount; i++) {
            const cells = $(`${masterSelector} .cell${i}`);
            const header = $(`${masterSelector} .header${i}`);
            let width = 0;
            for (const cell of cells as any) {
                const cellWidth = $(cell).width();
                if (width < cellWidth) {
                    width = cellWidth;
                }
            }
            if (cells.length) {
                header.width(width);
                if (!ignoreCells) {
                    cells.width(width);
                }
            }
        }
    }

    static HTMLEncode(val: string): string {
        return $("<div/>").text(val).html();
    }

    static HTMLDecode(val: string): string {
        return $("<div/>").html(val).text();
    }

    static ResizeBase64Image(data: string): Promise<string> {
        return new Promise(res => {
            // We create an image to receive the Data URI
            const img = document.createElement("img");

            // When the event "onload" is triggered we can resize the image.
            $(img).on("load", () => {
                const factor = img.width > 4000 || img.height > 4000 ? 4 : img.width > 2000 || img.height > 2000 ? 2 : 0;

                if (!factor) {
                    res(data);
                    return;
                }
                // We create a canvas and get its context.
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // We set the dimensions at the wanted size.
                canvas.width = img.width / factor;
                canvas.height = img.height / factor;

                // We resize the image with the canvas method drawImage();
                ctx.drawImage(img, 0, 0, img.width / factor, img.height / factor);
                res(Util.removeBase64Meta(canvas.toDataURL("image/jpeg", 0.75)));
            });
            img.src = Util.addBase64MetaIfNotPresent(data);
        });
    }

    static removeBase64Meta(base64: string): string {
        if (base64) {
            return base64.split("data:image/jpeg;base64,").join("");
        }
        return null;
    }

    static addBase64Meta(base64NoMeta: string): string {
        if (base64NoMeta) {
            return `data:image/jpeg;base64,${base64NoMeta}`;
        }
        return null;
    }

    static addBase64MetaIfNotPresent(base64: string) {
        if (!base64) {
            return base64;
        }
        if (base64.startsWith("data")) {
            return base64;
        } else {
            return `data:image/jpeg;base64,${base64}`;
        }
    }

    static isMaxUserMessage(message: string): boolean {
        return message.includes(translation.t("util.max-number-contact"));
    }

    static confirmTemplate(
        content: string,
        okText: string = translation.t("util.yes"),
        cancel: string = translation.t("util.no"),
        removeCancelButton: boolean = false,
        inputSelector: string[] = []
    ): Promise<{ confirmed: boolean; messages: JQuery[] }> {
        $("body").append("<div id='confirmTemplate'></div>");
        const confirm = $("#confirmTemplate")
            .kendoConfirm({
                title: "",
                content: this.replaceUmlaut(content),
                messages: {
                    okText: this.replaceUmlaut(okText),
                    cancel: this.replaceUmlaut(cancel)
                }
            } as any)
            .data("kendoConfirm")
            .open();

        if (removeCancelButton) {
            $(".k-dialog-buttongroup.k-dialog-button-layout-stretched > button:nth-child(2)").remove();
        }
        return new Promise(res => {
            confirm.result
                .done(() => {
                    res({ confirmed: true, messages: inputSelector.length ? inputSelector.map(x => $(x)) : null });
                })
                .fail(() => {
                    res({ confirmed: false, messages: inputSelector.length ? inputSelector.map(x => $(x)) : null });
                });
        });
    }

    static prompt(content: string, okText: string, cancel: string = translation.t("common.cancel"), value: string = ""): Promise<string> {
        return new Promise((res, rej) => {
            $("<div></div>")
                .kendoPrompt({
                    title: "",
                    content: content,
                    value: value,
                    messages: {
                        okText: okText,
                        cancel: cancel
                    }
                } as any)
                .data("kendoPrompt")
                .open()
                .result.done((text: string) => {
                    if (Util.isNullOrWhitespace(text)) {
                        rej(ConfirmRejectReason.NoText);
                    }
                    res(text);
                })
                .fail(() => rej(ConfirmRejectReason.Cancel));
        });
    }

    static getBillStatus(billStatus: BillStatus): string {
        switch (billStatus) {
            case BillStatus.None:
                return `<div class="billStatusNotCarried billStatusSymbol default-inline-block"></div><span class="default-inline-block smallText">${translation.t("bill-model.not-charged")}</span>`;
            case BillStatus.Draft:
                return `<div class="billStatusNotCarried billStatusSymbol default-inline-block"></div><span class="default-inline-block smallText">${translation.t("bill-model.draft")}</span>`;
            case BillStatus.Open:
                return `<div class="billStatusOpen billStatusSymbol default-inline-block"><i class="custom-icon-clock-isolated"></i></div><span class="default-inline-block smallText">${translation.t(
                    "bill-model.open"
                )}</span>`;
            case BillStatus.Reminder:
            case BillStatus.LateNotice1:
            case BillStatus.LateNotice2:
            case BillStatus.Overdue:
                return `<div class="billStatusOverdue billStatusSymbol default-inline-block"><b>!</b></div><span class="default-inline-block smallText">${translation.t("bill-model.overdue")}</span>`;
            case BillStatus.Paid:
                return `<div class="billStatusPaid billStatusSymbol default-inline-block"><i class="icons icon-tick"></i></div><span class="default-inline-block smallText">${translation.t(
                    "bill-model.paid"
                )}</span>`;
            case BillStatus.Canceled:
                return `<div class="billStatusCanceled billStatusSymbol"><i class="icons icon-cross"></i></div><span class="btnText billStatusText ellipsis smallText">${translation.t(
                    "bill-model.canceled"
                )}</span>`;
            default:
                return `<div class="billStatusPaid billStatusSymbol default-inline-block"><i class="icons icon-tick"></i></div><span class="default-inline-block smallText">${translation.t(
                    "bill-model.debitcard"
                )}</span>`;
        }
    }

    static getIntlOptions(): any {
        return {
            onlyCountries: [
                "al",
                "ad",
                "at",
                "by",
                "be",
                "ba",
                "bg",
                "hr",
                "cz",
                "dk",
                "ee",
                "fo",
                "fi",
                "fr",
                "de",
                "gi",
                "gr",
                "va",
                "hu",
                "is",
                "ie",
                "it",
                "lv",
                "li",
                "lt",
                "lu",
                "mk",
                "mt",
                "md",
                "mc",
                "me",
                "nl",
                "no",
                "pl",
                "pt",
                "ro",
                "ru",
                "sm",
                "rs",
                "sk",
                "si",
                "es",
                "se",
                "ch",
                "ua",
                "gb"
            ],
            initialCountry: "ch",
            preferredCountries: ["ch", "de", "at", "li"]
        };
    }
}
