import DateTime from "../modules/DateTime";
import JobPositionModel from "./JobPositionModel";
import { Util } from "../modules/Util";
import EducationModel from "./EducationModel";
import { IPerson } from "../types/Person";
import { IPersonAddress } from "../types/PersonAddress";
import { translation } from "../services/TranslationService";

export default class PersonDetailModel extends kendo.data.ObservableObject {
    public Education: EducationModel;

    private _person: IPerson;
    private _personId: number;
    private _firstName: string;
    private _lastName: string;
    private _birthday: Date;
    private _profession: string;
    private _lessonCount: number;
    private _balanceLeft: number;
    private _personAddress: string;
    private _ansprechspartnerName: string;
    private _jobPositions: JobPositionModel[];
    private _meetingPointAddress: string;
    public Balance: number;
    public Paid: number;

    constructor(
        person: IPerson,
        education: EducationModel,
        lessonCount: number,
        balanceLeft: number,
        personAddress: IPersonAddress,
        jobPositions: JobPositionModel[],
        anpsrechspartnerName: string,
        balance: number,
        paid: number
    ) {
        super();
        this.Education = education;
        this._person = person;
        this.PersonId = person.Id;
        this.FirstName = person.FirstName;
        this.LastName = person.LastName;
        this.Birthday = person.Birthday as Date;
        this.Profession = person.Profession;
        this.LessonCount = lessonCount;
        this.BalanceLeft = balanceLeft;
        this.PersonAddress = personAddress ? this.getFormattedAddress(personAddress) : "";
        this.set("JobPositions", jobPositions);
        this.AnsprechspartnerName = anpsrechspartnerName;
        this.Paid = paid;
        this.Balance = balance;
        super.init(this);
    }

    public getFormattedAddress(address: IPersonAddress) {
        return Util.getAddressStringFromAddress(address);
    }

    get IsSynced(): boolean {
        return !this._person.IsNew;
    }

    get NoteText(): string {
        if (!this.get("Note")) {
            return translation.t("common.no-note");
        }
        return this.get("Note");
    }

    get BirthdayText(): string {
        if (!this.get("Birthday")) {
            return translation.t("person-detail.no-birthday");
        }
        // if no birthday selected in orphy
        if (this.Birthday.getFullYear() === 400) {
            return DateTime.parseShortNumberDateWithoutYear(this.get("Birthday"));
        }
        return DateTime.parseNumberDate(this.get("Birthday"));
    }

    get ProfessionText(): string {
        if (!this.get("Profession")) {
            return translation.t("person-detail.no-job");
        }
        return this.get("Profession");
    }

    get LessonCountText(): string {
        return `${translation.t("account-lesson.account-lesson-name-no-ammount", { count: this.get("LessonCount") })}<br/>${translation.t("common.passed")}`;
    }

    get HeaderTitle(): string {
        return `${this.get("FirstName")} ${this.get("LastName")}`;
    }

    get AvatarText(): string {
        return this.get("FirstName")[0].toUpperCase() + this.get("LastName")[0].toUpperCase();
    }

    get PersonId(): number {
        return this._personId;
    }

    set PersonId(val: number) {
        this._personId = val;
    }

    get FirstName(): string {
        return this._firstName;
    }

    set FirstName(val: string) {
        this._firstName = val;
    }

    get LastName(): string {
        return this._lastName;
    }

    set LastName(val: string) {
        this._lastName = val;
    }

    get Birthday(): Date {
        return this._birthday;
    }

    set Birthday(val: Date) {
        this._birthday = val;
    }

    get Profession(): string {
        return this._profession;
    }

    set Profession(val: string) {
        this._profession = val;
    }

    get MeetingPointAddress(): string {
        return this._meetingPointAddress;
    }

    set MeetingPointAddress(val: string) {
        this._meetingPointAddress = val;
    }

    get LessonCount(): number {
        return this._lessonCount;
    }

    set LessonCount(val: number) {
        this._lessonCount = val;
    }

    get BalanceLeft(): number {
        return this._balanceLeft;
    }

    set BalanceLeft(val: number) {
        this._balanceLeft = val;
    }

    get PersonAddress(): string {
        return this._personAddress;
    }

    set PersonAddress(val: string) {
        this._personAddress = val;
    }

    get JobPositions(): JobPositionModel[] {
        return this._jobPositions;
    }

    set JobPositions(val: JobPositionModel[]) {
        this._jobPositions = val;
    }

    get AllEasyAccountingSorted(): JobPositionModel[] {
        return this.JobPositionLessons.concat(this.JobPositionPayments)
            .map(x => x)
            .concat(this.JobPositionsOtherServices)
            .sort((a, b) => b.OrderDate?.localeCompare(a.OrderDate));
    }

    get JobPositionLessons(): JobPositionModel[] {
        return (
            this.JobPositions.map(x => x)
                .sort((a, b) => a.TimeTrackingIssueDate?.localeCompare(b.TimeTrackingIssueDate))
                // !!j.LessonCount filters other services
                .filter(
                    j =>
                        (j.Description === translation.t("person-detail-model.drive-lesson") ||
                            j.Description === translation.t("person-detail-model.lesson") ||
                            (j.Description !== translation.t("person-detail-model.account") && j.Description !== translation.t("person-detail-model.payment-entry"))) &&
                        !!j.LessonCount
                )
        );
    }

    get JobPositionPayments(): JobPositionModel[] {
        return this.JobPositions.filter(j => j.IsPaid && (j.Description === translation.t("person-detail-model.account") || j.Description === translation.t("person-detail-model.payment-entry")));
    }

    get JobPositionsOtherServices(): JobPositionModel[] {
        return this.JobPositions.filter(
            j => j.LessonCount === 0 && j.Description !== translation.t("person-detail-model.account") && j.Description !== translation.t("person-detail-model.payment-entry")
        );
    }

    get OtherServicesTotalPrice(): number {
        return this.JobPositionsOtherServices.reduce((a: number, c) => a + c.Amount * c.Price, 0);
    }

    get LessonsTotalPrice(): number {
        return this.JobPositionLessons.reduce((a: number, c) => a + c.Amount * c.Price, 0);
    }

    get OtherServicesTotalAmount(): number {
        return this.JobPositionsOtherServices.length;
    }

    get TotalPrice(): number {
        return this.LessonsTotalPrice + this.OtherServicesTotalPrice;
    }

    get PaymentsTotalPrice(): number {
        return this.Balance;
    }

    get FormattedPaymentsTotalPrice(): string {
        return Util.formatPrice(this.Paid);
    }

    get FormattedTotalPrice(): string {
        return Util.formatPrice(this.TotalPrice);
    }

    get FormattedBalance(): string {
        return Util.formatPrice(this.TotalPrice - this.Paid);
    }

    get BalancePositive(): boolean {
        return this.TotalPrice - this.Paid > 0;
    }

    get BalanceNegative(): boolean {
        return !this.BalancePositive;
    }

    get AnsprechspartnerName(): string {
        return this._ansprechspartnerName;
    }

    set AnsprechspartnerName(val: string) {
        this._ansprechspartnerName = val;
    }
}
