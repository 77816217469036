import { Util } from "./Util";

export default class Popup {
    private openButton: JQuery;
    private popup: JQuery;
    private popupWidth: number;
    private popupHeight: number;
    private addButtonOffset: boolean;
    private positionFunction: () => void;

    private editHeight: number;
    private editTop: number;

    private headerOffset: number = 0;

    /**
     * Opens the popup. Handles popup position even on orientation change.
     * @param {popupId:string, event: Event, addCloseOnScroll?: boolean, addButtonOffset?: boolean}
     */
    public renderFixedPositionPopup = (popupId: string, event: Event, addCloseOnScroll: boolean = true, addButtonOffset: boolean = true) => {
        this.closePopup();
        this.addButtonOffset = addButtonOffset;
        this.popup = $(popupId);
        if ($(event.target).closest("button").length) {
            this.openButton = $(event.target).closest("button");
        } else {
            this.openButton = $(event.target);
        }
        this.positionFunction = this.setFixedPosition;
        this.setFixedPosition();
        this.openPopup();
        $(window).on("orientationchange", this.setPositionOnOrientationChange);
        if (addCloseOnScroll) {
            this.addCloseOnScroll();
        }
    };

    public renderContentEditPopup = (popupId: string, headerOffset: number = 0): Promise<number> => {
        this.closePopup();
        this.popup = $(popupId);
        this.headerOffset = headerOffset;
        this.positionFunction = this.setEditPosition;
        $(window).on("orientationchange", this.setPositionOnOrientationChange);
        return this.setEditPosition();
    };

    public renderFixedSizePopup(popupId: string, width: number, height: number) {
        this.closePopup();
        this.popup = $(popupId);
        this.popupWidth = width;
        this.popupHeight = height;
        this.headerOffset = 100;
        this.positionFunction = this.setFixedSizePosition;
        $(window).on("orientationchange", this.setPositionOnOrientationChange);
        this.popup.addClass("showPopup");
        this.setFixedSizePosition();
    }

    private setFixedSizePosition() {
        this.popup
            .css("position", "fixed")
            .css("width", this.popupWidth)
            .css("height", this.popupHeight)
            .css("top", window.innerHeight / 2 - this.popupHeight / 2)
            .css("left", window.innerWidth / 2 - this.popupWidth / 2);
    }

    private setEditPosition(): Promise<number> {
        return new Promise(res => {
            if (!this.editHeight || !this.editTop) {
                $("input:focus").blur();
                setTimeout(() => {
                    if (this.headerOffset) {
                        this.editHeight = (window.innerWidth / 100) * 45;
                        this.editTop = this.headerOffset;
                    } else if (window.innerWidth < window.innerHeight) {
                        this.editHeight = (window.innerHeight / 100) * 45;
                        this.editTop = (window.innerHeight / 100) * 20;
                    } else {
                        this.editHeight = (window.innerWidth / 100) * 45;
                        this.editTop = (window.innerWidth / 100) * 5;
                    }
                    res(this.setEditDimensions());
                }, 200);
            } else {
                res(this.setEditDimensions());
            }
        });
    }

    private setEditDimensions = (): number => {
        this.popup
            .css("position", "fixed")
            .css("width", (window.innerWidth / 100) * 80)
            .css("top", this.editTop)
            .css("left", (window.innerWidth - (window.innerWidth / 100) * 80) / 2);
        this.popup.addClass("showPopup");
        return (window.innerWidth / 100) * 80;
    };

    public isPopupOpen = () => {
        if (!this.popup) {
            return false;
        } else {
            return this.popup.hasClass("showPopup");
        }
    };

    private setFixedPosition = () => {
        this.popup.css("position", "fixed").css("width", this.popupWidth ? this.popupWidth : "auto");
        if (!this.popupWidth) {
            this.popupWidth = this.popup.outerWidth();
        }
        const positionTop = this.openButton.offset().top;
        const buttonOffset = this.openButton.outerWidth();
        let positionLeft = this.openButton.offset().left + this.openButton.width() - this.popup.width() + (this.addButtonOffset ? buttonOffset : 0);
        positionLeft -= this.popup.attr("id") === "dropDownContentExecuteLesson" ? 15 : 0;
        positionLeft += this.popup.attr("id") === "popupNoteCompletedLessons" ? 33 : 0;
        if (positionLeft < 0) {
            positionLeft = 0;
        } else if (positionLeft + this.popupWidth > window.innerWidth) {
            positionLeft = window.innerWidth - this.popupWidth;
        }
        this.popup.css("left", positionLeft);

        if (window.innerHeight - positionTop > this.popup.height()) {
            this.popup.css("top", positionTop);
        } else {
            this.popup.css("top", window.innerHeight - this.popup.height());
        }
    };

    private setPositionOnOrientationChange = () => {
        setTimeout(
            () => {
                this.positionFunction();
            },
            Util.isiOS() ? 0 : 200
        );
    };

    private openPopup = () => {
        this.popup.addClass("showPopup");
    };

    public closePopup = () => {
        this.removeCloseOnScroll();
        this.removeOrientationChange();
        $(".showPopup").removeClass("showPopup");
    };

    private addCloseOnScroll = () => {
        setTimeout(() => {
            $(document).on("click touchstart touchmove", this.closePopupOnScroll);
        }, 0);
    };

    private closePopupOnScroll = (event: Event): void => {
        let target = $(event.target);
        let targetNotFound = true;
        do {
            if (target.prop("nodeName").toLowerCase() === "body") {
                targetNotFound = false;
                this.closePopup();
            } else if (target.hasClass("popup") || target.hasClass("showPopup")) {
                targetNotFound = false;
            } else if (event.type.toLowerCase() === "touchmove") {
                this.closePopup();
                targetNotFound = false;
            }
            target = target.parent();
        } while (targetNotFound);
    };

    private removeOrientationChange = () => {
        $(window).off("orientationchange", this.setPositionOnOrientationChange);
    };

    private removeCloseOnScroll = () => {
        $(document).off("click touchstart touchmove", this.closePopupOnScroll);
    };

    public getHeight() {
        return this.popupHeight;
    }

    public destroy = () => {
        this.closePopup();
        this.removeOrientationChange();
        this.removeCloseOnScroll();
    };
}
