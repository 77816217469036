import { nameof } from "ts-simple-nameof";
import PlannedLessonModel from "../models/PlannedLessonModel";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseUserAppSettings {
    UserId: number;
    CalendarList: string;
    SyncRestriction: string;
    ShowAllUserData: boolean;
    PlannedLessons: Map<string, PlannedLessonModel>;
    LastSelectedPaymentWay: boolean;
    ShowSyncMessage: boolean;
    CollapseEducationCard: boolean;
    FirstNameFirst: boolean;
}

export interface IUserAppSettingsDto extends IBaseDto, IBaseUserAppSettings {
    appId: string;
    key: string;
    value: string;
}
export interface IUserAppSettings extends IBaseEntity, IBaseUserAppSettings {
    BillEmail: string[];
}

export default class UserAppSettings extends BaseEntity<IUserAppSettings, IUserAppSettingsDto> {
    public UserId: number;
    public BillEmail: string[];
    public CalendarList: string;
    public SyncRestriction: string;
    public ShowAllUserData: boolean;
    public PlannedLessons: Map<string, PlannedLessonModel>;
    public LastSelectedPaymentWay: boolean;
    public ShowSyncMessage: boolean;
    public CollapseEducationCard: boolean;
    public FirstNameFirst: boolean;

    public populateFromDb(dbItem: IUserAppSettings): void {
        super.populateFromDb(dbItem);
        this.UserId = dbItem.UserId;
        this.BillEmail = JSON.parse(dbItem.BillEmail as unknown as string);
        this.CalendarList = dbItem.CalendarList;
        this.SyncRestriction = dbItem.SyncRestriction;
        this.ShowAllUserData = this.getDbBoolean(dbItem.ShowAllUserData);
        this.LastSelectedPaymentWay = this.getDbBoolean(dbItem.LastSelectedPaymentWay);

        const plannedLessonsDb: Map<string, any> = JSON.parse(dbItem.PlannedLessons as unknown as string);
        const plannedLessons = new Map<string, PlannedLessonModel>();
        // ensure that old format can be loaded
        if (plannedLessonsDb) {
            plannedLessonsDb.forEach(value => {
                const pl = new PlannedLessonModel();
                pl.ChecklistPoints = value[1] && value[1]._checklistPoints ? value[1]._checklistPoints : value[1];
                if (value[1]) {
                    pl.Note = value[1]._note || "";
                } else {
                    pl.Note = "";
                }
                plannedLessons.set(value[0], pl);
            });
        }
        this.PlannedLessons = plannedLessons;
        this.ShowSyncMessage = this.getDbBoolean(dbItem.ShowSyncMessage);
        this.CollapseEducationCard = this.getDbBoolean(dbItem.CollapseEducationCard);
        this.FirstNameFirst = this.getDbBoolean(dbItem.FirstNameFirst);
    }

    public populateFromOData(oDataItem: IUserAppSettingsDto): void {
        // super.populateFromOData(oDataItem); // No base call!
        let data: any = null;
        try {
            data = JSON.parse(oDataItem.value);
        } catch (e) {
            // companyappsettings has no values
        }
        try {
            if (!data) {
                this.BillEmail = [];
            } else {
                this.BillEmail = data?.BillEmail ? JSON.parse(data?.BillEmail) : [];
            }
        } catch (error) {
            this.BillEmail = data?.BillEmail ? [data?.BillEmail] : [];
        }
        this.CalendarList = data?.CalendarList || null;
        this.SyncRestriction = data?.SyncRestriction || null;
        this.ShowAllUserData = data?.ShowAllUserData || true;
        this.LastSelectedPaymentWay = data?.LastSelectedPaymentWay || false;
        this.PlannedLessons = data?.PlannedLessons || null;
        this.ShowSyncMessage = data?.ShowSyncMessage || null;
        this.CollapseEducationCard = data?.CollapseEducationCard || false;
        this.FirstNameFirst = data?.FirstNameFirst || false;
    }

    public toODataObject(): IUserAppSettingsDto {
        // const oDataObject = super.toODataObject(); // No base call!
        const oDataObject = { appId: Util.getAppGUID(), key: UserAppSettings.getUserAppSettingsKey() } as IUserAppSettingsDto;
        oDataObject.value = JSON.stringify({
            BillEmail: JSON.stringify(this.BillEmail),
            CalendarList: this.CalendarList,
            SyncRestriction: this.SyncRestriction,
            ShowAllUserData: this.ShowAllUserData,
            LastSelectedPaymentWay: this.LastSelectedPaymentWay,
            PlannedLessons: this.PlannedLessons,
            ShowSyncMessage: this.ShowSyncMessage,
            CollapseEducationCard: this.CollapseEducationCard,
            FirstNameFirst: this.FirstNameFirst
        });
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<UserAppSettings>(x => x.Id),
            this.UserId
        ); // Override Id with UserId
        dbObject.set(
            nameof<UserAppSettings>(x => x.UserId),
            this.UserId
        );
        dbObject.set(
            nameof<UserAppSettings>(x => x.BillEmail),
            JSON.stringify(this.BillEmail)
        );
        dbObject.set(
            nameof<UserAppSettings>(x => x.CalendarList),
            this.CalendarList
        );
        dbObject.set(
            nameof<UserAppSettings>(x => x.SyncRestriction),
            this.SyncRestriction
        );
        dbObject.set(
            nameof<UserAppSettings>(x => x.ShowAllUserData),
            this.ShowAllUserData
        );
        dbObject.set(
            nameof<UserAppSettings>(x => x.LastSelectedPaymentWay),
            this.LastSelectedPaymentWay
        );
        dbObject.set(
            nameof<UserAppSettings>(x => x.PlannedLessons),
            this.PlannedLessons ? JSON.stringify(this.PlannedLessons) : null
        );
        dbObject.set(
            nameof<UserAppSettings>(x => x.ShowSyncMessage),
            this.ShowSyncMessage
        );
        dbObject.set(
            nameof<UserAppSettings>(x => x.CollapseEducationCard),
            this.CollapseEducationCard
        );
        dbObject.set(
            nameof<UserAppSettings>(x => x.FirstNameFirst),
            this.FirstNameFirst
        );
        return dbObject;
    }

    public static getUserAppSettingsKey() {
        return `UserSettings${network.getUserId()}`;
    }
}
