import Product, { IProduct } from "../types/Product";
import BaseModel from "./BaseModel";

export default class ProductModel extends BaseModel {
    public Name: string;
    public Beschreibung: string;
    public Verkaufspreis: number;
    public LessonCount: number;

    public IsEdit: boolean;

    public EditName: string;
    public EditVerkaufspreis: number;
    public EditLessonCount: number;

    constructor(product: IProduct) {
        super(product);
        this.Name = product.Name;
        this.Beschreibung = product.Beschreibung;
        this.Verkaufspreis = product.Verkaufspreis;
        this.LessonCount = product.LessonCount;

        this.EditName = product.Name;
        this.EditVerkaufspreis = product.Verkaufspreis;
        this.EditLessonCount = product.LessonCount;

        this.IsEdit = false;
        super.init(this);
    }

    public toEntity(product: IProduct = new Product()): void {
        super.toEntity(product);
        product.Name = this.Name;
        product.Beschreibung = this.Beschreibung;
        product.Verkaufspreis = this.Verkaufspreis;
        product.LessonCount = this.LessonCount;
    }
}
