export default class Transaction {
    private statements: { statement: string; params?: any[] }[] = [];

    executeSql(statement: string, params?: any[]): void {
        this.statements.push({
            statement: statement.endsWith(";") ? statement : `${statement};`,
            params
        });
    }

    public getStatements(): { statement: string; params?: any[] }[] {
        return this.statements;
    }
}
