import { keys } from "ts-transformer-keys";
import BillModel, { BillItemState } from "../models/BillModel";
import DateTime from "../modules/DateTime";
import { network } from "../modules/Network";
import OpenFileModule from "../modules/OpenFileModule";
import { Util } from "../modules/Util";
import Bill, { BillStatus, IBill, IBillDto } from "../types/Bill";
import JobDefaultPosition from "../types/JobDefaultPosition";
import { IJobPosition } from "../types/JobPosition";
import { IJobPricePosition } from "../types/JobPricePosition";
import JobProductPosition from "../types/JobProductPosition";
import JobSubTotalPosition from "../types/JobSubTotalPosition";
import { IPaymentWay, PaymentWayType } from "../types/PaymentWay";
import { checklistCollectionService } from "./ChecklistCollectionService";
import { companyAppSettingsService } from "./CompanyAppSettingsService";
import { conditionService } from "./ConditionService";
import { educationService } from "./EducationService";
import { JobBaseService } from "./JobBaseService";
import { jobPositionService } from "./JobPositionService";
import { jobService } from "./JobService";
import { localDataService } from "./LocalDataService";
import { orphyDriveJobPositionService } from "./OrphyDriveJobPositionService";
import { paymentWayService } from "./PaymentWayService";
import { personContactService } from "./PersonContactService";
import { personService } from "./PersonService";
import { productService } from "./ProductService";
import { syncService } from "./SyncService";
import { userAppSettingsService } from "./UserAppSettingsService";
import { translation } from "./TranslationService";
import { dal } from "../dal/Dal";

export default class BillService extends JobBaseService<IBill, IBillDto> {
    private readonly TABLE_NAME = "Bills";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IBillDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IBill>());
    }

    public getApiRoute(): string {
        return `${network.API}/${Bill.EntityTypeId}`;
    }

    private sendBillApiRoute(billId: number): string {
        return `${network.ODATA_API_V3}/bill(${billId})/SendAsMail`;
    }

    private getPdfRoute(billId: number): string {
        return `${network.ODATA_API_V3}/bill(${billId})/GetPdf`;
    }

    protected createEntityFromDb(item: IBill): Bill {
        const entity = new Bill();
        entity.populateFromDb(item);
        return entity;
    }

    public createEntityFromOData(item: IBillDto): Bill {
        const billEntity = new Bill();
        billEntity.populateFromOData(item);
        return billEntity;
    }

    protected async beforeProcessItemInterceptor(item: IBill): Promise<void> {
        item.NeedsStateChange = true;
        await this.updateEntity(item);
        item.BillStatus = BillStatus.Draft;
    }

    protected modifyNewItemUpdate(update: Map<string, any>, item: IBill): void {
        if (item.NeedsStateChange && update.has("BillStatus")) {
            update.delete("BillStatus");
        }
    }

    public async getBalance(educationId: number): Promise<number> {
        const education = await educationService.getItemById(educationId);
        const bills = await this.getItems(`JobMasterId = ${education.JobId} AND BillStatus = ${BillStatus.Paid}`);
        const positions = await jobPositionService.getItems(`ParentId IN ${Util.joinIds(bills.map(x => x.Id))}`);
        return this.calcPrice(positions);
    }

    public async getPaid(educationId: number): Promise<number> {
        const education = await educationService.getItemById(educationId);
        const bills = await this.getItems(`JobMasterId = ${education.JobId} AND BillStatus = ${BillStatus.Paid}`);
        const positions = await jobPositionService.getItems(`ParentId IN ${Util.joinIds(bills.map(x => x.Id))}`);
        positions.push(...(await jobPositionService.getItems(`ParentId IN ${Util.joinIds(positions.filter(x => x.Discriminator === JobSubTotalPosition.TYPE_NAME).map(x => x.Id))}`)));
        return this.calcPrice(positions);
    }

    private calcPrice(positions: IJobPosition[]) {
        return positions.reduce((a, p) => {
            if (p.Discriminator === JobProductPosition.TYPE_NAME || p.Discriminator === JobDefaultPosition.TYPE_NAME) {
                const pricePosition: IJobPricePosition = p as unknown as IJobPricePosition;
                return a + pricePosition.Amount * pricePosition.Price;
            }
            return a;
        }, 0);
    }

    public async triggerBillStatusChange(): Promise<void> {
        const bills = await this.getItems("NeedsStateChange = ?", null, null, null, null, [true]);
        for (const bill of bills) {
            bill.NeedsStateChange = false;
            await this.updateEntity(bill, true);
        }

        if (bills.length) {
            await this.patchChanges(await this.getDirtyItems());
            await this.pullItems();
        }
    }

    public async createFinalAccount(educationId: number): Promise<number> {
        await syncService.initSync();
        const billId = await network.send<number>("POST", `${network.BASE_API}/DriveAccounting/CreateBill?educationId=${educationId}&finalBill=false`);
        await productService.sync();
        await jobService.sync();
        await this.sync();
        await jobPositionService.sync();
        await orphyDriveJobPositionService.sync();
        return billId;
    }

    protected async processItem(item: Bill): Promise<number> {
        // Send bill as mail after having been pushed to Orphy
        // eslint-disable-next-line prefer-destructuring
        item.DocumentTitle = item.Name.split(" ")[0];
        const newId = await super.processItem(item);
        if (newId) {
            if (item.BillStatus !== BillStatus.Canceled) {
                await this.update("Id", newId, new Map<string, any>().set("SendBill", true), false);
            }
            return newId;
        } else {
            return newId;
        }
    }

    public async sendBills(): Promise<void> {
        const localData = await localDataService.getLocalData();
        const companyAppSettings = await companyAppSettingsService.getSettings();
        const bills = await this.getItems("SendBill = ?", null, null, null, null, [true]);
        const userAppSettings = await userAppSettingsService.getSettings();

        for (const bill of bills) {
            if (!localData.OrphyDriveOne && companyAppSettings.SendBills && bill.BillStatus !== BillStatus.Canceled) {
                const email = await personContactService.getPersonEmail(bill.PersonId);
                if (email) {
                    await network.send("POST", this.sendBillApiRoute(bill.Id), { email: email.NameInMedia });
                    if (userAppSettings && userAppSettings.BillEmail) {
                        for (const userCopyMail of userAppSettings.BillEmail) {
                            await network.send("POST", this.sendBillApiRoute(bill.Id), { email: userCopyMail });
                        }
                    }
                }
            }
            bill.SendBill = false;
            await this.updateEntity(bill, false);
        }
    }

    public updateJobForeignKey = async (oldId: number, newId: number): Promise<void> => {
        await this.update("JobMasterId", oldId, new Map<string, any>().set("JobMasterId", newId));
    };

    public async updateDependencies(oldId: number, newId: number, updateSelf: boolean): Promise<void> {
        await super.updateDependencies(oldId, newId, updateSelf);
        await jobPositionService.updateJobBaseForeignKey(oldId, newId);
    }

    public async getAllBillsByPersonId(personId, count: number = null): Promise<IBill[]> {
        return this.getItems(`PersonId = ${personId}`, "Date DESC", count);
    }

    public getBillItemById = async (billId: number): Promise<BillModel> => {
        const bills = await this.getItems(`Id = ${billId}`);
        const billItems = await this.createBillItems(bills);
        return billItems[0];
    };

    public getAllBills = async (where: string = null, limit: number = null, offset: number = null, params?: any[]): Promise<BillModel[]> => {
        const items = await this.getItems(where, "Date DESC", limit, "*", offset, params);
        return this.createBillItems(items);
    };

    public getNewestBills = async (count: number = null, personFilter: string = null): Promise<BillModel[]> => {
        const where = `BillStatus = ${BillStatus.Open}  ${personFilter ? `AND ${personFilter}` : ""}`;
        const items = await this.getItems(where, "Date DESC", count);
        return this.createBillItems(items);
    };

    public async getOverdueBills(count: number = null, personFilter: string = null): Promise<BillModel[]> {
        const where = personFilter ? ` AND ${personFilter}` : "";
        const conditions = await conditionService.getItems();
        let selector = Util.stringJoin(" OR ", conditions, condition => {
            const maxBillDate = DateTime.today();
            maxBillDate.setDate(maxBillDate.getDate() - condition.CountOfDays);
            return `(bills.ConditionId = ${condition.Id} AND bills.Date < '${maxBillDate.toISOString()}')`;
        });
        selector = `(${selector}) AND (BillStatus > ${BillStatus.Draft} AND BillStatus < ${BillStatus.Paid})`;
        selector = `(${selector}) OR (BillStatus = ${BillStatus.Reminder} OR BillStatus = ${BillStatus.LateNotice1} OR BillStatus = ${BillStatus.LateNotice2} OR BillStatus = ${BillStatus.Overdue})`;
        const limit = count ? ` LIMIT ${count}` : ``;
        const sql =
            `SELECT bills.* FROM ${this.getTableName()} AS bills LEFT JOIN ${personService.getTableName()} AS persons ON persons.Id = bills.PersonId ` +
            `WHERE ${selector} AND persons.IsDeleted = ? AND bills.IsDeleted = ? ${where} ORDER BY bills.Date ASC ${limit}`;
        const items = await dal.executeRead(sql, [false, false]);
        return this.createBillItems(items.map(this.createEntityFromDb));
    }

    private createBillItems = async (bills: IBill[]): Promise<BillModel[]> => {
        const billItems: BillModel[] = [];
        const billIds: number[] = [];
        bills.forEach(item => {
            billIds.push(item.Id);
        });
        const checklists = await checklistCollectionService.getItems();
        const persons = await personService.getPersonItems(
            `Id IN ${Util.joinIds(bills.map(b => b.PersonId).filter((value, index, array) => array.indexOf(value) === index))}`,
            null,
            null,
            "Id, FirstName, LastName"
        );

        const conditions = await conditionService.getItems();
        const paymentWays = await paymentWayService.getItems();
        const allJobPositions = await jobPositionService.getAllJobPositions(billIds);
        for (const bill of bills) {
            for (const person of persons) {
                if (bill.PersonId === person.Id) {
                    let overDue = false;
                    for (const condition of conditions) {
                        if (bill.ConditionId === condition.Id) {
                            const maxBillDate = DateTime.today();
                            maxBillDate.setDate(maxBillDate.getDate() - condition.CountOfDays);
                            overDue = maxBillDate > bill.Date;
                            break;
                        }
                    }
                    const education = await educationService.gedEducationByJobId(bill.JobMasterId);
                    const checklistCollection = education && education.ChecklistCollectionId ? checklists.find(c => c.Id === education.ChecklistCollectionId) : null;
                    const billItem = new BillModel(
                        bill.Id,
                        bill.CustomId,
                        `${person.FirstName} ${person.LastName}`,
                        bill.Date as Date,
                        this.getBillStatus(bill.BillStatus, Util.firstOrDefault(paymentWays.filter(paymentWay => bill.PaymentWayId === paymentWay.Id)), overDue),
                        checklistCollection ? checklistCollection.Id : null,
                        checklistCollection ? checklistCollection.Name : ""
                    );
                    const totalPosition = Util.firstOrDefault(Util.where(allJobPositions, item => item.Id === bill.Id));
                    if (totalPosition) {
                        billItem.TotalPrice = totalPosition.calculateTotalPrice();
                    }
                    billItems.push(billItem);
                    break;
                }
            }
        }
        return billItems;
    };

    private getBillStatus(billStatus: BillStatus, paymentWayType: IPaymentWay, overdue: boolean): BillItemState {
        if (billStatus === BillStatus.Paid) {
            if (!paymentWayType) {
                return BillItemState.Paid;
            }
            switch (paymentWayType.Type) {
                case PaymentWayType.Cash:
                    return BillItemState.PaidCash;
                case PaymentWayType.DebitCard:
                    return BillItemState.DebitCard;
                default:
                    return BillItemState.Paid;
            }
        } else if (billStatus === BillStatus.Draft) {
            return BillItemState.Draft;
        } else if (billStatus === BillStatus.Canceled) {
            return BillItemState.Canceled;
        } else if (billStatus === BillStatus.Overdue || billStatus === BillStatus.LateNotice1 || billStatus === BillStatus.LateNotice2 || billStatus === BillStatus.Reminder || overdue) {
            return BillItemState.Overdue;
        } else if (billStatus === BillStatus.Open) {
            return BillItemState.Open;
        }
    }

    public openPdfFile = async (billId: number): Promise<void> => {
        app.mobileApp.showLoading();
        const bill = await this.getItemById(billId);
        if (bill) {
            try {
                const response = await network.get<any>(this.getPdfRoute(billId));
                if (response.value) {
                    const data = response.value;
                    const fileName = `${bill.CustomId}.pdf`;
                    const mimeType = "application/pdf";
                    new OpenFileModule(
                        data,
                        fileName,
                        mimeType,
                        () => {
                            app.mobileApp.hideLoading();
                        },
                        () => {
                            app.mobileApp.hideLoading();
                            Util.showNotification(translation.t("util.unexpected-error"), "error");
                        }
                    ).openFile();
                } else {
                    Util.showNotification(translation.t("util.check-internet"), "error");
                    app.mobileApp.hideLoading();
                }
            } catch (e) {
                Util.showNotification(translation.t("util.check-internet"), "error");
                app.mobileApp.hideLoading();
            }
        }
    };

    public getUnsentBillCount = async (): Promise<number> => {
        const paymentWayCash = await paymentWayService.getPaymentWayByType(PaymentWayType.Cash);
        const paymentWayDebit = await paymentWayService.getPaymentWayByType(PaymentWayType.DebitCard);
        const sql = `SELECT COUNT(Id) AS UnsentBillCount FROM ${this.getTableName()}
         WHERE SendBill = ? AND 
         BillStatus != ${BillStatus.Canceled} AND 
         PaymentWayId != ${paymentWayCash.Id} AND 
         PaymentWayId != ${paymentWayDebit.Id}`;
        const result = await dal.firstOrDefault<{ UnsentBillCount: number }>(sql, [true]);
        if (result) {
            return result.UnsentBillCount;
        } else {
            return 0;
        }
    };

    public async setBillStatus(id: number, status: BillStatus): Promise<void> {
        const bill = await this.getItemById(id);
        bill.BillStatus = status;
        if (bill.BillStatus !== BillStatus.Paid) {
            bill.PaidAt = null;
        }
        if (bill.BillStatus === BillStatus.Paid) {
            bill.PaidAt = new Date();
        }
        await this.updateEntity(bill, true);
    }
}

export const billService = new BillService();
