import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseEntity, IBaseDto } from "./BaseEntity";

export interface IBaseEducation {
    ChecklistCollectionId: number | null;
    PersonId: number;
    ResponsibleDrivingTeacherId: number;
    MeetingPointAddress: string;
    Note: string;
    JobId: number;
}

export interface IEducationDto extends IBaseDto, IBaseEducation {}

export interface IEducation extends IBaseEntity, IBaseEducation {}

export default class Education extends BaseEntity<IEducation, IEducationDto> implements IEducation {
    public ChecklistCollectionId: number | null;
    public PersonId: number;
    public ResponsibleDrivingTeacherId: number;
    public MeetingPointAddress: string = "";
    public Note: string = "";
    public JobId: number;

    public static get EntityTypeId(): string {
        return "E854BB03-3D23-4C36-8031-9092F3DB4CEE";
    }

    public populateFromDb(dbItem: IEducation) {
        super.populateFromDb(dbItem);
        this.ChecklistCollectionId = dbItem.ChecklistCollectionId;
        this.PersonId = dbItem.PersonId;
        this.ResponsibleDrivingTeacherId = dbItem.ResponsibleDrivingTeacherId;
        this.MeetingPointAddress = dbItem.MeetingPointAddress;
        this.Note = dbItem.Note;
        this.JobId = dbItem.JobId;
    }

    public populateFromOData(oDataItem: IEducationDto): void {
        super.populateFromOData(oDataItem);
        this.ChecklistCollectionId = oDataItem.ChecklistCollectionId;
        this.PersonId = oDataItem.PersonId;
        this.ResponsibleDrivingTeacherId = oDataItem.ResponsibleDrivingTeacherId;
        this.MeetingPointAddress = oDataItem.MeetingPointAddress;
        this.Note = oDataItem.Note;
        this.JobId = oDataItem.JobId;
    }

    public toODataObject(): IEducationDto {
        const oDataObject = super.toODataObject();
        oDataObject.ChecklistCollectionId = this.ChecklistCollectionId;
        oDataObject.PersonId = this.PersonId;
        oDataObject.ResponsibleDrivingTeacherId = this.ResponsibleDrivingTeacherId;
        oDataObject.MeetingPointAddress = this.MeetingPointAddress;
        oDataObject.Note = this.Note;
        oDataObject.JobId = this.JobId;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Education>(x => x.ChecklistCollectionId),
            this.ChecklistCollectionId
        );
        dbObject.set(
            nameof<Education>(x => x.PersonId),
            this.PersonId
        );
        dbObject.set(
            nameof<Education>(x => x.ResponsibleDrivingTeacherId),
            this.ResponsibleDrivingTeacherId
        );
        dbObject.set(
            nameof<Education>(x => x.MeetingPointAddress),
            this.MeetingPointAddress
        );
        dbObject.set(
            nameof<Education>(x => x.Note),
            this.Note
        );
        dbObject.set(
            nameof<Education>(x => x.JobId),
            this.JobId
        );
        return dbObject;
    }
}
