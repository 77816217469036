import { network } from "../modules/Network";
import { translation } from "../services/TranslationService";
import { BaseView } from "./BaseView";

export default class Help extends BaseView {
    public isFAQVisible: boolean;
    public isContactVisible: boolean;
    public isInfoVisible: boolean;
    public isMenuVisible: boolean;
    public backButtonText: string;
    public headerTitle: string;

    private readonly NAV_POINT = "views/settings.html";

    public async loadData(): Promise<void> {
        // nothing to load
    }

    public showView(): void {
        this.showMenu();
        this.set("backButtonText", translation.t("common-footer.settings"));
        this.set("headerTitle", translation.t("setting.help"));
        $(".helpLink").on("click", this.openLink);
    }

    public showFAQ = (): void => {
        this.set("headerTitle", translation.t("help.faq"));
        this.showPoint(true, false, false);
        $(".helpLink").on("click", this.openLink);
    };

    public showContact = (): void => {
        this.set("headerTitle", translation.t("help.contact"));
        this.showPoint(false, true, false);
    };

    public showFAQAnswer = (e: Event): void => {
        if ($(e.target).hasClass("questionBoxOpen")) {
            $(e.target).next().slideUp("fast");
            $(".questionBoxTitle").removeClass("questionBoxOpen");
        } else {
            $(".questionBoxHelp").slideUp("fast");
            $(".questionBoxTitle").removeClass("questionBoxOpen");
            $(e.target).next().slideDown("fast");
            $(e.target).addClass("questionBoxOpen");
        }
    };

    public openLink = e => {
        const link = $(e.target).data("link").toString();
        window.open(link, "_system");
    };

    public back(): void {
        $(".helpLink").off("click", this.openLink);
        if (this.get<boolean>("isFAQVisible") || this.get<boolean>("isContactVisible") || this.get<boolean>("isInfoVisible")) {
            this.showMenu();
        } else {
            super.back();
        }
    }

    public getNavPoint(): string {
        return this.NAV_POINT;
    }

    public openPdfFile = (e): void => {
        const urlEnd = $(e.target).data("link");
        window.open(network.APP + urlEnd, "_system", "hideurlbar=yes");
    };

    private showPoint(faq, contact, info) {
        this.set("backButtonText", translation.t("common.back"));
        this.set("isMenuVisible", false);
        this.set("isFAQVisible", faq);
        this.set("isContactVisible", contact);
        this.set("isInfoVisible", info);
    }

    private showMenu = (): void => {
        this.set("backButtonText", translation.t("common-footer.settings"));
        this.set("headerTitle", "Hilfe");
        this.set("isMenuVisible", true);
        $(".questionBoxOpen").removeClass("questionBoxOpen");
        this.set("isFAQVisible", false);
        $(".questionBoxHelp").hide();
        this.set("isContactVisible", false);
        this.set("isInfoVisible", false);
    };
}
