import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { timeTrackingService } from "../services/TimeTrackingService";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { BaseView } from "./BaseView";
import TimeTrackingExport from "../export/TimeTrackingExport";
import { translation } from "../services/TranslationService";

export default class PaperworkTimetracking extends BaseView {
    public exportDisabled: boolean;

    private navPoint: string;
    private timeTrackings: kendo.data.DataSource;
    private readonly PAGE_SIZE: number = 5;
    private listView: kendo.mobile.ui.ListView;
    private filter: string = "";

    constructor() {
        super();
        this.set(
            "timeTrackings",
            kendo.data.DataSource.create({
                group: { field: "Sort", dir: "desc" },
                pageSize: this.PAGE_SIZE,
                serverPaging: true,
                serverFiltering: true,
                schema: {
                    data: "data",
                    total: "total"
                },
                transport: {
                    read: async options => {
                        const timeTrackings = await this.getTimeTrackings(options.data.skip, options.data.take);
                        this.setPagerVisibililty(timeTrackings.total);
                        options.success(timeTrackings);
                    }
                }
            } as any)
        );
        super.init(this);
    }

    private async getTimeTrackings(skip: number = null, take: number = null) {
        const settings = await userAppSettingsService.getSettings();
        const where = `Title LIKE '%' || ? || '%' ${settings.ShowAllUserData ? "" : ` AND MitarbeiterId = ${network.getPersonId()}`}`;
        const params = this.filter ? [this.filter] : [""];
        return timeTrackingService.getTimeTrackingItems(false, where, skip, take, params);
    }

    public initView(): void {
        $("#timeTrackingViewPaperworkTimetracking").show();
    }

    public async loadData(): Promise<void> {
        await this.timeTrackings.read();
    }

    public showView(e: any): void {
        this.listView = $("#listviewPaperWorkTimeTracking").data("kendoMobileListView");
        this.navPoint = e.view.params.navPoint ? e.view.params.navPoint : this.navPoint;
        this.toggleExport();
        Util.setTableWidths(4, "#paperworkTimeTrackingMasterElement");
        (this.listView as any).scroller().reset();
        $("#pagerPaperworkTimeTracking").kendoPager({
            numeric: false,
            dataSource: this.timeTrackings,
            change: this.pagerChange,
            messages: {
              display: translation.t("paperwork-time-tracking.list-footer-time")
            }
          });
    }

    private toggleExport() {
        this.set("exportDisabled", !this.timeTrackings.total());
    }

    public async search(e): Promise<void> {
        this.filter = $(e.target).val().toLowerCase();

        await this.timeTrackings.read();
        Util.setTableWidths(4, "#paperworkTimeTrackingMasterElement");
        this.toggleExport();
        (this.listView as any).scroller().reset();
    }

    private setPagerVisibililty(count: number) {
        if (count > this.PAGE_SIZE) {
            $("#pagerPaperworkTimeTracking").show();
        } else {
            $("#pagerPaperworkTimeTracking").hide();
        }
    }

    public pagerChange() {
        (this.listView as any).scroller().reset();
    }

    public async exportTimeTracking() {
        app.mobileApp.showLoading();
        const timeTrackings = await this.getTimeTrackings();
        await new TimeTrackingExport().export(timeTrackings.data);
        app.mobileApp.hideLoading();
    }

    public getNavPoint(): string {
        return this.navPoint;
    }
}
