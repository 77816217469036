import { Util } from "../modules/Util";
import { personAddressService } from "../services/PersonAddressService";
import { salutationService } from "../services/SalutationService";
import { BaseView } from "./BaseView";
import { personContactService } from "../services/PersonContactService";
import { personService } from "../services/PersonService";
import { navParams } from "../modules/NavParams";
import { nameof } from "ts-simple-nameof";
import PersonModel from "../models/PersonModel";
import PersonContactModel from "../models/PersonContactModel";
import PersonAddressModel from "../models/PersonAddressModel";
import ConfirmContent from "../modules/ConfirmContent";
import { translation } from "../services/TranslationService";

export interface RegisterPersonNavParams {
    Update: boolean;
    NavPoint: string;
}

export default class RegisterPerson extends BaseView {
    private validator: kendo.ui.Validator;
    private navPoint: string;

    public initView(): void {
        this.initTelControl();
        this.set("gender", Util.getTranslatedGender());
        this.validator = $("#registerPersonScroller").kendoValidator().data().kendoValidator;
        this.validator.bind("validateInput", Util.validateInput);
    }

    public async loadData(): Promise<void> {
        const params = navParams.getNavParam<RegisterPersonNavParams>(nameof(RegisterPerson));
        this.navPoint = params.NavPoint;
        this.set("gender", Util.getTranslatedGender());
        this.set("isEdit", params.Update);

        this.set("salutations", await salutationService.getItems());

        if (params.Update) {
            const personModel = new PersonModel(await personService.getItemById(Util.getSelectedPersonId()));
            this.set("person", personModel);
            this.set("personPhone", await personContactService.getPersonPhoneModel(personModel.Id));
            this.set("personEmail", await personContactService.getPersonEmailModel(personModel.Id));
            this.set("personAddress", await personAddressService.getPersonAddressModel(personModel.Id));
        } else {
            const personModel = new PersonModel();
            this.set("person", personModel);
            this.set("personPhone", await personContactService.getPersonPhoneModel(null));
            this.set("personEmail", await personContactService.getPersonEmailModel(null));
            this.set("personAddress", await personAddressService.getPersonAddressModel(null));
        }
    }

    public showView(e: any): void {
        this.validator.hideMessages();
        if (!e.view.params.fromMap) {
            $("#registerPersonMasterElement > div.km-content.km-stretched-view").scrollTop(0);
        }
    }

    public async saveOrUpdate() {
        if (!this.validator.validate()) {
            return;
        }
        const personModel = this.get<PersonModel>("person").toEntity();
        if (personModel.Id) {
            await personService.updateEntity(personModel, true);
        } else {
            if ((await this.emailAlreadyExists()) && !(await this.shouldCreateWithDuplicateEmail()).confirmed) {
                return;
            }
            if ((await this.nameAlreadyExists()) && !(await this.shouldCreateWithNameDuplicate()).confirmed) {
                return;
            }
            personModel.Id = await personService.insert(personModel);
            Util.setSelectedPersonId(personModel.Id);
        }
        await this.insertOrUpdatePersonAddress(personModel.Id);
        await this.insertOrUpdatePersonEmail(personModel.Id);
        await this.insertOrUpdatePersonPhone(personModel.Id);

        this.navigatePersonDetail(true);
    }

    private emailAlreadyExists(): Promise<boolean> {
        const mail = this.get<PersonContactModel>("personEmail");
        return personContactService.findDuplicateEmail(mail);
    }

    private shouldCreateWithDuplicateEmail(): Promise<{
        confirmed: boolean;
        messages: JQuery[];
    }> {
        const mail = this.get<PersonContactModel>("personEmail");
        return Util.confirmTemplate(ConfirmContent.createMailDuplicate(mail.NameInMedia), translation.t("register-person.yes-add-contact"), translation.t("common.cancel"));
    }

    private nameAlreadyExists(): Promise<boolean> {
        const personModel = this.get<PersonModel>("person");
        return personService.isDuplicateStudent(personModel.FirstName, personModel.LastName);
    }

    private shouldCreateWithNameDuplicate(): Promise<{
        confirmed: boolean;
        messages: JQuery[];
    }> {
        const personModel = this.get<PersonModel>("person");
        return Util.confirmTemplate(ConfirmContent.createNameDuplicate(personModel.FirstName, personModel.LastName), translation.t("register-person.yes-add-contact"), translation.t("common.cancel"));
    }

    private navigatePersonDetail(resetTab: boolean) {
        app.mobileApp.navigate(`views/personDetail.html?navPoint=views/allPersons.html&resetTab=${resetTab}`);
    }

    private insertOrUpdatePersonAddress = async (personId: number): Promise<void> => {
        const personAddress = this.get<PersonAddressModel>("personAddress").toEntity();
        personAddress.PersonId = personId;
        if (personAddress.Street || personAddress.StreetNumber || personAddress.City || personAddress.ZipCode) {
            await personAddressService.insertOrUpdateAddress(personId, personAddress);
        } else {
            await personAddressService.deleteAddressIfExists(personId);
        }
    };

    private insertOrUpdatePersonEmail = async (personId: number): Promise<void> => {
        const personEmail = this.get<PersonContactModel>("personEmail").toEntity();
        if (personEmail.NameInMedia) {
            await personContactService.insertOrUpdateEmail(personId, personEmail);
        } else {
            await personContactService.deleteEmailIfExists(personId);
        }
    };

    private insertOrUpdatePersonPhone = async (personId: number): Promise<void> => {
        const personPhone = this.get<PersonContactModel>("personPhone").toEntity();
        if (personPhone.NameInMedia) {
            await personContactService.insertOrUpdatePhone(personId, personPhone);
        } else {
            await personContactService.deletePhoneIfExists(personId);
        }
    };

    private initTelControl = () => {
        $("#phoneNumberRP").intlTelInput(Util.getIntlOptions());
        $("#phoneNumberRP").attr("data-val-phonenumber", translation.t("register-person.invalid-phone"));
    };

    public blurPhoneNumber = (e: Event) => {
        const element = $(e.target);
        const num = element.val();
        const currentFormat = num[0] === "+" ? window.intlTelInputUtils.numberFormat.INTERNATIONAL : window.intlTelInputUtils.numberFormat.NATIONAL;
        const countryData = $("#phoneNumberRP").intlTelInput("getSelectedCountryData").iso2;
        this.set("personPhone.NameInMedia", window.intlTelInputUtils.formatNumber(num, countryData, currentFormat));
    };

    public getNavPoint(): string {
        return this.navPoint;
    }
}
