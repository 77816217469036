import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import ContactMedia, { IContactMedia, IContactMediaDto, MediaType } from "../types/ContactMedia";
import { BaseNoHistoryService } from "./BaseNoHistoryService";

export default class ContactMediaService extends BaseNoHistoryService<IContactMedia, IContactMediaDto> {
    private TABLE_NAME = "contactmedia";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IContactMediaDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IContactMedia>());
    }

    public getApiRoute(): string {
        return `${network.API}/${ContactMedia.EntityTypeId}`;
    }

    protected createEntityFromDb(item: IContactMedia): ContactMedia {
        const contactMediaEntity = new ContactMedia();
        contactMediaEntity.populateFromDb(item);
        return contactMediaEntity;
    }

    protected createEntityFromOData(item: IContactMediaDto): ContactMedia {
        const contactMediaEntity = new ContactMedia();
        contactMediaEntity.populateFromOData(item);
        return contactMediaEntity;
    }

    public getPhoneContactMedia = async (): Promise<IContactMedia> => {
        const items = await this.getItems(`MediaType = ${MediaType.Phone}`, null, 1);
        return Util.firstOrDefault(items);
    };

    public getMobileContactMedia = async (): Promise<IContactMedia> => {
        const items = await this.getItems(`MediaType = ${MediaType.Mobile}`, null, 1);
        return Util.firstOrDefault(items);
    };

    public getEmailContactMedia = async (): Promise<IContactMedia> => {
        const items = await this.getItems(`MediaType = ${MediaType.EMail}`, null, 1);
        return Util.firstOrDefault(items);
    };
}

export const contactMediaService = new ContactMediaService();
