export default class DropdownItem extends kendo.data.ObservableObject {
    private readonly _index: number;
    private readonly _name: string;
    private _selected: boolean;

    constructor(index: number, name: string, selected: boolean = false) {
        super();
        this._index = index;
        this._name = name;
        this._selected = selected;
        super.init(this);
    }

    get Index(): number {
        return this._index;
    }

    get Name(): string {
        return this._name;
    }

    get Selected(): boolean {
        return this._selected;
    }

    set Selected(val: boolean) {
        this._selected = val;
    }
}
