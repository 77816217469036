export default class ImageItem {
    public _src: string;
    public _w: number;
    public _h: number;
    public _title: string;

    constructor(element: JQuery) {
        this._src = element.attr("src");
        this._w = element.width();
        this._h = element.height();
        this._title = $(element).closest(".myDriveGalleryPicture").find("em").html();
    }

    get src() {
        return this._src;
    }

    get w() {
        return this._w ? this._w : 100;
    }

    get h() {
        return this._h ? this._h : 100;
    }

    get title() {
        return this._title;
    }
}
