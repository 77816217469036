import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import Condition, { ConditionType, ICondition, IConditionDto } from "../types/Condition";
import { BaseNoHistoryService } from "./BaseNoHistoryService";
import { companyAppSettingsService } from "./CompanyAppSettingsService";

export default class ConditionService extends BaseNoHistoryService<ICondition, IConditionDto> {
    private readonly TABLE_NAME = "Conditions";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IConditionDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ICondition>());
    }

    public getApiRoute(): string {
        return `${network.API}/${Condition.EntityTypeId}`;
    }

    protected createEntityFromDb(item: ICondition): Condition {
        const conditionEntity = new Condition();
        conditionEntity.populateFromDb(item);
        return conditionEntity;
    }

    protected createEntityFromOData(item: IConditionDto): Condition {
        const conditionEntity = new Condition();
        conditionEntity.populateFromOData(item);
        return conditionEntity;
    }

    public async getCondition(): Promise<ICondition> {
        const companyAppSettings = await companyAppSettingsService.getSettings();
        const condition = await this.getItemById(companyAppSettings.PaymentConditionId);
        if (condition) {
            return condition;
        }
        return this.getDefaultCondition();
    }

    private async getDefaultCondition(): Promise<ICondition> {
        const items = await this.getItems(`Type = ${ConditionType.PaymentWithin30Days}`, null, 1);
        return Util.firstOrDefault(items);
    }
}

export const conditionService = new ConditionService();
