import { Connection } from "../modules/Connection";
import { logger } from "../modules/Logger";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import LocalData from "../types/LocalData";
import { addressTypeService } from "./AddressTypeService";
import { billService } from "./BillService";
import { checklistItemService } from "./ChecklistItemService";
import { checklistCollectionService } from "./ChecklistCollectionService";
import { cleanupService } from "./CleanUpService";
import { companyAppSettingsService } from "./CompanyAppSettingsService";
import { companySettingsService } from "./CompanySettingsService";
import { conditionService } from "./ConditionService";
import { contactMediaService } from "./ContactMediaService";
import { currencyService } from "./CurrencyService";
import ISynchronizableService from "./ISynchronizableService";
import { jobPositionService } from "./JobPositionService";
import { jobService } from "./JobService";
import { lessonService } from "./LessonService";
import { localDataService } from "./LocalDataService";
import { paymentWayService } from "./PaymentWayService";
import { personAddressService } from "./PersonAddressService";
import { productService } from "./ProductService";
import { profileService } from "./ProfileService";
import { salutationService } from "./SalutationService";
import { timeTrackingService } from "./TimeTrackingService";
import { unitService } from "./UnitService";
import { userAppSettingsService } from "./UserAppSettingsService";
import { userService } from "./UserService";
import { userSettingsService } from "./UserSettingsService";
import { personContactService } from "./PersonContactService";
import { personService } from "./PersonService";
import { activityService } from "./ActivityService";
import { educationService } from "./EducationService";
import { ratingService } from "./ChecklistRatingService";
import { lfaService } from "./LfaService";
import { orphyDriveJobPositionService } from "./OrphyDriveJobPositionService";
import { drivingTestService } from "./DrivingTestService";
import { testService } from "./TestService";
import { drivingStudentDocumentService } from "./DrivingStudentDocumentService";
import { Subscription } from "../types/Subscriptions";
import { loggerService } from "./LoggerService";
import EmailService from "./EmailService";
import { translation } from "./TranslationService";
import { dal } from "../dal/Dal";

interface IConfiguration {
    IsCompanyAdmin: boolean;
    IsOrphyDriveOne: boolean;
    IsReadOnly: boolean;
}

export default class SyncService {
    private isSynchronising = false;

    public async initSync(): Promise<void> {
        if (!this.getIsSynchronising()) {
            this.setIsSynchronising(true);
        } else {
            return Promise.reject();
        }

        this.changeStatusText(translation.t("sync.prepare"));
        $("#syncScreen").data().kendoMobileModalView.open();
        await network.getToken();

        if (!(await this.validVersion())) {
            return;
        }

        try {
            const syncTime = new Date().getTime();
            const userAppSettings = await userAppSettingsService.getSettings();
            if (!userAppSettings || Connection.isAllowedToSync(userAppSettings.SyncRestriction)) {
                let forbiddenItems = 0;
                await this.getOrphyDriveSubscription();
                await this.getConfiguration();
                try {
                    await this.sync("Logs", loggerService);
                } catch (e) {
                    await this.sendLogsAsMail();
                }
                forbiddenItems += await this.sync(translation.t("sync.user-settings"), userSettingsService);
                forbiddenItems += await this.sync(translation.t("sync.profile-settings"), profileService);
                forbiddenItems += await this.sync(translation.t("sync.company-settings"), companySettingsService);
                forbiddenItems += await this.sync(translation.t("sync.app-settings"), companyAppSettingsService);
                forbiddenItems += await this.sync(`&nbsp;${translation.t("sync.user-settings")}.`, userAppSettingsService);
                forbiddenItems += await this.sync(translation.t("sync.order-basis"), activityService);
                forbiddenItems += await this.sync(`&nbsp;${translation.t("sync.order-basis")}.`, conditionService);
                forbiddenItems += await this.sync(`&nbsp;&nbsp;${translation.t("sync.order-basis")}..`, paymentWayService);
                forbiddenItems += await this.sync(`&nbsp;&nbsp;&nbsp;${translation.t("sync.order-basis")}...`, currencyService);
                forbiddenItems += await this.sync(`&nbsp;&nbsp;&nbsp;&nbsp;${translation.t("sync.order-basis")}....`, unitService);
                forbiddenItems += await this.sync(translation.t("sync.crm-basis"), salutationService);
                forbiddenItems += await this.sync(`&nbsp;${translation.t("sync.crm-basis")}.`, addressTypeService);
                forbiddenItems += await this.sync(`&nbsp;&nbsp;${translation.t("sync.crm-basis")}..`, contactMediaService);
                forbiddenItems += await this.sync(translation.t("sync.customer-data"), personService);
                forbiddenItems += await this.sync(translation.t("sync.lfa-documents"), drivingStudentDocumentService);
                forbiddenItems += await this.sync(translation.t("sync.addresses"), personAddressService);
                forbiddenItems += await this.sync(translation.t("sync.contact-data"), personContactService);
                forbiddenItems += await this.sync(translation.t("sync.users"), userService);
                forbiddenItems += await this.sync(translation.t("sync.products"), productService);
                forbiddenItems += await this.sync(translation.t("sync.time-trackings"), timeTrackingService);
                forbiddenItems += await this.sync(translation.t("sync.jobs"), jobService);
                forbiddenItems += await this.sync(`&nbsp;${translation.t("sync.bills")}.`, billService);
                forbiddenItems += await this.sync(`&nbsp;&nbsp;${translation.t("sync.jobPositions")}..`, jobPositionService);
                forbiddenItems += await this.sync(`&nbsp;&nbsp;&nbsp;${translation.t("sync.jobPositions")}...`, orphyDriveJobPositionService);
                this.changeStatusText(`&nbsp;&nbsp;&nbsp;&nbsp;${translation.t("sync.bills")}....`);
                await billService.triggerBillStatusChange();
                forbiddenItems += await this.sync(translation.t("sync.educations"), educationService);
                forbiddenItems += await this.sync(translation.t("sync.lessons"), lessonService);
                forbiddenItems += await this.sync(translation.t("sync.assessments"), ratingService);
                forbiddenItems += await this.sync(translation.t("sync.licenses"), lfaService);
                forbiddenItems += await this.sync(translation.t("sync.tests"), drivingTestService);
                forbiddenItems += await this.sync(`&nbsp;${translation.t("sync.user-settings")}.`, testService);
                forbiddenItems += await this.sync(translation.t("sync.papers"), checklistCollectionService);
                forbiddenItems += await this.sync(`&nbsp;${translation.t("sync.papers")}.`, checklistItemService);
                this.changeStatusText(translation.t("sync.cleanup"));
                await cleanupService.cleanUp();
                this.changeStatusText(`&nbsp;${translation.t("sync.cleanup")}.`);

                logger.logInfo(`Sync took: ${(new Date().getTime() - syncTime) / 1000} seconds, ${forbiddenItems} forbidden items`);
                if (forbiddenItems) {
                    Util.showNotification(translation.t("sync.canceled", { count: forbiddenItems }), "info");
                }
                const localData = await localDataService.getLocalData();
                localData.LastSync = new Date();
                await localDataService.updateEntity(localData);
            }
        } catch (e) {
            logger.logError(e);
            Util.showNotification(translation.t("sync.failed"), "error");
            throw e;
        } finally {
            await this.sync(translation.t("sync.logs"), loggerService);
            this.changeStatusText("&nbsp;");
            this.stopSync();
        }
    }

    private async sendLogsAsMail() {
        try {
            const errorLogs = await loggerService.getItems("LogLevel = 'error'");
            await new EmailService().sendEmail(
                Util.getUsername(),
                "info@orphis.ch",
                `Errorlog from: ${Util.getUsername()}, UserId: ${network.getUserId()}, CompanyId: ${network.getCompanyId()}`,
                `${errorLogs.map(e => `<div>${e.Date}</div><div>${e.Log}</div><br />`)}`
            );
        } catch (e) {
            // do nothing
        } finally {
            await loggerService.hardDeleteAll();
        }
    }

    private async validVersion(): Promise<boolean> {
        try {
            const valid = await network.get<boolean>(`${network.BASE_API}/OrphyDriveConfiguration/VersionCheck`);
            if (!valid) {
                this.stopSync();
                Util.showNotification(translation.t("sync.please-update", { StoreString: Util.isiOS() ? "App Store" : "Play Store" }), "info");
            }
            return valid;
        } catch (e) {
            logger.logError(e);
            Util.showNotification(translation.t("sync.failed"), "error");
            this.stopSync();
        }
        return false;
    }

    private stopSync() {
        this.setIsSynchronising(false);
        $("#syncScreen").data().kendoMobileModalView.close();
    }

    public getDirtyItemsCountStatements(): { statements: string[]; params: any[] } {
        const statements: string[] = [];
        const params: any[] = [];
        statements.push(userSettingsService.getDirtyItemCountStatement(params));
        statements.push(profileService.getDirtyItemCountStatement(params));
        statements.push(companySettingsService.getDirtyItemCountStatement(params));
        statements.push(companyAppSettingsService.getDirtyItemCountStatement(params));
        statements.push(userAppSettingsService.getDirtyItemCountStatement(params));
        // Excluded because no push/patch:  statements.push(conditionService.getDirtyItemCountStatement());
        // Excluded because no push/patch:  statements.push(paymentWayService.getDirtyItemCountStatement());
        // Excluded because no push/patch:  statements.push(currencyService.getDirtyItemCountStatement());
        // Excluded because no push/patch:  statements.push(currencyValueService.getDirtyItemCountStatement());
        // Excluded because no push/patch:  statements.push(unitService.getDirtyItemCountStatement());
        // Excluded because no push/patch:  statements.push(salutationService.getDirtyItemCountStatement());
        // Excluded because no push/patch:  statements.push(addressTypeService.getDirtyItemCountStatement());
        // Excluded because no push/patch:  statements.push(contactMediaService.getDirtyItemCountStatement());
        statements.push(personService.getDirtyItemCountStatement(params));
        statements.push(personAddressService.getDirtyItemCountStatement(params));
        statements.push(personContactService.getDirtyItemCountStatement(params));
        // Excluded because no push/patch:  statements.push(userService.getDirtyItemCountStatement());
        statements.push(productService.getDirtyItemCountStatement(params));
        statements.push(timeTrackingService.getDirtyItemCountStatement(params));
        statements.push(jobService.getDirtyItemCountStatement(params));
        statements.push(billService.getDirtyItemCountStatement(params));
        statements.push(jobPositionService.getDirtyItemCountStatement(params));
        statements.push(lessonService.getDirtyItemCountStatement(params));
        statements.push(checklistCollectionService.getDirtyItemCountStatement(params));
        statements.push(checklistItemService.getDirtyItemCountStatement(params));
        statements.push(ratingService.getDirtyItemCountStatement(params));
        statements.push(testService.getDirtyItemCountStatement(params));
        statements.push(drivingTestService.getDirtyItemCountStatement(params));
        statements.push(lfaService.getDirtyItemCountStatement(params));
        statements.push(drivingStudentDocumentService.getDirtyItemCountStatement(params));
        return { statements, params };
    }

    public async getOverallDirtyItemsCount(): Promise<number> {
        const dirtyStatements = this.getDirtyItemsCountStatements();
        const dirtyCount = await dal.firstOrDefault<{ DirtyCount: number }>(`SELECT SUM(DirtyCount) AS DirtyCount FROM (${dirtyStatements.statements.join(" UNION ALL ")})`, dirtyStatements.params);
        return dirtyCount ? dirtyCount.DirtyCount : 0;
    }

    private async sync(statusText: string, service: ISynchronizableService): Promise<number> {
        this.changeStatusText(statusText);
        return service.sync();
    }

    private changeStatusText(statusText: string) {
        $(".syncreen .syncMessage").html(statusText);
    }

    private async getOrphyDriveSubscription(): Promise<void> {
        const localData = await localDataService.getLocalData();
        const odataRoute = `${network.ODATA_API_V3}/Subscription?$filter=BillStatus eq 'Open' or BillStatus eq 'Reminder' or BillStatus eq 'LateNotice1' or BillStatus eq 'LateNotice2' or BillStatus eq 'Overdue'`;
        const subscriptions = await network.get<{ value: Subscription[] }>(odataRoute);
        const subscriptionNotification = subscriptions.value && subscriptions.value.length > 0;
        if (localData) {
            localData.SubscriptionNotification = subscriptionNotification;
            localData.TrialOver = subscriptions.value.length === 1 && subscriptions.value[0].Id === 0 && new Date(subscriptions.value[0].RecurringJobPeriodEnd) < new Date();
            await localDataService.updateEntity(localData, false);
        } else {
            const newLocalData = new LocalData();
            newLocalData.SubscriptionNotification = subscriptionNotification;
            newLocalData.OrphyReadonly = null;
            newLocalData.LastSync = null;
            await localDataService.insert(newLocalData);
        }
    }

    private async getConfiguration(): Promise<void> {
        const configuration = await network.get<IConfiguration>(`${network.BASE_API}/OrphyDriveConfiguration/Configuration`);
        window.orphyDriveOne = configuration.IsOrphyDriveOne;
        await localDataService.updateLocalData(
            new Map<string, any>().set("OrphyDriveOne", configuration.IsOrphyDriveOne).set("CanUpdateCompanySettings", configuration.IsCompanyAdmin).set("OrphyReadonly", configuration.IsReadOnly)
        );
    }

    private setIsSynchronising(isSyncing: boolean) {
        logger.logInfo(`Synchronising flag set to: ${isSyncing}`);
        this.isSynchronising = isSyncing;
    }

    public getIsSynchronising(): boolean {
        return this.isSynchronising;
    }
}

export const syncService = new SyncService();
