import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

export enum MediaType {
    Custom,
    Phone,
    Mobile,
    Fax,
    EMail,
    Website,
    Skype,
    Twitter
}

export enum InputType {
    Unspecified,
    PhoneNumber,
    EMailAddress,
    Url
}

interface IBaseContactMedia {
    Name: string;
    MediaType: MediaType;
    InputType: InputType;
    TranslatedDisplayName: string;
}

export interface IContactMediaDto extends IBaseDto, IBaseContactMedia {}
export interface IContactMedia extends IBaseEntity, IBaseContactMedia {}

export default class ContactMedia extends BaseEntity<IContactMedia, IContactMediaDto> implements IContactMedia {
    public static get EntityTypeId(): string {
        return "4debd23a-dd8b-52c4-a088-0e6b15a6b8c7";
    }

    public Name: string;
    public MediaType: MediaType;
    public InputType: InputType;
    public TranslatedDisplayName: string;

    public populateFromDb(dbItem: IContactMedia): void {
        super.populateFromDb(dbItem);
        this.Name = dbItem.Name;
        this.MediaType = dbItem.MediaType;
        this.InputType = dbItem.InputType;
        this.TranslatedDisplayName = dbItem.TranslatedDisplayName;
    }

    public populateFromOData(oDataItem: IContactMediaDto): void {
        super.populateFromOData(oDataItem);
        this.Name = oDataItem.Name;
        this.MediaType = oDataItem.MediaType;
        this.InputType = oDataItem.InputType;
        this.TranslatedDisplayName = oDataItem.TranslatedDisplayName;
    }

    public toODataObject(): IContactMediaDto {
        return null; // ContactMedias are not pushed to Orphy
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<ContactMedia>(x => x.Name),
            this.Name
        );
        dbObject.set(
            nameof<ContactMedia>(x => x.MediaType),
            this.MediaType
        );
        dbObject.set(
            nameof<ContactMedia>(x => x.InputType),
            this.InputType
        );
        dbObject.set(
            nameof<ContactMedia>(x => x.TranslatedDisplayName),
            this.TranslatedDisplayName
        );
        return dbObject;
    }
}
