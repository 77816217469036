import { DashboardTestModel } from "../models/DashboardTestModel";
import DateTime from "../modules/DateTime";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { billService } from "../services/BillService";
import { calendarService } from "../services/CalendarService";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { localDataService } from "../services/LocalDataService";
import { personService } from "../services/PersonService";
import { syncService } from "../services/SyncService";
import { testService } from "../services/TestService";
import { timeTrackingService } from "../services/TimeTrackingService";
import { translation } from "../services/TranslationService";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { ILocalData } from "../types/LocalData";
import { kendoEvent } from "../typings/kendoEvent";
import { BaseView } from "./BaseView";

export default class MainView extends BaseView {
    private localData: ILocalData;

    public async loadData(): Promise<void> {
        await translation.reloadLanguage();
        const userAppSettings = await userAppSettingsService.getSettings();
        const companyAppSettings = await companyAppSettingsService.getSettings();
        this.localData = await localDataService.getLocalData();

        this.set("calendar", await calendarService.getCalendarItems());

        this.set("drivingTests", await testService.getFutureDrivingTestModels());
        this.set("newestBills", await billService.getNewestBills(20, userAppSettings.ShowAllUserData ? null : `ContactPersonId = ${network.getUserId()}`));

        const weeklyWorkingHours = companyAppSettings.WeeklyWorkingHours.find(wh => wh.UserId === network.getUserId());

        this.set("workingHoursVisible", !!weeklyWorkingHours && !!weeklyWorkingHours.WeeklyWorkingHours);

        if (this.get<boolean>("workingHoursVisible")) {
            const weeklyMaxWorkingHours = weeklyWorkingHours.WeeklyWorkingHours;
            const monthlyMaxWorkingHours = weeklyWorkingHours.WeeklyWorkingHours * 4;
            const thisWeekWorkingMinutes = await timeTrackingService.getThisWeekWorkingHoursInMinutes(weeklyWorkingHours.UserPersonId);
            const thisMonthWorkingMinutes = await timeTrackingService.getThisMonthWOrkingHoursInMinutes(weeklyWorkingHours.UserPersonId);
            this.set("tooMuchThisWeek", thisWeekWorkingMinutes > weeklyMaxWorkingHours * 60);
            this.set(
                "workingTimeOfMonth",
                translation.t("main-view.working-time-of-month", {
                    month: DateTime.getMonth(new Date()),
                    tooMuchThisMonth: thisMonthWorkingMinutes > monthlyMaxWorkingHours * 60 ? "tooMuchHours" : "",
                    thisMonthWorkingHours: DateTime.getFormattedHoursFromMinutes(thisMonthWorkingMinutes),
                    monthlyWorkingHours: DateTime.getFormattedHoursFromMinutes(monthlyMaxWorkingHours * 60)
                })
            );
            this.set(
                "workingTimeOfWeek",
                translation.t("main-view.working-time-of-week", {
                    thisWeekWorkingHours: DateTime.getFormattedHoursFromMinutes(thisWeekWorkingMinutes),
                    weeklyWorkingHours: DateTime.getFormattedHoursFromMinutes(weeklyMaxWorkingHours * 60)
                })
            );
        }

        await this.setBackbutton();
        await this.setDashCardStatus();
        this.setGreeting();
    }

    private setGreeting() {
        const hour = new Date().getHours();

        this.set(
            "welcomeMessage",
            translation.t("main-view.welcome-message", {
                dayTime: hour > 4 && hour < 10 ? translation.t("main-view.good-morning") : translation.t("main-view.good-day"),
                user: network.getPersonName()
            })
        );
    }

    private async setBackbutton(): Promise<void> {
        if (!this.personId) {
            this.set("backButtonVisible", false);
            return;
        }
        const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
        if (person) {
            this.set("backButtonText", `${person.FirstName} ${person.LastName}`);
            this.set("backButtonVisible", true);
        }
    }

    private async setDashCardStatus(): Promise<void> {
        const dirtyItemCount = await syncService.getOverallDirtyItemsCount();
        const unsentBillCount = await billService.getUnsentBillCount();

        if (!Util.isDemo()) {
            this.set("lastSyncText", translation.t("main-view.last-sync-text"));
            $("#lastSyncMainview").html(DateTime.parseNumberDateTime(new Date(this.localData.LastSync)));
            this.set("orphyDriveOne", window.orphyDriveOne);
            if (unsentBillCount && !window.orphyDriveOne) {
                $("#syncStatusColorMainView").addClass("syncStatusBarUnsentBills");
                $("#syncStatusTextMainview").html(
                    `<div>${translation.t("main-view.bills-not-send", { count: unsentBillCount })}.</div><div class="syncAdvice">${translation.t("main-view.click-to-send")}</div>`
                );
            } else {
                $("#syncStatusColorMainView").removeClass("syncStatusBarUnsentBills");

                if (dirtyItemCount) {
                    $("#syncStatusTextMainview").html(
                        `<div class="syncStatusBarSyncState">${translation.t("main-view.items-not-send", { count: dirtyItemCount })}</div><div class="syncAdvice">${translation.t(
                            "main-view.click-to-send"
                        )}</div>`
                    );
                } else {
                    $("#syncStatusTextMainview").html(`<div class="syncStatusBarSyncState">${translation.t("main-view.all-synced")}</div>`);
                }
            }
            if (this.localData.OrphyReadonly) {
                $("#syncStatusColorMainView").addClass("syncStatusBarReadonly");
                if (this.localData.TrialOver) {
                    $("#syncStatusTextMainview").html(`${translation.t("util.time-over")}<div>${translation.t("util.activate-license")}</div>`);
                } else {
                    $("#syncStatusTextMainview").html(`<div class="syncStatusBarSyncState">${translation.t("main-view.read-only")}</div>`);
                }
            } else {
                $("#syncStatusColorMainView").removeClass("syncStatusBarReadonly");
            }
        } else {
            $("#syncStatusColorMainView").addClass("syncStatusBarUnsentBills");
            $("#syncStatusTextMainview").html(`<div>DEMO Umgebung</div><div class="syncAdvice">${translation.t("main-view.login-for-orphydrive")}"</div>`);
            this.set("lastSyncText", translation.t("main-view.last-sync-text-demo"));
            $("#lastSyncMainview").html(`<span class='syncAdvice'>${translation.t("main-view.start-orphydrive")}</i>`);
        }
    }

    public initSync = async (e): Promise<void> => {
        // check if it's the buy orphy link
        if (e.target.tagName.toLowerCase() === "a") {
            return;
        }
        if (!Util.isDemo()) {
            await syncService.initSync();
            this.personId = Util.getSelectedPersonId();
            await this.setBackbutton();
            await translation.reloadLanguage();
            await this.loadData();
            translation.localize("#kendoUiMobileApp");
        } else {
            window.settingsModel.askForLogout();
        }
    };

    public navigatePersonDetail = (e: kendoEvent<DashboardTestModel>) => {
        Util.setSelectedPersonId(e.data.PersonId);
        app.mobileApp.navigate(`views/personDetail.html?navPoint=views/allPersons.html&backbuttonText=Schl\u00fcerliste&resetTab=true`);
    };

    public navigateBills = () => {
        app.mobileApp.navigate("views/paperworkBills.html?navPoint=views/mainView.html");
    };

    public navigateCalendarSettings = () => {
        app.mobileApp.navigate("views/settings/calendarSettings.html");
    };

    public getNavPoint(): string {
        return "views/personDetail.html?navPoint=views/allPersons.html&backbuttonText=Schl\u00fcerliste&resetTab=true";
    }

    public back(e) {
        if (this.personId && !syncService.getIsSynchronising()) {
            app.mobileApp.navigate(this.getNavPoint());
        } else {
            e.preventDefault();
        }
    }
}
