import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import User, { IUser, IUserDto } from "../types/User";
import { BaseService } from "./BaseService";

export default class UserService extends BaseService<IUser, IUserDto> {
    private readonly TABLE_NAME = "Users";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IUserDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IUser>());
    }

    public getApiRoute(): string {
        return `${network.API}/${User.EntityTypeId}`;
    }

    protected createEntityFromOData(item: IUserDto): IUser {
        const entity = new User();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: IUser): IUser {
        const entity = new User();
        entity.populateFromDb(item);
        return entity;
    }

    public async pushItems(): Promise<number> {
        return 0;
    }

    public async patchItems(): Promise<number> {
        return 0;
    }
}

export const userService = new UserService();
