import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseLogEntity {
    UserId: number;
    CompanyId: number;
}

export interface ILogDto extends IBaseDto, IBaseLogEntity {
    Source: string;
    Level: string;
    Timestamp: string;
    Message: string;
}

export interface ILogEntity extends IBaseEntity, IBaseLogEntity {
    Log: string;
    Date: string | Date;
    LogLevel: string;
}

export default class Log extends BaseEntity<ILogEntity, ILogDto> implements ILogEntity {
    public LogLevel: string;
    public Log: string;
    public Date: Date;
    public UserId: number;
    public CompanyId: number;

    public populateFromDb(dbItem: ILogEntity): void {
        super.populateFromDb(dbItem);
        this.LogLevel = dbItem.LogLevel;
        this.Log = dbItem.Log;
        this.Date = DateTime.fromDbDateTime(dbItem.Date as string);
        this.UserId = dbItem.UserId;
        this.CompanyId = dbItem.CompanyId;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public populateFromOData(oDataItem: any): void {
        // Not pulled from Orphy
    }

    public toODataObject(): ILogDto {
        const oDataObject = super.toODataObject();
        delete oDataObject.Uuid;
        oDataObject.Source = "OrphyDrive";
        switch (this.LogLevel) {
            case "error":
                oDataObject.Level = "Error";
                break;
            case "warning":
                oDataObject.Level = "Warn";
                break;
            case "info":
            default:
                oDataObject.Level = "Info";
                break;
        }
        oDataObject.Message = this.Log;
        oDataObject.Timestamp = DateTime.toODataDateTime(this.Date as Date);
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Log>(x => x.LogLevel),
            this.LogLevel
        );
        dbObject.set(
            nameof<Log>(x => x.Log),
            this.Log
        );
        dbObject.set(
            nameof<Log>(x => x.Date),
            DateTime.toDbDateTime(this.Date as Date)
        );
        dbObject.set(
            nameof<Log>(x => x.UserId),
            this.UserId
        );
        dbObject.set(
            nameof<Log>(x => x.CompanyId),
            this.CompanyId
        );
        return dbObject;
    }
}
