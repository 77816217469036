import DateTime from "../modules/DateTime";
import { Util } from "../modules/Util";
import { localDataService } from "../services/LocalDataService";
import { translation } from "../services/TranslationService";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { BaseView } from "./BaseView";

export default class SynchronisationSettings extends BaseView {
    public selectedSyncSettings: string;
    public lastSyncDate: string;

    private readonly NAV_POINT = "views/settings.html";

    public async loadData(): Promise<void> {
        const userAppSettings = await userAppSettingsService.getSettings();
        const localData = await localDataService.getLocalData();
        this.set("lastSyncDate", DateTime.parseStringDateTime(localData.LastSync as Date));
        this.set("selectedSyncSettings", userAppSettings.SyncRestriction ? userAppSettings.SyncRestriction : translation.t("common.always"));
    }

    public save = async (): Promise<void> => {
        await userAppSettingsService.updateSettings(new Map<string, string>().set("SyncRestriction", this.get<string>("selectedSyncSettings")));
        Util.showNotification(translation.t("synchronize-settings.save-successfully"), "success");
        this.back();
    };

    public getNavPoint(): string {
        return this.NAV_POINT;
    }
}
