import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseCurrency {
    Code: string;
}

export interface ICurrencyDto extends IBaseDto, IBaseCurrency {}
export interface ICurrency extends IBaseEntity, IBaseCurrency {}

export default class Currency extends BaseEntity<ICurrency, ICurrencyDto> implements ICurrency {
    public static get EntityTypeId(): string {
        return "076d21c9-c480-5f87-bab4-43db61349962";
    }

    public Code: string;

    public populateFromDb(dbItem: ICurrency): void {
        super.populateFromDb(dbItem);
        this.Code = dbItem.Code;
    }

    public populateFromOData(oDataItem: ICurrencyDto): void {
        super.populateFromOData(oDataItem);
        this.Code = oDataItem.Code;
    }

    public toODataObject(): ICurrencyDto {
        return null; // Currencies are not pushed to Orphy
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Currency>(x => x.Code),
            this.Code
        );
        return dbObject;
    }
}
