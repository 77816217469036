import { IBaseEntity } from "../types/BaseEntity";
import ObservableObject from "../viewModels/ObservableObject";
import { v4 as uuidv4 } from "uuid";

export default abstract class BaseModel extends ObservableObject {
    public Id: number;
    public Uuid: string;
    public IsNew: boolean;

    constructor(baseEntity: IBaseEntity) {
        super();
        this.Id = baseEntity.Id;
        this.IsNew = baseEntity.IsNew;
        this.Uuid = baseEntity.Uuid;
        super.init(this);
    }

    public toEntity(entity: IBaseEntity) {
        entity.Id = this.Id;
        if (!this.Uuid) {
            this.Uuid = uuidv4();
        }
        entity.Uuid = this.Uuid;
        entity.IsNew = this.IsNew;
    }
}
