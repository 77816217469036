import BillModel, { BillItemState } from "../models/BillModel";
import DateTime from "../modules/DateTime";
import { network } from "../modules/Network";
import { orientationChange } from "../modules/OrientationChange";
import { Util } from "../modules/Util";
import { billService } from "../services/BillService";
import { personService } from "../services/PersonService";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { BillStatus } from "../types/Bill";
import { BaseView } from "./BaseView";
import BillExport from "../export/BillExport";
import { logger } from "../modules/Logger";
import ConfirmContent from "../modules/ConfirmContent";
import { translation } from "../services/TranslationService";
import { conditionService } from "../services/ConditionService";

export default class PaperworkBills extends BaseView {
    private navPoint: string;
    private billItems: kendo.data.DataSource;
    private searchFilter: string = "";
    private readonly PAGE_SIZE: number = 50;
    private listView: kendo.mobile.ui.ListView;

    public showOverdue: boolean;
    public exportDisabled: boolean;
    public pagerVisible: boolean = false;
    public searchString: string = "";

    constructor() {
        super();
        this.set(
            "billItems",
            kendo.data.DataSource.create({
                pageSize: this.PAGE_SIZE,
                serverPaging: true,
                serverFiltering: true,
                schema: {
                    data: "data",
                    total: "total"
                },
                transport: {
                    read: async options => {
                        let where = "";
                        try {
                            const settings = await userAppSettingsService.getSettings();
                            const defaultWhere = `BillStatus != ${BillStatus.Canceled}`;
                            where = settings.ShowAllUserData ? defaultWhere : `ContactPersonId = ${network.getUserId()} AND ${defaultWhere}`;

                            if (this.searchFilter) {
                                const personIds = await personService.getPersonItems(
                                    `FirstName LIKE '%' || ? || '%' OR LastName || ' ' || FirstName LIKE '%' || ? || '%'`,
                                    null,
                                    null,
                                    "Id",
                                    null,
                                    this.searchFilter ? [this.searchFilter, this.searchFilter] : []
                                );
                                where += ` AND ((CustomId LIKE '%' || ? || '%') OR PersonId IN ${Util.joinIds(personIds.map(x => x.Id))})`;
                            }
                            if (this.get<boolean>("showOverdue")) {
                                const conditions = await conditionService.getItems();
                                const conditionSelect = Util.stringJoin(" OR ", conditions, condition => {
                                    const maxBillDate = DateTime.today();
                                    maxBillDate.setDate(maxBillDate.getDate() - condition.CountOfDays);
                                    return `(bills.ConditionId = ${condition.Id} AND bills.Date < '${maxBillDate.toISOString()}')`;
                                });
                                where += ` AND (${conditionSelect}) AND (BillStatus > ${BillStatus.Draft} AND BillStatus < ${BillStatus.Paid})`;
                                where += ` OR (BillStatus = ${BillStatus.Reminder} OR BillStatus = ${BillStatus.LateNotice1} OR BillStatus = ${BillStatus.LateNotice2} OR BillStatus = ${BillStatus.Overdue})`;
                            }
                            const params = this.searchFilter ? [this.searchFilter] : [];
                            const billItems = await billService.getAllBills(
                                where,
                                options.data.take,
                                options.data.skip,
                                params.map(x => x)
                            );
                            const count = await billService.getItemCount(
                                where,
                                params.map(x => x)
                            );
                            const result = this.getBillItems(billItems, count);
                            this.setPagerVisibililty(count);
                            options.success(result);
                        } catch (e) {
                            logger.logError(new Error(where), true);
                            logger.logError(e, true);
                        }
                    }
                } as any
            } as kendo.data.DataSourceOptions)
        );
        super.init(this);
    }

    public async loadData(e: any): Promise<void> {
        $("#billListPaperworkBills2").show();
        this.set("showOverdue", e.view.params.showOverdue === "true");

        this.searchFilter = e.view.params.billId ? e.view.params.billId : "";
        this.set("searchString", this.searchFilter);

        await this.get<kendo.data.DataSource>("billItems").read();
        if (this.searchFilter && this.personId) {
            const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
            if (person) {
                this.set("backButtonText", `${person.FirstName} ${person.LastName}`);
            }
        } else {
            this.set("backButtonText", "");
        }
    }

    public showView(e: any): void {
        this.navPoint = e.view.params.navPoint ? e.view.params.navPoint : this.navPoint;
        this.listView = $("#billListEndlessListView").data("kendoMobileListView");
        this.listView.refresh();
        orientationChange.FunctionList = [this.setHeaders];
        (this.listView as any).scroller().reset();
        $("#pagerPaperworkBills").kendoPager({
            numeric: false,
            dataSource: this.billItems,
            change: this.pagerChange,
            messages: {
                display: translation.t("paperwork-bills.list-footer-bill")
            }
        });
    }

    private setHeaders = () => {
        Util.setTableWidths(7, "#paperworkBillMasterElement", true);
    };

    public toggleExport = () => {
        this.set("exportDisabled", !this.billItems.total());
    };

    private getBillItems(items: BillModel[], total: number) {
        const newItems = {
            data: [],
            total
        };
        items.forEach(item => {
            newItems.data.push({
                Id: item.Id,
                Date: item.Date,
                DateView: DateTime.parseNumberDate(item.Date),
                CustomId: item.CustomId ? item.CustomId : '<i class="icon-sync icons largeIcon"></i>',
                Title: item.Title,
                TotalPrice: item.TotalPrice,
                TotalPriceView: Util.formatPrice(item.TotalPrice),
                ChecklistName: item.ChecklistName,
                StatusIcon: this.getStatusIcon(item.StatusIcon),
                IsPaid: item.IsPaid,
                IsNotCanceled: item.IsNotCanceled,
                DeleteAble: item.DeleteAble,
                BillStatus: item.BillStatus,
                StatusText: item.StatusText
            });
        });
        return newItems;
    }

    public pagerChange = () => {
        (this.listView as any).scroller().reset();
    };

    public async togglePaid(e) {
        const { data } = e;
        if (data.IsNotCanceled) {
            if (data.BillStatus === BillItemState.Paid || data.BillStatus === BillItemState.PaidCash || data.BillStatus === BillItemState.DebitCard) {
                const setOpen = (await Util.confirmTemplate(ConfirmContent.reopenBill(), translation.t("paperwork-bills.yes-show-as-open"), translation.t("common.cancel"))).confirmed;
                if (setOpen) {
                    await this.setBillStatus(data, BillStatus.Open);
                }
            } else if (data.BillStatus === BillItemState.Draft) {
                await this.setBillStatus(data, BillStatus.Open);
                await billService.update("Id", data.Id, new Map<string, any>().set("SendBill", true));
            } else {
                await this.setBillStatus(data, BillStatus.Paid);
            }
        }
    }

    public async deleteBill(e): Promise<void> {
        const { data } = e;
        const cancel = (await Util.confirmTemplate(ConfirmContent.cancelBill(), translation.t("paperwork-bills.yes-finally-cancel"), translation.t("common.cancel"))).confirmed;
        if (cancel) {
            app.mobileApp.showLoading();
            await billService.setBillStatus(e.data.Id, BillStatus.Canceled);
            const billItem = await billService.getBillItemById(e.data.Id);
            app.mobileApp.hideLoading();
            data.set("DeleteAble", billItem.DeleteAble);
            data.set("IsNotCanceled", billItem.IsNotCanceled);
            data.set("StatusIcon", this.getStatusIcon(billItem.StatusIcon));
            data.set("BillStatus", billItem.BillStatus);
        }
    }

    private setBillStatus = async (data: any, status: BillStatus): Promise<void> => {
        app.mobileApp.showLoading();
        await billService.setBillStatus(data.Id, status);
        const billItem = await billService.getBillItemById(data.Id);
        data.set("DeleteAble", billItem.DeleteAble);
        data.set("IsNotCanceled", billItem.IsNotCanceled);
        data.set("StatusIcon", this.getStatusIcon(billItem.StatusIcon));
        data.set("BillStatus", billItem.BillStatus);
        app.mobileApp.hideLoading();
    };

    public search = e => {
        this.searchText($(e.target).val());
    };

    private searchText(text: string) {
        this.searchFilter = text;
        this.setFilter(this.billItems);
        (this.listView as any).scroller().reset();
    }

    public toggleOverdue = e => {
        this.set("showOverdue", e.checked);
        this.setFilter(this.billItems);
        (this.listView as any).scroller().reset();
    };

    private setFilter = (dataSource: kendo.data.DataSource) => {
        dataSource.filter({});
    };

    private setPagerVisibililty = (count: number) => {
        if (count > this.PAGE_SIZE) {
            $("#paperworkBillMasterElement #pagerPaperworkBills").show();
        } else {
            $("#paperworkBillMasterElement #pagerPaperworkBills").hide();
        }
    };

    private getStatusIcon(statusIcon: string) {
        return `<div class="btn btnOutline">${statusIcon}</div>`;
    }

    public openPDF = async e => {
        if (e.data.Id > 0) {
            await billService.openPdfFile(e.data.Id);
        }
    };

    public exportBills = async (): Promise<void> => {
        app.mobileApp.showLoading();
        await this.billItems.read();
        const exportDataSource = kendo.data.DataSource.create({
            data: this.billItems.data()
        });
        this.setFilter(exportDataSource);
        await new BillExport().export(exportDataSource.view() as unknown as BillModel[]);
        app.mobileApp.hideLoading();
    };

    public getNavPoint() {
        return this.navPoint;
    }
}
