import { nameof } from "ts-simple-nameof";

export interface IBase {
    Id: number;
    Uuid: string;
    IsNew: boolean;
    IsDeleted: boolean;
}

export interface IBaseDto extends IBase {
    populateFromOData(oDataItem: IBaseDto): void;
}

export interface IBaseEntity extends IBase {
    IsDirty: boolean;
    populateFromDb(item: IBaseEntity): void;
    toDbObject(): Map<string, any>;
    toODataObject(): IBaseDto;
}

export default abstract class BaseEntity<TEntity extends IBaseEntity, TDto extends IBaseDto> extends kendo.data.ObservableObject implements IBaseEntity {
    public Id: number;
    public Uuid: string;
    public IsDirty: boolean = false;
    public IsNew: boolean = true;
    public IsDeleted: boolean = false;

    public static get EntityTypeId(): string {
        throw Error("BaseEntity has no EntityTypeId or derived class has not implemented EntityTypeId");
    }

    // TODO: OR-892 Comment this in to see where Entities instead of models are used
    // public get(str: any): any {
    //     throw new Error("Should not extend kendo");
    // }

    // public set(str: any, val: any) {
    //     throw new Error("Should not extend kendo");
    // }

    public populateFromDb(item: TEntity): void {
        this.Id = item.Id;
        this.Uuid = item.Uuid;
        this.IsDeleted = this.getDbBoolean(item.IsDeleted);
        this.IsDirty = this.getDbBoolean(item.IsDirty);
        this.IsNew = this.getDbBoolean(item.IsNew);
    }

    public populateFromOData(oDataItem: TDto): void {
        this.Id = oDataItem.Id;
        this.Uuid = oDataItem.Uuid;
        this.IsNew = false;
    }

    public toODataObject(): TDto {
        return {
            Uuid: this.Uuid
        } as TDto;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = new Map<string, any>();
        dbObject.set(
            nameof<BaseEntity<TEntity, TDto>>(x => x.Id),
            this.Id
        );
        dbObject.set(
            nameof<BaseEntity<TEntity, TDto>>(x => x.Uuid),
            this.Uuid
        );
        dbObject.set(
            nameof<BaseEntity<TEntity, TDto>>(x => x.IsDirty),
            this.IsDirty
        );
        dbObject.set(
            nameof<BaseEntity<TEntity, TDto>>(x => x.IsNew),
            this.IsNew
        );
        dbObject.set(
            nameof<BaseEntity<TEntity, TDto>>(x => x.IsDeleted),
            this.IsDeleted
        );
        return dbObject;
    }

    protected getDbBoolean(val: boolean): boolean {
        try {
            return !!JSON.parse(val as unknown as any);
        } catch (e) {
            return false;
        }
    }

    protected getNullAbleDbBoolean(val: boolean | null) {
        if (val === null) {
            return null;
        }
        return this.getDbBoolean(val);
    }
}
