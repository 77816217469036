import { Util } from "./Util";

export default class OrientationChange {
    private functionList: (() => void)[] = [];

    constructor() {
        $(window).on("orientationchange", this.orientationChanged);
    }

    get FunctionList(): (() => void)[] {
        return this.functionList;
    }

    set FunctionList(val: (() => void)[]) {
        this.functionList = val;
        this.orientationChanged();
    }

    private orientationChanged = (): void => {
        setTimeout(
            () => {
                for (const func of this.FunctionList) {
                    func();
                }
            },
            Util.isiOS() ? 0 : 200
        );
    };
}

export const orientationChange = new OrientationChange();
