import { Util } from "../modules/Util";
import { ICompanySettings } from "../types/CompanySettings";
import { ILocalData } from "../types/LocalData";
import { Gender } from "../types/Person";
import { IProfile } from "../types/Profile";
import { IUser } from "../types/User";
import { IUserAppSettings } from "../types/UserAppSettings";
import { translation } from "../services/TranslationService";

export default class ProfileModel extends kendo.data.ObservableObject {
    public CanUpdateCompanySettings: boolean;
    public CompanyName: string;
    public Sex: Gender;
    public NameFirst: string;
    public NameFamily: string;
    public Street: string;
    public StreetNumber: string;
    public Plz: string;
    public Ort: string;
    public Email: string;
    public PhoneNumber: string;
    public EasyAccounting: boolean;
    public ShowAllUserData: boolean;
    public HasMoreThanOneUser: boolean;
    public CollapseEducationCard: boolean;
    public gender = Util.getTranslatedGender();
    public languages = translation.getLanguageModels();
    public FirstNameFirst: boolean;
    public Language: string;

    constructor(profile: IProfile, companySettings: ICompanySettings, userAppSettings: IUserAppSettings, users: IUser[], localData: ILocalData) {
        super();
        this.CanUpdateCompanySettings = localData.CanUpdateCompanySettings;
        this.HasMoreThanOneUser = users.length > 1;
        this.ShowAllUserData = !userAppSettings.ShowAllUserData;

        this.CompanyName = companySettings.CompanyName;
        this.Sex = profile.Sex;
        this.NameFirst = profile.NameFirst;
        this.NameFamily = profile.NameFamily;
        this.Street = profile.Street;
        this.StreetNumber = profile.StreetNumber;
        this.Plz = profile.Plz;
        this.Ort = profile.Ort;
        this.Email = profile.Email;
        this.PhoneNumber = profile.PhoneNumber;
        this.EasyAccounting = localData.EasyAccounting;
        this.CollapseEducationCard = userAppSettings.CollapseEducationCard;
        this.FirstNameFirst = userAppSettings.FirstNameFirst;
        this.Language = translation.getSelectedLanguage();
        super.init(this);
    }

    public getProfileUpdate = (): Map<string, any> => {
        const profileUpdate = new Map<string, any>();
        profileUpdate.set("Sex", this.get("Sex"));
        profileUpdate.set("NameFirst", this.get("NameFirst"));
        profileUpdate.set("NameFamily", this.get("NameFamily"));
        profileUpdate.set("Street", this.get("Street"));
        profileUpdate.set("StreetNumber", this.get("StreetNumber"));
        profileUpdate.set("Plz", this.get("Plz"));
        profileUpdate.set("Ort", this.get("Ort"));
        profileUpdate.set("Email", this.get("Email"));
        profileUpdate.set("PhoneNumber", this.get("PhoneNumber"));
        return profileUpdate;
    };

    public getUserAppSettingsUpdate(): Map<string, any> {
        return new Map<string, any>()
            .set("ShowAllUserData", !this.get("ShowAllUserData"))
            .set("CollapseEducationCard", this.get("CollapseEducationCard"))
            .set("FirstNameFirst", this.get("FirstNameFirst"));
    }

    public getLocalDataUpdate(): Map<string, any> {
        return new Map<string, any>().set("EasyAccounting", this.get("EasyAccounting"));
    }

    public getCompanyUpdate(): Map<string, any> {
        return new Map<string, any>().set("CompanyName", this.get("CompanyName"));
    }

    public getUserSettingsUpdate() {
        return new Map<string, any>().set("Language", this.get("Language"));
    }
}
