import { keys } from "ts-transformer-keys";
import ProductChooserModel from "../models/ProductChooserModel";
import { network } from "../modules/Network";
import Product, { IProduct, IProductDto } from "../types/Product";
import { BaseService } from "./BaseService";
import { jobPositionService } from "./JobPositionService";

export default class ProductService extends BaseService<IProduct, IProductDto> {
    private readonly TABLE_NAME = "Products";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IProductDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IProduct>());
    }

    public getApiRoute(): string {
        return `${network.API}/${Product.EntityTypeId}`;
    }

    protected createEntityFromOData(item: IProductDto): IProduct {
        const entity = new Product();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: IProduct): IProduct {
        const entity = new Product();
        entity.populateFromDb(item);
        return entity;
    }

    public async updateDependencies(oldId: number, newId: number, updateSelf: boolean): Promise<void> {
        await super.updateDependencies(oldId, newId, updateSelf);
        await jobPositionService.updateProductForeignKey(oldId, newId);
    }

    public getDefaultLessonProduct(): Promise<IProduct> {
        return this.getItemByUuid(Product.DefaultLessonId);
    }

    public getDefaultPaymentFlowProduct(): Promise<IProduct> {
        return this.getItemByUuid(Product.DefaultPaymentFlowId);
    }

    public getItems(where?: string, orderBy?: string, limit?: number, select?: string, offset?: number): Promise<IProduct[]> {
        const defaultProductFilter = `Uuid != '${Product.DefaultPaymentFlowId}' COLLATE NOCASE AND Uuid != '${Product.DefaultLessonId}' COLLATE NOCASE`;
        if (where) {
            where += ` AND ${defaultProductFilter}`;
        } else {
            where = defaultProductFilter;
        }
        return super.getItems(where, orderBy, limit, select, offset);
    }

    public getChooserModel = async (): Promise<ProductChooserModel[]> => {
        const products = await this.getItems(null, "LessonCount ASC");
        return products.map(p => new ProductChooserModel(p.Id, p.Name, p.LessonCount, p.Verkaufspreis));
    };
}

export const productService = new ProductService();
