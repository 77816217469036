import PersonDetailModel from "../models/PersonDetailModel";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { BaseService } from "./BaseService";
import { jobPositionService } from "./JobPositionService";
import { lastSyncDateService } from "./LastSyncDateService";
import { lessonService } from "./LessonService";
import { personAddressService } from "./PersonAddressService";
import { reactivateService } from "./ReactivateService";
import { userService } from "./UserService";
import Person, { IPersonDto, IPerson } from "../types/Person";
import { userAppSettingsService } from "./UserAppSettingsService";
import AllPersonModel from "../models/AllPersonModel";
import { personContactService } from "./PersonContactService";
import { IDiffDto } from "../types/IDiffDto";
import { jobService } from "./JobService";
import { billService } from "./BillService";
import { timeTrackingService } from "./TimeTrackingService";
import PersonModel from "../models/PersonModel";
import { checklistCollectionService } from "./ChecklistCollectionService";
import { educationService } from "./EducationService";
import { drivingStudentDocumentService } from "./DrivingStudentDocumentService";
import { keys } from "ts-transformer-keys";
import PlanLesson from "../viewModels/PlanLesson";
import AllPersonItemModel from "../models/AllPersonItemModel";
import { translation } from "./TranslationService";
import { cleanupService } from "./CleanUpService";
import { dal } from "../dal/Dal";

export default class PersonService extends BaseService<IPerson, IPersonDto> {
    private readonly TABLE_NAME = "Persons";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IPerson>());
    }

    public getDtoFields() {
        return keys<IPersonDto>().filter(x => x !== "Blobs");
    }

    public getApiRoute(): string {
        return `${network.API}/${Person.EntityTypeId}`;
    }

    public createEntityFromOData(item: IPersonDto): IPerson {
        const entity = new Person();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: IPerson): IPerson {
        const entity = new Person();
        entity.populateFromDb(item);
        return entity;
    }

    public async getPersonItems(where?: string, orderBy?: string, limit?: number, select?: string, offset?: number, params?: any[], inactive: boolean = false): Promise<IPerson[]> {
        const userAppSettings = await userAppSettingsService.getSettings();
        const userDataFilter = `Id IN (SELECT PersonId From Educations WHERE ResponsibleDrivingTeacherId = ${network.getUserId()})`;
        const conditions: string[] = [];
        params = params ?? [];
        if (where) {
            conditions.push(`(${where})`);
        }
        if (inactive) {
            conditions.push(`IsInactive = ?`);
            params.push(true);
        } else {
            if (!userAppSettings.ShowAllUserData) {
                conditions.push(userDataFilter);
            }
            conditions.push(`IsInactive = ?`);
            params.push(false);
        }

        where = conditions.join(" AND ");

        return super.getItems(where, orderBy, limit, select, offset, params);
    }

    public getAllPersonModels = async (showInactive: boolean, searchFilter: string, take: number, skip: number): Promise<AllPersonModel> => {
        const userAppSettings = await userAppSettingsService.getSettings();

        const conditions: string[] = [];

        if (searchFilter) {
            conditions.push(`(FirstName || ' ' || LastName LIKE '%' || ? || '%' OR LastName || ' ' || FirstName LIKE '%' || ? || '%')`);
        }

        const orderBy = userAppSettings.FirstNameFirst ? "FirstName COLLATE NOCASE" : "LastName COLLATE NOCASE";

        conditions.push(`Id NOT IN ${Util.joinIds((await userService.getItems()).map(x => x.PersonId))}`);

        const where = conditions.join(" AND ");

        const persons = await this.getPersonItems(where, orderBy, take, "Id, FirstName, LastName, IsInactive", skip, searchFilter ? [searchFilter, searchFilter] : [], showInactive);

        const totalCount = (await this.getPersonItems(where, null, null, "Id", null, searchFilter ? [searchFilter, searchFilter] : [], showInactive)).length;

        const personResult = [];
        for (const person of persons) {
            const mail = await personContactService.getPersonEmail(person.Id);
            const phone = await personContactService.getPersonPhone(person.Id);
            const checklistNames = await checklistCollectionService.getChecklistNamesByPersonId(person.Id);
            const item = new AllPersonItemModel(person, mail?.NameInMedia, phone?.NameInMedia, checklistNames, userAppSettings.FirstNameFirst);
            personResult.push(item);
        }

        return new AllPersonModel(personResult, totalCount);
    };

    public async hasPersonEntries(): Promise<boolean> {
        return (await this.getItems(null, null, null, "Id", null)).length > 0;
    }

    protected async afterGetInitalItemsInterceptor(persons: IPersonDto[]): Promise<void> {
        for (const person of persons.filter(x => !x.IsInactive)) {
            await drivingStudentDocumentService.fetchDocuments(person.Id, person.Blobs);
        }
    }

    public async updateDependencies(oldId: number, newId: number, updateSelf: boolean): Promise<void> {
        const updateFK = new Map<string, any>().set("PersonId", newId);
        await super.updateDependencies(oldId, newId, updateSelf);
        await jobService.update("PersonId", oldId, updateFK, false);
        await billService.update("PersonId", oldId, updateFK, false);
        await personAddressService.update("PersonId", oldId, updateFK, false);
        await personContactService.update("PersonId", oldId, updateFK, false);
        await timeTrackingService.update("PersonId", oldId, updateFK, false);
        await educationService.update("PersonId", oldId, updateFK, false);
        await drivingStudentDocumentService.update("PersonId", oldId, updateFK, false);
        Util.updateEducationIdKey(oldId, newId);
        if (oldId === Util.getSelectedPersonId()) {
            Util.setSelectedPersonId(newId);
        }
        const userAppSettings = await userAppSettingsService.getSettings();

        const educations = await educationService.getEducationsByPersonId(newId);
        for (const education of educations) {
            const oldKey = PlanLesson.getStoredLessonKey(oldId, education.Id);
            const newKey = PlanLesson.getStoredLessonKey(newId, education.Id);
            const plannedLesson = userAppSettings.PlannedLessons.get(oldKey);
            if (plannedLesson) {
                userAppSettings.PlannedLessons.delete(oldKey);
                userAppSettings.PlannedLessons.set(newKey, plannedLesson);
            }
        }
        await userAppSettingsService.updateEntity(userAppSettings);
    }

    public async getPersonModel(personId: number): Promise<PersonModel> {
        return new PersonModel(await this.getItemById(personId));
    }

    public getPersonDetailItem = async (educationId: number, personId: number): Promise<PersonDetailModel> => {
        const person = await this.getItemById(personId);
        const educationModel = await educationService.getSelectedEducationModel(educationId, personId);
        const personAddress = await personAddressService.getPersonAddress(personId);
        const lessonCount = await lessonService.getLessonCount(educationId, personId);
        const jobPositions = await jobPositionService.getAllPositionsByEducationId(educationModel.Id);
        const balanceLeft = await jobPositionService.getAvailableBalance(educationId, personId);
        const user = await userService.getItemById(educationModel.ResponsibleDrivingTeacherId);
        const balance = await billService.getBalance(educationId);
        const paid = await billService.getPaid(educationId);
        return new PersonDetailModel(person, educationModel, lessonCount, balanceLeft, personAddress, jobPositions, user ? user.DisplayName : translation.t("common.no-contact-person"), balance, paid);
    };

    protected async handleModifiedItems(items: IDiffDto[]): Promise<void> {
        for (const item of items) {
            if (item.Id === Util.getSelectedPersonId() && item.ChangedProperties.IsDeleted) {
                Util.setSelectedPersonId(null);
            }

            if (item && item.ChangedProperties.hasOwnProperty("IsInactive") && !item.ChangedProperties.IsInactive) {
                const lastSyncDate = await lastSyncDateService.getLastSyncDate(this.getTableName());
                // prevent this during inital sync because the data will be fetched anyways
                if (lastSyncDate !== null) {
                    await reactivateService.reactivate(item.Id);
                }
            }

            if (item && item.ChangedProperties.hasOwnProperty("Blobs")) {
                await drivingStudentDocumentService.fetchDocuments(item.Id, item.ChangedProperties.Blobs);
            }
            const users = await userService.getItems(null, null, null, "PersonId");
            // something has changed with the users person entry
            // we want to update the users display name in any case
            // e.g (userperson change name => delete => undelete) => we won't get the name change! So we get the item and update the users display name
            if (users.some(u => u.PersonId === item.Id)) {
                // we can do this because we know at this point that the user is not deleted in orphy
                const person = await network.get<IPersonDto>(`${this.getApiRoute()}/${item.Id}`);
                if (person) {
                    await userService.update("PersonId", person.Id, new Map<string, any>().set("DisplayName", `${person.FirstName} ${person.LastName}`));
                }
            } else {
                await this.update("Id", item.Id, this.createDbUpdateObject(item), false);
            }
        }
    }

    public async reactivatePerson(personId: number): Promise<void> {
        if (personId < 0) {
            const person = await this.getItemById(personId);
            person.IsInactive = false;
            await this.updateEntity(person, false);
            return;
        }
        await this.sync();
        const person = await this.getItemById(personId);
        person.IsInactive = false;
        await this.updateEntity(person, true);
        await this.sync();
        await reactivateService.reactivate(personId);
    }

    public async refetchPerson(personId: number): Promise<void> {
        const person = await this.getItemById(personId);
        person.IsInactive = true;
        await this.updateEntity(person, false);
        await cleanupService.cleanUp();
        person.IsInactive = false;
        await this.updateEntity(person, false);
        await this.reactivatePerson(personId);
    }

    public gatherInactivePersons = async (): Promise<IPerson[]> => {
        const items = await dal.executeRead(
            `SELECT Id FROM ${this.getTableName()}
            WHERE Id IN (SELECT PersonId FROM ${educationService.getTableName()})
            AND IsInactive = ?`,
            [true]
        );
        return items.map(this.createEntityFromDb);
    };

    public isDuplicateStudent = async (firstName: string, lastName: string): Promise<boolean> => {
        const where = `FirstName = ? AND LastName = ?`;
        const result = await this.getItems(where, null, 1, "Id", null, [firstName, lastName]);
        return !!result.length;
    };

    protected getODataFilter(): string {
        return "Character/all(c:c/Character/CharacterType ne 'CoWorker')";
    }
}

export const personService = new PersonService();
