import DateTime from "../modules/DateTime";
import { Util } from "../modules/Util";
import ChecklistRating, { IChecklistRating } from "../types/ChecklistRating";
import BaseModel from "./BaseModel";

export default class RatingModel extends BaseModel {
    public RatingValue: number;
    public Note: string;
    public LessonId: number;
    public ChecklistItemId: number;
    public IsCurrentRating: boolean;
    public IsUpdateRating: boolean;
    public LessonDate: Date;
    public OldNote: string;
    public OldRatingValue: number;

    public ChecklistItemLabel: string = "";

    constructor(rating: IChecklistRating = new ChecklistRating()) {
        super(rating);
        this.RatingValue = rating.RatingValue;
        this.Note = rating.Note;
        this.LessonId = rating.LessonId;
        this.ChecklistItemId = rating.ChecklistItemId;
        this.IsCurrentRating = rating.IsCurrentRating || false;
        this.IsUpdateRating = rating.IsUpdateRating || false;
        this.OldNote = rating.OldNote;
        this.OldRatingValue = rating.OldRatingValue;
        super.init(this);
    }

    public getRatingColor() {
        return Util.getRatingColor(this.RatingValue);
    }

    public currentDate() {
        return DateTime.parseStringDate(new Date());
    }

    public lessonDate(): string {
        return this.LessonDate ? DateTime.parseStringDate(this.LessonDate) : "";
    }

    public get RatingDotStyle(): string {
        return this.RatingValue ? `background: ${this.getRatingColor()}` : "background: none; border: 1px solid #959595";
    }

    public toEntity(rating: IChecklistRating = new ChecklistRating()): IChecklistRating {
        super.toEntity(rating);
        rating.RatingValue = this.RatingValue;
        rating.Note = this.Note;
        rating.LessonId = this.LessonId;
        rating.ChecklistItemId = this.ChecklistItemId;
        rating.IsCurrentRating = this.IsCurrentRating;

        rating.IsUpdateRating = this.IsUpdateRating;
        rating.OldNote = this.OldNote;
        rating.OldRatingValue = this.OldRatingValue;
        return rating;
    }
}
