import { logger } from "../../modules/Logger";
import { network } from "../../modules/Network";
import { Util } from "../../modules/Util";
import { IDal } from "../IDal";
import Migration from "../Migration";
import Transaction from "../Wasm/Transaction";

export default class SqliteDal implements IDal {
    private dbName: string;
    private db: SQLitePlugin.Database;

    async load(dbName: string): Promise<void> {
        this.dbName = dbName;
        logger.logInfo(`loading: ${dbName}`);
        this.db = await this.openSQLiteDb(dbName);
        const migrator = new Migration(this);
        await migrator.migrate();
        window.dal = this;
    }

    async firstOrDefault<T>(sql: string, params?: any[]): Promise<T> {
        return Util.firstOrDefault<T>(await this.executeRead(sql, params));
    }

    execute(sql: string, params?: any[]): Promise<any[]> {
        if (sql.toUpperCase().startsWith("SELECT")) {
            logger.logWarning("Use executeRead for SELECT statements to improve performance");
        }
        return new Promise((res, rej) => {
            let resultData: SQLitePlugin.Results;
            this.db.transaction(
                tx => {
                    tx.executeSql(sql, params, (_tx: SQLitePlugin.Transaction | Transaction, result: SQLitePlugin.Results) => {
                        resultData = result; // Save result (to be returned when transaction completes)
                    });
                },
                error => {
                    logger.logError(error);
                    rej(error);
                },
                () => res(this.createArray(resultData))
            );
        });
    }

    withTransaction(transactionCallback: (transaction: SQLitePlugin.Transaction | Transaction) => void): Promise<void> {
        return new Promise((res, rej) => {
            this.db.transaction(transactionCallback, rej, res);
        });
    }

    async executeRead(sql: string, params?: any[]): Promise<any[]> {
        if (!this.dbName) {
            await this.load(`OD_${network.getCompanyId()}_${network.getUserId()}`);
        }
        if (!sql.toUpperCase().startsWith("SELECT")) {
            logger.logWarning("executeRead statements should start with the SELECT keyword", false);
        }
        return new Promise((res, rej) => {
            let resultData: SQLitePlugin.Results;
            this.db.readTransaction(
                tx => {
                    tx.executeSql(sql, params, (_tx: SQLitePlugin.Transaction | Transaction, result: SQLitePlugin.Results) => {
                        resultData = result; // Save result (to be returned when transaction completes)
                    });
                },
                error => {
                    logger.logError(error);
                    rej(error);
                },
                () => res(this.createArray(resultData))
            );
        });
    }

    isReady(): boolean {
        return !!this.dbName;
    }

    async closeDb(): Promise<void> {
        if ((this.db as SQLitePlugin.Database).close) {
            await new Promise<void | Error>(res => {
                (this.db as SQLitePlugin.Database).close(res, res);
            });
        }
        this.db = null;
    }

    getDbName(): string {
        return this.dbName;
    }

    getVersion(): number {
        throw new Error("Method not implemented.");
    }

    private async openSQLiteDb(dbName: string): Promise<SQLitePlugin.Database> {
        return new Promise((res, rej) => {
            window.sqlitePlugin.openDatabase(
                { name: dbName, location: "default" /* no itunes visibility no icloud sync */ },
                db => {
                    res(db);
                },
                rej
            );
        });
    }

    private createArray(dbSet: SQLitePlugin.Results): any[] {
        const resultSet = [];
        for (let i = 0; i < dbSet.rows.length; i++) {
            resultSet.push(dbSet.rows.item(i));
        }
        return resultSet;
    }
}
