import { keys } from "ts-transformer-keys";
import DrivingTestModel from "../models/DrivingTestModel";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import DrivingTest, { IDrivingTest, IDrivingTestDto } from "../types/DrivingTest";
import { IEducation } from "../types/Education";
import { BaseService } from "./BaseService";
import { checklistCollectionService } from "./ChecklistCollectionService";
import { educationService } from "./EducationService";

export default class DrivingTestService extends BaseService<IDrivingTest, IDrivingTestDto> {
    private readonly TABLE_NAME = "DrivingTests";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IDrivingTestDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IDrivingTest>());
    }

    public getApiRoute(): string {
        return `${network.API}/${DrivingTest.EntityTypeId}`;
    }

    protected getNewItems(): Promise<IDrivingTest[]> {
        return this.getItems("IsNew = ?", null, null, null, null, [true]);
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        params.push(true);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE (IsNew = ? OR IsDirty = ?)`;
    }

    protected createEntityFromDb(item: IDrivingTest): DrivingTest {
        const currencyEntity = new DrivingTest();
        currencyEntity.populateFromDb(item);
        return currencyEntity;
    }

    public createEntityFromOData(item: IDrivingTestDto): DrivingTest {
        const currencyEntity = new DrivingTest();
        currencyEntity.populateFromOData(item);
        return currencyEntity;
    }

    public async getDrivingTestModel(educationId: number, personId: number) {
        const education = await educationService.getSelectedEducation(educationId, personId);
        const checklistCollection = await checklistCollectionService.getItemById(education.ChecklistCollectionId);
        const drivingTest = await this.getDrivingTest(educationId);
        const drivingTestModel = drivingTest ? new DrivingTestModel(drivingTest) : new DrivingTestModel();
        drivingTestModel.HasVku = checklistCollection?.HasVku;
        return drivingTestModel;
    }

    public getDrivingTest(educationId: number): Promise<IDrivingTest> {
        return this.getItemById(educationId);
    }

    public getDrivingTests(educations: IEducation[]): Promise<IDrivingTest[]> {
        return this.getItems(`Id IN ${Util.joinIds(educations.map(x => x.Id))}`);
    }
}

export const drivingTestService = new DrivingTestService();
