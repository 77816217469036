import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

export interface IOrphyDriveJobPositionBase {
    JobPositionId: number;
    TimeTrackingId: number;
    LessonCount: number;
}

export interface IOrphyDriveJobPositionDto extends IBaseDto, IOrphyDriveJobPositionBase {}

export interface IOrphyDriveJobPosition extends IBaseEntity, IOrphyDriveJobPositionBase {}

export class OrphyDriveJobPosition extends BaseEntity<IOrphyDriveJobPosition, IOrphyDriveJobPositionDto> implements IOrphyDriveJobPosition {
    public JobPositionId: number;
    public TimeTrackingId: number;
    public LessonCount: number;

    public static get EntityTypeId(): string {
        return "80102B75-90ED-406A-B275-3A4963D57B2C";
    }

    public populateFromDb(dbItem: IOrphyDriveJobPosition): void {
        super.populateFromDb(dbItem);
        this.JobPositionId = dbItem.JobPositionId;
        this.TimeTrackingId = dbItem.TimeTrackingId;
        this.LessonCount = dbItem.LessonCount;
    }

    public populateFromOData(oDataItem: IOrphyDriveJobPositionDto): void {
        super.populateFromOData(oDataItem);
        this.JobPositionId = oDataItem.JobPositionId;
        this.TimeTrackingId = oDataItem.TimeTrackingId;
        this.LessonCount = oDataItem.LessonCount;
    }

    public toODataObject(): IOrphyDriveJobPositionDto {
        const oDataObject = super.toODataObject();
        oDataObject.JobPositionId = this.JobPositionId;
        oDataObject.TimeTrackingId = this.TimeTrackingId;
        oDataObject.LessonCount = this.LessonCount;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<OrphyDriveJobPosition>(x => x.JobPositionId),
            this.JobPositionId
        );
        dbObject.set(
            nameof<OrphyDriveJobPosition>(x => x.TimeTrackingId),
            this.TimeTrackingId
        );
        dbObject.set(
            nameof<OrphyDriveJobPosition>(x => x.LessonCount),
            this.LessonCount
        );
        return dbObject;
    }
}
