import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseCompanySettings {
    CompanyName: string;
}

export interface ICompanySettingsDto extends IBaseDto, IBaseCompanySettings {}
export interface ICompanySettings extends IBaseEntity, IBaseCompanySettings {}

export default class CompanySettings extends BaseEntity<ICompanySettings, ICompanySettingsDto> implements ICompanySettings {
    public static get EntityTypeId(): string {
        return "925ac842-3b00-58d3-b8d4-4eefa6fca489";
    }

    public CompanyName: string;

    public populateFromDb(dbItem: ICompanySettings): void {
        super.populateFromDb(dbItem);
        this.CompanyName = dbItem.CompanyName;
    }

    public populateFromOData(oDataItem: ICompanySettingsDto): void {
        super.populateFromOData(oDataItem);
        this.CompanyName = oDataItem.CompanyName;
    }

    public toODataObject(): ICompanySettingsDto {
        const oDataObject = super.toODataObject();
        oDataObject.CompanyName = this.CompanyName;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<CompanySettings>(x => x.Id),
            1
        ); // Override Id with 1 (only one entity)
        dbObject.set(
            nameof<CompanySettings>(x => x.CompanyName),
            this.CompanyName
        );
        return dbObject;
    }
}
