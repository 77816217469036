/* eslint-disable no-console */

export default class ObservableObject extends kendo.data.ObservableObject {
    constructor() {
        super();
        super.init(this);
    }

    protected getComputedFields(map: Map<string, string>): Map<string, string> {
        return map;
    }

    public get<T>(name: string): T {
        return super.get(name) as T;
    }

    public set(name: string, value: any): void {
        super.set(name, value);
        console.log(`trigger change ${name}, ${value}`);
        this.triggerChangeOnComputedFields(name);
    }

    protected triggerChangeOnComputedFields(propertyName: string) {
        const fields = this.getComputedFields(new Map<string, string>());
        for (const [fieldName, field] of Array.from(fields.entries())) {
            if (fieldName === propertyName) {
                console.log(`trigger change ${fieldName}, ${field}`);
                this.trigger("change", { field: field });
            }
        }
    }
}
