import { IJobPosition, IJobPositionDto, JobPosition } from "./JobPosition";

export interface IBaseJobSeparatorPosition {}

export interface IJobSeparatorPositionDto extends IBaseJobSeparatorPosition, IJobPositionDto {}
export interface IJobSeparatorPosition extends IBaseJobSeparatorPosition, IJobPosition {}

export default class JobSeparatorPosition extends JobPosition<IJobSeparatorPosition, IJobSeparatorPositionDto> implements IJobSeparatorPosition {
    public static TYPE_NAME = "JobSeparatorPosition";
    public static ODATA_TYPE_NAME = "#SharedDto.PublicApi.JobSeparatorPositionDtoV2";

    public odataTypeName(): string {
        return JobSeparatorPosition.ODATA_TYPE_NAME;
    }

    public get Discriminator(): string {
        return JobSeparatorPosition.TYPE_NAME;
    }

    public createChargingPosition(parentId: number): JobSeparatorPosition {
        const chargingPosition = new JobSeparatorPosition();
        chargingPosition.copyFrom(this); // Copy common data
        chargingPosition.SortOrder = 0;
        chargingPosition.ParentId = parentId;
        return chargingPosition;
    }
}
