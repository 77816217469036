import AllPersonItemModel from "./AllPersonItemModel";

export default class AllPersonModel extends kendo.data.ObservableObject {
    public TotalCount: number;
    public AllPersons: AllPersonItemModel[];

    constructor(allPersons: AllPersonItemModel[], totalCount: number) {
        super();
        this.TotalCount = totalCount;
        this.AllPersons = allPersons;
        super.init(this);
    }
}
