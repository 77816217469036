import { translation } from "../services/TranslationService";
import JobSubTotalPosition, { IJobSubTotalPosition, IJobSubTotalPositionDto } from "./JobSubTotalPosition";

export interface IBaseJobTotalPosition {}

export interface IJobTotalPositionDto extends IBaseJobTotalPosition, IJobSubTotalPositionDto {}
export interface IJobTotalPosition extends IBaseJobTotalPosition, IJobSubTotalPosition {}

export default class JobTotalPosition extends JobSubTotalPosition<IJobTotalPosition, IJobTotalPositionDto> implements IJobSubTotalPosition {
    constructor() {
        super();
        this.SortOrder = 0;
        this.Position = translation.t("common.total");
    }

    public static TYPE_NAME = "JobTotalPosition";
    public static ODATA_TYPE_NAME = "#SharedDto.PublicApi.JobTotalPositionDtoV2";

    public odataTypeName(): string {
        return JobTotalPosition.ODATA_TYPE_NAME;
    }

    public get Discriminator(): string {
        return JobTotalPosition.TYPE_NAME;
    }

    public createChargingPosition(parentId: number, amount: number = null): JobTotalPosition {
        const chargingPosition = new JobTotalPosition();
        chargingPosition.copyFrom(this); // Copy common data
        chargingPosition.Amount = amount !== null ? amount : this.Amount;
        chargingPosition.SortOrder = 0;
        chargingPosition.ParentId = parentId;
        chargingPosition.ChargedPositionId = this.Id;
        return chargingPosition;
    }
}
