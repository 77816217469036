import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

export enum Gender {
    Unspecified,
    Male,
    Female
}

interface IBasePerson {
    Sex: Gender;
    SalutationId: number;
    FirstName: string;
    LastName: string;
    Birthday: Date | string;
    Avatar: string;
    Profession: string;
    IsInactive: boolean;
}

export interface IPersonDto extends IBaseDto, IBasePerson {
    Blobs: string;
}

export interface IPerson extends IBaseEntity, IBasePerson {}

export default class Person extends BaseEntity<IPerson, IPersonDto> implements IPerson {
    public static get EntityTypeId(): string {
        return "546688f6-6fd6-5891-8813-4e0e91f9abe2";
    }

    public Sex: Gender;
    public SalutationId: number;
    public FirstName: string;
    public LastName: string;
    public Birthday: Date | string;
    public Avatar: string;
    public Profession: string;
    public IsInactive: boolean;

    public populateFromDb(dbItem: IPerson): void {
        super.populateFromDb(dbItem);
        this.Sex = dbItem.Sex;
        this.SalutationId = dbItem.SalutationId;
        this.FirstName = dbItem.FirstName;
        this.LastName = dbItem.LastName;
        this.Birthday = DateTime.fromDbDate(dbItem.Birthday as string);
        this.Avatar = dbItem.Avatar;
        this.Profession = dbItem.Profession;
        this.IsInactive = this.getDbBoolean(dbItem.IsInactive);
    }

    public populateFromOData(oDataItem: IPersonDto): void {
        super.populateFromOData(oDataItem);
        this.Sex = oDataItem.Sex;
        this.SalutationId = oDataItem.SalutationId;
        this.FirstName = oDataItem.FirstName;
        this.LastName = oDataItem.LastName;
        this.Birthday = DateTime.fromODataDate(oDataItem.Birthday as string);
        this.Avatar = oDataItem.Avatar;
        this.Profession = oDataItem.Profession;
        this.IsInactive = oDataItem.IsInactive;
    }

    public toODataObject(): IPersonDto {
        const oDataObject = super.toODataObject();
        oDataObject.Sex = this.Sex;
        oDataObject.SalutationId = this.SalutationId;
        oDataObject.FirstName = this.FirstName;
        oDataObject.LastName = this.LastName;
        oDataObject.Birthday = DateTime.toDbDate(this.Birthday as Date);
        oDataObject.Avatar = this.Avatar;
        oDataObject.Profession = this.Profession;
        oDataObject.IsInactive = this.IsInactive;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Person>(x => x.Sex),
            this.Sex
        );
        dbObject.set(
            nameof<Person>(x => x.SalutationId),
            this.SalutationId
        );
        dbObject.set(
            nameof<Person>(x => x.FirstName),
            this.FirstName
        );
        dbObject.set(
            nameof<Person>(x => x.LastName),
            this.LastName
        );
        dbObject.set(
            nameof<Person>(x => x.Birthday),
            DateTime.toDbDate(this.Birthday as Date)
        );
        dbObject.set(
            nameof<Person>(x => x.Avatar),
            this.Avatar
        );
        dbObject.set(
            nameof<Person>(x => x.Profession),
            this.Profession
        );
        dbObject.set(
            nameof<Person>(x => x.IsInactive),
            this.IsInactive
        );

        return dbObject;
    }
}
