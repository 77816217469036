import { Util } from "../modules/Util";

export default class ProductChooserModel extends kendo.data.ObservableObject {
    private _name: string;
    private _amount: number;
    private _price: number;
    private _selected: boolean;
    private _id: number;

    constructor(id: number, name: string, amount: number, price: number) {
        super();
        this._id = id;
        this._name = name;
        this._amount = amount;
        this._price = price;
        this._selected = false;
        super.init(this);
    }

    public get Id(): any {
        return this._id;
    }

    public set Id(value: any) {
        this._id = value;
    }

    public get Name(): string {
        return this._name;
    }

    public set Name(value: string) {
        this._name = value;
    }

    public get Amount(): number {
        return this._amount;
    }

    public set Amount(value: number) {
        this._amount = value;
    }

    public get Price(): number {
        return this._price;
    }

    public set Price(value: number) {
        this._price = value;
    }

    public get FormattedPrice(): string {
        return Util.formatPrice(this.Price);
    }

    public get Selected(): boolean {
        return this._selected;
    }

    public set Selected(value: boolean) {
        this._selected = value;
    }
}
