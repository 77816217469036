import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseEntity, IBaseDto } from "./BaseEntity";

interface IBaseChecklistItem {
    Label: string;
    Overview: string;
    ParentId: number;
    PreviousSiblingId: number;
    NextSiblingId: number;
    ChecklistCollectionId: number;
}

export interface IChecklistItemDto extends IBaseDto, IBaseChecklistItem {}

export interface IChecklistItem extends IBaseEntity, IBaseChecklistItem {
    ContentDirty: boolean;
    HasContent: boolean;
}

export default class ChecklistItem extends BaseEntity<IChecklistItem, IChecklistItemDto> implements IChecklistItem {
    public static get EntityTypeId(): string {
        return "2ddf8cfa-4c8b-598a-bdac-3cfbe45897ec";
    }

    public Label: string;
    public Overview: string;
    public ParentId: number;
    public PreviousSiblingId: number;
    public NextSiblingId: number;
    public ChecklistCollectionId: number;
    public ContentDirty: boolean;
    public HasContent: boolean;

    public populateFromDb(dbItem: IChecklistItem): void {
        super.populateFromDb(dbItem);
        this.Label = dbItem.Label;
        this.Overview = dbItem.Overview;
        this.ParentId = dbItem.ParentId;
        this.PreviousSiblingId = dbItem.PreviousSiblingId;
        this.NextSiblingId = dbItem.NextSiblingId;
        this.ChecklistCollectionId = dbItem.ChecklistCollectionId;
        this.ContentDirty = this.getDbBoolean(dbItem.ContentDirty);
        this.HasContent = this.getDbBoolean(dbItem.HasContent);
    }

    public populateFromOData(oDataItem: IChecklistItemDto): void {
        super.populateFromOData(oDataItem);
        this.Label = oDataItem.Label;
        this.Overview = oDataItem.Overview;
        this.ParentId = oDataItem.ParentId;
        this.PreviousSiblingId = oDataItem.PreviousSiblingId;
        this.NextSiblingId = oDataItem.NextSiblingId;
        this.ChecklistCollectionId = oDataItem.ChecklistCollectionId;
    }

    public toODataObject(): IChecklistItemDto {
        const oDataObject = super.toODataObject();
        oDataObject.Label = this.Label;
        if (this.ContentDirty) {
            oDataObject.Overview = this.Overview;
        }
        oDataObject.ParentId = this.ParentId;
        oDataObject.PreviousSiblingId = this.PreviousSiblingId;
        oDataObject.NextSiblingId = this.NextSiblingId;
        oDataObject.ChecklistCollectionId = this.ChecklistCollectionId;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<ChecklistItem>(x => x.Label),
            this.Label
        );
        dbObject.set(
            nameof<ChecklistItem>(x => x.Overview),
            this.Overview
        );
        dbObject.set(
            nameof<ChecklistItem>(x => x.ParentId),
            this.ParentId
        );
        dbObject.set(
            nameof<ChecklistItem>(x => x.PreviousSiblingId),
            this.PreviousSiblingId
        );
        dbObject.set(
            nameof<ChecklistItem>(x => x.NextSiblingId),
            this.NextSiblingId
        );
        dbObject.set(
            nameof<ChecklistItem>(x => x.ChecklistCollectionId),
            this.ChecklistCollectionId
        );
        dbObject.set(
            nameof<ChecklistItem>(x => x.ContentDirty),
            this.ContentDirty
        );
        return dbObject;
    }
}
