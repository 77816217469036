import { IJobPricePosition, IJobPricePositionDto, JobPricePosition } from "./JobPricePosition";

export interface IBaseJobSubTotalPosition {}

export interface IJobSubTotalPositionDto extends IBaseJobSubTotalPosition, IJobPricePositionDto {}
export interface IJobSubTotalPosition extends IBaseJobSubTotalPosition, IJobPricePosition {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class JobSubTotalPosition<TEntity extends IJobSubTotalPosition, TDto extends IJobSubTotalPositionDto>
    extends JobPricePosition<IJobSubTotalPosition, IJobSubTotalPositionDto>
    implements IJobSubTotalPosition
{
    public static TYPE_NAME = "JobSubTotalPosition";
    public static ODATA_TYPE_NAME = "#SharedDto.PublicApi.JobSubTotalPositionDtoV2";

    public odataTypeName(): string {
        return JobSubTotalPosition.ODATA_TYPE_NAME;
    }

    public get Discriminator(): string {
        return JobSubTotalPosition.TYPE_NAME;
    }

    public calculateTotalPrice(): number {
        if (this.Children && this.Children.length > 0) {
            let totalPrice = 0;
            for (const child of this.Children) {
                if (child instanceof JobPricePosition) {
                    totalPrice += child.calculateTotalPrice();
                }
            }
            return this.applyDiscount(totalPrice);
        } else {
            return 0;
        }
    }

    public createChargingPosition(parentId: number, amount: number = null): JobSubTotalPosition<IJobSubTotalPosition, IJobSubTotalPositionDto> {
        const chargingPosition = new JobSubTotalPosition();
        chargingPosition.copyFrom(this); // Copy common data
        chargingPosition.Amount = amount !== null ? amount : this.Amount;
        chargingPosition.SortOrder = 0;
        chargingPosition.ParentId = parentId;
        chargingPosition.ChargedPositionId = this.Id;
        return chargingPosition;
    }
}
