import { logger } from "../modules/Logger";
import { Util } from "../modules/Util";
import { IDal } from "./IDal";
import SqliteDal from "./Sqlite/SqliteDal";
import Transaction from "./Wasm/Transaction";
import WasmDal from "./Wasm/WasmDal";

export default class Dal {
    private db: IDal;

    constructor() {
        if (Util.isBrowser()) {
            this.db = new WasmDal();
        } else {
            this.db = new SqliteDal();
        }
    }

    async firstOrDefault<T>(sql: string, params?: any[]): Promise<T> {
        logger.logInfo(sql);
        if (params) {
            logger.logInfo(JSON.stringify(params));
        }
        return this.db.firstOrDefault(sql, params);
    }

    execute(sql: string, params?: any[]): Promise<any[]> {
        logger.logInfo(sql);
        if (params) {
            logger.logInfo(JSON.stringify(params));
        }
        return this.db.execute(sql, params);
    }

    withTransaction(transactionCallback: (transaction: SQLitePlugin.Transaction | Transaction) => void): Promise<void> {
        return this.db.withTransaction(transactionCallback);
    }

    executeRead(sql: string, params?: any[]): Promise<any[]> {
        logger.logInfo(sql);
        if (params) {
            logger.logInfo(JSON.stringify(params));
        }
        return this.db.executeRead(sql, params);
    }

    isReady(): boolean {
        return !!this.db.getDbName();
    }

    async closeDb(): Promise<void> {
        await this.db.closeDb();
    }

    getDbName(): string {
        return this.db.getDbName();
    }
}

export const dal = new Dal();
