import PersonAddress, { IPersonAddress } from "../types/PersonAddress";
import BaseModel from "./BaseModel";

export default class PersonAddressModel extends BaseModel {
    public PersonId: number;
    public Street: string;
    public StreetNumber: string;
    public ZipCode: string;
    public City: string;
    public State: string;
    public Country: string;
    public AddressZusatz: string;
    public AddressTypeId: number;
    public IsPrefered: boolean;

    constructor(personAddress: IPersonAddress = new PersonAddress()) {
        super(personAddress);
        this.PersonId = personAddress.PersonId;
        this.Street = personAddress.Street;
        this.StreetNumber = personAddress.StreetNumber;
        this.ZipCode = personAddress.ZipCode;
        this.City = personAddress.City;
        this.State = personAddress.State;
        this.Country = personAddress.Country;
        this.AddressZusatz = personAddress.AddressZusatz;
        this.AddressTypeId = personAddress.AddressTypeId;
        this.IsPrefered = personAddress.IsPrefered;
        super.init(this);
    }

    public toEntity(personAddress: IPersonAddress = new PersonAddress()): IPersonAddress {
        super.toEntity(personAddress);
        personAddress.PersonId = this.PersonId;
        personAddress.Street = this.Street;
        personAddress.StreetNumber = this.StreetNumber;
        personAddress.ZipCode = this.ZipCode;
        personAddress.City = this.City;
        personAddress.State = this.State;
        personAddress.Country = this.Country;
        personAddress.AddressZusatz = this.AddressZusatz;
        personAddress.AddressTypeId = this.AddressTypeId;
        personAddress.IsPrefered = this.IsPrefered;
        return personAddress;
    }
}
