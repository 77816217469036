import { network } from "../modules/Network";
import { billService } from "../services/BillService";
import { personService } from "../services/PersonService";
import { timeTrackingService } from "../services/TimeTrackingService";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { BaseView } from "./BaseView";

export default class PaperWorkDashboard extends BaseView {
    public personName: string = "";
    public backButtonVisible: boolean;

    private readonly NAV_POINT = "views/personDetail.html?resetTab=true";

    public async loadData(): Promise<void> {
        const settings = await userAppSettingsService.getSettings();
        const whereTimetTracking = settings.ShowAllUserData ? null : `MitarbeiterId = ${network.getPersonId()}`;
        const billWhere = settings.ShowAllUserData ? null : `ContactPersonId = ${network.getUserId()}`;

        this.set("timeTrackings", await timeTrackingService.getTimeTrackingForPaperworkDashboard(whereTimetTracking));
        this.set("newestBills", await billService.getNewestBills(3, billWhere));
        this.set("overduebills", await billService.getOverdueBills(3, billWhere));

        if (this.personId) {
            const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
            if (person) {
                this.set("personName", `${person.FirstName} ${person.LastName}`);
                this.set("backButtonVisible", true);
            } else {
                this.set("backButtonVisible", false);
            }
        }
    }

    public showView(): void {
        this.set("backButtonVisible", !!this.personId);
    }

    public getNavPoint(): string {
        return this.NAV_POINT;
    }

    public back(e) {
        if (this.personId) {
            app.mobileApp.navigate(this.getNavPoint());
        } else {
            e.preventDefault();
        }
    }
}
