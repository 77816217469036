import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import BaseEntity, { IBaseEntity, IBaseDto } from "./BaseEntity";

interface ILfaBase {
    LfaNumber: string;
    LfaExpireDate: Date | string;
    Pid: string;
    Pin: string;
    FaberNumber: string;
}

export interface ILfaDto extends IBaseDto, ILfaBase {}

export interface ILfa extends IBaseEntity, ILfaBase {}

export default class Lfa extends BaseEntity<ILfa, ILfaDto> implements ILfa {
    public static get EntityTypeId(): string {
        return "ECEA81DE-EB87-4ABB-9CBC-EBE4147F4068";
    }

    public LfaNumber: string;
    public LfaExpireDate: Date;
    public Pid: string;
    public Pin: string;
    public FaberNumber: string;

    public populateFromDb(dbItem: ILfa): void {
        super.populateFromDb(dbItem);
        this.LfaNumber = dbItem.LfaNumber;
        this.LfaExpireDate = DateTime.fromDbDate(dbItem.LfaExpireDate as string);
        this.Pid = dbItem.Pid;
        this.Pin = dbItem.Pin;
        this.FaberNumber = dbItem.FaberNumber;
    }

    public populateFromOData(oDataItem: ILfaDto): void {
        super.populateFromOData(oDataItem);
        this.LfaNumber = oDataItem.LfaNumber;
        this.LfaExpireDate = DateTime.fromODataDate(oDataItem.LfaExpireDate as string);
        this.Pid = oDataItem.Pid;
        this.Pin = oDataItem.Pin;
        this.FaberNumber = oDataItem.FaberNumber;
    }

    public toODataObject(): ILfaDto {
        const oDataObject = super.toODataObject();
        oDataObject.Id = this.Id;
        oDataObject.LfaNumber = this.LfaNumber;
        oDataObject.LfaExpireDate = DateTime.toODataDate(this.LfaExpireDate);
        oDataObject.Pid = this.Pid;
        oDataObject.Pin = this.Pin;
        oDataObject.FaberNumber = this.FaberNumber;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Lfa>(x => x.LfaNumber),
            this.LfaNumber
        );
        dbObject.set(
            nameof<Lfa>(x => x.LfaExpireDate),
            DateTime.toDbDate(this.LfaExpireDate)
        );
        dbObject.set(
            nameof<Lfa>(x => x.Pid),
            this.Pid
        );
        dbObject.set(
            nameof<Lfa>(x => x.Pin),
            this.Pin
        );
        dbObject.set(
            nameof<Lfa>(x => x.FaberNumber),
            this.FaberNumber
        );
        return dbObject;
    }
}
