import i18next, { TFunction, i18n } from "i18next";
import Backend from "i18next-http-backend";
import locI18next from "loc-i18next";
import LanguageModel from "../models/LanguageModel";
import { userSettingsService } from "./UserSettingsService";
import "intl-pluralrules";
import { dal } from "../dal/Dal";

export default class TranslationService {
    private i18next: i18n;

    public t: TFunction<["translation", ...string[]], undefined>;
    public localize: any;

    public static readonly fallbackLng: string = "de";
    private languageModels = [
        { DisplayName: "Deutsch", Code: "de" },
        { DisplayName: "English", Code: "en" },
        { DisplayName: "Français", Code: "fr" },
        { DisplayName: "Italiano", Code: "it" }
    ];

    public reloadLanguage = async () => {
        const userSettings = dal.isReady() ? await userSettingsService.getSettings() : null;
        const language = userSettings ? userSettings.Language : navigator.language;
        let lng = this.languageModels.find(x => x.Code.includes(language.substring(0, 2)));
        if (!lng) {
            lng = this.languageModels.find(x => x.Code.includes(TranslationService.fallbackLng.substring(0, 2)));
        }
        await this.i18next.changeLanguage(lng.Code);
    };

    public getSelectedLanguage = (): string => this.i18next.language;

    public getLanguageModels = (): LanguageModel[] => this.languageModels;

    public init = () => {
        void i18next.use(Backend).init(
            {
                lng: navigator.language.substring(0, 2),
                fallbackLng: TranslationService.fallbackLng,
                debug: true,
                backend: {
                    loadPath: "./bundles/resources/{{lng}}/{{ns}}.json"
                }
            },
            error => {
                if (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                this.localize = locI18next.init(i18next, {
                    optionsAttr: "data-i18n-options",
                    useOptionsAttr: true
                });
                this.i18next = i18next;
                this.t = this.i18next.t;
            }
        );
    };
}

export const translation = new TranslationService();
translation.init();
