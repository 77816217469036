import { currentLessonService } from "../services/CurrentLessonService";
import DropdownItem from "./DropdownItem";
import PlannedChecklistItemModel from "../models/PlannedChecklistItemModel";

export default class ActiveLesson {
    private _lessons: PlannedChecklistItemModel[];
    private _fullChecklistRating: any;
    private _personId: number;
    private _activeLesson: number;
    private navPoint: string;
    private updateLesson: boolean;

    constructor(lessons: PlannedChecklistItemModel[], navPoint: string, personId: number, updateLesson: boolean = false) {
        this._personId = personId;
        this.updateLesson = updateLesson;
        if (lessons) {
            this._lessons = lessons;
            this._activeLesson = 0;
            this.navPoint = navPoint;
        } else {
            this._lessons = [];
        }
        this._fullChecklistRating = {};
    }

    get PersonId(): number {
        return this._personId;
    }

    public navigate = () => {
        app.mobileApp.navigate(`views/executeLesson.html?navPoint=${this.navPoint}`);
    };

    public getFullChecklistRating = (): any => {
        // add a new flag for new Rating 0-5
        const ret = { NR: true };
        for (const key of Object.keys(this._fullChecklistRating)) {
            if (this._fullChecklistRating[key].N || this._fullChecklistRating[key].R) {
                ret[key] = this._fullChecklistRating[key];
            }
        }
        return ret;
    };

    public setRatingValue = async (chapterId: number, rating: number): Promise<void> => {
        if (!this.updateLesson) {
            await currentLessonService.updateRatingValue(this._personId, chapterId, rating);
        }
        if (this._fullChecklistRating[chapterId]) {
            this._fullChecklistRating[chapterId].R = rating;
        } else {
            this._fullChecklistRating[chapterId] = { R: rating };
        }
    };

    public deleteRatingValue = async (chapterId: number): Promise<void> => {
        if (!this.updateLesson) {
            await currentLessonService.deleteRatingValue(this._personId, chapterId);
        }
        if (this._fullChecklistRating[chapterId] && this._fullChecklistRating[chapterId].R) {
            delete this._fullChecklistRating[chapterId].R;
        }
    };

    public setNote = (chapterId: number, note: string): void => {
        if (this._fullChecklistRating[chapterId]) {
            this._fullChecklistRating[chapterId].N = note;
        } else {
            this._fullChecklistRating[chapterId] = { N: note };
        }
    };

    public getNote = (chapterId: number): string => {
        if (this.hasActiveNote(chapterId)) {
            return this._fullChecklistRating[chapterId].N;
        }
        return null;
    };

    public getRating = (chapterId: number): number => {
        if (this._fullChecklistRating[chapterId] && this._fullChecklistRating[chapterId].R) {
            return this._fullChecklistRating[chapterId].R;
        }
        return null;
    };

    public deleteNote(chapterId): void {
        delete this._fullChecklistRating[chapterId].N;
    }

    public getActiveChapterTitle(): string {
        return this._lessons[this._activeLesson].Label;
    }

    public getChapterContent(): string {
        return this._lessons[this._activeLesson].Overview;
    }

    public getLessonsLength(): number {
        return this._lessons.length;
    }

    public getChapterId(): number {
        return this._lessons[this._activeLesson].Id;
    }

    public getActiveLesson(): number {
        return this._activeLesson;
    }

    public setActiveLesson(index: number): void {
        this._activeLesson = index;
    }

    public getLessons(): PlannedChecklistItemModel[] {
        return this._lessons;
    }

    public getDropdownList = (): DropdownItem[] => {
        const ddl: DropdownItem[] = [];
        for (let i = 0; i < this._lessons.length; i++) {
            if (i === 0) {
                ddl.push(new DropdownItem(i, this._lessons[i].Label, true));
            } else {
                ddl.push(new DropdownItem(i, this._lessons[i].Label));
            }
        }
        return ddl;
    };

    public hasRating = (): boolean => {
        for (const key in this._fullChecklistRating) {
            if (this._fullChecklistRating.hasOwnProperty(key)) {
                if (this._fullChecklistRating[key].R) {
                    return true;
                }
            }
        }
        return false;
    };

    public hasActiveNote(chapterId: number) {
        return !!(this._fullChecklistRating[chapterId] && this._fullChecklistRating[chapterId].N);
    }

    public getSelectedLessonIds(): number[] {
        return this._lessons.map(x => x.Id);
    }
}

export const activeLesson: ActiveLesson[] = [];
