import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import BaseEntity, { IBaseEntity, IBaseDto } from "./BaseEntity";

interface IBaseDrivingTest {
    Vku1: boolean;
    Vku2: boolean;
    Vku3: boolean;
    Vku4: boolean;
    VkuDoneDate: string | Date;
}

export interface IDrivingTestDto extends IBaseDto, IBaseDrivingTest {}

export interface IDrivingTest extends IBaseEntity, IBaseDrivingTest {}

export default class DrivingTest extends BaseEntity<IDrivingTest, IDrivingTestDto> implements IDrivingTest {
    public static get EntityTypeId(): string {
        return "6BBE9305-8DC4-48D4-9274-35E304338DB8";
    }

    public Vku1: boolean;
    public Vku2: boolean;
    public Vku3: boolean;
    public Vku4: boolean;
    public VkuDoneDate: string | Date;

    public populateFromDb(dbItem: IDrivingTest): void {
        super.populateFromDb(dbItem);
        this.Vku1 = this.getDbBoolean(dbItem.Vku1);
        this.Vku2 = this.getDbBoolean(dbItem.Vku2);
        this.Vku3 = this.getDbBoolean(dbItem.Vku3);
        this.Vku4 = this.getDbBoolean(dbItem.Vku4);
        this.VkuDoneDate = DateTime.fromDbDate(dbItem.VkuDoneDate as string);
    }

    public populateFromOData(oDataItem: IDrivingTestDto): void {
        super.populateFromOData(oDataItem);
        this.Vku1 = oDataItem.Vku1;
        this.Vku2 = oDataItem.Vku2;
        this.Vku3 = oDataItem.Vku3;
        this.Vku4 = oDataItem.Vku4;
        this.VkuDoneDate = DateTime.fromODataDate(oDataItem.VkuDoneDate as string);
    }

    public toODataObject(): IDrivingTestDto {
        const oDataObject = super.toODataObject();
        oDataObject.Id = this.Id;
        oDataObject.Vku1 = this.Vku1;
        oDataObject.Vku2 = this.Vku2;
        oDataObject.Vku3 = this.Vku3;
        oDataObject.Vku4 = this.Vku4;
        oDataObject.VkuDoneDate = DateTime.toODataDate(this.VkuDoneDate as Date);
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<DrivingTest>(x => x.Vku1),
            this.Vku1
        );
        dbObject.set(
            nameof<DrivingTest>(x => x.Vku2),
            this.Vku2
        );
        dbObject.set(
            nameof<DrivingTest>(x => x.Vku3),
            this.Vku3
        );
        dbObject.set(
            nameof<DrivingTest>(x => x.Vku4),
            this.Vku4
        );
        dbObject.set(
            nameof<DrivingTest>(x => x.VkuDoneDate),
            DateTime.toDbDate(this.VkuDoneDate as Date)
        );
        return dbObject;
    }
}
