import { nameof } from "ts-simple-nameof";
import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import UserSettings, { IUserSettings, IUserSettingsDto } from "../types/UserSettings";
import { SettingsService } from "./SettingsService";

export default class UserSettingsService extends SettingsService<IUserSettings, IUserSettingsDto> {
    private readonly TABLE_NAME = "usersettings";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields(): string[] {
        return keys<IUserSettingsDto>().filter(x => x !== "Id");
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IUserSettings>()).filter(x => x !== nameof<IUserSettings>(n => n.AppCompanySettingsKey) && x !== nameof<IUserSettings>(n => n.AppUserSettingsKey));
    }

    public getApiRoute(): string {
        return `${network.ODATA_API_V3}/usersettings`;
    }

    protected canSkipAndTop(): boolean {
        return false;
    }

    protected createEntityFromOData(item: IUserSettingsDto): IUserSettings {
        const entity = new UserSettings();
        entity.populateFromOData(item);
        if (!entity.UserId) {
            entity.UserId = network.getUserId();
        }
        return entity;
    }

    protected createEntityFromDb(item: IUserSettings): IUserSettings {
        const entity = new UserSettings();
        entity.populateFromDb(item);
        return entity;
    }

    public async getSettings(): Promise<IUserSettings> {
        const userSettings = await this.getItems(`UserId = ${network.getUserId()} AND CompanyId = ${network.getCompanyId()}`);
        return Util.firstOrDefault(userSettings);
    }

    public async updateSettings(updateObject: Map<string, any>, isDirty: boolean = true): Promise<void> {
        await this.updateWhere(`UserId = ${network.getUserId()} AND CompanyId = ${network.getCompanyId()}`, updateObject, isDirty);
    }

    protected async updateChangedItem(changedItem: any, updateLastSyncDate: string): Promise<void> {
        if (changedItem && changedItem.DefaultCompanyLogo) {
            await super.updateChangedItem(changedItem, updateLastSyncDate);
            await this.downloadCompanyImage();
        } else {
            await super.updateChangedItem(changedItem, updateLastSyncDate);
        }
    }

    protected async insertNewItem(newItem: any, updateLastSyncDate: string): Promise<void> {
        await super.insertNewItem(newItem, updateLastSyncDate);
        await this.downloadCompanyImage();
    }

    protected async afterPatchSettings(usersettings: UserSettings): Promise<void> {
        await super.afterPatchSettings(usersettings);
        await this.uploadCompanyImage(usersettings.DefaultCompanyLogo_INTERNAL, usersettings.DefaultCompanyLogo);
    }

    private uploadCompanyImage = async (icon: string, fileName: string): Promise<void> => {
        if (!fileName) {
            await network.send("POST", `${network.ODATA_API_V3}/UploadCompanyIcon`, { icon: icon, fileName: this.getFileName(fileName), contentType: "image/jpeg" });
        }
    };

    private getFileName = (fileName: string): string => {
        let counter = parseInt(fileName);
        if (counter) {
            counter++;
            return `${counter}companyIcon`;
        } else {
            return "1companyIcon";
        }
    };

    private downloadCompanyImage = async (): Promise<void> => {
        const response = await network.get<any>(`${network.ODATA_API_V3}/DownloadCompanyIcon`);
        await this.updateSettings(new Map<string, any>().set("DefaultCompanyLogo_INTERNAL", response.value), null);
    };

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE IsDirty = ? AND UserId = ${network.getUserId()} AND CompanyId = ${network.getCompanyId()}`;
    }
}

export const userSettingsService = new UserSettingsService();
