import ChecklistModel from "../models/ChecklistModel";
import PlannedChecklistItemModel from "../models/PlannedChecklistItemModel";
import ChecklistItem, { IChecklistItem, IChecklistItemDto } from "../types/ChecklistItem";
import { BaseService } from "./BaseService";
import { network } from "../modules/Network";
import ChecklistItemModel from "../models/ChecklistItemModel";
import { ratingService } from "./ChecklistRatingService";
import { keys } from "ts-transformer-keys";
import { logger } from "../modules/Logger";
import { Util } from "../modules/Util";
import { nameof } from "ts-simple-nameof";

export default class ChecklistItemService extends BaseService<IChecklistItem, IChecklistItemDto> {
    private readonly TABLE_NAME = "ChecklistItems";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IChecklistItemDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IChecklistItem>()).filter(x => x !== nameof<IChecklistItem>(n => n.HasContent));
    }

    public getApiRoute(): string {
        return `${network.API}/${ChecklistItem.EntityTypeId}`;
    }

    protected getTopCount(): number {
        return 1;
    }

    protected createEntityFromOData(item: any): ChecklistItem {
        const entity = new ChecklistItem();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: any): ChecklistItem {
        const entity = new ChecklistItem();
        entity.populateFromDb(item);
        return entity;
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        return `SELECT '${this.getTableName()}' AS TableName, MIN(1, COUNT(*)) AS DirtyCount FROM ${this.getTableName()} WHERE Id < 0 OR IsDirty = ?`;
    }

    public async pushItems(): Promise<number> {
        // chapters
        const mainChapters = await this.pushChapters("Id < 0 AND ParentId IS NULL");
        // subchapters after chapters because parentid is a foreignkey
        const subChapters = await this.pushChapters("Id < 0 AND ParentId IS NOT NULL");
        const patchedCount = await this.patchItems();
        return mainChapters + subChapters + patchedCount;
    }

    private async pushChapters(select: string) {
        const items = await this.getItems(select);
        for (const item of items) {
            await this.processItem(item);
        }
        return items.length;
    }

    protected async processItem(item: IChecklistItem): Promise<number> {
        try {
            const data = await network.send<ChecklistItem>("POST", this.getPostRoute(), item.toODataObject());
            await this.updateDependencies(item.Id, data.Id, true);
            const update = new Map<string, any>().set("IsNew", false);
            await this.update("Id", data.Id, update);
            return data.Id;
        } catch (e) {
            await this.handleDuplicatedItem(item);
        }
    }

    protected async resetDirtyFlag(id: number): Promise<void> {
        await this.updateWhere(`Id = ${id}`, new Map<string, any>().set("ContentDirty", false), false);
    }

    public async updateDependencies(oldId: number, newId: number, updateSelf: boolean): Promise<void> {
        await super.updateDependencies(oldId, newId, updateSelf);
        await this.update("ParentId", oldId, new Map<string, any>().set("ParentId", newId), true);
        await this.update("NextSiblingId", oldId, new Map<string, any>().set("NextSiblingId", newId), true);
        await this.update("PreviousSiblingId", oldId, new Map<string, any>().set("PreviousSiblingId", newId), true);
        await ratingService.update("ChecklistItemId", oldId, new Map<string, any>().set("ChecklistItemId", newId), false);
    }

    public async getChecklistModel(checklistCollectionId: number, excludeNewEntries: boolean = false): Promise<ChecklistItemModel[]> {
        const items = await this.getItems(
            `ChecklistCollectionId = ${checklistCollectionId} ${excludeNewEntries ? "AND Id > 0" : ""}`,
            null,
            null,
            "Id, Label, NextSiblingId, PreviousSiblingId, ParentId, (Overview IS NOT NULL) AS HasContent"
        );
        const checklistItemModels: ChecklistItemModel[] = [];
        const parents = this.sortItems(items.filter(x => !x.ParentId));
        for (let i = 0; i < parents.length; i++) {
            const parent = parents[i];
            const childs = this.sortItems(items.filter(x => x.ParentId === parent.Id));
            checklistItemModels.push(...childs.map((x, chapterSort) => new ChecklistItemModel(x, parent.Label, i, chapterSort)));
        }
        return checklistItemModels;
    }

    public async getTreeStructure(checklistId: number, excludeNewEntries: boolean = false): Promise<ChecklistModel[]> {
        const where = `ChecklistCollectionId = ${checklistId}${excludeNewEntries ? " AND Id > 0" : ""}`;
        const select = "Id, ChecklistCollectionId, ParentId, Label, PreviousSiblingId, NextSiblingId, (Overview IS NOT NULL) AS HasContent";
        const tree = await this.getItems(where, null, null, select);
        return this.buildTree(tree);
    }

    public buildTree(tree: IChecklistItem[]): ChecklistModel[] {
        const rootItems: ChecklistModel[] = this.sortItems(tree.filter(x => !x.ParentId)).map(x => new ChecklistModel(x));

        for (const rootItem of rootItems) {
            rootItem.items = this.sortItems(tree.filter(x => x.ParentId === rootItem.Id)).map(x => {
                const checklistItem = new ChecklistModel(x);
                checklistItem._ParentChecklistItem = rootItem;
                return checklistItem;
            });
        }
        return rootItems;
    }

    public sortItems(items: IChecklistItem[]): IChecklistItem[] {
        if (!this.itemsSortable(items)) {
            if (items.length) {
                logger.logError(new Error(`Items with ParentId ${Util.firstOrDefault(items).ParentId} are not sortable`), true, false);
            }
            return items;
        }

        let sorted = items.splice(
            items.findIndex(x => x.PreviousSiblingId === null),
            1
        );
        sorted = sorted.concat(
            items.splice(
                items.findIndex(x => x.NextSiblingId === null),
                1
            )
        );
        while (items.length) {
            const item = items.pop();
            const index = sorted.findIndex(x => x.NextSiblingId === item.Id);
            if (index >= 0) {
                sorted.splice(index + 1, 0, item);
            } else {
                items.splice(0, 0, item);
            }
        }
        return sorted;
    }

    private itemsSortable(items: IChecklistItem[]): boolean {
        return (
            items.filter(x => !x.PreviousSiblingId).length === 1 &&
            items.filter(x => !x.NextSiblingId).length === 1 &&
            items
                .filter(x => x.PreviousSiblingId && x.NextSiblingId)
                .every(x => items.filter(p => p.PreviousSiblingId === x.Id).length === 1 && items.filter(n => n.NextSiblingId === x.Id).length === 1)
        );
    }

    public async getPlannedChecklistItems(ids: number[]): Promise<PlannedChecklistItemModel[]> {
        const checklistItems = await this.getItemsByIds(ids);
        return checklistItems.map(x => new PlannedChecklistItemModel(x));
    }

    public async delete(id: number, isDirty: boolean = true): Promise<void> {
        const select = "Id, ParentId, NextSiblingId, PreviousSiblingId";
        const item = await this.getItemById(id, select);
        if (!item.ParentId) {
            const children = await this.getItems(`ParentId = ${item.Id}`);
            for (const child of children) {
                await this.delete(child.Id, isDirty);
            }
        }

        if (item.PreviousSiblingId && item.NextSiblingId) {
            await this.update("Id", item.PreviousSiblingId, new Map<string, any>().set("NextSiblingId", item.NextSiblingId), true);
            await this.update("Id", item.NextSiblingId, new Map<string, any>().set("PreviousSiblingId", item.PreviousSiblingId), true);
        } else if (item.PreviousSiblingId) {
            await this.update("Id", item.PreviousSiblingId, new Map<string, any>().set("NextSiblingId", null), true);
        } else if (item.NextSiblingId) {
            await this.update("Id", item.NextSiblingId, new Map<string, any>().set("PreviousSiblingId", null), true);
        }
        await super.delete(item.Id, isDirty);
    }

    public async insertLast(label: string, parentId: number, checklistCollectionId: number): Promise<void> {
        const previous = parentId
            ? await this.get<{ Id: number }>(`ParentId = ? AND NextSiblingId IS NULL AND ChecklistCollectionId = ?`, "Id, ChecklistCollectionId", [parentId, checklistCollectionId])
            : await this.get<{ Id: number }>(` NextSiblingId IS NULL AND ChecklistCollectionId = ?`, "Id, ChecklistCollectionId", [checklistCollectionId]);
        const checklistEntity = new ChecklistItem();
        checklistEntity.ChecklistCollectionId = checklistCollectionId;
        checklistEntity.Label = label;
        checklistEntity.NextSiblingId = null;
        checklistEntity.ParentId = parentId;
        checklistEntity.PreviousSiblingId = previous ? previous.Id : null;
        const newId = await this.insert(checklistEntity);
        if (previous) {
            await this.update("Id", previous.Id, new Map<string, any>().set("NextSiblingId", newId), true);
        }
    }
}

export const checklistItemService = new ChecklistItemService();
