import { orientationChange } from "../modules/OrientationChange";
import { Util } from "../modules/Util";
import { BaseView } from "./BaseView";

export default class FirstTimeIntroduction extends BaseView {
    private countOfPages: number;
    private telerikBugFixApplyed: boolean = false;

    public async loadData(): Promise<void> {
        // nothing to load
    }

    public initView(): void {
        if (Util.isFirstStart()) {
            $("#firstTimeIntroductionHider").show();
            Util.setUsername("orphydrivedemo@orphis.ch");
        } else {
            app.mobileApp.navigate("views/login.html");
        }
    }

    public show = async (): Promise<void> => {
        this.translate();
        setTimeout(() => {
            this.countOfPages = $("[data-role='page']").length - 1;
            this.change();
            this.showView();
        }, 0);
    };

    public showView(): void {
        this.addBackButtonEvent();
        setTimeout(() => {
            orientationChange.FunctionList = [this.changeHeight];
        }, 2000);
    }

    private changeHeight = () => {
        $(".introductionPhoto").height(window.innerHeight - $("#headerFTI").outerHeight());
        $("#firstTimeIntroductionGallery").height(window.innerHeight - $("#headerFTI").outerHeight());
        $("#firstTimeIntroductionHider").height(window.innerHeight);
    };

    public change = () => {
        const headerNavigation = $("#firstTimeIntroduction").find("ol");
        if (headerNavigation.find("li").length > this.countOfPages + 1 && !this.telerikBugFixApplyed) {
            headerNavigation.find("li:last").remove();
            this.telerikBugFixApplyed = true;
        }
        $("#headerTitleFTI").html(headerNavigation[0] as any);
    };

    public next = (e: any) => {
        if (e.nextPage > this.countOfPages) {
            if (e) {
                e.preventDefault(); // prevent scrolling
            }
            app.mobileApp.navigate("views/login.html");
        }
    };

    public manualNext = (e: any) => {
        const model = $("#firstTimeIntroductionGallery").data().kendoMobileScrollView;
        if (model.page < this.countOfPages) {
            model.next();
        } else {
            if (e) {
                e.preventDefault(); // prevent scrolling
            }
            this.navigateLogin();
        }
    };

    public getNavPoint(): string {
        throw new Error("Not implemented");
    }

    public back(e: any) {
        if (e) {
            e.preventDefault();
        }
    }

    public leapIntro = () => {
        this.navigateLogin();
    };

    private navigateLogin = () => {
        app.mobileApp.navigate("views/login.html");
    };
}
