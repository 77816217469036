import CalendarEntry from "../models/CalendarEntry";
import CalendarModel from "../models/CalendarModel";
import { logger } from "../modules/Logger";
import { Util } from "../modules/Util";
import { translation } from "./TranslationService";
import { userAppSettingsService } from "./UserAppSettingsService";

export default class CalendarService {
    public async getCalendarItems(): Promise<CalendarModel> {
        const userAppsettings = await userAppSettingsService.getSettings();
        if (Util.isSimulator()) {
            return this.getSimulatorData();
        }

        try {
            if (Util.isBrowser() || !(await this.hasReadPermission())) {
                return new CalendarModel([], [], false);
            }
        } catch (e) {
            return new CalendarModel([], [], false);
        }

        if (!userAppsettings || !userAppsettings.CalendarList || userAppsettings.CalendarList === null) {
            return new CalendarModel([], [], false);
        }

        const calendarList = JSON.parse(userAppsettings.CalendarList);

        const today = new Date();
        const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
        const dayAfterTomorrow = new Date(new Date().setDate(today.getDate() + 2));
        tomorrow.setHours(0, 0, 0, 0);
        dayAfterTomorrow.setHours(0, 0, 0, 0);

        if (Util.isiOS()) {
            return this.getEntriesForIos(today, tomorrow, dayAfterTomorrow, calendarList);
        }
        return this.getForAndroid(today, tomorrow, dayAfterTomorrow, calendarList);
    }

    private hasReadPermission(): Promise<boolean> {
        return new Promise((res, rej) => {
            window.plugins.calendar.hasReadPermission(res, rej);
        });
    }

    private async getEntriesForIos(today: Date, tomorrow: Date, dayAfterTomorrow: Date, calendarList: any): Promise<CalendarModel> {
        const entriesToday: CalendarEntry[] = [];
        const entriesTomorrow: CalendarEntry[] = [];

        for (const calendarListItem of calendarList) {
            const calOptions = window.plugins.calendar.getCalendarOptions();
            calOptions.calendarId = calendarListItem.id;
            await new Promise<void>(res => {
                window.plugins.calendar.findEventWithOptions(
                    "",
                    "",
                    "",
                    today,
                    dayAfterTomorrow,
                    calOptions,
                    calendarEvents => {
                        if (!calendarEvents) {
                            res();
                        }
                        for (const event of calendarEvents) {
                            if (!event.startDate || !event.endDate) {
                                continue;
                            }
                            if (new Date(event.startDate.replace(" ", "T")) < tomorrow && new Date(event.startDate.replace(" ", "T")) > today) {
                                const currentEvent = new CalendarEntry(
                                    event.title ? event.title : translation.t("common.unknown"),
                                    new Date(event.startDate),
                                    new Date(event.endDate),
                                    event.location ? event.location : null
                                );
                                if (!this.containsEvent(entriesToday, currentEvent)) {
                                    entriesToday.push(currentEvent);
                                }
                            } else if (new Date(event.startDate.replace(" ", "T")) > tomorrow) {
                                const currentEvent = new CalendarEntry(
                                    event.title ? event.title : translation.t("common.unknown"),
                                    new Date(event.startDate),
                                    new Date(event.endDate),
                                    event.location ? event.location : null
                                );
                                if (!this.containsEvent(entriesTomorrow, currentEvent)) {
                                    entriesTomorrow.push(currentEvent);
                                }
                            }
                        }
                        res();
                    },
                    e => {
                        logger.logError(new Error(`Error in Calendar Plugin: ${JSON.stringify(e)}`));
                        res();
                    }
                );
            });
        }
        return new CalendarModel(entriesToday, entriesTomorrow, true);
    }
    private getForAndroid(today: Date, tomorrow: Date, dayAfterTomorrow: Date, calendarList: any): Promise<CalendarModel> {
        return new Promise(res => {
            window.plugins.calendar.listEventsInRange(
                today,
                dayAfterTomorrow,
                e => {
                    const entriesToday: CalendarEntry[] = [];
                    const entriesTomorrow: CalendarEntry[] = [];
                    if (e && e !== "OK") {
                        for (const event of e) {
                            if (new Date(event.dtstart) < tomorrow && new Date(event.dtstart) > today && this.showItem(event.calendar_id, calendarList)) {
                                entriesToday.push(new CalendarEntry(event.title, new Date(event.dtstart), new Date(event.dtend), event.eventLocation));
                            } else if (new Date(event.dtstart) > tomorrow && this.showItem(event.calendar_id, calendarList)) {
                                entriesTomorrow.push(new CalendarEntry(event.title, new Date(event.dtstart), new Date(event.dtend), event.eventLocation));
                            }
                        }
                    }
                    res(new CalendarModel(entriesToday, entriesTomorrow, true));
                },
                e => {
                    logger.logError(new Error(`Error in Calendar Plugin: ${JSON.stringify(e)}`));
                    res(new CalendarModel([], [], true));
                }
            );
        });
    }

    private showItem(calendarId: any, calendarList: { id: any }[]): boolean {
        return !!calendarList.find(x => x.id === calendarId);
    }

    private containsEvent(allEvents: CalendarEntry[], newEvent: CalendarEntry) {
        return allEvents.some(item => item.Title === newEvent.Title && item.StartDate === newEvent.StartDate && item.EndDate === newEvent.EndDate && item.Location === newEvent.Location);
    }

    private getSimulatorData(): CalendarModel {
        const today = [];
        const todayEntry = new CalendarEntry("Treffen mit Hans", new Date(), new Date(), "bei den Affen. Ein sehr sehr langer Eintrag");

        today.push(todayEntry);
        today.push(todayEntry);
        today.push(todayEntry);
        today.push(todayEntry);
        today.push(todayEntry);
        today.push(todayEntry);
        today.push(todayEntry);

        const tomorrow = [];
        const tomorrowItem = new CalendarEntry("Treffen mit Susi", new Date(new Date().setDate(new Date().getDate() + 1)), new Date(new Date().setDate(new Date().getDate() + 1)), "im Garten");

        tomorrow.push(tomorrowItem);
        tomorrow.push(tomorrowItem);
        tomorrow.push(tomorrowItem);
        tomorrow.push(tomorrowItem);
        tomorrow.push(tomorrowItem);
        tomorrow.push(tomorrowItem);
        tomorrow.push(tomorrowItem);

        return new CalendarModel(today, tomorrow, true);
    }
}

export const calendarService = new CalendarService();
