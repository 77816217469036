import { Util } from "../modules/Util";
import { translation } from "../services/TranslationService";
import Education, { IEducation } from "../types/Education";
import BaseModel from "./BaseModel";

export default class EducationModel extends BaseModel {
    public ChecklistCollectionId: number | null;
    public PersonId: number;
    public ResponsibleDrivingTeacherId: number;
    public MeetingPointAddress: string;
    public JobId: number;
    public Note: string;

    constructor(education: IEducation) {
        super(education);
        this.ChecklistCollectionId = education.ChecklistCollectionId;
        this.PersonId = education.PersonId;
        this.ResponsibleDrivingTeacherId = education.ResponsibleDrivingTeacherId;
        this.MeetingPointAddress = education.MeetingPointAddress;
        this.JobId = education.JobId;
        this.Note = education.Note ? Util.HTMLDecode(education.Note) : null;
        super.init(this);
    }

    protected getComputedFields(map: Map<string, string>): Map<string, string> {
        super.getComputedFields(map);
        map.set("Note", "getNoteText");
        return map;
    }

    public getNoteText(): string {
        if (!this.Note) {
            return translation.t("common.no-note");
        }
        return this.Note;
    }

    public toEntity(education: IEducation = new Education()) {
        super.toEntity(education);
        education.ChecklistCollectionId = this.ChecklistCollectionId;
        education.PersonId = this.PersonId;
        education.ResponsibleDrivingTeacherId = this.ResponsibleDrivingTeacherId;
        education.MeetingPointAddress = this.MeetingPointAddress;
        education.JobId = this.JobId;
        education.Note = this.Note ? Util.HTMLEncode(this.Note) : null;
        return education;
    }
}
