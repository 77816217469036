import { IJobPricePosition, IJobPricePositionDto, JobPricePosition } from "./JobPricePosition";

export interface IBaseJobDefaultPosition {}

export interface IJobDefaultPositionDto extends IBaseJobDefaultPosition, IJobPricePositionDto {}
export interface IJobDefaultPosition extends IBaseJobDefaultPosition, IJobPricePosition {}

export default class JobDefaultPosition<TEntity extends IJobDefaultPosition, TDto extends IJobDefaultPositionDto> extends JobPricePosition<TEntity, TDto> implements IJobDefaultPosition {
    public static TYPE_NAME = "JobDefaultPosition";
    public static ODATA_TYPE_NAME = "#SharedDto.PublicApi.JobDefaultPositionDtoV2";

    public odataTypeName(): string {
        return JobDefaultPosition.ODATA_TYPE_NAME;
    }

    public get Discriminator(): string {
        return JobDefaultPosition.TYPE_NAME;
    }

    public calculateTotalPrice(): number {
        return this.applyDiscount(this.Amount * this.Price);
    }

    public createChargingPosition(parentId: number, amount: number = null): JobDefaultPosition<IJobDefaultPosition, IJobDefaultPositionDto> {
        const chargingPosition = new JobDefaultPosition();
        chargingPosition.copyFrom(this); // Copy common data
        chargingPosition.Amount = amount !== null ? amount : this.Amount;
        chargingPosition.SortOrder = 0;
        chargingPosition.ParentId = parentId;
        chargingPosition.ChargedPositionId = this.Id;
        return chargingPosition;
    }

    public populateFromDb(dbItem: TEntity): void {
        super.populateFromDb(dbItem);
    }

    public populateFromOData(oDataItem: TDto): void {
        super.populateFromOData(oDataItem);
    }

    public toODataObject(): TDto {
        const oDataObject = super.toODataObject();
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        return dbObject;
    }
}
