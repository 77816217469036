import { Util } from "../modules/Util";
import { jobPositionService } from "../services/JobPositionService";
import { paymentWayService } from "../services/PaymentWayService";
import { personService } from "../services/PersonService";
import { translation } from "../services/TranslationService";
import { BaseView } from "./BaseView";

export default class AddCredit extends BaseView {
    private fromEnterLesson: boolean;

    public async loadData(): Promise<void> {
        const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
        const paymentWays = await paymentWayService.getCashPaymentWays();
        this.set("paymentWays", paymentWays);
        const lastSelectedId = await paymentWayService.getLastSelectedPaymentWayId();
        if (paymentWays.some(p => p.Id === lastSelectedId)) {
            this.set("selectedPaymentWay", lastSelectedId);
        } else {
            this.set("selectedPaymentWay", Util.firstOrDefault(paymentWays).Id);
        }
        this.set("displayName", `${person.FirstName} ${person.LastName}`);
        this.set("personAvatar", Util.getAvatar(person.FirstName, person.LastName));
    }

    public showView(e: any): void {
        this.fromEnterLesson = e.view.params.fromEnterLesson === "true";
        this.set("addCreditButtonEnabled", true);
        this.set("creditAmount", "");
        this.set("note", "");
    }

    public setLastSelectedPaymentWay = () => {
        paymentWayService.setLastSelectedPaymentWayId(this.get<number>("selectedPaymentWay"));
    };

    public addCredit = async (): Promise<void> => {
        if (typeof this.get("creditAmount") !== "number" || this.get<number>("creditAmount") < 0) {
            Util.showNotification(translation.t("add-credit.add-voucher"), "error");
            this.set("addCreditButtonEnabled", true);
        } else {
            Util.showNotification(translation.t("add-credit.voucher-added"), "success");
            const positionIds = await jobPositionService.addCreditPosition(this.get<number>("creditAmount"), this.get<string>("note"), Util.getEducationId(this.personId), this.personId);
            const paymentWayEntity = await paymentWayService.getItemById(this.get<number>("selectedPaymentWay"));
            const createDate = new Date();
            await jobPositionService.accountPositions(Util.getEducationId(this.personId), this.personId, positionIds, paymentWayEntity.Id, true, createDate, createDate, true);
            this.back();
        }
    };

    public getNavPoint(): string {
        if (this.fromEnterLesson) {
            return `views/enterLessons.html?fromEnterLesson=${this.fromEnterLesson}`;
        } else {
            return `views/personDetail.html`;
        }
    }
}
