import DateTime from "../modules/DateTime";
import { logger } from "../modules/Logger";
import { network } from "../modules/Network";
import { IBaseDto, IBaseEntity } from "../types/BaseEntity";
import { BaseService } from "./BaseService";
import { lastSyncDateService } from "./LastSyncDateService";

export abstract class SettingsService<TEntity extends IBaseEntity, TDto extends IBaseDto> extends BaseService<TEntity, TDto> {
    public abstract getSettings(): Promise<TEntity>;
    public abstract updateSettings(updateObject: Map<string, any>, isDirty: boolean): Promise<void>;

    protected getModifiedSinceODataRoute() {
        return `${this.getApiRoute()}/ModifiedSince`;
    }

    protected getPatchODataRoute() {
        return this.getApiRoute();
    }

    public async pullItems(): Promise<void> {
        const lastSyncDate = await lastSyncDateService.getLastSyncDate(this.getTableName());
        await this.requestItems(lastSyncDate);
    }

    protected async requestItems(lastSyncDate: string): Promise<void> {
        logger.logInfo(`${this.getTableName()} service preparing to pull, last sync date: ${lastSyncDate}`);
        const settings = await this.getSettings();
        const items = await this.getSettingsModifiedSinceOdata(lastSyncDate);
        logger.logInfo(`${this.getTableName()} service received changed settings`);
        const updateLastSyncDate: string = items.length > 0 ? items[0].ModifiedAt : null;

        if (settings && items.length > 0) {
            // If we have items already, we continue with the already downloaded items
            await this.updateChangedItem(items[0], updateLastSyncDate);
        } else if (!settings) {
            // If we are blank, we perform a full get. We still use the ModifiedSince date to evaluate the last sync date
            logger.logInfo(`${this.getTableName()} service will pull the settings`);
            const retrievedItems = await this.getSettingsOdata();
            await this.insertNewItem(retrievedItems, updateLastSyncDate);
        }
    }

    protected async updateChangedItem(changedItem: any, updateLastSyncDate: string): Promise<void> {
        await this.updateSettings(this.createDbUpdateObject(changedItem.Value ? JSON.parse(changedItem.Value) : changedItem), null);
        logger.logInfo(`${this.getTableName()} service has updated the local settings`);
        if (updateLastSyncDate) {
            await this.updateSyncDate(updateLastSyncDate);
        }
    }

    protected async insertNewItem(newItem: any, updateLastSyncDate: string): Promise<void> {
        await this.insert(this.createEntityFromOData(newItem));
        logger.logInfo(`${this.getTableName()} service has inserted new settings`);
        if (updateLastSyncDate) {
            await this.updateSyncDate(updateLastSyncDate);
        }
    }

    public async pushItems(): Promise<number> {
        return this.patchItems();
    }

    public async patchItems(): Promise<number> {
        const settings = await this.getSettings();
        if (settings && settings.IsDirty) {
            await this.patchSettingsOdata(settings);
            await this.updateWhere(`Id = ${settings.Id}`, new Map<string, any>(), false); // Reset IsDirty
            return 1;
        }
        return 0;
    }

    public createDbUpdateObject(oDataUpdateItem: any): Map<string, any> {
        const updateObject = new Map<string, any>();
        for (const [key, value] of Object.entries(oDataUpdateItem)) {
            if (key === "DefaultLanguage") {
                updateObject.set("Language", value);
            } else if (this.getDtoFields().some(x => x === key)) {
                updateObject.set(key, value);
            }
        }
        return updateObject;
    }

    protected getSettingsOdata(): Promise<any> {
        return network.getItems(this.getApiRoute());
    }

    protected getSettingsModifiedSinceOdata(lastSyncDate: string): Promise<any> {
        return network.getItems(`${this.getModifiedSinceODataRoute()}(Date='${DateTime.getModifiedSinceRequestDate(lastSyncDate)}')`);
    }

    protected async patchSettingsOdata(settings: TEntity): Promise<boolean> {
        try {
            await network.send("PATCH", this.getPatchODataRoute(), settings.toODataObject());
            await this.afterPatchSettings(settings);
            return true;
        } catch (response) {
            if (response.status === 403) {
                this.forbiddenItems++;
                await this.hardDelete(settings.Id);
                return false;
            } else {
                return Promise.reject(response);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected async afterPatchSettings(settings: TEntity): Promise<void> {
        // dont want to patch
    }
}
