import BillModel, { BillItemState } from "../models/BillModel";
import DateTime from "../modules/DateTime";
import { Util } from "../modules/Util";
import { translation } from "../services/TranslationService";
import ListExport from "./ListExport";

export default class BillExport extends ListExport {
    public async createExport(args: any[]): Promise<void> {
        const billItems: BillModel[] = args[0];

        const fileName = `${translation.t("export.bills-filename-prefix")}-${DateTime.parseNumberDate(new Date())}.xlsx`;
        const sheets: any[] = [];
        let rows: any[] = [];
        const colSpan = 5;
        const columns = [{ width: 100 }, { autoWidth: true }, { autoWidth: true }, { autoWidth: true }, { autoWidth: true }];
        let lastDate = null;
        let total = 0;
        let totalOpen = 0;
        let totalPaid = 0;
        for (const billItem of billItems) {
            if (!lastDate || !DateTime.isSameMonth(lastDate, billItem.Date)) {
                if (lastDate) {
                    rows.push(this.empty(colSpan));
                    rows.push(
                        rows.push({
                            cells: [{ value: `${translation.t("export.bills-total")}:`, bold: true, colSpan: 3 }, { value: Util.formatPriceExport(total), textAlign: "right", bold: true }, { value: "" }]
                        })
                    );
                    rows.push(this.empty(colSpan));
                    rows.push(
                        rows.push({
                            cells: [{ value: `${translation.t("export.bills-total-open")}:`, bold: true, colSpan: 3 }, { value: Util.formatPriceExport(totalOpen), textAlign: "right", bold: true }, { value: "" }]
                        })
                    );
                    rows.push(
                        rows.push({
                            cells: [{ value: `${translation.t("export.bills-total-payed")}:`, bold: true, colSpan: 3 }, { value: Util.formatPriceExport(totalPaid), textAlign: "right", bold: true }, { value: "" }]
                        })
                    );
                    total = 0;
                    totalOpen = 0;
                    totalPaid = 0;
                    sheets.push({ columns: columns, rows: rows, showGridLines: true, title: DateTime.getMonthAndYear(lastDate) });
                }
                rows = [];
                rows.push({
                    cells: [{ value: `${translation.t("export.bills-month-overview")} ${DateTime.getMonth(billItem.Date)}`, bold: true, colSpan: colSpan, rowSpan: 2, verticalAlign: "top" }]
                });
                rows.push(this.empty(colSpan));
                rows.push({
                    cells: [
                        { value: translation.t("common.date"), bold: true },
                        { value: "ID", bold: true },
                        { value: translation.t("common.student"), bold: true },
                        { value: translation.t("common.cost"), bold: true },
                        { value: translation.t("common.state"), bold: true },
                        { value: translation.t("common.category"), bold: true }
                    ]
                });
                rows.push(this.empty(colSpan));
            }
            rows.push({
                cells: [
                    { value: DateTime.parseNumberDate(billItem.Date) },
                    { value: this.getCustomId(billItem.CustomId) },
                    { value: billItem.Title },
                    { value: Util.formatPriceExport(billItem.TotalPrice), textAlign: "right" },
                    { value: billItem.StatusText.replace("&uuml;", "\u00FC").replace("&auml;", "\u00E4") },
                    { value: billItem.ChecklistName.replace("&ouml;", "\u00f6") }
                ]
            });
            lastDate = billItem.Date;
            total += billItem.TotalPrice;
            if (billItem.BillStatus === BillItemState.Open || billItem.BillStatus === BillItemState.Overdue) {
                totalOpen += billItem.TotalPrice;
            } else if (billItem.BillStatus === BillItemState.Paid || billItem.BillStatus === BillItemState.PaidCash) {
                totalPaid += billItem.TotalPrice;
            }
        }

        rows.push(this.empty(colSpan));
        rows.push(
            rows.push({
                cells: [{ value: `${translation.t("export.bills-total")}:`, bold: true, colSpan: 3 }, { value: Util.formatPriceExport(total), textAlign: "right", bold: true }, { value: "" }]
            })
        );
        rows.push(this.empty(colSpan));
        rows.push(
            rows.push({
                cells: [{ value: `${translation.t("export.bills-total-open")}:`, bold: true, colSpan: 3 }, { value: Util.formatPriceExport(totalOpen), textAlign: "right", bold: true }, { value: "" }]
            })
        );
        rows.push(
            rows.push({
                cells: [{ value: `${translation.t("export.bills-total-payed")}:`, bold: true, colSpan: 3 }, { value: Util.formatPriceExport(totalPaid), textAlign: "right", bold: true }, { value: "" }]
            })
        );
        sheets.push({ columns: columns, rows: rows, showGridLines: true, title: DateTime.getMonthAndYear(lastDate) });
        this.openExcel(sheets, fileName);
    }

    private getCustomId(customId: string) {
        if (customId === `<i class="icon-sync icons largeIcon"></i>`) {
            return translation.t("common.not-synchronized");
        }
        return customId;
    }
}
