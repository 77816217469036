import SeriesModel from "./SeriesModel";

export default class ChartModel extends kendo.data.ObservableObject {
    public Data: SeriesModel[];
    public Width: number;
    public Height: number;
    public HoleSize: number;
    public LabelDistance: number;
    public ChartTitle: string;
    public ChapterId: string;
    public Index: number;
}
