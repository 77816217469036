import DateTime from "../modules/DateTime";
import { translation } from "../services/TranslationService";

export enum BillItemState {
    Draft,
    Open,
    Overdue,
    PaidCash,
    Paid,
    Canceled,
    DebitCard
}

export default class BillModel extends kendo.data.ObservableObject {
    public Id: number;
    public CustomId: string;
    public Title: string;
    public Date: Date;
    public BillStatus: BillItemState;
    public TotalPrice: number;
    public Visible: boolean;
    public ChecklistId: number;
    public ChecklistName: string;

    constructor(id: number, customId: string, title: string, date: Date, billStatus: BillItemState, checklistId: number, checklistName: string) {
        super();
        this.Id = id;
        this.CustomId = customId;
        this.Title = title;
        this.Date = date;
        this.BillStatus = billStatus;
        this.Visible = true;
        this.ChecklistId = checklistId;
        this.ChecklistName = checklistName;
        super.init(this);
    }

    get IsPaid(): boolean {
        return this.BillStatus === BillItemState.Paid || this.BillStatus === BillItemState.PaidCash;
    }

    get DeleteAble(): boolean {
        return this.BillStatus !== BillItemState.Paid && this.BillStatus !== BillItemState.Canceled && this.BillStatus !== BillItemState.PaidCash && this.BillStatus !== BillItemState.DebitCard;
    }

    get IsNotCanceled(): boolean {
        return this.BillStatus !== BillItemState.Canceled;
    }

    public getShortDate() {
        return DateTime.parseShortNumberDate(this.Date);
    }

    get StatusText(): string {
        switch (this.BillStatus) {
            case BillItemState.Draft:
                return translation.t("bill-model.draft");
            case BillItemState.Open:
                return translation.t("bill-model.open");
            case BillItemState.Overdue:
                return translation.t("bill-model.overdue");
            case BillItemState.PaidCash:
                return translation.t("bill-model.paidcash");
            case BillItemState.Paid:
                return translation.t("bill-model.paid");
            case BillItemState.Canceled:
                return translation.t("bill-model.canceled");
            case BillItemState.DebitCard:
                return translation.t("bill-model.debitcard");
            default:
                return "";
        }
    }

    get StatusIcon(): string {
        switch (this.BillStatus) {
            case BillItemState.Draft:
                return `<div class="billStatusOpen billStatusSymbol"><i class="icon-sandglass"></i></div><span class="btnText billStatusText ellipsis smallText">${this.StatusText}</span>`;
            case BillItemState.Open:
                return `<div class="billStatusOpen billStatusSymbol"><i class="custom-icon-clock-isolated"></i></div><span class="btnText billStatusText ellipsis smallText">${this.StatusText}</span>`;
            case BillItemState.Overdue:
                return `<div class="billStatusOverdue billStatusSymbol"><b>!</b></div><span class="btnText billStatusText ellipsis btnText smallText">${this.StatusText}</span>`;
            case BillItemState.PaidCash:
                return `<div class="billStatusPaid billStatusSymbol"><i class="icons icon-tick"></i></div><span class="btnText billStatusText ellipsis smallText">${this.StatusText}</span>`;
            case BillItemState.Paid:
                return `<div class="billStatusPaid billStatusSymbol"><i class="icons icon-tick"></i></div><span class="btnText billStatusText ellipsis smallText">${this.StatusText}</span>`;
            case BillItemState.Canceled:
                return `<div class="billStatusCanceled billStatusSymbol"><i class="icons icon-cross"></i></div><span class="btnText billStatusText ellipsis smallText">${this.StatusText}</span>`;
            case BillItemState.DebitCard:
                return `<div class="billStatusPaid billStatusSymbol"><i class="icons icon-tick"></i></div><span class="btnText billStatusText ellipsis smallText">${this.StatusText}</span>`;
            default:
                return "";
        }
    }
}
