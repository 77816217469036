import ConfirmContent from "../modules/ConfirmContent";
import { logger } from "../modules/Logger";
import { Util } from "../modules/Util";
import { checklistItemService } from "../services/ChecklistItemService";
import { translation } from "../services/TranslationService";
import { BaseView } from "./BaseView";

export default class EditChecklistContent extends BaseView {
    public chapterTitle: string;
    private checklistCollectionId: number;

    private editor: kendo.ui.Editor;
    private chapterId: number;
    private readonly MAX_SIZE = 19_000_000; // 20 Mb (Overview max size)

    public initView(): void {
        this.initEditor();
    }

    private initEditor = () => {
        this.editor = $("#checklistContentTextEditor")
            .kendoEditor({
                tools: []
            })
            .data("kendoEditor");
        $(this.editor.body).keyup(() => {
            this.editor.update();
        });
        $(".editorToolbarWindow").remove();
    };

    public async loadData(e: any): Promise<void> {
        this.chapterId = Number(e.view.params.chapterId);
        this.checklistCollectionId = Number(e.view.params.checklistCollectionId);
        const item = await checklistItemService.getItemById(this.chapterId);
        this.set("chapterTitle", translation.t("edit-checklist.edit-label", { label: item.Label, interpolation: { escapeValue: false } }));
        $("#chapterContentECC").html(Util.replaceChecklistBlob(item.Overview));
        $(".myDriveGalleryPicture em [style]").each((_, ele) => $(ele).removeAttr("style").removeAttr("lang"));
        this.loadImages();
    }

    private loadImages = () => {
        $("#chapterContentECC").find("img").on("load", this.imageLoaded);
        $("#chapterContentECC")
            .find("iframe")
            .each((i, element) => this.imageLoaded({ target: element } as any));
    };

    private imageLoaded = (event: JQueryEventObject) => {
        const image = $(event.target);
        if (Util.isBrowser()) {
            image.attr("crossorigin", "anonymous");
        }
        const moveImageUpButton = $('<button class="iconOnly iconOnlyOutline iconOnlyFilled iconBtnLarge moveImageUp"><i class="icon-arrow-up icons"></i></button>');
        const deleteImageButton = $('<button class="iconOnly iconOnlyOutline iconOnlyFilled iconBtnLarge deleteImage"><i class="icon-trash icons"></i></button>');
        const moveImageDownButton = $('<button class="iconOnly iconOnlyOutline iconOnlyFilled iconBtnLarge moveImageDown"><i class="icon-arrow-down icons"></i></button>');
        image.wrap('<div class="wrappedEditContentImage default-flex"></div>');
        const buttonWrapper = $("<div class='editContentButtonWrapper'></div>");
        buttonWrapper.append(moveImageUpButton).append(deleteImageButton).append(moveImageDownButton);
        if (!$(event.target).closest(".myDriveGalleryPicture").find("em").find("a").length) {
            const editContent = $('<button class="iconOnly iconOnlyOutline iconOnlyFilled iconBtnLarge"><i class="icon-pencil icons"></i></button>');
            buttonWrapper.append(editContent);
            editContent.on("click", this.editContent);
        }
        image.after(buttonWrapper);
        moveImageUpButton.on("click", this.moveImageUp);
        deleteImageButton.on("click", this.deleteImage);
        moveImageDownButton.on("click", this.moveImageDown);
    };

    private currentEditElement;

    public editContent = (e: Event) => {
        const contentElement = $(e.target).closest(".myDriveGalleryPicture");
        this.set("editVisible", true);
        this.currentEditElement = contentElement.find("em");
        this.editor.value(this.currentEditElement.html());
    };

    public saveEdit = () => {
        this.currentEditElement.html(this.editor.value());
        this.set("editVisible", false);
    };

    public cancelEdit = () => {
        this.set("editVisible", false);
    };

    public moveImageUp = (e: Event) => {
        const imageWrapper = $(e.target).closest(".myDriveGalleryPicture");
        const prevImage = imageWrapper.prev();
        prevImage.before(imageWrapper);
    };

    public async deleteImage(e: Event) {
        const { confirmed } = await Util.confirmTemplate(ConfirmContent.deleteImage(), translation.t("common.delete"), translation.t("common.cancel"));
        if (confirmed) {
            $(e.target).closest(".myDriveGalleryPicture").remove();
        }
    }

    public moveImageDown = (e: Event) => {
        const imageWrapper = $(e.target).closest(".myDriveGalleryPicture");
        const nextImage = imageWrapper.next();
        nextImage.after(imageWrapper);
    };

    public addImage = async () => {
        try {
            const image = await Util.getPhotoFromLibrary();

            const resizedImage = await Util.ResizeBase64Image(image);

            const { confirmed, messages } = await Util.confirmTemplate(ConfirmContent.addChecklistItemImage(), translation.t("common.go"), translation.t("common.cancel"), false, [
                "#imageDescriptionECC"
            ]);
            if (confirmed) {
                this.removeWrappingContent();

                $("#chapterContentECC").html(
                    `${$("#chapterContentECC").html()}<div class="myDriveGalleryPicture"><img alt="mydrive" src='${Util.addBase64Meta(resizedImage)}'/><em>${Util.firstOrDefault(
                        messages
                    ).val()}</em></div>`
                );
                this.loadImages();
            }
        } catch (e) {
            logger.logError(e, false, false);
            Util.showNotification(translation.t("util.galery-not-open"), "error");
        }
    };

    public async addVideo() {
        const { confirmed, messages } = await Util.confirmTemplate(ConfirmContent.addChecklistItemVideo(), translation.t("common.go"), translation.t("common.cancel"), false, [
            "#videoUrl",
            "#videoDescriptionECC"
        ]);
        if (confirmed) {
            const url = messages[0].val();
            try {
                const searchParams = new URLSearchParams(new URL(url).search);
                if (searchParams.has("v") && url.includes("youtube.com")) {
                    this.removeWrappingContent();
                    $("#chapterContentECC").html(
                        `${$("#chapterContentECC").html()}<div class="myDriveGalleryPicture"><iframe height='315' width='80%' src="https://www.youtube.com/embed/${searchParams.get(
                            "v"
                        )}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><em>${messages[1].val()}</em></div>`
                    );
                    this.loadImages();
                } else {
                    throw new Error("invalid URL");
                }
            } catch (e) {
                Util.showNotification(translation.t("util.no-valid-youtube-url"), "error");
            }
        }
    }

    private getContent = () => {
        this.removeWrappingContent();
        return $("#chapterContentECC").html();
    };

    private removeWrappingContent = () => {
        $(".moveImageUp, .deleteImage, .moveImageDown .editContentButtonWrapper").remove();
        $(".editContentButtonWrapper").remove();
        $("#chapterContentECC").find("img").unwrap();
        $("#chapterContentECC").find("iframe").unwrap();
    };

    public async back(): Promise<void> {
        const content = this.getContent();
        // check if saved string is smaller than 20 Mb, 1 Char == 2 bytes
        // we give 19 Mb space for images
        if (content.length > this.MAX_SIZE) {
            Util.showNotification(translation.t("util.size-too-big"), "error");
            return;
        }
        app.mobileApp.showLoading();
        await checklistItemService.update("Id", this.chapterId, new Map().set("Overview", Util.addChecklistBlob(content)).set("ContentDirty", true), true);
        app.mobileApp.hideLoading();
        $("#chapterContentECC").empty();
        super.back();
    }

    public getNavPoint() {
        return `views/editChecklist.html?fromEditContent=true&checklistCollectionId=${this.checklistCollectionId}`;
    }
}
