import { dal } from "../dal/Dal";
import { logger } from "../modules/Logger";
import { network } from "../modules/Network";
import { IBaseEntity, IBaseDto } from "../types/BaseEntity";
import { BaseService } from "./BaseService";

export abstract class BaseNoHistoryService<TEntity extends IBaseEntity, TDto extends IBaseDto> extends BaseService<TEntity, TDto> {
    public async pullItems(): Promise<void> {
        logger.logInfo(`${this.getTableName()} service preparing to pull`);
        await this.deleteAllEntries();
        const items = await network.getItems<TDto[]>(this.getApiRoute());
        logger.logInfo(`${this.getTableName()} service received ${items.length} items`);
        for (const item of items) {
            await this.insert(this.createEntityFromOData(item));
        }
    }

    private async deleteAllEntries(): Promise<void> {
        await dal.execute(`DELETE FROM ${this.getTableName()}`);
    }

    public async pushItems(): Promise<number> {
        return 0;
    }

    public async patchItems(): Promise<number> {
        return 0;
    }
}
