import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";
import { translation } from "../services/TranslationService";

export enum FormalityKind {
    Custom,
    DearMrLastname,
    DearMrFirstnameLastname,
    DearFirstname,
    DearFirstnameLastname
}

interface IBaseSalutation {}

export interface ISalutationDto extends IBaseDto, IBaseSalutation {
    TranslatedNameWithPlaceholder: string;
    Kind: FormalityKind;
}
export interface ISalutation extends IBaseEntity, IBaseSalutation {
    Name: string;
    Type: FormalityKind;
}

export default class Salutation extends BaseEntity<ISalutation, ISalutationDto> implements ISalutation {
    public static get EntityTypeId(): string {
        return "96b0c65b-31d6-5b8c-bb89-fa204ab421d7";
    }

    public Name: string;
    public Type: FormalityKind;

    public populateFromDb(dbItem: ISalutation): void {
        super.populateFromDb(dbItem);
        this.Type = dbItem.Type;
        if (dbItem.Type === FormalityKind.Custom || dbItem.Type === null) {
            this.Name = dbItem.Name;
        } else {
            this.Name = translation.t(`salutations.type_${dbItem.Type}`);
        }
    }

    public populateFromOData(oDataItem: ISalutationDto): void {
        super.populateFromOData(oDataItem);
        this.Type = oDataItem.Kind;
        if (oDataItem.Kind === FormalityKind.Custom) {
            this.Name = oDataItem.TranslatedNameWithPlaceholder;
        } else {
            this.Name = translation.t(`salutations.type_${oDataItem.Kind}`);
        }
    }

    public toODataObject(): ISalutationDto {
        const oDataObject = super.toODataObject();
        oDataObject.TranslatedNameWithPlaceholder = this.Name;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Salutation>(x => x.Name),
            this.Name
        );
        dbObject.set(
            nameof<Salutation>(x => x.Type),
            this.Type
        );
        return dbObject;
    }
}
