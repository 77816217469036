import ChecklistModel from "./ChecklistModel";

export default class EditChecklistItemModel extends kendo.data.ObservableObject {
    public Id: number;
    public PreviousSiblingId: number;
    public NextSiblingId: number;
    public ParentId: number;
    public Label: string;
    public EditLabel: string = "";
    public expanded: boolean;
    public EditMode: boolean;
    public IsInputNode: boolean;
    public items: EditChecklistItemModel[] = [];
    public HasContent: boolean;

    public static fromChecklistItem(c: ChecklistModel): EditChecklistItemModel {
        const node = new EditChecklistItemModel();
        node.Id = c.Id;
        node.PreviousSiblingId = c.PreviousSiblingId;
        node.NextSiblingId = c.NextSiblingId;
        node.Label = c.Label;
        node.expanded = true;
        node.ParentId = c._ParentChecklistItem ? c._ParentChecklistItem.Id : null;
        node.EditMode = false;
        node.IsInputNode = false;
        node.items = c.items.map(child => EditChecklistItemModel.fromChecklistItem(child));
        node.HasContent = c.HasContent;
        return node;
    }
}
