import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import Salutation, { FormalityKind, ISalutation, ISalutationDto } from "../types/Salutation";
import { BaseNoHistoryService } from "./BaseNoHistoryService";

export default class SalutationService extends BaseNoHistoryService<ISalutation, ISalutationDto> {
    private readonly TABLE_NAME = "Salutations";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ISalutationDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ISalutation>());
    }

    public getApiRoute(): string {
        return `${network.API}/${Salutation.EntityTypeId}`;
    }

    public getDefaultSalutationId(): number {
        return FormalityKind.DearFirstname;
    }

    protected createEntityFromOData(item: ISalutationDto): ISalutation {
        const entity = new Salutation();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: ISalutation): ISalutation {
        const entity = new Salutation();
        entity.populateFromDb(item);
        return entity;
    }
}

export const salutationService = new SalutationService();
