import { Util } from "../modules/Util";
import EmailService from "../services/EmailService";
import { loggerService } from "../services/LoggerService";
import PushDbService from "../services/PushDbService";
import Log from "../types/Log";
import { BaseView } from "./BaseView";
import { configService } from "../services/ConfigService";
import ConfirmContent from "../modules/ConfirmContent";
import { translation } from "../services/TranslationService";

export default class ErrorLog extends BaseView {
    public errorLog: Log[];

    private readonly NAV_POINT = "views/settings.html";

    public async loadData(): Promise<void> {
        const errorLogs = await loggerService.getItems("LogLevel = 'error'");
        this.set("errorLog", errorLogs);
    }

    public showView(): void {
        this.set("headerTitle", `Log - Version: ${configService.Config.Version}`);
    }

    public sendPerMail = async (): Promise<void> => {
        const send = (await Util.confirmTemplate(ConfirmContent.sendLog(), translation.t("error-log.yes-send-to-support"), translation.t("error-log.no"))).confirmed;
        if (send) {
            try {
                app.mobileApp.showLoading();
                const dbCSV = await new PushDbService().pushDB();
                try {
                    await new EmailService().sendEmail(Util.getUsername(), "info@orphis.ch", `Errorlog from: ${Util.getUsername()}`, `${$("#errorLog").html()}`);
                } catch (e) {
                    Util.showNotification(translation.t("error-log.error-send"), "error");
                    // retry to send the log by user
                    return;
                }
                try {
                    await new EmailService().sendEmail(Util.getUsername(), "info@orphis.ch", `Errorlog from: ${Util.getUsername()}`, dbCSV);
                    Util.showNotification(translation.t("error-log.email-successful"), "success");
                } catch (e) {
                    // don't want to retry this
                }
            } finally {
                await loggerService.hardDeleteAll();
                this.back();
                app.mobileApp.hideLoading();
            }
        }
    };

    public deleteLog = async () => {
        const deleteLog = (await Util.confirmTemplate(ConfirmContent.deleteLog(), translation.t("util.yes"), translation.t("util.no"))).confirmed;
        if (deleteLog) {
            await loggerService.hardDeleteAll();
        }
        await this.loadData();
    };

    public getNavPoint(): string {
        return this.NAV_POINT;
    }
}
