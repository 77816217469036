import { nameof } from "ts-simple-nameof";
import { keys } from "ts-transformer-keys";
import { logger } from "../modules/Logger";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { IEducation } from "../types/Education";
import Job, { IJob, IJobDto, JobStatus } from "../types/Job";
import JobTotalPosition from "../types/JobTotalPosition";
import { billService } from "./BillService";
import { conditionService } from "./ConditionService";
import { currencyService } from "./CurrencyService";
import { educationService } from "./EducationService";
import { JobBaseService } from "./JobBaseService";
import { jobPositionService } from "./JobPositionService";
import { paymentWayService } from "./PaymentWayService";
import { personService } from "./PersonService";
import { userService } from "./UserService";
import { translation } from "./TranslationService";

export default class JobService extends JobBaseService<IJob, IJobDto> {
    private readonly TABLE_NAME = "Jobs";

    public getApiRoute(): string {
        return `${network.API}/${Job.EntityTypeId}`;
    }

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IJobDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IJob>()).filter(x => x !== nameof<IJob>(n => n.createBill));
    }

    protected createEntityFromDb(item: any): Job {
        const jobEntity = new Job();
        jobEntity.populateFromDb(item);
        return jobEntity;
    }

    public createEntityFromOData(item: any): IJob {
        const jobEntity = new Job();
        jobEntity.populateFromOData(item);
        return jobEntity;
    }

    public async getJobByEducationId(educationId: number): Promise<IJob> {
        const education = await educationService.getItemById(educationId);
        if (education) {
            return this.getItemById(education.JobId);
        }
        return null;
    }

    public async updateDependencies(oldId: number, newId: number, updateSelf: boolean): Promise<void> {
        await super.updateDependencies(oldId, newId, updateSelf);
        await billService.updateJobForeignKey(oldId, newId);
        await jobPositionService.updateJobBaseForeignKey(oldId, newId);
        const updateFK = new Map<string, any>().set("JobId", newId);
        await educationService.update("JobId", oldId, updateFK, true);
    }

    public getJobsByPersonId(personId: number): Promise<IJob[]> {
        return this.getItems(`PersonId = ${personId}`);
    }

    public async createJobForPerson(education: IEducation): Promise<IJob> {
        const users = await userService.getItems(null, null, null, "PersonId");
        const person = Util.firstOrDefault(
            await personService.getPersonItems(`Id = ${education.PersonId} AND Id NOT IN ${Util.joinIds(users.map(x => x.PersonId))}`, null, null, "Id, FirstName, LastName, AnsprechsPartnerId")
        );
        const paymentWay = await paymentWayService.getDefaultPaymentWay();
        const condition = await conditionService.getCondition();
        const currency = await currencyService.getDefaultCurrency();

        if (paymentWay === null || condition === null || currency === null) {
            Util.showNotification(translation.t("util.please-sync"), "error");
            const error = new Error("Missing crucial infos to create job, user needs to resync");
            logger.logError(error);
            throw error;
        }

        if (person) {
            const job = new Job();
            job.PersonId = education.PersonId;
            job.Date = new Date();
            job.Name = `${translation.t("person-detail.abos-of")} ${person.FirstName} ${person.LastName}`;
            job.JobStatus = JobStatus.Offen;
            job.PaymentWayId = paymentWay ? paymentWay.Id : null;
            job.ConditionId = condition ? condition.Id : null;
            job.CurrencyId = currency ? currency.Id : null;
            job.ContactPersonId = education.ResponsibleDrivingTeacherId;
            job.Id = await this.insert(job);
            const totalPosition = new JobTotalPosition();
            totalPosition.Id = job.Id;
            await jobPositionService.insert(totalPosition);
            return job;
        } else {
            logger.logError(new Error(`Person with id ${education.PersonId} not found`));
            throw new Error(`Person with id ${education.PersonId} not found`);
        }
    }
}

export const jobService = new JobService();
