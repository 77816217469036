import DateTime from "../modules/DateTime";

export default class CalendarEntry {
    public Title: string;
    public StartDate: Date;
    public EndDate: Date;
    public Location: string;

    constructor(title: string = null, startDate: Date, endDate: Date, location: string = null) {
        this.Title = title;
        this.StartDate = startDate;
        this.EndDate = endDate;
        this.Location = location;
    }

    public dateAndLocation(): string {
        return `${DateTime.getTime(this.StartDate)} &middot; ${DateTime.getTime(this.EndDate)} ${this.getLocationText()}`;
    }

    private getLocationText(): string {
        return this.Location ? ` - ${this.Location}` : "";
    }
}
