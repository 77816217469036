import DateTime from "../modules/DateTime";
import { translation } from "../services/TranslationService";
import Lfa, { ILfa } from "../types/Lfa";
import BaseModel from "./BaseModel";

export default class LfaModel extends BaseModel {
    public LfaNumber: string;
    public LfaExpireDate: string;
    public Pid: string;
    public Pin: string;
    public FaberNumber: string;

    constructor(lfa: ILfa) {
        super(lfa);
        this.LfaNumber = lfa.LfaNumber;
        this.LfaExpireDate = lfa.LfaExpireDate as string;
        this.Pid = lfa.Pid;
        this.Pin = lfa.Pin;
        this.FaberNumber = lfa.FaberNumber;
        super.init(this);
    }

    public getLfaNumber(): string {
        return this.LfaNumber ?? translation.t("education-view.no-lfa-number");
    }

    public getPid(): string {
        return this.Pid ?? translation.t("lfa-view.no-pid");
    }

    public getPin(): string {
        return this.Pin ?? translation.t("lfa-view.no-pin");
    }

    public getFaberNumber(): string {
        return this.FaberNumber ?? translation.t("lfa-view.no-faber");
    }

    public getLfaDate(): string {
        return this.LfaExpireDate ? DateTime.parseShortNumberDate(DateTime.getDateTimeFromInput(this.LfaExpireDate)) : translation.t("lfa-view.no-date");
    }

    public toEntity(lfa: ILfa = new Lfa()): ILfa {
        super.toEntity(lfa);
        lfa.LfaNumber = this.LfaNumber;
        lfa.LfaExpireDate = this.LfaExpireDate;
        lfa.Pid = this.Pid;
        lfa.Pin = this.Pin;
        lfa.FaberNumber = this.FaberNumber;
        return lfa;
    }
}
