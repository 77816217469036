import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBasePersonContact {
    PersonId: number;
    MediaId: number;
    NameInMedia: string;
    IsPrefered: boolean;
}

export interface IPersonContactDto extends IBaseDto, IBasePersonContact {}
export interface IPersonContact extends IBaseEntity, IBasePersonContact {}

export default class PersonContact extends BaseEntity<IPersonContact, IPersonContactDto> implements IPersonContact {
    public static get EntityTypeId(): string {
        return "88d8f4e2-40e6-567a-92d7-303b969af34f";
    }

    public PersonId: number;
    public MediaId: number;
    public NameInMedia: string;
    public IsPrefered: boolean;

    public populateFromDb(dbItem: IPersonContact): void {
        super.populateFromDb(dbItem);
        this.PersonId = dbItem.PersonId;
        this.MediaId = dbItem.MediaId;
        this.NameInMedia = dbItem.NameInMedia;
        this.IsPrefered = this.getDbBoolean(dbItem.IsPrefered);
    }

    public populateFromOData(oDataItem: IPersonContactDto): void {
        super.populateFromOData(oDataItem);
        this.PersonId = oDataItem.PersonId;
        this.MediaId = oDataItem.MediaId;
        this.NameInMedia = oDataItem.NameInMedia;
        this.IsPrefered = oDataItem.IsPrefered;
    }

    public toODataObject(): IPersonContactDto {
        const oDataObject = super.toODataObject();
        oDataObject.PersonId = this.PersonId;
        oDataObject.MediaId = this.MediaId;
        oDataObject.NameInMedia = this.NameInMedia;
        oDataObject.IsPrefered = this.IsPrefered;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<PersonContact>(x => x.PersonId),
            this.PersonId
        );
        dbObject.set(
            nameof<PersonContact>(x => x.MediaId),
            this.MediaId
        );
        dbObject.set(
            nameof<PersonContact>(x => x.NameInMedia),
            this.NameInMedia
        );
        dbObject.set(
            nameof<PersonContact>(x => x.IsPrefered),
            this.IsPrefered
        );
        return dbObject;
    }
}
