import { Util } from "../modules/Util";
import { jobPositionService } from "../services/JobPositionService";
import { paymentWayService } from "../services/PaymentWayService";
import { personAddressService } from "../services/PersonAddressService";
import { userSettingsService } from "../services/UserSettingsService";
import { IJobPosition, IJobPositionDto, JobPosition } from "../types/JobPosition";
import { JobPricePosition } from "../types/JobPricePosition";
import { PaymentWayType } from "../types/PaymentWay";
import UserSettings from "../types/UserSettings";
import { BaseView } from "./BaseView";
import { personContactService } from "../services/PersonContactService";
import { personService } from "../services/PersonService";
import DateTime from "../modules/DateTime";
import Person from "../types/Person";
import PersonAddress from "../types/PersonAddress";
import { translation } from "../services/TranslationService";

export default class AccountLesson extends BaseView {
    private navPoint: string;
    private jobPositionIds: number[];
    private personHasMail: boolean;
    public jobPositions: JobPosition<IJobPosition, IJobPositionDto>[];
    public person: Person;
    public personAddress: PersonAddress;
    public usersettings: UserSettings;

    // VIEWDATA
    public avatarText: string;
    public personName: string;
    public personEmail: string;
    public totalPrice: string;
    public createBill: string;
    public noAddress: string;
    public positionCounter: number;
    public isPortrait: boolean;

    public initView(): void {
        $("#positionViewAccountLesson").show();
    }

    public async loadData(e: any): Promise<void> {
        this.set("positionCounter", 1);
        this.set("billingDate", DateTime.getInputDate(new Date()));
        this.jobPositionIds = JSON.parse(e.view.params.jobPositionIds);
        const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
        const personAddress = await personAddressService.getPersonAddress(this.personId);
        const personEmail = await personContactService.getPersonEmail(this.personId);
        let jobPositions = await jobPositionService.getItemsByIds(this.jobPositionIds);
        const usersettings = await userSettingsService.getSettings();
        this.set("paymentWays", await paymentWayService.getItems());
        this.set("selectedPaymentWay", (await paymentWayService.getLastSelectedPaymentWay()).Id);
        jobPositions = jobPositionService.sortPositionsAfterIds(jobPositions, this.jobPositionIds);
        this.set("jobPositions", jobPositions);
        this.set("usersettings", usersettings);
        this.set("personAddress", personAddress || null);
        this.set("noAddress", personAddress ? "" : `<i class="icons icon-danger largeIcon"></i>&nbsp;${translation.t("account-lesson.no-receipt-address")}`);

        this.set("avatarText", Util.getAvatar(person.FirstName, person.LastName));
        this.set("personName", `${person.FirstName} ${person.LastName}`);
        this.set("personEmail", personEmail ? personEmail.NameInMedia : `<i class="icons icon-danger largeIcon"></i>&nbsp;${translation.t("account-lesson.no-email-on-bill")}`);
        this.personHasMail = !!personEmail;

        let total = 0;
        jobPositions.forEach(position => {
            if (position instanceof JobPricePosition) {
                total += position.Amount * position.Price;
            }
        });
        this.set("totalPrice", Util.formatPrice(total));
    }

    public getDriveLessonsString = Amount => ` ${translation.t("account-lesson.account-lesson-name", { count: Amount })}`;

    public showView(e: any): void {
        $("#positionViewAccountLesson");
        this.navPoint = `${e.view.params.navPoint}`;
    }

    public setLastSelectedPaymentWay = () => {
        paymentWayService.setLastSelectedPaymentWayId(this.get<number>("selectedPaymentWay"));
    };

    private locked = false;
    public async accountLessons(): Promise<void> {
        if (this.locked) {
            return;
        }
        try {
            this.locked = true;
            app.mobileApp.showLoading();
            const paymentWayEntity = await paymentWayService.getItemById(this.get<number>("selectedPaymentWay"));
            const billingDate = DateTime.getDateTimeFromInput(this.get<string>("billingDate"));
            if (paymentWayEntity.Type !== PaymentWayType.Invoice) {
                await jobPositionService.accountPositions(Util.getEducationId(this.personId), this.personId, this.jobPositionIds, paymentWayEntity.Id, true, billingDate, billingDate, true);
                Util.showNotification(translation.t("account-lesson.successfull-entered"), "success");
                this.back();
            } else {
                await jobPositionService.accountPositions(Util.getEducationId(this.personId), this.personId, this.jobPositionIds, paymentWayEntity.Id, false, billingDate, null);
                const message = `${translation.t("account-lesson.bill-successfull")}${this.personHasMail ? `<br />${translation.t("account-lesson.send-bill-after-sync")}` : ""}`;
                Util.showNotification(message, "info");
                this.back();
            }
        } finally {
            this.locked = false;
        }
    }

    public getNavPoint(): string {
        return this.navPoint;
    }
}
