import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import Activity, { IActivity, IActivityDto } from "../types/Activity";
import { BaseNoHistoryService } from "./BaseNoHistoryService";

export default class ActivityService extends BaseNoHistoryService<IActivity, IActivityDto> {
    private readonly TABLE_NAME = "Activities";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IActivity>());
    }

    public getDtoFields() {
        return keys<IActivityDto>();
    }

    public getApiRoute(): string {
        return `${network.API}/${Activity.EntityTypeId}`;
    }

    protected createEntityFromDb(item: IActivity): Activity {
        const currencyEntity = new Activity();
        currencyEntity.populateFromDb(item);
        return currencyEntity;
    }

    protected createEntityFromOData(item: IActivityDto): Activity {
        const currencyEntity = new Activity();
        currencyEntity.populateFromOData(item);
        return currencyEntity;
    }
}
export const activityService = new ActivityService();
