export default class ChecklistPricingModel extends kendo.data.ObservableObject {
    private _checklistId: number;
    private _lessonDuration: number;
    private _pricePerLesson: number;

    get ChecklistId(): number {
        return this._checklistId;
    }

    set ChecklistId(val: number) {
        this._checklistId = val;
    }

    get LessonDuration(): number {
        return this._lessonDuration;
    }

    set LessonDuration(val: number) {
        this._lessonDuration = val;
    }

    get PricePerLesson(): number {
        return this._pricePerLesson;
    }

    set PricePerLesson(val: number) {
        this._pricePerLesson = val;
    }
}
