import { network } from "../modules/Network";

export default class FahrschuelerAppTokenService {
    private getOdataRoute(): string {
        return `${network.BASE_API}/OrphyDriveStudent/CreateOrphyDriveTokenForStudent?personId=`;
    }

    public getFahrschuelerAppToken = async (personId: number): Promise<string> => {
        const token = await network.get<string>(`${this.getOdataRoute()}${personId}`);
        return token;
    };
}

export const fahrschuelerAppTokenService = new FahrschuelerAppTokenService();
