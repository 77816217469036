import { dal } from "../dal/Dal";
import { network } from "../modules/Network";
import Log, { ILogDto, ILogEntity } from "../types/Log";
import { BaseService } from "./BaseService";
import { keys } from "ts-transformer-keys";

export default class LoggerService extends BaseService<ILogEntity, ILogDto> {
    private readonly TABLE_NAME = "Logs";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ILogDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ILogEntity>());
    }

    public getApiRoute(): string {
        return `${network.ODATA_API_V3}/writelog`;
    }

    protected createEntityFromOData(item: ILogDto): ILogEntity {
        const entity = new Log();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: ILogEntity): ILogEntity {
        const entity = new Log();
        entity.populateFromDb(item);
        return entity;
    }

    public async pullItems(): Promise<void> {
        // dont' want to pull
    }

    public async pushItems(): Promise<number> {
        const logs = await this.getNewItems();
        for (const log of logs) {
            if (log.LogLevel === "error") {
                await network.send("POST", this.getApiRoute(), log.toODataObject());
            }
            log.IsNew = false;
            await this.updateEntity(log);
        }
        return 0; // Return zero because we don't need to pull/push again (see syncservice logic)
    }

    public hardDeleteLastEntry = async (num: number): Promise<number> => {
        const sql = `DELETE FROM ${this.getTableName()} WHERE Id = (SELECT MIN(Id) FROM ${this.getTableName()})`;
        await dal.execute(sql);
        return num;
    };

    public getNewItems(): Promise<ILogEntity[]> {
        return this.getItems("IsNew = ?", null, null, null, null, [true]);
    }

    protected processItem = async (item: Log): Promise<number> => {
        await network.send("POST", this.getApiRoute(), item.toODataObject());
        await this.updateLogItem(item.Id);
        return 0; // On error, don't fail but leave IsDirty untouched, we will try to push this entry again next time
    };

    public hardDeleteAll = async (): Promise<void> => {
        await dal.execute(`DELETE FROM ${this.getTableName()}`);
    };

    private updateLogItem = async (id: number): Promise<void> => {
        await this.update("Id", id, new Map<string, any>(), false); // Reset IsDirty
    };

    public async patchItems(): Promise<number> {
        return 0;
    }
}

export const loggerService = new LoggerService();
