import { Util } from "../modules/Util";
import AddressType, { AddressKind, IAddressType, IAddressTypeDto } from "../types/AddressTypeEntity";
import { network } from "../modules/Network";
import { BaseNoHistoryService } from "./BaseNoHistoryService";
import { keys } from "ts-transformer-keys";

export default class AddressTypeService extends BaseNoHistoryService<IAddressType, IAddressTypeDto> {
    private TABLE_NAME = "AddressTypes";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IAddressType>());
    }

    public getDtoFields() {
        return keys<IAddressTypeDto>();
    }

    public getApiRoute(): string {
        return `${network.API}/${AddressType.EntityTypeId}`;
    }

    protected createEntityFromDb(item: IAddressType): AddressType {
        const addressTypeEntity = new AddressType();
        addressTypeEntity.populateFromDb(item);
        return addressTypeEntity;
    }

    protected createEntityFromOData(item: IAddressTypeDto): AddressType {
        const addressTypeEntity = new AddressType();
        addressTypeEntity.populateFromOData(item);
        return addressTypeEntity;
    }

    public async getDefaultAddressTypeId(): Promise<number> {
        return (await this.getDefaultAddressType()).Id;
    }

    public getDefaultAddressType = async (): Promise<IAddressType> => {
        const items = await this.getItems(`Type = ${AddressKind.Address}`, null, 1);
        return Util.firstOrDefault(items);
    };
}

export const addressTypeService = new AddressTypeService();
