import { IJobBase, IJobBaseDto } from "../types/JobBase";
import { BaseService } from "./BaseService";
import { billService } from "./BillService";
import { jobPositionService } from "./JobPositionService";
import { jobService } from "./JobService";

export abstract class JobBaseService<TEntity extends IJobBase, TDto extends IJobBaseDto> extends BaseService<TEntity, TDto> {
    protected getIdStatement(): string {
        return `SELECT MIN((SELECT MIN(0, COALESCE(MIN(Id), 0)) - 1 FROM ${billService.getTableName()}), (SELECT MIN(0, COALESCE(MIN(Id), 0)) - 1 FROM ${jobService.getTableName()}), (SELECT MIN(0, COALESCE(MIN(Id), 0)) - 1 FROM ${jobPositionService.getTableName()})) AS Id`;
    }
}
