import { translation } from "../services/TranslationService";
import { Util } from "./Util";

export default class DateTime {
    static readonly day: string[] = ["time-date.sunday", "time-date.monday", "time-date.tuesday", "time-date.wednesday", "time-date.thursday", "time-date.friday", "time-date.saturday"];
    static readonly month: string[] = [
        "time-date.january",
        "time-date.february",
        "time-date.march",
        "time-date.april",
        "time-date.may",
        "time-date.june",
        "time-date.july",
        "time-date.august",
        "time-date.september",
        "time-date.october",
        "time-date.november",
        "time-date.december"
    ];
    static readonly TICK_CONVERT = 10_000;

    /**
     * Returns the time: eg. 12:00
     * @param {Date}
     * @return {string}
     */
    static getTime(date: Date): string {
        return `${DateTime.needsLeadingZero(date.getHours())}:${DateTime.needsLeadingZero(date.getMinutes())}`;
    }

    static getFormattedHoursFromMinutes(minutes: number): string {
        return `${Math.floor(minutes / 60)}:${DateTime.needsLeadingZero(minutes % 60)}`;
    }

    /**
     * Returns a date: eg. 04.09.2017
     * @param {Date}
     * @return {string}
     */
    static parseNumberDate(date: Date): string {
        return `${DateTime.needsLeadingZero(date.getDate())}.${DateTime.needsLeadingZero(date.getMonth() + 1)}.${date.getFullYear()}`;
    }

    /**
     * Returns a date with time: eg. 04.09.2017, 11:23
     * @param {Date}
     * @return {string}
     */
    static parseNumberDateTime(date: Date): string {
        return `${DateTime.parseNumberDate(date)}, ${DateTime.getTime(date)}`;
    }

    /**
     * Returns a date: eg. 04.09.17
     * @param {Date}
     * @return {string}
     */
    static parseShortNumberDate(date: Date): string {
        return `${DateTime.needsLeadingZero(date.getDate())}.${DateTime.needsLeadingZero(date.getMonth() + 1)}.${date.getFullYear().toString().substr(2, 4)}`;
    }

    /**
     * Returns a date: eg. 04.09
     * @param {Date}
     * @return {string}
     */
    static parseShortNumberDateWithoutYear(date: Date): string {
        return `${DateTime.needsLeadingZero(date.getDate())}.${DateTime.needsLeadingZero(date.getMonth() + 1)}`;
    }

    /**
     * Returns a date: eg. 04
     * @param {Date}
     * @return {string}
     */
    static parseShortNumberDateOnlyDay(date: Date): string {
        return DateTime.needsLeadingZero(date.getDate());
    }

    /**
     * Returns a date: eg. Montag 04. September 2017
     * @param {Date}
     * @return {string}
     */
    static parseStringDate(date: Date): string {
        return `${DateTime.getDay(date)} ${DateTime.needsLeadingZero(date.getDate())}. ${DateTime.getMonth(date)} ${date.getFullYear()}`;
    }

    /**
     * Returns a date: eg. Montag 04. September 2017, 11:27
     * @param {Date}
     * @return {string}
     */
    static parseStringDateTime(date: Date): string {
        return `${DateTime.parseStringDate(date)}, ${DateTime.getTime(date)}`;
    }

    /**
     * Returns a date: eg. September 2017
     * @param {Date}
     * @return {string}
     */
    static getMonthAndYear(date: Date): string {
        return `${translation.t(DateTime.month[date.getMonth()])} ${date.getFullYear()}`;
    }

    /**
     * Returns the input number with a leading zero if the number is a single digit
     * @param {number}
     * @return {string}
     */
    static needsLeadingZero(numberToCheck: number): string {
        return numberToCheck.toString().length === 1 ? `0${numberToCheck}` : numberToCheck.toString();
    }

    /**
     * Returns the name of the day of a date
     * @param {number}
     * @return {string}
     */
    static getDay(date: Date): string {
        return translation.t(DateTime.day[date.getDay()]);
    }

    /**
     * Returns the name the month of a date
     * @param {number}
     * @return {string}
     */
    static getMonth(date: Date): string {
        return translation.t(DateTime.month[date.getMonth()]);
    }

    static getNameOfMonth(monthNumber: number): string {
        return translation.t(DateTime.month[monthNumber - 1]);
    }

    static getInputDate(date: Date): string {
        if (!Number.isNaN(date.valueOf())) {
            return `${date.getFullYear()}-${DateTime.needsLeadingZero(date.getMonth() + 1)}-${DateTime.needsLeadingZero(date.getDate())}`;
        } else {
            return "";
        }
    }

    static getDateTimeForInput(date: Date): string {
        if (!Number.isNaN(date.valueOf())) {
            return `${DateTime.getInputDate(date)}T${DateTime.needsLeadingZero(date.getHours())}:${DateTime.needsLeadingZero(date.getMinutes())}`;
        } else {
            return "";
        }
    }

    static getDateTimeFromInput(dateString: string): Date {
        if (typeof dateString === "object") {
            return new Date(dateString);
        }

        let dateResult = new Date();
        if (dateString) {
            if (Util.isiOS()) {
                if (dateString.lastIndexOf(".") > 0) {
                    dateResult = new Date(dateString.substr(0, dateString.lastIndexOf(".")).split("T").join(" ").split("-").join("/"));
                } else {
                    dateResult = new Date(dateString.split("T").join(" ").split("-").join("/"));
                }
            } else {
                dateResult = new Date(dateString.split("T").join(" "));
            }
        }

        if (Number.isNaN(dateResult.valueOf())) {
            dateResult = new Date();
        }

        return dateResult;
    }

    static getLessonCount(durationTicks: number): string {
        const hour = DateTime.getHoursFromTicks(durationTicks);
        const decimalHours = Math.floor(hour);
        let decimalMinutes;
        if (decimalHours > 0) {
            decimalMinutes = Math.round((hour % decimalHours) * 60);
            if (decimalMinutes === 0) {
                return `${decimalHours} ${translation.t("time-date.short-sign-hours")}`;
            } else {
                return `${decimalHours} ${translation.t("time-date.short-sign-hours")} ${decimalMinutes} ${translation.t("time-date.short-sign-minutes")}`;
            }
        } else {
            decimalMinutes = Math.round(hour * 60);
            return `${decimalMinutes} ${translation.t("time-date.short-sign-minutes")}`;
        }
    }

    static getHoursFromTicks(ticks: number): number {
        return DateTime.getMinutesFromTicks(ticks) / 60;
    }

    static getMinutesFromTicks(ticks: number): number {
        return ticks / 600_000_000;
    }

    static getEndTime(issueDate: Date, durationTicks: number): Date {
        return new Date(issueDate.getTime() + durationTicks / DateTime.TICK_CONVERT);
    }

    static getEndDate(issueDate: Date, durationTicks: number): Date {
        const end = new Date(issueDate.toISOString());
        end.setMilliseconds(end.getMilliseconds() + durationTicks / DateTime.TICK_CONVERT);
        return end;
    }

    /**
     * Adapted and fixed from (site just available over waybackmachine https://archive.org/web/) http://www.merlyn.demon.co.uk/weekcalc.htm#JS
     * Beause the JS Date object does not provide that functionality
     * @param {date} date the date you need the weeknumber
     * @returns {number} WeekNumber
     *
     * */
    static getCalendarWeek(date: Date): number {
        const checkDate = new Date(date.toISOString());
        checkDate.setHours(0);
        checkDate.setMinutes(0);
        let day = checkDate.getDay();
        if (day === 0) {
            day = 7;
        }
        checkDate.setDate(checkDate.getDate() + (4 - day));
        const year = checkDate.getFullYear();
        const ZBDoCY = Math.floor((checkDate.getTime() - new Date(year, 0, 1, -6).getTime()) / 864e5);
        const WN = 1 + Math.floor(ZBDoCY / 7);
        return WN;
    }

    static isSameDay(date_1: Date, date_2: Date): boolean {
        return date_1.getDate() === date_2.getDate() && date_1.getMonth() === date_2.getMonth() && date_1.getFullYear() === date_2.getFullYear();
    }

    static isSameMonth(date_1: Date, date_2: Date): boolean {
        return date_1.getMonth() === date_2.getMonth() && date_1.getFullYear() === date_2.getFullYear();
    }

    static isSameWeek(date_1: Date, date_2: Date): boolean {
        return DateTime.getCalendarWeek(date_1) === DateTime.getCalendarWeek(date_2) && (date_1.getFullYear() === date_2.getFullYear() || Math.abs(DateTime.dayDiff(date_1, date_2)) <= 7);
    }

    static dayDiff(date_1: Date, date_2: Date): number {
        const diff = date_2.getTime() - date_1.getTime();
        return diff / 1000 / 60 / 60 / 24;
    }

    static isNewWeek(date_1: Date, date_2: Date): boolean {
        return !DateTime.isSameWeek(date_1, date_2);
    }

    static isNewMonth(date_1: Date, date_2: Date): boolean {
        return !DateTime.isSameMonth(date_1, date_2);
    }

    static getTicks(issueDate: Date, end: Date): number {
        const durationTicks = end;
        durationTicks.setMilliseconds(0);
        return (durationTicks.getTime() - issueDate.getTime()) * DateTime.TICK_CONVERT;
    }

    static today(): Date {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }

    static fromDbDateTime(dbDate: string | Date): Date {
        if (dbDate) {
            return new Date(dbDate);
        } else {
            return null;
        }
    }

    static toDbDateTime(date: Date): string {
        if (date) {
            return date.toISOString();
        } else {
            return null;
        }
    }

    static fromODataDateTime(oDataDate: string): Date {
        if (oDataDate) {
            return new Date(oDataDate);
        } else {
            return null;
        }
    }

    static toODataDateTime(date: Date): string {
        if (date) {
            return date.toISOString();
        } else {
            return null;
        }
    }

    static fromDbDate(dbDate: string): Date {
        if (dbDate) {
            return DateTime.convertToLocalDate(new Date(dbDate));
        } else {
            return null;
        }
    }

    static toDbDate(date: Date): string {
        if (date) {
            return DateTime.convertToUtcDate(date).toISOString();
        } else {
            return null;
        }
    }

    static fromODataDate(oDataDate: string): Date {
        if (oDataDate) {
            return DateTime.convertToLocalDate(new Date(oDataDate));
        } else {
            return null;
        }
    }

    static toODataDate(date: Date): string {
        if (date) {
            return DateTime.convertToUtcDate(date).toISOString();
        } else {
            return null;
        }
    }

    static convertToUtcDate(date: Date): Date {
        if (date) {
            const newDate = new Date(date.getTime());
            newDate.setUTCFullYear(date.getFullYear());
            newDate.setUTCMonth(date.getMonth(), date.getDate());
            newDate.setUTCHours(0);
            newDate.setUTCMinutes(0);
            newDate.setUTCSeconds(0);
            newDate.setUTCMilliseconds(0);
            return newDate;
        } else {
            return null;
        }
    }

    static convertToLocalDate(date: Date): Date {
        if (date) {
            const newDate = new Date(date.getTime());
            newDate.setFullYear(date.getUTCFullYear());
            newDate.setMonth(date.getUTCMonth());
            newDate.setDate(date.getUTCDate());
            newDate.setHours(0);
            newDate.setMinutes(0);
            newDate.setSeconds(0);
            newDate.setMilliseconds(0);
            return newDate;
        } else {
            return null;
        }
    }

    static getModifiedSinceRequestDate(lastSync: string): string {
        return (lastSync || new Date(null).toISOString()).split(":").join("_").split(".").join("-");
    }

    static getTrackedTime(issueDate: Date, durationTicks: number): number {
        const end = DateTime.getEndDate(issueDate, durationTicks);
        return end.getHours() - issueDate.getHours() + DateTime.getMinutes(issueDate, durationTicks);
    }

    static getMinutes(issueDate: Date, durationTicks: number): number {
        return (DateTime.getEndDate(issueDate, durationTicks).getMinutes() - issueDate.getMinutes()) / 60;
    }

    static getStartOfWeek(): Date {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        // getDay returns 0 - 6, Sun - Sat => Start of week is Monday
        date.setDate(date.getDate() - new Date().getDay() + 1);
        return date;
    }

    static getStartOfMonth(): Date {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date.setDate(1);
        return date;
    }

    /**
     * Returns if two dates are exactly equals
     * @param {Date, Date}
     * @return {boolean}
     */
    static equals(date_1: Date, date_2: Date): boolean {
        if (!date_1 && !date_2) {
            return date_1 === date_2;
        } else if ((!date_1 && date_2) || (date_1 && !date_2)) {
            return false;
        }
        return date_1.getTime() === date_2.getTime();
    }
}
