import TimeTrackingModel from "./TimeTrackingModel";
import TimeTrackingTotalModel from "./TimeTrackingTotalModel";

export default class TimeTrackingGroupModel extends kendo.data.ObservableObject {
    public TimeTrackingItems: TimeTrackingModel[] = [];
    public HeaderName: string;
    public DailyTotal: TimeTrackingTotalModel = new TimeTrackingTotalModel();
    public WeeklyTotal: TimeTrackingTotalModel = new TimeTrackingTotalModel();
    public MonthlyTotal: TimeTrackingTotalModel = new TimeTrackingTotalModel();
    public IssueDate: Date;

    public get Sort(): number {
        return this.IssueDate.getTime();
    }
}
