import { keys } from "ts-transformer-keys";
import { Util } from "../modules/Util";
import LocalData, { ILocalData, ILocalDataDto } from "../types/LocalData";
import { BaseService } from "./BaseService";

export default class LocalDataService extends BaseService<ILocalData, ILocalDataDto> {
    private readonly TABLE_NAME = "localdata";
    private readonly ODATA_ROUTE = null;

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ILocalDataDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ILocalData>());
    }

    public getApiRoute(): string {
        return this.ODATA_ROUTE;
    }

    protected createEntityFromOData(item: ILocalDataDto): ILocalData {
        const entity = new LocalData();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: ILocalData): ILocalData {
        const entity = new LocalData();
        entity.populateFromDb(item);
        return entity;
    }

    public updateLocalData = async (updateObject: Map<string, any>): Promise<void> => {
        await this.updateWhere(null, updateObject);
    };

    public getLocalData = async (): Promise<ILocalData> => {
        const localData = await this.getItems();
        return Util.firstOrDefault(localData);
    };

    public async pushItems(): Promise<number> {
        return 0;
    }

    public async pullItems(): Promise<void> {
        // don't want to pull
    }

    public async patchItems(): Promise<number> {
        return 0;
    }
}

export const localDataService = new LocalDataService();
