import { nameof } from "ts-simple-nameof";
import { navParams } from "../modules/NavParams";
import { Util } from "../modules/Util";
import { personService } from "../services/PersonService";
import { BaseView } from "./BaseView";
import RegisterPerson, { RegisterPersonNavParams } from "./RegisterPerson";
import AllPersonExport from "../export/AllPersonExport";
import { logger } from "../modules/Logger";
import Popup from "../modules/Popup";
import ConfirmContent from "../modules/ConfirmContent";
import AllPersonItemModel from "../models/AllPersonItemModel";
import { translation } from "../services/TranslationService";
import { kendoEvent } from "../typings/kendoEvent";

export default class AllPersons extends BaseView {
    public searchFilter = "";
    public showInactive: boolean;
    public backButtonVisible: boolean;
    public backButtonText: string;
    public hasPersonEntries: boolean;

    private allPersons: kendo.data.DataSource;
    private navPoint: string;

    private name: string;
    private textMessagePopup: Popup;
    private readonly PAGE_SIZE = 20;

    constructor() {
        super();
        this.textMessagePopup = new Popup();
        this.set("hasPersonEntries", false);
        super.init(this);
    }

    private createDataSource(): kendo.data.DataSource {
        return kendo.data.DataSource.create({
            pageSize: this.PAGE_SIZE,
            serverPaging: true,
            schema: {
                data: "data",
                total: "total"
            },
            transport: {
                read: async options => {
                    const showInactive = this.get<boolean>("showInactive");
                    const model = await personService.getAllPersonModels(showInactive, this.searchFilter, options.data.take, options.data.skip);
                    options.success({
                        data: model.AllPersons,
                        total: model.TotalCount
                    });
                }
            } as any
        } as kendo.data.DataSourceOptions);
    }

    public initView(): void {
        this.set("showInactive", false);
        $("#listviewAllpersons").show();
    }

    public async loadData(): Promise<void> {
        this.set("hasPersonEntries", await personService.hasPersonEntries());

        this.set("allPersons", this.createDataSource());

        if (this.personId) {
            const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
            if (person) {
                this.name = `${person.FirstName} ${person.LastName}`;
                this.set("backButtonVisible", true);
            } else {
                this.set("backButtonVisible", false);
            }
        }
    }

    public showView(): void {
        this.set("backButtonVisible", !!this.personId);
        this.set("backButtonText", this.name);
        this.navPoint = `views/personDetail.html?resetTab=true`;
    }

    public search = (e: Event) => {
        this.searchFilter = $(e.target).val();
        this.setFilter();
    };

    private setFilter = () => {
        this.set("allPersons", this.createDataSource());
    };

    public openTextMessagePopup = (e): void => {
        const person = this.allPersons.getByUid(e.data.uid) as any;
        const popup = $("#allPersonTextMessagePopup");
        let popupContent = "";
        if (person.PhoneNumber) {
            popupContent += `<a href="sms:${person.PhoneNumber}">sms</a>`;
        }
        if (person.Email) {
            const emailAppend = `<a href="mailto:${person.Email}">Email</a>`;
            popupContent += popupContent.length ? `<br >${emailAppend}` : emailAppend;
        }
        popup.html(popupContent);
        this.textMessagePopup.renderFixedPositionPopup("#allPersonTextMessagePopup", e, true, false);
    };

    public exportAllStudents = async () => {
        await new AllPersonExport().export(this.get<kendo.data.DataSource>("allPersons").data());
    };

    public createNewPerson = (): void => {
        const params: RegisterPersonNavParams = {
            NavPoint: "views/allPersons.html",
            Update: false
        };
        navParams.setNavParam(nameof(RegisterPerson), params);
        app.mobileApp.navigate("views/registerPerson.html");
    };

    public changeView = async (e: kendoEvent<AllPersonItemModel>) => {
        if (!$(e.target).is(":button") && $(e.target).attr("class") !== "icons icon-phone") {
            if (!e.data.IsInactive) {
                e.preventDefault();
                await this.navigate(e.data.Id);
            } else {
                await this.activateStudent(e.data);
            }
        }
    };

    public activateStudent = async (person: AllPersonItemModel): Promise<void> => {
        const activateStudent = (
            await Util.confirmTemplate(ConfirmContent.reactivateStudent(person.FirstName, person.LastName), translation.t("all-persons.yes-activate"), translation.t("common.cancel"))
        ).confirmed;
        if (activateStudent) {
            $(".syncreen #syncMessage").html(`${translation.t("all-persons.activate")} ${person.FirstName} ${person.LastName}`);
            $("#syncScreen").data().kendoMobileModalView.open();

            try {
                await personService.reactivatePerson(person.Id);
                $("#syncScreen").data().kendoMobileModalView.close();
                await this.navigate(person.Id);
            } catch (e) {
                logger.logError(e);
                $("#syncScreen").data().kendoMobileModalView.close();
                Util.showNotification(translation.t("util.check-internet"), "error");
            }
        }
    };

    private navigate = async (personId: number) => {
        Util.setSelectedPersonId(personId);
        app.mobileApp.navigate("views/personDetail.html?resetTab=true");
    };

    public getNavPoint(): string {
        return this.navPoint;
    }

    public back(e) {
        if (this.personId) {
            app.mobileApp.navigate(this.getNavPoint());
        } else {
            e.preventDefault();
        }
    }

    public hideView(): void {
        this.textMessagePopup.destroy();
    }
}
