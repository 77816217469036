import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import CompanySettings, { ICompanySettings, ICompanySettingsDto } from "../types/CompanySettings";
import { SettingsService } from "./SettingsService";

export default class CompanySettingsService extends SettingsService<ICompanySettings, ICompanySettingsDto> {
    private readonly TABLE_NAME = "Companysettings";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ICompanySettingsDto>().filter(x => x !== "Id");
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ICompanySettings>());
    }

    public getApiRoute(): string {
        return `${network.ODATA_API_V3}/companysettings`;
    }

    protected createEntityFromOData(item: ICompanySettingsDto): ICompanySettings {
        const entity = new CompanySettings();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: ICompanySettings): ICompanySettings {
        const entity = new CompanySettings();
        entity.populateFromDb(item);
        return entity;
    }

    public async getSettings(): Promise<ICompanySettings> {
        const companysettings = await this.getItems();
        return companysettings[0];
    }

    public async updateSettings(updateObject: Map<string, any>, isDirty: boolean = true): Promise<void> {
        await this.updateWhere(null, updateObject, isDirty);
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE IsDirty = ?`;
    }
}

export const companySettingsService = new CompanySettingsService();
