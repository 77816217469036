import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseDrivingStudentDocument {
    PersonId: number;
    BlobId: number;
    Image: string;
}

export interface IDrivingStudentDocumentDto extends IBaseDto, IBaseDrivingStudentDocument {}

export interface IDrivingStudentDocument extends IBaseEntity, IBaseDrivingStudentDocument {}

export interface IDrivingStudentDocumentUploadResponseDto {
    title: string;
    urL: string;
    filename: string;
    id: string;
    groupId: string;
    thumb: string;
}

export interface IAttachementInfoDto {
    title: string;
    url: string;
    fileName: string;
    id: string;
    thumb: string;
    documentGroups: IDmsDocumentGroup[];
    metaData: MetaData;
}

export interface MetaData {
    ContentType: string;
}

export interface IDmsDocumentGroup {
    id: string;
    name: string;
}

export default class DrivingStudentDocument extends BaseEntity<IDrivingStudentDocument, IDrivingStudentDocumentDto> implements IDrivingStudentDocument {
    public static GROUP_ID = "ECEA81DE-EB87-4ABB-9CBC-EBE4147F4068";
    public PersonId: number;
    public BlobId: number;
    public Image: string;

    public populateFromDb(item: IDrivingStudentDocument): void {
        super.populateFromDb(item);
        this.PersonId = item.PersonId;
        this.BlobId = item.BlobId;
        this.Image = item.Image;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public populateFromOData(oDataItem: IDrivingStudentDocumentDto): void {}

    public toODataObject(): IDrivingStudentDocumentDto {
        return {} as IDrivingStudentDocumentDto;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<DrivingStudentDocument>(x => x.PersonId),
            this.PersonId
        );
        dbObject.set(
            nameof<DrivingStudentDocument>(x => x.BlobId),
            this.BlobId
        );
        dbObject.set(
            nameof<DrivingStudentDocument>(x => x.Image),
            this.Image
        );
        return dbObject;
    }
}
