import JobDefaultPosition, { IJobDefaultPosition, IJobDefaultPositionDto } from "./JobDefaultPosition";

export interface IBaseJobProductPosition {
    ProductId: number;
}

export interface IJobProductPositionDto extends IBaseJobProductPosition, IJobDefaultPositionDto {}
export interface IJobProductPosition extends IBaseJobProductPosition, IJobDefaultPosition {}

export default class JobProductPosition extends JobDefaultPosition<IJobProductPosition, IJobProductPositionDto> implements IJobProductPosition {
    public static TYPE_NAME = "JobProductPosition";
    public static ODATA_TYPE_NAME = "#SharedDto.PublicApi.JobProductPositionDtoV2";

    public odataTypeName(): string {
        return JobProductPosition.ODATA_TYPE_NAME;
    }

    public get Discriminator(): string {
        return JobProductPosition.TYPE_NAME;
    }

    public ProductId: number;

    public populateFromDb(dbItem: IJobProductPosition): void {
        super.populateFromDb(dbItem);
        this.ProductId = dbItem.ProductId;
    }

    public populateFromOData(oDataItem: IJobProductPositionDto): void {
        super.populateFromOData(oDataItem);
        this.ProductId = oDataItem.ProductId;
    }

    public toODataObject(): IJobProductPositionDto {
        const oDataObject = super.toODataObject();
        oDataObject.ProductId = this.ProductId;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set("ProductId", this.ProductId);
        return dbObject;
    }

    public createChargingPosition(parentId: number, amount: number = null): JobProductPosition {
        const chargingPosition = new JobProductPosition();
        chargingPosition.copyFrom(this); // Copy common data
        chargingPosition.Amount = amount !== null ? amount : this.Amount;
        chargingPosition.SortOrder = 0;
        chargingPosition.ParentId = parentId;
        chargingPosition.ChargedPositionId = this.Id;
        return chargingPosition;
    }

    protected copyFrom(entity: IJobProductPosition): void {
        super.copyFrom(entity);
        this.ProductId = entity.ProductId;
    }
}
