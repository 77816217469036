import { keys } from "ts-transformer-keys";
import CompanyAppSettings, { ICompanyAppSettings, ICompanyAppSettingsDto } from "../types/CompanyAppSettings";
import { AppSettingsService } from "./AppSettingsService";
import { educationService } from "./EducationService";

export default class CompanyAppSettingsService extends AppSettingsService<ICompanyAppSettings, ICompanyAppSettingsDto> {
    private readonly TABLE_NAME = "companyappsettings";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ICompanyAppSettingsDto>().filter(x => x !== "Id");
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ICompanyAppSettings>());
    }

    protected getAppSettingsKey(): string {
        return CompanyAppSettings.getCompanyAppSettingsKey();
    }

    public async getSettings(): Promise<ICompanyAppSettings> {
        const appUserSettings = await this.getItems();
        return appUserSettings.length > 0 ? appUserSettings[0] : null;
    }

    public async updateSettings(updateObject: Map<string, any>, isDirty: boolean = true): Promise<void> {
        await this.updateWhere(null, updateObject, isDirty);
    }

    protected createEntityFromOData(item: ICompanyAppSettingsDto): ICompanyAppSettings {
        const entity = new CompanyAppSettings();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: ICompanyAppSettings): ICompanyAppSettings {
        const entity = new CompanyAppSettings();
        entity.populateFromDb(item);
        return entity;
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE IsDirty = ?`;
    }

    public async getChecklistPricing(educationId: number, personId: number): Promise<{ LessonDuration: number; PricePerLesson: number }> {
        const settings = await this.getSettings();
        const education = await educationService.getSelectedEducation(educationId, personId);

        const pricing = settings.ChecklistPricing.find(x => x.ChecklistId === education.ChecklistCollectionId);
        return pricing
            ? {
                  LessonDuration: pricing.LessonDuration !== undefined && pricing.LessonDuration !== null ? pricing.LessonDuration : settings.LessonDuration,
                  PricePerLesson: pricing.PricePerLesson !== undefined && pricing.PricePerLesson !== null ? pricing.PricePerLesson : settings.PricePerLesson
              }
            : { LessonDuration: settings.LessonDuration, PricePerLesson: settings.PricePerLesson };
    }

    public createDbUpdateObject(oDataUpdateItem: any): Map<string, any> {
        const updateObject = new Map<string, any>();
        for (const [key, value] of Object.entries(oDataUpdateItem)) {
            if ((key === "PlannedLessons" || key === "ChecklistPricing" || key === "RatingLabels" || key === "WeeklyWorkingHours") && typeof value !== "string") {
                updateObject.set(key, JSON.stringify(value));
            } else if (this.getDtoFields().some(x => x === key)) {
                updateObject.set(key, value);
            }
        }
        return updateObject;
    }
}

export const companyAppSettingsService = new CompanyAppSettingsService();
