import { logger } from "../modules/Logger";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { IPersonDto } from "../types/Person";
import { billService } from "./BillService";
import { ratingService } from "./ChecklistRatingService";
import { drivingStudentDocumentService } from "./DrivingStudentDocumentService";
import { drivingTestService } from "./DrivingTestService";
import { educationService } from "./EducationService";
import { jobPositionService } from "./JobPositionService";
import { jobService } from "./JobService";
import { lessonService } from "./LessonService";
import { lfaService } from "./LfaService";
import { orphyDriveJobPositionService } from "./OrphyDriveJobPositionService";
import { personService } from "./PersonService";
import { reactivateService } from "./ReactivateService";
import { testService } from "./TestService";
import { timeTrackingService } from "./TimeTrackingService";

export default class CleanupService {
    public cleanUp = async (): Promise<void> => {
        await this.cleanUpPersons();
        await this.checkMissingItems();
    };

    private async cleanUpPersons(): Promise<void> {
        const persons = await personService.gatherInactivePersons();

        if (persons.length <= 0) {
            return;
        }

        if (persons.some(p => p.Id === Util.getSelectedPersonId())) {
            Util.setSelectedPersonId(null);
        }

        try {
            const personIdFilter = `PersonId IN ${Util.joinIds(persons.map(x => x.Id))}`;
            const educations = await educationService.getItems(personIdFilter);
            const educationFilter = `Id IN ${Util.joinIds(educations.map(x => x.Id))}`;

            const jobs = await jobService.getItems(personIdFilter, null, null, "Id,PersonId");
            const bills = await billService.getItems(personIdFilter, null, null, "Id,PersonId");

            const drivingTests = await drivingTestService.getItems(educationFilter, null, null, "Id");
            await testService.hardDeleteByCondidtion(`DrivingTestId IN ${Util.joinIds(drivingTests.map(x => x.Id))}`);

            await lfaService.hardDeleteByCondidtion(educationFilter);
            await drivingTestService.hardDeleteByCondidtion(educationFilter);
            await educationService.hardDeleteByCondidtion(educationFilter);

            const lessons = await lessonService.getItems(`EducationId IN ${Util.joinIds(educations.map(x => x.Id))}`, null, null, "Id");
            await ratingService.hardDeleteByCondidtion(`LessonId IN ${Util.joinIds(lessons.map(x => x.Id))}`);
            await lessonService.hardDeleteByCondidtion(`Id IN ${Util.joinIds(lessons.map(x => x.Id))}`);

            await timeTrackingService.hardDeleteByCondidtion(personIdFilter);

            await this.deleteJobPositions(jobs.map(j => j.Id).concat(bills.map(b => b.Id)));

            await jobService.hardDeleteByCondidtion(personIdFilter);
            await billService.hardDeleteByCondidtion(personIdFilter);
            await drivingStudentDocumentService.hardDeleteByCondidtion(personIdFilter);
        } catch (e) {
            logger.logError(e);
        }
    }

    private async checkMissingItems(): Promise<void> {
        const jobs = await jobService.getItemsIncludingDeleted(null, null, null, "Id");
        const persons = await personService.getItemsIncludingDeleted(null, null, null, "Id");

        const educations = await educationService.getItems();

        const educationsWithMissingJobs = educations.filter(x => x.JobId).filter(x => !jobs.some(j => j.Id === x.JobId));
        const educationsWithMissingPersons = educations.filter(x => x.PersonId).filter(x => !persons.some(p => p.Id === x.PersonId));

        for (const missingJob of educationsWithMissingJobs) {
            try {
                await reactivateService.getJobs(`?$filter=PersonId eq ${missingJob.PersonId}`);
                await reactivateService.getBills(`?$filter=PersonId eq ${missingJob.PersonId}`);
                await reactivateService.getJobpositions(missingJob.PersonId);
            } catch (e) {
                logger.logError(new Error(`Could not fetch all data for jobid ${missingJob.JobId}`));
            }
        }
        for (const missingPerson of educationsWithMissingPersons) {
            try {
                const missingPersonsDto = await network.getItems<IPersonDto[]>(`${personService.getApiRoute()}?$filter=Id eq ${missingPerson.PersonId}`);
                const missingPersonsMapped = missingPersonsDto.map(pd => personService.createEntityFromOData(pd));
                for (const missingPersonMapped of missingPersonsMapped) {
                    await personService.insert(missingPersonMapped);
                }
                await reactivateService.reactivate(missingPerson.PersonId);
            } catch (e) {
                logger.logError(new Error(`Could not fetch all data for person ${missingPerson.PersonId}`));
            }
        }
    }

    private deleteJobPositions = async (jobBaseIds: number[]): Promise<void> => {
        if (jobBaseIds.length <= 0) {
            return;
        }
        const jobIds = jobBaseIds.join(",");

        const jobPositions = await jobPositionService.getItems(`ParentId IN (${jobIds}) OR Id IN (${jobIds})`, null, null, "Id,Discriminator");
        await jobPositionService.hardDeleteByCondidtion(`Id IN ${Util.joinIds(jobPositions.map(r => r.Id))}`);
        await orphyDriveJobPositionService.hardDeleteByCondidtion(`JobPositionId IN ${Util.joinIds(jobPositions.map(r => r.Id))}`);
    };
}

export const cleanupService = new CleanupService();
