import DateTime from "../modules/DateTime";
import { Util } from "../modules/Util";
import { drivingTestService } from "../services/DrivingTestService";
import DrivingTest, { IDrivingTest } from "../types/DrivingTest";
import BaseModel from "./BaseModel";

export default class DrivingTestModel extends BaseModel {
    public Vku1: boolean;
    public Vku2: boolean;
    public Vku3: boolean;
    public Vku4: boolean;
    public VkuDoneDate: string;
    public HasVku: boolean;

    constructor(drivingTest: IDrivingTest = new DrivingTest()) {
        super(drivingTest);
        this.Vku1 = drivingTest.Vku1 || false;
        this.Vku2 = drivingTest.Vku2 || false;
        this.Vku3 = drivingTest.Vku3 || false;
        this.Vku4 = drivingTest.Vku4 || false;
        this.VkuDoneDate = drivingTest.VkuDoneDate ? DateTime.getInputDate(new Date(drivingTest.VkuDoneDate)) : "";
        super.init(this);
    }

    protected getComputedFields(map: Map<string, string>): Map<string, string> {
        super.getComputedFields(map);
        map.set("Vku1", "vkusDone");
        map.set("Vku2", "vkusDone");
        map.set("Vku3", "vkusDone");
        map.set("Vku4", "vkusDone");
        return map;
    }

    public vkusDone(): boolean {
        return (this.Vku1 && this.Vku2 && this.Vku3 && this.Vku4) || !this.HasVku;
    }

    public focusVkuDate(): void {
        this.set("VkuDoneDate", DateTime.getDateTimeForInput(new Date()));
        Util.focusRegisterFormDate("vkuDatePD");
    }

    private prom: Promise<number> = Promise.resolve(0);

    public set(name: string, value: any): void {
        super.set(name, value);
        this.prom = this.prom.then(() => this.addOrUpdate());
    }

    public async addOrUpdate(): Promise<number> {
        const drivingTest = this.toEntity();
        if (drivingTest.Id) {
            await drivingTestService.updateEntity(drivingTest, true);
            return drivingTest.Id;
        } else {
            drivingTest.Id = Util.getEducationId(Util.getSelectedPersonId());
            this.Id = drivingTest.Id;
            return drivingTestService.insert(drivingTest);
        }
    }

    public toEntity(drivingTest: IDrivingTest = new DrivingTest()): IDrivingTest {
        super.toEntity(drivingTest);
        drivingTest.Vku1 = this.Vku1;
        drivingTest.Vku2 = this.Vku2;
        drivingTest.Vku3 = this.Vku3;
        drivingTest.Vku4 = this.Vku4;
        drivingTest.VkuDoneDate = this.VkuDoneDate ? DateTime.getDateTimeFromInput(this.VkuDoneDate) : null;
        return drivingTest;
    }
}
