/* eslint-disable no-console */
import { loggerService } from "../services/LoggerService";
import Log from "../types/Log";
import Info from "./Info";
import { network } from "./Network";
import { configService } from "../services/ConfigService";

interface LogItem {
    logObj: Error | Info;
    logLevel: "error" | "warning" | "info" | "debug";
    saveToDb: boolean;
    pushLog: boolean;
}

export default class Logger {
    private readonly consoleOutput: boolean;
    private readonly saveToDatabase: boolean;
    private readonly dontLog: boolean;
    private readonly logLevel: string;

    private readonly queue: LogItem[] = [];
    private processing = false;

    constructor(toConsole: boolean, toErrorLog: boolean, toDatabase: boolean, suppressLogging: boolean, logLevel: "error" | "warning" | "info" | "debug") {
        this.consoleOutput = toConsole;
        this.saveToDatabase = toDatabase;
        this.dontLog = suppressLogging;
        this.logLevel = logLevel;
    }

    private async log(logItem: LogItem): Promise<void> {
        if (logItem.saveToDb === undefined || logItem.saveToDb === null) {
            logItem.saveToDb = true;
        }
        if (!this.dontLog) {
            const date = new Date().toISOString();
            if (this.consoleOutput && this.checkLogLevel(logItem.logLevel)) {
                const message = `%c${logItem.logLevel}:\n${logItem.logObj.message}${logItem.logObj.stack ? `, ${logItem.logObj.stack}` : ""}`;
                if (logItem.logLevel === "error") {
                    console.log(message, "color:#FF0000");
                } else if (logItem.logLevel === "warning") {
                    console.log(message, "color:#FFA500;");
                } else if (logItem.logLevel === "info") {
                    console.log(message, "color:#33CCFF;");
                } else if (logItem.logLevel === "debug") {
                    console.log(message, "color:#000000;");
                }
            }
            if (this.saveToDatabase && logItem.saveToDb && this.checkLogLevel(logItem.logLevel)) {
                try {
                    const logEntry = new Log();
                    logEntry.IsDirty = true;
                    logEntry.IsNew = logItem.pushLog;
                    logEntry.Date = new Date(date);
                    logEntry.LogLevel = logItem.logLevel;
                    logEntry.Log = `Version: ${configService.Config.Version}, UserId: ${network.getUserId()}, ${logItem.logObj.message}${logItem.logObj.stack ? `, ${logItem.logObj.stack}` : ""}`;
                    logEntry.UserId = network.getUserId();
                    logEntry.CompanyId = network.getCompanyId();
                    await loggerService.insert(logEntry);
                } catch (e) {
                    console.log("Log to Database Failed");
                }
            }
        }
    }

    private checkLogLevel = (logLevel: "error" | "warning" | "info" | "debug"): boolean => {
        switch (this.logLevel) {
            case "error":
                return logLevel === "error";
            case "warning":
                return logLevel === "error" || logLevel === "warning";
            case "info":
                return logLevel === "error" || logLevel === "warning" || logLevel === "info";
            default:
                return true;
        }
    };

    public logError(error: Error, saveToDb: boolean = true, pushLog = true): void {
        this.queue.push({ logObj: error, logLevel: "error", saveToDb: saveToDb, pushLog: pushLog });
        void this.process();
    }

    public logWarning(warning: string, saveToDb: boolean = false): void {
        this.queue.push({ logObj: new Info(warning), logLevel: "warning", saveToDb: saveToDb, pushLog: false });
        void this.process();
    }

    public logInfo(info: string, saveToDb: boolean = false): void {
        this.queue.push({ logObj: new Info(info), logLevel: "info", saveToDb: saveToDb, pushLog: false });
        void this.process();
    }

    public logDebug(error: Error): void {
        this.queue.push({ logObj: error, logLevel: "debug", saveToDb: true, pushLog: false });
        void this.process();
    }

    private async process() {
        if (this.processing) {
            return;
        }
        this.processing = true;
        while (this.queue.length > 0) {
            const logObj = this.queue.shift();
            await this.log(logObj);
        }
        this.processing = false;
    }
}

export const logger = new Logger(true, true, true, false, "info");
