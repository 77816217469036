import { nameof } from "ts-simple-nameof";
import { network } from "../modules/Network";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseUserSettings {
    MailFrom: string;
    DefaultCompanyLogo: string;
    DefaultCompanyLogo_INTERNAL: string;
    Dokument_Rechnung_Kopfzeile: string;
    Dokument_Rechnung_Konditionen: string;
    Dokument_Rechnung_Fusszeile: string;
    UserId: number;
    CompanyId: number;
    AppUserSettingsKey: string;
    AppCompanySettingsKey: string;
}

export interface IUserSettingsDto extends IBaseDto, IBaseUserSettings {
    DefaultLanguage: string;
}
export interface IUserSettings extends IBaseEntity, IBaseUserSettings {
    Language: string;
}

export default class UserSettings extends BaseEntity<IUserSettings, IUserSettingsDto> implements IUserSettings {
    public static get EntityTypeId(): string {
        return "a9401320-c0ae-5fa9-923a-0bef4478bcea";
    }

    public MailFrom: string;
    public DefaultCompanyLogo: string;
    public DefaultCompanyLogo_INTERNAL: string;
    public Dokument_Rechnung_Kopfzeile: string;
    public Dokument_Rechnung_Konditionen: string;
    public Dokument_Rechnung_Fusszeile: string;
    public UserId: number;
    public CompanyId: number;
    public AppUserSettingsKey = `UserSettings${network.getUserId()}`;
    public AppCompanySettingsKey = "CompanySettings";
    public Language: string;

    public populateFromDb(dbItem: IUserSettings): void {
        super.populateFromDb(dbItem);
        this.MailFrom = dbItem.MailFrom;
        this.DefaultCompanyLogo = dbItem.DefaultCompanyLogo;
        this.DefaultCompanyLogo_INTERNAL = dbItem.DefaultCompanyLogo_INTERNAL;
        this.Dokument_Rechnung_Fusszeile = dbItem.Dokument_Rechnung_Fusszeile;
        this.Dokument_Rechnung_Konditionen = dbItem.Dokument_Rechnung_Konditionen;
        this.Dokument_Rechnung_Kopfzeile = dbItem.Dokument_Rechnung_Kopfzeile;
        this.UserId = dbItem.UserId;
        this.CompanyId = dbItem.CompanyId;
        this.Language = dbItem.Language;
    }

    public populateFromOData(oDataItem: IUserSettingsDto): void {
        super.populateFromOData(oDataItem);
        this.MailFrom = oDataItem.MailFrom;
        this.DefaultCompanyLogo = oDataItem.DefaultCompanyLogo;
        this.UserId = oDataItem.UserId;
        this.Dokument_Rechnung_Fusszeile = oDataItem.Dokument_Rechnung_Fusszeile;
        this.Dokument_Rechnung_Konditionen = oDataItem.Dokument_Rechnung_Konditionen;
        this.Dokument_Rechnung_Kopfzeile = oDataItem.Dokument_Rechnung_Kopfzeile;
        this.Language = oDataItem.DefaultLanguage;
    }

    public toODataObject(): IUserSettingsDto {
        const oDataObject = super.toODataObject();
        oDataObject.MailFrom = this.MailFrom;
        oDataObject.Dokument_Rechnung_Fusszeile = this.Dokument_Rechnung_Fusszeile;
        oDataObject.Dokument_Rechnung_Konditionen = this.Dokument_Rechnung_Konditionen;
        oDataObject.Dokument_Rechnung_Kopfzeile = this.Dokument_Rechnung_Kopfzeile;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<UserSettings>(x => x.Id),
            this.UserId
        ); // Override Id with UserId
        dbObject.set(
            nameof<UserSettings>(x => x.MailFrom),
            this.MailFrom
        );
        dbObject.set(
            nameof<UserSettings>(x => x.DefaultCompanyLogo),
            this.DefaultCompanyLogo
        );
        dbObject.set(
            nameof<UserSettings>(x => x.DefaultCompanyLogo_INTERNAL),
            this.DefaultCompanyLogo_INTERNAL
        );
        dbObject.set(
            nameof<UserSettings>(x => x.UserId),
            network.getUserId()
        );
        dbObject.set(
            nameof<UserSettings>(x => x.CompanyId),
            network.getCompanyId()
        );
        dbObject.set(
            nameof<UserSettings>(x => x.Dokument_Rechnung_Fusszeile),
            this.Dokument_Rechnung_Fusszeile
        );
        dbObject.set(
            nameof<UserSettings>(x => x.Dokument_Rechnung_Konditionen),
            this.Dokument_Rechnung_Konditionen
        );
        dbObject.set(
            nameof<UserSettings>(x => x.Dokument_Rechnung_Kopfzeile),
            this.Dokument_Rechnung_Kopfzeile
        );
        dbObject.set(
            nameof<UserSettings>(x => x.Language),
            this.Language
        );
        return dbObject;
    }
}
