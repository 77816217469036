import DateTime from "../modules/DateTime";
import { Util } from "../modules/Util";
import { translation } from "../services/TranslationService";

export enum JobPositionItemState {
    NotCharged, // No bill created yet
    PartiallyCharged, // At least one bill has been created but not the whole amount is charged yet
    Draft,
    PartiallyOpen, // At least one bills charging this position is open
    Open, // All bills charging this position are open
    PartiallyOverdue, // At least one bill charging this position is overdue
    Overdue, // All bills charging this position are overdue
    PaidCash, // All bills charging this position have been paid cash
    Paid, // All bills charging this position have been paid (but not all of them have been paid cash)
    PaidDebitCard
}

export default class JobPositionModel extends kendo.data.ObservableObject {
    private _jobId: number;
    private _positionId: number;
    private _description: string;
    private _amount: number;
    private _billingDate: Date;
    private _billStatus: JobPositionItemState;
    private _allowBilling: boolean;
    private _lessonCount: number;
    private _price: number;
    private _checkedForAccount: boolean = false;
    private _billCustomId: string;
    private _timeTrackingReference: number;
    private _timeTrackingIssueDate: string;
    public IsLessonPosition: boolean;
    public PaymentFlowText: string;
    public IsPaymentFlowPosition: boolean;

    constructor(positionId: number, description: string, amount: number, price: number, lessonCount: number, timeTrackingReference: number, timeTrackingIssueDate: string) {
        super();
        this._positionId = positionId;
        this._description = description;
        this._amount = amount;
        this._price = price;
        this._lessonCount = lessonCount;
        this._timeTrackingReference = timeTrackingReference;
        this._timeTrackingIssueDate = timeTrackingIssueDate;
        super.init(this);
    }

    get JobId(): number {
        return this._jobId;
    }

    set JobId(val: number) {
        this._jobId = val;
    }

    get PositionId(): number {
        return this._positionId;
    }

    set PositionId(val: number) {
        this._positionId = val;
    }

    get Description(): string {
        return this._description;
    }

    set Description(val: string) {
        this._description = val;
    }

    get TimeTrackingIssueDate(): string {
        return this._timeTrackingIssueDate;
    }

    set TimeTrackingIssueDate(val: string) {
        this._timeTrackingIssueDate = val;
    }

    get Amount(): number {
        return this._amount;
    }

    set Amount(val: number) {
        this._amount = val;
    }

    get Price(): number {
        return this._price;
    }

    set Price(val: number) {
        this._price = val;
    }

    set TimeTrackingReference(val: number) {
        this._timeTrackingReference = val;
    }

    get TimeTrackingReference(): number {
        return this._timeTrackingReference;
    }

    get OrderDate(): string {
        return this.TimeTrackingIssueDate ? this.TimeTrackingIssueDate : this.BillingDate?.toISOString();
    }

    set BillingDate(val: Date) {
        this._billingDate = val;
    }

    get BillingDate(): Date {
        return this._billingDate;
    }

    get GetPaymentDate() {
        return this.FormattedTimeTrackingDate ? this.FormattedTimeTrackingDate : this.FormattedBillingDate;
    }

    get BillStatus(): JobPositionItemState {
        return this._billStatus;
    }

    set BillStatus(val: JobPositionItemState) {
        this._billStatus = val;
    }

    get IsPaid(): boolean {
        return this.BillStatus >= JobPositionItemState.PaidCash;
    }

    get NotPaid(): boolean {
        return !this.IsPaid;
    }

    get AllowBilling(): boolean {
        return this._allowBilling;
    }

    set AllowBilling(val: boolean) {
        this._allowBilling = val;
    }

    get DontAllowBilling(): boolean {
        return !this.AllowBilling;
    }

    get Title(): string {
        if (this.IsLessonPosition || this.Description.includes(translation.t("job-position-model.title-prefix-1")) || this.Description.includes(translation.t("job-position-model.title-prefix-2"))) {
            return translation.t("account-lesson.account-lesson-name", { count: this.Amount });
        }
        return this.Description;
    }

    get NewPriceTitle(): string {
        return `${translation.t("job-position.new-price-for")} ${this.Title}`;
    }

    get TitleHTML(): string {
        if (
            this.IsLessonPosition ||
            this.Description.startsWith(translation.t("job-position-model.title-prefix-1")) ||
            this.Description.startsWith(translation.t("job-position-model.title-prefix-2")) ||
            this.Description.startsWith(translation.t("job-position-model.title-prefix-3"))
        ) {
            const title = translation.t("account-lesson.account-lesson-name", { count: this.Amount });
            if (this.TimeTrackingIssueDate) {
                return `<div>${title}</div><div class="smallText" style="padding-top: 3px;"><i>(${DateTime.parseShortNumberDate(new Date(this.TimeTrackingIssueDate))})</i></div>`;
            } else {
                return `<div>${title}</div><div class="smallText" style="padding-top: 3px;"><i>(${translation.t("account-lesson.no-lesson")})</i></div>`;
            }
        }
        if (this.PaymentFlowText) {
            return `<div>${this.Description}</div><div class="smallText" style="padding-top: 3px;"><i>(${this.PaymentFlowText})</i></div>`;
        }
        return this.Description;
    }

    get FormattedPrice(): string {
        return Util.formatPrice(this.Price * this.Amount);
    }

    get FormattedBillingDate(): string {
        return this.BillingDate ? DateTime.parseShortNumberDate(this.BillingDate) : "";
    }

    get FormattedTimeTrackingDate(): string {
        return this.TimeTrackingIssueDate ? DateTime.parseShortNumberDate(new Date(this.TimeTrackingIssueDate)) : "";
    }

    get BillingDateText(): string {
        return this.BillingDate ? DateTime.parseNumberDate(this.BillingDate) : "";
    }

    get FormattedOrderDate(): string {
        return this.OrderDate ? DateTime.parseNumberDate(new Date(this.OrderDate)) : "";
    }

    get CheckedForAccount(): boolean {
        return this._checkedForAccount;
    }

    set CheckedForAccount(val: boolean) {
        this._checkedForAccount = val;
    }

    get LessonCount(): number {
        return this._lessonCount;
    }

    set BillCustomId(val: string) {
        this._billCustomId = val;
    }

    get BillCustomId(): string {
        return this._billCustomId;
    }

    get BillCustomIdText(): string {
        return this._billCustomId || "";
    }

    get BillCustomIdEasyPayment(): string {
        return this.BillCustomId ? `<a onclick="personDetailModel.navigateToBillsSpecificBill({ data: { BillCustomId: '${this._billCustomId}' }})">${this._billCustomId}</a>` : "";
    }

    get Status(): string {
        switch (this.BillStatus) {
            case JobPositionItemState.NotCharged:
            case JobPositionItemState.PartiallyCharged:
                return `<div class="billStatusNotCarried billStatusSymbol default-inline-block"></div><span class="default-inline-block smallText">${translation.t("bill-model.not-charged")}</span>`;
            case JobPositionItemState.Draft:
                return `<div class="billStatusOpen billStatusSymbol"><i class="icon-sandglass"></i></div><span class="btnText billStatusText ellipsis smallText">${translation.t(
                    "bill-model.draft"
                )}</span>`;
            case JobPositionItemState.PartiallyOpen:
            case JobPositionItemState.Open:
                return `<div class="billStatusOpen billStatusSymbol default-inline-block"><i class="custom-icon-clock-isolated"></i></div><span class="default-inline-block smallText">${translation.t(
                    "bill-model.open"
                )}</span>`;
            case JobPositionItemState.PartiallyOverdue:
            case JobPositionItemState.Overdue:
                return `<div class="billStatusOverdue billStatusSymbol default-inline-block"><b>!</b></div><span class="default-inline-block smallText">${translation.t("bill-model.overdue")}</span>`;
            case JobPositionItemState.PaidCash:
                return `<div class="billStatusPaid billStatusSymbol default-inline-block"><i class="icons icon-tick"></i></div><span class="default-inline-block smallText">${translation.t(
                    "bill-model.paidcash"
                )}</span>`;
            case JobPositionItemState.Paid:
                return `<div class="billStatusPaid billStatusSymbol default-inline-block"><i class="icons icon-tick"></i></div><span class="default-inline-block smallText">${translation.t(
                    "bill-model.paid"
                )}</span>`;
            default:
                /* DebitCard */
                return `<div class="billStatusPaid billStatusSymbol default-inline-block"><i class="icons icon-tick"></i></div><span class="default-inline-block smallText">${translation.t(
                    "bill-model.debitcard"
                )}</span>`;
        }
    }
}
