import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBasePersonAddress {
    PersonId: number;
    Street: string;
    StreetNumber: string;
    ZipCode: string;
    City: string;
    State: string;
    Country: string;
    AddressZusatz: string;
    AddressTypeId: number;
    IsPrefered: boolean;
}

export interface IPersonAddressDto extends IBaseDto, IBasePersonAddress {}
export interface IPersonAddress extends IBaseEntity, IBasePersonAddress {}

export default class PersonAddress extends BaseEntity<IPersonAddress, IPersonAddressDto> implements IPersonAddress {
    public static get EntityTypeId(): string {
        return "67447d19-cfcd-50aa-bf54-3800d865b500";
    }

    public PersonId: number;
    public Street: string;
    public StreetNumber: string;
    public ZipCode: string;
    public City: string;
    public State: string;
    public Country: string;
    public AddressZusatz: string;
    public AddressTypeId: number;
    public IsPrefered: boolean;

    public populateFromDb(dbItem: IPersonAddress): void {
        super.populateFromDb(dbItem);
        this.PersonId = dbItem.PersonId;
        this.Street = dbItem.Street;
        this.StreetNumber = dbItem.StreetNumber;
        this.ZipCode = dbItem.ZipCode;
        this.City = dbItem.City;
        this.State = dbItem.State;
        this.Country = dbItem.Country;
        this.AddressZusatz = dbItem.AddressZusatz;
        this.AddressTypeId = dbItem.AddressTypeId;
        this.IsPrefered = this.getDbBoolean(dbItem.IsPrefered);
    }

    public populateFromOData(oDataItem: IPersonAddressDto): void {
        super.populateFromOData(oDataItem);
        this.PersonId = oDataItem.PersonId;
        this.Street = oDataItem.Street;
        this.StreetNumber = oDataItem.StreetNumber;
        this.ZipCode = oDataItem.ZipCode;
        this.City = oDataItem.City;
        this.State = oDataItem.State;
        this.Country = oDataItem.Country;
        this.AddressZusatz = oDataItem.AddressZusatz;
        this.AddressTypeId = oDataItem.AddressTypeId;
        this.IsPrefered = oDataItem.IsPrefered;
    }

    public toODataObject(): IPersonAddressDto {
        const oDataObject = super.toODataObject();
        oDataObject.PersonId = this.PersonId;
        oDataObject.Street = this.Street;
        oDataObject.StreetNumber = this.StreetNumber;
        oDataObject.ZipCode = this.ZipCode;
        oDataObject.City = this.City;
        oDataObject.State = this.State;
        oDataObject.Country = this.Country;
        oDataObject.AddressZusatz = this.AddressZusatz;
        oDataObject.AddressTypeId = this.AddressTypeId;
        oDataObject.IsPrefered = this.IsPrefered;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<PersonAddress>(x => x.PersonId),
            this.PersonId
        );
        dbObject.set(
            nameof<PersonAddress>(x => x.Street),
            this.Street
        );
        dbObject.set(
            nameof<PersonAddress>(x => x.StreetNumber),
            this.StreetNumber
        );
        dbObject.set(
            nameof<PersonAddress>(x => x.ZipCode),
            this.ZipCode
        );
        dbObject.set(
            nameof<PersonAddress>(x => x.City),
            this.City
        );
        dbObject.set(
            nameof<PersonAddress>(x => x.State),
            this.State
        );
        dbObject.set(
            nameof<PersonAddress>(x => x.Country),
            this.Country
        );
        dbObject.set(
            nameof<PersonAddress>(x => x.AddressZusatz),
            this.AddressZusatz
        );
        dbObject.set(
            nameof<PersonAddress>(x => x.AddressTypeId),
            this.AddressTypeId
        );
        dbObject.set(
            nameof<PersonAddress>(x => x.IsPrefered),
            this.IsPrefered
        );
        return dbObject;
    }
}
