import WeeklyWorkingHourModel from "../models/WeeklyWorkingHourModel";
import { Util } from "../modules/Util";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { translation } from "../services/TranslationService";
import { userService } from "../services/UserService";
import { UserWorkingHours } from "../types/CompanyAppSettings";
import { BaseView } from "./BaseView";

export default class AdminSettings extends BaseView {
    private readonly NAV_POINT = "views/settings.html";

    private validator: kendo.ui.Validator;

    public async loadData(): Promise<void> {
        const companyAppSettings = await companyAppSettingsService.getSettings();
        const users = await userService.getItems();
        const workingHoursModel = users.map(user => {
            const weeklyWorkingHourItem = companyAppSettings.WeeklyWorkingHours.find(wh => wh.UserId === user.Id);
            if (weeklyWorkingHourItem) {
                return new WeeklyWorkingHourModel(user, weeklyWorkingHourItem.WeeklyWorkingHours);
            }
            return new WeeklyWorkingHourModel(user, 0);
        });
        this.set("weeklyWorkingHours", workingHoursModel);
        this.set("lockEditLesson", companyAppSettings.LockEditLesson);
        this.set("saveCashPayment", companyAppSettings.SaveCashPayment);
        this.set("lessonEditLockDays", companyAppSettings.LessonEditLockDays);
        this.set("SendBills", companyAppSettings.SendBills);
        this.set("ForceEasyAcccounting", companyAppSettings.ForceEasyAcccounting);
        this.set("DisablePriceChange", companyAppSettings.DisablePriceChange);
        this.set("ratingLabels", companyAppSettings.RatingLabels);
        this.validator = $("#adminSettingsModelMasterElement").kendoValidator().data().kendoValidator;
        this.validator.bind("validateInput", Util.validateInput);
    }

    public checkLessonEditLockDays = () => {
        const currentDays = this.get<any>("lessonEditLockDays");
        if (currentDays === null) {
            this.set("lessonEditLockDays", 0);
        }
    };

    public save = async (): Promise<void> => {
        if (!this.validator.validate()) {
            return;
        }
        await companyAppSettingsService.updateSettings(
            new Map<string, any>()
                .set("LockEditLesson", this.get<boolean>("lockEditLesson"))
                .set("SaveCashPayment", this.get<boolean>("saveCashPayment"))
                .set("LessonEditLockDays", this.get<number>("lessonEditLockDays"))
                .set("SendBills", this.get<boolean>("SendBills"))
                .set("ForceEasyAcccounting", this.get<boolean>("ForceEasyAcccounting"))
                .set("DisablePriceChange", this.get<boolean>("DisablePriceChange"))
                .set("RatingLabels", JSON.stringify(this.get<string[]>("ratingLabels")))
                .set(
                    "WeeklyWorkingHours",
                    JSON.stringify(
                        this.get<WeeklyWorkingHourModel[]>("weeklyWorkingHours").map<UserWorkingHours>(i => ({
                            UserId: i.UserId,
                            UserPersonId: i.UserPersonId,
                            WeeklyWorkingHours: i.WeeklyWorkingHours
                        }))
                    )
                ),
            true
        );
        Util.showNotification(translation.t("setting.admin-saved"), "success");
        this.back();
    };

    public getNavPoint(): string {
        return this.NAV_POINT;
    }
}
