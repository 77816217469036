import { IChecklistItem } from "../types/ChecklistItem";

export default class ChecklistModel {
    public Id: number;
    public PreviousSiblingId: number;
    public NextSiblingId: number;
    public Label: string;
    public items: ChecklistModel[];
    public _ParentChecklistItem: ChecklistModel = null;

    public IsInputNode: boolean;
    public depth: number;
    public expanded: boolean;
    public EditMode: boolean;
    public selected: boolean;
    public Content: string;
    public HasContent: boolean;

    constructor(checklistItem: IChecklistItem = null) {
        if (checklistItem) {
            this.Id = checklistItem.Id;
            this.Label = checklistItem.Label;
            this.NextSiblingId = checklistItem.NextSiblingId;
            this.PreviousSiblingId = checklistItem.PreviousSiblingId;
            this.HasContent = checklistItem.HasContent;
        }
        this.items = [];
    }

    public pushItem(item: ChecklistModel) {
        this.items.push(item);
    }
}
