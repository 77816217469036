import { IChecklistItem } from "../types/ChecklistItem";

export default class PlannedChecklistItemModel {
    public Id: number;
    public Label: string;
    public Overview: string;

    constructor(checklistItem: IChecklistItem) {
        this.Id = checklistItem.Id;
        this.Label = checklistItem.Label;
        this.Overview = checklistItem.Overview;
    }
}
