import { ILesson } from "../types/Lesson";
import BaseModel from "./BaseModel";

export default class LessonModel extends BaseModel {
    public Date: Date;
    public Count: number;
    public TimeTrackingId: number;
    public Note: string;
    public EducationId: number;
    public IsCurrentLesson: boolean;

    constructor(lesson: ILesson) {
        super(lesson);
        this.Date = lesson.Date;
        this.Count = lesson.Count;
        this.TimeTrackingId = lesson.TimeTrackingId;
        this.Note = lesson.Note;
        this.EducationId = lesson.EducationId;
        this.IsCurrentLesson = lesson.IsCurrentLesson;
        super.init(this);
    }
}
