import { Util } from "../modules/Util";
import { logger } from "../modules/Logger";
import { network } from "../modules/Network";
import { syncService } from "../services/SyncService";
import { BaseView } from "./BaseView";
import CompanyModel from "../models/CompanyModel";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { userSettingsService } from "../services/UserSettingsService";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import DateTime from "../modules/DateTime";
import { localDataService } from "../services/LocalDataService";
import { configService } from "../services/ConfigService";
import ConfirmContent from "../modules/ConfirmContent";
import { translation } from "../services/TranslationService";

export default class Login extends BaseView {
    public showDemoLogin: boolean = false;
    public singleCompany: boolean = true;
    public companies: CompanyModel[] = [];
    public selectedCompany: number = null;

    private validator: kendo.ui.Validator;

    public async loadData(): Promise<void> {
        // nothing to load
    }

    public async initView(): Promise<void> {
        this.validator = $(".loginForm").kendoValidator().data().kendoValidator;

        if (Util.isDemo()) {
            await this.demoLogin();
        }

        if (Util.getAutoLogin() && Util.getUsername() && Util.getPassword() && network.getUserId() && network.getCompanyId() && network.getToken()) {
            logger.logInfo(`${translation.t("login.login-on")}: ${DateTime.parseNumberDateTime(new Date())}, Server: ${network.ODATA_API_V3}, User: ${Util.getUsername()}`, false);
            await this.loadApp();
        } else {
            setTimeout(() => {
                if (navigator && navigator.splashscreen) {
                    navigator.splashscreen.hide();
                }
                $("#loginHider").show();
            }, 500);
        }
    }

    // override show from base as database is not ready
    public show = async (): Promise<void> => {
        await translation.reloadLanguage();
        this.translate();
        this.showView();
    };

    public showView(): void {
        this.set("orphyDriveVersion", `Version: ${configService.Config.Version}`);
        this.addBackButtonEvent();
        if (Util.getAutoLogin()) {
            $("#username").val(Util.getUsername());
        }
        if ((!Util.getUsername() || Util.getUsername() === "orphydrivedemo@orphis.ch") && !Util.isBrowser()) {
            $("#username").val("");
            this.set("showDemoLogin", true);
        } else if (Util.getUsername() === "orphydrivedemo@orphis.ch" && Util.isBrowser()) {
            $("#username").val("");
            this.set("showDemoLogin", false);
        }
        $("#password").val("");
        $("#loginHider").show();
        this.set("singleCompany", true);
        this.set("selectedCompany", null);
    }

    private demoLogin = async () => {
        if (!Util.isBrowser()) {
            $("#username").val("orphydrivedemo@orphis.ch");
            $("#password").val("-[,Im6G0Z0M*B5$tNU");
            await this.authenticate("orphydrivedemo@orphis.ch", "-[,Im6G0Z0M*B5$tNU");
        }
    };

    public login = async () => {
        await this.authenticate($("#username").val(), $("#password").val());
    };

    private authenticate = async (userName: string, password: string) => {
        try {
            if (userName === "orphydrivedemo@orphis.ch" || this.validator.validate()) {
                Util.setUsername(userName);
                Util.setPassword(password);
                app.mobileApp.showLoading();

                if (this.get<number>("selectedCompany")) {
                    network.setCompanyId(this.get<number>("selectedCompany"));
                }

                const companyModel = await network.getToken(false);

                if (companyModel && companyModel.Companies.length > 1 && !this.get<number>("selectedCompany")) {
                    if (!this.get<number>("selectedCompany")) {
                        this.set("singleCompany", false);
                        this.set("selectedCompany", companyModel.CompanyId);
                        this.set("companies", companyModel.Companies);
                    }
                    app.mobileApp.hideLoading();
                } else {
                    logger.logInfo(`${translation.t("login.login-on")}: ${DateTime.parseNumberDate(new Date())}, Server: ${network.ODATA_API_V3}, User: ${$("#username").val()}`, false);
                    logger.logInfo(navigator.userAgent);
                    app.mobileApp.hideLoading();
                    Util.setAutoLogin($("#chSavePassword").is(":checked"));
                    await this.loadApp();
                }
            }
        } catch (e) {
            Util.showNotification(translation.t("login.user-invalid"), "error");
            app.mobileApp.hideLoading();
        }
    };

    private loadApp = async (): Promise<void> => {
        try {
            await network.getToken(false);
            app.mobileApp.showLoading();
            Util.setFirstStartDone();
            const companyAppSettings = await companyAppSettingsService.getSettings();

            const userSettings = await userSettingsService.getSettings();

            app.mobileApp.hideLoading();

            if (!companyAppSettings || !companyAppSettings.IsInitialized) {
                app.mobileApp.navigate("views/firstTimeUsage.html");
            } else if (!userSettings) {
                // Check usersettings to determine whether a new user logged in to this company. In this case, we have to sync again in order to get their settings.
                try {
                    await syncService.initSync();
                } catch (e) {
                    this.syncFailed();
                    return;
                }
                await this.navigateMainview();
            } else {
                const localData = await localDataService.getLocalData();
                if (localData && localData.LastSync) {
                    if (!Util.isDemo()) {
                        await this.showSyncRememberAndNavigate();
                    } else {
                        await this.navigateMainview();
                    }
                } else {
                    try {
                        await syncService.initSync();
                        app.mobileApp.navigate("views/mainView.html");
                    } catch (e) {
                        this.syncFailed();
                    }
                }
            }
        } catch (error) {
            if (error) {
                logger.logError(new Error(`Failed to load Dal: ${error.code} - ${error.message}`), false);
            } else {
                logger.logError(new Error("Failed to initialize database"), false);
            }
            app.mobileApp.hideLoading();
        }
    };

    private showSyncRememberAndNavigate = async (): Promise<void> => {
        const dirtyItemCount = await syncService.getOverallDirtyItemsCount();
        const userAppSettings = await userAppSettingsService.getSettings();
        if (userAppSettings.ShowSyncMessage && dirtyItemCount) {
            const { confirmed, messages } = await Util.confirmTemplate(ConfirmContent.syncReminder(), translation.t("login.yes-sync"), translation.t("login.no-later"), false, [
                "#showSyncMessageCheckbox"
            ]);
            if (confirmed) {
                await userAppSettingsService.updateSettings(new Map<string, any>().set("ShowSyncMessage", !Util.firstOrDefault(messages).is(":checked")), true);
                try {
                    await syncService.initSync();
                } catch (e) {
                    this.syncFailed();
                }
            } else {
                await userAppSettingsService.updateSettings(new Map<string, any>().set("ShowSyncMessage", !Util.firstOrDefault(messages).is(":checked")), true);
            }
        }
        await this.navigateMainview();
    };

    private syncFailed = () => {
        Util.setAutoLogin(false);
        app.mobileApp.navigate("views/login.html");
    };

    private navigateMainview = async (): Promise<void> => {
        const localData = await localDataService.getLocalData();
        window.orphyDriveOne = localData.OrphyDriveOne;
        app.mobileApp.navigate("views/mainView.html");
    };

    public openAGB = () => {
        window.open("https://app.orphy.com/Content/pdf/agb.pdf", "_system");
    };

    public loginWithEnter = async (e: KeyboardEvent) => {
        if ((e.key === "Enter" || e.keyCode === 13) && this.validator.validate()) {
            await this.authenticate($("#username").val(), $("#password").val());
        }
    };

    public getNavPoint(): string {
        throw new Error("Not implemented");
    }

    public back(e: any) {
        e.preventDefault();
    }

    public passwordLost = () => {
        window.open(`${network.IDSRV_BASE}/Account/ForgotPassword`, "_system");
    };
}
