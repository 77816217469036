export default class Info {
    private readonly _message: string;

    constructor(message: string) {
        this._message = message;
    }

    get message() {
        return this._message;
    }

    get stack() {
        return null;
    }
}
