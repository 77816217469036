import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseActivity {
    Name: string;
    Type: string;
    TranslatedDisplayName: string;
    Verrechnbar: boolean;
    Stundenansatz: number;
}

export interface IActivityDto extends IBaseDto, IBaseActivity {}

export interface IActivity extends IBaseEntity, IBaseActivity {}

export default class Activity extends BaseEntity<IActivity, IActivityDto> implements IActivity {
    public static get EntityTypeId(): string {
        return "2f252073-cdda-590b-8033-4abd0801da07";
    }

    public Name: string;
    public Type: string;
    public TranslatedDisplayName: string;
    public Verrechnbar: boolean;
    public Stundenansatz: number;

    public populateFromDb(dbItem: IActivity): void {
        super.populateFromDb(dbItem);
        this.Name = dbItem.Name;
        this.Type = dbItem.Type;
        this.TranslatedDisplayName = dbItem.TranslatedDisplayName;
        this.Verrechnbar = this.getDbBoolean(dbItem.Verrechnbar);
        this.Stundenansatz = dbItem.Stundenansatz;
    }

    public populateFromOData(oDataItem: any): void {
        super.populateFromOData(oDataItem);
        this.Name = oDataItem.Name;
        this.Type = oDataItem.Type;
        this.TranslatedDisplayName = oDataItem.TranslatedDisplayName;
        this.Verrechnbar = oDataItem.Verrechnbar;
        this.Stundenansatz = oDataItem.Stundenansatz;
    }

    public toODataObject(): IActivityDto {
        return null; // Currencies are not pushed to Orphy
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Activity>(x => x.Name),
            this.Name
        );
        dbObject.set(
            nameof<Activity>(x => x.Type),
            this.Type
        );
        dbObject.set(
            nameof<Activity>(x => x.TranslatedDisplayName),
            this.TranslatedDisplayName
        );
        dbObject.set(
            nameof<Activity>(x => x.Verrechnbar),
            this.Verrechnbar
        );
        dbObject.set(
            nameof<Activity>(x => x.Stundenansatz),
            this.Stundenansatz
        );
        return dbObject;
    }
}
