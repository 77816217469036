import { translation } from "../services/TranslationService";

export default class TimeTrackingTotalModel extends kendo.data.ObservableObject {
    public Total: number = 0;
    public LessonTotal: number = 0;

    public getHours(): number {
        return parseInt(this.Total.toString());
    }

    public getMinutes() {
        return Math.round((this.Total - this.getHours()) * 60);
    }

    get Title(): string {
        return `${this.HoursText} ${this.MinutesText}${this.LessonTotalText ? `, ${this.LessonTotalText}` : ""}`;
    }

    get MinutesText(): string {
        const minutes = this.getMinutes();
        if (minutes === 0) {
            return "";
        }
        return translation.t("time-date.minutes", { count: minutes });
    }

    get HoursText(): string {
        const hours = this.getHours();
        if (hours === 0) {
            return "";
        }
        return translation.t("time-date.hours", { count: hours });
    }

    get LessonTotalText(): string {
        if (this.LessonTotal === 0) {
            return "";
        }
        return translation.t("time-date.lesson", { count: this.LessonTotal });
    }
}
