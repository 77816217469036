import OpenFileModule from "../modules/OpenFileModule";
import { Util } from "../modules/Util";
import { translation } from "../services/TranslationService";

export default abstract class ListExport {
    protected MIME_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    protected abstract createExport(...args: any[]): Promise<void>;

    public async export(...args: any[]): Promise<void> {
        app.mobileApp.showLoading();
        await this.createExport(args);
        app.mobileApp.hideLoading();
    }

    protected openExcel(sheets: any[], fileName: string): void {
        const workbook = this.getWorkbook(sheets);
        new OpenFileModule(
            this.getWorkbookData(workbook),
            fileName,
            this.MIME_TYPE,
            () => {
                app.mobileApp.hideLoading();
            },
            () => {
                app.mobileApp.hideLoading();
                Util.showNotification(translation.t("export.unknown-error"), "error");
            }
        ).openFile();
    }

    protected addEmptyRows(rows: any[], colspan: number, amount: number): any[] {
        for (let i = 0; i < amount; i++) {
            rows.push({ cells: [{ colSpan: colspan }] });
        }
        return rows;
    }

    private getWorkbook(sheets: any[]): kendo.ooxml.Workbook {
        return new kendo.ooxml.Workbook({
            sheets: sheets
        });
    }

    private getWorkbookData(workbook: kendo.ooxml.Workbook): string {
        const ret = workbook.toDataURL();
        return ret.substr(ret.indexOf(",") + 1, ret.length);
    }

    protected getSheetTitle(totalTitle: string): string {
        return totalTitle.substr(totalTitle.indexOf(".") + 2, totalTitle.length);
    }

    protected empty(colSpan: number): any {
        return { cells: [{ colSpan: colSpan }] };
    }
}
