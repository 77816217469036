import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { IOrphyDriveJobPosition, IOrphyDriveJobPositionDto, OrphyDriveJobPosition } from "../types/OrphyDriveJobPosition";
import { BaseService } from "./BaseService";
import { jobPositionService } from "./JobPositionService";

export default class OrphyDriveJobPositionService extends BaseService<IOrphyDriveJobPosition, IOrphyDriveJobPositionDto> {
    private readonly TABLE_NAME = "OrphyDriveJobPositions";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IOrphyDriveJobPositionDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IOrphyDriveJobPosition>());
    }

    public getApiRoute(): string {
        return `${network.API}/${OrphyDriveJobPosition.EntityTypeId}`;
    }

    public createEntityFromOData(item: IOrphyDriveJobPositionDto): IOrphyDriveJobPosition {
        const entity = new OrphyDriveJobPosition();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: IOrphyDriveJobPosition): IOrphyDriveJobPosition {
        const entity = new OrphyDriveJobPosition();
        entity.populateFromDb(item);
        return entity;
    }

    public add(positionId: number, timetrackingId: number, lessonCount: number): Promise<number> {
        const position = new OrphyDriveJobPosition();
        position.JobPositionId = positionId;
        position.TimeTrackingId = timetrackingId;
        position.LessonCount = lessonCount;
        return this.insert(position);
    }

    public getItemById(): Promise<IOrphyDriveJobPosition> {
        throw new Error("OrphyDriveJobPosition does not have an id use getItemByPositionId instead");
    }

    public async getItemByPositionId(positionId: number): Promise<IOrphyDriveJobPosition> {
        return Util.firstOrDefault(await this.getItems(`JobPositionId = ${positionId}`));
    }

    public async getItemsByTimeTrackingId(timeTrackingId: number): Promise<IOrphyDriveJobPosition[]> {
        return this.getItems(`TimeTrackingId = ${timeTrackingId}`);
    }

    public async delete(jobPositionId: number, isDirty: boolean = true): Promise<void> {
        const item = await this.getItemByPositionId(jobPositionId);
        if (item && item.Id > 0) {
            item.IsDeleted = true;
            await this.updateEntity(item, isDirty);
        } else if (item) {
            await this.hardDeleteByCondidtion(`JobPositionId = ${jobPositionId}`);
        }
    }

    public async getFirstUnchargedPosition(timeTrackingId: number): Promise<IOrphyDriveJobPosition> {
        const orphyDriveJobPositions = await this.getItems(`TimeTrackingId = ${timeTrackingId}`);

        for (const orphyDriveJobPosition of orphyDriveJobPositions) {
            if (!(await jobPositionService.isPositionCharged(orphyDriveJobPosition.JobPositionId))) {
                return orphyDriveJobPosition;
            }
        }
        return null;
    }

    public async getUnchargedLessonPositions(timeTrackingId: number): Promise<IOrphyDriveJobPosition[]> {
        const orphyDriveJobPositions = await this.getItems(`TimeTrackingId = ${timeTrackingId}`);
        return orphyDriveJobPositions.filter(async x => !((await jobPositionService.isPositionCharged(x.JobPositionId)) && (await jobPositionService.isLessonPosition(x.JobPositionId))));
    }

    public async updateJobpositionId(oldId: number, newId: number): Promise<void> {
        // due a bug there are some position with negative jobpositionids which will be patched now with the new id
        await this.update("JobPositionId", oldId, new Map<string, any>().set("JobPositionId", newId), oldId < 0);
    }

    public async updateTimeTrackingId(oldId: number, newId: number): Promise<void> {
        await this.update("TimeTrackingId", oldId, new Map<string, any>().set("TimeTrackingId", newId), false);
    }
}

export const orphyDriveJobPositionService = new OrphyDriveJobPositionService();
