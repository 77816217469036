import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import JobBase, { IJobBase, IJobBaseDto } from "./JobBase";

export enum BillStatus {
    None = 0, // 0
    Draft = 0x01, // 1
    Open = 0x02, // 2
    Reminder = 0x04, // 4
    LateNotice1 = 0x08, // 8
    LateNotice2 = 0x10, // 16
    Overdue = 0x20, // 32
    Paid = 0x40, // 64
    Canceled = 0x80, // 128
    // eslint-disable-next-line no-bitwise
    OpenToOvertue = Open | Reminder | LateNotice1 | LateNotice2 | Overdue
}

interface IBaseBill {
    BillStatus: BillStatus;
    JobMasterId: number;
    LastStateChange: string | Date;
    DokumentTitel: string;
    PaidAt: string | Date;
}

export interface IBillDto extends IJobBaseDto, IBaseBill {}

export interface IBill extends IJobBase, IBaseBill {
    NeedsStateChange: boolean;
    SendBill: boolean;
}

export default class Bill extends JobBase<IBill, IBillDto> implements IBill {
    public DokumentKopfZeile: string;
    public DocumentAfterList: string;
    public DokumentFussZeile: string;
    public DokumentTitel: string;
    public PaidAt: string | Date;
    public BillStatus: BillStatus;
    public JobMasterId: number;
    public LastStateChange: Date | string;
    public DocumentTitle: string;
    public NeedsStateChange: boolean;
    public SendBill: boolean;

    public static get EntityTypeId(): string {
        return "5aee9d96-5b36-546b-84e3-a52f5866e406";
    }

    public populateFromDb(dbItem: IBill): void {
        super.populateFromDb(dbItem);
        this.BillStatus = dbItem.BillStatus;
        this.JobMasterId = dbItem.JobMasterId;
        this.LastStateChange = DateTime.fromDbDateTime(dbItem.LastStateChange as string);
        this.PaidAt = DateTime.fromDbDate(dbItem.PaidAt as string);
        this.NeedsStateChange = this.getDbBoolean(dbItem.NeedsStateChange);
        this.SendBill = this.getDbBoolean(dbItem.SendBill);
    }

    public populateFromOData(oDataItem: IBillDto): void {
        super.populateFromOData(oDataItem);
        this.BillStatus = oDataItem.BillStatus;
        this.JobMasterId = oDataItem.JobMasterId;
        this.LastStateChange = DateTime.fromODataDateTime(oDataItem.LastStateChange as string);
        this.PaidAt = DateTime.fromODataDate(oDataItem.PaidAt as string);
        this.NeedsStateChange = false;
    }

    public toODataObject(): IBillDto {
        const oDataObject = super.toODataObject();
        oDataObject.BillStatus = this.BillStatus;
        oDataObject.JobMasterId = this.JobMasterId;
        oDataObject.LastStateChange = DateTime.toODataDateTime(this.LastStateChange as Date);
        oDataObject.PaidAt = DateTime.toODataDateTime(this.PaidAt as Date);
        if (this.DocumentTitle) {
            oDataObject.DokumentTitel = this.DocumentTitle;
        }
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Bill>(x => x.BillStatus),
            this.BillStatus
        );
        dbObject.set(
            nameof<Bill>(x => x.JobMasterId),
            this.JobMasterId
        );
        dbObject.set(
            nameof<Bill>(x => x.LastStateChange),
            DateTime.toDbDateTime(this.LastStateChange as Date)
        );
        dbObject.set(
            nameof<Bill>(x => x.PaidAt),
            DateTime.toDbDateTime(this.PaidAt as Date)
        );
        dbObject.set(
            nameof<Bill>(x => x.NeedsStateChange),
            this.NeedsStateChange
        );
        dbObject.set(
            nameof<Bill>(x => x.SendBill),
            this.SendBill
        );
        return dbObject;
    }
}
