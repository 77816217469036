import ChartModel from "../models/ChartModel";
import ChecklistModel from "../models/ChecklistModel";
import PersonDetailModel from "../models/PersonDetailModel";
import RatingModel from "../models/RatingModel";
import SeriesModel from "../models/SeriesModel";
import ActiveLesson, { activeLesson } from "../modules/ActiveLesson";
import { network } from "../modules/Network";
import OpenFileModule from "../modules/OpenFileModule";
import Popup from "../modules/Popup";
import { Util } from "../modules/Util";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { jobPositionService } from "../services/JobPositionService";
import { lessonService } from "../services/LessonService";
import { BaseView } from "./BaseView";
import { personService } from "../services/PersonService";
import RegisterPerson, { RegisterPersonNavParams } from "./RegisterPerson";
import { navParams } from "../modules/NavParams";
import { nameof } from "ts-simple-nameof";
import DrivingTestModel from "../models/DrivingTestModel";
import DashboardNotesModel from "../models/DashboardNotesModel";
import { lfaService } from "../services/LfaService";
import { personContactService } from "../services/PersonContactService";
import { testService } from "../services/TestService";
import { checklistCollectionService } from "../services/ChecklistCollectionService";
import { checklistItemService } from "../services/ChecklistItemService";
import { ratingService } from "../services/ChecklistRatingService";
import { drivingTestService } from "../services/DrivingTestService";
import { educationService } from "../services/EducationService";
import { fahrschuelerAppTokenService } from "../services/FahrschuelerAppTokenService";
import { localDataService } from "../services/LocalDataService";
import { orphyDriveJobPositionService } from "../services/OrphyDriveJobPositionService";
import TestModel from "../models/TestModel";
import { billService } from "../services/BillService";
import QRCode, { QRCodeToDataURLOptions } from "qrcode";
import { userService } from "../services/UserService";
import PersonModel from "../models/PersonModel";
import ConfirmContent from "../modules/ConfirmContent";
import LfaModel from "../models/LfaModel";
import DateTime from "../modules/DateTime";
import { logger } from "../modules/Logger";
import { syncService } from "../services/SyncService";
import { translation } from "../services/TranslationService";

export default class PersonDetail extends BaseView {
    public personModel = "person";

    private navPoint: string = "views/allPersons.html";
    private confettiInit: boolean;

    public eduTab: boolean;
    public drivingTestTab: boolean;
    public detailsTab: boolean;
    public subscriptionTab: boolean;
    public accountButtonDisabled: boolean;
    public navigateCompletedDrivingLesson: boolean;
    private editor: kendo.ui.Editor;

    public walletVisible: boolean = false;
    public isSyncedPerson: boolean = false;
    public chartsVisible: boolean = true;

    private lfaPopup: Popup;
    private notePopup: Popup;
    private testEditorPopup: Popup;
    private QRCodePopup: Popup;

    public FirstTest = new TestModel();
    public SecondTest = new TestModel();
    public ThirdTest = new TestModel();
    public FourthTest = new TestModel();

    constructor() {
        super();
        this.lfaPopup = new Popup();
        this.notePopup = new Popup();
        this.QRCodePopup = new Popup();
        this.testEditorPopup = new Popup();
        super.init(this);
    }

    public initView(): void {
        this.initEditor();
    }

    private initEditor = () => {
        this.editor = $("#noteEditorPD")
            .kendoEditor({
                tools: []
            })
            .data("kendoEditor");
        $(this.editor.body)
            .keyup(() => {
                this.editor.update();
            })
            .focus(() => {
                $(".inputFieldContainerFocused").removeClass("inputFieldContainerFocused");
                $("#noteEditorPD").parent().addClass("inputFieldContainerFocused");
            })
            .blur(() => {
                $(".inputFieldContainerFocused").removeClass("inputFieldContainerFocused");
            });
        $(".editorToolbarWindow").remove();
    };

    public async loadData(e: any): Promise<void> {
        if (!(await educationService.personHasEducation(this.personId))) {
            app.mobileApp.navigate("views/educationView.html");
            return;
        }

        if (!(await educationService.isValidEducation(Util.getEducationId(this.personId), this.personId))) {
            const validEducations = await educationService.getEducationsByPersonId(this.personId);
            Util.setEducationId(Util.firstOrDefault(validEducations).Id, this.personId);
        }

        await lessonService.resetUpdateLesson(Util.getEducationId(this.personId));

        const companyAppSettings = await companyAppSettingsService.getSettings();
        const localData = await localDataService.getLocalData();
        this.set("easyAccounting", localData.EasyAccounting || companyAppSettings.ForceEasyAcccounting);
        this.set("legend", companyAppSettings.RatingLabels);
        try {
            const education = await educationService.getSelectedEducation(Util.getEducationId(this.personId), this.personId);

            const educations = await educationService.getDashboardEducationModelsByPersonId(this.personId);
            const users = await userService.getItems();
            const checklistCollections = await checklistCollectionService.getItems();
            this.set("educationSwitchEnabled", educations.length > 1);
            this.set("educations", educations);
            this.set("selectedEducationId", education.Id);

            const personDetail = await personService.getPersonDetailItem(Util.getEducationId(this.personId), this.personId);
            this.set("drivingStudentName", `${personDetail.FirstName} ${personDetail.LastName}`);
            const checklistCollection = await checklistCollectionService.getItemById(education.ChecklistCollectionId);

            if (!checklistCollection) {
                app.mobileApp.navigate("views/educationView.html");
                return;
            }

            const rootItems = await checklistItemService.getTreeStructure(education.ChecklistCollectionId);
            const lessonCount = await lessonService.getLessonCount(Util.getEducationId(this.personId), this.personId);
            const ratings = await ratingService.getPreviousRatingModels(Util.getEducationId(this.personId));
            const drivingTest = await drivingTestService.getDrivingTestModel(Util.getEducationId(this.personId), this.personId);
            this.set("mobile", await personContactService.getPersonPhoneModel(this.personId));

            const allEducations = await educationService.getEducationsByPersonId(this.personId);
            const syncedChecklists = checklistCollections.filter(x => x.Id > 0);
            this.set("eudcationsVisible", syncedChecklists.length > 1 || users.length > 1);
            this.set("addEducationVisible", checklistCollections.filter(x => x.Id > 0).length > 1 && allEducations.length < syncedChecklists.length);
            this.set("educationEditable", users.length > 1);
            this.set("educationSynced", education.Id > 0);

            if (drivingTest.Id) {
                const tests = (await testService.getItems(`DrivingTestId = ${drivingTest.Id}`)).sort((a, b) => {
                    if (a.TestDate === null) {
                        return -1;
                    } else if (b.TestDate === null) {
                        return -1;
                    } else {
                        return (a.TestDate as Date).getTime() - (b.TestDate as Date).getTime();
                    }
                });
                tests[0] ? this.set("FirstTest", new TestModel(tests[0])) : this.set("FirstTest", new TestModel());
                tests[1] ? this.set("SecondTest", new TestModel(tests[1])) : this.set("SecondTest", new TestModel());
                tests[2] ? this.set("ThirdTest", new TestModel(tests[2])) : this.set("ThirdTest", new TestModel());
                tests[3] ? this.set("FourthTest", new TestModel(tests[3])) : this.set("FourthTest", new TestModel());
                this.setDrivingTestId(drivingTest.Id);
                this.setTestVisible();
            } else {
                this.set("FirstTest", new TestModel());
                this.set("SecondTest", new TestModel());
                this.set("ThirdTest", new TestModel());
                this.set("FourthTest", new TestModel());
                this.setTestVisible();
            }

            this.set(this.personModel, personDetail);
            this.set("hasVku", await checklistCollectionService.hasVku(Util.getEducationId(this.personId), this.personId));
            this.set("walletVisible", personDetail.IsSynced && !this.orphyDriveOne);
            this.set("isSyncedPerson", personDetail.IsSynced);
            this.set("drivingTest", drivingTest);
            this.set("chartData", this.createCharts(rootItems, ratings));
            this.set("notes", await this.createNoteList(rootItems, ratings));
            const lfaModel = await lfaService.getLfaModel(Util.getEducationId(this.personId));
            this.set("lfa", lfaModel);
            this.showLfaExpireWarning(lfaModel);
            this.set("email", await personContactService.getPersonEmailModel(this.personId));

            this.setRadioAttributes();
            this.set("LFACategory", checklistCollection ? checklistCollection.Name : translation.t("person-detail.card-deleted"));
            const missedLessonCount = await lessonService.getMissedLessonCount(Util.getEducationId(this.personId), this.personId);
            this.set("navigateCompletedDrivingLesson", lessonCount + missedLessonCount);
            this.set("person.MeetingPointAddress", education.MeetingPointAddress);
        } catch (error) {
            logger.logError(error);
            app.mobileApp.navigate("views/mainView.html");
        }
        if (e.view.params.fromMap && e.view.params.formattedAddress) {
            const meetingPointAddress = decodeURI(e.view.params.formattedAddress);
            if (meetingPointAddress) {
                this.set("person.MeetingPointAddress", meetingPointAddress);
                this.trigger("change");
                await educationService.update("Id", Util.getEducationId(this.personId), new Map<string, any>().set("MeetingPointAddress", meetingPointAddress), true);
            }
        }
    }

    public showView(e: any): void {
        if (e.view.params.resetTab) {
            $("#eduTabButtonPD").click();
        } else if (e.view.params.specificTab) {
            $(`#${e.view.params.specificTab}`).click();
        } else {
            $("#eduTabButtonPD").click();
        }
        this.set("accountButtonDisabled", true);
        Util.setTableWidths(4, "#personDetailME");
    }

    public getNavPoint(): string {
        return this.navPoint;
    }

    public focusVkuDate() {
        this.get<DrivingTestModel>("drivingTest").focusVkuDate();
    }

    public addEducation() {
        app.mobileApp.navigate("views/educationView.html?addEducation=true&navPoint=views/personDetail.html");
    }

    public editEducation() {
        app.mobileApp.navigate("views/educationView.html?navPoint=views/personDetail.html");
    }

    public editLfa() {
        app.mobileApp.navigate("views/lfaView.html?navPoint=views/personDetail.html");
    }

    private showLfaExpireWarning(lfaModel: LfaModel) {
        if (!lfaModel.LfaExpireDate) {
            return;
        }
        const currentDate = new Date();
        const lfaExpireDate = new Date(lfaModel.LfaExpireDate);

        if (currentDate > lfaExpireDate) {
            Util.showNotification(translation.t("person-detail.lfa-expired", { dateString: DateTime.parseNumberDate(new Date(lfaModel.LfaExpireDate)) }), "error");
            return;
        }

        lfaExpireDate.setMonth(lfaExpireDate.getMonth() - 4);
        if (currentDate > lfaExpireDate) {
            Util.showNotification(translation.t("person-detail.lfa-invalid-at", { dateString: DateTime.parseNumberDate(new Date(lfaModel.LfaExpireDate)) }), "info");
        }
    }

    public async switchEducation() {
        Util.setEducationId(this.get<number>("selectedEducationId"), this.personId);
        await this.loadData({ view: { params: {} } });
    }

    private setDrivingTestId(id: number): void {
        this.get<TestModel>("FirstTest").DrivingTestId = id;
        this.get<TestModel>("SecondTest").DrivingTestId = id;
        this.get<TestModel>("ThirdTest").DrivingTestId = id;
        this.get<TestModel>("FourthTest").DrivingTestId = id;
    }

    private setRadioAttributes = () => {
        $("#examScrollerStudentDetail input:radio").each((index, element) => {
            if ($(element).is(":checked")) {
                $(element).attr("waschecked", "true");
            }
        });
    };

    public setVisible(testIndex: number): void {
        switch (testIndex) {
            case 0:
                this.set("FirstTest.pleaseChooseVisible", false);
                break;
            case 1:
                this.set("SecondTest.pleaseChooseVisible", false);
                break;
            case 2:
                this.set("ThirdTest.pleaseChooseVisible", false);
                break;
            case 3:
                this.set("FourthTest.pleaseChooseVisible", false);
                break;
            default:
                logger.logError(new Error(`No testIndex ${testIndex} possible`));
        }
    }

    public async setPleaseChooseVisible(): Promise<void> {
        app.mobileApp.showLoading();
        this.setDrivingTestId(this.get<DrivingTestModel>("drivingTest").Id);
        this.set("FirstTest.pleaseChooseVisible", !this.get<TestModel>("FirstTest").TestDate);
        this.set("SecondTest.pleaseChooseVisible", !this.get<TestModel>("SecondTest").TestDate);
        this.set("ThirdTest.pleaseChooseVisible", !this.get<TestModel>("ThirdTest").TestDate);
        this.set("FourthTest.pleaseChooseVisible", !this.get<TestModel>("FourthTest").TestDate);
        app.mobileApp.hideLoading();
    }

    public setVKkuDatePlaceHolderVisible() {
        this.set("vkuDateVisible", true);
        this.set("vkuPleaseChooseVisible", false);
        Util.focusRegisterFormDate("vkuDatePD");
    }

    public chooseSubscription() {
        this.changeView(`views/subscriptionChooser.html?navPoint=views/personDetail.html&reload=true`);
    }

    public enterMissedLesson() {
        activeLesson.push(new ActiveLesson(null, "views/personDetail.html", this.personId));
        this.changeView(`views/enterLessons.html?navPoint=views/personDetail.html&isMissedLesson=true&reload=true`);
    }

    public displayContent(event: Event) {
        const tabIndex = Number($(event.target).data("tabid"));

        this.set("eduTab", tabIndex === 0);
        this.set("drivingTestTab", tabIndex === 1);
        this.set("detailsTab", tabIndex === 2);
        this.set("subscriptionTab", tabIndex === 3);
        Util.setTableWidths(4, "#personDetailME");
        translation.localize("#kendoUiMobileApp");
    }

    public async openQRCodePopup(): Promise<void> {
        app.mobileApp.showLoading();
        try {
            const code = await fahrschuelerAppTokenService.getFahrschuelerAppToken(this.personId);
            this.QRCodePopup.renderFixedSizePopup("#PopuQRCodePD", 300, 300);
            $("#QRCodePD").attr(
                "src",
                (await QRCode.toDataURL(code, {
                    width: 240,
                    color: {
                        dark: "#279fad"
                    }
                } as QRCodeToDataURLOptions)) as string
            );

            app.mobileApp.hideLoading();
        } catch (e) {
            app.mobileApp.hideLoading();
        }
    }

    public closeQRCodePopup() {
        $("#QRCodePD").empty();
        this.QRCodePopup.closePopup();
    }

    public async resyncPerson() {
        const { confirmed } = await Util.confirmTemplate(ConfirmContent.reloadStudentData(this.get<string>("drivingStudentName")), translation.t("common.update"), translation.t("common.cancel"));
        if (confirmed) {
            $("#refreshStudent").data().kendoMobileModalView.open();
            await syncService.initSync();
            try {
                await personService.refetchPerson(this.personId);
                await this.loadData({
                    view: {
                        params: {
                            fromMap: false
                        }
                    }
                });
            } finally {
                $("#refreshStudent").data().kendoMobileModalView.close();
            }
        }
    }

    public async getSchuelerAbrechnung(): Promise<void> {
        app.mobileApp.showLoading();
        try {
            const response = await network.getPDF("POST", `${network.BASE_API}/OrphyDriveReport/DrivingStudent`, Util.getEducationId(this.personId));
            if (response && !response.includes("<!DOCTYPE html>")) {
                const fileName = "Report.pdf";
                const mimeType = "application/pdf";
                new OpenFileModule(
                    response,
                    fileName,
                    mimeType,
                    () => {
                        app.mobileApp.hideLoading();
                    },
                    () => {
                        app.mobileApp.hideLoading();
                        Util.showNotification(translation.t("export.unknown-error"), "error");
                    }
                ).openFile();
            } else {
                Util.showNotification(translation.t("export.no-rights"), "info");
                app.mobileApp.hideLoading();
            }
        } catch (e) {
            if (e.responseText.includes("GET /Dashboard")) {
                Util.showNotification(translation.t("export.no-rights"), "info");
            } else {
                Util.showNotification(translation.t("util.check-internet"), "error");
            }
            app.mobileApp.hideLoading();
        }
    }

    public educationCardDirect() {
        while (activeLesson.length > 0) {
            activeLesson.pop();
        }
        activeLesson.push(new ActiveLesson(null, "views/personDetail.html", this.personId));
        this.changeView(`views/educationCard.html?navPoint=views/personDetail.html&resetLastSelectedTab=true`);
    }

    public planLesson() {
        this.changeView(`views/planLesson.html?navPoint=views/personDetail.html`);
    }

    public changeMeetingPoint() {
        const personModel = this.get<PersonDetailModel>(this.personModel);
        this.changeView(
            `views/map.html?navPoint=views/personDetail.html&address=${encodeURI(personModel.MeetingPointAddress ? personModel.MeetingPointAddress : "")}&buttonText=${translation.t(
                "person-detail.save-meeting-point"
            )}`
        );
    }

    public selectJobPosition(e: any) {
        const target = $(e.target);
        if (!target.is("button") && !target.is("i") && e.data.AllowBilling) {
            e.data.set("CheckedForAccount", !e.data.get("CheckedForAccount"));
            this.toggleAccountButton();
        }
    }

    public async deleteSubscription(e): Promise<void> {
        const { confirmed } = await Util.confirmTemplate(ConfirmContent.deletePosition(e.data.Description), translation.t("util.yes-delete-finaly"), translation.t("common.cancel"));
        if (confirmed) {
            await jobPositionService.delete(e.data.PositionId);
            await orphyDriveJobPositionService.delete(e.data.PositionId);
            const personDetail = await personService.getPersonDetailItem(Util.getEducationId(this.personId), this.personId);
            this.set(this.personModel, personDetail);
        }
    }

    private toggleAccountButton() {
        const personModel = this.get<PersonDetailModel>(this.personModel);
        let disabled = true;
        for (const jobPosition of personModel.JobPositions) {
            if (jobPosition.CheckedForAccount) {
                disabled = false;
                break;
            }
        }
        this.set("accountButtonDisabled", disabled);
    }

    public toggleCharts() {
        this.set("chartsVisible", !this.get<boolean>("chartsVisible"));
        if (this.get<boolean>("chartsVisible")) {
            $("#chartIconPD").addClass("icon-note");
            $("#chartIconPD").removeClass("icon-line-chart");
        } else {
            $("#chartIconPD").addClass("icon-line-chart");
            $("#chartIconPD").removeClass("icon-note");
        }
    }

    public changeFailedPassed(event: Event) {
        const testId = Number($(event.target).data("testid"));
        switch (testId) {
            case 0:
                this.setRadios(event, "FirstTest");
                break;
            case 1:
                this.setRadios(event, "SecondTest");
                break;
            case 2:
                this.setRadios(event, "ThirdTest");
                break;
            case 3:
                this.setRadios(event, "FourthTest");
                break;
            default:
                logger.logError(new Error(`No Test with id: ${testId}`));
        }
    }

    private setRadios(event: Event, test: string): void {
        const first = this.get<TestModel>("FirstTest");
        const second = this.get<TestModel>("SecondTest");
        const third = this.get<TestModel>("ThirdTest");
        const fourth = this.get<TestModel>("FourthTest");

        if (test === "FirstTest" && second.TestDate) {
            this.set("FirstTest.TestPassed", first.TestPassed);
            this.setTestVisible();
            return;
        }
        if (test === "SecondTest" && third.TestDate) {
            this.set("SecondTest.TestPassed", second.TestPassed);
            this.setTestVisible();
            return;
        }
        if (test === "ThirdTest" && fourth.TestDate) {
            this.set("ThirdTest.TestPassed", third.TestPassed);
            this.setTestVisible();
            return;
        }

        const radio = $(event.target);
        const isPassedRadio = radio.data("passed");
        if (!this.get<TestModel>(test).TestDate) {
            Util.showNotification(translation.t("person-detail.add-first-date"), "error");
            setTimeout(() => {
                this.set(`${test}.TestPassed`, null);
            }, 0);
            this.setTestVisible();
            return;
        } else if (radio.is(":checked") && isPassedRadio && this.get<TestModel>(test).TestPassed) {
            this.set(`${test}.TestPassed`, null);
        } else if (radio.is(":checked") && isPassedRadio && !this.get<TestModel>(test).TestPassed) {
            this.set(`${test}.TestPassed`, true);
        } else if (radio.is(":checked") && !isPassedRadio && (this.get<TestModel>(test).TestPassed === null || this.get<TestModel>(test).TestPassed === undefined)) {
            this.set(`${test}.TestPassed`, false);
        } else if (radio.is(":checked") && !isPassedRadio && this.get<TestModel>(test).TestPassed === true) {
            this.set(`${test}.TestPassed`, false);
        }
        setTimeout(async () => {
            if (this.get(`${test}.TestPassed`)) {
                await this.showConfetti();
            }
        }, 0);
        this.setTestVisible();
    }

    private setTestVisible() {
        setTimeout(() => {
            this.set("secondTestVisible", this.get<TestModel>("FirstTest").TestPassed === false);
            this.set("thirdTestVisible", this.get<TestModel>("FirstTest").TestPassed === false && this.get<TestModel>("SecondTest").TestPassed === false);
            this.set(
                "fourthTestVisible",
                this.get<TestModel>("FirstTest").TestPassed === false && this.get<TestModel>("SecondTest").TestPassed === false && this.get<TestModel>("ThirdTest").TestPassed === false
            );
        }, 0);
    }

    private async showConfetti() {
        const personModel = this.get<PersonDetailModel>(this.personModel);

        if (!this.confettiInit) {
            $.confetti.init();
            this.confettiInit = true;
        } else {
            $.confetti.start();
        }
        const { confirmed } = await Util.confirmTemplate(ConfirmContent.congratulationsScreen(personModel.FirstName, personModel.LastName), translation.t("person-detail.back-to-student"), "", true);
        if (confirmed) {
            $.confetti.stop();
        }
    }

    public accountLesson() {
        const jobPositionIds: number[] = [];
        const personModel = this.get<PersonDetailModel>(this.personModel);
        for (const jobPosition of personModel.JobPositions) {
            if (jobPosition.CheckedForAccount) {
                jobPositionIds.push(jobPosition.PositionId);
            }
        }
        this.changeView(`views/accountLesson.html?navPoint=views/personDetail.html&jobPositionIds=${JSON.stringify(jobPositionIds)}`);
    }

    private addclosePopupOnDocumentClick() {
        // timeout needed to prevent first click on the open popup button
        setTimeout(() => {
            $(document).on("click touchstart touchmove", Util.closePopupOnDocumentClick);
        }, 0);
    }

    public closeTestNotePopup() {
        this.testEditorPopup.closePopup();
    }

    public async setTestNote(event, test: string) {
        const popup = $("#popupTestNotePD");
        await this.testEditorPopup.renderContentEditPopup("#popupTestNotePD");
        popup.data("testname", test);

        $("#noteAreaPD").val(this.get<TestModel>(test).TestNote);
        $("#noteAreaPD").focus();
    }

    public saveTestNote() {
        const test = $("#popupTestNotePD").data("testname");
        const note = $("#noteAreaPD").val();
        $("#noteAreaPD").blur();

        this.set(`${test}.TestNote`, note);
        this.testEditorPopup.closePopup();
    }

    public async openPersonNote() {
        await this.notePopup.renderContentEditPopup("#popupNotePD");
        const personModel = this.get<PersonDetailModel>(this.personModel);
        if (personModel.Education.Note === translation.t("common.no-comment")) {
            this.editor.value("");
        } else {
            this.editor.value(personModel.Education.Note);
        }
    }

    public closeNotePopup() {
        const personModel = this.get<PersonDetailModel>(this.personModel);
        this.editor.value(personModel.Education.Note);
        this.notePopup.closePopup();
    }

    public async saveNote() {
        const personModel = this.get<PersonDetailModel>(this.personModel);
        await educationService.update("Id", personModel.Education.Id, new Map<string, any>().set("Note", Util.HTMLEncode(this.editor.value())), true);
        personModel.Education.set("Note", this.editor.value());
        this.notePopup.closePopup();
    }

    public openNativeMap(e) {
        const address = $(e.target).closest("button").data("address").toString();
        window.open(Util.getMapURIScheme(address), "_system");
    }

    public getSeriesColor(event) {
        return event.series.color;
    }

    public getSeriesLabel(event) {
        return `${event.value} %`;
    }

    private createCharts(rootItems: ChecklistModel[], ratings: RatingModel[]): ChartModel[] {
        return rootItems.filter(x => x.items.length > 0).map((rootItem, index) => this.createChart(rootItem, ratings, index));
    }

    private createChart(rootItem: ChecklistModel, ratings: RatingModel[], index: number): ChartModel {
        const chart = new ChartModel();
        chart.ChartTitle = rootItem.Label;
        chart.ChapterId = rootItem.Id.toString();
        chart.Index = index;
        const chartRatings = [
            new SeriesModel(Util.getRatingColor(0)),
            new SeriesModel(Util.getRatingColor(1)),
            new SeriesModel(Util.getRatingColor(2)),
            new SeriesModel(Util.getRatingColor(3)),
            new SeriesModel(Util.getRatingColor(4)),
            new SeriesModel(Util.getRatingColor(5))
        ];
        for (const checklistItem of rootItem.items) {
            const rating = this.getNewestRating(checklistItem.Id, ratings);
            if (rating && rating.RatingValue) {
                chartRatings[rating.RatingValue].Rating += 1;
            } else {
                chartRatings[0].Rating += 1;
            }
        }
        chart.Data = chartRatings;
        chart.Width = Math.floor(window.innerWidth < window.innerHeight ? window.innerWidth / 3 : window.innerHeight / 3);
        chart.Height = chart.Width;
        // donut is ~70% of width
        const donutOuterRadius = ((chart.Width / 100) * 70) / 2;
        chart.HoleSize = Math.floor(donutOuterRadius - 20);
        chart.LabelDistance = Math.floor(window.innerWidth / 3 / 100);

        this.scaleTo100(chart.Data); // Ensures that the total of the percentages is always 100
        return chart;
    }

    private getNewestRating(checklistItemId: number, ratings: RatingModel[]): RatingModel {
        return Util.firstOrDefault(ratings.filter(x => x.ChecklistItemId === checklistItemId));
    }

    public scaleTo100(data: SeriesModel[]) {
        if (data.length > 0) {
            // Calculate total of values
            let total = 0.0;
            for (const series of data) {
                total += series.Rating;
            }
            if (total > 0) {
                // Prevent division by zero
                // Scale all values to get a total of 100
                let sum = 0;
                let maxId = 0;
                for (let k = 0; k < data.length; k++) {
                    data[k].Rating = Math.round((data[k].Rating / total) * 100);
                    sum += data[k].Rating;
                    if (data[k].Rating >= data[maxId].Rating) {
                        maxId = k;
                    }
                }
                // Manipulate largest value to reach a total of exactly 100
                data[maxId].Rating = data[maxId].Rating + 100 - sum;
            }
        }
        return data;
    }

    private async createNoteList(rootItems: ChecklistModel[], ratings: RatingModel[]): Promise<DashboardNotesModel[]> {
        const checklistItems = rootItems.map(x => x.items).flat();
        const dashboardNotes: DashboardNotesModel[] = [];

        for (const rating of ratings) {
            const checklistItem = checklistItems.find(x => x.Id === rating.ChecklistItemId);
            if (!checklistItem) {
                // checklistItem deleted, skip
                continue;
            }
            rating.ChecklistItemLabel = checklistItem.Label;

            if (!dashboardNotes.some(x => x.LessonDate === rating.LessonDate)) {
                const newNoteModel = new DashboardNotesModel();
                newNoteModel.LessonDate = rating.LessonDate;
                newNoteModel.Ratings = [];
                dashboardNotes.push(newNoteModel);
            }
            const dashboardNote = dashboardNotes.find(x => x.LessonDate === rating.LessonDate);
            dashboardNote.Ratings.push(rating);
        }

        const lessonIds = dashboardNotes.map(x => Util.firstOrDefault(x.Ratings).LessonId);
        const lessons = await lessonService.getItems(`Id IN ${Util.joinIds(lessonIds)}`);
        for (const dasbhoardNote of dashboardNotes) {
            const lesson = lessons.find(x => x.Id === Util.firstOrDefault(dasbhoardNote.Ratings).LessonId);
            if (lesson) {
                dasbhoardNote.LessonNote = lesson.Note ? lesson.Note : "";
            }
        }

        return dashboardNotes.map(notesModel => {
            notesModel.Ratings.sort((a, b) => checklistItems.findIndex(x => x.Id === a.ChecklistItemId) - checklistItems.findIndex(x => x.Id === b.ChecklistItemId));
            return notesModel;
        });
    }

    public async createFinalAccount() {
        const personModel = this.get<PersonModel>(this.personModel);
        const { confirmed } = await Util.confirmTemplate(ConfirmContent.createFinalBill(personModel.FirstName, personModel.LastName), translation.t("person-detail.generate-bill"), translation.t("common.cancel"));
        if (!confirmed || Util.isDemo()) {
            return;
        }
        try {
            $("#finalAccountScreen").data().kendoMobileModalView.open();
            const billId = await billService.createFinalAccount(Util.getEducationId(this.personId));
            if (billId) {
                const billItem = await billService.getBillItemById(billId);
                if (billItem.TotalPrice !== 0) {
                    const bill = await billService.getItemById(billId);
                    bill.SendBill = true;
                    await billService.updateEntity(bill, false);
                }
                this.changeView(`views/paperworkBills.html?navPoint=views/personDetail.html%3FspecificTab%3DsubsTabButtonPD&billId=${billItem.CustomId}`);
                Util.showNotification(translation.t("person-detail.bill-added"), "success");
            } else {
                Util.showNotification(translation.t("person-detail.bill-already-exist"), "info");
            }
        } catch (e) {
            logger.logDebug(e);
            Util.showNotification(translation.t("util.check-internet"), "error");
        } finally {
            $("#finalAccountScreen").data().kendoMobileModalView.close();
        }
    }

    public completedDrivingLessons() {
        this.changeView(`views/completedDrivingLessons.html?navPoint=views/personDetail.html`);
    }

    public goToeducationCardAtSpecificPosition(event) {
        while (activeLesson.length > 0) {
            activeLesson.pop();
        }
        activeLesson.push(new ActiveLesson(null, "views/personDetail.html", this.personId));
        const chapterId = $(event.target).closest(".dashboard-graphics-container").data("chapterlabel");
        this.changeView(`views/educationCard.html?scrollPosition=${chapterId}&navPoint=views/personDetail.html&resetLastSelectedTab=true`);
    }

    public navigateToBillsSpecificBill(e) {
        this.changeView(`views/paperworkBills.html?navPoint=views/personDetail.html%3FspecificTab%3DsubsTabButtonPD&billId=${e.data.BillCustomId}`);
    }

    public editPerson() {
        const params: RegisterPersonNavParams = {
            NavPoint: "views/personDetail.html",
            Update: true
        };
        navParams.setNavParam(nameof(RegisterPerson), params);
        app.mobileApp.navigate("views/registerPerson.html");
    }

    public back(): void {
        this.changeView(this.navPoint);
    }

    private changeView(navPoint: string): void {
        app.mobileApp.navigate(navPoint);
    }

    public hideView(): void {
        this.resetRadios();
        this.lfaPopup.destroy();
        this.notePopup.destroy();
        this.QRCodePopup.destroy();
        $("#QRCodePD").empty();
        this.testEditorPopup.destroy();
    }

    private resetRadios() {
        for (let i = 0; i < 4; i++) {
            $(`#failed${i}`).attr("waschecked", null).prop("checked", false);
            $(`#passed${i}`).attr("waschecked", null).prop("checked", false);
        }
    }
}
