import OrphyDriveSubscriptionModel from "../models/OrphyDriveSubscriptionModel";
import { network } from "../modules/Network";
import OpenFileModule from "../modules/OpenFileModule";
import { Util } from "../modules/Util";
import { translation } from "../services/TranslationService";
import { IOrphyDriveSubscriptionDto } from "../types/OrphyDriveSubscription";
import { BaseView } from "./BaseView";

export default class OrphyDriveSubscription extends BaseView {
    private readonly NAV_POINT = "views/settings.html";

    public async loadData(): Promise<void> {
        // nothing to load
    }

    private translateHeaderValue(e): string {
        return `<div class="smallText">${translation.t(`orphy-drive-subscription.${e.field}-${e.value}`)}</div>`;
    }

    public initView(): void {
        $("#orphyDriveSubscriptionListView").kendoMobileListView({
            pullToRefresh: true,
            filterable: {
                field: "Name",
                placeholder: translation.t("common.placeholder-search"),
                ignoreCase: true
            },
            messages: {
                releaseTemplate: translation.t("orphy-drive-subscription.release-to-update"),
                pullTemplate: translation.t("orphy-drive-subscription.pull-to-update"),
                refreshTemplate: translation.t("orphy-drive-subscription.update")
            },
            template: kendo.template($("#orphyDriveSubscriptionTemplate").html()),
            headerTemplate: this.translateHeaderValue,
            fixedHeaders: true,
            dataSource: kendo.data.DataSource.create({
                type: "odata-v4",
                group: [{ field: "BillStatus" }],
                transport: {
                    read: async options => {
                        const subscriptions = await network.getItems<IOrphyDriveSubscriptionDto[]>(`${network.ODATA_API_V3}/Subscription`);
                        options.success(subscriptions.map(x => new OrphyDriveSubscriptionModel(x)));
                    }
                } as kendo.data.DataSourceOptions
            })
        });
        $("#orphyDriveSubscriptionView").show();
    }

    public async getDocument(id: number): Promise<void> {
        if (id <= 0) {
            return;
        }
        app.mobileApp.showLoading();
        try {
            const response = await network.get<{ value: string }>(`${network.ODATA_API_V3}/SubscriptionBill?id=${id}`);
            if (response.value) {
                const data = response.value;
                const fileName = "OrphyDriveLizenz.pdf";
                const mimeType = "application/pdf";
                new OpenFileModule(
                    data,
                    fileName,
                    mimeType,
                    () => {
                        app.mobileApp.hideLoading();
                    },
                    () => {
                        app.mobileApp.hideLoading();
                        Util.showNotification(translation.t("exportunknown-error"), "error");
                    }
                ).openFile();
            } else {
                Util.showNotification(translation.t("util.check-internet"), "error");
                app.mobileApp.hideLoading();
            }
        } catch (e) {
            Util.showNotification(translation.t("util.check-internet"), "error");
            app.mobileApp.hideLoading();
        }
    }

    public getNavPoint() {
        return this.NAV_POINT;
    }
}
