import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseChecklistRating {
    RatingValue: number;
    Note: string;
    LessonId: number;
    NewRating: boolean;
    ChecklistItemId: number;
}

export interface IChecklistRatingDto extends IBaseDto, IBaseChecklistRating {}

export interface IChecklistRating extends IBaseEntity, IBaseChecklistRating {
    IsCurrentRating: boolean;

    IsUpdateRating: boolean;
    OldNote: string;
    OldRatingValue: number;
}

export default class ChecklistRating extends BaseEntity<IChecklistRating, IChecklistRatingDto> implements IChecklistRating {
    public static get EntityTypeId(): string {
        return "2344CA7D-F4C3-411E-A8CD-32D24F104B6B";
    }

    public RatingValue: number;
    public Note: string;
    public LessonId: number;
    public NewRating: boolean;
    public ChecklistItemId: number;
    public IsCurrentRating: boolean;
    public OldNote: string;
    public OldRatingValue: number;

    public IsUpdateRating: boolean;

    public populateFromDb(dbItem: IChecklistRating): void {
        super.populateFromDb(dbItem);
        this.RatingValue = dbItem.RatingValue;
        this.Note = dbItem.Note;
        this.LessonId = dbItem.LessonId;
        this.NewRating = this.getDbBoolean(dbItem.NewRating);
        this.ChecklistItemId = dbItem.ChecklistItemId;
        this.IsCurrentRating = this.getDbBoolean(dbItem.IsCurrentRating);

        this.IsUpdateRating = this.getDbBoolean(dbItem.IsUpdateRating);
        this.OldNote = dbItem.OldNote;
        this.OldRatingValue = dbItem.OldRatingValue;
    }

    public populateFromOData(oDataItem: IChecklistRatingDto): void {
        super.populateFromOData(oDataItem);
        this.NewRating = oDataItem.NewRating;
        if (oDataItem.NewRating) {
            this.RatingValue = oDataItem.RatingValue;
        } else {
            switch (oDataItem.RatingValue) {
                case 2:
                    this.RatingValue = 3;
                    break;
                case 3:
                    this.RatingValue = 5;
                    break;
                default:
                // do nothing mapping correct
            }
        }
        this.Note = oDataItem.Note;
        this.LessonId = oDataItem.LessonId;
        this.ChecklistItemId = oDataItem.ChecklistItemId;
        this.IsCurrentRating = false;

        this.IsUpdateRating = false;
        this.OldNote = null;
        this.OldRatingValue = null;
    }

    public toODataObject(): IChecklistRatingDto {
        const oDataObject = super.toODataObject();
        oDataObject.RatingValue = this.RatingValue;
        oDataObject.Note = this.Note;
        oDataObject.LessonId = this.LessonId;
        oDataObject.NewRating = true;
        oDataObject.ChecklistItemId = this.ChecklistItemId;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<ChecklistRating>(x => x.RatingValue),
            this.RatingValue
        );
        dbObject.set(
            nameof<ChecklistRating>(x => x.Note),
            this.Note
        );
        dbObject.set(
            nameof<ChecklistRating>(x => x.LessonId),
            this.LessonId
        );
        dbObject.set(
            nameof<ChecklistRating>(x => x.NewRating),
            true
        );
        dbObject.set(
            nameof<ChecklistRating>(x => x.ChecklistItemId),
            this.ChecklistItemId
        );
        dbObject.set(
            nameof<ChecklistRating>(x => x.IsCurrentRating),
            this.IsCurrentRating
        );
        dbObject.set(
            nameof<ChecklistRating>(x => x.IsUpdateRating),
            this.IsUpdateRating
        );
        dbObject.set(
            nameof<ChecklistRating>(x => x.OldNote),
            this.OldNote
        );
        dbObject.set(
            nameof<ChecklistRating>(x => x.OldRatingValue),
            this.OldRatingValue
        );
        return dbObject;
    }
}
