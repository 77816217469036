import { translation } from "../services/TranslationService";
import { Util } from "./Util";
// eslint-disable-next-line @typescript-eslint/no-namespace
export module Photo {
    export async function takePhoto(): Promise<string> {
        return new Promise(res => {
            navigator.camera.getPicture(
                (data: string) => res(`data:image/jpeg;base64,${data}`),
                () => {
                    Util.showNotification(translation.t("confirm.procedure-stopped"), "info");
                    res("");
                },
                {
                    quality: 70,
                    encodingType: Camera.EncodingType.JPEG,
                    targetWidth: 1024,
                    targetHeight: 768,
                    destinationType: Camera.DestinationType.DATA_URL
                }
            );
        });
    }
}
