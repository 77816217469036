/* eslint-disable @typescript-eslint/dot-notation */
import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

export interface IBaseJobPosition {
    Position: string;
    Discriminator: string;
    SortOrder: number;
    UnitId: number;
    ParentId: number;
}

export interface IJobPositionDto extends IBaseDto, IBaseJobPosition {
    Amount: number;
    Price: number;
}

export interface IJobPosition extends IBaseEntity, IBaseJobPosition {
    Parent: IJobPosition;
    Children: IJobPosition[];
    createChargingPosition(parentId: number, amount?: number): IJobPosition;
}

export abstract class JobPosition<TEntity extends IJobPosition, TDto extends IJobPositionDto> extends BaseEntity<TEntity, TDto> implements IJobPosition {
    constructor() {
        super();
        this.SortOrder = 0;
    }

    public abstract odataTypeName(): string;

    public static get EntityTypeId(): string {
        return "a1b2a812-4bac-5ce7-97c0-958cbf9052db";
    }

    public abstract get Discriminator(): string;

    public Position: string;
    public SortOrder: number;
    public UnitId: number;
    public ParentId: number;

    public Parent: TEntity;
    public Children: TEntity[] = [];

    public populateFromDb(dbItem: TEntity): void {
        super.populateFromDb(dbItem);
        this.Position = dbItem.Position;
        this.SortOrder = dbItem.SortOrder;
        this.UnitId = dbItem.UnitId;
        this.ParentId = dbItem.ParentId;
    }

    public populateFromOData(oDataItem: TDto): void {
        super.populateFromOData(oDataItem);
        this.Position = oDataItem.Position;
        this.SortOrder = oDataItem.SortOrder;
        this.UnitId = oDataItem.UnitId;
        this.ParentId = oDataItem.ParentId;
    }

    public toODataObject(): TDto {
        const oDataObject = super.toODataObject();
        oDataObject["@odata.type"] = this.odataTypeName();
        oDataObject.Position = this.Position;
        oDataObject.SortOrder = this.SortOrder;
        oDataObject.UnitId = this.UnitId;
        oDataObject.ParentId = this.ParentId;
        oDataObject["Optional"] = false;

        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<JobPosition<IJobPosition, IJobPositionDto>>(x => x.Discriminator),
            this.Discriminator
        );
        dbObject.set(
            nameof<JobPosition<IJobPosition, IJobPositionDto>>(x => x.Position),
            this.Position
        );
        dbObject.set(
            nameof<JobPosition<IJobPosition, IJobPositionDto>>(x => x.SortOrder),
            this.SortOrder
        );
        dbObject.set(
            nameof<JobPosition<IJobPosition, IJobPositionDto>>(x => x.UnitId),
            this.UnitId
        );
        dbObject.set(
            nameof<JobPosition<IJobPosition, IJobPositionDto>>(x => x.ParentId),
            this.ParentId
        );
        return dbObject;
    }

    public abstract createChargingPosition(parentId: number, amount?: number): IJobPosition;

    protected copyFrom(entity: TEntity): void {
        this.Position = entity.Position;
        this.SortOrder = entity.SortOrder;
        this.UnitId = entity.UnitId;
        this.ParentId = entity.ParentId;
    }
}
