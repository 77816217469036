module.exports = function () {
    const conf = {
        Version: ENV_VERSION,
        Production: ENV_MODE === "production"
    };

    switch (ENV_ENDPOINT) {
        case "local":
            conf.App = "https://80-niggi.cluyo.ch/orphy";
            conf.Base_Api = "https://80-niggi.cluyo.ch/orphyapi/drive/v2";
            conf.Api = `${conf.Base_Api}/Drive`;
            conf.Public_Api = "https://80-niggi.cluyo.ch/orphyapi/api/v1";
            conf.OdataApi_v3 = "https://80-niggi.cluyo.ch/orphyapi/odata/v3";
            conf.Idsrv = "https://dev.idsrv.orphy.com";
            return conf;
        case "cyril":
            conf.App = "https://80-cyril.cluyo.ch/orphy";
            conf.Base_Api = "https://80-cyril.cluyo.ch/orphyapi/drive/v2";
            conf.Api = `${conf.Base_Api}/Drive`;
            conf.Public_Api = "https://80-cyril.cluyo.ch/orphyapi/api/v1";
            conf.OdataApi_v3 = "https://80-cyril.cluyo.ch/orphyapi/odata/v3";
            conf.Idsrv = "https://dev.idsrv.orphy.com";
            return conf;
        case "dev":
            conf.App = "https://dev.orphy.com";
            conf.Base_Api = "https://dev.api.orphy.com/drive/v2";
            conf.Api = `${conf.Base_Api}/Drive`;
            conf.Public_Api = "https://dev.api.orphy.com/api/v1";
            conf.OdataApi_v3 = "https://dev.api.orphy.com/odata/v3";
            conf.Idsrv = "https://dev.idsrv.orphy.com";
            return conf;
        case "stage":
            conf.App = "https://stage.orphy.com";
            conf.Base_Api = "https://stage.api.orphy.com/drive/v2";
            conf.Api = `${conf.Base_Api}/Drive`;
            conf.Public_Api = "https://stage.api.orphy.com/api/v1";
            conf.OdataApi_v3 = "https://stage.api.orphy.com/odata/v3";
            conf.Idsrv = "https://dev.idsrv.orphy.com";
            return conf;
        case "production":
            conf.App = "https://app.orphy.com";
            conf.Base_Api = "https://api.orphy.com/drive/v2";
            conf.Api = `${conf.Base_Api}/Drive`;
            conf.Public_Api = "https://api.orphy.com/api/v1";
            conf.OdataApi_v3 = "https://api.orphy.com/odata/v3";
            conf.Idsrv = "https://idsrv.orphy.com";
            return conf;
        default:
            throw new Error("endpoint not defined check package.json");
    }
};
