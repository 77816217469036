import AllPersonItemModel from "../models/AllPersonItemModel";
import DateTime from "../modules/DateTime";
import { personAddressService } from "../services/PersonAddressService";
import { translation } from "../services/TranslationService";
import ListExport from "./ListExport";

export default class AllPersonExport extends ListExport {
    public async createExport(args: any[]): Promise<void> {
        const persons = args[0] as AllPersonItemModel[];

        const personAddresses = await personAddressService.getItems();

        const sheets: any[] = [];
        const rows: any[] = [];
        const colSpan = 6;
        const columns = [{ autoWidth: true }, { autoWidth: true }, { autoWidth: true }, { autoWidth: true }, { autoWidth: true }, { autoWidth: true }];
        const fileName = `${translation.t("export.all-student-filename-prefix")}-${DateTime.parseNumberDate(new Date())}.xlsx`;

        rows.push({
            cells: [
                { value: translation.t("register-person.last-name"), bold: true },
                { value: translation.t("register-person.first-name"), bold: true },
                { value: translation.t("common.phone"), bold: true },
                { value: translation.t("common.e-mail"), bold: true },
                { value: translation.t("common.place"), bold: true },
                { value: translation.t("common.category"), bold: true }
            ]
        });

        for (const person of persons) {
            const personAddress = personAddresses.find(x => x.PersonId === person.Id);

            rows.push(this.empty(colSpan));
            rows.push({
                cells: [
                    { value: person.LastName },
                    { value: person.FirstName },
                    { value: person.PhoneNumber ? person.PhoneNumber : "" },
                    { value: person.Email ? person.Email : "" },
                    { value: personAddress && personAddress.City ? personAddress.City : "" },
                    { value: person.ChecklistNames ? person.ChecklistNames.replace("&ouml;", "\u00f6") : "" }
                ]
            });
        }
        sheets.push({ columns: columns, rows: rows, showGridLines: true, title: translation.t("export.all-student-title") });
        this.openExcel(sheets, fileName);
    }
}
