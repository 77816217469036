import ActiveLesson, { activeLesson } from "../modules/ActiveLesson";
import Popup from "../modules/Popup";
import { Util } from "../modules/Util";
import { checklistCollectionService } from "../services/ChecklistCollectionService";
import { educationService } from "../services/EducationService";
import { jobPositionService } from "../services/JobPositionService";
import { lessonService } from "../services/LessonService";
import { orphyDriveJobPositionService } from "../services/OrphyDriveJobPositionService";
import { personService } from "../services/PersonService";
import { timeTrackingService } from "../services/TimeTrackingService";
import { BaseView } from "./BaseView";
import CompletedDrivingLessonExport from "../export/CompletedDrivingLessonExport";
import ConfirmContent from "../modules/ConfirmContent";
import { translation } from "../services/TranslationService";
import { kendoEvent } from "../typings/kendoEvent";
import TimeTrackingModel from "../models/TimeTrackingModel";

export default class CompletedDrivingLessons extends BaseView {
    public personName: string;
    public firstTitle: string = translation.t("completed-driving-lessons.current-month");
    public personAvatar: string;
    public checklistName: string = "";

    private timeTrackingsDataSource: kendo.data.DataSource;
    private navPoint: string;
    private listView: kendo.mobile.ui.ListView;
    private missedNotePopup: Popup;

    constructor() {
        super();
        this.missedNotePopup = new Popup();
        super.init(this);
    }

    public initView(): void {
        $("#listViewTimeTracking").show();
    }

    public async loadData(): Promise<void> {
        this.set(
            "timeTrackingsDataSource",
            kendo.data.DataSource.create({
                group: { field: "Sort", dir: "desc" },
                transport: {
                    read: async options => {
                        options.success(await timeTrackingService.getTimeTrackingsItemsByEducationId(Util.getEducationId(this.personId)));
                    }
                }
            } as any)
        );

        await this.timeTrackingsDataSource.read();
        const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
        const lessonCount = await lessonService.getLessonCount(Util.getEducationId(this.personId), this.personId);
        const missedLessonCount = await lessonService.getMissedLessonCount(Util.getEducationId(this.personId), this.personId);
        const education = await educationService.getSelectedEducation(Util.getEducationId(this.personId), this.personId);
        const checklist = await checklistCollectionService.getItemById(education.ChecklistCollectionId);
        this.set("checklistName", checklist ? checklist.Name : translation.t("completed-driving-lessons.checklist-deleted"));
        this.set("personName", `${person.FirstName} ${person.LastName}`);
        this.set("personAvatar", Util.getAvatar(person.FirstName, person.LastName));
        this.set("lessonCount", lessonCount + missedLessonCount);
        this.set("lessonCountText", translation.t("completed-driving-lessons.lesson-completed", { count: lessonCount }));
    }

    public showView(e: any): void {
        this.navPoint = e.view.params.navPoint ? e.view.params.navPoint : this.navPoint;
        Util.setTableWidths(4, "#completedDrivingLessonsMasterElement");
        this.listView = $("#listViewCompletedDrivingLessons").data("kendoMobileListView");
        (this.listView as any).scroller().reset();
    }

    public showNote = (event: kendoEvent<TimeTrackingModel>) => {
        $("#popupNoteCompletedLessonsConent").val(event.data.MissedReason || translation.t("completed-driving-lessons.no-reason"));
        this.missedNotePopup.renderFixedPositionPopup("#popupNoteCompletedLessons", event, true, false);
    };

    public showLessonNote = (event: kendoEvent<TimeTrackingModel>) => {
        $("#popupNoteCompletedLessonsConent").val(event.data.GlobalLessonNote);
        this.missedNotePopup.renderFixedPositionPopup("#popupNoteCompletedLessons", event, true, false);
    };

    public closePopup = () => {
        this.missedNotePopup.closePopup();
    };

    public editMissedLesson = (e: kendoEvent<TimeTrackingModel>) => {
        while (activeLesson.length > 0) {
            activeLesson.pop();
        }
        activeLesson.push(new ActiveLesson(null, "views/personDetail.html", this.personId, true));
        app.mobileApp.navigate(`views/enterLessons.html?navPoint=views/completedDrivingLessons.html&updateLesson=true&isMissedLesson=true&timeTrackingId=${e.data.TimeTrackingId}`);
    };

    public editLesson = async (e: kendoEvent<TimeTrackingModel>): Promise<void> => {
        app.mobileApp.showLoading();
        await lessonService.setUpdateLesson(e.data.TimeTrackingId, Util.getEducationId(this.personId));
        app.mobileApp.hideLoading();
        app.mobileApp.navigate(`views/educationCard.html?updateLesson=true`);
    };

    public deleteEntry = async (e: kendoEvent<TimeTrackingModel>): Promise<void> => {
        const deleteLesson = (await Util.confirmTemplate(ConfirmContent.deleteLessonAndTimetracking(), translation.t("util.yes-delete-finaly"), translation.t("common.cancel"))).confirmed;
        if (deleteLesson) {
            await this.deleteLesson(e.data.TimeTrackingId);
            await this.deleteJobPositions(e.data.TimeTrackingId);
            await timeTrackingService.delete(e.data.TimeTrackingId);
            await this.removeTimeTrackingIdFromOrphyDriveJobPosition(e.data.TimeTrackingId);
        }
        await this.loadData();
    };

    private async removeTimeTrackingIdFromOrphyDriveJobPosition(timeTrackingId: number): Promise<void> {
        const orphyDriveJobPositions = await orphyDriveJobPositionService.getItemsByTimeTrackingId(timeTrackingId);
        for (const orphyDriveJobPositon of orphyDriveJobPositions) {
            orphyDriveJobPositon.TimeTrackingId = null;
            await orphyDriveJobPositionService.updateEntity(orphyDriveJobPositon);
        }
    }

    private async deleteLesson(timeTrackingId: number): Promise<void> {
        const lesson = await lessonService.getLessonByTimeTrackingId(timeTrackingId, Util.getEducationId(this.personId));
        if (lesson) {
            await lessonService.delete(lesson.Id);
            const lessonCount = await lessonService.getLessonCount(Util.getEducationId(this.personId), this.personId);
            if (lessonCount === 0) {
                this.back();
            }
        }
    }

    private async deleteJobPositions(timeTrackingId: number): Promise<void> {
        const positions = (await jobPositionService.getPositionsChargedByTimetrackingId(timeTrackingId, Util.getEducationId(this.personId))).filter(p => p.AllowBilling);
        if (positions.length) {
            const { confirmed } = await Util.confirmTemplate(ConfirmContent.deletePositionsForLesson(), translation.t("util.yes"), translation.t("util.no"));
            if (confirmed) {
                for (const position of positions) {
                    await jobPositionService.delete(position.PositionId);
                    await orphyDriveJobPositionService.delete(position.PositionId);
                }
            }
        }
    }

    public getNavPoint(): string {
        return this.navPoint;
    }

    public async exportTimeTracking(): Promise<void> {
        const lessonCount = await lessonService.getLessonCount(Util.getEducationId(this.personId), this.personId);
        const missedLessonCount = await lessonService.getMissedLessonCount(Util.getEducationId(this.personId), this.personId);
        const timetrackings = await timeTrackingService.getTimeTrackingsItemsByEducationId(Util.getEducationId(this.personId));
        await new CompletedDrivingLessonExport().export(timetrackings, lessonCount, missedLessonCount, this.personId, this.get<string>("checklistName"));
    }

    public hideView(): void {
        this.missedNotePopup.destroy();
    }
}
