import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import BaseEntity, { IBase, IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseJobBase extends IBase {
    PaymentWayId: number;
    Name: string;
    Date: string | Date;
    PersonId: number;
    ConditionId: number;
    CurrencyId: number;
    CurrencyValueId: number;
    CustomId: string;
    Anschrift: string;
    ContactPersonId: number;
    DokumentKopfZeile: string;
    DocumentAfterList: string;
    DokumentFussZeile: string;
}

export interface IJobBaseDto extends IBaseDto, IBaseJobBase {}

export interface IJobBase extends IBaseEntity, IBaseJobBase {}

export default class JobBase<TEntity extends IJobBase, TDto extends IJobBaseDto> extends BaseEntity<TEntity, TDto> implements IJobBase {
    public PaymentWayId: number;
    public Name: string;
    public Date: Date | string;
    public PersonId: number;
    public ConditionId: number;
    public CurrencyId: number;
    public CurrencyValueId: number;
    public CustomId: string;
    public Anschrift: string;
    public ContactPersonId: number;
    public DokumentKopfZeile: string;
    public DokumentFussZeile: string;
    public DocumentAfterList: string;

    public populateFromDb(dbItem: TEntity): void {
        super.populateFromDb(dbItem);
        this.PaymentWayId = dbItem.PaymentWayId;
        this.Name = dbItem.Name;
        this.Date = DateTime.fromDbDateTime(dbItem.Date as string);
        this.PersonId = dbItem.PersonId;
        this.ConditionId = dbItem.ConditionId;
        this.CurrencyId = dbItem.CurrencyId;
        this.CurrencyValueId = dbItem.CurrencyValueId;
        this.CustomId = dbItem.CustomId;
        this.Anschrift = dbItem.Anschrift;
        this.ContactPersonId = dbItem.ContactPersonId;
        this.DokumentKopfZeile = dbItem.DokumentKopfZeile;
        this.DokumentFussZeile = dbItem.DokumentFussZeile;
        this.DocumentAfterList = dbItem.DocumentAfterList;
    }

    public populateFromOData(oDataItem: TDto): void {
        super.populateFromOData(oDataItem);
        this.PaymentWayId = oDataItem.PaymentWayId;
        this.Name = oDataItem.Name;
        this.Date = DateTime.fromODataDateTime(oDataItem.Date as string);
        this.PersonId = oDataItem.PersonId;
        this.ConditionId = oDataItem.ConditionId;
        this.CurrencyId = oDataItem.CurrencyId;
        this.CurrencyValueId = oDataItem.CurrencyValueId;
        this.CustomId = oDataItem.CustomId;
        this.Anschrift = oDataItem.Anschrift;
        this.ContactPersonId = oDataItem.ContactPersonId;
        this.DokumentKopfZeile = oDataItem.DokumentKopfZeile;
        this.DokumentFussZeile = oDataItem.DokumentFussZeile;
        this.DocumentAfterList = oDataItem.DocumentAfterList;
    }

    public toODataObject(): TDto {
        const oDataObject = super.toODataObject();
        oDataObject.PaymentWayId = this.PaymentWayId;
        oDataObject.Name = this.Name;
        oDataObject.Date = DateTime.toODataDateTime(this.Date as Date);
        oDataObject.PersonId = this.PersonId;
        oDataObject.ConditionId = this.ConditionId;
        oDataObject.CurrencyId = this.CurrencyId;
        oDataObject.CurrencyValueId = this.CurrencyValueId;
        oDataObject.DokumentKopfZeile = this.DokumentKopfZeile;
        oDataObject.DocumentAfterList = this.DocumentAfterList;
        oDataObject.DokumentFussZeile = this.DokumentFussZeile;
        oDataObject.Anschrift = this.Anschrift;
        oDataObject.ContactPersonId = this.ContactPersonId;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.PaymentWayId),
            this.PaymentWayId
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.Name),
            this.Name
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.Date),
            DateTime.toDbDateTime(this.Date as Date)
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.PersonId),
            this.PersonId
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.ConditionId),
            this.ConditionId
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.CurrencyId),
            this.CurrencyId
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.CurrencyValueId),
            this.CurrencyValueId
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.CustomId),
            this.CustomId
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.Anschrift),
            this.Anschrift
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.ContactPersonId),
            this.ContactPersonId
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.DokumentKopfZeile),
            this.DokumentKopfZeile
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.DocumentAfterList),
            this.DocumentAfterList
        );
        dbObject.set(
            nameof<JobBase<IJobBase, IJobBaseDto>>(x => x.DokumentFussZeile),
            this.DokumentFussZeile
        );
        return dbObject;
    }
}
