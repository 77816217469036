import DateTime from "../modules/DateTime";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { IBaseDto, IBaseEntity } from "../types/BaseEntity";
import { SettingsService } from "./SettingsService";

export abstract class AppSettingsService<TEntity extends IBaseEntity, TDto extends IBaseDto> extends SettingsService<TEntity, TDto> {
    private getAppSettingsOdataRoute(): string {
        return `${network.ODATA_API_V3}/GetAppSetting`;
    }

    private getAppSettingsModifiedSinceOdataRoute(): string {
        return `${network.ODATA_API_V3}/GetAppSettingModifiedSince`;
    }

    private setAppSettingsOdataRoute(): string {
        return `${network.ODATA_API_V3}/SetAppSetting`;
    }

    protected abstract getAppSettingsKey(): string;

    public getApiRoute(): string {
        return null; // Not used
    }

    protected async getSettingsOdata(): Promise<any> {
        return network.get(`${this.getAppSettingsOdataRoute()}?appId=${Util.getAppGUID()}&key=${this.getAppSettingsKey()}`);
    }

    protected async getSettingsModifiedSinceOdata(lastSyncDate: string): Promise<any> {
        const serviceUrl = `${this.getAppSettingsModifiedSinceOdataRoute()}?appId=${Util.getAppGUID()}&key=${this.getAppSettingsKey()}&date='${DateTime.getModifiedSinceRequestDate(lastSyncDate)}'`;
        const data = await network.get<any>(serviceUrl);
        return data.value;
    }

    protected patchSettingsOdata = async (settings: TEntity): Promise<boolean> => {
        await network.send("POST", this.setAppSettingsOdataRoute(), settings.toODataObject());
        return true;
    };
}
