import { network } from "../modules/Network";
import DrivingStudentDocument, {
    IAttachementInfoDto as IAttachmentInfoDto,
    IDmsDocumentGroup,
    IDrivingStudentDocument,
    IDrivingStudentDocumentDto,
    IDrivingStudentDocumentUploadResponseDto,
    MetaData
} from "../types/DrivingStudentDocument";
import Person from "../types/Person";
import { BaseService } from "./BaseService";
import { personService } from "./PersonService";
import { v4 as uuidv4 } from "uuid";
import { Util } from "../modules/Util";
import { keys } from "ts-transformer-keys";

export default class DrivingStudentDocumentService extends BaseService<IDrivingStudentDocument, IDrivingStudentDocumentDto> {
    public getTableName(): string {
        return "DrivingStudentDocuments";
    }

    public getDtoFields() {
        return keys<IDrivingStudentDocumentDto>();
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        params.push(true);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE IsNew = ? OR IsDirty = ?`;
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IDrivingStudentDocument>());
    }

    public getApiRoute(): string {
        return `${network.PUBLIC_API}/Attachment`;
    }

    protected createEntityFromDb(item: IDrivingStudentDocument): IDrivingStudentDocument {
        const entity = new DrivingStudentDocument();
        entity.populateFromDb(item);
        return entity;
    }

    protected createEntityFromOData(item: IDrivingStudentDocumentDto): IDrivingStudentDocument {
        const entity = new DrivingStudentDocument();
        entity.populateFromOData(item);
        return entity;
    }

    public async pullItems(): Promise<void> {
        // not pulling items
    }

    public async pushItems(): Promise<number> {
        const newDocuments = await this.getItems("IsNew = ?", null, null, null, null, [true]);
        for (const newDocument of newDocuments) {
            const person = await personService.getItemById(newDocument.PersonId);
            const form = new FormData();
            const file = new Blob([await (await fetch(newDocument.Image)).blob()], { type: "image/jpeg" });
            form.append("files", file, `lfa_${uuidv4()}.jpg`);
            form.append("groupId", DrivingStudentDocument.GROUP_ID);
            const uploadedFiles = await network.postForm<IDrivingStudentDocumentUploadResponseDto[]>(`${this.getApiRoute()}/Upload?entityId=${person.Uuid}&entityTypeId=${Person.EntityTypeId}`, form);
            const uploadedFile = Util.firstOrDefault(uploadedFiles);
            newDocument.BlobId = Number(uploadedFile.id);
            newDocument.IsNew = false;
            await this.updateEntity(newDocument, false);
        }
        return this.patchItems();
    }

    public async patchItems(): Promise<number> {
        const deletedDocuments = await this.getDeletedItems();
        for (const deletedDocument of deletedDocuments) {
            const person = await personService.getItemById(deletedDocument.PersonId);
            try {
                await network.send("POST", `${this.getApiRoute()}/Delete?entityTypeId=${Person.EntityTypeId}&entityId=${person.Uuid}`, { Id: deletedDocument.BlobId });
            } catch (e) {
                // attachement can't be deleted twice
            }
            await this.hardDelete(deletedDocument.Id);
        }
        return 0;
    }

    public getDocuments(personId: number): Promise<IDrivingStudentDocument[]> {
        return this.getItems(`PersonId = ${personId}`, "BlobId ASC");
    }

    public async fetchDocuments(personId: number, changedBlobs: string): Promise<void> {
        const blobIds = this.getBlobIds(changedBlobs);
        const blobs = await this.getItemsIncludingDeleted(`PersonId = ? AND IsNew = ?`, null, null, "Id, BlobId, IsDeleted", null, [personId, false]);

        for (const blob of blobs) {
            if (!blobIds.some(x => x === blob.BlobId)) {
                await this.hardDelete(blob.Id);
            }
        }

        for (const blobId of blobIds) {
            if (this.blobExists(blobs, blobId)) {
                continue;
            }

            try {
                const info = await network.get<IAttachmentInfoDto>(`${this.getApiRoute()}/GetAttachmentInfo?id=${blobId}`);

                if (!this.containsDrivingStudentDocumentGroup(info.documentGroups) || !this.isImage(info.metaData)) {
                    continue;
                }
                const blob = await network.getBlob(`${this.getApiRoute()}/DownloadAttachment?id=${blobId}`);
                const studentDocument = new DrivingStudentDocument();
                studentDocument.BlobId = blobId;
                studentDocument.PersonId = personId;
                studentDocument.IsNew = false;
                studentDocument.Image = await this.blobToBase64(blob);
                await this.insert(studentDocument);
            } catch (e) {
                continue;
            }
        }
    }

    private containsDrivingStudentDocumentGroup(documentGroups: IDmsDocumentGroup[]) {
        return documentGroups.some(x => Util.compareGuids(x.id, DrivingStudentDocument.GROUP_ID));
    }

    private blobExists(blobs: IDrivingStudentDocument[], blobId: number) {
        return blobs.some(x => x.BlobId === blobId);
    }

    private isImage(metaData: MetaData) {
        return metaData.ContentType && metaData.ContentType.startsWith("image");
    }

    public blobToBase64(blob: Blob): Promise<string> {
        return new Promise(res => {
            const reader = new FileReader();
            reader.onloadend = () => res(reader.result as string);
            reader.readAsDataURL(blob);
        });
    }

    private getBlobIds(blobs: string): number[] {
        if (!blobs) {
            return [];
        }
        return blobs.split(",").map(x => Number(x));
    }

    public async delete(id: number): Promise<void> {
        const document = await this.getItemById(id);
        if (document.IsNew) {
            return this.hardDelete(id);
        }
        return this.update("Id", id, new Map<string, any>().set("IsDeleted", true), true);
    }
}

export const drivingStudentDocumentService = new DrivingStudentDocumentService();
