import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";
import { translation } from "../services/TranslationService";

export enum AddressKind {
    Custom,
    Address
}

interface IBaseAddressType {}

export interface IAddressTypeDto extends IBaseDto, IBaseAddressType {
    TranslatedDisplayName: string;
    Kind: AddressKind;
}
export interface IAddressType extends IBaseEntity, IBaseAddressType {
    Name: string;
    Type: AddressKind;
}

export default class AddressType extends BaseEntity<IAddressType, IAddressTypeDto> implements IAddressType {
    public static get EntityTypeId(): string {
        return "44caea2b-9ad2-523b-9a79-085fecceb539";
    }

    public Name: string;
    public Type: AddressKind;

    public populateFromDb(dbItem: IAddressType): void {
        super.populateFromDb(dbItem);
        this.Type = dbItem.Type;
        if (dbItem.Type === AddressKind.Custom || dbItem.Type === null) {
            this.Name = dbItem.Name;
        } else {
            this.Name = translation.t(`adress-type.type_${dbItem.Type}`);
        }
    }

    public populateFromOData(oDataItem: IAddressTypeDto): void {
        super.populateFromOData(oDataItem);
        this.Type = oDataItem.Kind;
        if (oDataItem.Kind === AddressKind.Custom) {
            this.Name = oDataItem.TranslatedDisplayName;
        } else {
            this.Name = translation.t(`adress-type.type_${oDataItem.Kind}`);
        }
    }

    public toODataObject(): IAddressTypeDto {
        return null; // AddressTypes are not pushed to Orphy
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<AddressType>(x => x.Name),
            this.Name
        );
        dbObject.set(
            nameof<AddressType>(x => x.Type),
            this.Type
        );
        return dbObject;
    }
}
