export default class CalendarListModel {
    id: number;
    isChecked: boolean;
    name: string;
    constructor(id, name, isChecked) {
        this.id = id;
        this.isChecked = isChecked;
        this.name = name;
    }
}
