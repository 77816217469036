import NoteModel from "../models/NoteModel";
import RatingModel from "../models/RatingModel";
import { Util } from "../modules/Util";
import { checklistItemService } from "../services/ChecklistItemService";
import { ratingService } from "../services/ChecklistRatingService";
import { translation } from "../services/TranslationService";
import { BaseView } from "./BaseView";

export default class ChecklistNotes extends BaseView {
    public readonly notes: NoteModel[];
    public updateLesson: boolean;

    public async loadData(e: any): Promise<void> {
        this.updateLesson = e.view.params.updateLesson === "true";
        const checklistItemId = Number(e.view.params.checklistItemId);
        this.set("chaptertitle", (await checklistItemService.getItemById(checklistItemId, "Label")).Label);
        if (this.updateLesson) {
            this.set("ratingModel", await ratingService.getUpdateNote(checklistItemId, Util.getEducationId(this.personId)));
        } else {
            this.set("ratingModel", await ratingService.getCurrentNote(checklistItemId, Util.getEducationId(this.personId)));
            this.set("previousRatings", await ratingService.getPreviousNotes(checklistItemId, Util.getEducationId(this.personId)));
        }
    }

    public async updateNote() {
        const ratingModel = this.get<RatingModel>("ratingModel");
        ratingModel.IsCurrentRating = !this.updateLesson;

        if (!ratingModel.Id && !ratingModel.Note) {
            this.back();
        } else if (!ratingModel.Id && ratingModel.Note) {
            await ratingService.insert(ratingModel.toEntity());
        } else if (!ratingModel.Note && !ratingModel.RatingValue) {
            if (ratingModel.OldNote || ratingModel.OldRatingValue) {
                const entity = ratingModel.toEntity();
                if (entity.Id) {
                    await ratingService.updateEntity(entity, true);
                }
            } else {
                await ratingService.delete(ratingModel.Id);
            }
        } else {
            const entity = ratingModel.toEntity();
            if (entity.Id) {
                await ratingService.updateEntity(entity, true);
            }
        }
        this.backToEducationCard();
    }

    private backToEducationCard = () => {
        Util.showNotification(translation.t("util.note-saved"), "success");
        this.back();
    };

    public getNavPoint(): string {
        if (this.updateLesson) {
            return `views/educationCard.html?updateLesson=${this.updateLesson}&fromNotes=true`;
        }
        return "views/educationCard.html?fromNotes=true";
    }
}
