/* eslint-disable no-console */
import { dal } from "../dal/Dal";
import { checklistItemService } from "../services/ChecklistItemService";
import { educationService } from "../services/EducationService";
import { jobPositionService } from "../services/JobPositionService";
import { personService } from "../services/PersonService";
import { syncService } from "../services/SyncService";

export class Debug {
    static async logNegativeLessonCount(): Promise<void> {
        const negativeLessonCounts: string[] = [];
        const persons = await personService.getItems(null, null, null, "Id,FirstName,LastName");
        for (const person of persons) {
            const educations = await educationService.getEducationsByPersonId(person.Id);
            for (const education of educations) {
                const balance = await jobPositionService.getAvailableBalance(education.Id, person.Id);
                if (balance < 0) {
                    negativeLessonCounts.push(`${person.FirstName} ${person.LastName}: ${balance}`);
                }
            }
        }
        for (const negativeLessonCount of negativeLessonCounts) {
            console.log(negativeLessonCount);
        }
    }

    static async fixChapterSort(parentId: number): Promise<void> {
        const checklistitems = await checklistItemService.getItems(`ParentId = ${parentId}`, null, null, "Id,PreviousSiblingId,NextSiblingId");
        for (let i = 0; i < checklistitems.length; i++) {
            const current = checklistitems[i];
            const update = new Map<string, any>();
            try {
                update.set("PreviousSiblingId", checklistitems[i - 1].Id);
            } catch (e) {
                update.set("PreviousSiblingId", null);
            }

            try {
                update.set("NextSiblingId", checklistitems[i + 1].Id);
            } catch (e) {
                update.set("NextSiblingId", null);
            }
            await checklistItemService.update("Id", current.Id, update, true);
        }
    }

    public static async getDirtyItemsCount(): Promise<void> {
        const dirtyStatements = syncService.getDirtyItemsCountStatements();
        const items = await dal.executeRead(`SELECT TableName, DirtyCount FROM (${dirtyStatements.statements.join(" UNION ALL ")})`, dirtyStatements.params);
        if (items.length) {
            let output = "";
            for (const item of items) {
                if (parseInt(item.DirtyCount) > 0) {
                    output += `${item.TableName}: ${item.DirtyCount}\r\n`;
                }
            }
            console.log(output || "no changes");
        }
    }
}
