import TimeTrackingGroupModel from "../models/TimeTrackingGroupModel";
import DateTime from "../modules/DateTime";
import { translation } from "../services/TranslationService";
import ListExport from "./ListExport";

export default class TimeTrackingExport extends ListExport {
    public async createExport(args: any[]): Promise<void> {
        const timeTrackingGroups = args[0] as TimeTrackingGroupModel[];

        const sheets: any[] = [];
        let rows: any[] = [];
        const columns = [{ width: 200 }, { width: 100 }, { width: 100 }];
        const colSpan = 3;
        const fileName = `${translation.t("export.timetracking-filename-prefix")}-${DateTime.parseNumberDate(new Date())}.xlsx`;

        rows.push({
            cells: [
                { value: translation.t("common.subject"), bold: true },
                { value: translation.t("common.start"), bold: true },
                { value: translation.t("common.end"), bold: true },
                { value: translation.t("common.category"), bold: true }
            ]
        });

        for (const group of timeTrackingGroups) {
            rows.push(this.empty(colSpan));
            rows.push({ cells: [{ value: group.HeaderName, colSpan: colSpan, bold: true }] });
            rows.push(this.empty(colSpan));

            for (const timeTracking of group.TimeTrackingItems) {
                rows.push({
                    cells: [
                        { value: `${timeTracking.Title}${timeTracking.MissedReason ? `: ${timeTracking.MissedReason}` : ""}` },
                        { value: timeTracking.StartTime },
                        { value: timeTracking.EndTime },
                        { value: timeTracking.MissedReason ? "" : timeTracking.ChecklistName ? timeTracking.ChecklistName.replace("&ouml;", "\u00f6") : "" }
                    ]
                });
            }
            if (group.DailyTotal.Total) {
                rows.push(this.empty(colSpan));
                rows.push({
                    cells: [
                        { value: translation.t("common.total-day"), colSpan: 1, bold: true },
                        { value: group.DailyTotal.Title, colSpan: 2, bold: true }
                    ]
                });
            }
            if (group.WeeklyTotal.Total) {
                rows.push({
                    cells: [
                        { value: translation.t("common.total-week"), colSpan: 1, bold: true },
                        { value: group.WeeklyTotal.Title, colSpan: 2, bold: true }
                    ]
                });
            }
            if (group.MonthlyTotal.Total) {
                rows.push({
                    cells: [
                        { value: translation.t("common.total-month"), colSpan: 1, bold: true },
                        { value: group.MonthlyTotal.Title, colSpan: 2, bold: true }
                    ]
                });
                sheets.push({ columns: columns, rows: rows, showGridLines: true, title: this.getSheetTitle(group.HeaderName) });
                rows = [];
                rows.push({
                    cells: [
                        { value: translation.t("common.subject"), bold: true },
                        { value: translation.t("common.start"), bold: true },
                        { value: translation.t("common.end"), bold: true }
                    ]
                });
            }
        }
        this.openExcel(sheets, fileName);
    }
}
