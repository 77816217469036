import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import { network } from "../modules/Network";
import Bill, { BillStatus, IBill } from "./Bill";
import JobBase, { IJobBase, IJobBaseDto } from "./JobBase";
import { translation } from "../services/TranslationService";

export enum JobStatus {
    Offen,
    Teilweise,
    Erledigt,
    Storniert,
    NurRechnung
}

interface IBaseJob {
    JobStatus: JobStatus;
}

export interface IJobDto extends IJobBaseDto, IBaseJob {}
export interface IJob extends IJobBase, IBaseJob {
    createBill(billDate: Date, paidAt: Date, paymentConditionId: number, checklistCollectionName: string, paymentWayId: number): IBill;
}

export default class Job extends JobBase<IJob, IJobDto> implements IJob {
    public static get EntityTypeId(): string {
        return "9869dc74-f589-5bfa-9bc3-71e76dbb5e40";
    }

    public JobStatus: JobStatus;

    public populateFromDb(dbItem: IJob): void {
        super.populateFromDb(dbItem);
        this.JobStatus = dbItem.JobStatus;
    }

    public populateFromOData(oDataItem: IJobDto): void {
        super.populateFromOData(oDataItem);
        this.JobStatus = oDataItem.JobStatus;
    }

    public toODataObject(): IJobDto {
        const oDataObject = super.toODataObject();
        oDataObject.JobStatus = this.JobStatus;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Job>(x => x.JobStatus),
            this.JobStatus
        );
        return dbObject;
    }

    public createBill(billDate: Date, paidAt: Date, paymendConditionId: number, checklistCollectionName: string, paymentWayId: number = null): IBill {
        const bill = new Bill();
        // Copy common data
        bill.PaymentWayId = this.PaymentWayId;
        bill.Name = this.Name.split(translation.t("job.abos-of")).join(`${translation.t("job.replace-abos-of")} - ${checklistCollectionName} `);
        bill.PersonId = this.PersonId;
        bill.ConditionId = paymendConditionId;
        bill.CurrencyId = this.CurrencyId;
        bill.CurrencyValueId = this.CurrencyValueId;
        bill.Anschrift = this.Anschrift;
        bill.ContactPersonId = network.getUserId() || this.ContactPersonId;

        if (paymentWayId !== null) {
            bill.PaymentWayId = paymentWayId;
        }

        bill.BillStatus = BillStatus.Open;
        bill.CustomId = null;
        bill.Date = DateTime.convertToUtcDate(billDate);
        bill.PaidAt = paidAt;
        bill.JobMasterId = this.Id;
        return bill;
    }
}
