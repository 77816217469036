import { keys } from "ts-transformer-keys";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import Unit, { IUnit, IUnitDto, UnitType } from "../types/Unit";
import { BaseNoHistoryService } from "./BaseNoHistoryService";

export default class UnitService extends BaseNoHistoryService<IUnit, IUnitDto> {
    private readonly TABLE_NAME = "units";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IUnitDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IUnit>());
    }

    public getApiRoute(): string {
        return `${network.API}/${Unit.EntityTypeId}`;
    }

    protected createEntityFromDb(item: IUnit): Unit {
        const unitEntity = new Unit();
        unitEntity.populateFromDb(item);
        return unitEntity;
    }

    protected createEntityFromOData(item: IUnitDto): Unit {
        const unitEntity = new Unit();
        unitEntity.populateFromOData(item);
        return unitEntity;
    }

    public getDefaultUnit = async (): Promise<IUnit> => {
        const items = await this.getItems(`Type = ${UnitType.Pieces}`, null, 1);
        return Util.firstOrDefault(items);
    };
}

export const unitService = new UnitService();
