import LfaModel from "../models/LfaModel";
import OpenFileModule from "../modules/OpenFileModule";
import { Photo } from "../modules/Photo";
import Popup from "../modules/Popup";
import { Util } from "../modules/Util";
import { drivingStudentDocumentService } from "../services/DrivingStudentDocumentService";
import { lfaService } from "../services/LfaService";
import { translation } from "../services/TranslationService";
import DrivingStudentDocument from "../types/DrivingStudentDocument";
import { BaseView } from "./BaseView";

export default class LfaView extends BaseView {
    private readonly lfaModel = "model";
    private popupImage: Popup;
    private popupAddIamge: Popup;
    private navPoint: string;
    private selectedDocumentId: number;

    constructor() {
        super();
        this.popupImage = new Popup();
        this.popupAddIamge = new Popup();
        super.init(this);
    }

    protected getComputedFields(map: Map<string, string>): Map<string, string> {
        super.getComputedFields(map);
        map.set("LfaImage", "model");
        return map;
    }

    public async loadData(e: any): Promise<void> {
        this.navPoint = e.view.params.navPoint ? e.view.params.navPoint : "views/allPersons.html";
        const documents = await drivingStudentDocumentService.getDocuments(this.personId);
        this.set("documents", documents);
        this.set(this.lfaModel, await lfaService.getLfaModel(Util.getEducationId(this.personId)));
    }

    public async saveOrUpdate() {
        const lfa = this.get<LfaModel>(this.lfaModel).toEntity();
        await lfaService.insertOrUpdate(lfa);
        app.mobileApp.navigate("views/personDetail.html");
    }

    public focusDate() {
        Util.focusRegisterFormDate("lfaExpireDate");
    }

    public getNavPoint(): string {
        return this.navPoint;
    }

    public showPopupImage(e) {
        const selectedDocument = e.data as DrivingStudentDocument;
        this.selectedDocumentId = selectedDocument.Id;
        this.popupImage.renderFixedPositionPopup("#popupImage", e, true, false);
    }

    public closePopupImage() {
        this.popupImage.closePopup();
    }

    public showAddImagePopup(e) {
        this.popupAddIamge.renderFixedPositionPopup("#popupAddIamge", e, true, false);
    }

    public closePopupAddImage() {
        this.popupAddIamge.closePopup();
    }

    public async takeLFAImage() {
        this.popupAddIamge.closePopup();
        const imageData = await Photo.takePhoto();
        await this.insertDocument(imageData);
    }

    public async selectLFAImage() {
        this.popupAddIamge.closePopup();
        try {
            const image = await Util.getPhotoFromLibrary();
            const resizedImage = await Util.ResizeBase64Image(image);
            await this.insertDocument(resizedImage);
        } catch (e) {
            Util.showNotification(translation.t("util.galery-not-open"), "error");
        }
    }

    private async insertDocument(imageData: string) {
        app.mobileApp.showLoading();
        const document = new DrivingStudentDocument();
        document.Image = Util.addBase64MetaIfNotPresent(imageData);
        document.PersonId = this.personId;
        await drivingStudentDocumentService.insert(document);
        this.set("documents", await drivingStudentDocumentService.getDocuments(this.personId));
        app.mobileApp.hideLoading();
    }

    public deleteLFAImage = async () => {
        this.popupImage.closePopup();
        app.mobileApp.showLoading();
        const documents = this.get<DrivingStudentDocument[]>("documents");
        this.set(
            "documents",
            documents.filter(x => x.Id !== this.selectedDocumentId)
        );
        await drivingStudentDocumentService.delete(this.selectedDocumentId);
        app.mobileApp.hideLoading();
    };

    public exportLFAImage() {
        this.popupImage.closePopup();
        const document = this.get<DrivingStudentDocument[]>("documents").find(x => x.Id === this.selectedDocumentId);

        new OpenFileModule(
            document.Image.substring(document.Image.indexOf(";base64,") + 8),
            `${translation.t("lfa-view.export-image-file-name")}.jpg`,
            "image/jpeg",
            () => {
                app.mobileApp.hideLoading();
            },
            () => {
                app.mobileApp.hideLoading();
                Util.showNotification(translation.t("export.unknown-error"), "error");
            }
        ).openFile();
    }

    public hideView(): void {
        this.popupImage.destroy();
    }
}
