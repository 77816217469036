import { keys } from "ts-transformer-keys";
import EducationModel from "../models/EducationModel";
import PersonDetailEducationModel from "../models/PersonDetailEducationModel";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import Education, { IEducation, IEducationDto } from "../types/Education";
import { IDiffDto } from "../types/IDiffDto";
import PlanLesson from "../viewModels/PlanLesson";
import { BaseService } from "./BaseService";
import { checklistCollectionService } from "./ChecklistCollectionService";
import { drivingTestService } from "./DrivingTestService";
import { jobService } from "./JobService";
import { lessonService } from "./LessonService";
import { lfaService } from "./LfaService";
import { testService } from "./TestService";
import { userAppSettingsService } from "./UserAppSettingsService";
import { nameof } from "ts-simple-nameof";
import { personService } from "./PersonService";

export default class EducationService extends BaseService<IEducation, IEducationDto> {
    private readonly TABLE_NAME = "Educations";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IEducationDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IEducation>());
    }

    public getApiRoute(): string {
        return `${network.API}/${Education.EntityTypeId}`;
    }

    protected createEntityFromDb(item: IEducation): Education {
        const currencyEntity = new Education();
        currencyEntity.populateFromDb(item);
        return currencyEntity;
    }

    public createEntityFromOData(item: IEducationDto): Education {
        const currencyEntity = new Education();
        currencyEntity.populateFromOData(item);
        return currencyEntity;
    }

    protected async handleDeletedItems(items: IDiffDto[]): Promise<void> {
        for (const item of items) {
            if (item.ChangedProperties.IsDeleted) {
                const education = await this.getItemById(item.Id);
                if (item.Id === Util.getEducationId(education.PersonId)) {
                    Util.setEducationId(null, education.PersonId);
                }
            }
        }
        await super.handleDeletedItems(items);
    }

    protected async updateDependencies(oldId: number, newId: number, updateSelf: boolean): Promise<void> {
        await super.updateDependencies(oldId, newId, updateSelf);

        const updateFK = new Map<string, any>().set("EducationId", newId);

        await lessonService.update("EducationId", oldId, updateFK, false);

        const updateId = new Map<string, any>().set("Id", newId);
        await testService.update("DrivingTestId", oldId, new Map<string, any>().set("DrivingTestId", newId), false);

        await lfaService.update("Id", oldId, updateId, false);
        await drivingTestService.update("Id", oldId, updateId, false);

        const education = await this.getItemById(newId);
        Util.setEducationId(education.Id, education.PersonId);

        const userAppSettings = await userAppSettingsService.getSettings();
        const oldKey = PlanLesson.getStoredLessonKey(education.PersonId, oldId);
        const newKey = PlanLesson.getStoredLessonKey(education.PersonId, newId);
        const plannedLesson = userAppSettings.PlannedLessons.get(oldKey);
        if (plannedLesson) {
            userAppSettings.PlannedLessons.delete(oldKey);
            userAppSettings.PlannedLessons.set(newKey, plannedLesson);
            await userAppSettingsService.updateEntity(userAppSettings);
        }
    }

    public async personHasEducation(personId: number): Promise<boolean> {
        const educations = await this.getEducationsByPersonId(personId);
        const checklistCollections = await checklistCollectionService.getItems();
        const hasEducation = educations.filter(e => checklistCollections.some(c => c.Id === e.ChecklistCollectionId)).length > 0;

        if (!hasEducation) {
            Util.setEducationId(null, personId);
        }
        return hasEducation;
    }

    protected async handleModifiedItem(item: IDiffDto): Promise<void> {
        const education = await this.getItemById(item.Id);
        await super.handleModifiedItem(item);
        if (item.ChangedProperties.hasOwnProperty(nameof<Education>(x => x.PersonId)) && item.ChangedProperties.PersonId !== education.PersonId) {
            if (!(await this.personHasEducation(education.PersonId))) {
                await personService.hardDelete(education.PersonId);
            }
        }
    }

    public async isValidEducation(educationId: number, personId: number): Promise<boolean> {
        const education = Util.firstOrDefault(await this.getItems(`Id = ${educationId} AND PersonId = ${personId}`));
        return !!education;
    }

    public async getSelectedEducationModel(educationId: number, personId: number): Promise<EducationModel> {
        const education = await this.getSelectedEducation(educationId, personId);
        return new EducationModel(education);
    }

    public async getSelectedEducation(educationId: number, personId: number): Promise<IEducation> {
        const personEducations = await this.getItems(`PersonId = ${personId}`);
        const checklistCollections = await checklistCollectionService.getItems();
        const defaultChecklist = Util.firstOrDefault(personEducations.filter(e => checklistCollections.some(c => c.Id === e.ChecklistCollectionId)));
        const education = educationId ? (await this.getItemById(educationId)) ?? defaultChecklist : defaultChecklist;

        if (education && !education.JobId) {
            const job = await jobService.createJobForPerson(education);
            education.JobId = job.Id;
            await this.updateEntity(education, true);
        }

        if (education) {
            Util.setEducationId(education.Id, personId);
        }
        return education;
    }

    public async getEducationsByPersonId(personId: number): Promise<IEducation[]> {
        return this.getItems(`PersonId = ${personId}`);
    }

    public async gedEducationByJobId(jobId: number): Promise<IEducation> {
        return Util.firstOrDefault(await this.getItems(`JobId = ${jobId}`));
    }

    public async getDashboardEducationModelsByPersonId(personId: number): Promise<PersonDetailEducationModel[]> {
        const educations = await this.getEducationsByPersonId(personId);
        const checklistCollections = await checklistCollectionService.getItems();
        return educations
            .filter(e => checklistCollections.some(c => c.Id === e.ChecklistCollectionId))
            .map(e => new PersonDetailEducationModel(e.Id, checklistCollections.find(x => x.Id === e.ChecklistCollectionId).Name));
    }
}

export const educationService = new EducationService();
