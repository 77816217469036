export default class NavParams {
    private readonly navParams = new Map<string, any>();

    public setNavParam(viewName: string, params: any) {
        this.navParams.set(viewName, params);
    }

    public deleteNavParam(viewName: string) {
        this.navParams.delete(viewName);
    }

    public getNavParam<T>(viewName: string): T {
        return this.navParams.get(viewName) as T;
    }
}

export const navParams = new NavParams();
