import { orientationChange } from "../modules/OrientationChange";
import Popup from "../modules/Popup";
import { Util } from "../modules/Util";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { companySettingsService } from "../services/CompanySettingsService";
import { conditionService } from "../services/ConditionService";
import { translation } from "../services/TranslationService";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { userSettingsService } from "../services/UserSettingsService";
import CompanyAppSettings, { ICompanyAppSettings } from "../types/CompanyAppSettings";
import Condition from "../types/Condition";
import UserAppSettings from "../types/UserAppSettings";
import UserSettings, { IUserSettings } from "../types/UserSettings";
import { BaseView } from "./BaseView";

export default class BillSettings extends BaseView {
    private readonly NAV_POINT = "views/settings.html";

    public conditions: Condition[];
    public selectedCondition: number;
    public companyName: string;
    public userAppSettings: UserAppSettings;
    public companyContact: string;
    public companyFullAddress: string;
    public showPopup: boolean;

    private billEmail: kendo.data.ObservableArray;
    private isInitialized: boolean = false;
    private oldValues: Map<string, string>;

    private userSettings: UserSettings;
    private companyAppSettings: CompanyAppSettings;

    private validator: kendo.ui.Validator;

    private companyLogoPopup: Popup;
    private editorPopup: Popup;

    constructor() {
        super();
        this.companyLogoPopup = new Popup();
        this.editorPopup = new Popup();
        this.oldValues = new Map<string, string>();
        super.init(this);
    }

    public initView(): void {
        if ($("#billSettingsContent").length) {
            Util.renderView("billSettingsTemplate", this, $("#billSettingsContent"));
        }
    }

    public async loadData(): Promise<void> {
        if (!this.isInitialized) {
            this.initEditors();
            this.validator = $(".billSettingsTemplateContent").kendoValidator().data().kendoValidator;
            this.validator.bind("validateInput", Util.validateInput);
            this.isInitialized = true;
        }
        const conditions = await conditionService.getItems();
        const userSettings = await userSettingsService.getSettings();
        const userAppSettings = await userAppSettingsService.getSettings();
        const companySettings = await companySettingsService.getSettings();
        const companyAppSettings = await companyAppSettingsService.getSettings();
        this.set("conditions", conditions);
        this.setDefaultValues(userSettings, companyAppSettings);
        this.set("userSettings", userSettings);
        this.set("userAppSettings", userAppSettings);
        this.set("companyAppSettings", companyAppSettings);
        this.set("companyName", companySettings.CompanyName);
        this.setCombinedFields();
        const tempEmails = [];
        if (userAppSettings.BillEmail) {
            userAppSettings.BillEmail.forEach(email => {
                tempEmails.push({ email: email });
            });
        }
        this.set("billEmail", tempEmails);
    }

    public showView(): void {
        Util.enableFocusStateFeature($("input"));
        Util.enableFocusStateFeature($("textarea"));
        $(".inputFieldContainer").on("click", Util.focusInput);
        this.createBillPreview();
        orientationChange.FunctionList = [this.createBillPreview];
    }

    public getCompanyLogoPreview() {
        return `data:image/jpeg;base64,${this.get<string>("userSettings.DefaultCompanyLogo_INTERNAL")}`;
    }

    private setDefaultValues(userSettings: IUserSettings, companyAppSettings: ICompanyAppSettings) {
        if (!companyAppSettings.PaymentConditionId) {
            companyAppSettings.PaymentConditionId = 1;
        }

        if (!userSettings.DefaultCompanyLogo_INTERNAL) {
            userSettings.DefaultCompanyLogo_INTERNAL = "";
            userSettings.DefaultCompanyLogo = null;
        }
    }

    private setCombinedFields() {
        this.set("companyContact", `Telefon<br/>Email`);
        this.set("companyFullAddress", `<b>${this.get<string>("companyName")}</b><br/>Strasse<br/>PLZ Ort`);
    }

    private initEditors = () => {
        const leadingIn = this.getEditor("#billLeadingInBillSettings");
        this.addEditorListeners($(leadingIn.body), "billLeadingInBillSettings");

        const endText = this.getEditor("#billEndTextBillSettings");
        this.addEditorListeners($(endText.body), "billEndTextBillSettings");

        const paymentDetail = this.getEditor("#billPaymentDetailsBillSettings");
        this.addEditorListeners($(paymentDetail.body), "billPaymentDetailsBillSettings");
    };

    private getEditor(selector: string): kendo.ui.Editor {
        return $(selector)
            .kendoEditor({
                tools: []
            })
            .data("kendoEditor");
    }

    private addEditorListeners = (element: JQuery, selector: string) => {
        element.attr("onclick", `billSettingsModel.focusEditor(${selector});`);
        element
            .focus(() => {
                this.editorFocus(selector);
            })
            .blur(() => {
                this.editorBlur();
            });
    };

    private editorFocus = (selector: string) => {
        $(".inputFieldContainerFocused").removeClass("inputFieldContainerFocused");
        $(`#${selector}`).parent().addClass("inputFieldContainerFocused");
        $(".billLayoutPreviewElementActive").removeClass("billLayoutPreviewElementActive");
        $(`.${selector.substr(0, selector.indexOf("BillSettings"))}`).addClass("billLayoutPreviewElementActive");
    };

    private editorBlur = () => {
        $(".inputFieldContainerFocused").removeClass("inputFieldContainerFocused");
    };

    public focusEditor = (editor: kendo.ui.Editor) => {
        editor.focus();
        $(".editorToolbarWindow").remove();
    };

    public closePopup = e => {
        const elementName = $(e.currentTarget).data("parameter");
        const element = $(`#${elementName}`);
        element.removeClass("showPopup");
    };

    public openCompanyLogoPopup = e => {
        this.companyLogoPopup.renderFixedPositionPopup("#popupImageCompanyLogo", e, true, false);
    };

    public closeCompanyLogoPoup = () => {
        this.companyLogoPopup.closePopup();
    };

    public deleteCompanyLogo = () => {
        this.closeCompanyLogoPoup();
        this.set("userSettings.DefaultCompanyLogo_INTERNAL", "");
        this.set("userSettings.DefaultCompanyLogo", null);
        this.trigger("change", { field: "userSettings" });
    };

    public async selectCompanyLogo() {
        this.closeCompanyLogoPoup();
        try {
            const image = await Util.getPhotoFromLibrary();
            const resizedImage = await Util.ResizeBase64Image(image);
            this.set("userSettings.DefaultCompanyLogo_INTERNAL", resizedImage);
            const A4Container = $(".billLayout");
            const A4width = 210;
            const A4height = A4width * Math.sqrt(2);
            A4Container.css("height", A4Container.width() * Math.sqrt(2));
            const billLogoHeight = (A4Container.height() / A4height) * 20;
            $("#companyLogoBillSettings").css("max-height", billLogoHeight);
            this.trigger("change", { field: "userSettings" });
        } catch (e) {
            Util.showNotification("Gallerie konnte nicht geöffnet werden.", "error");
        }
    }

    public addEmail = () => {
        if (this.validator.validate() && this.checkEmailInputFilled()) {
            this.billEmail.push({ email: "" });
        }
    };

    public openOrphyDrive = () => {
        window.open("http://www.orphydrive.ch", "_system");
    };

    public deleteEmail = e => {
        this.billEmail.remove(e.data);
    };

    private checkEmailInputFilled = (): boolean => {
        for (const mail of this.billEmail as any) {
            if (!mail.email) {
                Util.showNotification("Bitter erst leere felder ausfüllen", "error");
                return false;
            }
        }
        return true;
    };

    public save = async (showMessage: boolean = true): Promise<void> => {
        if (!this.validator.validate()) {
            return;
        }

        const billSettingsUpdate = new Map<string, any>();
        billSettingsUpdate.set("DefaultCompanyLogo_INTERNAL", this.userSettings.get("DefaultCompanyLogo_INTERNAL"));
        billSettingsUpdate.set("DefaultCompanyLogo", this.userSettings.get("DefaultCompanyLogo"));
        billSettingsUpdate.set("Dokument_Rechnung_Kopfzeile", Util.replaceParagraph(this.userSettings.get("Dokument_Rechnung_Kopfzeile")));
        billSettingsUpdate.set("Dokument_Rechnung_Fusszeile", Util.replaceParagraph(this.userSettings.get("Dokument_Rechnung_Fusszeile")));
        billSettingsUpdate.set("Dokument_Rechnung_Konditionen", Util.replaceParagraph(this.userSettings.get("Dokument_Rechnung_Konditionen")));

        const companyAppSettingsUpdate = new Map<string, any>();
        companyAppSettingsUpdate.set("PaymentConditionId", this.companyAppSettings.get("PaymentConditionId"));

        const userAppSettingsUpdate = new Map<string, any>();
        const tempMail: string[] = [];
        this.billEmail.forEach((item: any) => {
            const { email } = item;
            if (email && !tempMail.includes(email)) {
                tempMail.push(email);
            }
        });
        userAppSettingsUpdate.set("BillEmail", JSON.stringify(tempMail));

        await userSettingsService.updateSettings(billSettingsUpdate);
        await companyAppSettingsService.updateSettings(companyAppSettingsUpdate);
        await userAppSettingsService.updateSettings(userAppSettingsUpdate);
        if (showMessage) {
            Util.showNotification(translation.t("bill-settings.saved-successfully"), "success");
            this.back();
        }
    };

    public closeBillLayoutPopup = () => {
        this.oldValues.forEach((value, field) => {
            this.set(field, value);
        });
        $(".billLayoutPreviewElementActive").removeClass("billLayoutPreviewElementActive");
        this.setCombinedFields();
        this.editorPopup.closePopup();
    };

    public openLayoutPopup = async (e: Event) => {
        if (this.editorPopup.isPopupOpen()) {
            this.closeBillLayoutPopup();
        }
        this.saveOldValues(e);
        if (!this.validator.validate()) {
            return;
        }
        const selector = $(e.currentTarget).data("parameter").toString();
        const popup = $("#popupBillLayout");
        const editor = $(`#${selector}`).data("kendoEditor");

        $(".billLayoutPopupEdtior").hide();
        $(`.${selector}`).show();
        $(".billLayoutPreviewElementActive").removeClass("billLayoutPreviewElementActive");
        $(e.currentTarget).addClass("billLayoutPreviewElementActive");

        if (selector === "billLeadingInBillSettings") {
            popup.data("anredehandling", true);
            editor.value(this.removeAllAnredeTags(editor.value()));
        }

        if (selector === "billContactBillSettings") {
            if (!$("#phoneMobileProfile").data() || !$("#phoneMobileProfile").data().plugin_intlTelInput) {
                $("#phoneMobileProfile").intlTelInput(Util.getIntlOptions());
            }
            $("#phoneMobileProfile").intlTelInput("setNumber", this.get<string>("userSettings.CompanyPhone") || "");
            popup.data("phonenumberhandling", true);
        }
        await this.editorPopup.renderContentEditPopup("#popupBillLayout");
    };

    private saveOldValues = (e: Event) => {
        const fields: string[] = $(e.currentTarget).data("fields").split(", ");
        this.oldValues = new Map<string, string>();
        for (const field of fields) {
            this.oldValues.set(field, this.get(field));
        }
    };

    private removeAllAnredeTags = (input: string): string => {
        let editorValue = Util.replaceAll(input, "[Anrede mit Name]<br /><br />", "");
        editorValue = Util.replaceAll(editorValue, "[Anrede mit Name]<br/><br/>", "");
        editorValue = Util.replaceAll(editorValue, "[Anrede mit Name]<br><br>", "");
        editorValue = Util.replaceAll(editorValue, "[Anrede mit Name]<br />", "");
        editorValue = Util.replaceAll(editorValue, "[Anrede mit Name]<br/>", "");
        editorValue = Util.replaceAll(editorValue, "[Anrede mit Name]<br>", "");
        return editorValue;
    };

    public saveBillLayoutChanges = (): void => {
        if (!this.validator.validate()) {
            return;
        }
        $(".billLayoutPreviewElementActive").removeClass("billLayoutPreviewElementActive");
        const selector = $("#popupBillLayout");
        if (selector.data() && selector.data().anredehandling !== undefined) {
            this.set("userSettings.Dokument_Rechnung_Kopfzeile", `[Anrede mit Name]<br><br>${this.removeAllAnredeTags(this.get<string>("userSettings.Dokument_Rechnung_Kopfzeile"))}`);
            selector.removeData("anredehandling");
        }
        if (selector.data() && selector.data().phonenumberhandling !== undefined) {
            this.set("userSettings.CompanyPhone", $("#phoneMobileProfile").intlTelInput("getNumber", window.intlTelInputUtils.numberFormat.INTERNATIONAL));
            selector.removeData("phonenumberhandling");
        }
        this.editorPopup.closePopup();
        this.setCombinedFields();
    };

    public createBillPreview = (): void => {
        const A4Container = $(".billLayout");
        const A4width = 210;
        const A4height = A4width * Math.sqrt(2);
        A4Container.css("height", A4Container.width() * Math.sqrt(2));

        const pageMarginLeft = (A4Container.width() / A4width) * 20;
        const pageMarginRight = A4Container.width() - pageMarginLeft;
        const fullWidth = A4Container.width() - 2 * pageMarginLeft;

        const billLogoWidth = (A4Container.width() / A4width) * 20;
        const billLogoHeight = (A4Container.height() / A4height) * 20;
        const billLogoTopPosition = (A4Container.height() / A4height) * 7;
        $(".billLogo")
            .css("width", billLogoWidth)
            .css("height", billLogoHeight)
            .css("top", billLogoTopPosition)
            .css("left", pageMarginRight - billLogoWidth);

        const addressWidth = (A4Container.width() / A4width) * 55;
        const addressHeight = (A4Container.height() / A4height) * 30;
        const senderAddressTopPosition = (A4Container.height() / A4height) * 30;
        $(".billSenderAddress")
            .css("width", addressWidth)
            .css("height", addressHeight)
            .css("top", senderAddressTopPosition)
            .css("left", pageMarginRight - addressWidth);

        const contactWidth = (A4Container.width() / A4width) * 55;
        const contactHeight = (A4Container.height() / A4height) * 20;
        const contactTopPosition = (A4Container.height() / A4height) * 63;
        $(".billContact")
            .css("width", contactWidth)
            .css("height", contactHeight)
            .css("top", contactTopPosition)
            .css("left", pageMarginRight - contactWidth);

        const billRecipientAddressWidth = (A4Container.width() / A4width) * 45;
        const billRecipientAddressHeight = (A4Container.height() / A4height) * 30;
        const billRecipientAddressTopPosition = (A4Container.height() / A4height) * 53;
        $(".billRecipientAddress").css("width", billRecipientAddressWidth).css("height", billRecipientAddressHeight).css("top", billRecipientAddressTopPosition).css("left", pageMarginLeft);

        const billInfoHeaderHeight = (A4Container.height() / A4height) * 30;
        const billInfoHeaderTopPosition = (A4Container.height() / A4height) * 95;
        $(".billInfoHeader").css("width", fullWidth).css("height", billInfoHeaderHeight).css("top", billInfoHeaderTopPosition).css("left", pageMarginLeft);

        const billLeadingInHeight = (A4Container.height() / A4height) * 30;
        const billLeadingInTopPosition = (A4Container.height() / A4height) * 130;
        $(".billLeadingIn").css("width", fullWidth).css("height", billLeadingInHeight).css("top", billLeadingInTopPosition).css("left", pageMarginLeft);

        const billBodyHeight = (A4Container.height() / A4height) * 30;
        const billBodyTopPosition = (A4Container.height() / A4height) * 165;
        $(".billBody").css("width", fullWidth).css("height", billBodyHeight).css("top", billBodyTopPosition).css("left", pageMarginLeft);

        const billEndTextHeight = (A4Container.height() / A4height) * 26;
        const billEndTextTopPosition = (A4Container.height() / A4height) * 200;
        $(".billEndText").css("width", fullWidth).css("height", billEndTextHeight).css("top", billEndTextTopPosition).css("left", pageMarginLeft);

        const billPaymentDetailsWidth = (A4Container.width() / A4width) * 60;
        const billPaymentDetailsTextHeight = (A4Container.height() / A4height) * 39;
        const billPaymentDetailsTopPosition = (A4Container.height() / A4height) * 240;
        $(".billPaymentDetails").css("width", billPaymentDetailsWidth).css("height", billPaymentDetailsTextHeight).css("top", billPaymentDetailsTopPosition).css("left", pageMarginLeft);
    };

    public getNavPoint(): string {
        return this.NAV_POINT;
    }

    public hideView(): void {
        $(".inputFieldContainer").off("click", Util.focusInput);
        Util.disableFocusStateFeature($("input"));
        Util.disableFocusStateFeature($("textarea"));
        $(".billLayoutPreviewElementActive").removeClass("billLayoutPreviewElementActive");
        $(".showPopup").removeClass("showPopup");
        this.companyLogoPopup.destroy();
        this.editorPopup.destroy();
    }
}
