import ConfirmContent from "../modules/ConfirmContent";
import DateTime from "../modules/DateTime";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import { ratingService } from "../services/ChecklistRatingService";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { jobPositionService } from "../services/JobPositionService";
import { lessonService } from "../services/LessonService";
import { localDataService } from "../services/LocalDataService";
import { orphyDriveJobPositionService } from "../services/OrphyDriveJobPositionService";
import { paymentWayService } from "../services/PaymentWayService";
import { personService } from "../services/PersonService";
import { timeTrackingService } from "../services/TimeTrackingService";
import { translation } from "../services/TranslationService";
import { IJobProductPosition } from "../types/JobProductPosition";
import { IPerson } from "../types/Person";
import TimeTracking, { ITimeTracking } from "../types/TimeTracking";
import { BaseView } from "./BaseView";

export default class EnterLessons extends BaseView {
    private updateLessonCount: number;
    private timeTrackingId: number;

    private readonly fullFractionArray = new kendo.data.ObservableArray([
        { Name: "-", Value: 0 },
        { Name: "\u00BC", Value: 0.25 },
        { Name: "\u00BD", Value: 0.5 },
        { Name: "\u00BE", Value: 0.75 }
    ]);

    constructor() {
        super();
        this.set(
            "lessonCount",
            new kendo.data.ObservableArray([
                { Name: "-", Value: 0 },
                { Name: translation.t("time-date.lesson", { count: 1 }), Value: 1 },
                { Name: translation.t("time-date.lesson", { count: 2 }), Value: 2 },
                { Name: translation.t("time-date.lesson", { count: 3 }), Value: 3 },
                { Name: translation.t("time-date.lesson", { count: 4 }), Value: 4 },
                { Name: translation.t("time-date.lesson", { count: 5 }), Value: 5 },
                { Name: translation.t("time-date.lesson", { count: 6 }), Value: 6 },
                { Name: translation.t("time-date.lesson", { count: 7 }), Value: 7 },
                { Name: translation.t("time-date.lesson", { count: 8 }), Value: 8 },
                { Name: translation.t("time-date.lesson", { count: 9 }), Value: 9 },
                { Name: translation.t("time-date.lesson", { count: 10 }), Value: 10 }
            ])
        );
        this.set("LessonCountFractions", this.fullFractionArray);
        super.init(this);
    }

    public async loadData(e: any): Promise<void> {
        this.set("AvailableLessonCount", await jobPositionService.getAvailableBalance(Util.getEducationId(this.personId), this.personId));

        if (e.view.params.fromEnterLesson === "true") {
            // don't reload data if just balance was added
            return;
        }

        const isMissedLesson = e.view.params.isMissedLesson === "true";
        this.set("isMissedLesson", isMissedLesson);
        if (isMissedLesson) {
            this.set("titleMissedOrNot", translation.t("enter-lessons.post-missed-lessons"));
        } else {
            this.set("titleMissedOrNot", translation.t("enter-lessons.post-lessons"));
        }
        this.set("missedLessonReason", "");

        const updateLesson = e.view.params.updateLesson === "true";
        this.set("updateLesson", updateLesson);

        const paymentWays = await paymentWayService.getCashPaymentWays();
        const pricing = await companyAppSettingsService.getChecklistPricing(Util.getEducationId(this.personId), this.personId);

        const isAdmin = (await localDataService.getLocalData()).CanUpdateCompanySettings;
        const disablePriceChange = (await companyAppSettingsService.getSettings()).DisablePriceChange;
        this.set("disablePriceChange", !isAdmin && disablePriceChange);

        this.set("PaymentWays", paymentWays);
        const lastSelectedPaymentWay = await paymentWayService.getLastSelectedPaymentWayId();
        const paymentWay = paymentWays.find(x => x.Id === lastSelectedPaymentWay);
        this.set("SelectedPaymentWayId", paymentWay ? paymentWay.Id : Util.firstOrDefault(paymentWays).Id);
        this.set("PricePerLesson", pricing.PricePerLesson);

        if (updateLesson) {
            this.timeTrackingId = Number(e.view.params.timeTrackingId);
            const lesson = isMissedLesson ? await lessonService.getItemByTimeTrackingId(this.timeTrackingId) : await lessonService.getUpdateLesson(Util.getEducationId(this.personId));
            const timeTracking = await timeTrackingService.getItemById(lesson.TimeTrackingId);

            this.updateLessonCount = lesson.Count;
            this.set("SelectedLessonCount", Math.floor(lesson.Count));
            this.set("SelectedLessonCountFraction", lesson.Count - Math.floor(lesson.Count));

            this.set("timetrackingStart", DateTime.getDateTimeForInput(timeTracking.IssueDate as Date));
            this.set("missedLessonReason", timeTracking.Beschreibung);
            const endDate = DateTime.getEndDate(timeTracking.IssueDate as Date, timeTracking.DurationTicks);
            this.set("timetrackingEnd", DateTime.getDateTimeForInput(endDate));
        } else {
            this.set("SelectedLessonCount", 1);
            this.set("SelectedLessonCountFraction", 0);
            await this.initTimeTracking();
        }
        this.trigger("change", { field: "enoughBalanceAvailable" });
        this.updateLessonSubtractionInfo();
    }

    public async showView(): Promise<void> {
        this.set("EnterLessonButtonEnabled", true);
        await this.lessonCountChanged();
    }

    public enoughBalanceAvailable(): boolean {
        if (this.get<boolean>("updateLesson")) {
            return this.get<number>("AvailableLessonCount") + this.updateLessonCount - this.getLessonCount() >= 0;
        }
        return this.get<number>("AvailableLessonCount") - this.getLessonCount() >= 0;
    }

    public showBookingFromAvailableLessons(): boolean {
        if (this.get<boolean>("updateLesson")) {
            return false;
        }
        return this.get<number>("AvailableLessonCount") - this.getLessonCount() >= 0;
    }

    public getNeededLessons() {
        if (this.get<boolean>("updateLesson")) {
            return this.getLessonCount() - this.updateLessonCount - this.get<number>("AvailableLessonCount");
        }
        return this.getLessonCount() - this.get<number>("AvailableLessonCount");
    }

    public async lessonCountChanged(): Promise<void> {
        this.setLessonCountFractions();
        await this.setTimeTrackingStart();
        if (this.get<boolean>("updateLesson")) {
            const neededLessonCount = Math.abs(this.getLessonCount() - this.updateLessonCount);
            if (this.updateLessonCount === this.getLessonCount()) {
                this.set("editLessonLessonCountChanged", translation.t("enter-lessons.amount-not-changed"));
            } else if (this.updateLessonCount < this.getLessonCount()) {
                const text = translation.t("enter-lessons.amount-posted", { count: neededLessonCount });
                const availableLessonCountText =
                    this.get<number>("AvailableLessonCount") !== 0 ? `<br /> ${translation.t("enter-lessons.available-credit")}: ${this.get<number>("AvailableLessonCount")}` : "";
                this.set("editLessonLessonCountChanged", `${text}${availableLessonCountText}`);
            } else if (this.updateLessonCount > this.getLessonCount()) {
                const text = translation.t("enter-lessons.credit-added", { neededLessonCount: neededLessonCount });
                this.set("editLessonLessonCountChanged", text);
            }
        }
        this.updateLessonSubtractionInfo();
    }

    private updateLessonSubtractionInfo(): void {
        this.set("lessonCountSubtract", translation.t("enter-lessons.lesson-subtraction-info", { availableLessonCount: this.get<number>("AvailableLessonCount"), count: this.getLessonCount() }));
        this.set("lessonOrderInfo", translation.t("enter-lessons.lesson-order-info", { count: this.getNeededLessons() }));
    }

    private setLessonCountFractions(): void {
        if (this.get<number>("SelectedLessonCount") === 0) {
            this.set(
                "LessonCountFractions",
                new kendo.data.ObservableArray([
                    { Name: "\u00BC", Value: 0.25 },
                    { Name: "\u00BD", Value: 0.5 },
                    { Name: "\u00BE", Value: 0.75 }
                ])
            );
            if (this.get<number>("SelectedLessonCountFraction") === 0) {
                this.set("SelectedLessonCountFraction", 0.25);
            }
        } else {
            this.set("LessonCountFractions", this.fullFractionArray);
        }
    }

    private async initTimeTracking(): Promise<void> {
        await this.setTimeTrackingStart();
        await this.setTimeTrackingEnd();
    }

    private async setTimeTrackingStart(): Promise<void> {
        const pricing = await companyAppSettingsService.getChecklistPricing(Util.getEducationId(this.personId), this.personId);
        const timeTrackingEnd = this.getEndDate();
        timeTrackingEnd.setMinutes(timeTrackingEnd.getMinutes() - this.getLessonCount() * pricing.LessonDuration);
        this.set("timetrackingStart", DateTime.getDateTimeForInput(timeTrackingEnd));
    }

    private getEndDate(): Date {
        const end = this.get<string>("timetrackingEnd");
        if (end) {
            return DateTime.getDateTimeFromInput(end);
        }
        return new Date();
    }

    public async setTimeTrackingEnd(): Promise<void> {
        const pricing = await companyAppSettingsService.getChecklistPricing(Util.getEducationId(this.personId), this.personId);
        const start = DateTime.getDateTimeFromInput(this.get<string>("timetrackingStart"));
        if (start) {
            start.setMinutes(start.getMinutes() + pricing.LessonDuration * this.getLessonCount());
            this.set("timetrackingEnd", DateTime.getDateTimeForInput(start));
        }
    }

    public setLastSelectedPaymentWay = () => {
        paymentWayService.setLastSelectedPaymentWayId(this.get<number>("SelectedPaymentWayId"));
    };

    private getLessonCount(): number {
        return this.get<number>("SelectedLessonCount") + this.get<number>("SelectedLessonCountFraction");
    }

    public async enterLesson() {
        this.set("EnterLessonButtonEnabled", false);
        app.mobileApp.showLoading();
        if (this.get<boolean>("updateLesson")) {
            await this.update();
        } else {
            await this.insert();
        }

        this.resetTimeTrackingDates();
        app.mobileApp.hideLoading();
        app.mobileApp.navigate("views/personDetail.html");
    }

    private async update(): Promise<void> {
        const lesson = this.get<boolean>("isMissedLesson") ? await lessonService.getItemByTimeTrackingId(this.timeTrackingId) : await lessonService.getUpdateLesson(Util.getEducationId(this.personId));
        const timeTracking = await timeTrackingService.getItemById(lesson.TimeTrackingId);

        const startDate = DateTime.getDateTimeFromInput(this.get<string>("timetrackingStart"));
        const endDate = DateTime.getDateTimeFromInput(this.get<string>("timetrackingEnd"));

        lesson.IsUpdateLesson = false;
        lesson.Date = startDate;
        lesson.Count = this.getLessonCount();
        await lessonService.updateEntity(lesson, true);

        timeTracking.IssueDate = startDate;
        timeTracking.DurationTicks = DateTime.getTicks(startDate, endDate);
        timeTracking.Beschreibung = this.get<string>("missedLessonReason");
        await timeTrackingService.updateEntity(timeTracking, true);

        const availableBalance = await jobPositionService.getAvailableBalance(Util.getEducationId(this.personId), this.personId);

        if (availableBalance < 0) {
            const additionalBalance = Math.abs(availableBalance);

            const unchargedPositionOrphyDrivePosition = await orphyDriveJobPositionService.getFirstUnchargedPosition(timeTracking.Id);

            if (unchargedPositionOrphyDrivePosition) {
                const unchargedProductPosition = await jobPositionService.getProductPosition(unchargedPositionOrphyDrivePosition.JobPositionId);
                unchargedProductPosition.Amount += additionalBalance;
                unchargedProductPosition.Price = this.getPricePerLesson();
                await jobPositionService.updateEntity(unchargedProductPosition, true);
            } else {
                const positionId = await jobPositionService.addLessonProductPosition(this.getPricePerLesson(), additionalBalance, Util.getEducationId(this.personId), this.personId);
                await orphyDriveJobPositionService.add(positionId, timeTracking.Id, 1);
                if (this.get<boolean>("CreateReceipt")) {
                    await jobPositionService.accountPositions(Util.getEducationId(this.personId), this.personId, [positionId], this.get<number>("SelectedPaymentWayId"), true, endDate, endDate, true);
                }
            }
        } else if (availableBalance > 0) {
            const unchargedOrphyDriveJobpositions = await orphyDriveJobPositionService.getUnchargedLessonPositions(timeTracking.Id);
            if (unchargedOrphyDriveJobpositions.length) {
                const productPositions = await jobPositionService.getProductPositions(unchargedOrphyDriveJobpositions.map(x => x.JobPositionId));
                const position = await this.mergePositions(productPositions);

                if (!position) {
                    return;
                }

                app.mobileApp.hideLoading();

                if (this.updateLessonCount === this.getLessonCount()) {
                    await this.reducePositionAmount(position, ConfirmContent.reducePositionAmountOnUpdateLessonCountNotChanged(position.Amount - this.getLessonCount()));
                } else if (this.updateLessonCount > this.getLessonCount()) {
                    await this.reducePositionAmount(position, ConfirmContent.reducePositionAmountOnUpdateLesson(position.Amount - this.getLessonCount()));
                }
            }
        }
    }

    private async reducePositionAmount(position: IJobProductPosition, confirmContent): Promise<void> {
        if (position.Amount > this.getLessonCount()) {
            if ((await Util.confirmTemplate(confirmContent)).confirmed) {
                app.mobileApp.showLoading();
                position.Amount = this.getLessonCount();
                await jobPositionService.updateEntity(position, true);
            }
        }
    }

    private async mergePositions(positions: IJobProductPosition[]): Promise<IJobProductPosition> {
        const firstPositionId = Util.firstOrDefault(positions.map(x => x.Id));
        const firstPosition = (await jobPositionService.getItemById(firstPositionId)) as IJobProductPosition;
        if (!firstPositionId || !firstPosition) {
            return null;
        }
        firstPosition.Amount = positions.reduce((a, b) => a + b.Amount, 0);
        await jobPositionService.updateEntity(firstPosition, true);
        const otherPositions = positions.filter(x => x.Id !== firstPositionId);
        for (const position of otherPositions) {
            await jobPositionService.delete(position.Id);
            await orphyDriveJobPositionService.delete(position.Id);
        }
        return firstPosition;
    }

    private async insert(): Promise<void> {
        const pricePerLesson = this.getPricePerLesson();
        const lessonCount = this.getLessonCount();
        const neededLessonCount = this.getNeededLessons();
        const timetrackingId = await this.insertTimetracking();

        if (neededLessonCount > 0) {
            const positionId = await jobPositionService.addLessonProductPosition(pricePerLesson, neededLessonCount, Util.getEducationId(this.personId), this.personId);
            await orphyDriveJobPositionService.add(positionId, timetrackingId, 1);
            if (this.get<boolean>("CreateReceipt")) {
                const billingDate = DateTime.getDateTimeFromInput(this.get<string>("timetrackingEnd"));
                await jobPositionService.accountPositions(
                    Util.getEducationId(this.personId),
                    this.personId,
                    [positionId],
                    this.get<number>("SelectedPaymentWayId"),
                    true,
                    billingDate,
                    billingDate,
                    true
                );
            }
        }
        const startDate = DateTime.getDateTimeFromInput(this.get<string>("timetrackingStart"));

        if (this.get<boolean>("isMissedLesson")) {
            await lessonService.addMisseLesson(lessonCount, timetrackingId, startDate, Util.getEducationId(this.personId));
        } else {
            await ratingService.addCurrentRatings(Util.getEducationId(this.personId));
            await lessonService.addCurrentLesson(lessonCount, timetrackingId, startDate, Util.getEducationId(this.personId));
        }
    }

    private getPricePerLesson(): number {
        const pricePerLesson = this.get<number>("PricePerLesson");
        return !pricePerLesson ? 0 : pricePerLesson;
    }

    private resetTimeTrackingDates(): void {
        this.set("timetrackingStart", "");
        this.set("timetrackingEnd", "");
    }

    private async insertTimetracking(): Promise<number> {
        const person = await personService.getItemById(this.personId);
        const timeTracking = this.getNewTimeTracking(person);
        return timeTrackingService.insert(timeTracking);
    }

    private getNewTimeTracking(person: IPerson): ITimeTracking {
        const timetracking = new TimeTracking();
        const startTime = DateTime.getDateTimeFromInput(this.get<string>("timetrackingStart"));
        const endTime = DateTime.getDateTimeFromInput(this.get<string>("timetrackingEnd"));
        timetracking.PersonId = person.Id;
        timetracking.IssueDate = startTime;
        timetracking.DurationTicks = DateTime.getTicks(startTime, endTime);
        timetracking.ActivityId = 2;
        timetracking.MitarbeiterId = network.getPersonId();
        timetracking.IsDeleted = false;
        timetracking.Title = `Fahrlektion mit ${person.FirstName} ${person.LastName}`;
        timetracking.Status = 2;
        timetracking.Beschreibung = this.get<string>("missedLessonReason");
        return timetracking;
    }

    public chooseSubscription = () => {
        app.mobileApp.navigate(`views/subscriptionChooser.html?fromEnterLesson=true&navPoint=views/enterLessons.html`);
    };

    public back(): void {
        this.resetTimeTrackingDates();
        super.back();
    }

    public getNavPoint(): string {
        if (this.get<boolean>("updateLesson") || this.get("isMissedLesson")) {
            return "views/personDetail.html";
        }
        return "views/educationCard.html";
    }
}
