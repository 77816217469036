import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseChecklistCollection {
    Name: string;
    HasVku: boolean;
}

export interface IChecklistCollectionDto extends IBaseDto, IBaseChecklistCollection {}

export interface IChecklistCollection extends IBaseEntity, IBaseChecklistCollection {}

export default class ChecklistCollection extends BaseEntity<IChecklistCollection, IChecklistCollectionDto> implements IChecklistCollection {
    public static get EntityTypeId(): string {
        return "bc3b52a8-99db-5715-ac10-5bb171fab897";
    }

    public Name: string;
    public HasVku: boolean;

    public populateFromDb(dbItem: ChecklistCollection): void {
        super.populateFromDb(dbItem);
        this.Name = dbItem.Name;
        this.HasVku = this.getDbBoolean(dbItem.HasVku);
    }

    public populateFromOData(oDataItem: ChecklistCollection): void {
        super.populateFromOData(oDataItem);
        this.Name = oDataItem.Name;
        this.HasVku = oDataItem.HasVku;
    }

    public toODataObject(): IChecklistCollectionDto {
        const oDataObject = super.toODataObject();
        oDataObject.Name = this.Name;
        oDataObject.HasVku = this.HasVku;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<ChecklistCollection>(x => x.Name),
            this.Name
        );
        dbObject.set(
            nameof<ChecklistCollection>(x => x.HasVku),
            this.HasVku
        );
        return dbObject;
    }
}
