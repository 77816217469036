import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";
import { translation } from "../services/TranslationService";

export enum ConditionType {
    Custom,
    PaymentWithin30Days,
    PaymentWithin10Days
}

interface IBaseCondition {
    Name: string;
    CountOfDays: number;
    Skonto: number;
    Type: ConditionType;
}

export interface IConditionDto extends IBaseDto, IBaseCondition {}
export interface ICondition extends IBaseEntity, IBaseCondition {}

export default class Condition extends BaseEntity<ICondition, IConditionDto> implements ICondition {
    public static get EntityTypeId(): string {
        return "14bbd3a9-d809-535c-80f4-d558e021804f";
    }

    public Name: string;
    public CountOfDays: number;
    public Skonto: number;
    public Type: ConditionType;

    public populateFromDb(dbItem: ICondition): void {
        super.populateFromDb(dbItem);
        this.CountOfDays = dbItem.CountOfDays;
        this.Skonto = dbItem.Skonto;
        this.Type = dbItem.Type;
        if (dbItem.Type === ConditionType.Custom) {
            this.Name = dbItem.Name;
        } else {
            this.Name = translation.t(`conditions.type_${dbItem.Type}`);
        }
    }

    public populateFromOData(oDataItem: IConditionDto): void {
        super.populateFromOData(oDataItem);
        this.CountOfDays = oDataItem.CountOfDays;
        this.Skonto = oDataItem.Skonto;
        this.Type = oDataItem.Type;
        if (oDataItem.Type === ConditionType.Custom) {
            this.Name = oDataItem.Name;
        } else {
            this.Name = translation.t(`conditions.type_${oDataItem.Type}`);
        }
    }

    public toODataObject(): IConditionDto {
        return null; // Conditions are not pushed to Orphy
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Condition>(x => x.Name),
            this.Name
        );
        dbObject.set(
            nameof<Condition>(x => x.CountOfDays),
            this.CountOfDays
        );
        dbObject.set(
            nameof<Condition>(x => x.Skonto),
            this.Skonto
        );
        dbObject.set(
            nameof<Condition>(x => x.Type),
            this.Type
        );
        return dbObject;
    }
}
