import saveAs from "file-saver";
import { logger } from "./Logger";
import { Util } from "./Util";
import { translation } from "../services/TranslationService";

export default class OpenFileModule {
    private readonly _data: Blob;
    private readonly _originalData: string;
    private readonly _fileName: string;
    private readonly _mimeType: string;
    private readonly _successCB: () => void;
    private readonly _errorCB: () => void;

    constructor(data: string, filename: string, mimeType: string, successCB: () => void, errorCB: () => void) {
        this._originalData = atob(data);
        this._data = new Blob([this.stringToByteArray(atob(data))], { type: mimeType });
        this._fileName = filename;
        this._mimeType = mimeType;
        this._successCB = successCB;
        this._errorCB = errorCB;
    }

    public openFile = (): void => {
        this.getFS();
    };

    private getFS = (): void => {
        window.requestFileSystem(window.LocalFileSystem.TEMPORARY, 0, this.gotFS, this._errorCB);
    };

    private gotFS = (fileSystem): void => {
        fileSystem.root.getFile(this._fileName, { create: true, exclusive: false }, this.gotFile, this._errorCB);
    };

    private gotFile = (fileEntry): void => {
        const fullPath = Util.isBrowser() ? fileEntry.toInternalURL() : fileEntry.nativeURL;
        fileEntry.createWriter(
            writer => {
                writer.onwriteend = () => {
                    if (Util.isiOS()) {
                        window.handleDocumentWithURL(
                            () => {},
                            e => {
                                logger.logError(e);
                            },
                            writer.localURL
                        );
                    } else if (Util.isBrowser()) {
                        saveAs(this._data, this._fileName);
                    } else {
                        window.cordova.plugins.fileOpener2.open(fullPath, this._mimeType, {
                            error: e => {
                                if (e.status === 9) {
                                    Util.showNotification(
                                        translation.t("confirm.no-application", { fileType: this._mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? "Excel" : "PDF" }),
                                        "error"
                                    );
                                }
                            },
                            success: () => {}
                        });
                    }
                };
                writer.onerror = (): void => {
                    if ($.isFunction(this._errorCB)) {
                        this._errorCB();
                    }
                };

                writer.write(this._data);
                if ($.isFunction(this._successCB)) {
                    this._successCB();
                }
            },
            (): void => {
                if ($.isFunction(this._errorCB)) {
                    this._errorCB();
                }
            }
        );
    };

    private stringToByteArray(str: string): Uint8Array {
        const bytes: number[] = [];
        for (let i = 0; i < str.length; ++i) {
            bytes.push(str.charCodeAt(i));
        }
        return new Uint8Array(bytes);
    }
}
