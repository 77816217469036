import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";
import { Gender } from "./Person";

interface IBaseProfile {
    NameFirst: string;
    NameFamily: string;
    Street: string;
    StreetNumber: string;
    Plz: string;
    Ort: string;
    Sex: Gender;
    Email: string;
    PhoneNumber: string;
}

export interface IProfileDto extends IBaseDto, IBaseProfile {}
export interface IProfile extends IBaseEntity, IBaseProfile {}

export default class Profile extends BaseEntity<IProfile, IProfileDto> implements IProfile {
    public NameFirst: string;
    public NameFamily: string;
    public Street: string;
    public StreetNumber: string;
    public Plz: string;
    public Ort: string;
    public Sex: Gender;
    public Email: string;
    public PhoneNumber: string;

    public populateFromDb(dbItem: IProfile): void {
        super.populateFromDb(dbItem);
        this.NameFirst = dbItem.NameFirst;
        this.NameFamily = dbItem.NameFamily;
        this.Street = dbItem.Street;
        this.StreetNumber = dbItem.StreetNumber;
        this.Plz = dbItem.Plz;
        this.Ort = dbItem.Ort;
        this.Email = dbItem.Email;
        this.PhoneNumber = dbItem.PhoneNumber;
        this.Sex = dbItem.Sex;
    }

    public populateFromOData(oDataItem: IProfileDto): void {
        super.populateFromOData(oDataItem);
        this.NameFirst = oDataItem.NameFirst;
        this.NameFamily = oDataItem.NameFamily;
        this.Street = oDataItem.Street;
        this.StreetNumber = oDataItem.StreetNumber;
        this.Plz = oDataItem.Plz;
        this.Ort = oDataItem.Ort;
        this.Email = oDataItem.Email;
        this.PhoneNumber = oDataItem.PhoneNumber;
        this.Sex = oDataItem.Sex;
    }

    public toODataObject(): IProfileDto {
        const oDataObject = super.toODataObject();
        // subscriptionaddress has no uuid
        delete oDataObject.Uuid;
        oDataObject.NameFirst = this.NameFirst;
        oDataObject.NameFamily = this.NameFamily;
        oDataObject.Street = this.Street;
        oDataObject.StreetNumber = this.StreetNumber;
        oDataObject.Plz = this.Plz;
        oDataObject.Ort = this.Ort;
        oDataObject.Sex = this.Sex;
        oDataObject.Email = this.Email;
        oDataObject.PhoneNumber = this.PhoneNumber;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Profile>(x => x.Id),
            1
        ); // Override Id with 1 (only one entity)
        dbObject.set(
            nameof<Profile>(x => x.NameFirst),
            this.NameFirst
        );
        dbObject.set(
            nameof<Profile>(x => x.NameFamily),
            this.NameFamily
        );
        dbObject.set(
            nameof<Profile>(x => x.Street),
            this.Street
        );
        dbObject.set(
            nameof<Profile>(x => x.StreetNumber),
            this.StreetNumber
        );
        dbObject.set(
            nameof<Profile>(x => x.Plz),
            this.Plz
        );
        dbObject.set(
            nameof<Profile>(x => x.Ort),
            this.Ort
        );
        dbObject.set(
            nameof<Profile>(x => x.Sex),
            this.Sex
        );
        dbObject.set(
            nameof<Profile>(x => x.Email),
            this.Email
        );
        dbObject.set(
            nameof<Profile>(x => x.PhoneNumber),
            this.PhoneNumber
        );
        return dbObject;
    }
}
