export default class SeriesModel extends kendo.data.ObservableObject {
    public Rating: number;
    public readonly color: string;

    constructor(color: string) {
        super();
        this.color = color;
        this.Rating = 0;
        super.init(this);
    }
}
