import ChecklistPricingModel from "../models/ChecklistPricingModel";
import ProductModel from "../models/ProductModel";
import ConfirmContent from "../modules/ConfirmContent";
import { Util } from "../modules/Util";
import { checklistCollectionService } from "../services/ChecklistCollectionService";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { jobPositionService } from "../services/JobPositionService";
import { productService } from "../services/ProductService";
import { translation } from "../services/TranslationService";
import Product from "../types/Product";
import { kendoEvent } from "../typings/kendoEvent";
import { BaseView } from "./BaseView";

export default class ProductManager extends BaseView {
    public checklistPricings: ChecklistPricingModel[];

    private navPoint: string;
    private validator: kendo.ui.Validator;
    private isInitialized: boolean = false;
    private fromEnterLesson: boolean;

    public initView(): void {
        if ($("#productManagerContent").length) {
            Util.renderView("productsMainTemplate", this, $("#productManagerContent"));
        }
    }

    public async loadData(): Promise<void> {
        if (!this.isInitialized) {
            this.validator = $(".productManagerTemplateContent").kendoValidator().data().kendoValidator;
            this.validator.bind("validateInput", Util.validateInput);
            this.isInitialized = true;
        }

        this.set("isInsertVisible", true);

        const companyAppSettings = await companyAppSettingsService.getSettings();
        const checklists = await checklistCollectionService.getItems("Id > 0", "Name ASC");

        this.set(
            "products",
            (await productService.getItems()).map(p => new ProductModel(p))
        );

        this.set("checklistPricings", companyAppSettings.ChecklistPricing);
        this.set("checklists", checklists);
        this.set("checklistId", checklists[0].Id);
        this.checklistPricingChanged();
        $("body").delegate(".inputField", "focus", this.addFocusedClass);

        this.set("lessonDurationMinutes", companyAppSettings.LessonDuration ? companyAppSettings.LessonDuration : 45);
        this.set("pricePerLesson", companyAppSettings.PricePerLesson ? companyAppSettings.PricePerLesson : 100);
    }

    public showView(e: any): void {
        this.fromEnterLesson = e.view.params.fromEnterLesson;
        if (e.view.params.navPoint) {
            this.navPoint = e.view.params.navPoint;
            this.set("backButtonText", translation.t("common.back"));
        } else {
            this.navPoint = "views/settings.html";
            this.set("backButtonText", translation.t("admin-settings.back"));
        }
    }

    public toNumberInput = element => {
        $(element).attr("type", "number");
    };

    public toTextInput = element => {
        $(element).attr("type", "text");
    };

    private addFocusedClass = e => {
        $(".inputFieldContainerFocused").removeClass("inputFieldContainerFocused");
        $(e.target).closest(".editingInputFieldContainer").addClass("inputFieldContainerFocused");
    };

    public lessonCountChanged(e) {
        const input = $(e.sender.element);
        const lessonCount = Number(input.val());
        input.val(this.roundToQuarter(lessonCount));
    }

    private roundToQuarter(lessonCount: number): number {
        return parseFloat((Math.round(lessonCount * 4) / 4).toFixed(2));
    }

    public edit = (e: kendoEvent<ProductModel>): void => {
        e.data.set("IsEdit", true);
        this.trigger("change", { field: "products" });
    };

    public updateProduct = async (e: kendoEvent<ProductModel>): Promise<void> => {
        if (this.checkName(e.data.EditName) && this.checkLessonCount(e.data.EditLessonCount) && this.checkPrice(e.data.EditVerkaufspreis)) {
            app.mobileApp.showLoading();
            e.data.Name = e.data.EditName;
            e.data.Beschreibung = e.data.EditName;
            e.data.LessonCount = e.data.EditLessonCount ?? 0;
            e.data.Verkaufspreis = e.data.EditVerkaufspreis ?? 0;
            e.data.IsEdit = false;
            const update = new Product();
            e.data.toEntity(update);
            await productService.updateEntity(update);
            app.mobileApp.hideLoading();
            this.trigger("change", { field: "products" });
        }
    };

    public cancelEdit = (e: kendoEvent<ProductModel>): void => {
        e.data.EditName = e.data.Name;
        e.data.EditLessonCount = e.data.LessonCount;
        e.data.EditVerkaufspreis = e.data.Verkaufspreis;
        e.data.IsEdit = false;
        this.trigger("change", { field: "products" });
    };

    public saveProduct = async (): Promise<void> => {
        const newName = this.get<string>("newName");
        const newLessonCount = this.roundToQuarter(this.get<number>("newLessonCount"));
        const newVerkaufsPreis = this.get<number>("newVerkaufspreis");
        if (this.checkName(this.get<string>("newName")) && this.checkLessonCount(newLessonCount) && this.checkPrice(newVerkaufsPreis)) {
            app.mobileApp.showLoading();
            const product = new Product();
            product.Name = newName;
            product.Beschreibung = newName;
            product.LessonCount = newLessonCount ?? 0;
            product.Verkaufspreis = newVerkaufsPreis ?? 0;
            await productService.insert(product);
            this.set(
                "products",
                (await productService.getItems()).map(x => new ProductModel(x))
            );
            this.set("newName", "");
            this.set("newLessonCount", "");
            this.set("newVerkaufspreis", "");
            app.mobileApp.hideLoading();
        }
    };

    private checkName = (name): boolean => {
        if (!name) {
            Util.showNotification(translation.t("product-manager-settings.add-name"), "error");
            return false;
        }
        return true;
    };

    private checkLessonCount = (amount): boolean => {
        if (amount < 0) {
            Util.showNotification(translation.t("product-manager-settings.no-negativ"), "error");
            return false;
        } else if (amount === null || amount === undefined || amount === "") {
            Util.showNotification(translation.t("product-manager-settings.invalid-entry"), "error");
            return false;
        } else if (amount > 100_000) {
            Util.showNotification(translation.t("product-manager-settings.too-big"), "error");
            return false;
        }
        return true;
    };

    private checkPrice = (price): boolean => {
        if (price < 0) {
            Util.showNotification(translation.t("product-manager-settings.no-negative-price"), "error");
            return false;
        } else if (price === null || price === undefined || price === "") {
            Util.showNotification(translation.t("product-manager-settings.invalid-price"), "error");
            return false;
        } else if (price > 100_000) {
            Util.showNotification(translation.t("product-manager-settings.price-too-big"), "error");
            return false;
        }
        return true;
    };

    public async delete(e: kendoEvent<ProductModel>): Promise<void> {
        if (e.data.Id < 0 && (await jobPositionService.productInUse(e.data.Id))) {
            Util.showNotification(translation.t("product-manager-settings.delete-abo-after-sync"), "info");
            return;
        }
        const { confirmed } = await Util.confirmTemplate(ConfirmContent.deleteProduct(e.data.Name), translation.t("util.yes-delete-finaly"), translation.t("common.cancel"));
        if (confirmed) {
            app.mobileApp.showLoading();
            await productService.delete(e.data.Id);
            this.set(
                "products",
                (await productService.getItems()).map(x => new ProductModel(x))
            );
            app.mobileApp.hideLoading();
        }
    }

    public checklistPricingChanged = (): void => {
        this.updateChecklistPricing(
            (checklistPricing: ChecklistPricingModel) => {
                this.set("lessonDurationMinutesForChecklist", checklistPricing.LessonDuration);
                this.set("pricePerLessonForChecklist", checklistPricing.PricePerLesson);
            },
            () => {
                this.set("lessonDurationMinutesForChecklist", "");
                this.set("pricePerLessonForChecklist", "");
            }
        );
    };

    public checklistLessonDurationChanged = (): void => {
        this.updateChecklistPricing(
            (checklistPricing: ChecklistPricingModel) => {
                checklistPricing.LessonDuration = this.get<number>("lessonDurationMinutesForChecklist");
            },
            () => {
                const newChecklistPricing = new ChecklistPricingModel();
                newChecklistPricing.ChecklistId = this.get<number>("checklistId");
                newChecklistPricing.LessonDuration = this.get<number>("lessonDurationMinutesForChecklist");
                this.checklistPricings.push(newChecklistPricing);
            }
        );
    };

    public pricePerLessonForChecklistChanged = (): void => {
        this.updateChecklistPricing(
            (checklistPricing: ChecklistPricingModel) => {
                checklistPricing.PricePerLesson = this.get<number>("pricePerLessonForChecklist");
            },
            () => {
                const newChecklistPricing = new ChecklistPricingModel();
                newChecklistPricing.ChecklistId = this.get<number>("checklistId");
                newChecklistPricing.PricePerLesson = this.get<number>("pricePerLessonForChecklist");
                this.checklistPricings.push(newChecklistPricing);
            }
        );
    };

    private updateChecklistPricing = (updateView: (checklistPricing: ChecklistPricingModel) => void, handleNotFound: () => void): void => {
        let found = false;
        for (const checklistPricing of this.checklistPricings) {
            if (checklistPricing.ChecklistId === this.get<number>("checklistId")) {
                updateView(checklistPricing);
                found = true;
                break;
            }
        }
        if (!found) {
            handleNotFound();
        }
    };

    public getNavPoint(): string {
        return `${this.navPoint}?fromEnterLesson=${this.fromEnterLesson}`;
    }

    public save = async (showMessage: boolean = true): Promise<void> => {
        const validationResult = this.validator.validate();
        if (!validationResult) {
            void Promise.reject();
            return;
        }
        const cleanChecklistPricings = [];
        for (const checklistPricing of this.checklistPricings) {
            if (checklistPricing.LessonDuration || checklistPricing.PricePerLesson) {
                cleanChecklistPricings.push({
                    ChecklistId: checklistPricing.ChecklistId,
                    LessonDuration: checklistPricing.LessonDuration,
                    PricePerLesson: checklistPricing.PricePerLesson,
                    _checklistId: checklistPricing.ChecklistId,
                    _lessonDuration: checklistPricing.LessonDuration,
                    _pricePerLesson: checklistPricing.PricePerLesson
                });
            }
        }

        await companyAppSettingsService.updateSettings(
            new Map<string, any>()
                .set("LessonDuration", this.get<number>("lessonDurationMinutes"))
                .set("PricePerLesson", this.get<number>("pricePerLesson"))
                .set("ChecklistPricing", JSON.stringify(cleanChecklistPricings))
        );
        if (showMessage) {
            Util.showNotification(translation.t("product-manager-settings.saved-successfully"), "success");
            this.back();
        }
    };

    public hideView(): void {
        $("body").undelegate(".inputField", "focus", this.addFocusedClass);
    }
}
