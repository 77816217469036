import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseUser {
    DisplayName: string;
    PersonId: number;
}

export interface IUserDto extends IBaseDto, IBaseUser {}
export interface IUser extends IBaseEntity, IBaseUser {}

export default class User extends BaseEntity<IUser, IUserDto> implements IUser {
    public static get EntityTypeId(): string {
        return "b4b6b62e-dfe4-598a-b861-666e56723188";
    }

    public DisplayName: string;
    public PersonId: number;

    public populateFromDb(dbItem: IUser): void {
        super.populateFromDb(dbItem);
        this.DisplayName = dbItem.DisplayName;
        this.PersonId = dbItem.PersonId;
    }

    public populateFromOData(oDataItem: IUserDto): void {
        super.populateFromOData(oDataItem);
        this.DisplayName = oDataItem.DisplayName;
        this.PersonId = oDataItem.PersonId;
    }

    public toODataObject(): IUserDto {
        return null; // Users are not pushed to Orphy
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<User>(x => x.DisplayName),
            this.DisplayName
        );
        dbObject.set(
            nameof<User>(x => x.PersonId),
            this.PersonId
        );
        return dbObject;
    }
}
