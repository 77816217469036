import Logger from "./modules/Logger";
import Network from "./modules/Network";
import OrientationChange from "./modules/OrientationChange";
import { Util } from "./modules/Util";
import ActivityService from "./services/ActivityService";
import AddressTypeService from "./services/AddressTypeService";
import BillService from "./services/BillService";
import CalendarService from "./services/CalendarService";
import ChecklistItemService from "./services/ChecklistItemService";
import ChecklistCollectionService from "./services/ChecklistCollectionService";
import CleanupService from "./services/CleanUpService";
import CompanyAppSettingsService from "./services/CompanyAppSettingsService";
import CompanySettingsService from "./services/CompanySettingsService";
import ConditionService from "./services/ConditionService";
import ContactMediaService from "./services/ContactMediaService";
import CurrencyService from "./services/CurrencyService";
import CurrentLessonService from "./services/CurrentLessonService";
import FahrschuelerAppTokenService from "./services/FahrschuelerAppTokenService";
import JobPositionService from "./services/JobPositionService";
import JobService from "./services/JobService";
import LastSyncDateService from "./services/LastSyncDateService";
import LessonService from "./services/LessonService";
import LocalDataService from "./services/LocalDataService";
import LoggerService from "./services/LoggerService";
import PaymentWayService from "./services/PaymentWayService";
import PersonAddressService from "./services/PersonAddressService";
import PersonContactService from "./services/PersonContactService";
import PersonService from "./services/PersonService";
import ProductService from "./services/ProductService";
import ProfileService from "./services/ProfileService";
import ReactivateService from "./services/ReactivateService";
import SalutationService from "./services/SalutationService";
import SyncService from "./services/SyncService";
import TimeTrackingService from "./services/TimeTrackingService";
import UnitService from "./services/UnitService";
import UserAppSettingsService from "./services/UserAppSettingsService";
import UserService from "./services/UserService";
import UserSettingsService from "./services/UserSettingsService";
import AccountLesson from "./viewModels/AccountLesson";
import AddCredit from "./viewModels/AddCredit";
import AdminSettings from "./viewModels/AdminSettings";
import AllPersons from "./viewModels/AllPersons";
import BillSettings from "./viewModels/BillSettings";
import CalendarSettings from "./viewModels/CalendarSettings";
import ChecklistNotes from "./viewModels/ChecklistNotes";
import CompletedDrivingLessons from "./viewModels/CompletedDrivingLessons";
import CreateBill from "./viewModels/CreateBill";
import CreateTimeTracking from "./viewModels/CreateTimeTracking";
import Drawing from "./viewModels/Drawing";
import EditChecklist from "./viewModels/EditChecklist";
import EditChecklistContent from "./viewModels/EditChecklistContent";
import EducationCard from "./viewModels/EducationCard";
import EducationPaper from "./viewModels/EducationPaper";
import ErrorLog from "./viewModels/ErrorLog";
import ExecuteLesson from "./viewModels/ExecuteLesson";
import FirstTimeIntroduction from "./viewModels/FirstTimeIntroduction";
import FirstTimeUsage from "./viewModels/FirstTimeUsage";
import Help from "./viewModels/Help";
import Login from "./viewModels/Login";
import MainView from "./viewModels/MainView";
import GMap from "./viewModels/Map";
import OrphyDriveSubscription from "./viewModels/OrphyDriveSubscription";
import PaperworkBills from "./viewModels/PaperworkBills";
import PaperWorkDashboard from "./viewModels/PaperWorkDashboard";
import PaperworkTimetracking from "./viewModels/PaperworkTimetracking";
import PersonDetail from "./viewModels/PersonDetail";
import PlanLesson from "./viewModels/PlanLesson";
import ProductManager from "./viewModels/ProductManager";
import ProfileView from "./viewModels/ProfileView";
import RegisterPerson from "./viewModels/RegisterPerson";
import Settings from "./viewModels/Settings";
import SubscriptionChooser from "./viewModels/SubscriptionChooser";
import SynchronisationSettings from "./viewModels/SynchronisationSettings";
import ConfigService from "./services/ConfigService";
import DrivingTestService from "./services/DrivingTestService";
import EducationService from "./services/EducationService";
import TestService from "./services/TestService";
import LfaService from "./services/LfaService";
import ChecklistRatingService from "./services/ChecklistRatingService";
import EducationView from "./viewModels/EducationView";
import LfaView from "./viewModels/LfaView";
import NavParams from "./modules/NavParams";
import EnterLessons from "./viewModels/EnterLessons";
import DateTime from "./modules/DateTime";
import DrivingStudentDocumentService from "./services/DrivingStudentDocumentService";
import { Debug } from "./modules/Debug";
import { translation } from "./services/TranslationService";
import Dal from "./dal/Dal";

(() => {
    window.app = {
        data: {},
        isOnline: () => {
            if (!navigator || !navigator.connection) {
                return true;
            } else {
                return navigator.connection.type !== "none";
            }
        }
    } as any;

    const bootstrap = () => {
        $(() => {
            app.mobileApp = new kendo.mobile.Application(document.body, {
                transition: "none",
                skin: "flat",
                initial: "views/firstTimeIntroduction.html"
            });
        });
    };

    // used for url scheme plugin
    const handleOpenURL = url => {
        setTimeout(() => {
            const splitted = url.substr(13).split(":");
            if (splitted[0] === "PasswordToken") {
                app.mobileApp.navigate(`views/passwordReset.html?token=${encodeURIComponent(splitted[1])}`);
            }
        }, 0);
    };

    if (window.cordova) {
        document.addEventListener(
            "deviceready",
            () => {
                window.firstTimeIntroductionModel = new FirstTimeIntroduction();
                window.loginModel = new Login();
                window.accountLessonModel = new AccountLesson();
                window.synchronisationSettingsModel = new SynchronisationSettings();
                window.personDetailModel = new PersonDetail();
                window.firstTimeProfileModel = new ProfileView();
                window.profileModel = new ProfileView();
                window.productManagerModel = new ProductManager();
                window.firstTimeProductManagerModel = new ProductManager();
                window.subscriptionChooserModel = new SubscriptionChooser();
                window.settingsModel = new Settings();
                window.registerPersonModel = new RegisterPerson();
                window.planLessonModel = new PlanLesson();
                window.paperworkTimetrackingModel = new PaperworkTimetracking();
                window.paperWorkDashboardModel = new PaperWorkDashboard();
                window.paperworkBillsModel = new PaperworkBills();
                window.orphyDriveSubscriptionModel = new OrphyDriveSubscription();
                window.mapModel = new GMap();
                window.mainViewModel = new MainView();
                window.helpModel = new Help();
                window.firstTimeModel = new FirstTimeUsage();
                window.executeLessonModel = new ExecuteLesson();
                window.errorLogModel = new ErrorLog();
                window.educationPaperModel = new EducationPaper();
                window.educationCardModel = new EducationCard();
                window.enterLessonsModel = new EnterLessons();
                window.editChecklistContentModel = new EditChecklistContent();
                window.editChecklistModel = new EditChecklist();
                window.drawingModel = new Drawing();
                window.createTimeTrackingModel = new CreateTimeTracking();
                window.createBillModel = new CreateBill();
                window.completedDrivingLessonsModel = new CompletedDrivingLessons();
                window.checklistNotesModel = new ChecklistNotes();
                window.calendarSettingsModel = new CalendarSettings();
                window.firstTimeCalendarSettingsModel = new CalendarSettings();
                window.billSettingsModel = new BillSettings();
                window.firstTimeBillSettingsModel = new BillSettings();
                window.allPersonsModel = new AllPersons();
                window.adminSettingsModel = new AdminSettings();
                window.addCreditModel = new AddCredit();
                window.educationView = new EducationView();
                window.lfaView = new LfaView();

                window.Util = Util;
                window.DateTime = DateTime;
                window.Debug = Debug;
                window.dal = new Dal();

                window.configService = new ConfigService();
                window.network = new Network();
                window.logger = new Logger(true, true, true, false, "info");
                window.orientationChange = new OrientationChange();
                window.navParams = new NavParams();

                window.activeLesson = [];
                window.syncService = new SyncService();
                window.userService = new UserService();
                window.lastSyncDateService = new LastSyncDateService();
                window.userSettingsService = new UserSettingsService();
                window.salutationService = new SalutationService();
                window.addressTypeService = new AddressTypeService();
                window.contactMediaService = new ContactMediaService();
                window.currentLessonService = new CurrentLessonService();
                window.conditionService = new ConditionService();
                window.drivingStudentDocumentService = new DrivingStudentDocumentService();
                window.paymentWayService = new PaymentWayService();
                window.currencyService = new CurrencyService();
                window.unitService = new UnitService();
                window.companySettingsService = new CompanySettingsService();
                window.profileService = new ProfileService();
                window.personService = new PersonService();
                window.timeTrackingService = new TimeTrackingService();
                window.productService = new ProductService();
                window.lessonService = new LessonService();
                window.loggerService = new LoggerService();
                window.personAddressService = new PersonAddressService();
                window.personContactService = new PersonContactService();
                window.checklistCollectionService = new ChecklistCollectionService();
                window.checklistItemService = new ChecklistItemService();
                window.jobService = new JobService();
                window.billService = new BillService();
                window.jobPositionService = new JobPositionService();
                window.calendarService = new CalendarService();
                window.companyAppSettingsService = new CompanyAppSettingsService();
                window.userAppSettingsService = new UserAppSettingsService();
                window.localDataService = new LocalDataService();
                window.activityService = new ActivityService();
                window.fahrschuelerAppTokenService = new FahrschuelerAppTokenService();
                window.cleanupService = new CleanupService();
                window.reactivateService = new ReactivateService();
                window.lfaService = new LfaService();
                window.ratingService = new ChecklistRatingService();
                window.drivingTestService = new DrivingTestService();
                window.educationService = new EducationService();
                window.testService = new TestService();

                window.debug = false;

                /* --- GLOBAL VARIABLES --- */
                window.appPrefix = "orphydrive://";

                window.handleOpenURL = handleOpenURL;
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                window.orphyDriveOne;

                if (window.configService.Config && window.configService.Config.Production) {
                    // eslint-disable-next-line no-console
                    console.log = () => {};
                }

                if (Util.isiOS()) {
                    StatusBar.overlaysWebView(false);
                    StatusBar.backgroundColorByHexString("#000000");

                    window.open = window.cordova.InAppBrowser.open;
                }
                if (!Util.isiOS()) {
                    // prevent keyboard from hiding input
                    window.addEventListener("native.keyboardshow", () => {
                        const focusedControl = $(".k-prompt-container, .inputFieldContainerFocused")[0];
                        if (focusedControl) {
                            focusedControl.scrollIntoView();
                        }
                    });
                }

                window.onerror = () => {
                    app.mobileApp.hideLoading();
                };

                window.addEventListener("unhandledrejection", () => {
                    app.mobileApp.hideLoading();
                });

                // MyDriveModules.Util.setWindowHeight();
                kendo.culture("de-DE");

                document.addEventListener(
                    "online",
                    () => {
                        if (app.isOnline()) {
                            $("body").removeClass("isOffline");
                            $("body").addClass("isOnline");
                        } else {
                            $("body").removeClass("isOnline");
                            $("body").addClass("isOffline");
                        }
                    },
                    false
                );

                $("body").on("focus", "#searchAllStudents", () => {
                    $(".footer").hide(0);
                });

                $("body").on("blur", "#searchAllStudents", () => {
                    $(".footer").show(400);
                });

                document.addEventListener(
                    "offline",
                    () => {
                        $("body").removeClass("isOnline");
                        $("body").addClass("isOffline");
                    },
                    false
                );

                bootstrap();

                if (app.isOnline()) {
                    $("body").addClass("isOnline");
                } else {
                    $("body").addClass("isOffline");
                }

                $.extend(true, (kendo.ui as any).validator, {
                    rules: {
                        pattern(input) {
                            if (input.is("[data-role=numerictextbox]") && input.is("[data-nopatternvalidation=true]")) {
                                return true;
                            } else {
                                if (window.$(input).val() && window.$(input).attr("pattern")) {
                                    let pattern = window.$(input).attr("pattern");
                                    if (typeof pattern === "string") {
                                        pattern = new window.RegExp(`^(?:${pattern})$`) as any;
                                    }
                                    return (pattern as any).test(window.$(input).val());
                                }
                                return true;
                            }
                        },
                        phonenumber(input) {
                            if (input.is("[data-val-phonenumber]")) {
                                if (input.val() === "") {
                                    return true;
                                }
                                return input.intlTelInput("isValidNumber");
                            } else {
                                return true;
                            }
                        }
                    },
                    messages: {
                        required: translation.t("index.required"),
                        email: translation.t("index.invalid-email"),
                        phonenumber(input) {
                            return input.attr("data-val-phonenumber");
                        }
                    }
                });
            },
            false
        );
    } else {
        bootstrap();
    }
})();
