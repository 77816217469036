import { keys } from "ts-transformer-keys";
import PersonAddressModel from "../models/PersonAddressModel";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import PersonAddress, { IPersonAddress, IPersonAddressDto } from "../types/PersonAddress";
import { addressTypeService } from "./AddressTypeService";
import { BaseService } from "./BaseService";
import { translation } from "./TranslationService";

export default class PersonAddressService extends BaseService<IPersonAddress, IPersonAddressDto> {
    private readonly TABLE_NAME = "PersonAddresses";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<IPersonAddressDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<IPersonAddress>());
    }

    public getApiRoute(): string {
        return `${network.API}/${PersonAddress.EntityTypeId}`;
    }

    public async insert(insertObject: IPersonAddress): Promise<number> {
        const addressType = await addressTypeService.getDefaultAddressType();
        if (!insertObject.Country) {
            insertObject.Country = translation.t("common.swiss");
        }
        if (insertObject.IsPrefered === undefined || insertObject.IsPrefered === null) {
            insertObject.IsPrefered = true;
        }
        if (!insertObject.AddressTypeId) {
            insertObject.AddressTypeId = addressType.Id;
        }
        return super.insert(insertObject);
    }

    public createEntityFromOData(item: IPersonAddressDto): IPersonAddress {
        const entity = new PersonAddress();
        entity.populateFromOData(item);
        return entity;
    }

    protected createEntityFromDb(item: IPersonAddress): IPersonAddress {
        const entity = new PersonAddress();
        entity.populateFromDb(item);
        return entity;
    }

    protected async patchItem(item: PersonAddress): Promise<number> {
        if (!item.IsDeleted) {
            return super.patchItem(item);
        } else {
            try {
                await network.send("DELETE", `${this.getApiRoute()}/${item.Id}`);
                await this.hardDelete(item.Id);
                return 1;
            } catch (response) {
                await this.patchItemErrorHandling(response, item);
            }
        }
    }

    public async getPersonAddressModel(personId: number): Promise<PersonAddressModel> {
        const personAddress = await this.getPersonAddress(personId);
        if (personAddress) {
            return new PersonAddressModel(personAddress);
        }
        const personAddressModel = new PersonAddressModel(new PersonAddress());
        personAddressModel.AddressTypeId = await addressTypeService.getDefaultAddressTypeId();
        return personAddressModel;
    }

    public getPersonAddress = async (personId: number): Promise<IPersonAddress> => {
        const addressType = await addressTypeService.getDefaultAddressType();
        const items = await this.getItems(`PersonId = ${personId} AND AddressTypeId = ${addressType.Id}`, `IsPrefered DESC`, 1);
        return Util.firstOrDefault(items);
    };

    public insertOrUpdateAddress = async (personId: number, address: IPersonAddress): Promise<void> => {
        address.PersonId = personId;
        const existingAddress = await this.getPersonAddress(personId);
        if (existingAddress) {
            await this.update("Id", existingAddress.Id, address.toDbObject(), true);
        } else {
            await this.insert(address);
        }
    };

    public deleteAddressIfExists = async (personId: number): Promise<void> => {
        const existingAddress = await this.getPersonAddress(personId);
        if (existingAddress) {
            if (existingAddress.Id > 0) {
                existingAddress.IsDeleted = true;
                await this.updateEntity(existingAddress, true);
            } else {
                await this.hardDelete(existingAddress.Id);
            }
        }
    };
}

export const personAddressService = new PersonAddressService();
