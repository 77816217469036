import { network } from "../modules/Network";
import { billService } from "./BillService";
import { jobPositionService } from "./JobPositionService";
import { jobService } from "./JobService";
import { lessonService } from "./LessonService";
import { timeTrackingService } from "./TimeTrackingService";
import { ITimeTrackingDto } from "../types/TimeTracking";
import { IJobActivityPositionDto } from "../types/JobActivityPositionEntity";
import { IBillDto } from "../types/Bill";
import { IJobDto } from "../types/Job";
import { ILessonDto } from "../types/Lesson";
import { IEducation, IEducationDto } from "../types/Education";
import { educationService } from "./EducationService";
import { IChecklistRatingDto } from "../types/ChecklistRating";
import { ratingService } from "./ChecklistRatingService";
import { drivingTestService } from "./DrivingTestService";
import { IDrivingTestDto } from "../types/DrivingTest";
import { ITestDto } from "../types/Test";
import { testService } from "./TestService";
import { ILfaDto } from "../types/Lfa";
import { lfaService } from "./LfaService";
import { IOrphyDriveJobPositionDto } from "../types/OrphyDriveJobPosition";
import { orphyDriveJobPositionService } from "./OrphyDriveJobPositionService";
import { personService } from "./PersonService";
import { IJobPositionDto } from "../types/JobPosition";
import { IPersonContactDto } from "../types/PersonContact";
import { personContactService } from "./PersonContactService";
import { IPersonAddressDto } from "../types/PersonAddress";
import { personAddressService } from "./PersonAddressService";
import { drivingStudentDocumentService } from "./DrivingStudentDocumentService";
import { IPersonDto } from "../types/Person";
import { Util } from "../modules/Util";

export default class ReactivateService {
    public async reactivate(personId: number): Promise<void> {
        if (!personId) {
            return;
        }
        const personFilter = `?$filter=PersonId eq ${personId}`;

        await this.getPersonContacts(personFilter);
        await this.getPersonAddresses(personFilter);

        const educations = await this.getEducations(personFilter);

        if (educations.length) {
            const lessonIds = await this.getLessons(`?$filter=${educations.map(e => `EducationId eq ${e.Id}`).join(" OR ")}`);
            if (lessonIds.length) {
                await this.getRatings(lessonIds);
            }
            const educationIdFilter = `?$filter=${educations.map(e => `Id eq ${e.Id}`).join(" OR ")}`;
            const drivingTestIds = await this.getDrivingTests(educationIdFilter);
            await this.getLfas(educationIdFilter);
            if (drivingTestIds.length) {
                await this.getTests(`?$filter=${drivingTestIds.map(id => `DrivingTestId eq ${id}`).join(" OR ")}`);
            }
        }

        await this.getJobs(personFilter);
        await this.getBills(personFilter);
        await this.getJobpositions(personId);
        await this.getTimeTrackings(personFilter);
        await this.getDocuments(personId);
    }

    public async getPersonContacts(personFilter: string): Promise<void> {
        const contactDtos = await network.getItems<IPersonContactDto[]>(`${personContactService.getApiRoute()}${personFilter}`);
        const contacts = contactDtos.map(cd => personContactService.createEntityFromOData(cd));
        for (const contact of contacts) {
            await personContactService.insert(contact);
        }
    }

    public async getPersonAddresses(personFilter: string): Promise<void> {
        const addressDtos = await network.getItems<IPersonAddressDto[]>(`${personAddressService.getApiRoute()}${personFilter}`);
        const personAdress = addressDtos.map(ad => personAddressService.createEntityFromOData(ad));
        for (const personAddress of personAdress) {
            await personAddressService.insert(personAddress);
        }
    }

    private async getEducations(personFilter: string): Promise<IEducation[]> {
        const educationDtos = await network.getItems<IEducationDto[]>(`${educationService.getApiRoute()}${personFilter}`);
        const educations = educationDtos.map(ed => educationService.createEntityFromOData(ed));
        for (const education of educations) {
            await educationService.insert(education);
        }

        return educations;
    }

    private async getLessons(educationFilter: string): Promise<number[]> {
        const lessonsDtos = await network.getItems<ILessonDto[]>(`${lessonService.getApiRoute()}${educationFilter}`);
        const lessons = lessonsDtos.map(ld => lessonService.createEntityFromOData(ld));
        for (const lesson of lessons) {
            await lessonService.insert(lesson);
        }
        return lessons.map(x => x.Id);
    }

    private async getRatings(lessonIds: number[]): Promise<void> {
        const ratingDtos = await network.send<IChecklistRatingDto[]>("POST", `${network.BASE_API}/OrphyDriveChecklistRating/GetChecklistRatingsByLessonIds`, lessonIds);
        const ratings = ratingDtos.map(rd => ratingService.createEntityFromOData(rd));
        for (const rating of ratings) {
            await ratingService.insert(rating);
        }
    }

    private async getDrivingTests(educationFilter: string): Promise<number[]> {
        const drivingTestDtos = await network.getItems<IDrivingTestDto[]>(`${drivingTestService.getApiRoute()}${educationFilter}`);
        const drivingTests = drivingTestDtos.map(dtd => drivingTestService.createEntityFromOData(dtd));
        for (const drivingtest of drivingTests) {
            await drivingTestService.insert(drivingtest);
        }
        return drivingTests.map(x => x.Id);
    }

    private async getTests(drivingTestFilter: string): Promise<void> {
        const testDtos = await network.getItems<ITestDto[]>(`${testService.getApiRoute()}${drivingTestFilter}`);
        const tests = testDtos.map(td => testService.createEntityFromOData(td));
        for (const test of tests) {
            await testService.insert(test);
        }
    }

    private async getLfas(educationFilter: string): Promise<void> {
        const lfaDtos = await network.getItems<ILfaDto[]>(`${lfaService.getApiRoute()}${educationFilter}`);
        const lfas = lfaDtos.map(ld => lfaService.createEntityFromOData(ld));
        for (const lfa of lfas) {
            await lfaService.insert(lfa);
        }
    }

    public async getJobs(personFilter: string): Promise<void> {
        const jobs = await network.getItems<IJobDto[]>(`${jobService.getApiRoute()}${personFilter}`);
        for (const job of jobs) {
            await jobService.insert(jobService.createEntityFromOData(job));
        }
    }

    public async getBills(personFilter: string): Promise<any> {
        const bills = await network.getItems<IBillDto[]>(`${billService.getApiRoute()}${personFilter}`);
        for (const bill of bills) {
            await billService.insert(billService.createEntityFromOData(bill));
        }
    }

    public async getJobpositions(personId: number): Promise<void> {
        const jobs = await jobService.getJobsByPersonId(personId);
        for (const job of jobs) {
            const jobPositions = await this.requestPositions(job.Id, `${network.ODATA_API_V3}/job`);
            for (const jobPosition of jobPositions) {
                await jobPositionService.insert(jobPositionService.createEntityFromOData(jobPosition));
            }
            if (jobPositions.length) {
                await this.getOrphyDriveJobPositions(jobPositions.map(x => x.Id));
            }
        }

        const bills = await billService.getAllBillsByPersonId(personId);
        for (const bill of bills) {
            const billPositions = await this.requestPositions(bill.Id, `${network.ODATA_API_V3}/bill`);
            for (const billPosition of billPositions) {
                await jobPositionService.insert(jobPositionService.createEntityFromOData(billPosition));
            }
        }
    }

    private async requestPositions(parentId: number, odataRoute: string): Promise<IJobPositionDto[]> {
        const jobPositions = [];
        if (parentId > 0) {
            const jobPositionIds = await network.getItems<IJobActivityPositionDto[]>(`${odataRoute}(${parentId})/GetPositionIds`);
            for (const jobPositionId of jobPositionIds) {
                const jobPosition = await network.getItems(`${network.ODATA_API_V3}/JobPosition(${jobPositionId})`);
                jobPositions.push(jobPosition);
            }
        }
        return jobPositions;
    }

    private async getOrphyDriveJobPositions(jobPositionIds: number[]): Promise<void> {
        const odriveJobPositionDtos = await network.send<IOrphyDriveJobPositionDto[]>("POST", `${network.BASE_API}/OrphyDriveJobPosition/GetItemsByJobPositionIdArray`, jobPositionIds);
        const orphyDriveJobPositions = odriveJobPositionDtos.map(odjd => orphyDriveJobPositionService.createEntityFromOData(odjd));
        for (const odJobPos of orphyDriveJobPositions) {
            await orphyDriveJobPositionService.insert(odJobPos);
        }
    }

    private async getTimeTrackings(personFilter: string): Promise<any> {
        const timetrackings = await network.getItems<ITimeTrackingDto[]>(`${timeTrackingService.getApiRoute()}${personFilter}`);
        for (const timetracking of timetrackings) {
            await timeTrackingService.insert(timeTrackingService.createEntityFromOData(timetracking));
        }
    }

    private async getDocuments(personId: number): Promise<void> {
        const personDtos = await network.get<IPersonDto[]>(`${personService.getApiRoute()}?$filter=Id eq ${personId}`);
        const personDto = Util.firstOrDefault(personDtos);
        if (personDto) {
            await drivingStudentDocumentService.fetchDocuments(personId, personDto.Blobs);
        }
    }
}

export const reactivateService = new ReactivateService();
