import { nameof } from "ts-simple-nameof";
import DateTime from "../modules/DateTime";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseTimeTracking {
    IssueDate: Date | string;
    DurationTicks: number;
    Title: string;
    Status: number;
    ActivityId: number;
    MitarbeiterId: number;
    PersonId: number;
    Beschreibung: string;
}

export interface ITimeTrackingDto extends IBaseDto, IBaseTimeTracking {}
export interface ITimeTracking extends IBaseEntity, IBaseTimeTracking {}
export default class TimeTracking extends BaseEntity<ITimeTracking, ITimeTrackingDto> implements ITimeTracking {
    public static get EntityTypeId(): string {
        return "c96cf7d8-b129-5015-ba49-0e4cef9e3393";
    }

    public IssueDate: Date | string;
    public DurationTicks: number;
    public Title: string;
    public Status: number;
    public ActivityId: number;
    public MitarbeiterId: number;
    public PersonId: number;
    public Beschreibung: string;

    public populateFromDb(dbItem: ITimeTracking): void {
        super.populateFromDb(dbItem);
        this.IssueDate = DateTime.fromDbDateTime(dbItem.IssueDate as string);
        this.DurationTicks = dbItem.DurationTicks;
        this.Title = dbItem.Title;
        this.Status = dbItem.Status;
        this.ActivityId = dbItem.ActivityId;
        this.MitarbeiterId = dbItem.MitarbeiterId;
        this.PersonId = dbItem.PersonId;
        this.Beschreibung = dbItem.Beschreibung;
    }

    public populateFromOData(oDataItem: ITimeTrackingDto): void {
        super.populateFromOData(oDataItem);
        this.IssueDate = DateTime.fromODataDateTime(oDataItem.IssueDate as string);
        this.DurationTicks = oDataItem.DurationTicks;
        this.Title = oDataItem.Title;
        this.Status = oDataItem.Status;
        this.ActivityId = oDataItem.ActivityId;
        this.MitarbeiterId = oDataItem.MitarbeiterId;
        this.PersonId = oDataItem.PersonId;
        this.Beschreibung = oDataItem.Beschreibung;
    }

    public toODataObject(): ITimeTrackingDto {
        const oDataObject = super.toODataObject();
        oDataObject.IssueDate = DateTime.toODataDateTime(this.IssueDate as Date);
        oDataObject.DurationTicks = this.DurationTicks;
        oDataObject.Title = this.Title;
        oDataObject.Status = this.Status;
        oDataObject.ActivityId = this.ActivityId;
        oDataObject.MitarbeiterId = this.MitarbeiterId;
        oDataObject.PersonId = this.PersonId;
        oDataObject.Beschreibung = this.Beschreibung;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<TimeTracking>(x => x.IssueDate),
            DateTime.toDbDateTime(this.IssueDate as Date)
        );
        dbObject.set(
            nameof<TimeTracking>(x => x.DurationTicks),
            this.DurationTicks
        );
        dbObject.set(
            nameof<TimeTracking>(x => x.Title),
            this.Title
        );
        dbObject.set(
            nameof<TimeTracking>(x => x.Status),
            this.Status
        );
        dbObject.set(
            nameof<TimeTracking>(x => x.ActivityId),
            this.ActivityId
        );
        dbObject.set(
            nameof<TimeTracking>(x => x.MitarbeiterId),
            this.MitarbeiterId
        );
        dbObject.set(
            nameof<TimeTracking>(x => x.PersonId),
            this.PersonId
        );
        dbObject.set(
            nameof<TimeTracking>(x => x.Beschreibung),
            this.Beschreibung
        );
        return dbObject;
    }
}
