/* eslint-disable @typescript-eslint/dot-notation */
import { IJobPosition, IJobPositionDto, JobPosition } from "./JobPosition";

export interface IBaseJobTextPosition {}

export interface IJobTextPositionDto extends IBaseJobTextPosition, IJobPositionDto {}
export interface IJobTextPosition extends IBaseJobTextPosition, IJobPosition {}

export default class JobTextPosition extends JobPosition<IJobPosition, IJobPositionDto> implements IJobTextPosition {
    public static TYPE_NAME = "JobTextPosition";
    public static ODATA_TYPE_NAME = "#SharedDto.PublicApi.JobTextPositionDtoV2";

    public get Discriminator(): string {
        return JobTextPosition.TYPE_NAME;
    }

    public odataTypeName(): string {
        return JobTextPosition.ODATA_TYPE_NAME;
    }

    public createChargingPosition(parentId: number): JobTextPosition {
        const chargingPosition = new JobTextPosition();
        chargingPosition.copyFrom(this); // Copy common data
        chargingPosition.SortOrder = 0;
        chargingPosition.ParentId = parentId;
        return chargingPosition;
    }

    public toODataObject(): IJobPositionDto {
        const oDataObject = super.toODataObject();
        delete oDataObject["CustomColumns"];
        delete oDataObject["UnitId"];
        delete oDataObject["Optional"];
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        delete dbObject["LessonCount"];
        delete dbObject["UnitId"];
        delete dbObject["Optional"];
        return dbObject;
    }
}
