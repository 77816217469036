import ChecklistItemModel from "../models/ChecklistItemModel";
import RatingModel from "../models/RatingModel";
import Popup from "../modules/Popup";
import { Util } from "../modules/Util";
import { checklistItemService } from "../services/ChecklistItemService";
import { ratingService } from "../services/ChecklistRatingService";
import { companyAppSettingsService } from "../services/CompanyAppSettingsService";
import { educationService } from "../services/EducationService";
import { lessonService } from "../services/LessonService";
import ChecklistRating from "../types/ChecklistRating";
import { BaseView } from "./BaseView";
import EducationCardExport from "../export/EducationCardExport";
import { userAppSettingsService } from "../services/UserAppSettingsService";
import { IUserAppSettings } from "../types/UserAppSettings";
import LessonModel from "../models/LessonModel";
import { activeLesson } from "../modules/ActiveLesson";
import { translation } from "../services/TranslationService";

export default class EducationCard extends BaseView {
    private ratingLegendPopup: Popup;
    private lessonNotePopup: Popup;
    private userAppSettings: IUserAppSettings;
    private checklistScroller;
    private editor: kendo.ui.Editor;

    constructor() {
        super();
        this.ratingLegendPopup = new Popup();
        this.lessonNotePopup = new Popup();
        super.init(this);
    }

    public getNavPoint(): string {
        return `views/personDetail.html`;
    }

    public initView(): void {
        $("#checklistViewEducationCard").show();
        $("#twinPersonList").show();
        $("#twinPersonView").show();
        this.checklistScroller = $("#checklistViewEducationCardListView").data().kendoMobileListView.scroller();
        this.initEditor();
    }

    private initEditor() {
        this.editor = $("#lessonNoteEditorPD")
            .kendoEditor({
                tools: []
            })
            .data("kendoEditor");
        $(this.editor.body)
            .keyup(() => {
                this.editor.update();
            })
            .focus(() => {
                $(".inputFieldContainerFocused").removeClass("inputFieldContainerFocused");
                $("#lessonNoteEditorPD").parent().addClass("inputFieldContainerFocused");
            })
            .blur(() => {
                $(".inputFieldContainerFocused").removeClass("inputFieldContainerFocused");
            });
        $(".editorToolbarWindow").remove();
    }

    protected getComputedFields(map: Map<string, string>): Map<string, string> {
        super.getComputedFields(map);
        map.set("RatingValue", "fullChecklist");
        return map;
    }

    public async loadData(e: any): Promise<void> {
        const updateLesson = e.view.params.updateLesson === "true";
        this.set("updateLesson", updateLesson);
        this.set("planLessonInvisible", updateLesson);
        this.userAppSettings = await userAppSettingsService.getSettings();

        const education = await educationService.getSelectedEducation(Util.getEducationId(this.personId), this.personId);
        this.set("saveLesson", translation.t("education-card.save-ranking"));
        this.set("exportVisible", await lessonService.hasRatedLessons());

        this.set(
            "fullChecklist",
            kendo.data.DataSource.create({
                group: {
                    field: "Group",
                    dir: "asc",
                    compare: (a, b) => {
                        const compareA = Util.firstOrDefault<ChecklistItemModel>(a.items);
                        const compareB = Util.firstOrDefault<ChecklistItemModel>(b.items);
                        if (compareA && compareB) {
                            return compareA.TitleSort - compareB.TitleSort;
                        }
                        return 0;
                    }
                } as any,
                sort: { field: "ChapterSort", dir: "asc" },
                transport: {
                    read: async options => {
                        const checklistItemModels = (await checklistItemService.getChecklistModel(education.ChecklistCollectionId)).filter(x => x.Id > 0);
                        const lessonCount = await lessonService.getCompletedLessons(Util.getEducationId(this.personId));
                        const previousLessons = await lessonService.getPreviousLessons(Util.getEducationId(this.personId));

                        const lesson = updateLesson
                            ? await lessonService.getUpdateLesson(Util.getEducationId(this.personId))
                            : await lessonService.getCurrentLessonModel(Util.getEducationId(this.personId));
                        const ratings = updateLesson ? await ratingService.getUpdateRatings(lesson.Id) : await ratingService.getCurrentRatingModels(lesson.Id);

                        this.set("lesson", lesson);

                        const previousRatings = await ratingService.getPreviousRatingModels(Util.getEducationId(this.personId));

                        this.set("exportVisible", !updateLesson && previousRatings.length);
                        const plannedLessons = activeLesson.length ? activeLesson[0].getLessons() : [];
                        checklistItemModels.forEach(checklistItem => {
                            checklistItem.IsUpdate = updateLesson;
                            checklistItem.CurrentRating =
                                ratings.find(x => x.ChecklistItemId === checklistItem.Id && (updateLesson ? x.IsUpdateRating : x.IsCurrentRating)) || new RatingModel(new ChecklistRating());

                            const previousChecklistItemRatings = previousRatings.filter(x => x.ChecklistItemId === checklistItem.Id && !x.IsCurrentRating);

                            checklistItem.PreviouseRatings = previousLessons.map(l => previousChecklistItemRatings.find(cr => cr.LessonId === l.Id) || new RatingModel());
                            checklistItem.LessonCount = lessonCount;
                            checklistItem.WasInPlannedLesson = !!plannedLessons.find(x => x.Id === checklistItem.Id);
                        });
                        options.success(checklistItemModels);
                    }
                },
                change: () => {
                    this.set("hasNoRating", this.getHasNoRating());
                }
            } as kendo.data.DataSourceOptions)
        );
        await this.get<kendo.data.DataSource>("fullChecklist").read();
    }

    public showView(e: any): void {
        if (e.view.params.fromDrawing === "true" || e.view.params.fromNotes === "true") {
            return;
        }
        this.checklistScroller.reset();
        const { scrollPosition } = e.view.params;
        if (this.userAppSettings.CollapseEducationCard) {
            // no scrolling but don't collapse this chapter
            $(".headerTemplateEducationCard").each((_, element) => this.toggleSubChapters(element, scrollPosition));
        } else if (scrollPosition) {
            const element = $(`#${scrollPosition.match(/\b\w+\b/g).join("")}EC`);
            if (element && element.length > 0) {
                const headerHeight = $("#educationCardHeader").height();
                const { top } = element.offset();
                const scroll = top - headerHeight;
                this.checklistScroller.scrollTo(0, -scroll);
            }
        }
    }

    public getHasNoRating(): boolean {
        return this.get("fullChecklist") && !(this.get("fullChecklist") as any).data().some((x: ChecklistItemModel) => x.CurrentRating.RatingValue);
    }

    public async setLastRating() {
        const checklistItemModels = this.get<kendo.data.DataSource>("fullChecklist").data() as unknown as ChecklistItemModel[];
        for (const item of checklistItemModels) {
            await item.setLastRating(this.personId);
        }
    }

    public async back(): Promise<void> {
        if (this.get<boolean>("updateLesson")) {
            app.mobileApp.showLoading();
            await lessonService.resetUpdateLesson(Util.getEducationId(this.personId));
            app.mobileApp.hideLoading();
        }
        super.back();
    }

    public async enterLesson(): Promise<void> {
        app.mobileApp.showLoading();
        if (this.get<boolean>("updateLesson")) {
            const updateLesson = await lessonService.getUpdateLesson(Util.getEducationId(this.personId));
            await ratingService.saveUpdateRatings(updateLesson.Id);
        }
        app.mobileApp.hideLoading();
        app.mobileApp.navigate(`views/enterLessons.html?updateLesson=${this.get<boolean>("updateLesson")}`);
    }

    public async showRatingLegend() {
        const ratingLabels = (await companyAppSettingsService.getSettings()).RatingLabels;
        const content = $("#ratingLegendContent");
        content.empty();
        for (let i = 1; i < ratingLabels.length; i++) {
            content.append(`<div  style="padding-top: 7px; color: ${Util.getRatingColor(i)}">${i}. ${ratingLabels[i]}</div>`);
        }
        await this.ratingLegendPopup.renderContentEditPopup("#ratingLegendPopup", $("#educationCardHeader").height());
    }

    public closeRatingLegend() {
        this.ratingLegendPopup.closePopup();
    }

    public async exportEducationCard() {
        await new EducationCardExport().export(this.personId);
    }

    public planLesson(): void {
        app.mobileApp.navigate(`views/planLesson.html?navPoint=views/personDetail.html`);
    }

    public async openLessonNotePopup() {
        await this.lessonNotePopup.renderContentEditPopup("#lessonNoteED");
        const lessonModel = this.get<LessonModel>("lesson");
        this.editor.value(lessonModel.Note);
    }

    public closeLessonNotePopup() {
        const lessonModel = this.get<LessonModel>("lesson");
        this.editor.value(lessonModel.Note);
        this.lessonNotePopup.closePopup();
    }

    public async saveLessonNote() {
        const lessonModel = this.get<LessonModel>("lesson");
        await lessonService.update("Id", lessonModel.Id, new Map<string, any>().set("Note", this.editor.value()), true);
        this.set("lesson.Note", this.editor.value());
        this.lessonNotePopup.closePopup();
    }

    public openFreeHandDrawing() {
        app.mobileApp.navigate(`views/drawing.html?name=${translation.t("drawing.file-name")}&navPoint=${encodeURI("views/educationCard.html?fromDrawing=true")}`);
    }

    public toggleSubChapters(e, exclude: string) {
        const header = $(e);
        if (header.find(".chapterTitle").attr("name") === `${exclude}EC`) {
            return;
        }
        header.closest(".km-group-container").find(".km-list li").toggle();
        const icon = header.find(".icons");
        if (icon.hasClass("icon-arrow-up")) {
            icon.removeClass("icon-arrow-up");
            icon.addClass("icon-arrow-down");
        } else {
            icon.removeClass("icon-arrow-down");
            icon.addClass("icon-arrow-up");
        }
    }
}
