import DateTime from "../modules/DateTime";
import { translation } from "../services/TranslationService";

export default class TimeTrackingModel extends kendo.data.ObservableObject {
    public TimeTrackingId: number;
    public Title: string;
    public Group: number;
    public IsTotal: boolean;
    public IssueDate: Date;
    public Order: number;
    public LessonCount: number;
    public MissedReason: string;
    public MissedLesson: boolean;
    public PersonName: string;
    public Avatar: string;
    public ChecklistName: string;
    public IsNewDay: boolean;
    public DurationTicks: number;
    public IsEditAble: boolean;
    public GlobalLessonNote: string;

    public getIssueDate(): string {
        return DateTime.parseNumberDate(this.IssueDate);
    }

    get StartTime(): string {
        if (this.IssueDate) {
            return DateTime.getTime(this.IssueDate);
        }
        return "";
    }

    public getChecklistName(): string {
        return this.ChecklistName || "";
    }

    get EndTime(): string {
        if (this.IssueDate) {
            return DateTime.getTime(DateTime.getEndTime(this.IssueDate, this.DurationTicks));
        }
        return "";
    }

    get LessonCountText(): string {
        if (this.LessonCount) {
            return translation.t("account-lesson.account-lesson-name", { count: this.LessonCount });
        }
        return "";
    }

    get Duration(): string {
        return DateTime.getLessonCount(this.DurationTicks);
    }

    get FromTo(): string {
        return `${this.StartTime} - ${this.EndTime}`;
    }

    get LessonDuration(): string {
        return DateTime.getLessonCount(this.DurationTicks);
    }

    get SplittetLessonDuration(): string {
        const duration = this.LessonDuration;
        const durationArr = duration.split(" ");
        if (durationArr.length === 4) {
            return `<span class="noWrap">${durationArr[0]} ${durationArr[1]}</span> <span class="noWrap">${durationArr[2]} ${durationArr[3]}</span>`;
        } else {
            return `<span>${duration}</span>`;
        }
    }
}
