import { translation } from "../services/TranslationService";
import { Util } from "./Util";

export default class ConfirmContent {
    public static askDeleteOldPositions = () =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.delete-old-position-title"
        )}</div><div class='dialogDescription'>${translation.t("confirm.delete-old-position-dialog")}</div></div>`;

    public static reactivateStudent = (firstName: string, lastName: string) =>
        `<div class='centerText'><div class='dialogIcon'><i class='icons icon-danger'></i></div><div class='largeTitle'>${firstName} ${lastName} ${translation.t(
            "confirm.activate-student"
        )}</div></div>`;

    public static deleteLessonAndTimetracking = () =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.delete-lesson-and-time"
        )}</div></div>`;

    public static deletePositionsForLesson = () =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.delete-position"
        )}</div></div>`;

    public static deleteChecklistItem = (isParent: boolean, itemName: string) =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>"${itemName}" ${translation.t(
            "confirm.delete-checklist-item-title"
        )}</div><div class='dialogDescription'>${
            isParent
                ? translation.t("confirm.delete-checklist-item-parent-dialog")
                : translation.t("confirm.delete-checklist-item-dialog")
        }</div></div>`;

    public static deleteImage = () => `<div class="centerText"><p>${translation.t("confirm.delete-image")}</p></div>`;

    public static addChecklistItemImage = () =>
        `<div class="centerText"><p>${translation.t(
            "confirm.add-checklist-item-image"
        )}</p><div><textarea rows="10" cols="30" id="imageDescriptionECC" />`;

    public static addChecklistItemVideo = () =>
        `<div class="centerText"><p>${translation.t(
            "confirm.add-checklist-item-video"
        )}</p><div class="smallTitle"><i>(${translation.t(
            "common.eg-short-name"
        )} https://www.youtube.com/watch?v=qyro81iV52k)</i></div></div><input id="videoUrl" type="url" placeholder="https://www.youtube.com/watch?v=qyro81iV52k" style="width:100%" /><div style="padding: 10px">${translation.t(
            "confirm.add-checklist-item-video-end"
        )}</div><div><textarea style="width: 94%" rows="10" cols="30" id="videoDescriptionECC"></textarea></div>`;

    public static sendLog = () =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.send-log-title"
        )}</div><div class='dialogDescription'>${translation.t("confirm.send-log")}</div></div>`;

    public static deleteLog = () =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "error-log.delete-error-log"
        )}</div></div>`;

    public static syncReminder = () =>
        `<div class="default-padding centerText loginLogo"><div class="dialogIcon default-margin"><i class="icons custom-icon-sync-warning"></i></div><div class="default-padding"><div class="largeTitle">${translation.t(
            "confirm.sync-reminder-title"
        )}</div><p class="default-margin">${translation.t(
            "confirm.sync-reminder"
        )}</p><p><input type="checkbox" class="k-checkbox" id="showSyncMessageCheckbox" /><label for="showSyncMessageCheckbox" class="k-checkbox-label">${translation.t(
            "confirm.sync-reminder-checkbox"
        )}</label></p>`;

    public static reopenBill = () =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.reopen-bill-title"
        )}</div><div class='dialogDescription'>${translation.t("confirm.reopen-bill-dialog")}</div></div>`;

    public static cancelBill = () =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.cancel-bill-title"
        )}</div><div class='dialogDescription'>${translation.t("confirm.cancel-bill-dialog")}</div></div>`;

    public static deletePosition = (description: string) =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>"${description}" ${translation.t(
            "confirm.delete-checklist-item-title"
        )}</div></div>`;

    public static congratulationsScreen = (firstName: string, lastName: string) =>
        `<div class='centerText confirmContent'><div class='largeTitle'>${translation.t(
            "confirm.congratulation"
        )}</div></div><div class='default-flex confirmAvatar'>${Util.getAvatar(
            firstName,
            lastName
        )}<div class='largeTitle'>${firstName} ${lastName}</div>`;

    public static createFinalBill = (firstName: string, lastName: string) =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-info'></i></div><div class='largeTitle'>${translation.t(
            "confirm.create-final-bill-title",
            {
                firstName: firstName,
                lastName: lastName
            }
        )}</div><div>${translation.t("confirm.create-final-bill-dialog-1")}</div><br/><div>${translation.t(
            "confirm.create-final-bill-dialog-2"
        )}</div><br/><div class='dialogDescription'>${translation.t("confirm.create-final-bill-dialog-3")}</div></div>`;

    public static deleteProduct = (name: string) =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>"${name}" ${translation.t(
            "confirm.delete-checklist-item-title"
        )}</div></div>`;

    public static createMailDuplicate = (email: string) =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.email-dublicate-title",
            {
                email: email
            }
        )}</div><div>${translation.t("confirm.contact-dublicate-dialog")}</div></div>`;

    public static createNameDuplicate = (firstName: string, lastName: string) =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.name-dublicate-title",
            {
                firstName: firstName,
                lastName: lastName
            }
        )}</div><div>${translation.t("confirm.contact-dublicate-dialog")}</div></div>`;

    public static logoutWarning = () =>
        `<div class='centerText'><div class='dialogIcon'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.logout-warning"
        )}</div></div>`;

    public static syncBeforeLogout = () =>
        `<div class='centerText'><div class='dialogIcon'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.sync-before-logout"
        )}</div></div>`;

    public static reducePositionAmountOnUpdateLesson = (lessonCount: number) =>
        `<div class='centerText'><div class='dialogIcon'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.reduce-position-ammount",
            {
                lessonCount: lessonCount
            }
        )}</div></div>`;

    public static reducePositionAmountOnUpdateLessonCountNotChanged = (lessonCount: number) =>
        `<div class='centerText'><div class='dialogIcon'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.reduce-position-ammount-not-changed",
            {
                lessonCount: lessonCount
            }
        )}</div></div>`;

    public static reloadStudentData = (displayName: string) =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${translation.t(
            "confirm.reload-student",
            {
                displayName: displayName
            }
        )}</div></div>`;

    public static deleteDrivingStudent = (displayName: string) =>
        `<div class='centerText'><div class='dialogIcon default-margin'><i class='icons icon-danger'></i></div><div class='largeTitle'>${displayName} ${translation.t(
            "confirm.delete"
        )}?</div></div>`;
}
