import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";
import { translation } from "../services/TranslationService";

export enum UnitType {
    Custom,
    Pieces,
    Hours,
    Kilometers
}

export interface IBasUnit {
    Name: string;
    Type: UnitType;
}

export interface IUnitDto extends IBaseDto, IBasUnit {}
export interface IUnit extends IBaseEntity, IBasUnit {}

export default class Unit extends BaseEntity<IUnit, IUnitDto> implements IUnit {
    public static get EntityTypeId(): string {
        return "c44b61ff-acc6-5b2a-beff-db258cc61225";
    }

    public Name: string;
    public Type: UnitType;

    public populateFromDb(dbItem: IUnit): void {
        super.populateFromDb(dbItem);
        this.Type = dbItem.Type;
        if (dbItem.Type === UnitType.Custom) {
            this.Name = dbItem.Name;
        } else {
            this.Name = translation.t(`units.type_${dbItem.Type}`);
        }
    }

    public populateFromOData(oDataItem: IUnitDto): void {
        super.populateFromOData(oDataItem);
        this.Type = oDataItem.Type;
        if (oDataItem.Type === UnitType.Custom) {
            this.Name = oDataItem.Name;
        } else {
            this.Name = translation.t(`units.type_${oDataItem.Type}`);
        }
    }

    public toODataObject(): IUnitDto {
        return null; // Units are not pushed to Orphy
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Unit>(x => x.Name),
            this.Name
        );
        dbObject.set(
            nameof<Unit>(x => x.Type),
            this.Type
        );
        return dbObject;
    }
}
