import { keys } from "ts-transformer-keys";
import { DashboardTestModel } from "../models/DashboardTestModel";
import DateTime from "../modules/DateTime";
import { network } from "../modules/Network";
import { Util } from "../modules/Util";
import Test, { ITest, ITestDto } from "../types/Test";
import { BaseService } from "./BaseService";
import { checklistCollectionService } from "./ChecklistCollectionService";
import { drivingTestService } from "./DrivingTestService";
import { educationService } from "./EducationService";
import { personService } from "./PersonService";

export default class TestService extends BaseService<ITest, ITestDto> {
    private readonly TABLE_NAME = "Tests";

    public getTableName(): string {
        return this.TABLE_NAME;
    }

    public getDtoFields() {
        return keys<ITestDto>();
    }

    public getDbFields(): string[] {
        return this.filterFunctions(keys<ITest>());
    }

    public getApiRoute(): string {
        return `${network.API}/${Test.EntityTypeId}`;
    }

    protected createEntityFromDb(item: ITest): ITest {
        const test = new Test();
        test.populateFromDb(item);
        return test;
    }

    public createEntityFromOData(item: ITestDto): ITest {
        const test = new Test();
        test.populateFromOData(item);
        return test;
    }

    public async getFutureDrivingTestModels(): Promise<DashboardTestModel[]> {
        const currentDate = new Date().toISOString();
        const tests = await this.getItems(`TestDate IS NOT NULL AND TestDate > '${currentDate}' AND (TestPassed IS NULL OR TestPassed = ?)`, "TestDate ASC", 25, null, null, [false]);

        const drivingTests = await drivingTestService.getItems(`Id IN ${Util.joinIds(tests.map(x => x.DrivingTestId))}`);
        const educations = await educationService.getItems(`Id IN ${Util.joinIds(drivingTests.map(x => x.Id))}`);
        const persons = await personService.getPersonItems(`Id IN ${Util.joinIds(educations.map(x => x.PersonId))}`);
        const checklistCollections = await checklistCollectionService.getItems();
        const dashboardTests: DashboardTestModel[] = [];
        for (const person of persons) {
            const personEducations = educations.filter(x => x.PersonId === person.Id);
            const personDrivingTests = drivingTests.filter(x => personEducations.some(e => e.Id === x.Id));
            const personTests = tests.filter(x => personDrivingTests.some(pdt => pdt.Id === x.DrivingTestId));
            for (const personTest of personTests) {
                const personEducation = educations.find(x => x.Id === personTest.DrivingTestId);
                const checklistCollection = checklistCollections.find(x => x.Id === personEducation.ChecklistCollectionId);
                dashboardTests.push({
                    PersonId: person.Id,
                    Avatar: Util.getAvatar(person.FirstName, person.LastName),
                    Date: DateTime.parseStringDateTime(personTest.TestDate as Date),
                    SortDate: personTest.TestDate as Date,
                    Title: `${person.FirstName} ${person.LastName}`,
                    ChecklistCollectionName: checklistCollection ? checklistCollection.Name : ""
                });
            }
        }
        return dashboardTests.sort((a, b) => a.SortDate.getTime() - b.SortDate.getTime());
    }

    public getItemsByDrivingTestId(drivingTestId: number): Promise<ITest[]> {
        return this.getItems(`DrivingTestId = ${drivingTestId}`, "TestDate ASC");
    }
}

export const testService = new TestService();
