import { network } from "../modules/Network";
import NetworkError from "../modules/NetworkError";
import { Util } from "../modules/Util";
import ISynchronizableService from "./ISynchronizableService";
import { lastSyncDateService } from "./LastSyncDateService";
import { IDiffDto } from "../types/IDiffDto";
import { IBaseDto, IBaseEntity } from "../types/BaseEntity";
import { EventType } from "../types/EventType";
import { v4 as uuidv4 } from "uuid";
import { nameof } from "ts-simple-nameof";
import { logger } from "../modules/Logger";
import { translation } from "./TranslationService";
import { dal } from "../dal/Dal";

export abstract class BaseService<TEntity extends IBaseEntity, TDto extends IBaseDto> implements ISynchronizableService {
    private readonly TOP_COUNT: number = 500;

    public abstract getTableName(): string;
    public abstract getApiRoute(): string;

    protected abstract createEntityFromDb(item: TEntity): TEntity;
    protected abstract createEntityFromOData(item: TDto): TEntity;

    public abstract getDtoFields(): string[];
    public abstract getDbFields(): string[];

    protected filterFunctions(names: string[]) {
        return names.filter(x => x !== nameof<IBaseEntity>(n => n.populateFromDb) && x !== nameof<IBaseEntity>(n => n.toDbObject) && x !== nameof<IBaseEntity>(n => n.toODataObject));
    }

    protected forbiddenItems: number;

    protected getPostRoute(): string {
        return this.getApiRoute();
    }

    protected getPatchRoute(id: number): string {
        return `${this.getApiRoute()}/${id}`;
    }

    protected localItemSelect(): string {
        return "Id";
    }

    public async insertMany(insertObjects: TEntity[]): Promise<void> {
        await dal.withTransaction(tx => {
            for (const insertObject of insertObjects) {
                const data = insertObject.toDbObject();
                const columns = Array.from(data.keys()).join(",");
                const values = Array.from(data.values()).map(x => BaseService.transformUndefinedToNull(x));
                const idIndex = Array.from(data.keys()).findIndex(x => x === "Id");
                if (!insertObject.Id) {
                    values[idIndex] = this.getIdStatement();
                }
                const sql = `INSERT INTO ${this.getTableName()} (${columns}) VALUES (${Array(values.length).fill("?")})`;
                tx.executeSql(sql, values);
            }
        });
    }

    public async insert(insertObject: TEntity): Promise<number> {
        if (!insertObject.IsDeleted) {
            insertObject.IsDeleted = false;
        }

        if (!insertObject.Uuid) {
            insertObject.Uuid = uuidv4();
        }

        const data = insertObject.toDbObject();
        const columns = Array.from(data.keys()).join(",");
        const values = Array.from(data.values()).map(x => BaseService.transformUndefinedToNull(x));

        const sql = `INSERT INTO ${this.getTableName()} (${columns}) VALUES (${Array(values.length).fill("?")})`;

        if (insertObject.Id) {
            await dal.execute(`DELETE FROM ${this.getTableName()} WHERE Id = ?`, [insertObject.Id]);
            await dal.execute(sql, values);
            return insertObject.Id;
        }

        const newId = (await dal.firstOrDefault<{ Id: number }>(this.getIdStatement())).Id;
        const idIndex = Array.from(data.keys()).findIndex(x => x === "Id");
        values[idIndex] = newId;
        await dal.execute(sql, values);
        return newId;
    }

    protected getIdStatement(): string {
        return `SELECT MIN(0, COALESCE(MIN(Id), 0)) - 1 AS Id FROM ${this.getTableName()}`;
    }

    public updateEntity = async (entity: TEntity, isDirty: boolean = null): Promise<void> => {
        await this.update("Id", entity.Id, entity.toDbObject(), isDirty);
    };

    public update = async (columnName: string, columnId: number, updateObject: Map<string, any>, isDirty: boolean = null): Promise<void> => {
        const where = `${columnName} = ${columnId}`;
        await this.updateWhere(where, updateObject, isDirty);
    };

    public updateWhere = async (where: string, updateObject: Map<string, any>, isDirty: boolean = null): Promise<void> => {
        if (isDirty !== null) {
            updateObject.set("IsDirty", isDirty);
        }

        const columnNames = Array.from(updateObject.keys());
        if (!columnNames.length) {
            // empty update object => do nothing
            return;
        }

        const columns = columnNames.map(x => `${x}=?`).join(",");

        const values = Array.from(updateObject.values()).map(x => BaseService.transformUndefinedToNull(x));
        await dal.execute(`UPDATE ${this.getTableName()} SET ${columns}${where ? ` WHERE ${where}` : ""}`, values);
    };

    public async getItemById(id: number, select: string = "*"): Promise<TEntity> {
        const sql = `SELECT ${select} FROM ${this.getTableName()} WHERE Id = ? AND IsDeleted = ?`;
        const item = await dal.firstOrDefault<TEntity>(sql, [id, false]);
        return item ? this.createEntityFromDb(item) : null;
    }

    public getItemByUuid = async (uuid: string, select: string = "*"): Promise<TEntity> => {
        const sql = `SELECT ${select} FROM ${this.getTableName()} WHERE Uuid = ? COLLATE NOCASE AND IsDeleted = ?`;
        const item = await dal.firstOrDefault<TEntity>(sql, [uuid, false]);
        return item ? this.createEntityFromDb(item) : null;
    };

    public getItemsByIds = async (ids: number[]): Promise<TEntity[]> => {
        if (ids.length > 1) {
            return this.getItems(`Id IN (${ids.join(",")})`);
        } else if (ids.length === 1) {
            return this.getItems(`Id = '${ids[0]}'`);
        } else {
            return [];
        }
    };

    public async get<T>(where: string, select: string, params: any[]): Promise<T> {
        if (where) {
            where = `(${where}) AND IsDeleted = ?`;
        } else {
            where = "IsDeleted = ?";
        }
        params.push(false);
        const sql = `SELECT ${select} FROM ${this.getTableName()} WHERE ${where}`;
        return Util.firstOrDefault<T>(await dal.executeRead(sql, params));
    }

    public async getItems(where: string = null, orderBy: string = null, limit: number = null, select: string = "*", offset: number = null, params?: any[]): Promise<TEntity[]> {
        params = params ?? [];
        if (where) {
            where = `(${where}) AND IsDeleted = ?`;
        } else {
            where = "IsDeleted = ?";
        }
        params.push(false);
        return this.getItemsIncludingDeleted(where, orderBy, limit, select, offset, params);
    }

    public getItemsIncludingDeleted = async (where: string = null, orderBy: string = null, limit: number = null, select: string = "*", offset: number = null, params?: any[]): Promise<TEntity[]> => {
        let sql = `SELECT ${select || "*"} FROM ${this.getTableName()}`;

        if (where) {
            sql += ` WHERE ${where}`;
        }
        if (orderBy) {
            sql += ` ORDER BY ${orderBy}`;
        }
        if (limit) {
            sql += ` LIMIT ${limit}`;
        }
        if (offset) {
            sql += ` OFFSET ${offset}`;
        }
        const items = await dal.executeRead(sql, params);
        return items.map(x => this.createEntityFromDb(x));
    };

    public async getItemCount(where: string = null, params?: any[]): Promise<number> {
        params = params ?? [];
        let sql = `SELECT COUNT(*) AS ItemCount FROM ${this.getTableName()}`;
        if (where) {
            sql += ` WHERE (${where}) AND IsDeleted = ?`;
        } else {
            sql += ` WHERE IsDeleted = ?`;
        }
        params.push(false);
        const count = Util.firstOrDefault((await dal.executeRead(sql, params)) as { ItemCount: number }[]);
        return count ? count.ItemCount : 0;
    }

    public async getDeletedItems(): Promise<TEntity[]> {
        const sql = `SELECT * FROM ${this.getTableName()} WHERE IsDeleted = ?`;
        const items = await dal.executeRead(sql, [true]);
        return items.map(x => this.createEntityFromDb(x));
    }

    protected getNewItems(): Promise<TEntity[]> {
        return this.getItems("Id < 0");
    }

    protected getDirtyItems(): Promise<TEntity[]> {
        // We want to get deleted items as well, in order to delete them in Orphy
        return this.getItemsIncludingDeleted("IsDirty = ? AND Id > 0", null, null, null, null, [true]);
    }

    public getDirtyItemCountStatement(params: any[]): string {
        params.push(true);
        return `SELECT '${this.getTableName()}' AS TableName, COUNT(*) AS DirtyCount FROM ${this.getTableName()} WHERE Id < 0 OR IsDirty = ?`;
    }

    public async delete(id: number, isDirty: boolean = true): Promise<void> {
        const item = await this.getItemById(id);
        if (item && item.Id > 0) {
            item.IsDeleted = true;
            await this.updateEntity(item, isDirty);
        } else if (item) {
            await this.hardDelete(id);
        }
    }

    public deleteByCondition = async (where: string): Promise<void> => {
        const items = await this.getItemsIncludingDeleted(where);
        for (const item of items) {
            item.IsDeleted = true;
            await this.updateEntity(item, true);
        }
    };

    public async hardDelete(id: number): Promise<void> {
        const sql = `DELETE FROM ${this.getTableName()} WHERE Id = ${id}`;
        await dal.execute(sql);
    }

    public async hardDeleteByCondidtion(where: string): Promise<void> {
        const sql = `DELETE FROM ${this.getTableName()} WHERE ${where}`;
        await dal.execute(sql);
    }

    public sync = async (forbiddenItems: number = 0): Promise<number> => {
        this.forbiddenItems = forbiddenItems;
        await this.pullItems();
        const pushedCount = await this.pushItems();
        if (pushedCount) {
            const count = await this.sync(this.forbiddenItems);
            return this.forbiddenItems + count;
        } else {
            return this.forbiddenItems;
        }
    };

    public async pullItems(): Promise<void> {
        const lastSyncDate = await lastSyncDateService.getLastSyncDate(this.getTableName());
        await this.requestItems(lastSyncDate);
    }

    protected async requestItems(lastSyncDate: string): Promise<void> {
        logger.logInfo(`${this.getTableName()} service preparing to pull, last sync date: ${lastSyncDate}`);
        const lastModifiedAt = await this.getLastModifiedAt();
        let moreItemsAvailable = true;
        let skip = 0;
        while (moreItemsAvailable) {
            if (!lastSyncDate) {
                moreItemsAvailable = await this.getInitialItems(skip);
            } else {
                moreItemsAvailable = await this.getItemsModifiedSince(lastSyncDate, skip);
            }
            skip += this.getTopCount();
        }
        await this.updateSyncDate(lastModifiedAt);
    }

    protected async handleDeletedItems(items: IDiffDto[]): Promise<void> {
        for (const item of items) {
            await this.delete(item.Id, false);
        }
    }

    protected async handleModifiedItem(item: IDiffDto) {
        await this.update("Id", item.Id, this.createDbUpdateObject(item.ChangedProperties), null);
    }

    protected async handleModifiedItems(items: IDiffDto[]): Promise<void> {
        for (const item of items) {
            await this.handleModifiedItem(item);
        }
    }

    protected handleAddedItems = async (items: IDiffDto[]): Promise<void> => {
        if (!items.length) {
            return;
        }
        const requestedItems = await network.send<TDto[]>(
            "POST",
            `${this.getApiRoute()}/GetItemsByArray`,
            items.map(x => x.Id)
        );
        for (const requestedItem of requestedItems) {
            if (requestedItem) {
                try {
                    await this.insert(this.createEntityFromOData(requestedItem));
                } catch (e) {
                    if (e.Status === 404) {
                        logger.logInfo(`No item for table: ${this.getTableName()} received after ModifiedSince`);
                    } else {
                        this.failedToPull(e, requestedItem as unknown as any);
                    }
                }
            } else {
                logger.logWarning(`No item for table: ${this.getTableName()} received after ModifiedSince`);
            }
        }
    };

    private failedToPull = (error: NetworkError, items: any[] = []): any[] => {
        if (error.Status && error.Status === 403) {
            this.forbiddenItems++;
            logger.logInfo(`${this.getTableName()} service was not allowed to pull items`, false);
            return items;
        } else if (error.Status && error.Status > 400 && error.Status < 500) {
            logger.logInfo(`Skipping item for table ${this.getTableName()}, stauts: ${error.Status}, state: ${error.StatusText}`, false);
            return items;
        } else {
            logger.logInfo(`Failure while pulling ${this.getTableName()}, stauts: ${error.Status}, state: ${error.StatusText}`, false);
            throw new Error(error.StatusText);
        }
    };

    protected getItemsModifiedSince = async (lastSyncDate: string, skip: number): Promise<boolean> => {
        try {
            const items = await network.getItems<IDiffDto[]>(`${this.getApiRoute()}/ModifiedSince/${lastSyncDate}?$skip=${skip}&$top=${this.getTopCount()}`);
            const localItems = await this.getItems(`Id IN ${Util.joinIds(items.map(x => x.Id))}`, null, null, this.localItemSelect());
            // event: Modified AND we already have this item locally AND IsDeleted is not present OR IsDeleted is not true
            const modifiedItems = items.filter(
                i => i.EventType === EventType.Modified && (!i.ChangedProperties.hasOwnProperty("IsDeleted") || !i.ChangedProperties.IsDeleted) && localItems.some(l => l.Id === i.Id)
            );
            // event: Added OR event: Modified and we don't have the item locally AND IsDeleted is not present OR IsDeleted is not true
            const addedItems = items.filter(
                i =>
                    (i.EventType === EventType.Added || (i.EventType === EventType.Modified && (!i.ChangedProperties.hasOwnProperty("IsDeleted") || !i.ChangedProperties.IsDeleted))) &&
                    !localItems.some(l => l.Id === i.Id)
            );
            // event: Deleted OR event: Modified and IsDeleted set to true
            const deletedItems = items.filter(
                i => (i.EventType === EventType.Deleted || (i.ChangedProperties.hasOwnProperty("IsDeleted") && i.ChangedProperties.IsDeleted)) && localItems.some(l => l.Id === i.Id)
            );

            await this.handleModifiedItems(modifiedItems);
            await this.handleAddedItems(addedItems);
            await this.handleDeletedItems(deletedItems);
            const allItems = await this.getItems(
                `Id IN ${Util.joinIds(items.map(x => x.Id))} AND Uuid IN (SELECT Uuid FROM ${this.getTableName()} GROUP BY uuid HAVING COUNT(*) > 1)`,
                null,
                null,
                "Uuid"
            );
            const possibleDuplicates = await this.getItems(`Uuid IN ${Util.joinUuids(allItems)}`);
            for (const item of possibleDuplicates) {
                const duplicates = possibleDuplicates.filter(l => l.Uuid === item.Uuid);
                if (duplicates.length > 1) {
                    const positiveDuplicate = duplicates.find(x => x.Id > 0);
                    if (positiveDuplicate) {
                        for (const negativeDuplicate of duplicates.filter(x => x.Id < 0)) {
                            await this.updateDependencies(negativeDuplicate.Id, positiveDuplicate.Id, false);
                            await this.hardDelete(negativeDuplicate.Id);
                        }
                    }
                }
            }

            return items.length === this.getTopCount();
        } catch (e) {
            this.failedToPull(e);
        }
    };

    protected getLastModifiedAt(): Promise<string> {
        return network.get<string>(`${this.getApiRoute()}/LastModifiedAt`);
    }

    protected async getInitialItems(skip: number): Promise<boolean> {
        try {
            const items = await network.getItems<TDto[]>(this.getSkiptTopOdataRoute(skip));
            await this.insertMany(items.map(x => this.createEntityFromOData(x)));
            await this.afterGetInitalItemsInterceptor(items);
            return items.length === this.getTopCount();
        } catch (e) {
            this.failedToPull(e);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected async afterGetInitalItemsInterceptor(items: IBaseDto[]): Promise<void> {
        // not used in baseService
    }

    private getSkiptTopOdataRoute(skip: number): string {
        return `${this.getApiRoute()}?$skip=${skip}&$top=${this.getTopCount()}`;
    }

    protected getTopCount(): number {
        return this.TOP_COUNT;
    }

    protected canSkipAndTop(): boolean {
        return true;
    }

    protected updateSyncDate = async (lastSyncDate: string): Promise<void> => {
        if (lastSyncDate) {
            await lastSyncDateService.update(this.getTableName(), lastSyncDate);
        }
    };

    protected valueModifier(key: string, value: any) {
        return value;
    }

    public createDbUpdateObject(oDataUpdateItem: any): Map<string, any> {
        oDataUpdateItem = oDataUpdateItem.ChangedProperties ? oDataUpdateItem.ChangedProperties : oDataUpdateItem;
        const updateObject = new Map<string, any>();
        for (const [key, value] of Object.entries(oDataUpdateItem)) {
            if (this.getDtoFields().some(x => x === key)) {
                updateObject.set(key, this.valueModifier(key, value));
            }
        }
        return updateObject;
    }

    public async pushItems(): Promise<number> {
        const newItems = await this.getNewItems();
        const pushedCount = await this.processNewItems(newItems);
        const patchedCount = await this.patchItems();
        return pushedCount + patchedCount;
    }

    protected async processNewItems(items: TEntity[]): Promise<number> {
        let pushedItemCount = 0;
        logger.logInfo(`${this.getTableName()} service has found ${items.length} local items to push`);
        for (const item of items) {
            await this.beforeProcessItemInterceptor(item);
            const id = await this.processItem(item);
            if (id) {
                pushedItemCount++;
            }
        }
        logger.logInfo(`${this.getTableName()} service has pushed ${pushedItemCount} items`);
        return pushedItemCount;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected async beforeProcessItemInterceptor(item: TEntity): Promise<void> {
        // not used in baseService
    }

    protected async processItem(item: TEntity): Promise<number> {
        try {
            const data = await network.send<TDto>("POST", this.getPostRoute(), item.toODataObject());
            await this.updateDependencies(item.Id, data.Id);
            const update = this.createDbUpdateObject(data);
            update.set("IsNew", false);
            this.modifyNewItemUpdate(update, item);
            await this.update("Id", data.Id, update);
            return data.Id;
        } catch (response) {
            if (response.Status === 403) {
                Util.showNotification(translation.t("confirm.to-many-students"), "error", 0, false);
            }
            await this.handleDuplicatedItem(item);
        }
    }

    protected async handleDuplicatedItem(item: TEntity): Promise<void> {
        if (item.Uuid) {
            try {
                const orphyItem = await network.get<TDto>(`${this.getApiRoute()}/GetByUuid/${item.Uuid}`);
                if (orphyItem) {
                    try {
                        await this.updateDependencies(item.Id, orphyItem.Id);
                        item.IsNew = false;
                        await this.updateEntity(item, false);
                    } catch (e) {
                        if (e && e.code && e.code === 6 /* Constraint Error */ && item.Id < 0) {
                            await this.hardDelete(item.Id);
                            await this.updateDependencies(item.Id, orphyItem.Id, false);
                        }
                    }
                }
            } catch (e) {
                logger.logError(new Error(`Failed to handle duplicate item ${item.Uuid} in table ${this.getTableName()}`));
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected modifyNewItemUpdate(update: Map<string, any>, item: TEntity) {}

    public async patchItems(): Promise<number> {
        const changedItems = await this.getDirtyItems();
        return this.patchChanges(changedItems);
    }

    protected patchChanges = async (changedItems: TEntity[]): Promise<number> => {
        logger.logInfo(`${this.getTableName()} service has found ${changedItems.length} items to patch`);
        let patchedItemCount = 0;
        for (const changedItem of changedItems) {
            patchedItemCount += await this.patchItem(changedItem);
        }
        logger.logInfo(`${this.getTableName()} service has pushed ${patchedItemCount} items`);
        return patchedItemCount;
    };

    protected async patchItem(item: TEntity): Promise<number> {
        const method = item.IsDeleted ? "DELETE" : "PATCH";
        try {
            const data = await network.send<any>(method, this.getPatchRoute(item.Id), item.toODataObject());
            await this.resetDirtyFlag(data.value ? data.value : data.Id ? data.Id : data);
            return 1;
        } catch (response) {
            await this.patchItemErrorHandling(response, item);
            return 0;
        }
    }

    protected patchItemErrorHandling = async (response: NetworkError, item: TEntity): Promise<void> => {
        if (response.Status === 404) {
            await this.update("Id", item.Id, new Map<string, any>().set("IsDeleted", true), false);
        } else {
            throw new Error(response.StatusText);
        }
    };

    protected async resetDirtyFlag(id: number): Promise<void> {
        await this.updateWhere(`Id = ${id}`, new Map<string, any>(), false);
    }

    protected async updateDependencies(oldId: number, newId: number, updateSelf: boolean = true): Promise<void> {
        if (updateSelf) {
            await this.update("Id", oldId, new Map<string, any>().set("Id", newId), null);
        }
    }

    protected getODataFilter(): string {
        return "";
    }

    protected getODataSelect(): string {
        return "";
    }

    protected getIntValueOrDefault(value) {
        return Number.isNaN(value) ? 0 : value;
    }

    static transformUndefinedToNull = (value: any): any => {
        if (value === undefined) {
            return null;
        }
        return value;
    };
}
