import { activeLesson } from "../modules/ActiveLesson";
import Popup from "../modules/Popup";
import { Util } from "../modules/Util";
import { lessonService } from "../services/LessonService";
import { emailService } from "../services/EmailService";
import { ImageGalleryView } from "./ImageGalleryView";
import { personContactService } from "../services/PersonContactService";
import { personService } from "../services/PersonService";
import { checklistItemService } from "../services/ChecklistItemService";
import { ratingService } from "../services/ChecklistRatingService";
import { translation } from "../services/TranslationService";
import PromptContent from "../modules/PromptContent";
import { ConfirmRejectReason } from "../modules/ConfirmRejectReason";

export default class ExecuteLesson extends ImageGalleryView {
    public personAvatar: string = "";
    public personName: string = "";
    public chapterTitle: string = "";
    public stepCount: number;
    public activeStep: number;
    public previousLessonDisabled: boolean;
    public nextLessonDisabled: boolean;
    public overView: string;
    public ratingHistory: string[];
    public ratedCount: number;
    public ratingLength: number;
    public dropDownList: any[];

    private popupRendered: Popup;
    private navPoint: string;
    private step: number;

    constructor() {
        super();
        this.dropDownList = [];
        this.popupRendered = new Popup();
        super.init(this);
    }

    public async loadData(e: any): Promise<void> {
        if (!e.view.params.fromDrawing) {
            const person = await personService.getItemById(this.personId, "Id, FirstName, LastName");
            this.set("personAvatar", Util.getAvatar(person.FirstName, person.LastName));
            this.set("personName", `${person.FirstName} ${person.LastName}`);
            this.navPoint = e.view.params.navPoint ? `${e.view.params.navPoint}` : this.navPoint;
            this.set("stepCount", activeLesson[0].getLessonsLength());
            this.set("step", -1);
            await this.nextLesson();
            this.createPopup();
            this.set("dropDownList", activeLesson[0].getDropdownList());
            this.updateLessonAmountOf();
        }
    }

    private updateLessonAmountOf(): void {
        this.set(
            "LessonAmountOf",
            translation.t("execute-lesson.lesson-amount-of", { stepCount: this.stepCount, count: this.activeStep })
        );
    }

    private createPopup = () => {
        const createdView: kendo.View = new kendo.View("#dropDownContentExecuteLessonTemplate", {
            model: this,
            evalTemplate: true
        });
        let destination = $("#executeLessonTemplateHolder");
        if (destination.length) {
            destination.empty();
        } else {
            $("body").append("<div id='executeLessonTemplateHolder'></div>");
            destination = $("#executeLessonTemplateHolder");
        }
        createdView.render(destination);
    };

    public openPopup = (e: Event) => {
        this.popupRendered.renderFixedPositionPopup("#dropDownContentExecuteLesson", e, false, false);
    };

    public changeLesson = async e => {
        this.popupRendered.closePopup();
        if (!e.data.Selected) {
            this.removeSelected();
            e.data.set("Selected", true);
            this.step = e.data.Index;
            await this.setLesson();
        }
    };

    public nextLesson = async () => {
        this.step++;
        await this.setLesson();
    };

    public previousLesson = async () => {
        this.step--;
        await this.setLesson();
    };

    private setLesson = async () => {
        this.handleNavigationButtons();
        this.set("activeStep", this.step + 1);
        activeLesson[0].setActiveLesson(this.step);
        this.setDropdownStep();
        this.set("chapterTitle", activeLesson[0].getActiveChapterTitle());
        const content = Util.replaceChecklistBlob(
            activeLesson[0].getChapterContent()
                ? activeLesson[0].getChapterContent().trim()
                : translation.t("common.no-entry")
        );
        await this.setRatingHistory(activeLesson[0].getChapterId());
        this.loadImages(content);
        $(".myDriveGalleryPicture em [style]").each((_, e) => $(e).removeAttr("style"));
        this.set("hasContent", !!content);
        this.updateLessonAmountOf();
    };

    protected getChapterId(): number {
        return activeLesson[0].getChapterId();
    }

    private handleNavigationButtons = () => {
        if (this.step === 0) {
            this.set("previousLessonDisabled", true);
        } else if (this.step > 0) {
            this.set("previousLessonDisabled", false);
        }
        if (this.step < activeLesson[0].getLessonsLength() - 1) {
            this.set("nextLessonDisabled", false);
        } else if (this.step === activeLesson[0].getLessonsLength() - 1) {
            this.set("nextLessonDisabled", true);
        }
    };

    private async setRatingHistory(chapterId: number): Promise<void> {
        const ratings = await ratingService.getRatingsByChapterId(chapterId, Util.getEducationId(this.personId));
        const ratedCount = (await lessonService.getPreviousLessons(Util.getEducationId(this.personId))).length;
        this.set("ratingLength", ratedCount);
        this.set("ratingHistory", ratings);
        this.set("ratedCount", ratings.filter(element => !!element).length);
    }

    private setDropdownStep = () => {
        this.removeSelected();
        for (const item of this.dropDownList) {
            if (item.Index === this.step) {
                item.set("Selected", true);
            }
        }
    };

    private removeSelected = () => {
        for (const item of this.dropDownList) {
            if (item.Selected) {
                item.set("Selected", false);
                break;
            }
        }
    };

    public endLesson = () => {
        app.mobileApp.navigate(`views/educationCard.html?navPoint=views/executeLesson.html&resetLastSelectedTab=true`);
    };

    protected getImageSelector(): string {
        return "#contentExecuteLesson";
    }

    public sendPerMail = async (): Promise<void> => {
        const chapter = await checklistItemService.getItemById(this.getChapterId());
        const personEmail = await personContactService.getPersonEmail(this.personId);
        let emailRecipient = "";
        try {
            emailRecipient = await Util.prompt(
                PromptContent.sendContentMail(),
                translation.t("education-paper.send-email"),
                translation.t("common.cancel"),
                personEmail.NameInMedia
            );

            await emailService.sendEmail(Util.getUsername(), emailRecipient, chapter.Label, chapter.Overview);
            Util.showNotification(
                translation.t("education-paper.send-email-to-successful", { toAddress: emailRecipient }),
                "success"
            );
        } catch (e) {
            if (e !== ConfirmRejectReason.Cancel && e !== ConfirmRejectReason.NoText) {
                Util.showNotification(
                    translation.t("education-paper.send-email-error", { toAddress: emailRecipient }),
                    "error"
                );
            }
        }
    };

    public openFreeHandDrawing = () => {
        app.mobileApp.navigate(
            `views/drawing.html?name=${translation.t("drawing.file-name")}&navPoint=${this.thisNavPoint()}`
        );
    };

    public closePopup = () => {
        this.popupRendered.closePopup();
    };

    public thisNavPoint(): string {
        return encodeURI(`views/executeLesson.html?noReload=true`);
    }

    public getNavPoint() {
        return this.navPoint;
    }

    public hideView(): void {
        this.popupRendered.destroy();
    }
}
