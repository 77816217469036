import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseProduct {
    Name: string;
    Beschreibung: string;
    Verkaufspreis: number;
    LessonCount: number;
}

export interface IProductDto extends IBaseDto, IBaseProduct {}
export interface IProduct extends IBaseEntity, IBaseProduct {}

export default class Product extends BaseEntity<IProduct, IProductDto> implements IProduct {
    public static get EntityTypeId(): string {
        return "9e8a1f9f-a8ce-5b8e-8e52-aab4c4e3a935";
    }

    public static get DefaultLessonId(): string {
        return "5dfb25e1-37c0-411a-9944-a73e53e08f9c";
    }

    public static get DefaultPaymentFlowId(): string {
        return "eb517db5-f2ca-4d44-b637-f07e4fe6624b";
    }

    public Name: string;
    public Beschreibung: string;
    public Verkaufspreis: number;
    public LessonCount: number;

    public populateFromDb(dbItem: IProduct): void {
        super.populateFromDb(dbItem);
        this.Name = dbItem.Name;
        this.Beschreibung = dbItem.Beschreibung;
        this.Verkaufspreis = dbItem.Verkaufspreis;
        this.LessonCount = dbItem.LessonCount;
    }

    public populateFromOData(oDataItem: IProductDto): void {
        super.populateFromOData(oDataItem);
        this.Name = oDataItem.Name;
        this.Beschreibung = oDataItem.Beschreibung;
        this.Verkaufspreis = oDataItem.Verkaufspreis;
        this.LessonCount = oDataItem.LessonCount;
    }

    public toODataObject(): IProductDto {
        const oDataObject = super.toODataObject();
        oDataObject.Name = this.Name;
        oDataObject.Beschreibung = this.Beschreibung;
        oDataObject.Verkaufspreis = this.Verkaufspreis;
        oDataObject.LessonCount = this.LessonCount;

        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<Product>(x => x.Name),
            this.Name
        );
        dbObject.set(
            nameof<Product>(x => x.Beschreibung),
            this.Beschreibung
        );
        dbObject.set(
            nameof<Product>(x => x.Verkaufspreis),
            this.Verkaufspreis
        );
        dbObject.set(
            nameof<Product>(x => x.LessonCount),
            this.LessonCount
        );
        return dbObject;
    }
}
