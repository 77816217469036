import { nameof } from "ts-simple-nameof";
import BaseEntity, { IBaseDto, IBaseEntity } from "./BaseEntity";

interface IBaseCurrentLesson {
    RatingValue: number;
    PersonId: number;
    ChapterId: number;
    Note: string;
}

export interface ICurrentLessonDto extends IBaseDto, IBaseCurrentLesson {}
export interface ICurrentLesson extends IBaseEntity, IBaseCurrentLesson {}

export default class CurrentLesson extends BaseEntity<ICurrentLesson, ICurrentLessonDto> implements ICurrentLesson {
    public RatingValue: number;
    public PersonId: number;
    public ChapterId: number;
    public Note: string;

    public populateFromDb(dbItem: ICurrentLesson): void {
        super.populateFromDb(dbItem);
        this.RatingValue = dbItem.RatingValue;
        this.PersonId = dbItem.PersonId;
        this.ChapterId = dbItem.ChapterId;
        this.Note = dbItem.Note;
    }

    public populateFromOData(oDataItem: ICurrentLessonDto): void {
        super.populateFromOData(oDataItem);
        this.RatingValue = oDataItem.RatingValue;
        this.PersonId = oDataItem.PersonId;
        this.ChapterId = oDataItem.ChapterId;
        this.Note = oDataItem.Note;
    }

    public toODataObject(): ICurrentLessonDto {
        const oDataObject = super.toODataObject();
        oDataObject.RatingValue = this.RatingValue;
        oDataObject.PersonId = this.PersonId;
        oDataObject.ChapterId = this.ChapterId;
        oDataObject.Note = this.Note;
        return oDataObject;
    }

    public toDbObject(): Map<string, any> {
        const dbObject = super.toDbObject();
        dbObject.set(
            nameof<CurrentLesson>(x => x.RatingValue),
            this.RatingValue
        );
        dbObject.set(
            nameof<CurrentLesson>(x => x.PersonId),
            this.PersonId
        );
        dbObject.set(
            nameof<CurrentLesson>(x => x.ChapterId),
            this.ChapterId
        );
        dbObject.set(
            nameof<CurrentLesson>(x => x.Note),
            this.Note
        );
        return dbObject;
    }
}
